/*****************************************************************************************
 * 설명 : 사용자 통합검색 - list - 프로젝트
 * URI : /user/TotalSearch/list
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import Dialog from '@mui/material/Dialog';
import Pagination from '@mui/material/Pagination';
import { AgGridReact } from 'ag-grid-react';
import { useEffect, useState } from 'react';

import { PaperComponent, comma } from 'service/utils';

import customTooltipAgGrid from 'components/tooltipAgGrid';

import ProjectInfo from 'pages/user/search/searchList/projectInfo';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const TabProject = ( props ) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [openModal, setOpenModal] = useState({open: false, modal: 'sync', data: []});

  const [totalPage, setTotalPage] = useState(0);
  const [list, setList] = useState([]);

  // table column
  const [columnDefs] = useState([
    { headerName: '번호', field: 'index', width: 45, cellClass: 'cp text-right',
      valueGetter: (params) => {
        return comma(( params.node.rowIndex + 1 ) + ( (params.data.projectPageNo - 1) * params.data.projectPageRow ));
      }
    },
    { headerName:'구분', field: 'projectDivisionName', width: 120, cellClass: 'cp center' },
    { headerName:'PJ NO', field: 'projectNumber', width: 100, cellClass: 'cp center' },
    { headerName:'PJ 명', field: 'projectName', width: 300, cellClass: 'cp', tooltipField: 'projectName' },
    { headerName:'유형', field: 'projectTypeName', width: 100, cellClass: 'cp center', tooltipField: 'projectTypeName' },
    { headerName:'용도', field: 'purpose1', width: 150, cellClass: 'cp center', tooltipField: 'purpose1' },
    { headerName:'규모', field: 'scale', width: 150, cellClass: 'cp center', tooltipField: 'scale' },
    { headerName:'주관부서', field: 'projectDepartmentName', width: 120, cellClass: 'cp center', tooltipField: 'projectDepartmentName' },
    { headerName:'담당PM', field: 'projectPmName', width: 100, cellClass: 'cp center' },
    { headerName:'건축주', field: 'builderName', width: 200, cellClass: 'cp', tooltipField: 'builderName' },
    { headerName:'발주처', field: 'orderCompanyIdName', width: 200, cellClass: 'cp', tooltipField: 'orderCompanyIdName' },
    { headerName:'시공사', field: 'builderCompanyIdName', width: 200, cellClass: 'cp', tooltipField: 'builderCompanyIdName' }
  ]);

  /***************************************************************************************
   * 설명 : 데이터 처리
  ***************************************************************************************/
  useEffect(() => {
    if( props.tabIndex === 1 && props.list ) {
      setList(props.list);

      if( props.total !== undefined ) {
        let totalCount = (props.total.project === undefined ) ? 1 : props.total.project;
        let total = Math.floor(totalCount / 20);
        if( totalCount % 20 > 0 ) total++;

        setTotalPage(total);

      } else {
        setTotalPage(0);
      }

    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.tabIndex, props.list]);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <section className="search-list-result">
      <section className="project-file">
        <section className="ag-theme-balham grid pad-height420" style={{height:'calc(100vh - 380px)'}}>
          <AgGridReact
            defaultColDef={{
              sortable: true,
              resizable: true,
              filter: false,
              lockVisible: true,
              tooltipComponent: customTooltipAgGrid,
            }}
            tooltipShowDelay={0}
            tooltipHideDelay={2000}
            rowSelection={'single'}
            columnDefs={columnDefs}
            rowData={list}
            onRowClicked={(event) => {
              //window.open("/project")
              //history("/project")
              setOpenModal({open: true, modal: 'projectInfo', data: event.data})
            }}
            overlayNoRowsTemplate = "검색된 내용이 없습니다."
          />
        </section>

        <footer className="mt10">
          <div className="mb10">
            <div className="f16">디자인 검색결과 : {comma(props.total.design)}건,  프로젝트 검색결과 : {comma(props.total.project)}건, 파일 검색결과 : {comma(props.total.file)}건</div>
          </div>
          <div className="search-pagination1">
            <div className="pagination big">
              <Pagination
                count={totalPage}
                defaultPage={props.formik.values.pageNo}
                siblingCount={0}
                boundaryCount={2}
                size={!props.size ? 'small' : props.size}
                shape="rounded"
                color="primary"
                showFirstButton
                showLastButton
                onChange={(event, pageNo) => props.change(event, pageNo)}
              />
            </div>
          </div>
        </footer>
      </section>

      {
        openModal.open && openModal.modal === 'projectInfo' &&
        <Dialog
          open={openModal.open}
          onClose={() => setOpenModal({ open: false, modalName: 'projectInfo', data: openModal.data })}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "1000px",  // Set your width here
              },
            },
          }}
        >
          <ProjectInfo
            open={openModal.open}
            data={openModal.data}
            isFile={false}
            close={() => setOpenModal({ open: false, modalName: 'projectInfo', data: openModal.data })}
          />
        </Dialog>
      }
    </section>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default TabProject;