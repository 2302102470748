/*****************************************************************************************
 * 설명 : 열람관리
 * URI : /reading
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import Dialog from '@mui/material/Dialog';
import { AgGridReact } from 'ag-grid-react';
import { useFormik } from 'formik';
import * as moment from 'moment';
import { useEffect, useState } from 'react';
import * as Yup from "yup";

import useGridConfig from 'hooks/useGridConfig';

import BtnAgGridSave from 'components/btnAgGridSave';
import ChipEx from 'components/chipEx';
import { BtnRefresh, BtnSearch } from 'components/common';
import { DateEx, InputEx, ToggleButtonGroupEx } from 'components/inputEx';
import customTooltipAgGrid from 'components/tooltipAgGrid';

import { getUserRequestApplicationApi } from 'service/request';
import Restful from 'service/restful';
import { comma, PaperComponent } from 'service/utils';

import ReadingModal from 'pages/admin/reading/readingModal';
import UserLayout from 'pages/layout/userLayout';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const UserReading = (props) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [getApi] = Restful();

  const [list, setList] = useState([]);
  const [selected, setSelected] = useState([]);

  const initialValues = {
    status: 1,
    startDate: moment().add(-1, 'month').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
    searchText: ''
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object().shape({
      searchText: Yup.string().max(50, "50자리")
    }),
    onSubmit: (values) => {
      getList();
    }
  });

  const [openModal, setOpenModal] = useState({open: false, modal: 'sync', data: []});

  // table column
  const [columnDefs, setColumnDefs] = useState([
    { headerName: '', field: 'seq', width: 40, cellClass: 'center', flex: 0,
      headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
    { headerName: '순번', field: 'rowIndex', width: 45, cellClass: 'cp text-right',
      valueGetter: (params) => {
        return comma(params.node.rowIndex + 1);
      }
    },
    { headerName: '신청상태', field: 'status', width: 100, cellClass: 'cp center',
      cellRendererFramework: function(params) {
        if( parseInt(params.data.status) === 1 )
          return <ChipEx color="primary" variant="outlined" size="small" label="신청" />;
        else if( parseInt(params.data.status) === 2 )
          return <ChipEx color="success" variant="outlined" size="small" label="승인" />;
        else if( parseInt(params.data.status) === 3 )
          return <ChipEx color="error" variant="outlined" size="small" label="반려" />;
        else
          return '';
      }
    },
    { headerName: '프로젝트번호', field: 'projectNumber', width: 120, cellClass: 'cp center' },
    { headerName: '프로젝트명', field: 'projectName', width: 300, cellClass: 'cp' },
    { headerName: '열람신청 사유', field: 'reason', width: 400, cellClass: 'cp' },
    { headerName: '신청일시', field: 'createDate', width: 150, cellClass: 'cp center' },
    { headerName: '승인기간', field: 'approvalStartDate', width: 180, cellClass: 'cp center',
      valueGetter: (params) => {
        if( parseInt(params.data.status) === 3 )
          return '';
        else if( parseInt(params.data.isDate) === 1 )
          return '기간제한 없음'
        else if( params.data.approvalStartDate !== null )
          return params.data.approvalStartDate + '~' + params.data.approvalEndDate;
        else
          return '';
      }
    },
    { headerName: '승인/반려일자', field: 'approvalDate', width: 150, cellClass: 'cp center'},
    { headerName: '승인/반려 사유', field: 'approvalReason', width: 300, cellClass: 'cp'},
  ]);

  const [gridConfig, setGridUpdate] = useGridConfig(130, setColumnDefs);

  /***************************************************************************************
   * 설명 : 데이터 가져오기
  ***************************************************************************************/
  const getList = () => {
    let params = {...formik.values}

    getUserRequestApplicationApi(getApi, params, (response) => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        setList(response.data.data);

      } else {
        setList([]);
      }
    });
  }

  /***************************************************************************************
   * 설명 : 상태값 변경 시 자동 검색
  ***************************************************************************************/
  useEffect(() => {
    getList();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.status]);

  /***************************************************************************************
   * 설명 : 데이터 로딩 처리
  ***************************************************************************************/
  useEffect(() => {
    getList();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <UserLayout>
      <form onSubmit={formik.handleSubmit} className="h100">
        <header className="search-list-result ml10 pb10">
          <div className="left">
            <BtnRefresh onClick={() => {
              formik.setValues(initialValues);
              formik.handleSubmit();
            }}></BtnRefresh>
            <BtnSearch></BtnSearch>
            <BtnAgGridSave click={setGridUpdate} />

            <ToggleButtonGroupEx
              name="status"
              exclusive={true}
              formik={formik}
              className="search-toggle-btn"
              data={[
                {label: '전체', value: ''},
                {label: '신청', value: 1},
                {label: '승인', value: 2},
                {label: '반려', value: 3},
              ]}
            />

            <DateEx
              type="date"
              name="startDate"
              formik={formik}
              fullWidth={false}
              style={{width: '150px'}}
              className="ml5"
            />
            <span className="ml5 mr5">~</span>
            <DateEx
              type="date"
              name="endDate"
              formik={formik}
              fullWidth={false}
              style={{width: '150px'}}
            />
            <InputEx
              name="searchText"
              formik={formik}
              style={{width: '300px'}}
              label="신청자, 프로젝트 명 검색"
              className="ml5"
            />
          </div>
        </header>
      </form>

      <section style={{height: 'calc(100vh - 140px)'}} className="p10 pad-height198">
        <section className="ag-theme-balham grid mb10" style={{height:'100%'}}>
          <AgGridReact
            defaultColDef={{
              sortable: true,
              resizable: true,
              filter: false,
              lockVisible: true,
              tooltipComponent: customTooltipAgGrid,
            }}
            tooltipShowDelay={0}
            tooltipHideDelay={2000}
            rowSelection = {'multiple'}
            columnDefs={columnDefs}
            rowData={list}
            onGridReady={(event) => {
              gridConfig(event.columnApi);
            }}
            onSelectionChanged={(event) => {
              let nodes = event.api.getSelectedRows();
              setSelected(nodes);
            }}
            onRowDoubleClicked={(event) => {
              setOpenModal({open: true, modal: 'readingInfo', data: {siteSeq: formik.values.siteSeq}})
            }}
            rowDragManaged={true}
            rowDragMultiRow={true}
            animateRows={true}
            overlayNoRowsTemplate = "검색된 내용이 없습니다."
          />
        </section>
      </section>

      { openModal.open && openModal.modal === 'readingInfo' &&
        <Dialog
          open={openModal}
          onClose={() => (event, reason) => {
            if (reason && reason === "backdropClick") return;
            setOpenModal({open: false, modal: openModal.modal, data: {}});
          }}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "800px",  // Set your width here
              },
            },
          }}
        >
          <ReadingModal
            open={openModal.open}
            close={() => setOpenModal({ open: false, modalName: openModal.modal, data: openModal.data })}
            selected={selected}
            getList={getList}
          />
        </Dialog>
      }
    </UserLayout>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default UserReading;