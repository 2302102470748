/*****************************************************************************************
 * 설명 : 로그인
*****************************************************************************************/
import { Button } from "@mui/material";
import { useFormik } from 'formik';
import { useEffect } from "react";
import { Cookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from "yup";

import { COOKIE_NAME, MESSAGE_DELAY } from "config/config";
import { setUserInfo } from 'reducers/userReducer';

import { CheckBoxEx, InputEx } from 'components/inputEx';
import alertMsg from "components/message";

import { loginApi } from 'service/member';
import Restful from "service/restful";

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
function Login(props) {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const dispatch = useDispatch();
  const user = useSelector(state => state?.userInfo?.user);
  const cookies = new Cookies();

  const [getApi] = Restful();

  const formik = useFormik({
    initialValues: {
      userId: '',
      userPw: '',
      saveId: false
    },
    validationSchema: Yup.object().shape({
      userId: Yup.string().min(4, '최소4자').max(20, "20자리").required('필수'),
      userPw: Yup.string().min(4, '최소4자').max(20, "20자리").required('필수'),
      saveId: Yup.boolean().required('필수')
    }),
    validator: () => ({}),
    onSubmit: (values) => {
      const loginProcess = (response) => {
        if( response !== undefined && response.data.result ) {
          // 아이디 저장
          if (values.saveId) cookies.set(COOKIE_NAME, values.userId);
          else cookies.remove(COOKIE_NAME);

          dispatch(setUserInfo(response.data.data));

          // admin 구분
          // 계정별 첫페이지 주소

          if( parseInt(response.data.data?.isAdmin) === 1 ) {
            // 관리자 접속 권한이 없을 경우
            if( response.data.data1 && response.data.data1.length < 1 )
              props.history('/search');
            else {
              let tmp = response.data.data1?.filter((item) => {
                if( item.linkUrl !== '' ) return true;
                else return false;
              })
              props.history(tmp[0].linkUrl);
            }
          } else
          //else if( response.data.data?.isAdmin !== 1 && formik.values.userId !== 'admin')
            props.history('/search');

          return;

        } else {
          alertMsg(response?.data?.message || '서버와의 통신에 실패하였습니다.', 'E', MESSAGE_DELAY);

          dispatch(setUserInfo({}));
          return;
        }
      }

      const uri = window.location.pathname;
      let paramsUri = uri.split(/\//gi);

      if( paramsUri[1].indexOf('admin') !== 0 && paramsUri[1].indexOf('user') !== 0  )
        paramsUri = '/';
      else
        paramsUri = paramsUri[1];

      loginApi(getApi, {
        userId: values.userId,
        password: values.userPw,
        domain: window.location.hostname,
        uri: paramsUri
      }, loginProcess);
    }
  });

  /***************************************************************************************
   * 설명 : 아이디 저장 체크
  ***************************************************************************************/
  useEffect(() => {
    const id = cookies.get(COOKIE_NAME);

    if( id !== undefined && id !== 'undefined' ) {
      formik.setFieldValue('userId', id);
      formik.setFieldValue('saveId', true);
    }

    // eslint-disable-next-line
  }, []);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <>
      <form className="Login" onSubmit={formik.handleSubmit}>
        <section className="login-wrap">
          <div className="login-logo"></div>
          <div className="com-name">설계정보관리시스템</div>

          <div className="inputbox">
            <InputEx
              name="userId"
              formik={formik}
              placeholder="ID"
              inputProps={{focus: "true"}}
              fullWidth={true}
              className="inputbox"
            />
          </div>

          <div className="inputbox mt10">
            <InputEx
              type="password"
              name="userPw"
              formik={formik}
              placeholder="Password"
              fullWidth={true}
              className="inputbox"
            />
          </div>

          <CheckBoxEx
            name="saveId"
            color="default"
            formik={formik}
            className="idsave checkBox"
            label="아이디 저장"
          />

          <Button
            type="submit"
            className="login-btn btn"
          >로그인</Button>
        </section>

        <footer className="footer-txt">Copyright ⓒ 2023 by dA Group Urban Design & Architecture Co., Ltd. All Rights Reserved.</footer>
      </form>
    </>
  )
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default Login;