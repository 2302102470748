/*****************************************************************************************
 * 설명 : 관리자 > 시스템관리 > 권한그룹관리
*****************************************************************************************/
import Dialog from '@mui/material/Dialog';
import Modal from '@mui/material/Modal';
import { AgGridReact } from 'ag-grid-react';
import { useFormik } from 'formik';
import 'moment/locale/ko';
import { useEffect, useState } from 'react';
import SplitPane from "react-split-pane";

import AlertDialog from 'components/alertDiolog';
import BtnAgGridSave from 'components/btnAgGridSave';
import ChipEx from 'components/chipEx';
import { ButtonEx } from 'components/inputEx';
import alertMsg from 'components/message';
import customTooltipAgGrid from 'components/tooltipAgGrid';

import { MESSAGE_DELAY } from 'config/config';
import useGridConfig from 'hooks/useGridConfig';

import { deleteAuthGroupApi, getAuthGroupListApi } from 'service/auth';
import Restful from 'service/restful';
import { PaperComponent, selectGrid } from 'service/utils';

import Layout from '../../../layout/layout';
import AuthGroupCopyModal from './groupCopyModal';
import AuthGroupModal from './groupModal';
// import ProjectAuth from '../../project/auth/group/tabGroupProject';
import MenuAuth from './tabGroupMenu';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
function AuthGroup(props) {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [getApi] = Restful();

  const [gridApi, setGridApi] = useState({});

  const [authgroupSeq, setAuthgroupSeq] = useState('');
  const [authList, setAuthList] = useState([]);

  const [selected, setSelected] = useState([]);

  const [openModal, setOpenModal] = useState({open: false, modal: 'add', data: []});

  const formik = useFormik({
    initialValues: {
      siteSeq: null,
    },
    onSubmit: (values) => {
      //onRowClickedGroup();
    }
  });

  // 권한그룹 컬럼 선언
  const [columnDefsGroup, setColumnDefsGroup] = useState([
    { headerName: '', field: 'seq', cellClass: 'cp', width: 36, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
    { headerName: '구분', field: 'isAdmin', width: 65, cellClass: 'cp center',
      cellRendererFramework: function(params) {
        if( parseInt(params.data.isAdmin) === 1 )
          return <ChipEx color="primary" variant="outlined" size="small" label="관리자" />;
        else
          return <ChipEx color="success" variant="outlined" size="small" label="사용자" />;
      }
    },
    { headerName: '권한명', field: 'authGroupName', width: 130, cellClass: 'cp' },
    { headerName: '사용여부', field: 'isUse', width: 75, cellClass: 'cp center',
      cellRendererFramework: function(params) {
        if( parseInt(params.data.isUse) === 1 )
          return <ChipEx color="success" variant="outlined" size="small" label="사용" />;
        else
          return <ChipEx color="default" variant="outlined" size="small" label="사용안함" />;
      }
    }
  ]);

  // 권한그룹 데이터
  const [groupList] = useState([
    {
      groupName: '비회원'
    },
    {
      groupName: '일반회원'
    },
    {
      groupName: '직원'
    },
    {
      groupName: '관리자'
    },
    {
      groupName: '시스템관리자'
    },
    {
      groupName: '업체관리자'
    },

  ]);
  const [gridConfigGroup, setGridUpdateGroup] = useGridConfig(1, setColumnDefsGroup);

  /***************************************************************************************
   * 설명 : 권한 그룹 셀 클릭 이벤트
  ***************************************************************************************/
  const onRowClickedGroup = (seq) => {
    setAuthgroupSeq(seq);

    /*
    let params = {
      program: 'api',
      service: 'cms',
      action: 'getAuthGroupInfo',
      version: '1.0',
      seq: seq,
      siteSeq: formik.values.siteSeq
    }

    getApi("get", params).then( response => {
      if( response !== undefined && response.data.result ) {
        setAuthPageList(response.data.data);
      } else {
        setAuthPageList([]);
      }
    })
    */
  }

  /***************************************************************************************
   * 설명 : 권한그룹 삭제
  ***************************************************************************************/
  const deleteAuthGroup = () => {
    let nodes = gridApi.getSelectedRows();

    if( nodes.length < 1 ) {
      alertMsg("삭제할 공통코드를 선택하시기 바랍니다.", "W", MESSAGE_DELAY);
      return;
    }

    let params = nodes.map((item) => {
      return {seq: item.seq}
    });

    deleteAuthGroupApi(getApi, params, (response) => {
      if( response !== undefined && response.data.result ) {
        alertMsg(response.data.message, "S", MESSAGE_DELAY);

        getAuthGroupList();

      } else {
        alertMsg(response.data.message || '서버와의 통신에 실패하였습니다.', "E", MESSAGE_DELAY);
      }
    })
  }

  /***************************************************************************************
   * 설명 : 권한그룹 정보 가져오기
  ***************************************************************************************/
  const getAuthGroupList = () => {
    getAuthGroupListApi(getApi, (response) => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        setAuthList(response.data.data);
      } else {
        setAuthList([]);
      }
    });
  }

  /***************************************************************************************
   * 설명 : 권한 그룹 새로 고침 시 자동 선택
  ***************************************************************************************/
  useEffect(() => {
    if( authList.length > 0 ) {
      selectGrid(gridApi, selected, setSelected, false);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authList]);

  /***************************************************************************************
   * 설명 : Step - 2, 그룹 리스트 변경 시 페이지 데이터 가져오기
  ***************************************************************************************/
  useEffect(() => {
    if( groupList.length > 0 ) {
      onRowClickedGroup(authgroupSeq);
    }

    // eslint-disable-next-line
  }, [formik.values.siteSeq])

  /***************************************************************************************
   * 설명 : 권한 그룹 리스트 가져오기
  ***************************************************************************************/
  useEffect( () => {
    getAuthGroupList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <Layout>
      <section className="admin-cms-contents system-page">
        <SplitPane split="vertical" minSize={250} defaultSize={320}>
          <div className="mr10">
            <header className="admin-cms-search-header">
              <div className="left">
                <div className="fl">
                  <BtnAgGridSave className="fl" click={setGridUpdateGroup}></BtnAgGridSave>
                </div>
                <div className="fl">
                  <h4 className="f15 sub-title">권한그룹</h4>
                </div>
              </div>
              <div className="text-right">
                <ButtonEx
                  title="복사"
                  auth="isModify"
                  color="inherit"
                  variant="outlined"
                  className="Btn"
                  onClick={() => {
                    if( selected.length < 1 ) {
                      alertMsg("복사할 권한그룹을 선택하시기 바랍니다.", "W", MESSAGE_DELAY);
                      return;
                    }

                    setOpenModal({open: true, modal: 'copy', data: selected[0]});
                  }}
                />

                <ButtonEx
                  title="삭제"
                  auth="isDelete"
                  color="error"
                  variant="outlined"
                  className="ml5"
                  onClick={() => {
                    // deleteCommon();
                    setOpenModal({open: true, modal: 'dialog', data: {}});
                  }}
                />

                <ButtonEx
                  title="추가"
                  auth="isWrite"
                  color="primary"
                  className="ml5"
                  variant="contained"
                  onClick={() => {
                    setOpenModal({open: true, modal: 'add', data: {}})
                  }}
                />
              </div>
            </header>

            <section className="ag-theme-balham grid mt10 pad-height165" style={{height:'calc(100vh - 130px)'}}>
              <div style={{ height: '100%' }}>
                <AgGridReact
                  defaultColDef={{
                    sortable: true,
                    resizable: true,
                    filter: false,
                    lockVisible: true,
                    tooltipComponent: customTooltipAgGrid,
                  }}
                  tooltipShowDelay={0}
                  tooltipHideDelay={2000}
                  rowSelection={'single'}
                  columnDefs={columnDefsGroup}
                  rowData={authList}
                  onGridReady={(event) => {
                    gridConfigGroup(event.columnApi);
                    setGridApi(event.api);
                  }}
                  onFirstDataRendered={(event) => {
                    event.api.forEachNode((item, index) => {
                      if(index === 0) {
                        item.setSelected(true);
                        setSelected([item.data]);
                        onRowClickedGroup(item.data.seq);
                      }
                    })
                  }}
                  onRowClicked={(event) => {
                    let nodes = event.api.getSelectedRows();
                    setSelected(nodes);
                  }}
                  onRowDoubleClicked={(event) => {
                    setOpenModal({open: true, modal: 'add', data: event.data})
                  }}
                  rowDragManaged={true}
                  rowDragMultiRow={true}
                  animateRows={true}
                  overlayNoRowsTemplate = "검색된 내용이 없습니다."
                />
              </div>
            </section>
          </div>
          <div className="Tab-page">
            <div className="tab-contents">
              <MenuAuth
                tabIndex={'1'}
                selected={selected}
              />
            </div>
          </div>
        </SplitPane>
      </section>

      { openModal.open && openModal.modal === 'add' &&
        <Dialog
          open={openModal}
          onClose={() => (event, reason) => {
            if(reason && reason === "backdropClick") return;
            setOpenModal({open: false, modal: openModal.modal, data: {}});
          }}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "500px",  // Set your width here
              },
            },
          }}
        >
          <section className="modal">
            <AuthGroupModal
              open={openModal.open}
              close={() => setOpenModal({ open: false, modalName: 'add', data: openModal.data })}
              data={openModal.data}
              list={authList}
              getList={getAuthGroupList}
              indicator={props.indicator}
              history={props.history}
            />
          </section>
        </Dialog>
      }

      { openModal.open && openModal.modal === 'copy' &&
        <Dialog
          open={openModal}
          onClose={() => (event, reason) => {
            if(reason && reason === "backdropClick") return;
            setOpenModal({open: false, modal: openModal.modal, data: {}});
          }}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "500px",  // Set your width here
              },
            },
          }}
        >
          <section className="modal">
            <AuthGroupCopyModal
              open={openModal.open}
              close={() => setOpenModal({ open: false, modalName: openModal.modal, data: openModal.data })}
              data={openModal.data}
              getList={getAuthGroupList}
              list={authList}
              setSelected={setSelected}
              indicator={props.indicator}
              history={props.history}
            />
          </section>
        </Dialog>
      }

      { openModal.open && openModal.modal === 'dialog' &&
        <Modal
          open={openModal.open}
          onClose={() => setOpenModal({ open: false, modalName: openModal.modal, data: openModal.data })}
        >
          <AlertDialog
            open={openModal.open}
            close={() => setOpenModal({ open: false, modalName: openModal.modal, data: openModal.data })}
            title="권한그룹 삭제"
            message={`선택하신 권한그룹을 삭제하시겠습니까?`}
            confirm="삭제"
            onClick={deleteAuthGroup}
            indicator={props.indicator}
            history={props.history}
          />
        </Modal>
      }
    </Layout>
  )
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default AuthGroup;