/*****************************************************************************************
 * 설명 : 관리자 > 컨텐츠관리 > 사이트 관리
 * URI : /admin/site
*****************************************************************************************/
import Modal from '@mui/material/Modal';
import { AgGridReact } from 'ag-grid-react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import BtnAgGridSave from 'components/btnAgGridSave';
import ChipEx from 'components/chipEx';
import { BtnSearch } from 'components/common';
import { ButtonEx } from 'components/inputEx';
import alertMsg from 'components/message';
import customTooltipAgGrid from 'components/tooltipAgGrid';

import { deleteSiteInfoApi, getSiteInfoApi } from 'service/cms';
import Restful from "service/restful";
import { comma } from 'service/utils';

import { MESSAGE_DELAY } from 'config/config';

import useGridConfig from 'hooks/useGridConfig';

import AlertDialog from 'components/alertDiolog';
import SiteModal from 'pages/admin/cms/siteModal';
import Layout from 'pages/layout/layout';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const Site = ( props ) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [gridApi, setGridApi] = useState({});
  const [getApi] = Restful();

  const [count] = '1';

  const currentMenu = useSelector(state => state?.menuList?.current);

  const [list, setList] = useState([]);

  const [openModal, setOpenModal] = useState({open: false, modal: 'add', data: []});

  // table column
  const [columnDefs, setColumnDefs] = useState([
    { headerName: '', field: 'seq', width: 40, cellClass: 'center', flex: 0,
      headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
    { headerName: '번호', field: 'index', width: 55, cellClass: 'cp text-right',
      valueGetter: (params) => {
        return comma(params.node.rowIndex + 1);
      }
    },
    { headerName:'사이트명', field: 'siteName', width: 300, cellClass: 'cp', tooltipField: 'siteName' },
    { headerName:'도메인', field: 'domain', width: 300, cellClass: 'cp', tooltipField: 'domain' },
    { headerName:'URI', field: 'uri', width: 300, cellClass: 'cp', tooltipField: 'uri' },
    { headerName: '사용여부', field: 'isUse', width: 100, cellClass: 'cp center',
      cellRendererFramework: function(params) {
        if( params.data.isUse === '1' || params.data.isUse === 1 )
          return <ChipEx color="success" variant="outlined" size="small" label="사용" />;
        else
          return <ChipEx color="default" variant="outlined" size="small" label="사용안함" />;
      }
    },
    {headerName: '수정', field: 'modify', cellClass: 'cp center', width: 100,
      cellRendererFramework: function (params) {
        return <ButtonEx title="수정" auth="isModify" color="secondary" variant="outlined" className="gridBtn" onClick={() => setOpenModal({open: true, modal: 'add', data: params.data})} />;
      }
    }
  ]);

  const [gridConfig, setGridUpdate] = useGridConfig(1, setColumnDefs);

  /***************************************************************************************
   * 설명 : 사이트 리스트 가져오기
  ***************************************************************************************/
  const getSiteList = () => {
    getSiteInfoApi(getApi, (response) => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        setList(response.data.data);
      } else {
        setList([]);
      }
    });
  }

  /***************************************************************************************
   * 설명 : 사이트 삭제
  ***************************************************************************************/
  const deleteSite = () => {
    let nodes = gridApi.getSelectedRows();

    if( nodes.length < 1 ) {
      alertMsg("삭제할 사이트를 선택하시기 바랍니다.", "W", MESSAGE_DELAY);
      return;
    }

    if( ! window.confirm("선택한 사이트를 삭제하시겠습니까?") ) return;

    let params = nodes.map((item) => {
      return {seq: item.seq}
    });

    deleteSiteInfoApi(getApi, params, (response) => {
      if( response !== undefined && response.data.result ) {
        alertMsg(response.data.message, "S", MESSAGE_DELAY);

        getSiteList();

      } else {
        alertMsg(response.data.message || '서버와의 통신에 실패하였습니다.', "E", MESSAGE_DELAY);
      }
    })
  }

  /***************************************************************************************
   * 설명 : 데이터 처리
  ***************************************************************************************/
  useEffect(() => {
    getSiteList();

    // eslint-disable-next-line
  }, []);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <Layout>
      <div className="admin-cms-contents">
        <header className="admin-cms-search-header">
          <div className="left">
            <BtnSearch click={() => getSiteList()}></BtnSearch>
            <BtnAgGridSave click={setGridUpdate}></BtnAgGridSave>
          </div>

          <div className="text-right">
            <ButtonEx
              color="error"
              variant="outlined"
              auth="isDelete"
              title="사이트삭제"
              onClick={() => {
                // deleteSite()
                setOpenModal({open: true, modal: 'dialog', data: {}})
              }}
            />

            <ButtonEx
              color="secondary"
              className="ml5"
              variant="outlined"
              auth="isWrite"
              title="사이트등록"
              onClick={() => {
                setOpenModal({open: true, modal: 'add', data: {}})
              }}
            />
          </div>
        </header>

        <section className="admin-cms-body">
          <section className="ag-theme-balham grid" style={{height:' calc(100% - 50px)'}}>
            <AgGridReact
              defaultColDef={{
                sortable: true,
                resizable: true,
                filter: false,
                lockVisible: true,
                tooltipComponent: customTooltipAgGrid,
              }}
              tooltipShowDelay={0}
              tooltipHideDelay={2000}
              rowSelection = {'multiple'}
              columnDefs={columnDefs}
              rowData={list}
              onGridReady={(event) => {
                setGridApi(event.api);
                gridConfig(event.columnApi);
              }}
              onRowDoubleClicked={(event) => {
                if( currentMenu?.authModify === '1' || currentMenu?.authModify === 1 )
                  setOpenModal({open: true, modal: 'add', data: event.data});
              }}
              overlayNoRowsTemplate = "검색된 내용이 없습니다."
            />
          </section>
        </section>
      </div>

      { openModal.open && openModal.modal === 'add' &&
        <Modal
          open={openModal.open}
          onClose={() => setOpenModal({ open: false, modalName: 'add', data: openModal.data })}
        >
          <SiteModal
            open={openModal.open}
            close={() => setOpenModal({ open: false, modalName: 'add', data: openModal.data })}
            data={openModal.data}
            getList={getSiteList}
            indicator={props.indicator}
            history={props.history}
          />
        </Modal>
      }

      { openModal.open && openModal.modal === 'dialog' &&
        <Modal
          open={openModal.open}
          onClose={() => setOpenModal({ open: false, modalName: 'dialog', data: openModal.data })}
        >
          <AlertDialog
            open={openModal.open}
            close={() => setOpenModal({ open: false, modalName: 'dialog', data: openModal.data })}
            title="사이트 삭제"
            message={`선택하신 ${count}개의 "사이트"를 삭제하시겠습니까?`}
            confirm="삭제"
            onClick={deleteSite}
            getList={getSiteList}
            indicator={props.indicator}
            history={props.history}
          />
        </Modal>
      }
    </Layout>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default Site;