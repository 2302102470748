/**
 * * 권한그룹 리스트 가져오기
 * @param {*} getApi
 * @param {*} func
 * @returns
 */
export function getAuthGroupListApi(getApi, func) {
  if( ! getApi ) return;

  getApi("get", '/api/v1/auth/group').then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  })
}

/**
 * * 사용자 권한그룹 설정
 * @param {*} getApi
 * @param {*} func
 * @returns
 */
export function setAuthGroupUserApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("post", '/api/v1/auth/group/user', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  })
}


/**
 * * 권한그룹 별 메뉴 권한 리스트 가져오기
 * @param {*} getApi
 * @param {*} func
 * @returns
 */
export function getAuthListApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("get", '/api/v1/auth/list', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  })
}

/**
 * * 권한그룹 별 프로젝트 권한 리스트 가져오기
 * @param {*} getApi
 * @param {*} func
 * @returns
 */
export function getAuthProjectListApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("get", '/api/v1/auth/project', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  })
}

/**
 * * 권한그룹 추가/수정
 * @param {*} getApi
 * @param {*} func
 * @returns
 */
export function setAuthGroupApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("post", '/api/v1/auth/group', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  })
}

/**
 * * 권한그룹 복사
 * @param {*} getApi
 * @param {*} func
 * @returns
 */
export function setAuthGroupCopyApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("post", '/api/v1/auth/group/copy', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  })
}


/**
 * * 권한그룹 정보 삭제
 * @param {*} getApi
 * @param {*} params
 * @param {*} func
 * @returns
 */
export function deleteAuthGroupApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("post", '/api/v1/auth/group/delete', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  });
}

/**
 * * 권한그룹 설정 저장
 * @param {*} getApi
 * @param {*} params
 * @param {*} func
 * @returns
 */
export function setAuthGroupSaveApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("post", '/api/v1/auth/group/save', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  });
}
