/**
 * * 대시보드 정보 가져오기
 * @param {*} getApi
 * @param {*} params
 * @param {*} func
 * @returns
 */
export function getDashboardInfoApi(getApi, func) {
  if( ! getApi ) return;

  getApi("get", '/api/v1/dashboard/info').then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  })
}