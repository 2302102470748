/*****************************************************************************************
 * 설명 : 관리자 > 로그조회 > 그룹웨어 동기화
*****************************************************************************************/
import Modal from '@mui/material/Modal';
import { AgGridReact } from 'ag-grid-react';
import { useFormik } from 'formik';
import moment from 'moment';
import { useEffect, useState } from 'react';
import * as Yup from "yup";

import AlertDialog from 'components/alertDiolog';
import BtnAgGridSave from 'components/btnAgGridSave';
import ChipEx from 'components/chipEx';
import { BtnRefresh, BtnSearch, CustomPagenation } from 'components/common';
import { ButtonEx, InputEx } from 'components/inputEx';
import alertMsg from 'components/message';
import customTooltipAgGrid from 'components/tooltipAgGrid';

import { getGroupwareLogApi } from 'service/logs';
import Restful from 'service/restful';
import { getGroupwareSyncApi } from 'service/sync';
import { comma } from 'service/utils';

import { MESSAGE_DELAY } from 'config/config';
import useGridConfig from 'hooks/useGridConfig';

import Layout from 'pages/layout/layout';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const GroupwareSync = ( props ) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [getApi] = Restful();

  const [openModal, setOpenModal] = useState({open: false, modal: 'sync', data: []});

  const [list, setList] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const initFormik = {
    startDate: moment().add(-1, 'year').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
    isSuccess: 0,
    pageNo: 1,
    pageRow: 100
  }

  const formik = useFormik({
    initialValues: initFormik,
    validationSchema: Yup.object().shape({
    }),
    onSubmit: (values) => {
      getList();
    }
  });

  // table column
  const [columnDefs, setColumnDefs] = useState([
    { headerName: '', field: 'seq', width: 40, cellClass: 'cp center', flex: 0,
      headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
    { headerName: '번호', field: 'rowIndex', width: 40, cellClass: 'cp text-right',
      valueGetter: (params) => {
        return comma(params.node.rowIndex + 1);
      }
    },
    { headerName:'상태', field: 'isSuccess', width: 60, cellClass: 'cp center',
      cellRendererFramework: function(params) {
        if( parseInt(params.data.isSuccess) === 1 )
          return <ChipEx color="success" variant="outlined" size="small" label="성공" />;
        else
          return <ChipEx color="error" variant="outlined" size="small" label="실패" />;
      }
    },
    { headerName:'일시', field: 'syncDate', width: 160, cellClass: 'cp center' },
    { headerName:'작업자', field: 'creatorName', width: 100, cellClass: 'cp center' },
    { headerName:'실패 내역', field: 'error', width: 130, cellClass: 'cp', flex: 1, tooltipField: 'error' },
  ]);


  const [gridConfig, setGridUpdate] = useGridConfig(1, setColumnDefs);

  /***************************************************************************************
   * 설명 : PMS 동기화 로그 가져오기
  ***************************************************************************************/
  const getList = () => {
    let params = {...formik.values}

    getGroupwareLogApi(getApi, params, (response) => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        setList(response.data.data);

        let totalCount = (response.data.data1 || 0);
        let totalPage = totalCount / formik.values.pageRow;
        if(totalCount % formik.values.pageRow > 0 ) totalPage = totalPage + 1;
        formik.setFieldValue('totalPage', totalPage);
        formik.setFieldValue('totalCount', totalCount);

      } else {
        setList([]);
      }
    });
  }

  /***************************************************************************************
   * 설명 : 그룹에어 수동 동기화
  ***************************************************************************************/
  const getSyncProcess = () => {
    getGroupwareSyncApi(getApi, (response) => {
      if( response !== undefined && response.data.result ) {
        alertMsg(response.data.message, "S", MESSAGE_DELAY);

        getList();

      } else {
        alertMsg(response.data.message || '서버와의 통신에 실패하였습니다.', "E", MESSAGE_DELAY);
      }
    })
  }

  /***************************************************************************************
   * 설명 : 전체, 성공, 실패 변경시 검색 처리
  ***************************************************************************************/
  useEffect(() => {
    if( isLoading )
      getList();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.isSuccess, formik.values.pageNo]);

  /***************************************************************************************
   * 설명 : 데이터 처리
  ***************************************************************************************/
  useEffect(() => {
    // getProjectYear();

    getList();

    setIsLoading(true);

    // eslint-disable-next-line
  }, []);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <Layout>
      <section className="admin-cms-contents log-page">
        <form onSubmit={formik.handleSubmit}>
          <header className="admin-cms-search-header mb10">
            <div className="left">
              <div className='fl'>
                <BtnRefresh click={() => {
                  formik.setValues(initFormik);
                  formik.handleSubmit();
                }}></BtnRefresh>
                <BtnSearch></BtnSearch>
                <BtnAgGridSave click={setGridUpdate}></BtnAgGridSave>
              </div>
              <div className='fl'>
                <InputEx
                  type="date"
                  name="startDate"
                  formik={formik}
                  fullWidth={false}
                  label="검색시작일"
                  style={{width: '125px'}}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />

                <span className="ml5 mr5 lh28px">~</span>

                <InputEx
                  type="date"
                  name="endDate"
                  formik={formik}
                  fullWidth={false}
                  label="검색종료일"
                  style={{width: '125px'}}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              {/*
              <div className='fl ml5'>
                <ToggleButtonGroupEx
                  name="isSuccess"
                  exclusive={true}
                  formik={formik}
                  className="search-toggle-btn"
                  data={[
                    {label: '전체', value: 0},
                    {label: '성공', value: 1},
                    {label: '실패', value: 2},
                  ]}
                />
              </div>
              */}
            </div>
            <div className="text-right">
              <div className="fr">
                <ButtonEx
                  title="그룹웨어 수동동기화"
                  auth="isWrite"
                  color="secondary"
                  className="ml5"
                  variant="outlined"
                  onClick={() => setOpenModal({open: true, modal: 'dialog', data: {}})}
                />
              </div>
            </div>
          </header>
        </form>

        <section className="ag-theme-balham grid pad-height220" style={{height:'calc(100vh - 180px)'}}>
          <AgGridReact
            defaultColDef={{
              sortable: true,
              resizable: true,
              filter: false,
              lockVisible: true,
              tooltipComponent: customTooltipAgGrid,
            }}
            tooltipShowDelay={0}
            tooltipHideDelay={2000}
            rowSelection = {'multiple'}
            columnDefs={columnDefs}
            rowData={list}
            onGridReady={(event) => {
              gridConfig(event.columnApi);
            }}
            rowDragManaged={true}
            rowDragMultiRow={true}
            animateRows={true}
            overlayNoRowsTemplate = "검색된 내용이 없습니다."
          />
        </section>

        <section className="pagination mt10">
          <div className="fl">
            <CustomPagenation
              currPage={{pageNo: formik.values.pageNo, pageRow: formik.values.pageRow}}
              totalPage={formik.values.totalPage}
              change={(event, pageNo) => {
                formik.setFieldValue("pageNo", pageNo);
              }}
            ></CustomPagenation>
          </div>
          <div className="fr f12">
            검색 :<span className="ml10">{comma(formik.values.totalCount)}</span>
          </div>
        </section>
      </section>

      { openModal.open && openModal.modal === 'dialog' &&
        <Modal
          open={openModal.open}
          onClose={() => setOpenModal({ open: false, modalName: 'dialog', data: openModal.data })}
        >
          <AlertDialog
            open={openModal.open}
            close={() => setOpenModal({ open: false, modalName: 'dialog', data: openModal.data })}
            title="그룹웨어 수동 동기화"
            message={`그룹웨어의 사용자 및 부서 정보를 동기화 하시겠습니까?`}
            color="primary"
            confirm="동기화"
            onClick={getSyncProcess}
            indicator={props.indicator}
            history={props.history}
          />
        </Modal>
      }
    </Layout>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default GroupwareSync;