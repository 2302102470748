import React from 'react';
import { toast } from "react-toastify";

const customTitle = (msg, className, title) => (
  <>
    <div className={className + " toastify-title"}>{title}</div>
    <div className="toastify-message">{msg}</div>
  </>
);

/*****************************************************************************************
 * 설명 : 함수 선언
 * 입력값 : message = 표시 내용
 *          type = W : WARNING, I : INFORMATION, S : SUCCESS, E : ERROR,
 *                 P : PRIMARY, DG : Dark Gray, LG : Light Gray, B : BLACK ==========> customTitle 적용
 *          autoClose = 자동 닫힘 시간
 *          title = 커스텀 타이틀 메시지
 * 리턴값 : 없음
 *****************************************************************************************/
export const alertMsg = (
  msg,
  msgType = "S",
  autoClose = 5000,
  title = "알림"
) => {
  if (msg === "") {
    return false;
  }

  const options = {
    position: toast.POSITION.RIGHT_BOTTOM,
    autoClose: 3000,

    closeOnClick: true,
    pauseOnHover: false,
  };

  switch (msgType.toUpperCase()) {
    case "W":
      toast.warn(customTitle(msg, "toastify-warning", "경고"), options);
      break;
    case "E":
      toast.error(customTitle(msg, "toastify-error", "에러"), options);
      break;
    case "I":
      toast.info(customTitle(msg, "toastify-information", "알림"), options);
      break;
    case "P":
      toast.info(customTitle(msg, "toastify-primary", title), options);
      break;
    case "DG":
      toast.info(customTitle(msg, "toastify-darkgray", title), options);
      break;
    case "LG":
      toast.info(customTitle(msg, "toastify-lightgray", title), options);
      break;
    case "B":
      toast.info(customTitle(msg, "toastify-black", title), options);
      break;
    default:
      toast.success(customTitle(msg, "toastify-success", "성공"), options);
      break;
  }
};

/*****************************************************************************************
 * 설명 : 디폴트 선언
 *****************************************************************************************/
export default alertMsg;
