/**
 * 게시판 정보 리스트 가져오기
 * @param {*} getApi
 * @param {*} params
 * @param {*} func
 * @returns
 */
export function getBoardInfoListApi(getApi, func) {
  if( ! getApi ) return;

  getApi("get", '/api/v1/board/info').then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  });
}

/**
 * 게시판 테이블 정보 리스트 가져오기
 * @param {*} getApi
 * @param {*} params
 * @param {*} func
 * @returns
 */
export function getBoardTableListApi(getApi, func) {
  if( ! getApi ) return;

  getApi("get", '/api/v1/board/table').then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  });
}

/**
 * * 게시판 테이블 추가
 * @param {*} getApi
 * @param {*} params
 * @param {*} func
 * @returns
 */
export function setBoardTableApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("post", '/api/v1/board/table', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  });
}

/**
 * * 게시판 정부 추가/수정
 * @param {*} getApi
 * @param {*} params
 * @param {*} func
 * @returns
 */
export function setBoardInfoApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("post", '/api/v1/board/info', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  });
}

/**
 * * 게시판 정보 삭제
 * @param {*} getApi
 * @param {*} params
 * @param {*} func
 * @returns
 */
export function deleteBoardInfoApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("post", '/api/v1/board/info/delete', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  });
}