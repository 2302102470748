/*****************************************************************************************
 * 설명 : 관리자 > 컨텐츠관리 > 모듈 관리 > 추가/수정
 * URI : /admin/module
*****************************************************************************************/
import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import * as Yup from "yup";

import Restful from "service/restful";
import { getSiteInfoApi, setModuleApi } from 'service/cms';
import { getSelectData } from 'service/utils';

import { SelectEx, InputEx, ToggleButtonGroupEx } from 'components/inputEx';
import alertMsg from 'components/message';
import { MESSAGE_DELAY } from 'config/config';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
export const ModuleModal = ( props ) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [getApi] = Restful();

  const [title, setTitle] = useState('추가');

  const [siteList, setSiteList] = useState([]);

  const formik = useFormik({
    initialValues: {
      seq: '',
      siteSeq: null,
      module: '',
      moduleName: '',
      modulePath: '',
      isUse: 1
    },
    validationSchema: Yup.object().shape({
      module: Yup.string().max(50, "50자리").required('필수'),
      moduleName: Yup.string().max(50, "50자리").required('필수'),
      modulePath: Yup.string().max(100, "100자리").required('필수')
    }),
    onSubmit: (values) => {
      setModuleApi(getApi, values, (response => {
        if( response !== undefined && response.data.result ) {
          alertMsg(response.data.message, "S", MESSAGE_DELAY);

          if( props.getList ) props.getList();

          props.close();

        } else {
          alertMsg(response.data.message || '서버와의 통신에 실패하였습니다.', "E", MESSAGE_DELAY);
        }
      }));
    }
  });

  /***************************************************************************************
   * 설명 : 사이트 리스트 가져오기
  ***************************************************************************************/
  const getSiteList = () => {
    getSiteInfoApi(getApi, (response) => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        setSiteList(getSelectData(response.data.data, 'siteName', 'seq'));
      } else {
        setSiteList([]);
      }
    });
  }

  /***************************************************************************************
   * 설명 : 모달 오픈 시 처리
  ***************************************************************************************/
  useEffect(() => {
    if( props.open ) {
      getSiteList();

      if( props.data.seq !== undefined ) {
        formik.setValues(props.data);

        setTitle('수정');
      } else {
        formik.setFieldValue("siteSeq", props.data.siteSeq);

        setTitle('추가');
      }
    }

    // eslint-disable-next-line
  }, [props.open]);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <section className="modal">
      <section className="modal-wrap" style={{ width: '750px' }}>
        <form onSubmit={formik.handleSubmit}>
          <header className="modal-header">
            <div className="modal-title">모듈 {title}</div>
            <Button className="close-btn" onClick={props.close}>
              <CloseIcon style={{ color: '#fff', fontSize: '28px' }}></CloseIcon>
            </Button>
          </header>

          <section className="modal-body">
            <table className="input-table">
              <colgroup>
                <col style={{width: '25%'}} />
                <col style={{width: '75%'}} />
              </colgroup>

              <tbody>
                <tr>
                  <th scope="row"><label htmlFor="siteSeq">사이트</label></th>
                  <td>
                    <SelectEx
                      name="siteSeq"
                      formik={formik}
                      fullWidth={true}
                      data={[
                        {label: '공통', value: ''}
                      ].concat(siteList)}
                    />
                  </td>
                </tr>
                <tr>
                  <th scope="row"><label htmlFor="module">모듈</label></th>
                  <td>
                    <InputEx
                      name="module"
                      formik={formik}
                      fullWidth={true}
                      focus={true}
                    />
                    <span className="ml10 lh38px">
                      ※ 대소문자 주의(첫글자 대문자), 정확한 모듈명
                    </span>
                  </td>
                </tr>
                <tr>
                  <th scope="row"><label htmlFor="moduleName">모듈명</label></th>
                  <td>
                    <InputEx
                      name="moduleName"
                      formik={formik}
                      fullWidth={true}
                    />
                    <span className="ml10 lh38px">
                      ※ 모듈의 명칭으로 구분에만 사용됨
                    </span>
                  </td>
                </tr>
                <tr>
                  <th scope="row"><label htmlFor="modulePath">모듈경로</label></th>
                  <td>
                    <InputEx
                      name="modulePath"
                      formik={formik}
                      fullWidth={true}
                    />
                    <span className="ml10 lh38px">
                      ※ src 경로 아래 지정
                    </span>
                  </td>
                </tr>
                <tr>
                  <th scope="row"><label htmlFor="isUse">사용여부</label></th>
                  <td>
                    <ToggleButtonGroupEx
                      name="isUse"
                      exclusive={true}
                      formik={formik}
                      data={[
                        {label: '사용', value: 1},
                        {label: '사용안함', value: 0},
                      ]}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </section>

          <footer className="modal-footer">
            <div className="modal-footer-center">
              <Button
                color="inherit"
                variant="outlined"
                className="Btn"
                onClick={() => {
                  props.close();
                }}
              >창닫기</Button>

              <Button
                type="submit"
                variant="contained"
                color="primary"
                className="Btn ml10"
              >저장하기</Button>
            </div>
          </footer>
        </form>
      </section>
    </section>
  )
}

export default ModuleModal;