/*****************************************************************************************
 * 설명 : 관리자 > 시스템관리 > 논리스토리지 관리 > 파일 추가
 * URI : /basic/engineCode
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import { AgGridReact } from 'ag-grid-react';
import { useFormik } from 'formik';
import { useState } from 'react';

import useGridConfig from 'hooks/useGridConfig';

import { InputEx } from 'components/inputEx';
import customTooltipAgGrid from 'components/tooltipAgGrid';

import { comma } from 'service/utils';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const FileModal = (props) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [rowData] = useState([]);

  /***************************************************************************************
   * 설명 : 업체 검색
  ***************************************************************************************/
  const formik = useFormik({
    initialValues: {
      searchText: '',
      companyName: '',
      rep_name: '',
      nationCode: '',
      sido: '',
      gugun: '',
      chargeMemNo: '',
      isPermit: '',
      isUse: '',
      tech_mem_grade: '',
      manufacturingType: '',
      memberType: ''
    },
    onSubmit: (values) => {
    }
  });

  // table column
  const [columnDefs, setColumnDefs] = useState([
    { headerName: '번호', field: 'rowIndex', width: 80, cellClass: 'cp text-right',
      valueGetter: (params) => {
        return comma(params.node.rowIndex + 1);
      }
    },
    { headerName: '상태', field: 'userName', width: 200, cellClass: "cp center" },
    { headerName: '파일명', field: 'position', width: 120, cellClass: "cp center" },
    { headerName: '태그', field: 'userId', width: 200, cellClass: "cp" },
  ]);

  const [gridConfig] = useGridConfig(99, setColumnDefs);

  /***************************************************************************************
   * 설명 : 선택 시 처리
  ***************************************************************************************/
  const onClick = () => {

  }

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <section className="modal">
      <section className="modal-wrap" style={{width: '700px'}}>
        <header className="modal-header">
          <div className="modal-title">파일 추가</div>
          <Button className="close-btn" onClick={props.close}><CloseIcon></CloseIcon></Button>
        </header>

        <section className="modal-body">
          <form onSubmit={formik.handleSubmit}>
            <div className="mb10">
              <table className="input-table fl" style={{width: 'calc(100% - 102px)'}}>
                <colgroup>
                  <col style={{width: '25%'}} />
                  <col style={{width: '75%'}} />
                </colgroup>

                <tbody>
                  <tr>
                    <th scope="row"><label htmlFor="siteName">파일추가</label></th>
                    <td>
                      <InputEx
                        type="file"
                        name="siteName"
                        formik={formik}
                        focus={true}
                        fullWidth={true}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>

              <Button
                type="submit"
                color="error"
                variant='contained'
                className="ml5 mt3"
              >삭제</Button>

            </div>
          </form>

          <div className="clearfix"></div>

          <div className="ag-theme-balham" style={{height: '400px'}}>
            <AgGridReact
              defaultColDef={{
                sortable: true,
                resizable: true,
                filter: false,
                lockVisible: true,
                tooltipComponent: customTooltipAgGrid,
              }}
              tooltipShowDelay={0}
              tooltipHideDelay={2000}
              columnDefs={columnDefs}
              rowData={rowData}
              rowSelection={'single'}
              onGridReady={(event) => {
                gridConfig(event.columnApi);
              }}
              onRowClicked={(event) => {
              }}
              onRowDoubleClicked={(event) => {
              }}
              overlayNoRowsTemplate = "검색된 내용이 없습니다."
            />
          </div>
        </section>

        <footer className="modal-footer">
          <div className="modal-footer-center">
            <Button
              color="inherit"
              variant="outlined"
              className="Btn"
              onClick={() => {
                props.close();
              }}
            >창닫기</Button>

            <Button
              variant="contained"
              color="primary"
              className="Btn ml10"
              onClick={onClick}
            >업로드</Button>
          </div>
        </footer>
      </section>
    </section>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default FileModal;