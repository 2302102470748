/*****************************************************************************************
 * 설명 : 사용자메뉴
 * URI : /project
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import React from 'react';

import UserLayout from 'pages/layout/userLayout';

import Project from 'pages/user/project/project';
import ProjectCosting from 'pages/admin/cost/cost/cost';
import BoardList from 'pages/admin/board/boardList';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const UserMenu = ( props ) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <UserLayout props={props}>
      <div className={ (props.uri === 'project') ? 'displayBlock' : 'hidden' }>
        <Project></Project>
      </div>
      <div className={ (props.uri === 'projectCosting') ? 'displayBlock' : 'hidden' }>
        <ProjectCosting></ProjectCosting>
      </div>
      <div className={ (props.uri === 'board') ? 'displayBlock' : 'hidden' }>
        <BoardList></BoardList>
      </div>
    </UserLayout>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default UserMenu;