/**
 * * 환경설정 정보 리스트 가져오기
 * @param {*} getApi
 * @param {*} func
 * @returns
 */
export function getSystemConfigApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("get", '/api/v1/system/common', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  })
}

/**
 * * 시스템 환경설정 추가/수정
 * @param {*} getApi
 * @param {*} func
 * @returns
 */
export function setSystemConfigApi(getApi, params) {
  if( ! getApi ) return;

  return getApi("post", '/api/v1/system/common', params);
}

/**
 * * 접속 아이피 정보 리스트 가져오기
 * @param {*} getApi
 * @param {*} func
 * @returns
 */
export function getAccessIpApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("get", '/api/v1/system/access/ip', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  })
}

/**
 * * 접근 아이피 추가/수정
 * @param {*} getApi
 * @param {*} func
 * @returns
 */
export function setAccessIpApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("post", '/api/v1/system/access/ip', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  })
}

/**
 * * 접근 아이피 정보 삭제
 * @param {*} getApi
 * @param {*} params
 * @param {*} func
 * @returns
 */
export function deleteIpAddressApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("post", '/api/v1/system/access/ip/delete', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  });
}

/**
 * * 논리 드라이브 리스트 가져오기
 * @param {*} getApi
 * @param {*} func
 * @returns
 */
export function getLogicalDriveApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("get", '/api/v1/system/logical/drive', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  })
}

/**
 * * root 디렉토리 리스트 가져오기
 * @param {*} getApi
 * @param {*} func
 * @returns
 */
export function getDirectoryListApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("get", '/api/v1/system/logical/drive/directory', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  })
}

/**
 * * 논리 드라이브 추가/수정
 * @param {*} getApi
 * @param {*} func
 * @returns
 */
export function setLogicalDriveApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("post", '/api/v1/system/logical/drive', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  })
}

/**
 * * 논리 드라이브 정보 삭제
 * @param {*} getApi
 * @param {*} params
 * @param {*} func
 * @returns
 */
export function deleteLogicalDriveApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("post", '/api/v1/system/logical/drive/delete', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  });
}
