/*****************************************************************************************
 * 설명 : 설계비가산정
 * URI : /project/costing
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import Button from '@mui/material/Button';
import qs from 'qs';
import React, { useEffect, useRef, useState } from 'react';

import { getCostMemberDetailApi, getCostMemberListApi } from 'service/costMember';
import Restful from "service/restful";
import { comma } from 'service/utils';

import alertMsg from 'components/message';

import 'assets/css/print.scss';
import { MESSAGE_DELAY } from 'config/config';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const CostPrint = ( props ) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [getApi] = Restful();

  const pageNo = useRef(1);

  const [cost, setCost] = useState({});

  const [pages, setPages] = useState([]);

  /***************************************************************************************
   * 설명 : 검색 조건에 따른 검색
  ***************************************************************************************/
  const getList = (seq) => {
    let params = {
      startProjectYear: '',
      endProjectYear: '',
      totalSizeStart: '',
      totalSizeEnd: '',
      purpose: '',
      purposeName: '',
      order: '',
      orderTypeName: '',
      orderName: '',
      searchText: '',
      seq: seq
    }

    getCostMemberListApi(getApi, params, (response) => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        setCost(response.data.data[0]);

        getDetail(seq);
      } else {
        alertMsg("정상적인 절차로 접근하시기 바랍니다.", "W", MESSAGE_DELAY);
        window.close();
      }
    });
  }

  /***************************************************************************************
   * 설명 : 데이터 로딩 처리
  ***************************************************************************************/
  const getDetail = (seq) => {
    let params = {
      seq: seq
    }

    getCostMemberDetailApi(getApi, params, (response) => {
      if( response !== undefined && response.data.result !== false && response.data.data && response.data.data.length > 0 ) {
        // 페이지 계산 첫페이지 30줄, 중간 페이지 34줄, 마지막 페이지 28줄
        // 한페이지인 경우 24
        let tmp = [...response.data.data];
        let tmp2 = [];

        // 한페이지
        if( tmp.length < 24 ) {
          tmp2.push(tmp);
          setPages(tmp2);

        } else if( tmp.length < 58 ) {
          let tmp1 = tmp.splice(0, 30);
          tmp2.push(tmp1);
          tmp2.push(tmp)
          setPages(tmp2);

        } else {
          let tmp1 = tmp.splice(0, 30);
          setPages(tmp2.push(tmp1));

          for(let i = 0; i < tmp.length; i++ ) {
            if( tmp.length > 24 && tmp.length <= 32 ) {
              tmp1 = tmp.splice(0, tmp.length - 1);
              tmp2.push(tmp1);
              break;
            } else {
              tmp1 = tmp.splice(0, 32);
              tmp2.push(tmp1)
            }
          }

          if( tmp.length > 0 )
            tmp2.push(tmp);

          setPages(tmp2);
        }

      } else {
        alertMsg("정상적인 절차로 접근하시기 바랍니다.", "W", MESSAGE_DELAY);
        window.close();
      }
    });
  }

  /***************************************************************************************
   * 설명 : 데이터 로딩 처리
  ***************************************************************************************/
  useEffect(() => {
    let query = qs.parse(window.location.search, {
      ignoreQueryPrefix: true
    });

    if( query.seq === undefined )
      window.close();

    getList(query.seq);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <>
      <section className="btn-area">
        <Button
          variant="contained"
          color="primary"
          className="Btn"
          onClick={() => window.print()}
        >출력</Button>
      </section>

      {pages.length > 0 && pages?.map((page, pageIndex) => {
        return (
          <React.Fragment key={pageIndex}>
            <div className="print-page-top"></div>

            <section className="print-section">
              {pageIndex === 0 &&
                <table className="input-table f12">
                  <colgroup>
                    <col style={{width: '15%'}} />
                    <col style={{width: '35%'}} />
                    <col style={{width: '15%'}} />
                    <col style={{width: '35%'}} />
                  </colgroup>

                  <tbody>
                    <tr className='layout-small'>
                      <th scope="row"><label htmlFor="">연도</label></th>
                      <td>{cost.year}</td>
                      <th scope="row"><label htmlFor="">발주처</label></th>
                      <td>{cost.orderTypeName}</td>
                    </tr>
                    <tr className='layout-small'>
                      <th scope="row"><label htmlFor="">연면적(평)</label></th>
                      <td>
                        {comma(cost.totalSize ?? '')}
                        {(cost.totalSize ?? '') !== '' &&
                          <span>({comma(Math.floor(cost.totalSize * 0.3025))})</span>
                        }
                      </td>
                      <th scope="row"><label htmlFor="">제목</label></th>
                      <td className="center pr10">
                        {cost.title}
                      </td>
                    </tr>
                    <tr className='layout-small'>
                      <th scope="row"><label htmlFor="">용도</label></th>
                      <td>{cost.purposeName}</td>
                      <th><label htmlFor="">비고</label></th>
                      <td className="pr10">
                        {cost.memo}
                      </td>
                    </tr>
                  </tbody>
                </table>
              }

              <table className="input-table f12 mt10">
                <colgroup>
                  <col style={{width: '6%'}} />
                  <col style={{width: '12%'}} />
                  <col style={{width: '23%'}} />
                  <col style={{width: '14%'}} />
                  <col style={{width: '14%'}} />
                  <col style={{width: '14%'}} />
                  <col style={{width: '17%'}} />
                </colgroup>

                <thead>
                  <tr>
                    <th scope="col" className="p0">번호</th>
                    <th scope="col">구분</th>
                    <th scope="col">상세</th>
                    <th scope="col">평단가</th>
                    <th scope="col">기준 평단가</th>
                    <th scope="col">평균 평단가</th>
                    <th scope="col">비고</th>
                  </tr>
                </thead>

                <tbody>
                  {page.length > 0 && page.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td className="p5 right">{pageNo.current++}</td>
                        <td className="p5 center">
                          {
                            parseInt(item.type) === 0 ? '기본'
                            : parseInt(item.type) === 1 ? '외주용역비' : '기타'
                          }
                        </td>
                        <td className="p5 ellipsis">{item.title}</td>
                        <td className="p5 right">{comma(item.amt ?? 0)}</td>
                        <td className="p5 right">{comma(item.baseAmt ?? 0)}</td>
                        <td className="p5 right">{comma(item.avgAmt ?? 0)}</td>
                        <td className="p5 ellipsis">{item.memo ?? ''}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>

              {pageIndex === ( pages.length - 1 ) &&
                <table className="input-table mt10" style={{width: '60%'}}>
                  <colgroup>
                    <col style={{width: "100px"}} />
                    <col style={{width: "100px"}} />
                  </colgroup>

                  <tbody>
                    <tr className='layout-small'>
                      <th scope="row">직접인건비</th>
                      <td className="text-right pr20">{comma(cost.directLabourCost ?? 0)}</td>
                    </tr>
                    <tr className='layout-small'>
                      <th scope="row">직접경비</th>
                      <td className="text-right pr20">{comma(cost.directCost ?? 0)}</td>
                    </tr>
                    <tr className='layout-small'>
                      <th scope="row">외주용역비</th>
                      <td className="text-right pr20">{comma(cost.outsourcingCost ?? 0)}</td>
                    </tr>
                    <tr className='layout-small'>
                      <th scope="row">전사간접비</th>
                      <td className="text-right pr20">{comma(cost.totalIndirectionCost ?? 0)}</td>
                    </tr>
                    <tr className='layout-small'>
                      <th scope="row">이윤</th>
                      <td className="text-right pr20">{comma(cost.margin ?? 0)}</td>
                    </tr>
                    <tr className='layout-small'>
                      <th scope="row">기타</th>
                      <td className="text-right pr20">{comma(cost.extra ?? 0)}</td>
                    </tr>
                    <tr className='layout-small'>
                      <th scope="row">견적(제안)설계용역비</th>
                      <td className="text-right pr20">{comma(cost.totalCost ?? 0)}</td>
                    </tr>
                  </tbody>
                </table>
              }

              <footer className="page-no">
                {pageIndex + 1} / {pages.length}
              </footer>
            </section>
          </React.Fragment>
        )
      })}
    </>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default CostPrint;