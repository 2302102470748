/*****************************************************************************************
 * 설명 : 프로젝트관리 - 주관부서 변경이력 조회
 * URI : /project
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import React, { useState, useEffect }  from 'react';
import Button from '@mui/material/Button';
import SortableTree from 'react-sortable-tree';
import FileExplorerTheme from 'react-sortable-tree-theme-file-explorer';
import { useFormik } from 'formik';
import * as Yup from "yup";
import $ from 'jquery';

import Restful from "service/restful";
import { getDepartmentListApi } from 'service/member';

import alertMsg from 'components/message';
import { InputEx } from 'components/inputEx';

import { MESSAGE_DELAY } from 'config/config';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const DepartmentModal = (props) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [getApi] = Restful();

  const [departmentList, setDepartmentList] = useState([]);
  const [selectedNode, setSelectedNode] = useState({});

  const formik = useFormik({
    initialValues: {
      departmentNameLast: '',
      departmentNameLastReason: ''
    },
    validationSchema: Yup.object().shape({
      departmentNameLastReason: Yup.string().max(200, "200자리").required('필수').nullable(),
    }),
    onSubmit: (values) => {

      if( selectedNode.title === undefined ) {
        alertMsg("부서를 선택하시기 바랍니다.", "W", MESSAGE_DELAY);
        return;
      }
      props.formik.setFieldValue('departmentNameReason', values.departmentNameLastReason);
      props.formik.setFieldValue('departmentNameLast', selectedNode.title);

      props.close();
    }
  });


  /***************************************************************************************
   * 설명 : 조직도 리스트 데이터 가져오기
  ***************************************************************************************/
  const getDepartmentList = () => {
    let params = formik.values;

    const findNode = (data, id) => {
      // root
      if(id.length === 3) return data;

      for(let i = 0; i < data.length; i++ ) {
        if( String(data[i]?.sortOrder) === String(id.substr(0, id.length - 3)) ) {
          return data[i];
        } else if( data[i].children !== undefined ) {
          let tmp = findNode(data[i].children, id);
          if( tmp !== undefined ) return tmp;
        }
      }
    }

    getDepartmentListApi(getApi, params, (response) => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        setDepartmentList(response.data.data);

        let tmp = [];
        let data = response.data.data;
        data.forEach((item, index) => {
          let parentNode = findNode(tmp, item.sortOrder, 'sortOrder');

          if( parentNode === undefined ) {
            tmp.push({
              title: item.departmentName,
              expanded: true,
              ...item
            })

          } else if( item.sortOrder.length === 3 ) {
            parentNode.push({
              title: item.departmentName,
              expanded: true,
              ...item
            });

          } else {
            if( parentNode.children === undefined ) parentNode.children = [];
              parentNode.children.push({
                title: item.departmentName,
                expanded: true,
                ...item
              });
          }
        });

        setDepartmentList(tmp);

      } else {
        setDepartmentList([]);
      }
    });
  }

  /***************************************************************************************
   * 설명 : 트리 노드 클릭 시 한줄 선택 처리
  ***************************************************************************************/
  const treeRowClick = () => {
    $(".rstcustom__node").each((index, item) => {
      $(item).on('click', (event) => {

        // 기존 선택된 노드 삭제
        $(".modal-body").find('.tree-selected-parent').each((index, subItem) => {
          $(subItem).removeClass('tree-selected-parent');
        });

        $(event.target).closest('.rstcustom__rowWrapper').addClass('tree-selected-parent');
      });
    })
  }

  /***************************************************************************************
   * 설명 : 트리 노드 클릭 시 처리
  ***************************************************************************************/
  useEffect(() => {
    if( departmentList.length > 0 )
      treeRowClick();

    // eslint-disable-next-line
  }, [departmentList]);

  /***************************************************************************************
   * 설명 : 팝업 시 처리
  ***************************************************************************************/
  useEffect(() => {
    if( props.open )
      getDepartmentList();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <section className="modal-wrap" style={{width: '100%'}}>
      <form onSubmit={formik.handleSubmit}>
        <header className="modal-header" style={{ cursor: 'move' }} id="draggable-dialog-title">
          <div className="modal-title">주관부서 선택</div>
          <Button className="close-btn" onClick={props.close}>×</Button>
        </header>

        <section className="modal-body">
          <div className="clearfix"></div>

          <div>
            <table className="input-table mb15">
              <colgroup>
                <col style={{width: '20%'}} />
                <col style={{width: '80%'}} />
              </colgroup>

              <tbody>
                <tr>
                  <th scope="row"><label htmlfor="">부서선택</label></th>
                  <td>
                    <div className="p10" style={{height: '300px', overflow: 'auto'}}>
                      <SortableTree
                        treeData={departmentList}
                        onChange={(rowInfo) => {
                          setDepartmentList(rowInfo);
                        }}
                        isVirtualized={false}
                        theme={FileExplorerTheme}
                        style={{fontSize: '13px'}}
                        canDrag={false}
                        scaffoldBlockPxWidth={22}
                        generateNodeProps={(rowInfo) => {
                          return ({
                            onClick: (event) => {
                              setSelectedNode(rowInfo.node);
                            },
                            icons: rowInfo.node.children !== undefined && rowInfo.node.children.length > 0
                              ? [
                                  <div
                                    style={{
                                      borderLeft: 'solid 8px gray',
                                      borderBottom: 'solid 10px gray',
                                      marginRight: 10,
                                      boxSizing: 'border-box',
                                      width: 16,
                                      height: 12,
                                      filter: rowInfo.node.expanded
                                        ? 'drop-shadow(1px 0 0 gray) drop-shadow(0 1px 0 gray) drop-shadow(0 -1px 0 gray) drop-shadow(-1px 0 0 gray)'
                                        : 'none',
                                      borderColor: rowInfo.node.expanded ? 'white' : 'gray',
                                    }}
                                  />,
                                ]
                              : [
                                  <div
                                    style={{
                                      borderLeft: 'solid 8px gray',
                                      borderBottom: 'solid 10px gray',
                                      marginRight: 10,
                                      boxSizing: 'border-box',
                                      width: 16,
                                      height: 12,
                                      filter: 'drop-shadow(1px 0 0 gray) drop-shadow(0 1px 0 gray) drop-shadow(0 -1px 0 gray) drop-shadow(-1px 0 0 gray)',
                                      borderColor: 'white',
                                    }}
                                  />,
                                ],
                            className: selectedNode?.seq === (rowInfo.node?.seq || '') ? 'tree-selected' : '',
                          })
                        }}
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <th scope="row"><label htmlfor="departmentNameLastReason">사유입력</label></th>
                  <td>
                    <InputEx
                      name="departmentNameLastReason"
                      formik={formik}
                      fullWidth={true}
                    />
                  </td>
                </tr>
              </tbody>
            </table>

          </div>

        </section>

        <footer className="modal-footer">
          <div className="modal-footer-center">
            <Button
              color="inherit"
              variant="outlined"
              className="Btn"
              onClick={() => {
                props.close();
              }}
            >창닫기</Button>

            <Button
              type="submit"
              color="primary"
              variant="outlined"
              className="Btn ml10"
            >선택하기</Button>
          </div>
        </footer>
      </form>
    </section>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default DepartmentModal;