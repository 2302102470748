/*****************************************************************************************
 * 설명 : 사용자 통합검색 - 프로젝트 상세보기 - 미리보기
 * URI : /user/TotalSearch/list
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import React, { useState, useEffect }  from 'react';
import Button from '@mui/material/Button';
import {encode as base64_encode} from 'base-64';

import { formatBytes } from 'service/utils';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const ImagePreviewModal = (props) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [image, setImage] = useState({});

  /***************************************************************************************
   * 설명 : 업체 검색
  ***************************************************************************************/
  useEffect(() => {
    if( props.open ) {
      setImage(props.data);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <section className="previewModal modal-wrap" style={{width: '100%'}}>
      <header className="modal-header" style={{ cursor: 'move' }} id="draggable-dialog-title">
        <div className="modal-title">
          파일 미리보기 -
          <span className="f14">{image?.fileName}</span>
          <span className="f14 ml5">[{formatBytes(image?.fileSize)}]</span>
        </div>
        <Button className="close-btn" onClick={props.close}>×</Button>
      </header>

      <section className="modal-body">
        <div className="preview-div">
          <div className="img">
            <img src={"/api/v1/project/image/download?thumbnail=" + base64_encode(image.destPath)} alt={image.fileName} />
          </div>
        </div>
      </section>

      <footer className="modal-footer">
        <div className="modal-footer-center">
          {/*
          <Button
            color="secondary"
            variant="outlined"
            className="Btn"
            onClick={() => {
              alertMsg('원본파일 다운로드 권한이 없습니다.', "W", MESSAGE_DELAY);
              return;
            }}
          >다운로드</Button>

          <Button
            color="inherit"
            variant="outlined"
            className="Btn ml10"
            onClick={() => {
              window.open("http://archive.dagroup.co.kr/synap/SynapDocViewServer/job?fileType=Local&filePath=/home/synap/SynapDocViewServer/tmp/2023/08/1.png&fid=2023082100002&sync=true")
            }}
          >미리보기</Button>
          */}

          <Button
            color="inherit"
            variant="outlined"
            className="Btn ml10"
            onClick={() => {
              props.close();
            }}
          >창닫기</Button>
        </div>
      </footer>
    </section>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default ImagePreviewModal;