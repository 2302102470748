/*****************************************************************************************
 * 설명 : 관리자 > 시스템관리 > 권한그룹관리
*****************************************************************************************/
import { AgGridReact } from 'ag-grid-react';
import { useFormik } from 'formik';
import 'moment/locale/ko';
import { useEffect, useState } from 'react';
import SplitPane from "react-split-pane";
import * as Yup from "yup";

import BtnAgGridSave from 'components/btnAgGridSave';
import { InputEx } from 'components/inputEx';
import customTooltipAgGrid from 'components/tooltipAgGrid';

import useGridConfig from 'hooks/useGridConfig';

import { getEmployeeListApi } from 'service/member';
import Restful from 'service/restful';
import { selectGrid } from 'service/utils';

import Layout from '../../../layout/layout';
import TabEmployeeMenu from './tabEmployeeMenu';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
function AuthEmployee(props) {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [getApi] = Restful();

  const [gridApi, setGridApi] = useState({});

  const [employeeList, setEmployeeList] = useState([]);
  const [selected, setSelected] = useState([]);

  // 권한그룹 컬럼 선언
  const [columnDefsGroup, setColumnDefsGroup] = useState([
    { headerName: '', field: 'seq', cellClass: 'cp', width: 36, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
    { headerName: '부서명', field: 'departmentName', width: 130, cellClass: 'cp' },
    { headerName: '직위명', field: 'positionName', width: 100, cellClass: 'cp' },
    { headerName: '사용자명', field: 'userName', width: 100, cellClass: 'cp' },
    { headerName: '아이디', field: 'userId', width: 100, cellClass: 'cp' },
  ]);

  const [gridConfigGroup, setGridUpdateGroup] = useGridConfig(1, setColumnDefsGroup);

  const formik = useFormik({
    initialValues: {
      searchText: '',
    },
    validationSchema: Yup.object().shape({
      searchText: Yup.string().max(30, "30자리")
    }),
    onSubmit: (values) => {
      getAuthGroupList();
    }
  });

  /***************************************************************************************
   * 설명 : 사용자 정보 가져오기
  ***************************************************************************************/
  const getAuthGroupList = () => {
    let params = {
      departmentCode: '',
      sortOrder: '',
      searchText: formik.values.searchText
    };

    getEmployeeListApi(getApi, params, (response) => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        setEmployeeList(response.data.data);
      } else {
        setEmployeeList([]);
      }
    });
  }

  /***************************************************************************************
   * 설명 : 조직별 그룹 새로 고침 시 자동 선택
  ***************************************************************************************/
  useEffect(() => {
    if( setEmployeeList.length > 0 ) {
      selectGrid(gridApi, selected, setSelected, false);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setEmployeeList]);

  /***************************************************************************************
   * 설명 : 권한 그룹 리스트 가져오기
  ***************************************************************************************/
  useEffect( () => {
    getAuthGroupList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <Layout>
      <section className="admin-cms-contents system-page">
        <SplitPane split="vertical" minSize={250} defaultSize={400}>
          <div className="mr10">
            <form onSubmit={formik.handleSubmit}>
              <header className="admin-cms-search-header">
                <div className="left">
                  <div className="fl">
                    <BtnAgGridSave className="fl" click={setGridUpdateGroup}></BtnAgGridSave>
                  </div>
                  <div className="fl">
                    <h4 className="f15 sub-title">사용자</h4>
                  </div>
                  <div className="fl ml10">
                    <InputEx
                      name="searchText"
                      formik={formik}
                      fullWidth={false}
                      label="사용자명,부서,아이디"
                    />
                  </div>
                </div>
                <div className="text-right">
                </div>
              </header>
            </form>

            <section className="ag-theme-balham grid mt10 pad-height165" style={{height:'calc(100vh - 130px)'}}>
              <div style={{ height: '100%' }}>
                <AgGridReact
                  defaultColDef={{
                    sortable: true,
                    resizable: true,
                    filter: false,
                    lockVisible: true,
                    tooltipComponent: customTooltipAgGrid
                  }}
                  tooltipShowDelay={0}
                  tooltipHideDelay={2000}
                  rowSelection={'single'}
                  columnDefs={columnDefsGroup}
                  rowData={employeeList}
                  onGridReady={(event) => {
                    gridConfigGroup(event.columnApi);
                    setGridApi(event.api);
                  }}
                  onFirstDataRendered={(event) => {
                    event.api.forEachNode((item, index) => {
                      if(index === 0) {
                        item.setSelected(true);
                        setSelected([item.data]);
                      }
                    })
                  }}
                  onRowClicked={(event) => {
                    let nodes = event.api.getSelectedRows();
                    setSelected(nodes);
                  }}
                  rowDragManaged={true}
                  rowDragMultiRow={true}
                  animateRows={true}
                  overlayNoRowsTemplate = "검색된 내용이 없습니다."
                />
              </div>
            </section>
          </div>
          <div className="Tab-page">
            <div className="tab-contents">
              <TabEmployeeMenu
                selected={selected}
              />
            </div>
          </div>
        </SplitPane>
      </section>
    </Layout>
  )
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default AuthEmployee;