/**
 * * 공통코드 그룹 리스트 가져오기
 * @param {*} getApi
 * @param {*} func
 * @returns
 */
export function getCommonGroupListApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("get", '/api/v1/system/common/group', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  })
}

/**
 * * 공통코드 리스트 가져오기
 * @param {*} getApi
 * @param {*} func
 * @returns
 */
export function getCommonListApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("get", '/api/v1/system/common', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  })
}

/**
 * * 공통코드 리스트 가져오기
 * @param {*} getApi
 * @param {*} func
 * @returns
 */
export function setCommonGroupApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("post", '/api/v1/system/common/group', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  })
}

/**
 * * 공통코드 그룹 정보 삭제
 * @param {*} getApi
 * @param {*} params
 * @param {*} func
 * @returns
 */
export function deleteCommonGroupApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("post", '/api/v1/system/common/group/delete', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  });
}

/**
 * * 공통코드 추가/수정
 * @param {*} getApi
 * @param {*} func
 * @returns
 */
export function setCommonApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("post", '/api/v1/system/common', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  })
}

/**
 * * 공통코드 엑셀 업로드 저장
 * @param {*} getApi
 * @param {*} func
 * @returns
 */
export function setCommonExcelApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("post", '/api/v1/system/common/excel', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  })
}


/**
 * * 공통코드 정보 삭제
 * @param {*} getApi
 * @param {*} params
 * @param {*} func
 * @returns
 */
export function deleteCommonApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("post", '/api/v1/system/common/delete', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  });
}

/**
 * * 공통코드 순서 변경
 * @param {*} getApi
 * @param {*} params
 * @param {*} func
 * @returns
 */
export function sortCommonApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("post", '/api/v1/system/common/sort', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  });
}

/**
 * * 그리드 정보 가져오기
 * @param {*} getApi
 * @param {*} func
 * @returns
 */
export function getGridApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("get", '/api/v1/common/grid', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  })
}

/**
 * * 그리드 정보 가져오기
 * @param {*} getApi
 * @param {*} func
 * @returns
 */
export function setGridSaveApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("post", '/api/v1/common/grid', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  })
}
