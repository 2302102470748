/*****************************************************************************************
 * 설명 : 관리 - 프로잭트정보
 * URI : /project
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Modal from '@mui/material/Modal';
import { AgGridReact } from 'ag-grid-react';
import { encode as base64_encode } from 'base-64';
import { useFormik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import * as Yup from "yup";

import { IMAGE_FILE, MESSAGE_DELAY, MOVIE_FILE, MOVIE_FILE_STREAM, baseURL } from 'config/config';
import useGridConfig from 'hooks/useGridConfig';

import {
  getProjectAwardListApi,
  getProjectForeignListApi,
  getProjectImageListApi,
  getProjectOwnershipListApi,
  getProjectPartnerListApi,
  getProjectScaleListApi,
  setProjectApi
} from 'service/project';
import Restful from "service/restful";
import { PaperComponent, checkArray, checkArrayValue, comma, formatBytes, isCheckValue } from 'service/utils';

import AlertDialog from 'components/alertDiolog';
import BtnAgGridSave from 'components/btnAgGridSave';
import ChipEx from 'components/chipEx';
import DaumPost from 'components/daumAddress';
import { ButtonEx, InputEx, SelectEx, SelectSearchEx, TextareaEx, ToggleButtonGroupEx } from 'components/inputEx';
import alertMsg from 'components/message';
import customTooltipAgGrid from 'components/tooltipAgGrid';

import ProjectAwardModal from './modal/awardInfoModal';
import DepartmentHistoryModal from './modal/departmentHistoryModal';
import DepartmentModal from './modal/departmentModal';
import ProjectForeignModal from './modal/foreignModal';
import ImagePreviewModal from './modal/imagePreviewModal';
import NewTechModal from './modal/newTechModal';
import ProjectOwnershipModal from './modal/ownershipModal';
import ProjectPartnerModal from './modal/partnerModal';
import PmSelectModal from './modal/pmSelectModal';
import PointOutModal from './modal/pointOutModal';
import ProjectPmNameHistoryModal from './modal/projectPmNameHistoryModal';
import PurposeModal from './modal/purposeModal';
import PurposeRegionModal from './modal/purposeRegionModal';
import ReasonModal from './modal/reasonModal';
import ScaleModal from './modal/scaleModal';
import StructureModal from './modal/structureModal';

import NoImage from 'assets/images/prepare.png';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const TabProjectInfo = ( props ) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const movieRef = useRef();
  const [getApi] = Restful();

  const [previewMovie, setPreviewMovie] = useState('');

  const [gridApi, setGridApi] = useState({});
  const [gridApiAward, setGridApiAward] = useState({});
  const [gridApiScale, setGridApiScale] = useState({});
  const [gridApiPartner, setGridApiPartner] = useState({});

  const [ownershipList, setOwnershipList] = useState([]);
  const [partnerList, setPartnerList] = useState([]);
  const [foreignList, setForeignList] = useState([]);
  const [newTechList, setNewTechList] = useState([]);
  const [awardInfoList, setAwardInfoList] = useState([]);
  const [scaleArrayList, setScaleArrayList] = useState([]);

  const [pathInfo, setPathInfo] = useState({
    totalDirectoryCount: 0,
    totalFileCount: 0,
    totalSize: 0
  });

  const [previewList, setPreviewList] = useState([]);


  const formik = useFormik({
    initialValues: {
      seq: '',
      projectNumber: '',
      hashTag: '',
      businessType: '',
      builderType: '',
      orderCompanyId: '',
      builderCompanyId: '',
      purpose1: '',
      purpose1Code: '',
      purposeExtra: '',
      doroAddress: '',
      parcelAddress: '',
      longitude: '',
      latitude: '',
      pointOut: '',
      purposeRegion: '',
      purposeRegionCode: '',
      responsibilityConstructor: '',
      cooperationCompany: '',
      partMember: '',
      underLayer: '',
      groundLayer: '',
      numberHouse: '',
      scaleExtra: '',
      awardInfo: '',
      structure1: '',
      structure1Code: '',
      structureExtra: '',
      newTech: '',
      newTechCode: '',
      detailSeq: '',
      memo: '',
      ownershipMemo: '',
      partnerMemo: '',
      foreignMemo: '',
      readingGrade: 0,
      readingProject: 1,
      isConfirm: 0,
      isFile: 0,
      relationProject: '',
      departmentNameLast: '',
      departmentNameLastReason: '',
      projectPmNameLast: '',
      projectPmNameLastReason: '',
      projectApmNameLast: '',
      projectApmNameLastReason: '',
      projectCompanyList: [],
      ownershipCompanyList: [],
      awardInfoList: []
    },
    validationSchema: Yup.object().shape({
      hashTag: Yup.string().max(45, "45자리").nullable(),
      purposeExtra: Yup.string().max(200, "200자리").nullable(),
      doroAddress: Yup.string().max(100, "100자리").nullable(),
      parcelAddress: Yup.string().max(100, "100자리").nullable(),
      pointOut: Yup.string().max(100, "100자리").nullable(),
      purposeRegion: Yup.string().max(100, "100자리").nullable(),
      responsibilityConstructor: Yup.string().max(100, "100자리").nullable(),
      // cooperationCompany: Yup.string().max(100, "100자리").nullable(),
      partMember: Yup.string().max(200, "200자리").nullable(),
      // underLayer: Yup.string().max(100, "100자리").nullable(),
      // groundLayer: Yup.string().max(100, "100자리").nullable(),
      // numberHouse: Yup.string().max(100, "100자리").nullable(),
      scaleExtra: Yup.string().max(100, "100자리").nullable(),
      awardInfo: Yup.string().max(200, "200자리").nullable(),
      structureExtra: Yup.string().max(200, "200자리").nullable(),
      // newTech: Yup.string().max(1000, "1000자리").nullable(),
    }),
    onSubmit: (values) => {
      saveProject(values.isConfirm);
    }
  });

  const [openModal, setOpenModal] = useState({open: false, modal: 'sync', data: []});

  // 참여업체 컬럼
  const [columnDefs, setColumnDefs] = useState([
    { headerName: '', field: 'seq', cellClass: 'cp', width: 35, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
    { headerName: '순번', field: 'rowIndex', width: 45, cellClass: 'cp text-right',
      valueGetter: (params) => {
        return comma(params.node.rowIndex + 1);
      }
    },
    { headerName: '주관업체여부', field: 'isMain', width: 100, cellClass: 'cp center',
      cellRendererFramework: function(params) {
        if( parseInt(params.data.isMain) === 1 )
          return <ChipEx color="secondary" variant="outlined" size="small" label="주관업체" />;
        else
          return <ChipEx color="success" variant="outlined" size="small" label="참여업체" />;
      }
    },
    { headerName: '참여업체', field: 'companyName', width: 300, cellClass: 'cp', tooltipField: 'companyName' },
    { headerName: '지분율(%)', field: 'ownershipRate', width: 100, cellClass: 'cp text-right' },
    { headerName: '메모', field: 'memo', width: 500, cellClass: 'cp', tooltipField: 'memo' },
  ]);

  // 협력업체 컬럼
  const [columnDefsPartner, setColumnDefsPartner] = useState([
    { headerName: '', field: 'seq', cellClass: 'cp', width: 35, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
    { headerName: '순번', field: 'rowIndex', width: 45, cellClass: 'cp text-right',
      valueGetter: (params) => {
        return comma(params.node.rowIndex + 1);
      }
    },
    { headerName: '구분', field: 'group', width: 100, cellClass: 'cp center' },
    { headerName: '업체명', field: 'companyName', width: 300, cellClass: 'cp' },
    { headerName: '담당자', field: 'chargeName', width: 200, cellClass: 'cp' },
    { headerName: '메모', field: 'memo', width: 500, cellClass: 'cp' },
  ]);

  // 협력업체 컬럼
  const [columnDefsForeign, setColumnDefsForeign] = useState([
    { headerName: '', field: 'seq', cellClass: 'cp', width: 35, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
    { headerName: '순번', field: 'rowIndex', width: 45, cellClass: 'cp text-right',
      valueGetter: (params) => {
        return comma(params.node.rowIndex + 1);
      }
    },
    { headerName: '구분', field: 'group', width: 100, cellClass: 'cp center' },
    { headerName: '업체명', field: 'companyName', width: 300, cellClass: 'cp', tooltipField: 'companyName' },
    { headerName: '담당자', field: 'chargeName', width: 200, cellClass: 'cp' },
    { headerName: '메모', field: 'memo', width: 500, cellClass: 'cp', tooltipField: 'memo' },
  ]);

  // 수상정보 컬럼
  const [columnDefsAward, setColumnDefsAward] = useState([
    { headerName: '', field: 'seq', cellClass: 'cp', width: 35, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
    { headerName: '순번', field: 'rowIndex', width: 45, cellClass: 'cp text-right',
      valueGetter: (params) => {
        return comma(params.node.rowIndex + 1);
      }
    },
    { headerName: '수상여부', field: 'type', width: 100, cellClass: 'cp center',
      cellRendererFramework: function(params) {
        if( parseInt(params.data.type) === 1 )
          return <ChipEx color="success" variant="outlined" size="small" label="수상" />;
        else
          return <ChipEx color="default" variant="outlined" size="small" label="비수상" />;
      }
    },
    { headerName: '수상정보', field: 'awardInfo', width: 500, cellClass: 'cp', tooltipField: 'awardInfo' },
    { headerName: '메모', field: 'memo', width: 500, cellClass: 'cp' },
  ]);

  // 신기술 정보 컬럼
  const [columnDefsNewTech, setColumnDefsNewTech] = useState([
    { headerName: '', field: 'seq', cellClass: 'cp', width: 35, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
    { headerName: '순번', field: 'rowIndex', width: 45, cellClass: 'cp text-right',
      valueGetter: (params) => {
        return comma(params.node.rowIndex + 1);
      }
    },
    { headerName: '검색어', field: 'searchText', hide: true },
    { headerName: '지정번호', field: 'commonCode', width: 70, cellClass: "cp text-right" },
    { headerName: '신기술명', field: 'commonName', width: 300, cellClass: "cp", tooltipField: 'commonName' },
    { headerName: '개발사(기관)', field: 'val1', width: 230, cellClass: "cp", tooltipField: 'val1' },
    { headerName: '기술분야', field: 'val2', width: 200, cellClass: "cp", tooltipField: 'val2' },
    { headerName: '보호기간', field: 'val3', width: 170, cellClass: "cp center", tooltipField: 'val3' },
    { headerName: '스마트건설기술', field: 'val4', width: 70, cellClass: "cp center",
      cellRendererFramework: function(params) {
        if( params.data.val4 === '해당' )
          return <ChipEx color="primary" variant="outlined" size="small" label="해당" />;
        else
          return <ChipEx color="error" variant="outlined" size="small" label="미해당" />;
      }
    },
    { headerName: '상태', field: 'val5', width: 60, cellClass: "cp center",
      cellRendererFramework: function(params) {
        if( params.data.val5 === '유효' )
          return <ChipEx color="success" variant="outlined" size="small" label="유효" />;
        else
          return <ChipEx color="error" variant="outlined" size="small" label="만료" />;
      }
    },
  ]);

  // 규모 정보 컬럼
  const [columnDefsScale, setColumnDefsScale] = useState([
    { headerName: '', field: 'seq', cellClass: 'cp', width: 35, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
    { headerName: '순번', field: 'rowIndex', width: 45, cellClass: 'cp text-right',
      valueGetter: (params) => {
        return comma(params.node.rowIndex + 1);
      }
    },
    { headerName: '명칭', field: 'scaleName', width: 150, cellClass: "cp", tooltipField: 'scaleName' },
    { headerName: '우편번호', field: 'zipCode', width: 50, cellClass: "cp center" },
    { headerName: '도로명주소', field: 'address', width: 200, cellClass: "cp", tooltipField: 'address' },
    { headerName: '상세주소', field: 'addressDetail', width: 150, cellClass: "cp", tooltipField: 'addressDetail' },
    { headerName: '지번주소', field: 'parcelAddress', width: 150, cellClass: "cp", tooltipField: 'parcelAddress' },
    { headerName: '층수(지하)', field: 'underLayer', width: 70, cellClass: "cp text-right" },
    { headerName: '층수(지상)', field: 'groundLayer', width: 70, cellClass: "cp text-right" },
    { headerName: '세대수', field: 'numberHouse', width: 70, cellClass: "cp text-right" },
    { headerName: '규모(기타)', field: 'scaleExtra', width: 200, cellClass: "cp", tooltipField: 'scaleExtra' },
  ]);

  const [gridConfig, setGridUpdate] = useGridConfig(130, setColumnDefs);
  const [gridConfigNewTech, setGridUpdateNewTech] = useGridConfig(131, setColumnDefsNewTech);
  const [gridConfigAward, setGridUpdateAward] = useGridConfig(132, setColumnDefsAward);
  const [gridConfigPartner, setGridUpdatePartner] = useGridConfig(133, setColumnDefsPartner);
  const [gridConfigForeign, setGridUpdateForeign] = useGridConfig(133, setColumnDefsForeign);
  const [gridConfigScale, setGridUpdateScale] = useGridConfig(133, setColumnDefsScale);

  /*******************************************************************************
    설  명 : 주소 바인딩
  *******************************************************************************/
  const handleCloseModal = (data) => {
    formik.setFieldValue('doroAddress', data.address);
    formik.setFieldValue('parcelAddress', data.jibunAddress);

    setOpenModal({open: false, modal: 'daum', data: {}});
  };

  /*******************************************************************************
    설  명 : 수상정보 그리드에서 삭제
  *******************************************************************************/
  const awardDel = () => {
    let nodes = gridApiAward.getSelectedRows();

    if( nodes.length < 1 ) {
      alertMsg("삭제할 수상 정보를 선택해 주시기 바랍니다.", "W", MESSAGE_DELAY);
      return;
    }

    let tmp = awardInfoList.filter((item) => {
      let tmp1 = nodes.filter((subItem) => subItem.seq === item.seq );
      return ( tmp1.length > 0 ) ? false : true;
    });

    setAwardInfoList(tmp);
  }


  /*******************************************************************************
    설  명 : 구조 그리드에서 삭제
  *******************************************************************************/
  const scaleDel = () => {
    let nodes = gridApiScale.getSelectedRows();

    if( nodes.length < 1 ) {
      alertMsg("삭제할 규모 정보를 선택해 주시기 바랍니다.", "W", MESSAGE_DELAY);
      return;
    }

    let tmp = scaleArrayList.filter((item) => {
      let tmp1 = nodes.filter((subItem) => subItem.seq === item.seq );
      return ( tmp1.length > 0 ) ? false : true;
    });

    setScaleArrayList(tmp);
  }

  /*******************************************************************************
    설  명 : 참여업체 그리드에서 삭제
  *******************************************************************************/
  const ownershipDel = () => {
    let nodes = gridApi.getSelectedRows();

    if( nodes.length < 1 ) {
      alertMsg("삭제할 참여업체 정보를 선택해 주시기 바랍니다.", "W", MESSAGE_DELAY);
      return;
    }

    let tmp = ownershipList.filter((item) => {
      let tmp1 = nodes.filter((subItem) => subItem.seq === item.seq );
      return ( tmp1.length > 0 ) ? false : true;
    });

    setOwnershipList(tmp);
  }

  /*******************************************************************************
    설  명 : 규모 정보 가져오기
  *******************************************************************************/
  const getScaleList = () => {
    let params = {
      projectNumber: props.selected?.projectNumber
    }

    getProjectScaleListApi(getApi, params, (response) => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        setScaleArrayList(response.data.data);
      } else {
        setScaleArrayList([]);
      }
    });
  }

  /*******************************************************************************
    설  명 : 참여업체 정보 가져오기
  *******************************************************************************/
  const getOwnershipList = () => {
    let params = {
      projectNumber: props.selected?.projectNumber
    }

    getProjectOwnershipListApi(getApi, params, (response) => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        setOwnershipList(response.data.data);
      } else {
        setOwnershipList([]);
      }
    });
  }

  /*******************************************************************************
    설  명 : 협력업체 그리드에서 삭제
  *******************************************************************************/
  const partnerDel = () => {
    let nodes = gridApiPartner.getSelectedRows();

    if( nodes.length < 1 ) {
      alertMsg("삭제할 참여업체 정보를 선택해 주시기 바랍니다.", "W", MESSAGE_DELAY);
      return;
    }

    let tmp = partnerList.filter((item) => {
      let tmp1 = nodes.filter((subItem) => subItem.seq === item.seq );
      return ( tmp1.length > 0 ) ? false : true;
    });

    setPartnerList(tmp);
  }

  /*******************************************************************************
    설  명 : 협업해외사 그리드에서 삭제
  *******************************************************************************/
  const foreignDel = () => {
    let nodes = gridApi.getSelectedRows();

    if( nodes.length < 1 ) {
      alertMsg("삭제할 협업해외사 정보를 선택해 주시기 바랍니다.", "W", MESSAGE_DELAY);
      return;
    }

    let tmp = foreignList.filter((item) => {
      let tmp1 = nodes.filter((subItem) => subItem.seq === item.seq );
      return ( tmp1.length > 0 ) ? false : true;
    });

    setForeignList(tmp);
  }

  /*******************************************************************************
    설  명 : 협력업체 정보 가져오기
  *******************************************************************************/
  const getPartnerList = () => {
    let params = {
      projectNumber: props.selected?.projectNumber
    }

    getProjectPartnerListApi(getApi, params, (response) => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        setPartnerList(response.data.data);
      } else {
        setPartnerList([]);
      }
    });
  }

  /*******************************************************************************
    설  명 : 협력업체 정보 가져오기
  *******************************************************************************/
  const getForeignList = () => {
    let params = {
      projectNumber: props.selected?.projectNumber
    }

    getProjectForeignListApi(getApi, params, (response) => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        setForeignList(response.data.data);
      } else {
        setForeignList([]);
      }
    });
  }

  /*******************************************************************************
    설  명 : 수상 정보 가져오기
  *******************************************************************************/
  const getAwardInfoList = () => {
    let params = {
      projectNumber: props.selected?.projectNumber
    }

    getProjectAwardListApi(getApi, params, (response) => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        setAwardInfoList(response.data.data);
      } else {
        setAwardInfoList([]);
      }
    });
  }

  /*******************************************************************************
    설  명 : 프로젝트 추가 정보 저장
  *******************************************************************************/
  const saveProject = (isConfirm) => {
    let params = {...formik.values};
    isConfirm = isConfirm === null ? 0 : isConfirm;

/*
    let tmp = checkArray(params, 'orderCompanyId', true);
    params.orderCompanyId = tmp.value;
    params.orderCompanyIdName = tmp.label;
*/

    let tmp1 = checkArray(params, 'builderCompanyId', true);
    params.builderCompanyId = tmp1.value;
    params.builderCompanyIdName = tmp1.label;

    /*
    let tmp2 = checkArray(params, 'pointOut', true);
    params.pointOut = tmp2.value;
    params.pointOutName = tmp2.label;

    // let tmp3 = checkArray(params, 'purposeRegion', true);
    // params.purposeRegion = tmp3.value;
    // params.purposeRegionName = tmp3.label;
    */

    let tmp4 = checkArray(params, 'cooperationCompany', true);
    params.cooperationCompany = tmp4.value;
    params.cooperationCompanyName = tmp4.label;

    /*
    let tmp5 = props.structure1.filter((item) => item.value === params.structure1);
    if( tmp5.length > 0 ) params.structure1Name = tmp5[0].label;

    let tmp6 = props.structure2.filter((item) => item.value === params.structure2);
    if( tmp6.length > 0 ) params.structure2Name = tmp6[0].label;
    */

    let tmp7 = ownershipList.map((item) => item.companyName).join();
    params.ownershipName = tmp7;

    let tmp8 = awardInfoList.map((item) => item.awardInfo).join();
    params.awardInfo = tmp8;

    let tmp9 = partnerList.map((item) => item.companyName).join();
    params.partnerName = tmp9;

    let tmp10 = foreignList.map((item) => item.companyName).join();
    params.foreignName = tmp10;

    params.isConfirm = isConfirm;
    params.isFile = checkArrayValue(params, 'isFile', true);

    params.scaleArrayList = scaleArrayList;
    params.ownershipCompanyList = ownershipList;
    params.awardInfoList = awardInfoList;
    params.partnerCompanyList = partnerList;
    params.foreignCompanyList = foreignList;


    setProjectApi(getApi, params, (response => {
      if( response !== undefined && response.data.result ) {
        alertMsg(response.data.message, "S", MESSAGE_DELAY);

        if( formik.values.seq === null || formik.values.seq === '' )
          formik.setFieldValue("detailSeq", response.data.data?.detailSeq);

        formik.setFieldValue("isConfirm", response.data.data?.isConfirm);
        formik.setFieldValue("departmentNameOrg", formik.values.departmentNameLast);
        formik.setFieldValue("projectPmNameOrg", formik.values.projectPmNameLast);

        // 프로젝트 리스트 업데이트
        if( props.setList && props.list) {
          let tmp = [...props.list];
          let index = props.selectedIndex || 0;

          if( index > -1 ) {
            tmp[index] = {...formik.values};

            tmp[index].detailSeq = response.data.data?.detailSeq;
            tmp[index].cooperationCompany = response.data.data?.cooperationCompany;
            tmp[index].orderCompanyId = params.orderCompanyId;
            tmp[index].builderCompanyId = params.builderCompanyId;
            tmp[index].pointOut = params.pointOut;
            tmp[index].purposeRegion = params.purposeRegion;
            tmp[index].purposeRegionCode = params.purposeRegionCode;
            tmp[index].isConfirm = isConfirm;
            tmp[index].readingGrade = params.readingGrade ?? 0;
            tmp[index].readingProject = params.readingProject ?? 1;
          }

          if( props.setSelected && index > -1) {
            props.setSelected(tmp[index]);
          }

          if( props.setList ) {
            props.setList(tmp);
          }
        }

      } else {
        alertMsg(response.data.message || '서버와의 통신에 실패하였습니다.', "E", MESSAGE_DELAY);
      }
    }));
  }

  /***************************************************************************************
   * 설명 : 신기술 초기화
  ***************************************************************************************/
  const newTechInit = () => {
    let tmp = String(formik.values.newTechCode || '');
    let tmp1 = tmp.split(/,/gi);

    let node = props.newTech.filter((item) => {
      let tmp2 = tmp1.filter((subItem) => {
        return parseInt(subItem) === parseInt(item.commonCode)
      });

      if( tmp2.length > 0 ) return true;
      else return false;
    });

    setNewTechList(node);
  }

  /***************************************************************************************
   * 설명 : 프로젝트 선택 시 대표 이미지 가져오기
  ***************************************************************************************/
  const getImageList = () => {
    let params = {
      projectNumber: props.selected?.projectNumber
    }

    getProjectImageListApi(getApi, params, (response) => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        setPreviewList(response.data.data);

        if( response.data.data1 )
          setPathInfo(response.data.data1);

      } else {
        setPreviewList([]);
        setPathInfo({
          totalDirectoryCount: 0,
          totalFileCount: 0,
          totalSize: 0
        });
      }
    });
  }

  /***************************************************************************************
   * 설명 : 프로젝트 선택 시 처리
  ***************************************************************************************/
  useEffect(() => {
    if( props.tabIndex === 0 && props.selected !== undefined && props.selected.seq !== undefined ) {
      formik.setValues(props.selected);

      // 추가 정보 바인딩
      /*
      let tmp = props?.order?.filter((item) => props.selected.orderCompanyId === item.value);
      formik.setFieldValue('orderCompanyId', tmp);
      */

      if( (props.selected.builderCompanyId || '') !== '' ) {
        let tmp = props?.company?.filter((item) => props.selected.builderCompanyId?.indexOf(item.value) > -1 );
        formik.setFieldValue('builderCompanyId', tmp);
      }

      /*
      tmp = props?.pointOut?.filter((item) => props.selected.pointOut === item.value);
      formik.setFieldValue('pointOut', tmp);

      // tmp = props?.purposeRegion?.filter((item) => props.selected.purposeRegion === item.value);
      // formik.setFieldValue('purposeRegion', tmp);
      */

      let tmp = [];
      if( Array.isArray(props.selected.cooperationCompany) ) {
        props?.partner?.forEach((item) => {
          for(let i = 0; i < props.selected.cooperationCompany.length; i++ ) {
            if( item.value === props.selected.cooperationCompany[i] ) tmp.push(item);
          }
        });
        formik.setFieldValue('cooperationCompany', tmp);

      } else if( ( props.selected.cooperationCompany || '' ) !== '' ) {
        let tmp1 = props.selected.cooperationCompany?.split(/,/gi);
        if( tmp1 !== undefined ) {
          tmp = [];
          props?.partner?.forEach((item) => {
            for(let i = 0; i < tmp1.length; i++ ) {
              if( item.value === tmp1[i] ) tmp.push(item);
            }
          });
          formik.setFieldValue('cooperationCompany', tmp);
        }
      }

      // 신기술 처리
      newTechInit();

      // 규모 정보 리스트 가져오기
      getScaleList();

      // 참여업체 정보 리스트 가져오기
      getOwnershipList();

      // 협력업체 정보 리스트 가져오기
      getPartnerList();

      // 협업해외사 정보 리스트 가져오기
      getForeignList();

      // 수상 정보 리스트 가져오기
      getAwardInfoList();

      // 대표 이미지 파일 가져오기
      getImageList();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.tabIndex, props.selected]);

  /***************************************************************************************
   * 설명 : 신기술 변경 시 리스트 표시
  ***************************************************************************************/
  useEffect(() => {
    if( props.tabIndex === 0 && props.newTech?.length > 0 ) {
      // 신기술 처리
      newTechInit();
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.newTechCode]);

  /***************************************************************************************
   * 설명 : 용도 1단계 데이터 로딩 시 첫번째 자동 선택
  ***************************************************************************************/
  useEffect(() => {
    if( props.tabIndex === 0 ) {

    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.purpose1, props.builderType, props.pointOut,
    props.purposeRegion, props.structure
  ]);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <section className="project-info">
      <form onSubmit={formik.handleSubmit}>
        <section className="no-permission hidden">
          <div className="infoTxt">
            프로젝트 정보를 조회할 권한이 없습니다.<br/>
            프로젝트 조회를 원할 경우 [열람신청]을 하시기 바랍니다.
          </div>
        </section>

        <section className="">
          {/* <header className="mt20">
            <div className="clearfix">
              <div className="fl">
                <ChipEx color="warning" variant="variant" size="small" label="준비" />
              </div>
              <div className="fl">
                <div className="pl10 bold">행복도시 2-4생활권 P1주상복합 신축공사 실시설계</div>
                <div className="bigo pl10 mt5">2023. 프로젝트구분</div>
              </div>
            </div>
          </header> */}

          <header className="project-header">
            <div className="fl">
              { // 프로젝트 추가 정보 상태
                formik.values.isConfirm === null
                ? <ChipEx color="error" variant="variant" size="small" label="등록" className="f12" />
                : ! isCheckValue(formik.values.detailSeq) && parseInt(formik.values.isConfirm) !== 1
                ? <ChipEx color="error" variant="variant" size="small" label="등록" className="f12" />
                : isCheckValue(formik.values.detailSeq) && parseInt(formik.values.isConfirm) !== 1
                ? <ChipEx color="success" variant="variant" size="small" label="추가"  className="f12" />
                : <ChipEx color="primary" variant="variant" size="small" label="등록완료" className="f12" />
              }

              { // 프로젝트 파일 정보 상태
                formik.values.isUploadCheck === 0 ? <ChipEx color="info" variant="variant" size="small" className="ml5" label="처리중" />
                : ! isCheckValue(formik.values.isFile) || parseInt(formik.values.isFile) === 0
                ? <ChipEx color="error" variant="variant" size="small" className="ml5 f12" label="미등록" />
                : parseInt(formik.values.isFile) === 1
                ? <ChipEx color="secondary" variant="variant" size="small" className="ml5 f12" label="등록중" />
                : parseInt(formik.values.isFile) === 2
                ? <ChipEx color="success" variant="variant" size="small" className="ml5 f12" label="등록완료" />
                : <ChipEx color="primary" variant="variant" size="small" className="ml5 f12" label="처리완료" />
              }
              <div style={{display: "inline-block", verticalAlign: 'middle'}}>
                <span className="ml5 bold f14">[{formik.values.projectNumber}]</span>
                <span className="pad-newLine"></span>
                <span className="ml5 bold f14">{formik.values.projectName}</span>
              </div>
            </div>
          </header>

          <table className="input-table mb15">
            <colgroup>
              <col style={{width: '8%'}} />
              <col style={{width: '17%'}} />
              <col style={{width: '8%'}} />
              <col style={{width: '17%'}} />
              <col style={{width: '8%'}} />
              <col style={{width: '17%'}} />
              <col style={{width: '8%'}} />
              <col style={{width: '17%'}} />
            </colgroup>

            <tbody>
              <tr>
                <th scope="row"><label htmlFor="projectDivision">구분</label></th>
                <td className="pl10">
                  {formik.values.projectDivisionName}
                </td>
                <th scope="row"><label htmlFor="projectYear">년도</label></th>
                <td className="pl10">
                  {formik.values.projectYear}
                </td>
                <th scope="row"><label htmlFor="projectNumber">PJ.NO</label></th>
                <td className="pl10">
                  {formik.values.projectNumber}
                </td>
                <th scope="row"><label htmlFor="projectStatusName">PJ 상태</label></th>
                <td className="pl10">
                  {formik.values.projectStatusName}
                </td>
              </tr>
              <tr>
                <th scope="row"><label htmlFor="projectName">PJ명</label></th>
                <td colSpan={3} className="pl10">
                  {formik.values.projectName}
                </td>
                <th scope="row"><label htmlFor="projectPmName">담당PM</label></th>
                <td className="pl10">
                  {formik.values.projectPmName}
                </td>
                <th scope="row"><label htmlFor="projectApmName">담당APM</label></th>
                <td className="pl10">
                  {formik.values.projectApmName}
                </td>
              </tr>
              <tr>
                <th scope="row"><label htmlFor="projectTypeName">유형</label></th>
                <td className="pl10">
                  {formik.values.projectTypeName}
                </td>
                <th scope="row"><label htmlFor="useSeal">사용인감</label></th>
                <td className="pl10">
                  {formik.values.useSeal}
                </td>
                <th scope="row"><label htmlFor="projectTaxRate">과세/면세</label></th>
                <td className="pl10">
                  {formik.values.projectTaxRate}
                  {(formik.values.projectTaxRate || '' ) !== '' && '%'}
                </td>
                <th scope="row"><label htmlFor="">실행계획변경</label></th>
                <td className="pl10">
                </td>
              </tr>
              <tr>
                <th scope="row"><label htmlFor="indirectCostRate">간접비(%)</label></th>
                <td className="pl10">
                  {formik.values.indirectCostRate}
                </td>
                <th scope="row"><label htmlFor="partialIndirectCostRate">부분간접비(%)</label></th>
                <td className="pl10">
                  {formik.values.partialIndirectCostRate}
                </td>
                <th scope="row"><label htmlFor="totalIndirectionCostRate">전사간접비(%)</label></th>
                <td className="pl10">
                  {formik.values.totalIndirectionCostRate}
                </td>
                <th scope="row"><label htmlFor="">항목</label></th>
                <td className="pl10">
                </td>
              </tr>
              <tr>
                <th scope="row"><label htmlFor="departmentName">주관부서</label></th>
                <td className="pl10">
                  {formik.values.departmentName}
                </td>
                <th scope="row"><label htmlFor="departmentName2">부서2</label></th>
                <td className="pl10">
                  {formik.values.departmentName2}
                </td>
                <th scope="row"><label htmlFor="departmentName3">부서3</label></th>
                <td className="pl10">
                  {formik.values.departmentName3}
                </td>
                <th scope="row"><label htmlFor="departmentName4">부서4</label></th>
                <td className="pl10">
                  {formik.values.departmentName4}
                </td>
              </tr>
              <tr>
                <th scope="row"><label htmlFor="departmentRate">지분율(%)</label></th>
                <td className="pl10">
                  {formik.values.departmentRate}
                  {(formik.values.departmentRate || '') !== '' && '%'}
                </td>
                <th scope="row"><label htmlFor="departmentRate2">지분율(%)</label></th>
                <td className="pl10">
                  {formik.values.departmentRate2}
                  {(formik.values.departmentRate2 || '') !== '' && '%'}
                </td>
                <th scope="row"><label htmlFor="departmentRate3">지분율(%)</label></th>
                <td className="pl10">
                  {formik.values.departmentRate3}
                  {(formik.values.departmentRate3 || '') !== '' && '%'}
                </td>
                <th scope="row"><label htmlFor="departmentRate4">지분율(%)</label></th>
                <td className="pl10">
                  {formik.values.departmentRate4}
                  {(formik.values.departmentRate4 || '') !== '' && '%'}
                </td>
              </tr>
              <tr>
                <th scope="row"><label htmlFor="builderName">건축주</label></th>
                <td className="pl10">
                  {formik.values.builderName}
                </td>
                <th scope="row"><label htmlFor="builderCharge">담당(건축주)</label></th>
                <td className="pl10">
                  {formik.values.builderCharge}
                </td>
                <th scope="row"><label htmlFor="builderTel">전화(건축주)</label></th>
                <td className="pl10">
                  {formik.values.builderTel}
                </td>
                <th scope="row"><label htmlFor="builderFax">FAX(건축주)</label></th>
                <td className="pl10">
                  {formik.values.builderFax}
                </td>
              </tr>
              <tr>
                <th scope="row"><label htmlFor="builderAddress">주소(건축주)</label></th>
                <td colSpan={7} className="pl10">
                  {formik.values.builderAddress}
                </td>
              </tr>
              <tr>
                <th scope="row"><label htmlFor="location">위치</label></th>
                <td colSpan={3} className="pl10">
                  {formik.values.location}
                </td>
                <th scope="row"><label htmlFor="scale">규모</label></th>
                <td colSpan={3} className="pl10">
                  {formik.values.scale}
                </td>
              </tr>
              <tr>
                <th scope="row"><label htmlFor="purpose">용도</label></th>
                <td colSpan={3} className="pl10">
                  {formik.values.purpose}
                </td>
                <th scope="row"><label htmlFor="structure">구조</label></th>
                <td colSpan={3} className="pl10">
                  {formik.values.structure}
                </td>
              </tr>
              <tr>
                <th scope="row"><label htmlFor="landSize">대지면적(㎡)</label></th>
                <td className="pl10">
                  {comma(formik.values.landSize)}
                </td>
                <th scope="row"><label htmlFor="buildSize">건축면적(㎡)</label></th>
                <td className="pl10">
                  {comma(formik.values.buildSize)}
                </td>
                <th scope="row"><label htmlFor="totalSize">연면적(㎡)</label></th>
                <td className="pl10">
                  {comma(formik.values.totalSize)}
                </td>
                <th scope="row"><label htmlFor="treeSize">조경면적(㎡)</label></th>
                <td className="pl10">
                  {comma(formik.values.treeSize)}
                </td>
              </tr>
              <tr>
                <th scope="row"><label htmlFor="bigo">비고</label></th>
                <td colSpan={7} className="pl10">
                  {formik.values.bigo}
                </td>
              </tr>
            </tbody>
          </table>

          <h4 className="mt20 mb10">프로젝트 추가정보</h4>
          <table className="input-table mb15">
            <colgroup>
              <col style={{width: '8%'}} />
              <col style={{width: '17%'}} />
              <col style={{width: '8%'}} />
              <col style={{width: '17%'}} />
              <col style={{width: '8%'}} />
              <col style={{width: '17%'}} />
              <col style={{width: '8%'}} />
              <col style={{width: '17%'}} />
            </colgroup>

            <tbody>
              <tr>
                <th scope="row"><label htmlFor="businessType">사업유형</label></th>
                <td>
                  <SelectEx
                    name="businessType"
                    formik={formik}
                    fullWidth={true}
                    label="사업유형"
                    data={[
                      {label: '선택 안함', value: ''}
                    ].concat(props?.businessType)}
                  />
                </td>
                <th scope="row"><label htmlFor="builderType">건축주유형</label></th>
                <td>
                  <SelectEx
                    name="builderType"
                    formik={formik}
                    fullWidth={true}
                    label="건축주유형"
                    data={[
                      {label: '선택 안함', value: ''}
                    ].concat(props?.builderType)}
                  />
                </td>
                <th></th>
                <td colSpan={3}></td>
              </tr>
              <tr>
                <th scope="row"><label htmlFor="orderCompanyId">발주처</label></th>
                <td colSpan={3}>
                  <InputEx
                    name="orderCompanyId"
                    formik={formik}
                    fullWidth={true}
                  />
                </td>
                <th scope="row"><label htmlFor="builderCompanyId">시공사</label></th>
                <td colSpan={3}>
                  <SelectSearchEx
                    name="builderCompanyId"
                    formik={formik}
                    data={[
                      {label: '시공사 선택', value: ''}
                    ].concat(props?.company)}
                    height={28}
                    isMulti={true}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    placeholder="시공사 선택"
                    fullWidth={true}
                    optionWidth="100%"
                  />
                </td>
              </tr>
              <tr>
                <th scope="row"><label htmlFor="purpose1">용도</label></th>
                <td colSpan={3}>
                  <InputEx
                    name="purpose1"
                    formik={formik}
                    fullWidth={true}
                    disabled={true}
                    style={{width: 'calc(100% - 65px)'}}
                  />

                  <ButtonEx
                    title="검색"
                    auth="isModify"
                    color="inherit"
                    variant="outlined"
                    className="fr"
                    onClick={() => setOpenModal({open: true, modal: 'purpose', data: {}})}
                  />
                </td>
                <th scope="row"><label htmlFor="purposeExtra">용도기타</label></th>
                <td colSpan={3}>
                  <InputEx
                    name="purposeExtra"
                    formik={formik}
                    fullWidth={true}
                  />
                </td>
              </tr>
              <tr>
                <th scope="row"><label htmlFor="purposeRegion">용도지역</label></th>
                <td colSpan={3}>
                  <InputEx
                    name="purposeRegion"
                    formik={formik}
                    fullWidth={true}
                    disabled={true}
                    style={{width: 'calc(100% - 65px)'}}
                  />

                  <ButtonEx
                    title="검색"
                    auth="isModify"
                    color="inherit"
                    variant="outlined"
                    className="fr"
                    onClick={() => setOpenModal({open: true, modal: 'purposeRegion', data: {}})}
                  />
                  {/*
                  <SelectSearchEx
                    name="purposeRegion"
                    formik={formik}
                    data={[
                      {label: '선택 안함', value: ''}
                    ].concat(props?.purposeRegion)}
                    height={28}
                    isMulti={false}
                    closeMenuOnSelect={true}
                    hideSelectedOptions={true}
                    placeholder="용도지역 선택"
                    fullWidth={true}
                    optionWidth="100%"
                  />
                  */}
                </td>
                <th scope="row"><label htmlFor="pointOut">지목</label></th>
                <td colSpan={3}>
                  <InputEx
                    name="pointOut"
                    formik={formik}
                    fullWidth={true}
                    disabled={true}
                    style={{width: 'calc(100% - 65px)'}}
                  />

                  <ButtonEx
                    title="검색"
                    auth="isModify"
                    color="inherit"
                    variant="outlined"
                    className="fr"
                    onClick={() => setOpenModal({open: true, modal: 'pointOut', data: {}})}
                  />
                  {/*
                  <SelectSearchEx
                    name="pointOut"
                    formik={formik}
                    data={[
                      {label: '선택 안함', value: ''}
                    ].concat(props?.pointOut)}
                    height={28}
                    isMulti={false}
                    closeMenuOnSelect={true}
                    hideSelectedOptions={true}
                    placeholder="지목 선택"
                    fullWidth={true}
                    optionWidth="100%"
                  />
                  */}
                </td>
              </tr>
              {/*
              <tr>
                <th scope="row"><label htmlFor="doroAddress">도로명 주소</label></th>
                <td colSpan={3}>
                  <InputEx
                    name="doroAddress"
                    formik={formik}
                    fullWidth={true}
                    style={{width: 'calc(100% - 90px)'}}
                  />

                  <ButtonEx
                    title="주소찾기"
                    auth="isModify"
                    color="inherit"
                    variant="outlined"
                    className="fr"
                    onClick={() => setOpenModal({open: true, modal: 'daum', data: {}})}
                  />
                </td>
                <th scope="row"><label htmlFor="doroAddressDetail">상세 주소</label></th>
                <td colSpan={3}>
                  <InputEx
                    name="doroAddressDetail"
                    formik={formik}
                    fullWidth={true}
                  />
                </td>
                <th scope="row"><label htmlFor="parcelAddress">지번</label></th>
                <td colSpan={3}>
                  <InputEx
                    name="parcelAddress"
                    formik={formik}
                    fullWidth={true}
                  />
                </td>
              </tr>
              */}
              <tr>
                <th scope="row"><label htmlFor="hashTag">키워드</label></th>
                <td colSpan={3}>
                  <InputEx
                    name="hashTag"
                    formik={formik}
                    fullWidth={true}
                  />
                </td>
                <th scope="row"><label htmlFor="responsibilityConstructor">책임건축사</label></th>
                <td colSpan={3}>
                  <InputEx
                    name="responsibilityConstructor"
                    formik={formik}
                    fullWidth={true}
                  />
                </td>
              </tr>
              <tr>
                <th scope="row"><label htmlFor="departmentNameLast">주관부서</label></th>
                <td colSpan={3}>
                  <Button
                    color="error"
                    variant="outlined"
                    className="mr5"
                    disabled={formik.values?.departmentNameLast === ''}
                    style={{padidng: '0px !important', width: '30px'}}
                    onClick={() => setOpenModal({open: true, modal: 'reason', data: 'department'})}
                  >X</Button>

                  <InputEx
                    name="departmentNameLast"
                    formik={formik}
                    fullWidth={true}
                    disabled={true}
                    style={{width: 'calc(100% - 220px)'}}
                  />

                  <div className="fr">
                    <Button
                      color="secondary"
                      variant='outlined'
                      style={{width: '82px'}}
                      onClick={() => setOpenModal({open: true, modal: 'departmentSelect', data: formik.values})}
                    >주관부서</Button>

                    <Button
                      color="primary"
                      variant="outlined"
                      className="ml5"
                      onClick={() => setOpenModal({open: true, modal: 'department', data: formik.values})}
                    >변경이력</Button>
                  </div>
                </td>
                <th scope="row"><label htmlFor="readingGrade">기본열람등급</label></th>
                <td colSpan={3}>
                  <ToggleButtonGroupEx
                    name="readingGrade"
                    exclusive={true}
                    formik={formik}
                    className="search-toggle-btn"
                    data={[
                      {label: '전체열람', value: 0},
                      {label: '목록열람', value: 1},
                      {label: '열람불가', value: 2},
                    ]}
                  />

                  <span className="ml10"></span>

                  <ToggleButtonGroupEx
                    name="readingProject"
                    exclusive={true}
                    formik={formik}
                    className="search-toggle-btn pad-mt1"
                    data={[
                      {label: '기본정보만 열람', value: 0},
                      {label: '추가정보도 열람', value: 1},
                    ]}
                  />
                </td>
              </tr>
              <tr>
              <th scope="row"><label htmlFor="projectPmNameLast">담당PM</label></th>
                <td colSpan={3}>
                  <Button
                    color="error"
                    variant="outlined"
                    className="mr5"
                    disabled={formik.values?.projectPmNameLast === ''}
                    style={{padidng: '0px !important', width: '30px'}}
                    onClick={() => setOpenModal({open: true, modal: 'reason', data: 'pm'})}
                  >X</Button>

                  <InputEx
                    name="projectPmNameLast"
                    disabled={true}
                    formik={formik}
                    fullWidth={true}
                    style={{width: 'calc(100% - 220px)'}}
                  />

                  <div className="fr">
                    <Button
                      color="secondary"
                      variant='outlined'
                      onClick={() => setOpenModal({open: true, modal: 'pmSelect', data: 'pm'})}
                      style={{width: '82px'}}
                    >담당PM</Button>

                    <Button
                      color="primary"
                      variant="outlined"
                      className="ml5"
                      onClick={() => setOpenModal({open: true, modal: 'pm', data: formik.values})}
                    >변경이력</Button>
                  </div>
                </td>
                <th scope="row"><label htmlFor="projectApmNameLast">담당APM</label></th>
                <td colSpan={3}>
                  <Button
                    color="error"
                    variant="outlined"
                    className="mr5"
                    disabled={formik.values?.projectApmNameLast === ''}
                    style={{padidng: '0px !important', width: '30px'}}
                    onClick={() => setOpenModal({open: true, modal: 'reason', data: 'apm'})}
                  >X</Button>

                  <InputEx
                    name="projectApmNameLast"
                    disabled={true}
                    formik={formik}
                    fullWidth={true}
                    style={{width: 'calc(100% - 220px)'}}
                  />

                  <div className="fr">
                    <Button
                      color="secondary"
                      variant='outlined'
                      onClick={() => setOpenModal({open: true, modal: 'pmSelect', data: 'apm'})}
                    >담당APM</Button>

                    <Button
                      color="primary"
                      variant="outlined"
                      className="ml5"
                      onClick={() => setOpenModal({open: true, modal: 'apm', data: formik.values})}
                    >변경이력</Button>
                  </div>
                </td>
              </tr>
              {/*
              <tr>
                <th scope="row"><label htmlFor="underLayer">층수(지하)</label></th>
                <td>
                  <InputEx
                    name="underLayer"
                    formik={formik}
                    fullWidth={true}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">층</InputAdornment>,
                    }}
                    onChange={(event) => {
                      if (event.nativeEvent.data ){
                        event.target.value = onlyNumber(event.target.value);
                      }
                    }}
                    onBlur={(event) => {
                      event.target.value = onlyNumber(event.target.value);
                    }}
                  />
                </td>
                <th scope="row"><label htmlFor="groundLayer">층수(지상)</label></th>
                <td>
                  <InputEx
                    name="groundLayer"
                    formik={formik}
                    fullWidth={true}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">층</InputAdornment>,
                    }}
                    onChange={(event) => {
                      if (event.nativeEvent.data ){
                        event.target.value = onlyNumber(event.target.value);
                      }
                    }}
                    onBlur={(event) => {
                      event.target.value = onlyNumber(event.target.value);
                    }}
                  />
                </td>
                <th scope="row"><label htmlFor="numberHouse">세대수</label></th>
                <td>
                  <InputEx
                    name="numberHouse"
                    formik={formik}
                    fullWidth={true}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">세대</InputAdornment>,
                    }}
                    onChange={(event) => {
                      if (event.nativeEvent.data ){
                        event.target.value = onlyNumber(event.target.value);
                      }
                    }}
                    onBlur={(event) => {
                      event.target.value = onlyNumber(event.target.value);
                    }}
                  />
                </td>
                <th scope="row"><label htmlFor="scaleExtra">규모(기타)</label></th>
                <td>
                  <InputEx
                    name="scaleExtra"
                    formik={formik}
                    fullWidth={true}
                  />
                </td>
              </tr>
              */}
              <tr>
                <th scope="row"><label htmlFor="structure1">구조</label></th>
                <td colSpan={3}>
                  <InputEx
                    name="structure1"
                    formik={formik}
                    fullWidth={true}
                    disabled={true}
                    style={{width: 'calc(100% - 65px)'}}
                  />

                  <ButtonEx
                    title="검색"
                    auth="isModify"
                    color="inherit"
                    variant="outlined"
                    className="fr"
                    onClick={() => setOpenModal({open: true, modal: 'structure', data: {}})}
                  />
                </td>

                <th scope="row"><label htmlFor="structureExtra">구조(추가정보)</label></th>
                <td colSpan={3}>
                  <InputEx
                    name="structureExtra"
                    formik={formik}
                    fullWidth={true}
                  />
                </td>
              </tr>
              <tr>
                <th scope="row"><label htmlFor="partMember">참여구성원</label></th>
                <td colSpan={7}>
                  <TextareaEx
                    name="partMember"
                    formik={formik}
                    minRows="5"
                    maxRows="5"
                    className="p10"
                    style={{width: '100%'}}
                  />
                </td>
              </tr>
              <tr>
                <th scope="row"><label htmlFor="relationProject">연관프로젝트</label></th>
                <td colSpan={7}>
                  <TextareaEx
                    name="relationProject"
                    formik={formik}
                    minRows="5"
                    maxRows="5"
                    className="p10"
                    style={{width: '100%'}}
                  />
                </td>
              </tr>
              <tr>
                <th scope="row"><label htmlFor="memo">비고</label></th>
                <td colSpan={7}>
                  <TextareaEx
                    name="memo"
                    formik={formik}
                    minRows="5"
                    maxRows="5"
                    className="p10"
                    style={{width: '100%'}}
                  />
                </td>
              </tr>
            </tbody>
          </table>

          {/* 규모 정보 */}
          <section className="">
            <header className="admin-cms-search-header">
              <div className="left">
                <div className="fl">
                  <BtnAgGridSave className="fl" click={setGridUpdateScale}></BtnAgGridSave>
                </div>
                <div className="fl">
                  <h4 className="f15 sub-title">규모 정보</h4>
                </div>
              </div>

              <div className="text-right">
                <ButtonEx
                  auth="isModify"
                  title="규모 삭제"
                  color="error"
                  variant="outlined"
                  onClick={() => {
                    let nodes = gridApiScale.getSelectedRows();

                    if( nodes.length < 1 ) {
                      alertMsg("삭제할 규모 정보를 선택해 주시기 바랍니다.", "W", MESSAGE_DELAY);
                      return;
                    }

                    setOpenModal({open: true, modal: 'scaleDel', data: {}})
                  }}
                />

                <ButtonEx
                  auth="isModify"
                  title="규모 추가"
                  color="primary"
                  variant="outlined"
                  className="ml5"
                  onClick={() => setOpenModal({open: true, modal: 'scaleAdd', data: {seq: '', projectNumber: formik.values.projectNuber}})}
                />
              </div>
              <div className="clearfix"></div>
            </header>

            <div className="ag-theme-balham mt5" style={{height: '200px'}}>
              <AgGridReact
                defaultColDef={{
                  sortable: true,
                  resizable: true,
                  filter: false,
                  lockVisible: true,
                  tooltipComponent: customTooltipAgGrid,
                }}
                tooltipShowDelay={0}
                tooltipHideDelay={2000}
                columnDefs={columnDefsScale}
                rowData={scaleArrayList}
                rowSelection={'multiple'}
                onGridReady={(event) => {
                  gridConfigScale(event.columnApi);
                  setGridApiScale(event.api);
                }}
                onRowDoubleClicked={(event) => {
                  setOpenModal({
                    open: true,
                    modal: 'scaleAdd',
                    data: event.data
                  })
                }}
                overlayNoRowsTemplate = "검색된 내용이 없습니다."
              />
            </div>
          </section>

          {/* 신기술 정보 */}
          <section className="mt10">
            <header className="admin-cms-search-header">
              <div className="left">
                <div className="fl">
                  <BtnAgGridSave className="fl" click={setGridUpdateNewTech}></BtnAgGridSave>
                </div>
                <div className="fl">
                  <h4 className="f15 sub-title">신기술 정보</h4>
                </div>
              </div>

              <div className="text-right">
                <ButtonEx
                  title="신기술 검색"
                  auth="isModify"
                  color="inherit"
                  variant="outlined"
                  className="fr"
                  onClick={() => setOpenModal({open: true, modal: 'newTech', data: {}})}
                />
              </div>
              <div className="clearfix"></div>
            </header>

            <div className="ag-theme-balham mt5" style={{height: '200px'}}>
              <AgGridReact
                defaultColDef={{
                  sortable: true,
                  resizable: true,
                  filter: false,
                  lockVisible: true,
                  tooltipComponent: customTooltipAgGrid,
                }}
                tooltipShowDelay={0}
                tooltipHideDelay={2000}
                columnDefs={columnDefsNewTech}
                rowData={newTechList}
                rowSelection={'single'}
                onGridReady={(event) => {
                  gridConfigNewTech(event.columnApi);
                }}
                overlayNoRowsTemplate = "검색된 내용이 없습니다."
              />
            </div>
          </section>

          {/* 참여업체 정보 */}
          <section className="mt10">
            <header className="admin-cms-search-header">
              <div className="left">
                <div className="fl">
                  <BtnAgGridSave className="fl" click={setGridUpdate}></BtnAgGridSave>
                </div>
                <div className="fl">
                  <h4 className="f15 sub-title">참여업체 정보</h4>
                </div>
              </div>

              <div className="text-right">
                <ButtonEx
                  auth="isModify"
                  title="참여업체 삭제"
                  color="error"
                  variant="outlined"
                  onClick={() => {
                    let nodes = gridApi.getSelectedRows();

                    if( nodes.length < 1 ) {
                      alertMsg("삭제할 참여업체 정보를 선택해 주시기 바랍니다.", "W", MESSAGE_DELAY);
                      return;
                    }

                    setOpenModal({open: true, modal: 'dialogDel', data: {}})
                  }}
                />

                <ButtonEx
                  auth="isModify"
                  title="참여업체 추가"
                  color="primary"
                  variant="outlined"
                  className="ml5"
                  onClick={() => setOpenModal({open: true, modal: 'ownershipAdd', data: {seq: ''}})}
                />
              </div>
              <div className="clearfix"></div>
            </header>

            <div className="ag-theme-balham mt5" style={{height: '200px'}}>
              <AgGridReact
                defaultColDef={{
                  sortable: true,
                  resizable: true,
                  filter: false,
                  lockVisible: true,
                  tooltipComponent: customTooltipAgGrid,
                }}
                tooltipShowDelay={0}
                tooltipHideDelay={2000}
                columnDefs={columnDefs}
                rowData={ownershipList}
                rowSelection={'single'}
                onGridReady={(event) => {
                  gridConfig(event.columnApi);
                  setGridApi(event.api);
                }}
                onRowDoubleClicked={(event) => {
                  setOpenModal({
                    open: true,
                    modal: 'ownershipAdd',
                    data: event.data
                  })
                }}
                overlayNoRowsTemplate = "검색된 내용이 없습니다."
              />
            </div>

            <table className="input-table mb15">
              <colgroup>
                <col style={{width: '10%'}} />
                <col style={{width: '90%'}} />
              </colgroup>

              <tbody>
                <tr>
                  <th scope="row"><label htmlFor="ownershipMemo">참여업체 특이사항</label></th>
                  <td>
                    <TextareaEx
                      name="ownershipMemo"
                      formik={formik}
                      minRows="5"
                      maxRows="5"
                      className="p10"
                      style={{width: '100%'}}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </section>

          {/* 협력업체 정보 */}
          <section className="mt10">
            <header className="admin-cms-search-header">
              <div className="left">
                <div className="fl">
                  <BtnAgGridSave className="fl" click={setGridUpdatePartner}></BtnAgGridSave>
                </div>
                <div className="fl">
                  <h4 className="f15 sub-title">협력업체 정보</h4>
                </div>
              </div>

              <div className="text-right">
                <ButtonEx
                  auth="isModify"
                  title="협력업체 삭제"
                  color="error"
                  variant="outlined"
                  onClick={() => {
                    let nodes = gridApiPartner.getSelectedRows();

                    if( nodes.length < 1 ) {
                      alertMsg("삭제할 협력업체 정보를 선택해 주시기 바랍니다.", "W", MESSAGE_DELAY);
                      return;
                    }

                    setOpenModal({open: true, modal: 'partnerDel', data: {}})
                  }}
                />

                <ButtonEx
                  auth="isModify"
                  title="협력업체 추가"
                  color="primary"
                  variant="outlined"
                  className="ml5"
                  onClick={() => setOpenModal({open: true, modal: 'partnerAdd', data: {seq: ''}})}
                />
              </div>
              <div className="clearfix"></div>
            </header>

            <div className="ag-theme-balham mt5" style={{height: '200px'}}>
              <AgGridReact
                defaultColDef={{
                  sortable: true,
                  resizable: true,
                  filter: false,
                  lockVisible: true,
                  tooltipComponent: customTooltipAgGrid,
                }}
                tooltipShowDelay={0}
                tooltipHideDelay={2000}
                columnDefs={columnDefsPartner}
                rowData={partnerList}
                rowSelection={'single'}
                onGridReady={(event) => {
                  gridConfigPartner(event.columnApi);
                  setGridApiPartner(event.api);
                }}
                onRowDoubleClicked={(event) => {
                  setOpenModal({
                    open: true,
                    modal: 'partnerAdd',
                    data: event.data
                  })
                }}
                overlayNoRowsTemplate = "검색된 내용이 없습니다."
              />
            </div>

            <table className="input-table mb15">
              <colgroup>
                <col style={{width: '10%'}} />
                <col style={{width: '90%'}} />
              </colgroup>

              <tbody>
                <tr>
                  <th scope="row"><label htmlFor="partnerMemo">협력업체 특이사항</label></th>
                  <td>
                    <TextareaEx
                      name="partnerMemo"
                      formik={formik}
                      minRows="5"
                      maxRows="5"
                      className="p10"
                      style={{width: '100%'}}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </section>

          {/* 협업해외사 정보 */}
          <section className="mt10">
            <header className="admin-cms-search-header">
              <div className="left">
                <div className="fl">
                  <BtnAgGridSave className="fl" click={setGridUpdateForeign}></BtnAgGridSave>
                </div>
                <div className="fl">
                  <h4 className="f15 sub-title">협업해외사 정보</h4>
                </div>
              </div>

              <div className="text-right">
                <ButtonEx
                  auth="isModify"
                  title="협업해외사 삭제"
                  color="error"
                  variant="outlined"
                  onClick={() => {
                    let nodes = gridApi.getSelectedRows();

                    if( nodes.length < 1 ) {
                      alertMsg("삭제할 협업해외사 정보를 선택해 주시기 바랍니다.", "W", MESSAGE_DELAY);
                      return;
                    }

                    setOpenModal({open: true, modal: 'foreignDel', data: {}})
                  }}
                />

                <ButtonEx
                  auth="isModify"
                  title="협업해외사 추가"
                  color="primary"
                  variant="outlined"
                  className="ml5"
                  onClick={() => setOpenModal({open: true, modal: 'foreignAdd', data: {seq: ''}})}
                />
              </div>
              <div className="clearfix"></div>
            </header>

            <div className="ag-theme-balham mt5" style={{height: '200px'}}>
              <AgGridReact
                defaultColDef={{
                  sortable: true,
                  resizable: true,
                  filter: false,
                  lockVisible: true,
                  tooltipComponent: customTooltipAgGrid,
                }}
                tooltipShowDelay={0}
                tooltipHideDelay={2000}
                columnDefs={columnDefsForeign}
                rowData={foreignList}
                rowSelection={'single'}
                onGridReady={(event) => {
                  gridConfigForeign(event.columnApi);
                }}
                onRowDoubleClicked={(event) => {
                  setOpenModal({
                    open: true,
                    modal: 'foreignAdd',
                    data: event.data
                  })
                }}
                overlayNoRowsTemplate = "검색된 내용이 없습니다."
              />
            </div>
            <table className="input-table mb15">
              <colgroup>
                <col style={{width: '10%'}} />
                <col style={{width: '90%'}} />
              </colgroup>

              <tbody>
                <tr>
                  <th scope="row"><label htmlFor="foreignMemo">협업해외사 특이사항</label></th>
                  <td>
                    <TextareaEx
                      name="foreignMemo"
                      formik={formik}
                      minRows="5"
                      maxRows="5"
                      className="p10"
                      style={{width: '100%'}}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </section>

          {/* 수상정보 */}
          <section className="mt10">
            <header className="admin-cms-search-header">
              <div className="left">
                <div className="fl">
                  <BtnAgGridSave className="fl" click={setGridUpdateAward}></BtnAgGridSave>
                </div>
                <div className="fl">
                  <h4 className="f15 sub-title">수상정보</h4>
                </div>
              </div>

              <div className="text-right">
                <ButtonEx
                  auth="isModify"
                  title="수상정보 삭제"
                  color="error"
                  variant="outlined"
                  onClick={() => {
                    let nodes = gridApiAward.getSelectedRows();

                    if( nodes.length < 1 ) {
                      alertMsg("삭제할 수상정보를 선택해 주시기 바랍니다.", "W", MESSAGE_DELAY);
                      return;
                    }

                    setOpenModal({open: true, modal: 'awardDel', data: {}})
                  }}
                />

                <ButtonEx
                  auth="isModify"
                  title="수상정보 추가"
                  color="primary"
                  variant="outlined"
                  className="ml5"
                  onClick={() => setOpenModal({open: true, modal: 'awardAdd', data: {seq: ''}})}
                />
              </div>
              <div className="clearfix"></div>
            </header>

            <div className="ag-theme-balham mt5" style={{height: '200px'}}>
              <AgGridReact
                defaultColDef={{
                  sortable: true,
                  resizable: true,
                  filter: false,
                  lockVisible: true,
                  tooltipComponent: customTooltipAgGrid,
                }}
                tooltipShowDelay={0}
                tooltipHideDelay={2000}
                columnDefs={columnDefsAward}
                rowData={awardInfoList}
                rowSelection={'single'}
                onGridReady={(event) => {
                  gridConfigAward(event.columnApi);
                  setGridApiAward(event.api);
                }}
                onRowDoubleClicked={(event) => {
                  setOpenModal({
                    open: true,
                    modal: 'awardAdd',
                    data: event.data
                  })
                }}
                overlayNoRowsTemplate = "검색된 내용이 없습니다."
              />
            </div>
          </section>


          <footer className="mt10 mb30">
            <div className="fr right">
              <ButtonEx
                auth="isWrite"
                title="저장하기"
                color="secondary"
                variant="contained"
                className="Btn ml10"
                onClick={() => {
                  // 참여업체 정보 지분율(%) 100%인지 점검
                  if( ownershipList.length > 0 ) {
                    let rate = 0;
                    ownershipList.forEach((item) => rate += parseFloat(item?.ownershipRate));

                    if( parseInt(rate) !== 100 ) {
                      alertMsg("참여업체 지분율의 합은 100%가 되어야 합니다.", "W", MESSAGE_DELAY);
                      return;
                    }
                  }

                  setOpenModal({open: true, modal: 'dialog', data: {}});
                }}
              />

              {isCheckValue(formik.values.detailSeq) && parseInt(formik.values.isConfirm) !== 1 &&
                <ButtonEx
                  auth="isWrite"
                  title="등록완료"
                  color="primary"
                  variant="contained"
                  className="Btn ml10"
                  onClick={() => {
                    // 참여업체 정보 지분율(%) 100%인지 점검
                    if( ownershipList.length > 0 ) {
                      let rate = 0;
                      ownershipList.forEach((item) => rate += parseFloat(item?.ownershipRate));

                      if( parseInt(rate) !== 100 ) {
                        alertMsg("참여업체 지분율의 합은 100%가 되어야 합니다.", "W", MESSAGE_DELAY);
                        return;
                      }
                    }

                    setOpenModal({open: true, modal: 'dialogComplete', data: {}});
                  }}
                />
              }
            </div>

            <div className="clearfix"></div>
          </footer>

          <section className="mb80 mt20">
            <header className="clearfix mb5">
              <h4 className="fl f14">대표 파일정보 (최대 100개 표시)</h4>
              <div className="fr f14">전체파일수: {comma(pathInfo.totalFileCount)}개 전체용량: {formatBytes(pathInfo.totalSize)}</div>
            </header>

            <div className="fileBox">
              {previewList?.length > 0 &&
                <ul>
                  { previewList.map((item, index) => {
                    let url = 'http://archive.dagroup.co.kr/synap/SynapDocViewServer/thumbnail/'
                      + item.thumbnail + '/0?dpi=L&withXml=false&resizeType=M&fileExt=jpg&resizeValue=210&quality=80';

                    return (
                      <React.Fragment key={index}>
                        <li>
                          <div className="img">
                            { // 이미지 파일 미리보기
                              IMAGE_FILE.includes(item.ext?.toLowerCase()) &&
                              <img src={"/api/v1/project/image/download?thumbnail=" + base64_encode(item.thumbnail)} alt={item.fileName}
                                onError={(e) => {
                                  e.target.src = NoImage;
                                }}
                              />
                            }

                            { // 동영상 파일 미리보기
                              MOVIE_FILE_STREAM.includes(item.ext?.toLowerCase()) &&
                              <>
                                <img
                                  src={"/api/v1/project/image/download?thumbnail=" + base64_encode(item.thumbnail)}
                                  alt={item.fileName}
                                  onError={(e) => {
                                    e.target.src = NoImage;
                                  }}
                                  className="cp"
                                  style={previewMovie === item.seq ? {display: 'none', width: '100%'} : {width: '100%'}}
                                  onMouseEnter={(e) => {
                                    if( MOVIE_FILE_STREAM.includes(item.ext?.toLowerCase()) ) {
                                      setPreviewMovie(item.seq);
                                    }
                                  }}
                                />
                                <video
                                  autoPlay
                                  muted
                                  loop
                                  playsInline
                                  width="100%"
                                  height="100%"
                                  ref={movieRef}
                                  style={previewMovie !== item.seq ? {display: 'none'} : {}}
                                  className="cp"
                                  onMouseLeave={(e) => {
                                    if( MOVIE_FILE_STREAM.includes(item.ext?.toLowerCase()) ) {
                                      setPreviewMovie('');
                                    }
                                  }}
                                >
                                  <source src={baseURL + '/api/v1/file/download?seq=' + item.seq} type="video/mp4" />
                                </video>
                              </>
                            }

                            { // 기타 파일 미리보기
                              ! IMAGE_FILE.includes(item.ext?.toLowerCase()) && ! MOVIE_FILE_STREAM.includes(item.ext?.toLowerCase()) &&
                              <img
                                src={url}
                                alt={item.fileName}
                                onError={(e) => {
                                  e.target.src = NoImage;
                                }}
                              />
                            }
                          </div>
                          <div className="name">{item.fileName}</div>
                          <div className="size">{formatBytes(item.fileSize)}</div>
                          { ! MOVIE_FILE.includes(item.ext?.toLowerCase()) &&
                            <div className="hover-wrap">
                              <Button
                                variant="contained"
                                color="primary"
                                className="Btn"
                                onClick={() => {
                                  let url = 'http://archive.dagroup.co.kr/synap/SynapDocViewServer/job?fileType=Local&sync=true&fid=' + item.seq + '&filePath=';
                                  window.open(url + encodeURIComponent(item.destPath))
                                  //setOpenModal({open: true, modal: 'previewFile', data: item})
                                }}
                              >미리보기</Button>
                              <div className="darkness"></div>
                            </div>
                          }
                        </li>
                      </React.Fragment>
                    )
                  })}
                </ul>
              }

              {previewList?.length < 1 &&
                <div style={{height: '190px', display: 'flex', alignItems: 'center'}}>
                  <div style={{width: '100%', textAlign: 'center'}}>등록된 미리보기 파일이 없습니다.</div>
                </div>
              }
            </div>
          </section>

          { // 신기술
            openModal.open && openModal.modal === 'newTech' &&
            <Dialog
              open={openModal}
              onClose={() => (event, reason) => {
                if(reason && reason === "backdropClick") return;
                setOpenModal({open: false, modal: openModal.modal, data: {}});
              }}
              PaperComponent={PaperComponent}
              aria-labelledby="draggable-dialog-title"
              sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "1200px",  // Set your width here
                  },
                },
              }}
            >
              <section className="modal">
                <NewTechModal
                  open={openModal.open}
                  close={() => setOpenModal({ open: false, modalName: openModal.modal, data: openModal.data })}
                  data={openModal.data}
                  formik={formik}
                  newTech={props.newTech}
                  indicator={props.indicator}
                  history={props.history}
                />
              </section>
            </Dialog>
          }

          { // 용도
            openModal.open && openModal.modal === 'purpose' &&
            <Dialog
              open={openModal}
              onClose={() => (event, reason) => {
                if(reason && reason === "backdropClick") return;
                setOpenModal({open: false, modal: openModal.modal, data: {}});
              }}
              PaperComponent={PaperComponent}
              aria-labelledby="draggable-dialog-title"
              sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "750px",  // Set your width here
                  },
                },
              }}
            >
              <section className="modal">
                <PurposeModal
                  open={openModal.open}
                  close={() => setOpenModal({ open: false, modalName: openModal.modal, data: openModal.data })}
                  data={openModal.data}
                  formik={formik}
                  purpose1={props.purpose1}
                  purpose2={props.purpose2}
                  purpose1Code={props.purpose1Code}
                  indicator={props.indicator}
                  history={props.history}
                />
              </section>
            </Dialog>
          }

          { // 용도지역
            openModal.open && openModal.modal === 'purposeRegion' &&
            <Dialog
              open={openModal}
              onClose={() => (event, reason) => {
                if(reason && reason === "backdropClick") return;
                setOpenModal({open: false, modal: openModal.modal, data: {}});
              }}
              PaperComponent={PaperComponent}
              aria-labelledby="draggable-dialog-title"
              sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "775px",  // Set your width here
                  },
                },
              }}
            >
              <section className="modal">
                <PurposeRegionModal
                  open={openModal.open}
                  close={() => setOpenModal({ open: false, modalName: openModal.modal, data: openModal.data })}
                  data={openModal.data}
                  formik={formik}
                  purposeRegion={props.purposeRegion}
                  indicator={props.indicator}
                  history={props.history}
                />
              </section>
            </Dialog>
          }

          { // 지목
            openModal.open && openModal.modal === 'pointOut' &&
            <Dialog
              open={openModal}
              onClose={() => (event, reason) => {
                if(reason && reason === "backdropClick") return;
                setOpenModal({open: false, modal: openModal.modal, data: {}});
              }}
              PaperComponent={PaperComponent}
              aria-labelledby="draggable-dialog-title"
              sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "775px",  // Set your width here
                  },
                },
              }}
            >
              <section className="modal">
                <PointOutModal
                  open={openModal.open}
                  close={() => setOpenModal({ open: false, modalName: openModal.modal, data: openModal.data })}
                  data={openModal.data}
                  formik={formik}
                  pointOut={props.pointOut}
                  indicator={props.indicator}
                  history={props.history}
                />
              </section>
            </Dialog>
          }

          { // 구조 선택
            openModal.open && openModal.modal === 'structure' &&
            <Dialog
              open={openModal}
              onClose={() => (event, reason) => {
                if(reason && reason === "backdropClick") return;
                setOpenModal({open: false, modal: openModal.modal, data: {}});
              }}
              PaperComponent={PaperComponent}
              aria-labelledby="draggable-dialog-title"
              sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "750px",  // Set your width here
                  },
                },
              }}
            >
              <section className="modal">
                <StructureModal
                  open={openModal.open}
                  close={() => setOpenModal({ open: false, modalName: openModal.modal, data: openModal.data })}
                  data={openModal.data}
                  formik={formik}
                  structure1={props.structure1}
                  structure2={props.structure2}
                  indicator={props.indicator}
                  history={props.history}
                />
              </section>
            </Dialog>
          }


          { // 주관부서 선택
            openModal.open && openModal.modal === 'departmentSelect' &&
            <Dialog
              open={openModal}
              onClose={() => (event, reason) => {
                if(reason && reason === "backdropClick") return;
                setOpenModal({open: false, modal: openModal.modal, data: {}});
              }}
              PaperComponent={PaperComponent}
              aria-labelledby="draggable-dialog-title"
              sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "600px",  // Set your width here
                  },
                },
              }}
            >
              <section className="modal">
                <DepartmentModal
                  open={openModal.open}
                  close={() => setOpenModal({ open: false, modalName: openModal.modal, data: openModal.data })}
                  data={openModal.data}
                  selected={props.selected}
                  formik={formik}
                  indicator={props.indicator}
                  history={props.history}
                />
              </section>
            </Dialog>
          }

          { // 주관부서 변경이력 관리
            openModal.open && openModal.modal === 'department' &&
            <Dialog
              open={openModal}
              onClose={() => (event, reason) => {
                if(reason && reason === "backdropClick") return;
                setOpenModal({open: false, modal: openModal.modal, data: {}});
              }}
              PaperComponent={PaperComponent}
              aria-labelledby="draggable-dialog-title"
              sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "1000px",  // Set your width here
                  },
                },
              }}
            >
              <section className="modal">
                <DepartmentHistoryModal
                  open={openModal.open}
                  close={() => setOpenModal({ open: false, modalName: openModal.modal, data: openModal.data })}
                  data={openModal.data}
                  formik={formik}
                  indicator={props.indicator}
                  history={props.history}
                />
              </section>
            </Dialog>
          }

          { // pm 선택
            openModal.open && openModal.modal === 'pmSelect' &&
            <Dialog
              open={openModal}
              onClose={() => (event, reason) => {
                if(reason && reason === "backdropClick") return;
                setOpenModal({open: false, modal: openModal.modal, data: {}});
              }}
              PaperComponent={PaperComponent}
              aria-labelledby="draggable-dialog-title"
              sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "1000px",  // Set your width here
                  },
                },
              }}
            >
              <section className="modal">
                <PmSelectModal
                  open={openModal.open}
                  close={() => setOpenModal({ open: false, modalName: openModal.modal, data: openModal.data })}
                  data={openModal.data}
                  selected={props.selected}
                  formik={formik}
                  indicator={props.indicator}
                  history={props.history}
                />
              </section>
            </Dialog>
          }

          { // 프로젝트 PM 변경이력
            ( ['pm', 'apm'].includes(openModal.modal) && openModal.open ) &&
            <Dialog
              open={openModal}
              onClose={() => (event, reason) => {
                if(reason && reason === "backdropClick") return;
                setOpenModal({open: false, modal: openModal.modal, data: {}});
              }}
              PaperComponent={PaperComponent}
              aria-labelledby="draggable-dialog-title"
              sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "1000px",  // Set your width here
                  },
                },
              }}
            >
              <section className="modal">
                <ProjectPmNameHistoryModal
                  open={openModal.open}
                  close={() => setOpenModal({ open: false, modalName: openModal.modal, data: openModal.data })}
                  modal={openModal.modal}
                  data={openModal.data}
                  formik={formik}
                  indicator={props.indicator}
                  history={props.history}
                />
              </section>
            </Dialog>
          }


          { // 규모정보 추가/수정
            openModal.open && openModal.modal === 'scaleAdd' &&
            <Dialog
              open={openModal}
              onClose={() => (event, reason) => {
                if(reason && reason === "backdropClick") return;
                setOpenModal({open: false, modal: openModal.modal, data: {}});
              }}
              PaperComponent={PaperComponent}
              aria-labelledby="draggable-dialog-title"
              sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "600px",  // Set your width here
                  },
                },
              }}
            >
              <section className="modal">
                <ScaleModal
                  open={openModal.open}
                  close={() => setOpenModal({ open: false, modalName: openModal.modal, data: openModal.data })}
                  data={openModal.data}
                  formik={formik}
                  gridApi={gridApiScale}
                  scaleList={scaleArrayList}
                  setScaleList={setScaleArrayList}
                  indicator={props.indicator}
                  history={props.history}
                />
              </section>
            </Dialog>
          }

          { // 참여업체정보 추가/수정
            openModal.open && openModal.modal === 'ownershipAdd' &&
            <Dialog
              open={openModal}
              onClose={() => (event, reason) => {
                if(reason && reason === "backdropClick") return;
                setOpenModal({open: false, modal: openModal.modal, data: {}});
              }}
              PaperComponent={PaperComponent}
              aria-labelledby="draggable-dialog-title"
              sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "550px",  // Set your width here
                  },
                },
              }}
            >
              <section className="modal">
                <ProjectOwnershipModal
                  open={openModal.open}
                  close={() => setOpenModal({ open: false, modalName: openModal.modal, data: openModal.data })}
                  data={openModal.data}
                  formik={formik}
                  list={ownershipList}
                  setList={setOwnershipList}
                  selected={props.selected}
                  indicator={props.indicator}
                  history={props.history}
                />
              </section>
            </Dialog>
          }

          { // 협력업체정보 추가/수정
            openModal.open && openModal.modal === 'partnerAdd' &&
            <Dialog
              open={openModal}
              onClose={() => (event, reason) => {
                if(reason && reason === "backdropClick") return;
                setOpenModal({open: false, modal: openModal.modal, data: {}});
              }}
              PaperComponent={PaperComponent}
              aria-labelledby="draggable-dialog-title"
              sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "550px",  // Set your width here
                  },
                },
              }}
            >
              <section className="modal">
                <ProjectPartnerModal
                  open={openModal.open}
                  close={() => setOpenModal({ open: false, modalName: openModal.modal, data: openModal.data })}
                  data={openModal.data}
                  formik={formik}
                  list={partnerList}
                  setList={setPartnerList}
                  selected={props.selected}
                  indicator={props.indicator}
                  history={props.history}
                />
              </section>
            </Dialog>
          }

          { // 협업해외사 추가/수정
            openModal.open && openModal.modal === 'foreignAdd' &&
            <Dialog
              open={openModal}
              onClose={() => (event, reason) => {
                if(reason && reason === "backdropClick") return;
                setOpenModal({open: false, modal: openModal.modal, data: {}});
              }}
              PaperComponent={PaperComponent}
              aria-labelledby="draggable-dialog-title"
              sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "550px",  // Set your width here
                  },
                },
              }}
            >
              <section className="modal">
                <ProjectForeignModal
                  open={openModal.open}
                  close={() => setOpenModal({ open: false, modalName: openModal.modal, data: openModal.data })}
                  data={openModal.data}
                  formik={formik}
                  list={foreignList}
                  setList={setForeignList}
                  selected={props.selected}
                  indicator={props.indicator}
                  history={props.history}
                />
              </section>
            </Dialog>
          }

          { // 수상정보 추가/수정
            openModal.open && openModal.modal === 'awardAdd' &&
            <Dialog
              open={openModal}
              onClose={() => (event, reason) => {
                if(reason && reason === "backdropClick") return;
                setOpenModal({open: false, modal: openModal.modal, data: {}});
              }}
              PaperComponent={PaperComponent}
              aria-labelledby="draggable-dialog-title"
              sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "800px",  // Set your width here
                  },
                },
              }}
            >
              <section className="modal">
                <ProjectAwardModal
                  open={openModal.open}
                  close={() => setOpenModal({ open: false, modalName: openModal.modal, data: openModal.data })}
                  data={openModal.data}
                  formik={formik}
                  list={awardInfoList}
                  setList={setAwardInfoList}
                  selected={props.selected}
                  indicator={props.indicator}
                  history={props.history}
                />
              </section>
            </Dialog>
          }

          { // 다음 우편번호 찾기
            openModal.open && openModal.modal === 'daum' &&
            <Dialog
              open={openModal}
              onClose={() => (event, reason) => {
                if (reason && reason === "backdropClick") return;
                handleCloseModal();
              }}
              PaperComponent={PaperComponent}
              aria-labelledby="draggable-dialog-title"
              sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "800px",  // Set your width here
                  },
                },
              }}
            >
              <section className="modal">
                <DaumPost
                  close={handleCloseModal}
                  open={openModal.open}
                  data={openModal.data}
                  setClose={handleCloseModal}
                />
              </section>
            </Dialog>
          }

          { // 사유 입력 모달
            openModal.open && openModal.modal === 'reason' &&
            <Dialog
              open={openModal}
              onClose={() => (event, reason) => {
                if (reason && reason === "backdropClick") return;
                handleCloseModal();
              }}
              PaperComponent={PaperComponent}
              aria-labelledby="draggable-dialog-title"
              sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "500px",  // Set your width here
                  },
                },
              }}
            >
              <section className="modal">
                <ReasonModal
                  open={openModal.open}
                  close={() => setOpenModal({ open: false, modalName: openModal.modal, data: openModal.data })}
                  data={openModal.data}
                  formik={formik}
                  indicator={props.indicator}
                  history={props.history}
                />
              </section>
            </Dialog>
          }

            { // 이미지 미리 보기 모달
            openModal.open && openModal.modal === 'previewFile' &&
            <Dialog
              open={openModal}
              onClose={() => (event, reason) => {
                if (reason && reason === "backdropClick") return;
                handleCloseModal();
              }}
              PaperComponent={PaperComponent}
              aria-labelledby="draggable-dialog-title"
              sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "1000px",  // Set your width here
                  },
                },
              }}
            >
              <section className="modal">
                <ImagePreviewModal
                  open={openModal.open}
                  close={() => setOpenModal({ open: false, modalName: openModal.modal, data: openModal.data })}
                  data={openModal.data}
                  formik={formik}
                  indicator={props.indicator}
                  history={props.history}
                />
              </section>
            </Dialog>
          }

          { openModal.open && openModal.modal === 'dialog' &&
            <Modal
              open={openModal.open}
              onClose={() => setOpenModal({ open: false, modalName: openModal.modal, data: openModal.data })}
            >
              <AlertDialog
                open={openModal.open}
                close={() => setOpenModal({ open: false, modalName: openModal.modal, data: openModal.data })}
                title="프로젝트 추가정보 저장"
                color="primary"
                message={`입력하신 프로젝트 추가 정보를 저장하시겠습니까?`}
                confirm="확인"
                onClick={() => {
                  saveProject(formik.values.isConfirm);
                }}
              />
            </Modal>
          }

          { openModal.open && openModal.modal === 'scaleDel' &&
            <Modal
              open={openModal.open}
              onClose={() => setOpenModal({ open: false, modalName: openModal.modal, data: openModal.data })}
            >
              <AlertDialog
                open={openModal.open}
                close={() => setOpenModal({ open: false, modalName: openModal.modal, data: openModal.data })}
                title="프로젝트 규모 삭제"
                color="error"
                message={
                  <div>
                    <div className="red">선택하신 프로젝트 규모 정보를 삭제하시겠습니까?</div>
                    <div className="red mt10">프로젝트 규모 삭제 후 저장하기를 진행하지 않으시면 저장되지 않습니다.</div>
                  </div>
                }
                confirm="확인"
                onClick={() => {
                  scaleDel()
                }}
              />
            </Modal>
          }

          { openModal.open && openModal.modal === 'dialogDel' &&
            <Modal
              open={openModal.open}
              onClose={() => setOpenModal({ open: false, modalName: openModal.modal, data: openModal.data })}
            >
              <AlertDialog
                open={openModal.open}
                close={() => setOpenModal({ open: false, modalName: openModal.modal, data: openModal.data })}
                title="프로젝트 참여업체 삭제"
                color="error"
                message={
                  <div>
                    <div className="red">선택하신 프로젝트 참여업체 정보를 삭제하시겠습니까?</div>
                    <div className="red mt10">참여업체 삭제 후 저장하기를 진행하지 않으시면 저장되지 않습니다.</div>
                  </div>
                }
                confirm="확인"
                onClick={() => {
                  ownershipDel()
                }}
              />
            </Modal>
          }

          { openModal.open && openModal.modal === 'partnerDel' &&
            <Modal
              open={openModal.open}
              onClose={() => setOpenModal({ open: false, modalName: openModal.modal, data: openModal.data })}
            >
              <AlertDialog
                open={openModal.open}
                close={() => setOpenModal({ open: false, modalName: openModal.modal, data: openModal.data })}
                title="프로젝트 협력업체 삭제"
                color="error"
                message={
                  <div>
                    <div className="red">선택하신 프로젝트 협력업체 정보를 삭제하시겠습니까?</div>
                    <div className="red mt10">협력업체 삭제 후 저장하기를 진행하지 않으시면 저장되지 않습니다.</div>
                  </div>
                }
                confirm="확인"
                onClick={() => {
                  partnerDel()
                }}
              />
            </Modal>
          }

          { openModal.open && openModal.modal === 'foreignDel' &&
            <Modal
              open={openModal.open}
              onClose={() => setOpenModal({ open: false, modalName: openModal.modal, data: openModal.data })}
            >
              <AlertDialog
                open={openModal.open}
                close={() => setOpenModal({ open: false, modalName: openModal.modal, data: openModal.data })}
                title="프로젝트 협업해외사 삭제"
                color="error"
                message={
                  <div>
                    <div className="red">선택하신 프로젝트 협업해외사 정보를 삭제하시겠습니까?</div>
                    <div className="red mt10">협업해외사 삭제 후 저장하기를 진행하지 않으시면 저장되지 않습니다.</div>
                  </div>
                }
                confirm="확인"
                onClick={() => {
                  foreignDel()
                }}
              />
            </Modal>
          }

          { openModal.open && openModal.modal === 'awardDel' &&
            <Modal
              open={openModal.open}
              onClose={() => setOpenModal({ open: false, modalName: openModal.modal, data: openModal.data })}
            >
              <AlertDialog
                open={openModal.open}
                close={() => setOpenModal({ open: false, modalName: openModal.modal, data: openModal.data })}
                title="프로젝트 수상정보 삭제"
                color="error"
                message={
                  <div>
                    <div className="red">선택하신 프로젝트 수상 정보를 삭제하시겠습니까?</div>
                    <div className="red mt10">수상정보 삭제 후 저장하기를 진행하지 않으시면 저장되지 않습니다.</div>
                  </div>
                }
                confirm="확인"
                onClick={() => {
                  awardDel();
                }}
              />
            </Modal>
          }

          { openModal.open && openModal.modal === 'dialogComplete' &&
            <Modal
              open={openModal.open}
              onClose={() => setOpenModal({ open: false, modalName: openModal.modal, data: openModal.data })}
            >
              <AlertDialog
                open={openModal.open}
                close={() => setOpenModal({ open: false, modalName: openModal.modal, data: openModal.data })}
                title="프로젝트 등록완료"
                color="primary"
                message={`해당 프로젝트를 등록완료 처리 하시겠습니까?`}
                confirm="확인"
                onClick={() => {
                  saveProject(1);
                }}
              />
            </Modal>
          }

        </section>
      </form>
    </section>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default TabProjectInfo;