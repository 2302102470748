/*****************************************************************************************
 * 설명 : 페이지 서브 레이아웃 (사용자)
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import React from 'react';

import UserHeader from "pages/layout/userHeader";
import Footer from "pages/layout/footer";

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const UserLayout = (props) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <section className="layout-wrapper">
      <UserHeader></UserHeader>
      <section className="user-content">
        {props.children}
        <Footer></Footer>
      </section>
    </section>
  )
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default UserLayout;