/*
import userImage01 from 'assets/images/sample/user_image01.png';
import userImage02 from 'assets/images/sample/user_image02.png';
import userImage03 from 'assets/images/sample/user_image03.png';
import userImage04 from 'assets/images/sample/user_image04.png';

/**
 * * 설명 : 업체 정보 리스트 가져오기
 * TODO : 로그인 정보 API 수정 필요
*/
export function loginApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("post", '/api/v1/login', params).then( response => {
    if( func ) func(response);

    /*
      memNo: 1,
      userName: '관리자',
      positionName: '시스템',
      picture: userImage03,
      isAdmin: 1
    */

  }).catch(error => {
    console.error(error);
  })
}

/**
 * * 페이지 변경 시 로그인 정보 가져오기
 * @param {*} getApi
 * @param {*} func
 * @param {*} errorFunc
 * @returns
 */
export function authenticateApi(getApi, func, errorFunc = null) {
  if( ! getApi ) return;

  getApi("get", '/api/v1/authenticate').then( response => {
    if( func ) func(response);

    /*
      memNo: 1,
      userName: '관리자',
      positionName: '시스템',
      picture: userImage03,
      isAdmin: 1
    */

  }).catch(error => {
    // 에러 발생 시 로그인 페이지로 이동
    if( errorFunc ) errorFunc(error);
    console.error(error);
  })
}

/**
 * * 조직도 가져오기
 * @param {*} getApi
 * @param {*} func
 * @param {*} params
 * @returns
 */
export function getDepartmentListApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("get", '/api/v1/employee/department', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  })
}

/**
 * * 선택된 조직도의 사용자 가져오기
 * @param {*} getApi
 * @param {*} params
 * @param {*} func
 * @returns
 */
export function getEmployeeListApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("get", '/api/v1/employee', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  })
}

/**
 * 사용자 아이디잠김해제 처리
 * @param {*} getApi
 * @param {*} params
 * @param {*} func
 * @returns
 */
export function getEmployeeUnlockApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("post", '/api/v1/employee/unlock', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  })
}

/**
 * * 직위 리스트 가져오기
 * @param {*} getApi
 * @param {*} func
 * @returns
 */
export function getPositionListApi(getApi, func) {
  if( ! getApi ) return;

  getApi("get", '/api/v1/employee/position').then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  })
}

/**
 * * 사용자 추가/수정
 * @param {*} getApi
 * @param {*} params
 * @param {*} func
 * @returns
 */
export function setUserAddApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("post", '/api/v1/employee/add', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  })
}


/**
 * * 사용자 삭제
 * @param {*} getApi
 * @param {*} params
 * @param {*} func
 * @returns
 */
export function delEmployeeApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("post", '/api/v1/employee/del', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  })
}

