/*****************************************************************************************
 * 설명 : 공통 컴포넌트
 * 작성자 : 송영석
 * 작성일 : 2021-04-22
*****************************************************************************************/
import React, {useState} from 'react';
import { useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import Tooltip from '@mui/material/Tooltip';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import styled from 'styled-components';

import {PAGEROW} from 'config/config';

import iconExcel from 'assets/images/icon-excel.png'

/***************************************************************************************
 * 설명 : 오른쪽 TABLE TH 여백
***************************************************************************************/
export const Thr = styled.th`
  padding-left: 10px;
`;

/***************************************************************************************
 * 설명 : 왼쪽 TABLE TD 여백
***************************************************************************************/
export const Tdl = styled.td`
  padding-right: 10px;
`;

/*****************************************************************************************
 * 설명 : 랜덤 해시키 가져오기
 * 입력값 : length = 사이즈
 * 리턴값 : 해시값
*****************************************************************************************/
export const makeid = (length) => {
  var result           = [];
  var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  var charactersLength = characters.length;
  for ( var i = 0; i < length; i++ ) {
    result.push(characters.charAt(Math.floor(Math.random() * charactersLength)));
 }

 return result.join('');
}

/*****************************************************************************************
 * 설명 : 탭 패널 컴포넌트 - 사용하지 말것 !
 *       호출되면 여러번 재실행되면서 안좋음
 * 입력값 : children
 *          value =
 *          index =
 *          classes
*****************************************************************************************/
export const CustomTabPanel = (props) => {
  const { children, value, index, classes, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${props.name + index}`}
      aria-labelledby={`simple-tab-${props.name + index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

/*****************************************************************************************
 * 설명 : 탭 컴포넌트
 * 입력값 : variant: 'fullWidth',
            className: 'tabs',
            change: tab index change event handler,
            tabList: [
              { title: 'IPV4', children: IPv4Tab },
              { title: 'IPV6', children: IPv6Tab }
            ]
*****************************************************************************************/
export const CustomTab = (props) => {
  const [tabInfo] = useState(props.tabInfo);

  const tabChange = (event, index) => {
    props.setTabIndex(index);

    if( tabInfo.change ) {
      tabInfo.change(event, index);
    }
  };

  // 10자리 해시 생성
  let id = makeid(10);
  let className = (tabInfo.className === undefined) ? "" : tabInfo.className;

  return (
    <section style={{...props.sessionStyle}}>
      <Tabs
        value={props.tabIndex}
        onChange={(event, index) => tabChange(event, index)}
        variant={tabInfo.variant}
        className={className}
      >
        {
          tabInfo.tabList.map( (list, index) =>
            <Tab
              key={id + index}
              label={list.title}
              className={list.className + " tab"}
            />
          )
        }
      </Tabs>

      <section className="tabpanel" style={{...props.parentStyle}}>
      {
        tabInfo.tabList.map( (list, index) =>
          <div
            role="tablist"
            hidden={props.tabIndex !== index}
            id={`simple-tabpanel-${id + index}`}
            aria-labelledby={`simple-tab-${id + index}`}
            key={index}
            className="tabs"
            style={{...props.parentStyle}}
          >
            {props.tabIndex === index && (
              list.children
            )}
            {/* CustomTabPanel로 호출할 경우  여러번 호출 되는 오류가 있음
            <CustomTabPanel key={id + index} name={id} value={tabIndex} index={index} className="tabpanel">
              {list.children}
            </CustomTabPanel>
            */}
          </div>
        )
      }
      </section>
    </section>
  );
}

/*****************************************************************************************
 * 설명 : 현재 페이지 카테고리 패스 HTML 표시
 * 입력값 : headerRightBtn = 오른쪽 영역에 표시할 Html
*****************************************************************************************/
export const PathHtml = (props) => {
  const HeaderRightBtn = props.headerRightBtn;
  const location = useLocation();
  var parentName = '';
  var pathName = '';

  // path로 메뉴명 찾아오기
  for(let menus of props.menuList ) {
    for( let menu of menus.items ) {
      for( let submenu of menu.items ) {
        if( submenu?.url === location.pathname ) {
          parentName = menu?.name;
          pathName = submenu?.name;
        }
      }
    }
  }

  return (
    <section className="page-header">
      <div className="path">
        <div className="wrap">
          <div className="favorite-icon cp"><span className="myicons-star"></span></div>
          <div className="depth1">{parentName}</div>
          <div className="path-arrow"><span className="myicons-path"></span></div>
          <div className="depth2">{pathName}</div>
        </div>
      </div>
      {HeaderRightBtn &&
        <HeaderRightBtn></HeaderRightBtn>
      }
    </section>
  )
}

/*****************************************************************************************
 * 설명 : 페이지 네이션 표시
 * 입력값 :
 *        아래값 사용안함
 *        currPage = 현재 페이지번호,
 *        maxPage = 전체 페이지번호,
 *        pageCount = 페이징 표시 갯수 10 : 1 ~ 10
 *        사용안함
 *  *
 *
 *        pageNo = 현재 페이지번호
 *        pageRow = 페이지당 줄수
 *        totalCount = 전체 검색된 갯수
 *        size = large, medium, small
*****************************************************************************************/
export function CustomPagenation(props) {
  let disabled = (props.disabled) ? props.disabled : false;

  let pageNo = ( props.pageNo === undefined ) ? 1 : props.pageNo;
  let pageRow = ( props.pageRow === undefined ) ? PAGEROW : props.pageRow;

  let totalPage = 1;
  if( props.totalPage === undefined ) {
    let totalCount = (props.totalCount === undefined ) ? 1 : props.totalCount;
    totalPage = Math.round(totalCount / pageRow);
    if( totalCount % pageRow > 0 ) totalPage++;

  } else {
    totalPage = props.totalPage;
  }

  return (
    <div className="pagination">
      <Pagination
        count={totalPage}
        defaultPage={pageNo}
        siblingCount={0}
        boundaryCount={2}
        size={!props.size ? 'small' : props.size}
        shape="rounded"
        color="primary"
        showFirstButton
        showLastButton
        onChange={props.change}
        disabled={disabled}
      />
    </div>
  )
}

/*****************************************************************************************
 * 설명 : 페이지 네이션 표시
 * 입력값 : currPage = 현재 페이지번호,
 *        maxPage = 전체 페이지번호,
 *        pageCount = 페이징 표시 갯수 10 : 1 ~ 10
 *        size = large, medium, small
*****************************************************************************************/
export function PageRow( props ) {
  var pageRowArr = [];

  if( props.pageRowArr === undefined ) {
    pageRowArr = [
      {title: '100줄씩', value: '100'},
      {title: '200줄씩', value: '200'},
      {title: '500줄씩', value: '500'},
      {title: '1000줄씩', value: '1000'}
    ]
  } else {
    pageRowArr = props.pageRowArr;
  }

  let disabled = (props.disabled) ? props.disabled : false;

  let className = 'mr5';
  if( props.className !== undefined ) {
    className += ' ' + props.className;
  }

  return (
    <FormControl className="selectBox page-rowcnt">
      <NativeSelect
        value={props.pageInfo?.pageRow}
        className={className}
        disabled={disabled}
        onChange={(e) => {
          props.setPageInfo(props.pageInfo.pageNo, e.target.value);
        }}
      >
        {pageRowArr.map((item, index) => {
          return <option key={index} value={item.value}>{item.title}</option>
        })}
      </NativeSelect>
    </FormControl>
  );
}

/*****************************************************************************************
 * 설명 : 리프레시 버튼
 * 입력값 : click = 클릭 이벤트
*****************************************************************************************/
export const BtnRefresh = (props) => {
  const clickHandler = (event) => {
    if( props.click ) {
      props.click(event);
    }
  }

  let disabled = (props.disabled) ? props.disabled : false;

  return (
    <Tooltip title="검색 초기화" placement="top" arrow>
      <Button
        type="button"
        color="inherit"
        variant="outlined"
        className="mr5 btn-refresh"
        disabled={disabled}
        onClick={(e) => clickHandler(e)}
      >
        <span className="material-icons md-24">refresh</span>
      </Button>
    </Tooltip>
  );
}

/*****************************************************************************************
 * 설명 : 검색 버튼
 * 입력값 : click = 클릭 이벤트
*****************************************************************************************/
export const BtnSearch = (props) => {
  const clickHandler = (event) => {
    if( props.click ) {
      props.click();
    }
  }

  let disabled = (props.disabled) ? props.disabled : false;

  return (
    <Tooltip title="다시 검색" placement="top" arrow>
      <Button
        type="submit"
        color="inherit"
        variant="outlined"
        className="mr5 btn-search"
        disabled={disabled}
        onClick={() => clickHandler()}
      >
        <span className="material-icons md-24">search</span>
      </Button>
    </Tooltip>
  );
}

/*****************************************************************************************
 * 설명 : 엑셀 다운로드 - 미선택 후 다운로드 시 전체 데이터 다운처리
 * 입력값 : AgGridApi
*****************************************************************************************/
export function BtnExcelDown(props) {
  const clickHandler = (event) => {
    if( props.click ) {
      props.click();
    }
  };

  let disabled = (props.disabled) ? props.disabled : false;

  return (
    <Tooltip title="엑셀다운로드" placement="top" arrow>
      <span>
        <Button
          type="button"
          color="inherit"
          variant="outlined"
          className="mr5 btn-excel"
          disabled={disabled}
          style={{width: '28px', minHeight: '28px', background: '#fff'}}
          onClick={() => clickHandler()}
        >
          <img src={iconExcel} alt="엑셀 다운로드" />
        </Button>
      </span>
    </Tooltip>
  );
}

/*****************************************************************************************
 * 설명 : 추가 버튼
 * 입력값 : click = 클릭 이벤트
*****************************************************************************************/
export const BtnPlus = (props) => {
  const clickHandler = (event) => {
    if( props.click ) {
      props.click();
    }
  }

  return (
    <Tooltip title="추가" placement="top" arrow>
      <span>
        <Button className="Btn sm" onClick={() => clickHandler()}>
          <AddIcon />
          {/* <span className="material-icons md-24">search</span> */}
        </Button>
      </span>
    </Tooltip>
  );
}

/*****************************************************************************************
 * 설명 : 삭제 버튼
 * 입력값 : click = 클릭 이벤트
*****************************************************************************************/
export const BtnDel = (props) => {
  const clickHandler = (event) => {
    if( props.click ) {
      props.click();
    }
  }

  return (
    <Tooltip title="삭제" placement="top" arrow>
      <span>
        <Button className="Btn sm mr5" onClick={() => clickHandler()}>
          <RemoveIcon />
          {/* <span className="material-icons md-24">search</span> */}
        </Button>
      </span>
    </Tooltip>
  );
}

/*****************************************************************************************
 * 설명 : Ag Grid Page Info
 * 입력값 : click = 클릭 이벤트
*****************************************************************************************/
export const PageInfo = {
  pageRow: 100,
  pageNo: 1
}

export const DefaultColDef = {
  sortable: true,
  resizable: true,
  filter: false,
}

export const FirstColumn = {
  headerName: '', field: '', width: 50, cellClass: 'center', flex: 0,
  headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true
}

/*****************************************************************************************
 * 설명 : Ag Grid Currency Format
*****************************************************************************************/
export const CurrencyFormat = (params) => {
  if( params.value === '' ) return '';

  else return parseInt(String(params.value || 0)).toLocaleString()
}