/*****************************************************************************************
 * 설명 : 왼쪽 메뉴
 * URI :
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';

import Restful from 'service/restful';
import { getLogicalDriveApi } from 'service/system';
import { formatBytesNum } from 'service/utils';

import logo from 'assets/images/logo.png';
import logo_reduce from 'assets/images/logo_reduce.png';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const Snb = (props) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [menuIndex, setMenuIndex] = useState(-1);
  const history = useNavigate();
  const [getApi] = Restful();

  const [logical, setLogical] = useState({
    totalSize: 0,
    totalUseSize: 0,
    totalRemainderSize: 0,
    totalFileCount: 0
  });

  const menuList = useSelector(state => state?.menuList);
  const currentMenu = useSelector(state => state?.menuList?.current);

  /***************************************************************************************
   * 설명 : 서브메뉴 HTML
  ***************************************************************************************/
  function SubMenu(props) {
    return (
      <li key={props.id}>
        <NavLink exact="true" to={props.url} className="subtitle">
          <div className="icon"></div>{props.title}
        </NavLink>
      </li>
    );
  }

  /***************************************************************************************
   * 설명 : 논리 드라이브 정보 가져오기
  ***************************************************************************************/
  const getLogicalDrive = () => {
    getLogicalDriveApi(getApi, {}, (response) => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        setLogical(response.data.data1);

      } else {
        setLogical({
          totalSize: 0,
          totalUseSize: 0,
          totalRemainderSize: 0,
          totalFileCount: 0
        });
      }
    });
  }

  /***************************************************************************************
   * 설명 : 데이터 로딩 처리
  ***************************************************************************************/
  const getRate = useCallback(() => {
    return parseFloat( (logical?.totalRemainderSize || 100) / (logical?.totalSize || 0)).toFixed(2) + '%';
  }, [logical]);

  /***************************************************************************************
   * 설명 : 데이터 로딩 처리
  ***************************************************************************************/
  useEffect(() => {
    getLogicalDrive();

    console.log(menuList);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <section className="snb">

      <NavLink to="/admin/dashboard" className="logo">
        <img src={logo} alt="DA그룹" />
      </NavLink>

      <NavLink to="/admin/dashboard" className="logo_reduce">
        <img src={logo_reduce} alt="DA그룹" />
      </NavLink>

      <div className="snblist">
        <section className="snbmenu">
          <div className="menu-title">MENU</div>
          <ul>
            {menuList?.menu?.length > 0 && menuList?.menu?.map !== undefined &&
              menuList?.menu?.map((menu, index) => {
                if( parseInt(menu.siteSeq) === 2 ) {
                  return <React.Fragment key={index}></React.Fragment>;

                } else {
                  // 서브 메뉴 포함 여부 확인
                  let tmp = menu?.children?.filter((item) => item?.seq === currentMenu?.seq);
                  let menuOpen = false;
                  if( tmp.length > 0 ) menuOpen = true;

                  return (
                    <li key={index} className={ menu.seq === menuIndex || menuOpen ? 'menu open' : 'menu'} onClick={() => {
                      if( menu.linkUrl !== null && menu.linkUrl !== '')
                        history(menu.linkUrl);
                      setMenuIndex(menu.seq)
                    }}>
                      <div className="depth1">
                        <i className={'icon1 ' + menu.icon} onClick={() => props.toggleMenuReduce()}></i>
                        <div className="title">{menu.menuName}</div>
                      </div>
                      <ul className="submenu">
                        {menu?.children?.map((submenu, index) => (
                          <SubMenu
                            key={index}
                            title={submenu.menuName}
                            url={submenu.linkUrl}
                            id={submenu.seq}
                          />
                        ))}
                      </ul>
                    </li>
                  )
                }
              })
            }
          </ul>
        </section>

        <section className="drive-list">
          <div className="info-title">전체 논리 드라이브 용량</div>
          <div className="drive-info">
            <div className="progress-wrap clearfix">
              <div className="progress dark">
                <div className="progress-bar bg-blue" style={{ width: getRate() }}></div>
              </div>
              <div className="percent">
                {getRate()}
              </div>
            </div>
            <div className="info-txt">
              {formatBytesNum((logical?.totalSize * (1024*1024)) || 0)} 중
              {formatBytesNum((logical?.totalRemainderSize * (1024*1024)) || 0)} 사용 가능
            </div>
          </div>
        </section>
      </div>

    </section>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default Snb;