/*****************************************************************************************
 * 설명 : 관리자 > 시스템관리 > 사용자 관리
*****************************************************************************************/
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Modal from '@mui/material/Modal';
import { AgGridReact } from 'ag-grid-react';
import { useFormik } from 'formik';
import $ from 'jquery';
import { useEffect, useState } from 'react';
import SortableTree, { toggleExpandedForAll } from 'react-sortable-tree';
import FileExplorerTheme from 'react-sortable-tree-theme-file-explorer';
import SplitPane from 'react-split-pane';
import * as Yup from "yup";

import AlertDialog from 'components/alertDiolog';
import BtnAgGridSave from 'components/btnAgGridSave';
import { BtnRefresh, BtnSearch } from 'components/common';
import { ButtonEx, InputEx } from 'components/inputEx';
import alertMsg from 'components/message';
import customTooltipAgGrid from 'components/tooltipAgGrid';

import { delEmployeeApi, getDepartmentListApi, getEmployeeListApi, getEmployeeUnlockApi } from 'service/member';
import Restful from "service/restful";
import { getGroupwareSyncApi } from 'service/sync';
import { PaperComponent, comma } from 'service/utils';

import { MESSAGE_DELAY } from 'config/config';
import useGridConfig from 'hooks/useGridConfig';

import Layout from 'pages/layout/layout';
//import AuthSetting from 'pages/admin/system/userManager/authSetting';
import ChipEx from 'components/chipEx';

import AuthModal from './authModal';
import UserAddModal from './userAddModal';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const UserManager = ( props ) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [getApi] = Restful();

  const [gridApi, setGridApi] = useState({});

  // const [auth, setAuth] = useState('1');

  const [departmentList, setDepartmentList] = useState([]);
  const [list, setList] = useState([]);
  const [selectedNode, setSelectedNode] = useState({});

  const [openModal, setOpenModal] = useState({open: false, modal: 'sync', data: []});

  const formik = useFormik({
    initialValues: {
      startDate: '',
      endDate: '',
      searchText: '',
    },
    validationSchema: Yup.object().shape({
      searchText: Yup.string().max(30, "30자리"),
    }),
    onSubmit: (values) => {
      getEmployeeList();
    }
  });

  // table column
  const [columnDefs, setColumnDefs] = useState([
    { headerName: '', field: 'seq', width: 40, cellClass: 'center', flex: 0,
      headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
    { headerName: '번호', field: 'rowIndex', width: 55, cellClass: 'cp text-right',
      valueGetter: (params) => {
        return comma(params.node.rowIndex + 1);
      }
    },
    { headerName: '수동여부', field: 'isAuto', width: 100, cellClass: 'cp center',
      cellRendererFramework: function(params) {
        if( parseInt(params.data.isAuto) === 1 )
          return <ChipEx color="success" variant="outlined" size="small" label="수동" />;
        else
          return <ChipEx color="primary" variant="outlined" size="small" label="자동" />;
      }
    },
    { headerName:'직위', field: 'dutyName', width: 150, cellClass: 'cp center' },
    { headerName:'사용자명', field: 'userName', width: 150, cellClass: 'cp center' },
    { headerName:'아이디', field: 'userId', width: 150, cellClass: 'cp center' },
    { headerName:'권한그룹', field: 'authGroupName', width: 150, cellClass: 'cp center' },
    /*
    { headerName:'연락처', field: 'companyPhone', width: 150, cellClass: 'cp' },
    { headerName:'이메일', field: 'companyEmail', width: 200, cellClass: 'cp' },
    { headerName:'입사일', field: 'joinDate', width: 100, cellClass: 'cp' },
    { headerName:'퇴사일', field: 'retireOutDate', width: 100, cellClass: 'cp' },
    */
    { headerName:'로그인실패횟수', field: 'loginFailCount', width: 150, cellClass: 'cp center' },
    { headerName: '잠김여부', field: 'isLoginDeny', width: 100, cellClass: 'cp center',
      cellRendererFramework: function(params) {
        if( parseInt(params.data.isLoginDeny) !== 0 )
          return <ChipEx color="warning" variant="outlined" size="small" label="잠김" />;
        else
          return '';
      }
    }
  ]);

  const [gridConfig, setGridUpdate] = useGridConfig(2, setColumnDefs);

  /***************************************************************************************
   * 설명 : 조직도 리스트 데이터 가져오기
  ***************************************************************************************/
  const getDepartmentList = () => {
    let params = formik.values;

    const findNode = (data, id) => {
      // root
      if(id.length === 3) return data;

      for(let i = 0; i < data.length; i++ ) {
        if( data[i]?.sortOrder === id.substr(0, id.length - 3) )
          return data[i];

        if( data[i].children !== undefined ) {
          let tmp = findNode(data[i].children, id);
          if( tmp !== undefined ) return tmp;
        }
      }
    }

    getDepartmentListApi(getApi, params, (response) => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        setDepartmentList(response.data.data);

        let tmp = [];
        let data = response.data.data;
        data.forEach((item, index) => {
          let parentNode = findNode(tmp, item.sortOrder, 'sortOrder');

          if( parentNode === undefined ) {
            tmp.push({
              title: item.departmentName,
              expanded: true,
              ...item
            })

          } else if( item.sortOrder.length === 3 ) {
            parentNode.push({
              title: item.departmentName,
              expanded: true,
              ...item
            });

          } else {
            if( parentNode.children === undefined ) parentNode.children = [];
              parentNode.children.push({
                title: item.departmentName,
                expanded: true,
                ...item
              });
          }
        });

        setDepartmentList(tmp);

      } else {
        setDepartmentList([]);
      }
    });
  }

  /***************************************************************************************
   * 설명 : 선택된 조직도의 사용자 가져오기
  ***************************************************************************************/
  const getEmployeeList = () => {
    // if( selectedNode === undefined || selectedNode.seq === undefined ) return;

    let params = {
      ...formik.values,
      departmentCode: selectedNode.departmentCode || '',
      sortOrder: selectedNode.sortOrder || ''
    };

    getEmployeeListApi(getApi, params, (response) => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        setList(response.data.data);
      } else {
        setList([]);
      }
    });
  }

  /***************************************************************************************
   * 설명 : 아이디 잠김 해제
  ***************************************************************************************/
  const getEmployeeUnlockProcess = () => {
    let nodes = gridApi.getSelectedRows();

    let params = {
      data: nodes
    };

    getEmployeeUnlockApi(getApi, params, (response) => {
      if( response !== undefined && response.data.result) {
        alertMsg(response.data.message, "S", MESSAGE_DELAY);

        getEmployeeList();

      } else {
        alertMsg(response.data.message || '서버와의 통신에 실패하였습니다.', "E", MESSAGE_DELAY);
      }
    });
  }

  /***************************************************************************************
   * 설명 : 선택 사용자 삭제
  ***************************************************************************************/
  const getEmployeeDel = () => {
    let nodes = gridApi.getSelectedRows();

    let params = {
      data: nodes
    }

    delEmployeeApi(getApi, params, (response) => {
      if( response !== undefined && response.data.result) {
        alertMsg(response.data.message, "S", MESSAGE_DELAY);

        getEmployeeList();

      } else {
        alertMsg(response.data.message || '서버와의 통신에 실패하였습니다.', "E", MESSAGE_DELAY);
      }
    });
  }

  /***************************************************************************************
   * 설명 : PMS 수동 동기화
  ***************************************************************************************/
  const getPMSSyncProcess = () => {
    if( ! window.confirm('조직도 및 사용자를 수동으로 동기화 하시겠습니까?') ) return;

    getGroupwareSyncApi(getApi, (response) => {
      if( response !== undefined && response.data.result ) {
        alertMsg(response.data.message, "S", MESSAGE_DELAY);
      } else {
        alertMsg(response.data.message || '서버와의 통신에 실패하였습니다.', "E", MESSAGE_DELAY);
      }
    })
  }

  /***************************************************************************************
   * 설명 : 그룹코드에 포함된 공통코드 검색 초기화
  ***************************************************************************************/
  const initSearch = () => {
    formik.setValues({
      startDate: '',
      endDate: '',
      searchText: ''
    });

    formik.handleSubmit();
  }

  /***************************************************************************************
   * 설명 : 트리 노드 클릭 시 한줄 선택 처리
  ***************************************************************************************/
  const treeRowClick = () => {
    $(".rstcustom__node").each((index, item) => {
      $(item).on('click', (event) => {

        // 기존 선택된 노드 삭제
        $(".admin-cms-contents").find('.tree-selected-parent').each((index, subItem) => {
          $(subItem).removeClass('tree-selected-parent');
        });

        $(event.target).closest('.rstcustom__rowWrapper').addClass('tree-selected-parent');
      });
    })
  }

  /***************************************************************************************
   * 설명 : 트리 노드 전체 열기
  ***************************************************************************************/
  const treeExpanded = (expanded) => {
    setDepartmentList(toggleExpandedForAll({
      treeData: departmentList,
      expanded,
    }), );
  }

  /***************************************************************************************
   * 설명 : 트리 노드 클릭 시 처리
  ***************************************************************************************/
  useEffect(() => {
    if( departmentList.length > 0 )
      treeRowClick();

    // eslint-disable-next-line
  }, [departmentList]);

  /***************************************************************************************
   * 설명 : 그룹코드 선택 시 데이터 불러오기
  ***************************************************************************************/
  useEffect(() => {
    getEmployeeList();

    // eslint-disable-next-line
  }, [selectedNode])

  /***************************************************************************************
   * 설명 : 데이터 처리
  ***************************************************************************************/
  useEffect(() => {
    getDepartmentList();

    getEmployeeList();

    // eslint-disable-next-line
  }, []);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <Layout>
      <section className="admin-cms-contents system-page">
        <SplitPane split="vertical" minSize={300} maxSize={370} defaultSize={320}>
          <div className="mr10">
            <header className="admin-cms-search-header">
              <div className="left">
                <div className='f15 bold lh39px'>조직도</div>
              </div>
              <div className="text-right">
                <Button
                  color="primary"
                  variant='outlined'
                  onClick={() => treeExpanded(true)}
                  className="mr5 btn-refresh"
                >
                  <span className="material-icons-outlined f18">folder_open</span>
                </Button>

                <Button
                  color="primary"
                  variant='outlined'
                  onClick={() => treeExpanded(false)}
                  className="mr5 btn-refresh"
                >
                  <span className="material-icons f18">folder</span>
                </Button>
              </div>
            </header>
            <section className='organization p10' style={{border: 'solid 1px #ccc', overflow:'auto'}}>
              <div className="pad-height200" style={{height:'calc(100vh - 160px)'}}>
                <SortableTree
                  treeData={departmentList}
                  onChange={(rowInfo) => {
                    setDepartmentList(rowInfo);
                  }}
                  isVirtualized={false}
                  theme={FileExplorerTheme}
                  style={{fontSize: '13px'}}
                  canDrag={false}
                  scaffoldBlockPxWidth={22}
                  generateNodeProps={(rowInfo) => {
                    return ({
                      onClick: (event) => {
                        setSelectedNode(rowInfo.node);
                      },
                      icons: rowInfo.node.children !== undefined && rowInfo.node.children.length > 0
                        ? [
                            <div
                              style={{
                                borderLeft: 'solid 8px gray',
                                borderBottom: 'solid 10px gray',
                                marginRight: 10,
                                boxSizing: 'border-box',
                                width: 16,
                                height: 12,
                                filter: rowInfo.node.expanded
                                  ? 'drop-shadow(1px 0 0 gray) drop-shadow(0 1px 0 gray) drop-shadow(0 -1px 0 gray) drop-shadow(-1px 0 0 gray)'
                                  : 'none',
                                borderColor: rowInfo.node.expanded ? 'white' : 'gray',
                              }}
                            />,
                          ]
                        : [
                            <div
                              style={{
                                borderLeft: 'solid 8px gray',
                                borderBottom: 'solid 10px gray',
                                marginRight: 10,
                                boxSizing: 'border-box',
                                width: 16,
                                height: 12,
                                filter: 'drop-shadow(1px 0 0 gray) drop-shadow(0 1px 0 gray) drop-shadow(0 -1px 0 gray) drop-shadow(-1px 0 0 gray)',
                                borderColor: 'white',
                              }}
                            />,
                          ],
                      className: selectedNode?.seq === (rowInfo.node?.seq || '') ? 'tree-selected' : '',
                    })
                  }}
                />
              </div>
            </section>
          </div>

          <div className="ml10">
            <header className="admin-cms-search-header pad-header">
              <div className="left">
                <form onSubmit={formik.handleSubmit}>
                  <BtnRefresh click={() => initSearch()}></BtnRefresh>
                  <BtnSearch></BtnSearch>
                  <BtnAgGridSave click={setGridUpdate}></BtnAgGridSave>

                  <InputEx
                    type="date"
                    name="startDate"
                    formik={formik}
                    fullWidth={false}
                    style={{width: '130px'}}
                    className="search-input"
                  />
                  <span className="ml5 mr5 lh28px">~</span>
                  <InputEx
                    type="date"
                    name="endDate"
                    formik={formik}
                    fullWidth={false}
                    className="ml5 search-input"
                    style={{width: '130px'}}
                  />
                  <InputEx
                    name="searchText"
                    formik={formik}
                    label="사용자명, 아이디 조직"
                    className="ml5"
                  />
                </form>
              </div>

              <div className="text-right">
                <ButtonEx
                  title="아이디잠김 해제"
                  auth="isWrite"
                  color="error"
                  variant="outlined"
                  className="ml5"
                  onClick={() => {
                    let nodes = gridApi.getSelectedRows();

                    if( nodes.length < 1 ) {
                      alertMsg("아이디 잠김해제할 사용자를 선택하시기 바랍니다.", "W", MESSAGE_DELAY);
                      return;
                    }

                    setOpenModal({open: true, modal: 'dialog', data: {}});
                  }}
                />

                <ButtonEx
                  title="권한그룹 설정"
                  auth="isWrite"
                  color="primary"
                  variant="outlined"
                  className="ml5"
                  onClick={() => {
                    let nodes = gridApi.getSelectedRows();

                    if( nodes.length < 1 ) {
                      alertMsg("권한그룹을 설정할 사용자를 선택하시기 바랍니다.", "W", MESSAGE_DELAY);
                      return;
                    }

                    setOpenModal({open: true, modal: 'authgroup', data: {}});
                  }}
                />

                <ButtonEx
                  title="사용자삭제"
                  auth="isDelete"
                  color="error"
                  className="ml5"
                  variant="outlined"
                  onClick={() => {
                    let nodes = gridApi.getSelectedRows();

                    if( nodes.length < 1 ) {
                      alertMsg("삭제할 사용자를 선택하시기 바랍니다.", "W", MESSAGE_DELAY);
                      return;
                    }

                    let tmp = nodes.filter((item) => parseInt(item.isAuto) === 0);
                    if( tmp.length > 0 ) {
                      alertMsg("수동으로 추가한 사용자만 삭제하실 수 있습니다.", "W", MESSAGE_DELAY);
                      return;
                    }

                    setOpenModal({open: true, modal: 'del', data: nodes});
                  }}
                />

                <ButtonEx
                  title="사용자추가"
                  auth="isWrite"
                  color="primary"
                  className="ml5"
                  variant="outlined"
                  onClick={() => {
                    setOpenModal({open: true, modal: 'add', data: {}})
                  }}
                />

                <ButtonEx
                  title="수동동기화"
                  auth="isWrite"
                  color="secondary"
                  className="ml5"
                  variant="outlined"
                  // disabled={selectedGroup.seq === undefined}
                  onClick={() => {
                    getPMSSyncProcess();
                  }}
                />
              </div>
            </header>

            <section className="admin-cms-body">
              <section className="ag-theme-balham grid pad-height210" style={{height:'calc(100vh - 139px)'}}>
                <div style={{ height: '100%' }}>
                  <AgGridReact
                    defaultColDef={{
                      sortable: true,
                      resizable: true,
                      filter: false,
                      lockVisible: true,
                      tooltipComponent: customTooltipAgGrid,
                    }}
                    tooltipShowDelay={0}
                    tooltipHideDelay={2000}
                    rowSelection = {'multiple'}
                    columnDefs={columnDefs}
                    rowData={list}
                    onGridReady={(event) => {
                      gridConfig(event.columnApi);
                      setGridApi(event.api);
                    }}
                    onRowDoubleClicked={(event) => {
                      // 수동으로 추가한 사용자 정보만 수정 가능
                      if( event.data.isAuto === 1 )
                        setOpenModal({open: true, modal: 'add', data: event.data})
                    }}
                    rowDragManaged={true}
                    rowDragMultiRow={true}
                    animateRows={true}
                    overlayNoRowsTemplate = "검색된 내용이 없습니다."
                  />
                </div>
              </section>
            </section>
          </div>
          {/*
            <div className="ml10">
              <AuthSetting />
            </div>
            */
          }


        </SplitPane>
      </section>

      { openModal.open && openModal.modal === 'dialog' &&
        <Modal
          open={openModal.open}
          onClose={() => setOpenModal({ open: false, modalName: 'dialog', data: openModal.data })}
        >
          <AlertDialog
            open={openModal.open}
            close={() => setOpenModal({ open: false, modalName: 'dialog', data: openModal.data })}
            title="로그인 실패횟수 초과 아이디 잠김해제"
            message={`로그인 실패 횟수 초과로 인한 <br /> <br /> 아이디 잠금 상태를 해제하시겠습니까?`}
            isHtml={true}
            confirm="해제"
            onClick={getEmployeeUnlockProcess}
            indicator={props.indicator}
            history={props.history}
          />
        </Modal>
      }

      { openModal.open && openModal.modal === 'del' &&
        <Modal
          open={openModal.open}
          onClose={() => setOpenModal({ open: false, modalName: openModal.modal, data: openModal.data })}
        >
          <AlertDialog
            open={openModal.open}
            close={() => setOpenModal({ open: false, modalName: openModal.modal, data: openModal.data })}
            title="사용자 삭제"
            message={`선택하신 사용자를 삭제하시겠습니까?`}
            isHtml={true}
            confirm="삭제"
            onClick={getEmployeeDel}
            indicator={props.indicator}
            history={props.history}
          />
        </Modal>
      }

      { // 권한선택 모달
        openModal.open && openModal.modal === 'authgroup' &&
        <Dialog
          open={openModal}
          onClose={() => (event, reason) => {
            if (reason && reason === "backdropClick") return;
            setOpenModal({open: false, modal: openModal.modal, data: {}});
          }}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "500px",  // Set your width here
              },
            },
          }}
        >
          <section className="modal">
            <AuthModal
              open={openModal.open}
              close={() => setOpenModal({ open: false, modalName: openModal.modal, data: openModal.data })}
              data={openModal.data}
              gridApi={gridApi}
              getList={getEmployeeList}
              indicator={props.indicator}
              history={props.history}
            />
          </section>
        </Dialog>
      }

      { // 사용자 수동 추가/수정 모달
        openModal.open && openModal.modal === 'add' &&
        <Dialog
          open={openModal}
          onClose={() => (event, reason) => {
            if (reason && reason === "backdropClick") return;
            setOpenModal({open: false, modal: openModal.modal, data: {}});
          }}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "500px",  // Set your width here
              },
            },
          }}
        >
          <section className="modal">
            <UserAddModal
              open={openModal.open}
              close={() => setOpenModal({ open: false, modalName: openModal.modal, data: openModal.data })}
              data={openModal.data}
              gridApi={gridApi}
              getList={getEmployeeList}
              indicator={props.indicator}
              history={props.history}
            />
          </section>
        </Dialog>
      }
    </Layout>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default UserManager;