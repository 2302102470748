/**
 * * 용도 리스트 가져오기
 * @param {*} getApi
 * @param {*} params
 * @param {*} func
 * @returns
 */
export function getCostPurposeApi(getApi, func) {
  if( ! getApi ) return;

  getApi("get", '/api/v1/cost/purpose').then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  });
}

/**
 * * 설계비가산정 양식 리스트 가져오기
 * @param {*} getApi
 * @param {*} params
 * @param {*} func
 * @returns
 */
export function getCostFormListApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("get", '/api/v1/cost/form', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  });
}

/**
 * * 비용산출 양식 마스터 추가/수정
 * @param {*} getApi
 * @param {*} params
 * @param {*} func
 * @returns
 */
export function setCostFormApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("post", '/api/v1/cost/form', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  });
}

/**
 * * 비용산출 양식 마스터 삭제
 * @param {*} getApi
 * @param {*} params
 * @param {*} func
 * @returns
 */
export function deleteCostFormApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("post", '/api/v1/cost/form/delete', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  });
}

/**
 * * 설계비가산정 양식 디테일 리스트 가져오기
 * @param {*} getApi
 * @param {*} params
 * @param {*} func
 * @returns
 */
export function getCostFormDetailApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("get", '/api/v1/cost/form/detail', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  });
}

/**
 * * 설계비가산정 외주용역비 가져오기
 * @param {*} getApi
 * @param {*} params
 * @param {*} func
 * @returns
 */
export function getCostItemApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("get", '/api/v1/cost/item', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  });
}

/**
 * * 설계비가산정 양식 추가/수정
 * @param {*} getApi
 * @param {*} params
 * @param {*} func
 * @returns
 */
export function setCostFormDetailApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("post", '/api/v1/cost/form/detail', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  });
}

/**
 * * 설계비가산정 양식 시작/종료년도 가져오기
 * @param {*} getApi
 * @param {*} params
 * @param {*} func
 * @returns
 */
export function getCostYearApi(getApi, func) {
  if( ! getApi ) return;

  getApi("get", '/api/v1/cost/form/year').then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  });
}

/**
 * * 비용산출 양식 디테일 삭제
 * @param {*} getApi
 * @param {*} params
 * @param {*} func
 * @returns
 */
export function deleteCostFormDetailApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("post", '/api/v1/cost/form/detail/delete', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  });
}

/**
 * * 설계비가산정 프로젝트 시작/종료년도 가져오기
 * @param {*} getApi
 * @param {*} params
 * @param {*} func
 * @returns
 */
export function getCostProjectYearApi(getApi, func) {
  if( ! getApi ) return;

  getApi("get", '/api/v1/cost/project/year').then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  });
}

/**
 * * 설계비가산정 프로젝트 리스트 가져오기
 * @param {*} getApi
 * @param {*} params
 * @param {*} func
 * @returns
 */
export function getCostProjectListApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("get", '/api/v1/cost/project', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  });
}

/**
 * * 설계비가산정 프로젝트 별 상세항목 가져오기
 * @param {*} getApi
 * @param {*} params
 * @param {*} func
 * @returns
 */
export function getProjectCostItemApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("get", '/api/v1/cost/item/project', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  });
}

/**
 * * 설계비가산정 프로젝트 별 상세항목 가져오기
 * @param {*} getApi
 * @param {*} params
 * @param {*} func
 * @returns
 */
export function getProjectCostItemListApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("post", '/api/v1/cost/item/project/list', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  });
}
