/*****************************************************************************************
 * 설명 : 프로젝트관리 - 파일관리 - 일괄분석
 * URI : /project
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import FolderIcon from '@mui/icons-material/Folder';
import Button from '@mui/material/Button';
import { AgGridReact } from 'ag-grid-react';
import ChipEx from 'components/chipEx';
import { useEffect, useState } from 'react';

import { MESSAGE_DELAY } from 'config/config';
import useGridConfig from 'hooks/useGridConfig';

import { ButtonEx } from 'components/inputEx';
import alertMsg from 'components/message';
import customTooltipAgGrid from 'components/tooltipAgGrid';

import { getProjectDirectoryScanApi, setProjectDirectoryScanCompleteApi } from 'service/project';
import Restful from "service/restful";
import { formatBytes } from 'service/utils';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const DirectoryScanModal = (props) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [getApi] = Restful();

  const [list, setList] = useState([]);
  const [gridApi, setGridApi] = useState({});
  const [isCheck, setIsCheck] = useState(true);
  const [isComplete, setIsComplete] = useState(false);
  const [progress, setProgress] = useState(0);
  const [status, setStatus] = useState({
    fileName: '',
    fileSize: 0
  })
  const [isDisabled, setIsDisabled] = useState(false);

  // table column
  const [columnDefs, setColumnDefs] = useState([
    { headerName: '키값(수정금지)', field: 'seq', width: 35, cellClass: 'center', flex: 0,
      headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true
    },
    { headerName:'진행상태', field: 'status', width: 100, cellClass: 'cp center',
      cellRendererFramework: function (params) {
        if( ( params.data.status || '' ) === '' )
          return <ChipEx color="default" variant="outlined" size="small" label="대기중" />;
        else if( ( params.data.status || '' ) === 1 )
          return <ChipEx color="success" variant="outlined" size="small" label="진행완료" />;
        else if( ( params.data.status || '' ) === 2 )
          return <ChipEx color="error" variant="outlined" size="small" label="처리실패" />;
        else
          return '';
      }
    },
    { headerName:'경로명', field: 'path', width: 250, cellClass: 'cp',
      valueGetter: (params) => {
        if( params.data.type === 1)
          return params.data.path
        else
          return (params.data.path === '') ? '/' : params.data.path;
      }
    },
    { headerName:'파일명', field: 'title', width: 150, cellClass: 'cp',
      cellRendererFramework: function (params) {
        if( parseInt(params.data?.type || 0) === 1 )
          return ( params.data.title === '' ) ? 'ROOT' : params.data.title;
        else
          return (
            <div
              className="flex vcenter" style={{height: '100%'}}>
                <FolderIcon style={{fontSize: '15px'}}/>
                <div className="ml5" style={{width: 'calc(100% - 30px)'}}>
                  {params.data.title}
                </div>
            </div>
          );
      }
    },
    { headerName:'용량', field: 'fileSize', width: 100, cellClass: 'cp text-right',
      valueGetter: (params) => {
        if( params.data.type === 1)
          return formatBytes(params.data?.fileSize || 0, 2);
        else
          return '';
      }
    },
    { headerName:'태그', field: 'tag', width: 400, cellClass: 'cp', editable: true },
    { headerName:'등록일시', field: 'createDate', width: 150, cellClass: 'cp center' },
  ]);

  const [gridConfig] = useGridConfig(99, setColumnDefs);

  /*****************************************************************************************
   * 설명 : 태그에서 제외할 단어 리스트 가져오기
  *****************************************************************************************/
  const getDirectoryScan = () => {
    let params = {
      projectNumber: props.data?.project?.projectNumber
    }

    getProjectDirectoryScanApi(getApi, params, (response) => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        setList(response.data.data2);

        setIsCheck(true);
      } else {
        alertMsg(response.data.message || '서버와의 통신에 실패하였습니다.', "E", MESSAGE_DELAY);

        props.close();
      }
    });
  }

  /***************************************************************************************
   * 설명 : 스캔된 데이터 일괄 처리
  ***************************************************************************************/
  const setScanProcess = async () => {
    let nodes = [];
    gridApi.forEachNode((item) => {
      nodes.push(item.data);
    });

    let index = 0;

    for await (const params of nodes ) {
      await setStatus(() => {
        return {
          type: params.type,
          fileName: params.title,
          fileSize: params.fileSize
        }
      });

      await setProgress(Math.round((index + 1) / list.length * 100));

      // eslint-disable-next-line no-loop-func
      await getApi("post", '/api/v1/project/directory/scan/save', params).then(async (response) => {
        if (response !== undefined && response.data.result) {
          params.status = 1;
          await gridApi.applyTransaction({update: [params]});
          await gridApi.ensureIndexVisible(index, "bottom");
        } else {
          params.status = 2;
          gridApi.applyTransaction({ update: [params] });
          gridApi.ensureIndexVisible(index, "bottom");
        }

      // eslint-disable-next-line no-loop-func
      }).catch((error) => {
        params.status = 2;
        gridApi.applyTransaction({ update: [params] });
        gridApi.ensureIndexVisible(index, "bottom");
      });

      index++;
    }

    setIsComplete(true);
  }

  /***************************************************************************************
   * 설명 : 일괄 등록 완료 처리
  ***************************************************************************************/
  useEffect(() => {
    if( isComplete ) {
      setIsComplete(false);

      let directoryCount = 0;
      let fileCount = 0;
      let totalSize = 0;

      list.forEach((item) => {
        if( item.type === 0 ) directoryCount++;
        else if( item.type === 1 ) fileCount++;

        totalSize = totalSize + item.fileSize;
      })

      let params = {
        projectNumber: list[0]?.projectNumber,
        fileCount: fileCount,
        directoryCount: directoryCount,
        totalSize: totalSize,
        isInsert: false
      }

      // 완료 처리
      setProjectDirectoryScanCompleteApi(getApi, params, (response) => {
        if( response !== undefined && response.data.result ) {
          alertMsg(response.data.message);

          if( props.getList ) props.getList();
          if( props.getDirectoryList ) props.getDirectoryList();
          if( props.getFileList ) props.getFileList();

        } else {
          alertMsg(response.data.message || '서버와의 통신에 실패하였습니다.', "E", MESSAGE_DELAY);
        }
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isComplete])

  /***************************************************************************************
   * 설명 : 데이터 로딩 처리
  ***************************************************************************************/
  useEffect(() => {
    if( props.open ) {
      getDirectoryScan();
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <section className="modal">
      <section className="modal-wrap" style={{width: '100%'}}>
        <header className="modal-header">
          <div className="modal-title">프로젝트 파일 일괄등록</div>
          <Button className="close-btn" onClick={props.close}>×</Button>
        </header>

        <section className="modal-body">
          <div className="ag-theme-balham" style={{height: '400px'}}>
            <AgGridReact
              defaultColDef={{
                sortable: true,
                resizable: true,
                filter: false,
                lockVisible: true,
                tooltipComponent: customTooltipAgGrid,
              }}
              tooltipShowDelay={0}
              tooltipHideDelay={2000}
              columnDefs={columnDefs}
              rowData={list}
              rowSelection={'single'}
              onGridReady={(event) => {
                gridConfig(event.columnApi);
                setGridApi(event.api);
              }}
              onRowClicked={(event) => {
              }}
              onRowDoubleClicked={(event) => {
              }}
              overlayNoRowsTemplate = "검색된 내용이 없습니다."
            />
          </div>

          <section className="progress-wrap mt20">
            <div className="progress-title clearfix">
              <div className="fl">
                {status?.fileName}
                {status?.type === 1 &&
                  <span> <span className="path f11">({formatBytes(status?.fileSize)})</span></span>
                }
              </div>
              <div className="fr">{progress}%</div>
            </div>
            <div className="progress mt10" style={{height: '20px'}}>
              <div className={"progress-bar bg-green"} style={{ width: progress + '%', height: '20px' }}></div>
            </div>
          </section>
        </section>

        <footer className="modal-footer">
          <div className="modal-footer-center">
            <Button
              color="inherit"
              variant="outlined"
              className="Btn"
              disabeld={isCheck}
              onClick={() => {
                if( isDisabled ) {
                  props.getDirectoryList();

                  // 프로젝트 상태 업데이트
                  let tmp = [...props.list];
                  tmp[props.selectedIndex].isFile = 1;
                  props.setSelected(tmp[props.selectedIndex]);
                  props.setList(tmp);

                  props.getList();
                }

                props.close();
              }}
            >창닫기</Button>

            <ButtonEx
              auth="isWrite"
              title="일괄등록"
              color="secondary"
              variant="outlined"
              className="ml10"
              disabled={isDisabled === true ? true : false}
              onClick={async () => {
                setIsCheck(false);
                setIsComplete(false);
                setIsDisabled(true);

                await setScanProcess();
              }}
            />
          </div>
        </footer>
      </section>
    </section>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default DirectoryScanModal;