/*****************************************************************************************
 * 설명 : pageInfoMsg - 페이지 안내 - 오류, 페이지를 찾을 수 없습니다.
*****************************************************************************************/
import React from 'react';
import { NavLink } from 'react-router-dom';

import logo1 from 'assets/images/logo1.png';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const PageInfoMsg = (props) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <section className="pageInfoMsg">
      <header className="pageInfo-header">
        <NavLink to="/dashboard" className="logo">
          <img src={logo1} alt="DA그룹" />
        </NavLink>
      </header>

      <section className="errorMsg">

        <sectiion className="error1">
          <div className="txt1">404 ERROR</div>
          <div className="txt2">
            에러내용이 나옵니다. 에러내용이 나옵니다.에러내용이 나옵니다.에러내용이 나옵니다.에러내용이 나옵니다. 에러내용이 나옵니다.에러내용이 나옵니다.에러내용이 나옵니다.에러내용이 나옵니다. 에러내용이 나옵니다.에러내용이 나옵니다.에러내용이 나옵니다.
            에러내용이 나옵니다. 에러내용이 나옵니다.에러내용이 나옵니다.에러내용이 나옵니다.에러내용이 나옵니다. 에러내용이 나옵니다.에러내용이 나옵니다.에러내용이 나옵니다.에러내용이 나옵니다. 에러내용이 나옵니다.에러내용이 나옵니다.에러내용이 나옵니다.
            에러내용이 나옵니다. 에러내용이 나옵니다.에러내용이 나옵니다.에러내용이 나옵니다.에러내용이 나옵니다. 에러내용이 나옵니다.에러내용이 나옵니다.에러내용이 나옵니다.에러내용이 나옵니다. 에러내용이 나옵니다.에러내용이 나옵니다.에러내용이 나옵니다.
            에러내용이 나옵니다. 에러내용이 나옵니다.에러내용이 나옵니다.에러내용이 나옵니다.에러내용이 나옵니다. 에러내용이 나옵니다.에러내용이 나옵니다.에러내용이 나옵니다.에러내용이 나옵니다. 에러내용이 나옵니다.에러내용이 나옵니다.에러내용이 나옵니다
            에러내용이 나옵니다. 에러내용이 나옵니다.에러내용이 나옵니다.에러내용이 나옵니다.에러내용이 나옵니다. 에러내용이 나옵니다.에러내용이 나옵니다.에러내용이 나옵니다.에러내용이 나옵니다. 에러내용이 나옵니다.에러내용이 나옵니다.에러내용이 나옵니다.
            에러내용이 나옵니다. 에러내용이 나옵니다.에러내용이 나옵니다.에러내용이 나옵니다.에러내용이 나옵니다. 에러내용이 나옵니다.에러내용이 나옵니다.에러내용이 나옵니다.에러내용이 나옵니다. 에러내용이 나옵니다.에러내용이 나옵니다.에러내용이 나옵니다.
            에러내용이 나옵니다. 에러내용이 나옵니다.에러내용이 나옵니다.에러내용이 나옵니다.에러내용이 나옵니다. 에러내용이 나옵니다.에러내용이 나옵니다.에러내용이 나옵니다.에러내용이 나옵니다. 에러내용이 나옵니다.에러내용이 나옵니다.에러내용이 나옵니다.
            에러내용이 나옵니다. 에러내용이 나옵니다.에러내용이 나옵니다.에러내용이 나옵니다.에러내용이 나옵니다. 에러내용이 나옵니다.에러내용이 나옵니다.에러내용이 나옵니다.에러내용이 나옵니다. 에러내용이 나옵니다.에러내용이 나옵니다.에러내용이 나옵니다
          </div>
        </sectiion>

        <sectiion className="error2  hidden">
          <div className="txt1">페이지를 찾을 수 없습니다. </div>
        </sectiion>

      </section>

      <footer className="footer">
        <div className="footer-txt">Copyright © 2022 by dA Group Urban Design & Architecture Co., Ltd. All Rights Reserved.</div>
      </footer>
    </section>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default PageInfoMsg;