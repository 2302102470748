/**
 * * PMS 수동 동기화
 * @param {*} getApi
 * @param {*} func
 * @returns
 */
export function getPMSSyncApi(getApi, func) {
  if( ! getApi ) return;

  getApi("get", '/api/v1/sync/pms').then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  })
}

/**
 * * 그룹웨어 수동 동기화
 * @param {*} getApi
 * @param {*} func
 * @returns
 */
export function getGroupwareSyncApi(getApi, func) {
  if( ! getApi ) return;

  getApi("get", '/api/v1/sync/groupware').then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  })
}

