/*****************************************************************************************
 * 설명 : 프로젝트관리 - 파일관리
 * URI : /project
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import FolderIcon from '@mui/icons-material/Folder';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Modal from '@mui/material/Modal';
import { AgGridReact } from 'ag-grid-react';
import { useFormik } from 'formik';
import $ from 'jquery';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import SortableTree, { toggleExpandedForAll } from 'react-sortable-tree';
import FileExplorerTheme from 'react-sortable-tree-theme-file-explorer';
import SplitPane from 'react-split-pane';
import * as Yup from "yup";

import { baseURL, DOWNLOAD_FILE, MESSAGE_DELAY } from 'config/config';
import useGridConfig from 'hooks/useGridConfig';

import AlertDialog from 'components/alertDiolog';
import BtnAgGridSave from 'components/btnAgGridSave';
import ChipEx from 'components/chipEx';
import { BtnRefresh, BtnSearch, CurrencyFormat } from 'components/common';
import { ButtonEx, InputEx, SelectEx, ToggleButtonGroupEx } from 'components/inputEx';
import alertMsg from 'components/message';
import customTooltipAgGrid from 'components/tooltipAgGrid';

import { getCommonListApi } from 'service/common';
import {
  deleteFileListApi, getPathInfoApi,
  getProjectBatchApi, getProjectPathDirectoryApi,
  getProjectPathDirectoryFileApi,
  setProjectFileComplete,
  setProjectFileNotUpload,
  setProjectFileTagApi,
  setProjectPathScanBackground,
  setProjectThumbBackground
} from 'service/project';
import Restful from "service/restful";
import { comma, formatBytes, getSelectData, isCheckValue, PaperComponent, selectGrid } from 'service/utils';

import ExcelModal from 'pages/admin/project/modal/excelModal';
import FileModal from 'pages/admin/project/modal/fileModal';
import ProjectBatchErrorModal from './modal/batchErrorModal';
import DirectoryScanModal from './modal/directoryScanModal';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const TabFile = ( props ) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [getApi] = Restful();

  const user = useSelector(state => state?.userInfo?.user);

  const [gridApi, setGridApi] = useState({});
  const [gridApiFile, setGridApiFile] = useState({});

  const [batchList, setBatchList] = useState([]);
  const [directoryList, setDirectoryList] = useState([]);
  const [fileList, setFileList] = useState([]);

  const [isUploadCheck, setIsUploadCheck] = useState('');

  const [selected, setSelected] = useState({});
  const [pathInfo, setPathInfo] = useState({
    totalDirectoryCount: 0,
    totalFileCount: 0,
    totalSize: 0
  });

  const [dirPathInfo, setDirPathInfo] = useState({
    totalDirectoryCount: 0,
    totalFileCount: 0,
    totalSize: 0
  });

  const [openModal, setOpenModal] = useState({open: false, modal: 'sync', data: []});

  const initialValues = {
    ext: '',
    extSearch: '',
    fileSize: '',
    sizeType: 1,
    searchText: '',
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object().shape({
      searchText: Yup.string().max(30, "30자리"),
      fileSize: Yup.number().typeError("숫자"),
    }),
    onSubmit: (values) => {
      getFileList();
    }
  });

  const [selectedNode, setSelectedNode] = useState({});
  const [fileExtList, setFileExtList] = useState([]);

  // table column
  const [columnDefs, setColumnDefs] = useState([
    { headerName: '', field: 'seq', width: 35, cellClass: 'center', flex: 0,
      headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
    { headerName: '순번', field: 'rowIndex', width: 45, cellClass: 'cp text-right',
      valueGetter: (params) => {
        return comma(params.node.rowIndex + 1);
      }
    },
    { headerName: '상태', field: 'status', width: 90, cellClass: "cp center",
      cellRendererFramework: function(params) {
        if( parseInt(params.data.status) === 0 )
          return <ChipEx color="secondary" variant="outlined" size="small" label="일괄등록중" />;
        else if( parseInt(params.data.status) === 1 )
          return <ChipEx color="error" variant="outlined" size="small" label="등록에러" />;
        else
          return <ChipEx color="success" variant="outlined" size="small" label="등록완료" />;
      }
    },
    { headerName: '등록구분', field: 'type', width: 90, cellClass: "cp center",
      cellRendererFramework: function(params) {
        if( parseInt(params.data.type) === 0 )
          return <ChipEx color="primary" variant="outlined" size="small" label="개별등록" />;
        else if( parseInt(params.data.type) === 1 )
          return <ChipEx color="success" variant="outlined" size="small" label="일괄등록" />;
        else if( parseInt(params.data.type) === 3 )
          return <ChipEx color="secondary" variant="outlined" size="small" label="섬네일생성" />;
        else if( parseInt(params.data.type) === 4 )
          return <ChipEx color="error" variant="outlined" size="small" label="파일삭제" />;
        else
          return <ChipEx color="error" variant="outlined" size="small" label="파일없음" />;
      }
    },
    { headerName: '등록일시', field: 'createDate', width: 140, cellClass: "cp center" },
    { headerName: '폴더개수', field: 'directoryCount', width: 80, cellClass: "cp text-right", valueFormatter: CurrencyFormat },
    { headerName: '파일개수', field: 'fileCount', width: 80, cellClass: "cp text-right", valueFormatter: CurrencyFormat },
    { headerName: '용량(GB)', field: 'totalSize', width: 80, cellClass: "cp text-right",
      valueGetter: (params) => {
        if(params.data.totalSize === 0)
          return 0;
        else
          return comma(parseFloat(params.data.totalSize / (1024*1024*1024)).toFixed(2));
      }
    },
    { headerName: '메시지', field: 'message', width: 300, cellClass: "cp", tooltipField: 'message'},
    { headerName: '에러로그', field: 'log', width: 300, cellClass: "cp", tooltipField: 'log' },
  ]);

  // table column
  const [columnDefsFile, setColumnDefsFile] = useState([
    { headerName: '', field: 'seq', width: 40, cellClass: 'center', flex: 0,
      headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
    { headerName:'섬네일', field: 'isThumbError', width: 80, cellClass: 'cp',
      cellRendererFramework: function (params) {
        if( parseInt(params.data.isThumbError) === 1 )
          return <ChipEx color="error" variant="outlined" size="small" label="에러" />;
        else
          return '';
      }
    },
    { headerName:'파일명', field: 'title', width: 300, cellClass: 'cp', tooltipField: 'title',
      cellRendererFramework: function (params) {
        if( params.data.type === 1 )
          return params.data.title;
        else
          return (
            <div
              className="flex vcenter" style={{height: '100%'}}>
                <FolderIcon style={{fontSize: '15px'}}/>
                <div className="ml5" style={{width: 'calc(100% - 30px)'}}>
                  {params.data.title}
                </div>
            </div>
          );
      }
    },
    { headerName:'파일', field: 'path', hide: true},
    { headerName:'실제위치', field: 'destPath', hide: true},
    { headerName:'용량', field: 'fileSize', width: 100, cellClass: 'cp text-right',
      valueGetter: (params) => {
        return formatBytes(params.data.fileSize, 2);
      }
    },
    { headerName:'태그', field: 'tag', width: 200, cellClass: 'cp', editable: true, tooltipField: 'tag' },
    { headerName:'등록일시', field: 'createDate', width: 150, cellClass: 'cp center' },
    { headerName:'다운로드', field: 'download', width: 70, cellClass: 'cp center',
      cellRendererFramework: function (params) {
        return (
          <div className={"grid-icon"}>
            <Button
              color="inherit"
              onClick={() => {
                let url = baseURL + '/api/v1/file/download?seq=' + params.data.seq;
                window.open(url)
              }}
            >
              <i className="ri-download-line"></i>
            </Button>
          </div>
        );
      }
    },
    { headerName:'미리보기', field: 'preview', width: 70, cellClass: 'cp center',
      cellRendererFramework: function (params) {
        return (
          <div className={"grid-icon"}>
            { DOWNLOAD_FILE.includes(params.data.ext?.toLowerCase()) &&
              <Button
                color="inherit"
                onClick={() => {
                  let url = 'http://archive.dagroup.co.kr/synap/SynapDocViewServer/job?fileType=Local&sync=true&fid=' + params.data.seq + '&filePath=';
                  window.open(url + encodeURIComponent(params.data.destPath))
                }}
              >
                <i className="ri-eye-line"></i>
              </Button>
            }
            { params.data.ext?.toLowerCase() === 'dwg' &&
              <Button
                color="inherit"
                onClick={() => {
                  let url = '/autocad?seq=' + params.data.seq;
                  window.open(url)
                }}
              >
                <i className="ri-eye-line"></i>
              </Button>
            }
          </div>
        );
      }
    },
    { headerName:'연관프로젝트', field: 'relationProject', width: 200, cellClass: 'cp', editable: true, tooltipField: 'relationProject' },
    { headerName:'비고', field: 'memo', width: 200, cellClass: 'cp', editable: true, tooltipField: 'memo' },
  ]);

  const [gridConfig, setGridUpdate] = useGridConfig(22, setColumnDefs);
  const [gridConfigFile, setGridUpdateFile] = useGridConfig(22, setColumnDefsFile);

  /***************************************************************************************
   * 설명 : 경로 리스트 가져오기
  ***************************************************************************************/
  const getList = () => {
    setDirectoryList([]);
    setFileList([]);

    let params = {
      projectNumber: props.selected?.projectNumber
    }

    getProjectBatchApi(getApi, params, (response) => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        setBatchList(response.data.data);

      } else {
        setBatchList([]);
      }
    });
  }

  /***************************************************************************************
   * 설명 : 선택한 파일리스트 삭제
  ***************************************************************************************/
  const deleteFileList = () => {
    let nodes = gridApiFile.getSelectedRows();

    if( nodes.length < 1 ) {
      alertMsg("삭제하고자 하는 파일을 선택하시기 바랍니다.", "W", MESSAGE_DELAY);
      return;
    }

    let params = {
      projectNumber: props.selected?.projectNumber,
      data: nodes
    }

    deleteFileListApi(getApi, params, (response) => {
      if( response !== undefined && response.data.result ) {
        alertMsg(response.data.message, "S", MESSAGE_DELAY);

        getList();

        getFileList();

        let params1 = {
          seq: selectedNode.seq || 0,
          projectNumber: props.selected?.projectNumber,
          ...formik.values
        }

        getPathInfoApi(getApi, params1, (response) => {
          if( response !== undefined && response.data.result ) {
            setPathInfo(response.data.data);
            setDirPathInfo(response.data.data1);
          }
        });

      } else {
        alertMsg(response.data.message || '서버와의 통신에 실패하였습니다.', "E", MESSAGE_DELAY);
      }
    })
  }

  /***************************************************************************************
   * 설명 : 그리드에서 태그 직접 수정
  ***************************************************************************************/
  const modifyTag = (data) => {
    let params = {
      seq: data.data?.seq,
      tag: data.data?.tag
    }

    setProjectFileTagApi(getApi, params, (response) => {
      if( response !== undefined && response.data.result ) {

      } else {
        alertMsg(response.data.message || '서버와의 통신에 실패하였습니다.', "E", MESSAGE_DELAY);
      }
    });
  }

  /***************************************************************************************
   * 설명 : parent 경로 가져오기
  ***************************************************************************************/
  const findParentDirectory = (path, data) => {
    for(let i = 0; i < data.length; i++ ) {
      if( data[i].path === path ) return data;
      else {
        if( data[i].children !== null && data[i].children !== undefined ) {
          let tmp = findParentDirectory(path, data[i].children);
          if( tmp !== undefined && tmp.length > 0 ) return tmp;
        }
      }
    }
  }

  /***************************************************************************************
   * 설명 : 경로 선택 시 폴더 리스트 가져오기
  ***************************************************************************************/
  const getDirectoryList = () => {
    if( selected.length < 1 ) return;

    setFileList([]);

    let params = {
      projectNumber: props.selected?.projectNumber
    }

    getProjectPathDirectoryApi(getApi, params, (response) => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        setPathInfo(response.data.data1);

        // 경로 분석 처리
        let data = response.data.data;
        let tmp = [];
        if( data.length > 0 ) {
          tmp.push(data[0]);

          for(let i = 1; i < data.length; i++ ) {
            let pathName = data[i].path;
            let len = String('/' + data[i].title).length;
            let path = pathName?.substr(0, pathName.length - len);
            let tmp1 = findParentDirectory(path, tmp);

            if( tmp1 === undefined ) {
              tmp.push(data[i]);
            } else {
              let index = tmp1.length - 1;
              if( tmp1[index].children === null || tmp1[index].children === undefined ) tmp1[index].children = [];
              tmp1[index].children.push(data[i]);
            }
          }
        }

        setDirectoryList(tmp);

      } else {
        setDirectoryList([]);
      }
    });
  }

  /***************************************************************************************
   * 설명 : 디렉토리 선택 시 파일 리스트 가져오기
  ***************************************************************************************/
  const getFileList = () => {
    if( selectedNode.length < 1 ) return;

    setFileList([]);

    let params = {
      seq: selectedNode.seq || 0,
      projectNumber: props.selected?.projectNumber,
      ...formik.values
    }

    getProjectPathDirectoryFileApi(getApi, params, (response) => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        setFileList(response.data.data);

        setDirPathInfo(response.data.data1);

      } else {
        setFileList([]);
        setDirPathInfo({
          totalDirectoryCount: 0,
          totalFileCount: 0,
          totalSize: 0
        })
      }
    });
  }

  /***************************************************************************************
   * 설명 : 경로 분석 처리
  ***************************************************************************************
  const pathScan = () => {
    let params = {
      ...props.selected
    }

    setProjectPathScan(getApi, params, (response) => {
      if( response !== undefined && response.data.result ) {
        alertMsg(response.data.message, "S", MESSAGE_DELAY);

        if( response.data.data && response.data.data.length > 0 ) {
          //setDirectoryList(response.data.data);
          //setFileList(response.data.data1);
          getDirectoryList();

          // 프로젝트 상태 업데이트
          let tmp = [...props.list];
          tmp[props.selectedIndex].isFile = 1
          props.setSelected(tmp[props.selectedIndex]);
          props.setList(tmp);

          getList();
        }

      } else {
        alertMsg(response.data.message || '서버와의 통신에 실패하였습니다.', "E", MESSAGE_DELAY);
      }
    });

    setOpenModal({ open: false, modalName: openModal.modal, data: openModal.data })
  }

  /***************************************************************************************
   * 설명 : 경로 분석 처리
  ***************************************************************************************/
  const pathScanBackground = () => {
    let params = {
      ...props.selected,
      userId: user.userId,
      hostname: window.location.hostname
    }

    setProjectPathScanBackground(getApi, params, (response) => {
      if( response !== undefined && response.data.result ) {
        alertMsg(response.data.message, "S", MESSAGE_DELAY);

        let tmp = window.localStorage.getItem('da_scan');
        if( tmp && tmp !== '' ) {
          let tmp1 = JSON.parse(tmp);

          if( Array.isArray(tmp1) ) {
            tmp1.push({
              isCheck: false,
              isRefresh: false,
              seq: response.data.data.seq,
              projectNumber: props.selected.projectNumber,
              memNo: user.memNo
            });
            window.localStorage.setItem('da_scan', JSON.stringify(tmp1));
          }

        } else {
          let tmp1 = [];
          tmp1.push({
            isCheck: false,
            isRefresh: false,
            seq: response.data.data.seq,
            projectNumber: props.selected.projectNumber,
            memNo: user.memNo
          });

          window.localStorage.setItem('da_scan', JSON.stringify(tmp1));
        }

        // 리스트 업로드 상태 변경
        // props.selected?.isUploadCheck
        let tmp2 = {...props.selected};
        tmp2.isUploadCheck = 0;
        setIsUploadCheck(0);
        props.setSelected(tmp2);

        let tmp3 = [...props.list];
        tmp3[props.selectedIndex].isUploadCheck = 0;
        props.setList(tmp3);

      } else {
        alertMsg(response.data.message || '서버와의 통신에 실패하였습니다.', "E", MESSAGE_DELAY);
      }
    });

    setOpenModal({ open: false, modalName: openModal.modal, data: openModal.data })
  }

  /***************************************************************************************
   * 설명 : 섬네일 생성
  ***************************************************************************************/
  const thumbBackground = () => {
    let nodes = gridApiFile.getSelectedRows();
    let params = {
      projectNumber: props.selected.projectNumber,
      projectName: props.selected.projectName,
      userId: user.userId,
      data: []
    }

    if( nodes.length > 0 ) {
      params.data = nodes;
    }

    setProjectThumbBackground(getApi, params, (response) => {
      if( response !== undefined && response.data.result ) {
        alertMsg(response.data.message, "S", MESSAGE_DELAY);

        let tmp = window.localStorage.getItem('da_scan');
        if( tmp && tmp !== '' ) {
          let tmp1 = JSON.parse(tmp);

          if( Array.isArray(tmp1) ) {
            tmp1.push({
              isCheck: false,
              isRefresh: false,
              seq: response.data.data.seq,
              projectNumber: props.selected.projectNumber,
              memNo: user.memNo
            });
            window.localStorage.setItem('da_scan', JSON.stringify(tmp1));
          }

        } else {
          let tmp1 = [];
          tmp1.push({
            isCheck: false,
            isRefresh: false,
            seq: response.data.data.seq,
            projectNumber: props.selected.projectNumber,
            memNo: user.memNo
          });

          window.localStorage.setItem('da_scan', JSON.stringify(tmp1));
        }

        // 리스트 업로드 상태 변경
        // props.selected?.isUploadCheck
        let tmp2 = {...props.selected};
        tmp2.isUploadCheck = 0;
        setIsUploadCheck(0);
        props.setSelected(tmp2);

        let tmp3 = [...props.list];
        tmp3[props.selectedIndex].isUploadCheck = 0;
        props.setList(tmp3);

      } else {
        alertMsg(response.data.message || '서버와의 통신에 실패하였습니다.', "E", MESSAGE_DELAY);
      }
    });

    setOpenModal({ open: false, modalName: openModal.modal, data: openModal.data })
  }

  /***************************************************************************************
   * 설명 : 등록완료 처리
  ***************************************************************************************/
  const setFileComplte = () => {
    let params = {
      ...props.selected,
      isFile: 2
    }

    setProjectFileComplete(getApi, params, (response) => {
      if( response !== undefined && response.data.result ) {
        alertMsg(response.data.message, "S", MESSAGE_DELAY);

        // 프로젝트 상태 업데이트
        let tmp = [...props.list];
        tmp[props.selectedIndex].isFile = 2;
        props.setSelected(tmp[props.selectedIndex]);
        props.setList(tmp);

      } else {
        alertMsg(response.data.message || '서버와의 통신에 실패하였습니다.', "E", MESSAGE_DELAY);
      }
    });

    setOpenModal({ open: false, modalName: openModal.modal, data: openModal.data })
  }

  /***************************************************************************************
   * 설명 : 파일 없음 처리
  ***************************************************************************************/
  const setFileNotUpload = () => {
    let params = {
      ...props.selected
    }

    setProjectFileNotUpload(getApi, params, (response) => {
      if( response !== undefined && response.data.result ) {
        alertMsg(response.data.message, "S", MESSAGE_DELAY);

        setDirectoryList([]);
        setFileList([]);

        // 프로젝트 상태 업데이트
        let tmp = [...props.list];
        tmp[props.selectedIndex].isFile = 3;
        props.setSelected(tmp[props.selectedIndex]);
        props.setList(tmp);

        // 일괄등록 정보 다시 가져오기
        getList();

      } else {
        alertMsg(response.data.message || '서버와의 통신에 실패하였습니다.', "E", MESSAGE_DELAY);
      }
    });

    setOpenModal({ open: false, modalName: openModal.modal, data: openModal.data })
  }

  /***************************************************************************************
   * 설명 : 트리 노드 클릭 시 처리
  ***************************************************************************************/
  const treeRowClick = () => {
    $(".rstcustom__node").each((index, item) => {
      $(item).on('click', (event) => {
        // 기존 선택된 노드 삭제
        $(".project-file").find('.tree-selected-parent').each((index, subItem) => {
          $(subItem).removeClass('tree-selected-parent');
        });

        $(event.target).closest('.rstcustom__rowWrapper').addClass('tree-selected-parent');
      });
    })
  }

  /***************************************************************************************
   * 설명 : 공통코드 리스트 데이터 가져오기
  ***************************************************************************************/
  const getCommonList = () => {
    let params = {
      groupCode: 'EXT'
    }

    getCommonListApi(getApi, params, (response) => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        setFileExtList(getSelectData(response.data.data, 'commonName', 'commonCode'));
      } else {
        setFileExtList([]);
      }
    });
  }

  /***************************************************************************************
   * 설명 : 트리 노드 전체 열기
  ***************************************************************************************/
  const treeExpanded = (expanded) => {
    setDirectoryList(toggleExpandedForAll({
      treeData: directoryList,
      expanded,
    }), );
  }

  /***************************************************************************************
   * 설명 : 파일형식 변경 시 자동 검색
  ***************************************************************************************/
  useEffect(() => {
    if( props.tabIndex === 1 && formik.values.ext !== 'ext') {
      getFileList();
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.ext]);

  /***************************************************************************************
   * 설명 : 트리 노드 클릭 시 파일 리스트 표시
  ***************************************************************************************/
  useEffect(() => {
    if( props.tabIndex === 1 && selectedNode.seq !== undefined ) {
      getFileList();
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNode]);

  /***************************************************************************************
   * 설명 : 트리 노드 클릭 시 처리
  ***************************************************************************************/
  useEffect(() => {
    if( props.tabIndex === 1 && directoryList.length > 0 ) {
      treeRowClick();

      // 디렉토리 첫번째 자동 선택
      setTimeout(() => {
        $(".rstcustom__node").each((index, item) => {
          if( index === 0 ) {
            $(item).trigger("click");
          }
        })

        setSelectedNode(directoryList[0]);
      }, 500);
    }

    // eslint-disable-next-line
  }, [directoryList]);

  /***************************************************************************************
   * 설명 : 일괄등록 리스트 불러올 경우 첫번째 데이터 자동 선택
  ***************************************************************************************/
  useEffect(() => {
    if( props.tabIndex === 1 && batchList.length > 0 ) {
      selectGrid(gridApi, selected, setSelected, true);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [batchList]);

  /***************************************************************************************
   * 설명 : 프로젝트 선택 시 경로 리스트 가져오기
  ***************************************************************************************/
  useEffect(() => {
    if( props.tabIndex === 1 && props.selected.seq !== undefined ) {
      // 파일 ext 리스트
      getCommonList();

      getList();

      getDirectoryList();

      setIsUploadCheck(props.selected?.isUploadCheck);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selected, props.tabIndex]);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <section className="project-file mt10">
      <header className="admin-cms-search-header mb10">
        <div className="fl left">
          <BtnRefresh click={(event) => {
            formik.setValues(initialValues);
            formik.handleSubmit();
          }}></BtnRefresh>
          <BtnSearch></BtnSearch>
          <BtnAgGridSave click={setGridUpdate} />
        </div>

        <div className="fl left">
          <div className="fl ml5">
            { // 프로젝트 추가 정보 상태
              props.selected.isConfirm === null
              ? <ChipEx color="error" variant="variant" size="small" label="등록" className="f12" />
              : ! isCheckValue(props.selected?.detailSeq) && parseInt(props.selected?.isConfirm) !== 1
              ? <ChipEx color="error" variant="variant" size="small" label="등록" className="f12" />
              : isCheckValue(props.selected?.detailSeq) && parseInt(props.selected?.isConfirm) !== 1
              ? <ChipEx color="success" variant="variant" size="small" label="추가" className="f12" />
              : <ChipEx color="primary" variant="variant" size="small" label="등록완료" className="f12" />
            }

            { // 프로젝트 파일 정보 상태
              props.selected?.isUploadCheck === 0 ? <ChipEx color="info" variant="variant" size="small" className="ml5 f12" label="처리중" />
              : ! isCheckValue(props.selected?.isFile) || parseInt(formik.values.isFile) === 0
              ? <ChipEx color="error" variant="variant" size="small" className="ml5 f12" label="미등록" />
              : parseInt(props.selected?.isFile) === 1
              ? <ChipEx color="secondary" variant="variant" size="small" className="ml5 f12" label="등록중" />
              : parseInt(props.selected?.isFile) === 2
              ? <ChipEx color="success" variant="variant" size="small" className="ml5 f12" label="등록완료" />
              : <ChipEx color="primary" variant="variant" size="small" className="ml5 f12" label="처리완료" />
            }
            <div style={{display: "inline-block", verticalAlign: 'middle'}}>
              <span className="ml5 bold f14">[{props.selected?.projectNumber}]</span>
              <span className="pad-newLine"></span>
              <span className="ml5 bold f14">{props.selected?.projectName}</span>
            </div>
          </div>
        </div>

        <div className="fr right">
          <ButtonEx
            title="일괄등록"
            auth="isModify"
            variant="outlined"
            color="primary"
            disabled={(isUploadCheck === 0) ? true : false}
            onClick={() => {
              setOpenModal({
                open: true,
                modal: 'scan',
                // modal: 'scanModal',
                data: {
                  project: props?.selected,
                  info: selected
                }
              })
            }}
          />

          <ButtonEx
            title="섬네일 재생성"
            auth="isModify"
            variant="outlined"
            color="primary"
            className="ml5"
            disabled={(isUploadCheck === 0) ? true : false}
            onClick={() => {
              if( ! isCheckValue(props.selected?.isFile) || parseInt(formik.values.isFile) === 0 ) {
                alertMsg("프로젝트 파일 일괄등록을 먼저 진행해 주세요.", "W", MESSAGE_DELAY);
                return;
              }

              setOpenModal({
                open: true,
                modal: 'thumb',
                // modal: 'scanModal',
                data: {
                  project: props?.selected,
                  info: selected
                }
              })
            }}
          />

          <ButtonEx
            title="파일없음"
            auth="isModify"
            variant="outlined"
            color="error"
            className="ml5"
            disabled={(isUploadCheck === 0) ? true : false}
            onClick={() => {
              setOpenModal({
                open: true,
                modal: 'fileNotUpload',
                data: {
                  project: props?.selected,
                  info: selected
                }
              })
            }}
          />

          <ButtonEx
            title="등록완료"
            auth="isModify"
            variant="outlined"
            color="secondary"
            className="ml5"
            disabled={(isUploadCheck === 0) ? true : false}
            onClick={() => {
              setOpenModal({
                open: true,
                modal: 'complete',
                data: {
                  project: props?.selected,
                  info: selected
                }
              })
            }}
          />
        </div>
        <div className="clearfix"></div>
      </header>

      <div className="ag-theme-balham mt5" style={{height: '200px'}}>
        <AgGridReact
          defaultColDef={{
            sortable: true,
            resizable: true,
            filter: false,
            lockVisible: true,
            tooltipComponent: customTooltipAgGrid,
          }}
          tooltipShowDelay={0}
          tooltipHideDelay={2000}
          columnDefs={columnDefs}
          rowData={batchList}
          rowSelection={'single'}
          onGridReady={(event) => {
            gridConfig(event.columnApi);
            setGridApi(event.api);
          }}
          onSelectionChanged={(event) => {
            let nodes = event.api.getSelectedRows();

            if( nodes.length > 0 ) {
              setSelected(nodes);
            } else {
              setSelected([]);
            }
          }}
          onCellDoubleClicked={(event) => {
            // 에러로그 일 경우 모달 표시
            if( event?.colDef?.field === 'log' && parseInt(event.data.status) === 1 ) {
              setOpenModal({open: true, modal: 'error', data: event.data})
            }
          }}
          overlayNoRowsTemplate = "검색된 내용이 없습니다."
        />
      </div>

      <SplitPane className="mt10 pad-height490" split="vertical" minSize={180} maxSize={500} defaultSize={270} style={{height: 'calc(100vh - 450px)'}}>
        <section className="h100">
          <header className="admin-cms-search-header">
            <div className="fl left">
              <h4 className="mt5 mb10">폴더/파일 정보</h4>
            </div>
            <div className="fr right">
              <Button
                color="primary"
                variant='outlined'
                onClick={() => treeExpanded(true)}
                className="mr5 btn-refresh"
              >
                <span className="material-icons-outlined f18">folder_open</span>
              </Button>

              <Button
                color="primary"
                variant='outlined'
                onClick={() => treeExpanded(false)}
                className="btn-refresh"
              >
                <span className="material-icons f18">folder</span>
              </Button>
            </div>
            <div className="clearfix"></div>
          </header>

          <section className="p10" style={{height: 'calc(100% - 40px)', border: 'solid 1px #ccc', overflow: 'auto'}}>
            <div style={{ height: '100%' }}>
              <SortableTree
                treeData={directoryList}
                onChange={(rowInfo) => {
                  setDirectoryList(rowInfo);
                }}
                isVirtualized={false}
                theme={FileExplorerTheme}
                style={{fontSize: '13px'}}
                canDrag={false}
                scaffoldBlockPxWidth={22}
                generateNodeProps={(rowInfo) => {
                  return ({
                    onClick: (event) => {
                      setSelectedNode(rowInfo.node);
                    },
                    icons: rowInfo.node?.children !== undefined && rowInfo.node?.children?.length > 0
                      ? [
                          <div
                            style={{
                              borderLeft: 'solid 8px gray',
                              borderBottom: 'solid 10px gray',
                              marginRight: 10,
                              boxSizing: 'border-box',
                              width: 16,
                              height: 12,
                              filter: rowInfo.node.expanded
                                ? 'drop-shadow(1px 0 0 gray) drop-shadow(0 1px 0 gray) drop-shadow(0 -1px 0 gray) drop-shadow(-1px 0 0 gray)'
                                : 'none',
                              borderColor: rowInfo.node.expanded ? 'white' : 'gray',
                            }}
                          />,
                        ]
                      : [
                          <div
                            style={{
                              borderLeft: 'solid 8px gray',
                              borderBottom: 'solid 10px gray',
                              marginRight: 10,
                              boxSizing: 'border-box',
                              width: 16,
                              height: 12,
                              filter: 'drop-shadow(1px 0 0 gray) drop-shadow(0 1px 0 gray) drop-shadow(0 -1px 0 gray) drop-shadow(-1px 0 0 gray)',
                              borderColor: 'white',
                            }}
                          />,
                        ],
                    className: selectedNode?.id === (rowInfo.node?.id || '') ? 'tree-selected' : '',
                  })
                }}
              />
            </div>

          </section>
        </section>

        <section className="h100">
          <header className="admin-cms-search-header clearfix mb5">
            <div className="fl">
              <form onSubmit={formik.handleSubmit}>
                <BtnRefresh click={(event) => {
                  formik.setValues(initialValues);
                  formik.handleSubmit();
                }}></BtnRefresh>
                <BtnSearch></BtnSearch>
                <BtnAgGridSave click={setGridUpdateFile} />

                <SelectEx
                  name="ext"
                  formik={formik}
                  fullWidth={false}
                  style={{width: '150px'}}
                  data={[
                    {label: '파일형식', value: ''}
                  ].concat(fileExtList).concat([{label: '직접입력', value: 'ext'}])}
                  onChange={(event) => {
                    if( event.target.value !== 'ext')
                      formik.setFieldValue('extSearch', '');
                  }}
                />

                {formik.values.ext === 'ext' &&
                  <InputEx
                    name="extSearch"
                    formik={formik}
                    label="확장명 입력"
                    className="basic ml5 mr5"
                    style={{width: '100px'}}
                  />
                }

                <InputEx
                  name="fileSize"
                  formik={formik}
                  label="파일크기(MB)"
                  className="basic ml5 mr5"
                  style={{width: '100px'}}
                />

                <ToggleButtonGroupEx
                  name="sizeType"
                  exclusive={true}
                  formik={formik}
                  className="search-toggle-btn mr5"
                  data={[
                    {label: '보다 큰', value: 1},
                    {label: '보다 작은', value: 2},
                  ]}
                />

                <InputEx
                  name="searchText"
                  formik={formik}
                  fullWidth={false}
                  className="pad-mt5"
                  label="파일명, 태그"
                />
              </form>
            </div>

            <div className={"fr tempFolder pad-mt28-minus"}>
              <div className="basicBtn">
                <ButtonEx
                  auth="isDelete"
                  title="삭제"
                  color="error"
                  className="ml5"
                  variant="outlined"
                  disabled={parseInt(selected[0]?.isConfirm) === 1 ? true : false}
                  onClick={() => {
                    let nodes = gridApiFile.getSelectedRows();

                    if( nodes.length < 1 ) {
                      alertMsg("삭제할 파일을 선택하시기 바랍니다.", "W", MESSAGE_DELAY);
                      return;
                    }

                    setOpenModal({open: true, modal: 'dialog', data: nodes});
                  }}
                />

                <ButtonEx
                  auth="isWrite"
                  title="추가"
                  color="secondary"
                  className="ml5"
                  variant="outlined"
                  disabled={(isUploadCheck === 0) ? true : false}
                  onClick={() => setOpenModal({open: true, modal: 'fileAdd', data: props.selected})}
                />
              </div>
            </div>
          </header>

          <section className="pad-height78-percent" style={{height:'calc(100% - 40px)'}}>
            <section className="ag-theme-balham grid" style={{height:'100%'}}>
              <div className="" style={{ height: '100%' }}>
                <AgGridReact
                  defaultColDef={{
                    sortable: true,
                    resizable: true,
                    filter: false,
                    lockVisible: true,
                    tooltipComponent: customTooltipAgGrid,
                  }}
                  tooltipShowDelay={0}
                  tooltipHideDelay={2000}
                  onGridReady={(event) => {
                    gridConfigFile(event.columnApi);
                    setGridApiFile(event.api);
                  }}
                  rowSelection = {'multiple'}
                  columnDefs={columnDefsFile}
                  rowData={fileList}
                  rowDragManaged={true}
                  rowDragMultiRow={true}
                  animateRows={true}
                  overlayNoRowsTemplate = "검색된 내용이 없습니다."
                  onRowClicked={() => {
                    setOpenModal({open: true, modal: 'projectInfo', data: {}})
                  }}
                  onCellEditingStopped={(event) => {
                    modifyTag(event.node);
                  }}
                />
              </div>
            </section>
          </section>
        </section>
      </SplitPane>

      <footer className="footer-txt">
        <div className="fl">
          프로젝트 전체 : 폴더 {comma(pathInfo?.totalDirectoryCount || 0)}개,
          파일 {comma(pathInfo?.totalFileCount || 0)}개
          / 사용용량 : {formatBytes(pathInfo?.totalSize || 0)}
        </div>

        <div className="fr">
          검색결과 : 파일 {comma(dirPathInfo?.totalFileCount || 0)}개
          / 사용용량 : {formatBytes(dirPathInfo?.totalSize || 0)}
        </div>
      </footer>

      { openModal.open && openModal.modal === 'scan' &&
        <Dialog
          open={openModal}
          onClose={() => (event, reason) => {
            if(reason && reason === "backdropClick") return;
            setOpenModal({open: false, modal: openModal.modal, data: {}});
          }}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "550px",  // Set your width here
              },
            },
          }}
        >
          <AlertDialog
            open={openModal.open}
            width="550px"
            close={() => setOpenModal({ open: false, modalName: openModal.modal, data: openModal.data })}
            title="경로 분석"
            message={
              <div>
                <div>해당 프로젝트의 디렉토리 및 파일을 상세 분석 하시겠습니까?</div>
                <div className="mt10">이 작업은 오랜시간이 걸릴 수 있습니다.</div>
              </div>
            }
            confirm="분석시작"
            color="primary"
            onClick={() => pathScanBackground()}
          />
        </Dialog>
      }

      { openModal.open && openModal.modal === 'thumb' &&
        <Dialog
          open={openModal}
          onClose={() => (event, reason) => {
            if(reason && reason === "backdropClick") return;
            setOpenModal({open: false, modal: openModal.modal, data: {}});
          }}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "550px",  // Set your width here
              },
            },
          }}
        >
          <AlertDialog
            open={openModal.open}
            width="550px"
            close={() => setOpenModal({ open: false, modalName: openModal.modal, data: openModal.data })}
            title="섬네일 재생성"
            message={
              <div>
                <div>선택된 파일의 섬네일을 재생성 하시겠습니까?</div>
                <div className="mt10">이 작업은 오랜시간이 걸릴 수 있습니다.</div>
              </div>
            }
            confirm="섬네일 재생성"
            color="primary"
            onClick={() => thumbBackground()}
          />
        </Dialog>
      }

      { openModal.open && openModal.modal === 'scanModal' &&
        <Dialog
          open={openModal}
          onClose={() => (event, reason) => {
            if(reason && reason === "backdropClick") return;
            setOpenModal({open: false, modal: openModal.modal, data: {}});
          }}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "1200px",  // Set your width here
              },
            },
          }}
        >
          <DirectoryScanModal
            open={openModal.open}
            data={openModal.data}
            selectedIndex={props.selectedIndex}
            setSelected={props.setSelected}
            setList={props.setList}
            list={props.list}
            getList={getList}
            getDirectoryList={getDirectoryList}
            getFileList={getFileList}
            close={() => setOpenModal({ open: false, modalName: openModal.modal, data: openModal.data })}
          />
        </Dialog>
      }

      { openModal.open && openModal.modal === 'excelUpload' &&
        <Modal
          open={openModal.open}
          onClose={() => setOpenModal({ open: false, modalName: openModal.modal, data: openModal.data })}
        >
          <ExcelModal
            open={openModal.open}
            close={() => setOpenModal({ open: false, modalName: openModal.modal, data: openModal.data })}
          />
        </Modal>
      }

      { openModal.open && openModal.modal === 'dialog' &&
        <Modal
          open={openModal.open}
          onClose={() => setOpenModal({ open: false, modalName: 'dialog', data: openModal.data })}
        >
          <AlertDialog
            open={openModal.open}
            close={() => setOpenModal({ open: false, modalName: 'dialog', data: openModal.data })}
            title="파일 삭제"
            message={`선택하신 ${openModal.data.length}개의 "파일"를 삭제하시겠습니까?`}
            confirm="삭제"
            onClick={deleteFileList}
          />
        </Modal>
      }

      { openModal.open && openModal.modal === 'fileNotUpload' &&
        <Modal
          open={openModal.open}
          onClose={() => setOpenModal({ open: false, modalName: 'fileNotUpload', data: openModal.data })}
        >
          <AlertDialog
            open={openModal.open}
            close={() => setOpenModal({ open: false, modalName: 'fileNotUpload', data: openModal.data })}
            title="파일 없음"
            message={
              <div>
                <div>
                  해당 프로젝트를 파일 업로드 없이 완료 하시겠습니까?
                </div>
                <div className="mt20 cred bold">
                  주의! 이미 등록된 파일이 있을 경우 모두 삭제 처리됩니다.
                </div>
              </div>
            }
            confirm="파일없음"
            onClick={setFileNotUpload}
          />
        </Modal>
      }

      { openModal.open && openModal.modal === 'complete' &&
        <Modal
          open={openModal.open}
          onClose={() => setOpenModal({ open: false, modal: openModal.modal, data: openModal.data })}
        >
          <AlertDialog
            open={openModal.open}
            close={() => setOpenModal({ open: false, modal: openModal.modal, data: openModal.data })}
            title="파일 등록완료 처리"
            message={
              <div>
                <div>
                  해당 프로젝트의 파일을 등록완료 처리하시겠습니까?
                </div>
              </div>
            }
            color="primary"
            confirm="등록완료"
            onClick={setFileComplte}
          />
        </Modal>
      }

      { openModal.open && openModal.modal === 'fileAdd' &&
        <Dialog
          open={openModal}
          onClose={() => (event, reason) => {
            if(reason && reason === "backdropClick") return;
            setOpenModal({open: false, modal: openModal.modal, data: {}});
          }}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "1200px",  // Set your width here
              },
            },
          }}
        >
          <FileModal
            open={openModal.open}
            data={openModal.data}
            selected={props.selected}
            selectedNode={selectedNode}
            fileList={fileList}
            setFileList={setFileList}
            getDirectoryList={getDirectoryList}
            getFileList={getFileList}
            getList={getList}
            close={() => setOpenModal({ open: false, modalName: openModal.modal, data: openModal.data })}
          />
        </Dialog>
      }

      { openModal.open && openModal.modal === 'error' &&
        <Dialog
          open={openModal}
          onClose={() => (event, reason) => {
            if(reason && reason === "backdropClick") return;
            setOpenModal({open: false, modal: openModal.modal, data: {}});
          }}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "1200px",  // Set your width here
                height: '80vh'
              },
            },
          }}
        >
          <ProjectBatchErrorModal
            open={openModal.open}
            data={openModal.data}
            close={() => setOpenModal({ open: false, modalName: openModal.modal, data: openModal.data })}
          />
        </Dialog>
      }

    </section>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default TabFile;