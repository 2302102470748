/*****************************************************************************************
 * 설명 : 프로젝트관리 - 주관부서 변경이력 조회
 * URI : /project
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import React, { useEffect }  from 'react';
import Button from '@mui/material/Button';
import { useFormik } from 'formik';
import * as Yup from "yup";

import Restful from "service/restful";
import {setRequestApplicationProjectApi} from 'service/request';

import alertMsg from 'components/message';
import { InputEx } from 'components/inputEx';

import { MESSAGE_DELAY } from 'config/config';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const RequestAddInfoModal = (props) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [getApi] = Restful();

  const formik = useFormik({
    initialValues: {
      departmentNameLast: '',
      reason: ''
    },
    validationSchema: Yup.object().shape({
      reason: Yup.string().max(200, "200자리").required('필수').nullable(),
    }),
    onSubmit: (values) => {
      let params = {
        projectNumber: props.formik.values.projectNumber,
        reason : values.reason,
        isApplicationView: 3
      }

      setRequestApplicationProjectApi(getApi, params, (response) => {
        if( response !== undefined && response.data.result ) {
          alertMsg(response.data.message, "S", MESSAGE_DELAY);

          if( props.formik ) {
            props.formik.setFieldValue('isApplicationView', 3);
          }

          props.close();

        } else {
          alertMsg(response.data.message || '서버와의 통신에 실패하였습니다.', "E", MESSAGE_DELAY);
        }
      });
    }
  });

  /***************************************************************************************
   * 설명 : 팝업 시 처리
  ***************************************************************************************/
  useEffect(() => {
    if( props.open ) {

    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <section className="modal-wrap" style={{width: '100%'}}>
      <form onSubmit={formik.handleSubmit}>
        <header className="modal-header" style={{ cursor: 'move' }} id="draggable-dialog-title">
          <div className="modal-title">프로젝트 추가정보 열람신청</div>
          <Button className="close-btn" onClick={props.close}>×</Button>
        </header>

        <section className="modal-body">
          <div className="clearfix"></div>

          <div>
            <table className="input-table">
              <colgroup>
                <col style={{width: '20%'}} />
                <col style={{width: '80%'}} />
              </colgroup>

              <tbody>
                <tr>
                  <th scope="row"><label htmlfor="reason">사유입력</label></th>
                  <td>
                    <InputEx
                      name="reason"
                      formik={formik}
                      fullWidth={true}
                      autoFocus={true}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>

        <footer className="modal-footer">
          <div className="modal-footer-center">
            <Button
              color="inherit"
              variant="outlined"
              className="Btn"
              onClick={() => {
                props.close();
              }}
            >창닫기</Button>

            <Button
              type="submit"
              color="primary"
              variant="outlined"
              className="Btn ml10"
            >열람신청</Button>
          </div>
        </footer>
      </form>
    </section>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default RequestAddInfoModal;