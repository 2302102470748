/*****************************************************************************************
 * 설명 : 전역 앱 설정
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer } from 'react-toastify';

import IndicatorContext from 'config/indicator';
import GlobalMenu from 'config/menu';
import { setUserInfo } from 'reducers/userReducer';

import { getAuthMenuApi } from 'service/cms';
import { authenticateApi } from 'service/member';
import { getScanResultApi } from 'service/project';
import Restful from "service/restful";
import { getRelationShipListApi } from 'service/search';

import { setMenuCurrent, setMenuInfo, setMenuOrigin } from 'reducers/menuListReducer';

import Indicator from 'components/indicator';
import alertMsg from 'components/message';

import SiteRouter from 'pages/siteRouter';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import 'react-toastify/dist/ReactToastify.css';

import 'assets/css/common.scss';
import 'assets/css/default.css';
import 'assets/css/layout.scss';
import 'assets/css/pages.scss';
import 'assets/css/toastify.css';


/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const App = (props) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [message, setMessage] = useState(null);

  const theme = createTheme({
    palette: {
      primary: {
        main: '#4c38b3',
      },
      secondary: {
        main: '#3577f1',
      },
      error: {
        main: '#f06548',
      }
    },
  });

  const location = useLocation();

  const [isIndicator, setIndicator] = useState(false);
  const indicator = { isIndicator, setIndicator };

  const history = useNavigate();

  /***************************************************************************************
   * * 설명 : 메뉴 리스트
  ***************************************************************************************/
  const dispatch = useDispatch();
  const [getApi] = Restful();

  const user = useSelector(state => state?.userInfo?.user);

  const [menuList, setMenuList] = useState([]);
  const [menuListOrigin, setMenuListOrigin] = useState([]);
  const [menuIndex, setMenuIndex] = useState(-1);
  const [menuReduce, setMenuReduce] = useState('');

  const [isCheck, setIsCheck] = useState(false);

  const menuOriginList = useSelector(state => state?.menuList?.list);

  const valueMenu = {menuList, setMenuList, menuIndex, setMenuIndex, menuReduce, setMenuReduce };

  const uri = window.location.pathname;

  /***************************************************************************************
   * 설명 : 트리 메뉴 구성 시 부모 트리 찾기
  ***************************************************************************************/
  const findMenuTree = (menuList, menu, menuOrder) => {
    var result;
    menuList.some(o => result = o.siteSeq === menu.siteSeq && o.menuOrder === menuOrder ? o : findMenuTree(o.children || [], menu, menuOrder));
    return result;
  }

  /***************************************************************************************
   * 설명 : 트리 메뉴 구성
  ***************************************************************************************/
  const setMenuTree = (menu, index) => {
    let menuList = [];

    for(let i = index; i < menu.length; i++ ) {
      let isActive = menu[i].linkUrl === uri ? true : false;

      if( menu[i].menuOrder.length === 3 ) {
        menuList.push({
          ...menu[i],
          isActive: isActive,
          children: [],
          parent: null
        });

        if( isActive )
          dispatch(setMenuCurrent({
            ...menu[i],
            parent: {
              seq: 0,
              menuName: 'HOME'
            }
          }));

      } else {
        let node = findMenuTree(menuList, menu[i], menu[i].menuOrder.substr(0, menu[i].menuOrder.length - 3));
        if( node === undefined ) {
        } else {
          let isActive = menu[i].linkUrl === uri ? true : false;

          if( node.children === undefined ) node.children = [];

          node?.children.push({
            ...menu[i],
            isActive: isActive,
            children: [],
            parent: {
              seq: node.seq,
              menuName: node.menuName
            }
          });

          if( isActive ) dispatch(setMenuCurrent({
            ...menu[i],
            parent: {
              seq: node.seq,
              menuName: node.menuName
            }
          }));

        }
      }
    }

    return menuList;
  }

  /***************************************************************************************
   * 설명 : 연관 단어 리스트 가져오기
  ***************************************************************************************/
  const getRelationShipList = () => {
    let check = window.localStorage.getItem('relationWord');
    let isCheck = false;

    if( ! check ) isCheck = true;
    else {
      const item = JSON.parse(check);
      const now = new Date();
      if( now.getTime() > item.expire ) {
        window.localStorage.removeItem('relationWord');
        isCheck = true
      }
    }

    // 1일에 한번만 데이터 가져와서 연관단어 처리
    if( isCheck ) {
      getRelationShipListApi(getApi, (response) => {
        if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
          const now = new Date();

          window.localStorage.setItem('relationWord', JSON.stringify({
            value: response.data.data,
            expire: now.getTime() + (24 * 60 * 60)
          }));
        }
      });
    }
  }

  /***************************************************************************************
   * 설명 : 서버에서 사이트(메뉴) 정보 가져오기
  ***************************************************************************************/
  const getSiteInfo = () => {
    // 첫번째 uri 보냄 사이트 구분자
    let paramsUri = uri.split(/\//gi);

    if( paramsUri[1].indexOf('admin') !== 0 && paramsUri[1].indexOf('user') !== 0  )
      paramsUri = '/';
    else
      paramsUri = paramsUri[1];

    let params = {
      domain: window.location.hostname,
      uri: paramsUri
    }

    getAuthMenuApi(getApi, params, (response) => {
      if( response.data.result ) {

        // 로그인 정보 저장
        /*
        if( response.data.loginCheck && response.data.member.length > 0 )
          dispatch(setUserInfo(response.data.member[0]));

        // 사이트 정보 저장
        if( response.data.siteInfo && response.data.siteInfo.length > 0 )
          dispatch(setSiteInfo(response.data.siteInfo[0]));
        */
        // 메뉴 리스트 정보 저장
        if( response.data.data && response.data.data.length > 0 ) {
          setMenuListOrigin(response.data.data);

          // 접속 가능한 메뉴 확인
          let tmp1 = response.data.data.filter((item) => item.linkUrl === window.location.pathname);
          if( tmp1.length < 1 ) {
            if( response.data.data.length > 0 ) {
              let tmp2 = response.data.data.filter((item) => item.linkUrl !== '');
              if(tmp2.length > 0 ) {
                history(tmp2[0].linkUrl);

              } else if( window.location.pathname !== '/login' )
                history("/login");
            } else if( window.location.pathname !== '/login' ) {
              history("/login");
            }

          } else {
            setIsCheck(true);

            // 메뉴 트리 구성
            const tmp = setMenuTree(response.data.data, 0);
            setMenuList(tmp);

            // 트리 메뉴 저장
            dispatch(setMenuInfo(tmp));

            // 리스트 메뉴 저장
            dispatch(setMenuOrigin(response.data.data));
          }
        }

        // 페이지 접근 권한 체크 필요
      }
    })
  }

  /***************************************************************************************
   * 설명 : 알림 메시지 갱신 처리
  ***************************************************************************************/
  useEffect(() => {

    // 관리자 페이지에서만
    if( user.isAdmin === 1 && window.location.pathname?.indexOf('/admin') > -1 ) {

      if( message === null ) {
        // 일정(30초) 시간동안 계속 실행
        let tmp = setInterval(() => {
          let tmp = window.localStorage.getItem('da_scan');
          let list = JSON.parse(tmp);

          // 일괄등록 리스트가 존재할 경우
          if( list !== null && Array.isArray(list) && list?.length > 0 ) {

            // 메시지 표시가 안된 내역이 있는지 확인
            let doneComplete = list.filter((item) => item.isCheck === false);

            // 메시지 표시가 안된 내역이 있을 경우
            if( doneComplete.length > 0 ) {
              doneComplete.forEach((item, index) => {
                let params = {
                  seq: item.seq,
                  memNo: item.memNo
                }

                // 서버에서 일괄 처리가 완료 됐는지 확인
                getScanResultApi(getApi, params, (response) => {
                  if( response !== undefined && response.data.result ) {
                    if( response.data.data && (response.data.data?.status === 1 || response.data.data?.status === 2 ) ) {
                      if( parseInt(response.data.data.status) === 2 ) {
                        // 성공 메시지
                        alertMsg(response.data.data?.message, "S", 30000);

                      } else {
                        // 실패 메시지
                        alertMsg(response.data.data?.message, "E", 30000);
                      }

                      // 성공이든 실패든 완료 처리
                      doneComplete[index].isCheck = true;
                      window.localStorage.setItem('da_scan', JSON.stringify(doneComplete));
                    }
                  }
                });
              });
            }
          }

          console.log('interval message')
        }, 30 * 1000);

        setMessage(tmp);
      }
    }

    return () => {
      if( user.isAdmin === 1 && window.location.pathname?.indexOf('/admin') > -1 && message ) {
        clearInterval(message);
      }
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  /***************************************************************************************
   * * 설명 : 페이지 변경 시 로그인 정보 가져오기
  ***************************************************************************************/
  useEffect(() => {
    authenticateApi(getApi, (response) => {
      // 로그인 계정 가져오기
      if( response !== undefined && response.data.result ) {
        dispatch(setUserInfo(response.data.data));

        // 사용자 페이지로 이동 처리해야 함

      } else {
        setIsCheck(true);

        if( window.location.pathname.indexOf('/pms/sso') < 0 ) {
          // 로그인 페이지를 제외한 다른 페이지의 경우는 로그인 페이지로 이동
          if( window.location.pathname.indexOf("/login") > -1 ) {
            dispatch(setUserInfo({}));
          } else {
            history("/login");
          }
        }
      }
    }, (error) => {
      if( window.location.pathname.indexOf('/pms/sso') < 0 ) {
        setIsCheck(true);
        history("/login");
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location]);

  /***************************************************************************************
   * * 설명 : 주소 변경 시 메뉴 정보 가져오기
  ***************************************************************************************/
  useEffect(() => {
    if( menuOriginList && menuOriginList.length > 0  ) {
      menuOriginList.forEach((menu) => {
        // 현재 페이지 정보 저장
        if( menu.isRouter !== 0 && menu.isRouter !== '0' ) {
          if( menu.linkUrl === window.location.pathname ) {
            dispatch(setMenuCurrent(menu));
          }
        }
      });
    }

    // 연관단어 가져오기
    getRelationShipList();

    getSiteInfo();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  /***************************************************************************************
   * * 설명 : 이미 로그인 되어 있는 경우 해당 페이지로 이동 처리
  ***************************************************************************************/
  useEffect(() => {
    if( (user.memNo ?? '' ) !== '' && menuListOrigin.length > 0 ) {

      if( window.location.pathname === '/' || window.location.pathname.indexOf('/login') > -1 ) {
        history(menuListOrigin[0].linkUrl);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, menuListOrigin])

  /***************************************************************************************
   * * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <>
      <ToastContainer
        limit={10}
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        draggable
        pauseOnHover
      />
      <Indicator checked={isIndicator} />

      <IndicatorContext.Provider value={indicator}>
        <ThemeProvider theme={theme}>
          <GlobalMenu.Provider value={valueMenu}>
            {( isCheck || ['/', '', '/login'].includes(window.location.pathname) ) &&
              <SiteRouter
                indicator={indicator}
                history={history}
              />
            }
          </GlobalMenu.Provider>
        </ThemeProvider>
      </IndicatorContext.Provider>
    </>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default App;
