/*****************************************************************************************
 * 설명 : 어플리케이션 설정
 *****************************************************************************************/
export const version = '1.0.15'

export const baseImgUrl = (process.env.NODE_ENV !== 'production')
                          ? 'http://localhost:8080/api'
                          : ( process.env.ssl ) ? 'https://archivedev.dagroup.kr/api' : 'http://archivedev.dagroup.co.kr/api';

// 기본 접속 주소
export const baseHost = (process.env.NODE_ENV !== 'production') ? window.location.protocol + '//' + window.location.hostname + ':8080' : window.location.origin;
export const baseURL = (process.env.NODE_ENV !== 'production') ? baseHost : baseHost;

// 행정정보 공동이용 서비스 주소
export const discountURL = baseURL;

export const PAGEROW = 100;

export const MESSAGE_DELAY = 3000;
export const COOKIE_NAME = 'DAGROUP_ARCHIVE';

export const IMAGE_FILE = ['png', 'gif', 'jpeg', 'jpg', 'bmp'];
export const MOVIE_FILE = ['mp4', 'mov', 'avi', 'wmv'];
export const MOVIE_FILE_STREAM = ['mp4'];
export const DOWNLOAD_FILE = ['doc', 'docx', 'pdf', 'xls', 'xlsx', 'hwp', 'hwpx', 'ppt', 'pptx', 'png', 'gif', 'jpeg', 'jpg', 'bmp', 'pdf'];