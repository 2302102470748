/*****************************************************************************************
 * 설명 : 프로젝트관리 - 파일관리 - 추가
 * URI : /project
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import Button from '@mui/material/Button';
import { AgGridReact } from 'ag-grid-react';
import axios from 'axios';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from "yup";

import { MESSAGE_DELAY, baseURL } from 'config/config';
import useGridConfig from 'hooks/useGridConfig';

import ChipEx from 'components/chipEx';
import { InputEx } from 'components/inputEx';
import alertMsg from 'components/message';
import customTooltipAgGrid from 'components/tooltipAgGrid';

import { setProjectDirectoryScanCompleteApi } from 'service/project';
import Restful from "service/restful";
import { comma, formatBytes } from 'service/utils';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const FileModal = (props) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [getApi] = Restful();

  const [list, setList] = useState([]);

  const [gridApi, setGridApi] = useState({});

  const [isComplete, setIsComplete] = useState(false);
  const [isUpload, setIsUpload] = useState(false);

  const [progress, setProgress] = useState(0);
  const [status, setStatus] = useState({
    fileName: '',
    fileSize: 0
  })

  /***************************************************************************************
   * 설명 : 업체 검색
  ***************************************************************************************/
  const formik = useFormik({
    initialValues: {
      fileName: '',
      realPath: '',
      path: '',
    },
    validationSchema: Yup.object().shape({
      path: Yup.string().max(250, "250자리"),
    }),
    onSubmit: (values) => {
    }
  });

  // table column
  const [columnDefs, setColumnDefs] = useState([
    { headerName: '', field: 'seq', width: 40, cellClass: 'center', flex: 0,
      headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
    { headerName: '번호', field: 'rowIndex', width: 45, cellClass: 'cp text-right',
      valueGetter: (params) => {
        return comma(params.node.rowIndex + 1);
      }
    },
    { headerName:'진행상태', field: 'status', width: 100, cellClass: 'cp center',
      cellRendererFramework: function (params) {
        if( ( params.data.status || '' ) === '' )
          return <ChipEx color="default" variant="outlined" size="small" label="대기중" />;
        else if( ( params.data.status || '' ) === 1 )
          return <ChipEx color="success" variant="outlined" size="small" label="진행완료" />;
        else if( ( params.data.status || '' ) === 2 )
          return <ChipEx color="error" variant="outlined" size="small" label="처리실패" />;
        else
          return '';
      }
    },
    { headerName: '파일명', field: 'fileName', width: 200, cellClass: "cp" },
    { headerName:'용량', field: 'fileSize', width: 100, cellClass: 'cp text-right',
      valueGetter: (params) => {
        return formatBytes(params.data.fileSize, 2);
      }
    },
    { headerName: '태그', field: 'tag', width: 200, cellClass: "cp", editable: true },
    { headerName: '연관프로젝트', field: 'relationProject', width: 200, cellClass: "cp", editable: true },
    { headerName: '메모', field: 'memo', width: 200, cellClass: "cp", editable: true },
    { headerName: '등록일시', field: 'createDate', width: 150, cellClass: 'cp center' },
  ]);

  const [gridConfig] = useGridConfig(99, setColumnDefs);

  /*******************************************************************************
    설  명 : 파일 업로드 시 처리
  *******************************************************************************/
  const handleChange = (e, item) => {
    if( e.target.files !== undefined && e.target.files.length > 0 ) {
      const files = e.target.files[0];

      if( files ) {
        uploadFile(files, item);
      }
    }
  };

  /*******************************************************************************
    설  명 : 파일 서버 업로드
  *******************************************************************************/
  const uploadFile = async(file, item) => {
    const formData = new FormData();

    let realPath = (props.selectedNode.realPath || '/archive_upload/' + props.selected?.projectNumber);
    formData.append('file', file);
    formData.append('projectNumber', props.selected?.projectNumber);
    formData.append('realPath', realPath);
    formData.append('path', formik.values.path);

    axios.post(baseURL + '/api/v1/file/upload', formData, {
      headers: {
        'content-type': 'multipart/form-data',
        'enctype': "multipart/form-data",
        'Access-Control-Allow-Origin': 'http://localhost:3000'
      }
    }).then(response => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {

        let tmp1 = list.filter((item) => {
          let tmp2 = response.data.data.filter((subItem) => subItem.realPath === item.realPath );
          if( tmp2.length > 0 )
            return true;
          else
            return false;
        });

        if( tmp1.length < 1 ) {
          let tmp = [
            ...list,
            ...response.data.data
          ];

          setList(tmp);
        } else {
          alertMsg("중복된 파일을 업로드 하셨습니다.", "W", MESSAGE_DELAY);
          // 서버에서 중복 업로드 된 파일 삭제 처리

        }

      } else {
        alertMsg("파일을 업로드는 하는데 실패하였습니다.", "W", MESSAGE_DELAY);
      }

    }).catch((error) => {
      alertMsg("파일을 업로드는 하는데 실패하였습니다.", "W", MESSAGE_DELAY);
    })
  }

  /***************************************************************************************
   * 설명 : 스캔된 데이터 일괄 처리
  ***************************************************************************************/
  const setScanProcess = async () => {
    let nodes = [];
    gridApi.forEachNode((item) => {
      nodes.push(item.data);
    });

    let index = 0;

    for await (const params of nodes ) {
      await setStatus(() => {
        return {
          type: params.type,
          fileName: params.title,
          fileSize: params.fileSize
        }
      });

      await setProgress(Math.round((index + 1) / list.length * 100));

      // eslint-disable-next-line no-loop-func
      await getApi("post", '/api/v1/project/directory/scan/save', params).then(async (response) => {
        if (response !== undefined && response.data.result) {
          params.status = 1;
          await gridApi.applyTransaction({update: [params]});
          await gridApi.ensureIndexVisible(index, "bottom");
        } else {
          params.status = 2;
          gridApi.applyTransaction({ update: [params] });
          gridApi.ensureIndexVisible(index, "bottom");
        }

      // eslint-disable-next-line no-loop-func
      }).catch((error) => {
        params.status = 2;
        gridApi.applyTransaction({ update: [params] });
        gridApi.ensureIndexVisible(index, "bottom");
      });

      index++;
    }

    setIsComplete(true);

  }

  /***************************************************************************************
   * 설명 : 파일업로드 처리
  ***************************************************************************************/
  const onClick = async () => {
    await setScanProcess();
  }

  /***************************************************************************************
   * 설명 : 일괄 등록 완료 처리
  ***************************************************************************************/
  useEffect(() => {
    if( isComplete ) {
      setIsComplete(false);
      setIsUpload(true);

      let directoryCount = 0;
      let fileCount = 0;
      let totalSize = 0;

      list.forEach((item) => {
        if( item.type === 0 ) directoryCount++;
        else if( item.type === 1 ) fileCount++;

        totalSize = totalSize + item.fileSize;
      })

      let params = {
        projectNumber: list[0]?.projectNumber,
        fileCount: fileCount,
        directoryCount: directoryCount,
        totalSize: totalSize,
        isInsert: true
      }

      // 완료 처리
      setProjectDirectoryScanCompleteApi(getApi, params, (response) => {
        if( response !== undefined && response.data.result ) {
          alertMsg("개별 파일 추가를 완료하였습니다.", "S", MESSAGE_DELAY);

          // 배치 등록 리스트 갱신
          if( props.getList ) props.getList();

          // 업데이트
          props.setFileList([
            ...props.fileList,
            ...list
          ]);

          // 파일 등록 리스트 갱신
          if( props.getFileList ) props.getFileList();

          // 디렉토리 리스트 갱신
          if( props.getDirectoryList ) props.getDirectoryList();

        } else {
          alertMsg(response.data.message || '서버와의 통신에 실패하였습니다.', "E", MESSAGE_DELAY);
        }
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isComplete]);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  useEffect(() => {
    if( props.open && props.selectedNode ) {
      let path = (props.selectedNode.path || '') === '' ? '/' : props.selectedNode?.path;
      formik.setFieldValue("path", path);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <section className="modal">
      <section className="modal-wrap" style={{width: '100%'}}>
        <header className="modal-header" style={{ cursor: 'move' }} id="draggable-dialog-title">
          <div className="modal-title">파일 추가</div>
          <Button className="close-btn" onClick={props.close}>×</Button>
        </header>

        <section className="modal-body">
          <form onSubmit={formik.handleSubmit}>
            <div className="mb10">
              <table className="input-table">
                <colgroup>
                  <col style={{width: '20%'}} />
                  <col style={{width: '80%'}} />
                </colgroup>

                <tbody>
                  <tr>
                    <th scope="row"><label htmlFor="path">디렉토리 경로</label></th>
                    <td>
                      <InputEx
                        name="path"
                        formik={formik}
                        fullWidth={true}
                        disabled={true}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row"><label htmlFor="fileName">파일추가</label></th>
                    <td>
                      <InputEx
                        type="file"
                        name="fileName"
                        formik={formik}
                        focus={true}
                        fullWidth={true}
                        onChange={handleChange}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </form>

          <div className="clearfix"></div>

          <div className="ag-theme-balham" style={{height: '400px'}}>
            <AgGridReact
              defaultColDef={{
                sortable: true,
                resizable: true,
                filter: false,
                lockVisible: true,
                tooltipComponent: customTooltipAgGrid,
              }}
              tooltipShowDelay={0}
              tooltipHideDelay={2000}
              columnDefs={columnDefs}
              rowData={list}
              rowSelection={'single'}
              onGridReady={(event) => {
                gridConfig(event.columnApi);
                setGridApi(event.api);
              }}
              onRowClicked={(event) => {
              }}
              onRowDoubleClicked={(event) => {
              }}
              overlayNoRowsTemplate = "검색된 내용이 없습니다."
            />
          </div>

          <section className="progress-wrap mt20">
            <div className="progress-title clearfix">
              <div className="fl">
                {status?.fileName}
                {status?.type === 1 &&
                  <span> <span className="path f11">({formatBytes(status?.fileSize)})</span></span>
                }
              </div>
              <div className="fr">{progress}%</div>
            </div>
            <div className="progress mt10" style={{height: '20px'}}>
              <div className={"progress-bar bg-green"} style={{ width: progress + '%', height: '20px' }}></div>
            </div>
          </section>
        </section>

        <footer className="modal-footer">
          <div className="modal-footer-center">
            <Button
              color="inherit"
              variant="outlined"
              className="Btn"
              onClick={() => {
                props.close();
              }}
            >창닫기</Button>

            <Button
              variant="contained"
              color="primary"
              className="Btn ml10"
              onClick={onClick}
              disabled={isUpload}
            >업로드</Button>
          </div>
        </footer>
      </section>
    </section>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default FileModal;