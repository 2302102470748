/*****************************************************************************************
 * 설명 : 관리자 > 컨텐츠관리 > 컨텐츠관리
 * URI : /admin/cms/menuList
*****************************************************************************************/
import { useFormik } from 'formik';
import $ from 'jquery';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import SortableTree from 'react-sortable-tree';
import FileExplorerTheme from 'react-sortable-tree-theme-file-explorer';
import 'react-sortable-tree/style.css';
import SplitPane from 'react-split-pane';
import * as Yup from "yup";

import { ButtonEx, SelectEx } from 'components/inputEx';

import { getMenuListApi, getSiteInfoApi } from 'service/cms';
import Restful from "service/restful";
import { getSelectData } from 'service/utils';

import Layout from 'pages/layout/layout';
import TabMenuConfig from './tabMenuConfig';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const MenuList = ( props ) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [getApi] = Restful();

  // 탭 클릭 시 처리
  const [tabMenu, setTabMenu] = useState('1');

  const currentMenu = useSelector(state => state?.menuList?.current);

  const [tabIndex, setTabIndex] = useState(0);

  const [selectedNode, setSelectedNode] = useState({});

  const [siteList, setSiteList] = useState([]);
  const [menuList, setMenuList] = useState([]);

  const formik = useFormik({
    initialValues: {
      siteSeq: '',
    },
    validationSchema: Yup.object().shape({
    }),
    onSubmit: (values) => {
    }
  });

  /***************************************************************************************
   * 설명 : 사이트 리스트 가져오기
  ***************************************************************************************/
  const getSiteList = () => {
    getSiteInfoApi(getApi, (response) => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        setSiteList(getSelectData(response.data.data, 'siteName', 'seq'));
      } else {
        setSiteList([]);
      }
    });
  }

  /***************************************************************************************
   * 설명 : 사이트 리스트 가져오기
  ***************************************************************************************
  const getModuleList = () => {
    getModuleInfoApi(getApi, formik.values, (response) => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        setModuleList(getSelectData(response.data.data, 'moduleName', 'seq'));
      } else {
        setModuleList([]);
      }

    });
  }

  /***************************************************************************************
   * 설명 : 메뉴 리스트 가져오기
  ***************************************************************************************/
  const getMenuList = (siteSeq) => {

    const findNode = (data, id) => {
      // root
      if(id.length === 3) return data;
      for(let i = 0; i < data.length; i++ ) {
        if( data[i]?.menuOrder === id.substr(0, id.length - 3) )
          return data[i];

        if( data[i].children !== undefined ) {
          let result = findNode(data[i].children, id);
          if( result !== false ) return result;
        }
      }

      return false;
    }

    getMenuListApi(getApi,
      {siteSeq: siteSeq}, (response) => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {

        let tmp = [];
        let data = response.data.data;

        data.forEach((item, index) => {
          let parentNode = findNode(tmp, item.menuOrder);

          if( item.menuOrder.length === 3 ) {
            parentNode.push({
              title: item.menuViewName,
              expanded: true,
              ...item
            });

          } else {
            if( parentNode.children === undefined ) parentNode.children = [];
              parentNode.children.push({
                title: item.menuViewName,
                expanded: true,
                ...item
              });
          }
        });

        setMenuList(tmp);

        setSelectedNode({});
      } else {
        setMenuList([]);
      }
    });
  }

  /***************************************************************************************
   * 설명 : 트리 노드 클릭 시 한줄 선택 처리
  ***************************************************************************************/
  const treeRowClick = () => {
    $(".rstcustom__node").each((index, item) => {
      $(item).on('click', (event) => {

        // 기존 선택된 노드 삭제
        $(".admin-cms-contents").find('.tree-selected-parent').each((index, subItem) => {
          $(subItem).removeClass('tree-selected-parent');
        });

        $(event.target).closest('.rstcustom__rowWrapper').addClass('tree-selected-parent');
      });
    })
  }

  /***************************************************************************************
   * 설명 : 트리 노드 클릭 시 처리
  ***************************************************************************************/
  useEffect(() => {
    if( menuList.length > 0 )
      treeRowClick();

    // eslint-disable-next-line
  }, [menuList])

  /***************************************************************************************
   * 설명 : 사이트 변경 시 처리
  ***************************************************************************************/
  useEffect(() => {
    if( formik.values.siteSeq !== '' ) {
      getMenuList(formik.values.siteSeq);
    }

    // eslint-disable-next-line
  }, [formik.values.siteSeq]);

  /***************************************************************************************
   * 설명 : 데이터 처리
  ***************************************************************************************/
  useEffect(() => {
    getSiteList();

    // getModuleList();

    // eslint-disable-next-line
  }, []);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <Layout>
      <section className="admin-cms-contents cms-page">
        <SplitPane split="vertical" minSize={25} defaultSize={400}>
          <div className="mr10">
            <header className="admin-cms-search-header">
              <div>
                <SelectEx
                  name="siteSeq"
                  formik={formik}
                  fullWidth={true}
                  style={{width: '200px'}}
                  data={[
                    {label: '사이트 선택', value: ''}
                  ].concat(siteList)}
                />
              </div>

              <div className="text-right">
                <ButtonEx
                  title="메뉴등록"
                  auth="isWrite"
                  color="primary"
                  className="ml5"
                  variant="contained"
                  onClick={() => {
                    setTabIndex(0);

                    let node = {...selectedNode};
                    node.seq = 0;
                    node.siteSeq = formik.values.siteSeq;
                    setSelectedNode(node);
                  }}
                />
              </div>
            </header>

            <section className="admin-cms-body" style={{border: 'solid 1px #ccc'}}>
              <div style={{ height: 'calc(100vh - 140px)' }} className="p10">
                <SortableTree
                  treeData={menuList}
                  onChange={(treeData) => {
                    setMenuList([...treeData])
                  }}
                  theme={FileExplorerTheme}
                  style={{fontSize: '13px'}}
                  scaffoldBlockPxWidth={22}
                  isVirtualized={false}
                  generateNodeProps={(rowInfo) => {
                    const { node } = rowInfo;
                    return {
                      onClick: (event) => {
                        if( selectedNode?.seq === node.seq ) setSelectedNode({seq: ''});
                        else setSelectedNode(node);
                      },
                      icons: rowInfo.node.children !== undefined && rowInfo.node.children.length > 0
                        ? [
                            <div
                              style={{
                                borderLeft: 'solid 8px gray',
                                borderBottom: 'solid 10px gray',
                                marginRight: 10,
                                boxSizing: 'border-box',
                                width: 16,
                                height: 12,
                                filter: rowInfo.node.expanded
                                  ? 'drop-shadow(1px 0 0 gray) drop-shadow(0 1px 0 gray) drop-shadow(0 -1px 0 gray) drop-shadow(-1px 0 0 gray)'
                                  : 'none',
                                borderColor: rowInfo.node.expanded ? 'white' : 'gray',
                              }}
                            />,
                          ]
                        : [
                            <div
                              style={{
                                borderLeft: 'solid 8px gray',
                                borderBottom: 'solid 10px gray',
                                marginRight: 10,
                                boxSizing: 'border-box',
                                width: 16,
                                height: 12,
                                filter: 'drop-shadow(1px 0 0 gray) drop-shadow(0 1px 0 gray) drop-shadow(0 -1px 0 gray) drop-shadow(-1px 0 0 gray)',
                                borderColor: 'white',
                              }}
                            />,
                          ],
                      className: selectedNode?.seq === (rowInfo.node?.seq || '') ? 'tree-selected' : '',
                    }
                  }}
                />
              </div>

            </section>
          </div>

          <div className="m10">
            <section className="Tab-page">
              <section className="tab-header">
                <ul className="tab-menu menu4 clearfix">
                  <li className={(tabMenu === '1')? "active":""} onClick={()=> setTabMenu('1')}>기본정보 설정</li>
                </ul>
              </section>
              <section className="tab-contents">
              { tabMenu === '1' &&
                <TabMenuConfig
                  tabIndex={tabIndex}
                  data={selectedNode}
                  siteSeq={formik.values.siteSeq}
                  menuList={menuList}
                  getList={() => getMenuList(formik.values.siteSeq)}
                  indicator={props.indicator}
                  history={props.history}
                  currentMenu={currentMenu}
                />
              }
              </section>
            </section>
          </div>
        </SplitPane>
      </section>
    </Layout>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default MenuList;