/*****************************************************************************************
 * 설명 : 설계비가산정
 * URI : /project/costing
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import InputAdornment from '@mui/material/InputAdornment';
import { AgGridReact } from 'ag-grid-react';
import * as Excel from "exceljs/dist/exceljs.min.js";
import { saveAs } from "file-saver";
import { useFormik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import SplitPane from 'react-split-pane';
import * as Yup from "yup";

import AlertDialog from 'components/alertDiolog';
import BtnAgGridSave from 'components/btnAgGridSave';
import ChipEx from 'components/chipEx';
import { BtnRefresh, CurrencyFormat } from 'components/common';
import { ButtonEx, InputEx, SelectEx } from 'components/inputEx';
import alertMsg from 'components/message';
import customTooltipAgGrid from 'components/tooltipAgGrid';

import { getCommonListApi } from 'service/common';
import { getCostPurposeApi } from 'service/cost';
import {
  deleteCostMemberApi,
  deleteCostMemberDetailApi,
  getCostMemberDetailApi,
  getCostMemberListApi,
  getCostMemberYearApi,
  setCostMemberDetailApi
} from 'service/costMember';
import Restful from "service/restful";
import { PaperComponent, comma, getSelectData, onlyNumber, onlyNumberDot } from 'service/utils';

import useGridConfig from 'hooks/useGridConfig';

import { MESSAGE_DELAY } from 'config/config';
import CostExtraModal from './costExtraModal';
import CostMemberModal from './costMemberModal';
import ProjectAddModal from './projectAddModal';
import WorkKindModal from './workKindModal';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const ProjectCosting = ( props ) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const columnDef = [
    { headerName: '', field: 'seq', width: 40, cellClass: 'center', flex: 0, pinned: 'left',
      headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
    { headerName: '정렬', field: 'sortOrder', width: 52, cellClass: 'cp center', pinned: 'left', rowDrag: true, filter: false, editable: false, resize: false },
    { headerName: '번호', field: 'rowIndex', width: 40, cellClass: 'cp text-right', pinned: 'left',
      valueGetter: (params) => {
        return comma(params.node.rowIndex + 1);
      }
    },
    { headerName:'구분', field: 'costMemberSeq', hide: true},
    { headerName:'구분', field: 'costItemSeq', hide: true},
    { headerName:'구분', field: 'type', width: 80, cellClass: 'cp center', pinned: 'left',
      cellRendererFramework: (params) => {
        if( parseInt(params.data.type) === 0 )
          return <ChipEx color="success" variant="outlined" size="small" label="기본" />;
        else if( parseInt(params.data.type) === 1 )
          return <ChipEx color="primary" variant="outlined" size="small" label="외주용역비" />;
        else
          return <ChipEx color="error" variant="outlined" size="small" label="기타" />;
      }
    },
    { headerName:'상세', field: 'title', width: 150, cellClass: 'cp', pinned: 'left' },
    { headerName:'평단가', field: 'amt', width: 90, editable: true, cellClass: 'cp text-right', valueFormatter: CurrencyFormat, pinned: 'left' },
    { headerName:'기준 평단가', field: 'baseAmt', width: 90, cellClass: 'cp text-right', valueFormatter: CurrencyFormat, pinned: 'left' },
    { headerName:'평균 평단가', field: 'avgAmt', width: 90, cellClass: 'cp text-right', valueFormatter: CurrencyFormat, pinned: 'left' },
    { headerName:'비고', field: 'memo', width: 140, cellClass: 'cp', editable: true, pinned: 'left' },
  ]

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [getApi] = Restful();
  const columnDefsRef = useRef(columnDef);

  const [groupList, setGroupList] = useState([]);
  const [list, setList] = useState([]);
  const [projectList, setProjectList] = useState([]);

  const [year, setYear] = useState([]);
  const [purpose, setPurpose] = useState([]);
  const [order, setOrder] = useState([]);

  const [selectedGroup, setSelectedGroup] = useState({});

  const [openModal, setOpenModal] = useState({open: false, modal: 'typeAdd', data: []});

  const [gridApi, setGridApi] = useState({});
  const [gridApiDetail, setGridApiDetail] = useState({});

  const [selectedDetail, setSelectedDetail] = useState([]);

  const initialValuesSearch = {
    startProjectYear: '',
    endProjectYear: '',
    totalSizeStart: '',
    totalSizeEnd: '',
    purpose: '',
    purposeName: '',
    order: '',
    orderTypeName: '',
    orderName: '',
    searchText: '',
  }

  const formikSearch = useFormik({
    initialValues: initialValuesSearch,
    validationSchema: Yup.object().shape({
      searchText: Yup.string().max(30, "30자리"),
      totalSizeStart: Yup.number().typeError('숫자'),
      totalSizeEnd: Yup.number().typeError('숫자'),
    }),
    onSubmit: (values) => {
      getList();
    }
  });

  const initialValues = {
    startProjectYear: '',
    endProjectYear: '',
    totalSizeStart: '',
    totalSizeEnd: '',
    purpose: '',
    purposeName: '',
    order: '',
    orderTypeName: '',
    orderName: '',
    searchText: '',
    directLabourCost: '',
    directCost: '',
    outsourcingCost: '',
    totalIndirectionCostRate: 195,
    margin: '',
    marginRate: 19.8,
    extra: '',
    totalCost: ''
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object().shape({
      searchText: Yup.string().max(30, "30자리"),
      totalSizeStart: Yup.number().typeError('숫자'),
      totalSizeEnd: Yup.number().typeError('숫자'),
    }),
    onSubmit: (values) => {
    }
  });

  // table column
  const [columnDefsGroup, setColumnDefsGroup] = useState([
    { headerName: '', field: 'seq', width: 35, cellClass: 'center', flex: 0,
      headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
    { headerName:'연도', field: 'year', width: 45, cellClass: 'cp center' },
    { headerName:'제목', field: 'title', width: 150, cellClass: 'cp' },
    { headerName:'용도', field: 'purpose', hide: true },
    { headerName:'용도', field: 'purposeName', width: 100, cellClass: 'cp center' },
    { headerName:'발주처', field: 'orderType', hide: true },
    { headerName:'발주처', field: 'orderTypeName', width: 70, cellClass: 'cp center' },
    {headerName: '수정', field: 'modify', cellClass: 'cp center', width: 50,
      cellRendererFramework: function (params) {
        return (
          <ButtonEx
            title="수정"
            auth="isModify"
            color="secondary"
            variant="outlined"
            className="gridBtn"
            onClick={() => setOpenModal({open: true, modal: 'masterAdd', data: params.data})}
          />
        );
      }
    },
    { headerName:'등록일시', field: 'createDate', width: 150, cellClass: 'cp center' }
  ]);

  /*
  // table column
  const [columnDefs, setColumnDefs] = useState([
    { headerName: '', field: 'seq', width: 40, cellClass: 'center', flex: 0, pinned: 'left',
      headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
    { headerName: '번호', field: 'rowIndex', width: 55, cellClass: 'cp text-right', pinned: 'left',
      valueGetter: (params) => {
        return comma(params.node.rowIndex + 1);
      }
    },
    { headerName:'구분', field: 'type', width: 100, cellClass: 'cp center', pinned: 'left',
      cellRendererFramework: (params) => {
        if( parseInt(params.data.type) === 0 )
          return <ChipEx color="success" variant="outlined" size="small" label="기본" />;
        else if( parseInt(params.data.type) === 1 )
          return <ChipEx color="primary" variant="outlined" size="small" label="외주용역비" />;
        else
          return <ChipEx color="error" variant="outlined" size="small" label="기타" />;
      }
    },
    { headerName:'상세', field: 'title', width: 150, cellClass: 'cp', pinned: 'left' },
    { headerName:'기준 평단가', field: 'baseAmt', width: 100, editable: true, cellClass: 'cp text-right', valueFormatter: CurrencyFormat, pinned: 'left' },
    { headerName:'평균 평단가', field: 'avgAmt', width: 100, cellClass: 'cp text-right', valueFormatter: CurrencyFormat, pinned: 'left' },
    { headerName:'비고', field: 'memo', width: 170, cellClass: 'cp', editable: true, pinned: 'left' },
  ]);
  */

  const [gridConfigGroup, setGridUpdateGroup] = useGridConfig(1, setColumnDefsGroup);
  const [gridConfig, setGridUpdate] = useGridConfig(2, columnDefsRef);

  const setAgGridFocus = (gridApi, columnField = 'seq', value) => {
    gridApi.forEachNode((item, index) => {
      if( item.data[columnField] === value ) {
        item.setSelected(true);
        gridApiDetail.ensureIndexVisible(index);
      }
    });
  }


  // 왼쪽영역 축소
  const [costReduce, setCostReduce] = useState('');
  const [costMin, setCostMin] = useState('250');
  const [costMax, setCostMax] = useState('350');

  const setCostReduceClick = () => {
    if (costReduce === '') {
      setCostReduce('reduce');
      setCostMin('10');
      setCostMax('18');
    } else {
      setCostReduce('');
      setCostMin('250');
      setCostMax('350');
    }
  }

  /***************************************************************************************
   * 설명 : 추가된 프로젝트 평균 값 계산
  ***************************************************************************************/
  const calc = (data) => {

    // 프로젝트 번호로 키값 찾기
    let keyField = Object.keys(data[0]).filter((item) => item.indexOf('-') > -1 && item.indexOf('_Check') < 0 );

    // 프로젝트 체크 키값 찾기
    let keyCheckField = Object.keys(data[0]).filter((item) => item.indexOf('_Check') > -1 );

    // 합계항목
    let directLabourCost = 0;
    let directCost = 0;
    let outsourcingCost = 0;
    let totalIndirectionCost = 0;
    let margin = 0;
    let extra = 0;
    let totalCost = 0;

    // 추가된 프로젝트 비용 계산
    let tmp1 = data.map((item) => {
      directLabourCost += (parseInt(item.type) === 0 && item.title === '직접인건비') ? parseInt(item.amt ?? 0) : 0;
      directCost += (item.type === 0 && item.title === '직접경비') ? parseInt(item.amt ?? 0) : 0;
      outsourcingCost += (parseInt(item.type) === 1) ? parseInt(item.amt ?? 0) : 0;
      extra += (parseInt(item.type) === 2) ? parseInt(item.amt ?? 0) : 0;
      totalCost += parseInt(item.amt ?? 0);

      let tmp2 = keyField.map((key) => item[key]);
      let tmp3 = keyCheckField.map((key) => item[key]);

      let total = 0;
      let count = 0;
      tmp2.forEach((subItem, index) => {
        if(parseInt(tmp3[index]) === 1 && parseInt(subItem ?? 0) !== 0 )  {
          total += subItem;
          count++;
        }
      });

      let tmp4 = {
        ...item
      };

      tmp4.avgAmt = Math.round(total / count);

      return tmp4;
    });

    let totalSize = formik.values.totalSize ?? selectedGroup.totalSize;

    // 직접인건비 계산 연면적(평형변환) * 평단가
    let size = parseInt(totalSize ?? 0) * 0.3025;
    directLabourCost = Math.round(size * directLabourCost);
    directCost = Math.round(size * directCost);
    outsourcingCost = Math.round(size * outsourcingCost);
    totalCost = Math.round(size * totalCost);
    extra = Math.round(size * extra);

    totalIndirectionCost = Math.round(directLabourCost * ((formik.values.totalIndirectionCostRate ?? 0) / 100));
    margin = Math.round((parseFloat(formik.values.marginRate ?? 0)) / 100 * totalCost);
    totalCost = totalCost + totalIndirectionCost + margin;

    // 합계 항목
    formik.setFieldValue('directLabourCost', directLabourCost);
    formik.setFieldValue('directCost', directCost);
    formik.setFieldValue('outsourcingCost', outsourcingCost);
    formik.setFieldValue('totalIndirectionCost', totalIndirectionCost);
    formik.setFieldValue('margin', margin);
    formik.setFieldValue('extra', extra);
    formik.setFieldValue('totalCost', totalCost);

    return tmp1;
  }

  /***************************************************************************************
   * 설명 : 컬럼 조정
  ***************************************************************************************/
  const setColumnCheck = (e, params) => {
    params.node.data[params.column.colDef.field] = (e.target.checked === false) ? 0 : 1;

    let nodes = [];
    params.api.forEachNode( (node) => {
      if( e.target.checked ) {
        node.updateData(node.data);
      } else {
        node.updateData(node.data);
      }

      nodes.push(node.data);
    });

    // 데이터 계산
    let tmp = calc(nodes);
    setList(tmp);
  }

  /***************************************************************************************
   * 설명 : 컬럼 전체 선택
  ***************************************************************************************/
  const setColumnAll = (e, params) => {
    let nodes = [];

    params.api.forEachNode( (node) => {
      if( e.target.checked ) {
        node.setDataValue( params.column.colDef.field, 1 );

      } else {
        node.setDataValue( params.column.colDef.field, 0 );
      }

      nodes.push(node.data);
    });

    // 데이터 계산
    let tmp = calc(nodes);
    setList(tmp);
  }

  /***************************************************************************************
   * 설명 : 체크박스 처리
  ***************************************************************************************/
  function agGridCheckbox(params, props) {
    let check = ( params.value === '0' || params.value === null || params.value === 0 ) ? false : true;
    const className = ( props.className !== undefined ) ? 'Checkbox ' + props.className : 'Checkbox';

    return (
      <Checkbox
        className={className}
        onChange={(e) => {setColumnCheck(e, params)}}
        checked={check}
        size="small"
        color="primary"
      />
    )
  }

  /***************************************************************************************
   * 설명 : 헤더 체크
  ***************************************************************************************/
  function agGridHeaderCheckbox(params, props) {
    const className = ( props.className !== undefined ) ? 'Checkbox ' + props.className : 'Checkbox';

    return (
      <>
        <Checkbox
          className={className}
          onChange={(e) => {setColumnAll(e, params)}}
          color="primary"
        />
        <span>{params.displayName}</span>
      </>
    )
  }

  /***************************************************************************************
   * 설명 : 컬럼 변경 처리
  ***************************************************************************************/
  const addColumn = (project) => {
    columnDefsRef.current = columnDef;

    project.forEach((item) => {
      columnDefsRef.current.push(
        { headerName: '', field: item.projectNumber + '_Check', width: 40, cellClass: 'cp p0',
          headerComponentFramework: (params) => agGridHeaderCheckbox(params, props),
          cellRendererFramework: (params) => agGridCheckbox(params, props)
        }
      );
      columnDefsRef.current.push(
        { headerName: item.projectName, field: item.projectNumber, width: 100, cellClass: 'cp text-right', valueFormatter: CurrencyFormat,
          cellClassRules: {
            'aggrid-cell-max': (params) => {
              const key = params.columnApi.columnModel.columnDefs?.filter((item) => {
                return ( item.field.indexOf('_Check') > -1 ) ? true : false;
              });
              const tmp = key.map((item) => item.field.replace('_Check', ''));
              const keys = [...new Set(tmp)];

              if( keys.length > 2 ) {
                // 0을 제외한 프로젝트 갯수가 3개이상일 경우만 판단
                let projectCount = keys.filter((item) => parseInt(params.data[item] ?? 0) > 0);

                if( projectCount.length > 2 ) {
                  let tmp = keys.map((item) => params.data[item] ?? 0);
                  let tmp1 = Math.max(...tmp);

                  if( params.value !== 0 && parseInt(tmp1) === parseInt(params.value ?? 0) ) {
                    return true;
                  }
                }
              }

              return false;
            },
            'aggrid-cell-min': (params) => {
              const key = params.columnApi.columnModel.columnDefs?.filter((item) => {
                return ( item.field.indexOf('_Check') > -1 ) ? true : false;
              });
              const tmp = key.map((item) => item.field.replace('_Check', ''));
              const keys = [...new Set(tmp)];

              if( keys.length > 2 ) {
                // 0을 제외한 프로젝트 갯수가 3개이상일 경우만 판단
                let projectCount = keys.filter((item) => parseInt(params.data[item] ?? 0) > 0);

                if( projectCount.length > 2 ) {
                  let tmp = keys.map((item) => params.data[item] ?? 0);
                  let tmp2 = tmp.filter((item) => item > 0 );
                  let tmp1 = Math.min(...tmp2);

                  if( params.value !== 0 && parseInt(tmp1) === parseInt(params.value ?? 0) ) {
                    return true;
                  }
                }
              }

              return false;
            }
          }
        }
      );
    });
  }

  /***************************************************************************************
   * 설명 : 엑셀 헤더
  ***************************************************************************************/
  const excelHeader = (sheet, title) => {
    // 상단 타이틀 추가
    const titleCell = sheet.addRow([title]);

    sheet.mergeCells('A1:H1');

    titleCell.height = 45;

    // 상단 타이틀 추가
    titleCell.eachCell((cell, colNum) => {
      cell.font = {
        bold: true,
        name: "Arial",
        size: 20,
        color: { argb: "ff000000" },
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      };
    });
  }

  /***************************************************************************************
   * 설명 : 엑셀 헤더 정보 영역
  ***************************************************************************************/
  const excelHeaderContents = (sheet) => {
    const style = (cell, colNum, count) => {
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      };

      cell.border = {
        top: { style: "thin", color: { argb: "-100000f" } },
        bottom: { style: "thin", color: { argb: "-100000f" } },
        right: { style: "thin", color: { argb: "-100000f" } },
      };

      if( count === 4 ) {
        if( [1, 2, 5, 6].includes(colNum) ) {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "ffebebeb" },
          };
        }
      } else {
        if( [1, 2].includes(colNum) ) {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "ffebebeb" },
          };
        }
      }
    }

    const merge = (sheet, number, count) => {
      if( count === 4 ) {
        sheet.mergeCells(`A${number}:B${number}`); // th 머지
        sheet.mergeCells(`C${number}:D${number}`); // tr 머지
        sheet.mergeCells(`E${number}:F${number}`); // th 머지
        sheet.mergeCells(`G${number}:H${number}`); // tr 머지
      } else {
        sheet.mergeCells(`A${number}:B${number}`); // th 머지
        sheet.mergeCells(`C${number}:H${number}`); // tr 머지
      }
    }

    // 상단 헤더
    const headerContents = sheet.addRow([
      '연도', '', selectedGroup.year, '', '발주처', '', selectedGroup.orderTypeName, ''
    ]);
    merge(sheet, 3, 4);
    headerContents.eachCell((cell, colNum) => style(cell, colNum, 4));

    const headerContents1 = sheet.addRow([
      '용도', '', selectedGroup.purposeName, '', '연면적', '', comma(selectedGroup.totalSize ?? 0), ''
    ]);
    merge(sheet, 4, 4);

    headerContents1.eachCell((cell, colNum) => style(cell, colNum, 4));

    const headerContents2 = sheet.addRow([
      '비고', '', selectedGroup.memo, ''
    ]);
    merge(sheet, 5, 2);
    headerContents2.eachCell((cell, colNum) => style(cell, colNum, 2));

  }

  /***************************************************************************************
   * 설명 : 엑셀 데이터 헤더 영역
  ***************************************************************************************/
  const excelListHeader = (sheet) => {
    // 데이터 헤더 영역
    let headerDefault = [...columnDefsRef.current.filter((item) => {
      let check = true;

      if( ['seq'].includes(item.field) || item.field.indexOf('_Check') > -1 ) check = false;
      if( ( item.hide ?? false ) === true ) check = false;

      return check;
    })];

    const headers = [...headerDefault.map((item) => item.headerName)];
    let headerWidths = [8, 14, 27, 14, 14, 14, 20];

    headerWidths = headerDefault.map((item, index) => {
      if( index < 7 )
        return headerWidths[index];
      else
        return 20;
    })

    // 상단 헤더(TH) 추가
    const headerRow = sheet.addRow(headers);
    headerRow.height = 45;

    // 각 헤더 cell에 스타일 지정
    headerRow.eachCell((cell, colNum) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "ffebebeb" },
      };
      cell.border = {
        top: { style: "thin", color: { argb: "-100000f" } },
        bottom: { style: "thin", color: { argb: "-100000f" } },
        right: { style: "thin", color: { argb: "-100000f" } },
      };
      cell.font = {
        name: "Arial",
        size: 12,
        bold: true,
        color: { argb: "ff252525" },
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      };

      sheet.getColumn(colNum).width = headerWidths[colNum - 1];
    });

    return headerDefault;
  }

  /***************************************************************************************
   * 설명 : 엑셀 리스트 내보내기
  ***************************************************************************************/
  const excelList = (sheet, headerDefault) => {
    // 각 Data cell에 데이터 삽입 및 스타일 지정
    list.forEach((item, index) => {
      // download, preview 제외

      const rowDatas = [...headerDefault.map((subItem) => {
        if( subItem.field === 'type') {
          if( parseInt(item[subItem.field]) === 0 ) return '기본';
          else if( parseInt(item[subItem.field]) === 1 ) return '외주용역비';
          else return '기타';
        } else if( subItem.field === 'rowIndex' ) {
          return index + 1;
        } else if( ['amt', 'baseAmt', 'avgAmt'].includes(subItem.field) ) {
          if( ! isNaN(item[subItem.field]) && item[subItem.field] !== undefined ) {
            return comma(parseInt(item[subItem.field] ?? 0));

          } else {
            return '';
          }
        } else {
          return item[subItem.field] ?? '';
        }
      })];

      const appendRow = sheet.addRow(rowDatas);

      appendRow.eachCell((cell, colNum) => {
        let align = ['right', 'center', 'left', 'left', 'right', 'right', 'right'];

        for(let i = align.length; i < headerDefault.length; i++ ) {
          align.push('right');
        }

        cell.font = {
          name: "Arial",
          size: 10,
          color: { argb: "ff252525" },
        };

        cell.alignment = {
          vertical: "middle",
          horizontal: align[parseInt(colNum) - 1],
          wrapText: true,
        };

        cell.border = {
          top: { style: "thin", color: { argb: "-100000f" } },
          bottom: { style: "thin", color: { argb: "-100000f" } },
          right: { style: "thin", color: { argb: "-100000f" } },
        };

        if ([4, 5, 6].includes(colNum) || colNum > 7) {
          cell.numFmt = "#,##0";
        }
      });
    });
  }

  /***************************************************************************************
   * 설명 : 엑셀 하단 통계
  ***************************************************************************************/
  const excelFooter = (sheet, startIndex) => {
    const style = (cell, colNum) => {
      if( [3, 4].includes(colNum) ) {
        cell.alignment = {
          vertical: "middle",
          horizontal: "right",
          wrapText: true,
        };
      } else {
        cell.alignment = {
          vertical: "middle",
          horizontal: "center",
          wrapText: true,
        };
      }

      cell.border = {
        top: { style: "thin", color: { argb: "-100000f" } },
        bottom: { style: "thin", color: { argb: "-100000f" } },
        right: { style: "thin", color: { argb: "-100000f" } },
      };

      if( [1, 2].includes(colNum) ) {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "ffebebeb" },
        };
      } else {
        cell.numFmt = "#,##0";
      }
    }

    const merge = (sheet, number) => {
      sheet.mergeCells(`A${number}:B${number}`); // th 머지
      sheet.mergeCells(`C${number}:D${number}`); // tr 머지
    }

    const key = [
      {key: 'directLabourCost', label: '직접인건비'},
      {key: 'directCost', label: '직접경비' },
      {key: 'outsourcingCost', label: '외주용역비' },
      {key: 'totalIndirectionCost', label: '전사간접비' },
      {key: 'margin', label: '이윤' },
      {key: 'extra', label: '기타' },
      {key: 'totalCost', label: '견적(제안)설계용역비' },
    ]

    key.forEach((item, index) => {
      const headerContents = sheet.addRow([item.label, '', comma(formik.values[item.key] ?? ''), '']);
      merge(sheet, startIndex + index);
      headerContents.eachCell((cell, colNum) => style(cell, colNum));
    })

  }

  /***************************************************************************************
   * 설명 : 엑셀 내보내기
  ***************************************************************************************/
  const exportExcel = async (title, fileName) => {
    try {
      // 여러 엑셀 시트를 포함하는 하나의 workbook(단위) 생성
      const wb = new Excel.Workbook();

      // 엑셀 sheet 생성
      const sheet = wb.addWorksheet("데이터");

      sheet.views = [
        {state: 'frozen', ySplit: 7}
      ];

      // 헤더 출력
      excelHeader(sheet, title);

      // 빈 라인 추가
      sheet.addRow(['']);

      // 헤더 정보 영역
      excelHeaderContents(sheet);

      // 빈 라인 추가
      sheet.addRow(['']);

      // 리스트 헤더 영역
      const headerDefault = excelListHeader(sheet);

      // 리스트
      excelList(sheet, headerDefault);

      // 빈 라인 추가
      sheet.addRow(['']);

      // 하단 통계 표시
      const startIndex = list.length + 9;
      excelFooter(sheet, startIndex);

      // 파일 생성
      const fileData = await wb.xlsx.writeBuffer(); //writeBuffer는 프로미스를 반환하므로 async-await을 사용해야 한다.
      const blob = new Blob([fileData], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      saveAs(blob, fileName);

    } catch(error) {
      alertMsg("엑셀 파일을 내보내는데 실패하였습니다.", "W", MESSAGE_DELAY);
      console.log(error);
    }

  }

  /***************************************************************************************
   * 설명 : 저장하기
  ***************************************************************************************/
  const save = () => {
    // 프로젝트 적용된 리스트 추출
    let tmp = projectList.map((item) => {
      let index = 0;
      return list.map((subItem) => {
        return {
          costMemberSeq: subItem.costMemberSeq,
          costItemSeq: subItem.costItemSeq ?? index--,
          projectNumber: item.projectNumber,
          baseAmt: subItem[item.projectNumber],
          isApply: subItem[item.projectNumber + '_Check'] ?? 1,
          sortOrder: subItem.rowIndex
        }
      })
    });

    // 프로젝트 리스트 추출
    let projectNumberList = projectList.map((item) => {
      return {
        costMemberSeq: selectedGroup?.seq,
        projectNumber: item.projectNumber
      }
    })

    let params = {
      list: list,
      projectList: tmp.flat(),
      projectNumberList: projectNumberList,
      totalInfo: {
        seq: selectedGroup?.seq,
        ...formik.values
      }
    }

    setCostMemberDetailApi(getApi, params, (response) => {
      if( response !== undefined && response.data.result ) {
        alertMsg(response.data.message, "S", MESSAGE_DELAY);

      } else {
        alertMsg(response.data.message || '서버와의 통신에 실패하였습니다.', "E", MESSAGE_DELAY);
      }
    });
  }

  /***************************************************************************************
   * 설명 : 검색 조건에 따른 검색
  ***************************************************************************************/
  const getList = () => {
    let params = {
      ...formikSearch.values
    }

    getCostMemberListApi(getApi, params, (response) => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        setGroupList(response.data.data);
      } else {
        setGroupList([]);
        formik.setValues(initialValues);
      }
    });
  }

  /***************************************************************************************
   * 설명 : 설계비 가산정 삭제
  ***************************************************************************************/
  const deleteCostForm = () => {
    let nodes = gridApi.getSelectedRows();

    let params = {
      seq: nodes[0].seq
    }

    deleteCostMemberApi(getApi, params, (response) => {
      if( response !== undefined && response.data.result ) {
        alertMsg(response.data.message, "S", MESSAGE_DELAY);

        getList();

      } else {
        alertMsg(response.data.message || '서버와의 통신에 실패하였습니다.', "E", MESSAGE_DELAY);
      }
    })
  }

  /***************************************************************************************
   * 설명 : 설계비가산정 상세항목 삭제
  ***************************************************************************************/
  const deleteCostFormDetail = () => {
    let nodes = gridApiDetail.getSelectedRows();

    // 로컬에서 삭제
    let tmp = nodes.filter((item) => ( item.seq ?? '' ) === '' );
    if( tmp.length > 0 ) {
      let tmp2 = list.filter((item) => {
        let tmp1 = tmp.filter((subItem) => subItem.costItemSeq === item.costItemSeq);
        if( tmp1.length > 0 )
          return false;
        else
          return true;
      });

      setList(tmp2);
    }

    // 서버에서 삭제
    tmp = nodes.filter((item) => ( item.seq ?? '' ) !== '' );
    if( tmp.length > 0 ) {
      let params = {
        list: nodes
      }

      deleteCostMemberDetailApi(getApi, params, (response) => {
        if( response !== undefined && response.data.result ) {
          alertMsg(response.data.message, "S", MESSAGE_DELAY);

          getDetail();

        } else {
          alertMsg(response.data.message || '서버와의 통신에 실패하였습니다.', "E", MESSAGE_DELAY);
        }
      });
    }
  }

  /***************************************************************************************
   * 설명 : 시작 종료년도 가져오기
  ***************************************************************************************/
  const getCostYear = () => {
    getCostMemberYearApi(getApi, (response) => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        setYear(getSelectData(response.data.data, 'year', 'year'));

        formikSearch.setFieldValue('startProjectYear', response.data.data[0].year);
        formikSearch.setFieldValue('endProjectYear', response.data.data[0].year);
      } else {
        setYear([]);
      }
    });
  }

  /***************************************************************************************
   * 설명 : 용도 가져오기
  ***************************************************************************************/
  const getPurpose = () => {
    getCostPurposeApi(getApi, (response) => {
      if( response !== undefined && response.data.result !== false && response.data.data && response.data.data.length > 0 ) {
        setPurpose(getSelectData([
          ...response.data.data.filter((item) => {
            return item.commonCode.length === 3
          })], 'commonName', 'commonCode')
        );
      } else {
        setPurpose([]);
      }
    });
  }

  /***************************************************************************************
   * 설명 : 발주처 리스트 가져오기
  ***************************************************************************************/
  const getCommonList = () => {
    getCommonListApi(getApi, {groupCode: 'ORDER'}, (response) => {
      if( response !== undefined && response.data.result !== false && response.data.data && response.data.data.length > 0 ) {
        setOrder(getSelectData(response.data.data, 'commonName', 'commonCode'));
      } else {
        setOrder([]);
      }
    })
  }

  /***************************************************************************************
   * 설명 : 디테일 불러오기
  ***************************************************************************************/
  const getDetail = () => {
    init();

    let params = {
      seq: selectedGroup?.seq
    }

    getCostMemberDetailApi(getApi, params, (response) => {
      if( response !== undefined && response.data.result !== false && response.data.data && response.data.data.length > 0 ) {
        let tmp = [...response.data.data.map((item) =>
          {
            return {
              seq: item.seq,
              type: item.type,
              costItemSeq: item.costItemSeq,
              costMemberSeq: item.costMemberSeq,
              title: item.title,
              amt: item.amt,
              avgAmt: item.avgAmt,
              baseAmt: item.baseAmt,
              memo: item.memo,
              sortOrder: item.sortOrder,
            }
          })
        ];

        // 프로젝트 적용 처리
        if( response.data.data1 && response.data.data1.length > 0 && response.data.data2 && response.data.data2.length > 0 ) {
          let key = response.data.data1.map((item) => item.projectNumber);

          let tmp1 = tmp.map((subItem) => {
            let tmp2 =  {
              ...subItem,
            }

            key.forEach((projectNumber) => {
              let tmp5 = response.data.data2.filter((subItem2) => {
                return subItem2.costItemSeq === subItem.costItemSeq && subItem2.projectNumber === projectNumber
              })

              tmp2[projectNumber] = tmp5[0]?.baseAmt ?? 0;
              tmp2[projectNumber + '_Check'] = parseInt(tmp5[0]?.isApply ?? 1);
            });

            return tmp2;
          });

          tmp = tmp1;
        }

        tmp = calc(tmp);
        setList(tmp);

        setProjectList([
          ...response.data.data1.map((item) => {
            return {
              label: item.projectName,
              value: item.projectNumber,
              ...item
            }
          })
        ]);

        // 컬럼 설정
        addColumn(response.data.data1);

      } else {
        setList([]);

        setProjectList([]);
      }

    });
  }

  /***************************************************************************************
   * 설명 : 데이터 초기화
  ***************************************************************************************/
  const init = () => {
    // 선택된 프로젝트 초기화
    setProjectList([]);

    // 컬럼 초기화
    columnDefsRef.current = columnDef;
  }

  /***************************************************************************************
   * 설명 : 전사 간접비, 이윤 계산
  ***************************************************************************************/
  useEffect(() => {
    if( list.length > 0 )
      calc(list);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formik.values.totalIndirectionCostRate,
    formik.values.marginRate
  ])

  /***************************************************************************************
   * 설명 : 마스터 변경 시 디테일 불러오기
  ***************************************************************************************/
  useEffect(() => {
    if( (selectedGroup?.seq ?? '') !== '' ) {
      formik.setValues(selectedGroup);

      getDetail();

    } else {
      setList([]);
      setProjectList([]);

      formik.setFieldValue('directLabourCost', '');
      formik.setFieldValue('directCost', '');
      formik.setFieldValue('outsourcingCost', '');
      formik.setFieldValue('totalIndirectionCost', '');
      formik.setFieldValue('margin', '');
      formik.setFieldValue('extra', '');
      formik.setFieldValue('totalCost', '');

    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[selectedGroup]);

  /***************************************************************************************
   * 설명 : 리스트 변경 시 포커스 처리
  ***************************************************************************************/
  useEffect(() => {
    if( list && list.length > 0 && gridApiDetail.ensureIndexVisible !== undefined && selectedDetail.length > 0) {
      setAgGridFocus(gridApiDetail, 'seq', selectedDetail[0].seq);
    }

    // eslint-disable-next-line
  }, [list]);

  /***************************************************************************************
   * 설명 : 데이터 검색
  ***************************************************************************************/
  useEffect(() => {
    if( groupList.length > 0 )
      getList();

    // eslint-disable-next-line
  }, [
    formikSearch.values.startProjectYear, formikSearch.values.endProjectYear,
    formikSearch.values.purpose, formikSearch.values.orderTypeName
  ]);

  /***************************************************************************************
   * 설명 : 데이터 처리
  ***************************************************************************************/
  useEffect(() => {
    if( year.length > 0 && formikSearch.values.year !== '' ) {
      getList();
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year])

  /***************************************************************************************
   * 설명 : 데이터 처리
  ***************************************************************************************/
  useEffect(() => {
    // 용도
    getPurpose();

    // 발주처
    getCommonList();

    // 시작, 종료년도 가져오기
    getCostYear();

    // eslint-disable-next-line
  }, []);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <>
      <section className={"admin-cms-contents cost-page " + costReduce}>
        <SplitPane split="vertical" minSize={costMin} defaultSize={costMax}>
          <section className="left-area">
            <div className="pad-reduce-btn">
              <i className={costReduce === 'reduce' ? "ri-arrow-right-circle-fill reduce-icon" : "ri-arrow-left-circle-fill reduce-icon"} onClick={() => setCostReduceClick()}></i>
            </div>
            <div className="reduce-blank">
              <form onSubmit={formikSearch.handleSubmit}>
                <header>
                  <div className="mt5 mb5">
                    <SelectEx
                      name="startProjectYear"
                      formik={formikSearch}
                      fullWidth={false}
                      style={{width: 'calc(50% - 5px)', marginRight: '5px'}}
                      data={[
                        {label: '연도 시작', value: ''},
                      ].concat(year)}
                    />
                    <SelectEx
                      name="endProjectYear"
                      formik={formikSearch}
                      fullWidth={false}
                      style={{width: '50%'}}
                      data={[
                        {label: '연도 끝', value: ''},
                      ].concat(year)}
                    />
                  </div>
                  <div className="mb5">
                    <InputEx
                      name="totalSizeStart"
                      formik={formikSearch}
                      label="연면적 시작검색"
                      fullWidth={false}
                      style={{width: 'calc(50% - 5px)'}}
                      className="search-input inputbox "
                    />
                    <InputEx
                      name="totalSizeEnd"
                      formik={formikSearch}
                      label="연면적 종료검색"
                      fullWidth={false}
                      style={{width: '50%'}}
                      className="search-input inputbox ml5"
                    />
                  </div>
                  <div className="mb5">
                    <SelectEx
                      name="purpose"
                      formik={formikSearch}
                      fullWidth={false}
                      style={{width: 'calc(50% - 5px)', marginRight: '5px'}}
                      data={[
                        {label: '용도 선택', value: ''}
                      ].concat(purpose)}
                    />
                    <SelectEx
                      name="order"
                      formik={formikSearch}
                      fullWidth={false}
                      style={{width: '50%'}}
                      data={[
                        {label: '발주처 선택', value: ''},
                      ].concat(order)}
                    />
                  </div>

                  <div className="flex between">
                    <InputEx
                      name="searchText"
                      formik={formikSearch}
                      placeholder="제목"
                      fullWidth={true}
                      className="search-input inputbox"
                      style={{width: 'calc(100% - 70px)'}}
                    />

                    <Button
                      variant="outlined"
                      color="primary"
                      className="Btn ml10"
                      onClick={(event) => {
                        getList();
                      }}
                    >검색</Button>
                  </div>

                  <div className="mt5">
                    <div className="fl">
                      <BtnRefresh click={() => {
                        formikSearch.setValues(initialValues);
                        formikSearch.handleSubmit();
                      }}></BtnRefresh>
                      <BtnAgGridSave click={setGridUpdateGroup}></BtnAgGridSave>
                    </div>
                    <div className="fr">
                      <ButtonEx
                        title="삭제"
                        auth="isDelete"
                        color="error"
                        variant="outlined"
                        className="ml5"
                        onClick={() => {
                          let nodes = gridApi.getSelectedRows();

                          if( nodes.length < 1 ) {
                            alertMsg("삭제할 설계비가산정을 선택하시기 바랍니다.", "W", MESSAGE_DELAY);
                            return;
                          }

                          setOpenModal({open: true, modal: 'masterDialog', data: {}});
                        }}
                      />

                      <ButtonEx
                        title="추가"
                        auth="isWrite"
                        color="secondary"
                        className="ml5"
                        variant="outlined"
                        // disabled={selectedGroup?.seq === undefined}
                        onClick={() => {
                          setOpenModal({open: true, modal: 'masterAdd', data: groupList})
                        }}
                      />
                    </div>
                  </div>
                </header>
              </form>

              <div className="clearfix" />

              <section className="mt5 ag-theme-balham grid pad-height350" style={{height:'calc(100vh - 255px)'}}>
                <div className="" style={{ height: '100%'}}>
                  <AgGridReact
                    defaultColDef={{
                      sortable: true,
                      resizable: true,
                      filter: false,
                      lockVisible: true,
                      tooltipComponent: customTooltipAgGrid,
                    }}
                    tooltipShowDelay={0}
                    tooltipHideDelay={2000}
                    rowSelection = {'single'}
                    columnDefs={columnDefsGroup}
                    rowData={groupList}
                    onGridReady={(event) => {
                      gridConfigGroup(event.columnApi);
                      setGridApi(event.api);
                    }}
                    onSelectionChanged={(event) => {
                      let nodes = event.api.getSelectedRows();
                      if( nodes.length > 0 )
                        setSelectedGroup(nodes[0]);
                      else
                        setSelectedGroup({});
                    }}
                    onRowDoubleClicked={(event) => {
                      setOpenModal({open: true, modal: 'masterAdd', data: event.data});
                    }}
                    rowDragManaged={true}
                    rowDragMultiRow={true}
                    animateRows={true}
                    overlayNoRowsTemplate = "검색된 내용이 없습니다."
                  />
                </div>
              </section>
            </div>
          </section>

          <section className="">
            <section className="">
              <section>
                <table className="input-table mt10 table80">
                  <colgroup>
                    <col style={{width: '25%'}} />
                    <col style={{width: '25%'}} />
                    <col style={{width: '25%'}} />
                    <col style={{width: '25%'}} />
                  </colgroup>

                  <tbody>
                    <tr className='layout-small'>
                      <th scope="row"><label htmlFor="">연도</label></th>
                      <td>{formik.values.year}</td>
                      <th scope="row"><label htmlFor="">발주처</label></th>
                      <td>{formik.values.orderTypeName}</td>
                    </tr>
                    <tr className='layout-small'>
                      <th scope="row"><label htmlFor="">연면적(평)</label></th>
                      <td>
                        {comma(formik.values.totalSize ?? '')}
                        {(formik.values.totalSize ?? '') !== '' &&
                          <span>({comma(Math.floor(formik.values.totalSize * 0.3025))})</span>
                        }
                      </td>
                      <th scope="row"><label htmlFor="">제목</label></th>
                      <td className="center pr10">
                        {formik.values.title}
                      </td>
                    </tr>
                    <tr className='layout-small'>
                      <th scope="row"><label htmlFor="">용도</label></th>
                      <td>{formik.values.purposeName}</td>
                      <th><label htmlFor="">비고</label></th>
                      <td className="pr10">
                        {formik.values.memo}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </section>

              <section>
                <section className="admin-cms-body mt5">
                  <header className=" clearfix mb5">
                    <div className="fl right-btn">
                      <BtnAgGridSave click={setGridUpdate}></BtnAgGridSave>

                      <div className="fr">
                        <ButtonEx
                          title="삭제"
                          auth="isDelete"
                          color="error"
                          variant="outlined"
                          disabled={(selectedGroup?.seq ?? '') === ''}
                          onClick={() => {
                            let nodes = gridApiDetail.getSelectedRows();

                            if( nodes.length < 1 ) {
                              alertMsg("삭제할 설계비가산정 상세항목을 선택하세요.", "W", MESSAGE_DELAY);
                              return;
                            }

                            // 기본 항목이 선택된 경우 삭제 불가
                            let tmp = nodes.filter((item) => parseInt(item.type) === 0);
                            if( tmp.length > 0 ) {
                              alertMsg("기본항목은 삭제하실 수 없습니다.", "W", MESSAGE_DELAY);
                              return;
                            }

                            setOpenModal({open: true, modal: 'delDetail', data: {}})
                          }}
                        />

                        <ButtonEx
                          title="외주용역비"
                          auth="isWrite"
                          color="secondary"
                          className="ml5"
                          variant="outlined"
                          disabled={(selectedGroup?.seq ?? '') === ''}
                          onClick={() => {
                            setOpenModal({open: true, modal: 'workKind', data: {}})
                          }}
                        />

                        <ButtonEx
                          title="기타"
                          auth="isWrite"
                          color="secondary"
                          className="ml5"
                          variant="outlined"
                          disabled={(selectedGroup?.seq ?? '') === ''}
                          onClick={() => {
                            setOpenModal({open: true, modal: 'extraAdd', data: {}})
                          }}
                        />

                        <ButtonEx
                          auth="isExcelDownload"
                          title="엑셀다운로드"
                          color="inherit"
                          className="ml5"
                          variant="outlined"
                          disabled={(selectedGroup?.seq ?? '') === ''}
                          onClick={() => {
                            exportExcel(selectedGroup.title, '설계비가산정' + selectedGroup.title + '.xlsx');
                          }}
                        />

                        <ButtonEx
                          auth="isWrite"
                          title="출력"
                          color="inherit"
                          className="ml5"
                          variant="outlined"
                          disabled={(selectedGroup?.seq ?? '') === ''}
                          onClick={() => {
                            window.open('/admin/cost/print?seq=' + selectedGroup?.seq, 'print', '');
                          }}
                        />
                      </div>
                    </div>
                    <div className="fr pad-mt5">
                      <ButtonEx
                        auth="isWrite"
                        title="참조프로젝트 관리"
                        color="secondary"
                        className="ml5"
                        variant="outlined"
                        disabled={(selectedGroup?.seq ?? '') === ''}
                        onClick={() => setOpenModal({open: true, modal: 'projectAdd', data: {}})}
                      />

                      <ButtonEx
                        auth="isWrite"
                        title="저장"
                        color="secondary"
                        className="ml5"
                        variant="contained"
                        disabled={(selectedGroup?.seq ?? '') === ''}
                        onClick={() => setOpenModal({open: true, modal: 'saveConfirm', data: {}})}
                      />
                    </div>
                  </header>

                  <section className="ag-theme-balham grid pad-height500" style={{height:'calc(100vh - 400px)'}}>
                    <div className="" style={{ height: '100%' }}>
                      <AgGridReact
                        defaultColDef={{
                          sortable: true,
                          resizable: true,
                          filter: false,
                          lockVisible: true,
                          tooltipComponent: customTooltipAgGrid,
                        }}
                        tooltipShowDelay={0}
                        tooltipHideDelay={2000}
                        rowSelection = {'multiple'}
                        columnDefs={columnDefsRef.current}
                        onGridReady={(event) => {
                          gridConfig(event.columnApi);
                          setGridApiDetail(event.api);
                          // setColumnApiDetail(event.columnApi);
                        }}
                        onCellValueChanged={(event) => {
                          let tmp = calc(list);
                          setList(tmp);
                        }}
                        rowData={list}
                        rowDragManaged={true}
                        rowDragMultiRow={true}
                        animateRows={true}
                        overlayNoRowsTemplate = "검색된 내용이 없습니다."
                      />
                    </div>
                  </section>

                  <div className="clearfix mt5"></div>

                  <table className="input-table table500">
                  <colgroup>
                    <col style={{width: '25%'}} />
                    <col style={{width: '25%'}} />
                    <col style={{width: '50%'}} />
                  </colgroup>

                  <tbody>
                    <tr className='layout-small'>
                      <th scope="row" colSpan={2}><label htmlFor="">직접인건비</label></th>
                      <td className="text-right pr10">{comma(formik.values.directLabourCost || '')}</td>
                    </tr>
                    <tr className='layout-small'>
                      <th scope="row" colSpan={2}><label htmlFor="">직접경비</label></th>
                      <td className="text-right pr10">{comma(formik.values.directCost || '')}</td>
                      </tr>
                    <tr className='layout-small'>
                      <th scope="col" colSpan={2}><label htmlFor="">외주용역비</label></th>
                      <td className="text-right pr10">{comma(formik.values.outsourcingCost || '')}</td>
                    </tr>
                    <tr className='layout-small'>
                      <th scope="row">
                        <label htmlFor="" style={{lineHeight: '28px'}}>전사간접비</label>
                      </th>
                      <th scope="row">
                        <InputEx
                          name="totalIndirectionCostRate"
                          formik={formik}
                          label="전사간접비요율"
                          style={{backgroundColor: '#fff'}}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                          }}
                          onChange={(event) => {
                            if (event.nativeEvent.data) {
                              event.target.value = onlyNumber(event.target.value);
                            }
                          }}
                          onBlur={(event) => {
                            event.target.value = onlyNumber(event.target.value);
                          }}
                        />
                      </th>
                      <td className="text-right pr10">
                        {comma(formik.values.totalIndirectionCost)}
                      </td>
                    </tr>
                    <tr className='layout-small'>
                      <th scope="row">
                        <label htmlFor="" style={{lineHeight: '28px'}}>이윤</label>
                      </th>
                      <th scope="row">
                        <InputEx
                          name="marginRate"
                          formik={formik}
                          fullWidth={true}
                          label="이윤율"
                          style={{backgroundColor: '#fff'}}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                          }}
                          onChange={(event) => {
                            if (event.nativeEvent.data) {
                              event.target.value = onlyNumberDot(event.target.value);
                            }
                          }}
                          onBlur={(event) => {
                            event.target.value = onlyNumberDot(event.target.value);
                          }}
                        />
                      </th>
                      <td className="text-right pr10">{comma(formik.values.margin)}</td>
                    </tr>
                    <tr className='layout-small'>
                      <th scope="col" colSpan={2}><label htmlFor="">기타</label></th>
                      <td className="text-right pr10">{comma(formik.values.extra || '')}</td>
                    </tr>
                    <tr className='layout-small'>
                      <th scope="row" colSpan={2}><label htmlFor="">견적(제안)설계용역비</label></th>
                      <td className="text-right pr10">{comma(formik.values.totalCost || '')}</td>
                    </tr>
                  </tbody>
                </table>
                </section>
              </section>
            </section>
          </section>
        </SplitPane>
      </section>

      { openModal.open && openModal.modal === 'masterAdd' &&
        <Dialog
          open={openModal.open}
          onClose={() => setOpenModal({ open: false, modalName: 'masterAdd', data: openModal.data })}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "900px",  // Set your width here
              },
            },
          }}
        >
          <CostMemberModal
            open={openModal.open}
            close={(result) => {
              // 년도가 추가된 경우를 위해 새로 가져옴
              if(result)
                getCostYear();

              setOpenModal({ open: false, modalName: 'masterAdd', data: openModal.data })
            }}
            group={selectedGroup}
            data={openModal.data}
            setGroupList={setGroupList}
            getList={getList}
            indicator={props.indicator}
            history={props.history}
          />
        </Dialog>
      }

      { openModal.open && openModal.modal === 'masterDialog' &&
        <Dialog
          open={openModal.open}
          onClose={() => setOpenModal({ open: false, modalName: 'masterDialog', data: openModal.data })}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "500px",  // Set your width here
              },
            },
          }}
        >
          <AlertDialog
            open={openModal.open}
            close={() => setOpenModal({ open: false, modalName: 'masterDialog', data: openModal.data })}
            title="설계비가산정 삭제"
            message={`선택하신 설계비가산정을 삭제하시겠습니까?`}
            confirm="삭제"
            onClick={deleteCostForm}
            indicator={props.indicator}
            history={props.history}
          />
        </Dialog>
      }

      { // 설계비가산정 상세항목 삭제
        openModal.open && openModal.modal === 'delDetail' &&
        <Dialog
          open={openModal.open}
          onClose={() => setOpenModal({ open: false, modal: openModal.modal, data: openModal.data })}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "500px",  // Set your width here
              },
            },
          }}
        >
          <AlertDialog
            open={openModal.open}
            close={() => setOpenModal({ open: false, modal: openModal.modal, data: openModal.data })}
            title="상세항목 삭제"
            message={`선택하신 설계비가산정 항목을 삭제하시겠습니까?`}
            confirm="삭제"
            onClick={() => {
              deleteCostFormDetail();
            }}
            indicator={props.indicator}
            history={props.history}
          />
        </Dialog>
      }


      { // 저장
        openModal.open && openModal.modal === 'saveConfirm' &&
        <Dialog
          open={openModal.open}
          onClose={() => setOpenModal({ open: false, modalName: openModal.modal, data: openModal.data })}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "500px",  // Set your width here
              },
            },
          }}
        >
          <AlertDialog
            open={openModal.open}
            close={() => setOpenModal({ open: false, modalName: openModal.modal, data: openModal.data })}
            title="설계비가산정 저장"
            message={`입력하신 설계비가산정을 저장하시겠습니까?`}
            color="primary"
            confirm="저장"
            onClick={save}
            indicator={props.indicator}
            history={props.history}
          />
        </Dialog>
      }

      { // 참조 프로젝트 관리
        openModal.open && openModal.modal === 'projectAdd' &&
        <Dialog
          open={openModal.open}
          onClose={() => (event, reason) => {
            if(reason && reason === "backdropClick") return;
              setOpenModal({open: false, modal: openModal.modal, data: {}});
          }}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "1400px",  // Set your width here
              },
            },
          }}
        >
          <ProjectAddModal
            open={openModal.open}
            close={() => setOpenModal({ open: false, modalName: 'projectAdd', data: openModal.data })}
            data={openModal.data}
            columnDefs={columnDefsRef}
            list={list}
            setList={setList}
            addColumn={addColumn}
            projectList={projectList}
            setProjectList={setProjectList}
            indicator={props.indicator}
            history={props.history}
          />
        </Dialog>
      }

      { openModal.open && openModal.modal === 'workKind' &&
        <Dialog
          open={openModal.open}
          onClose={() => setOpenModal({ open: false, modalName: 'workKind', data: openModal.data })}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "450px",  // Set your width here
              },
            },
          }}
        >
          <WorkKindModal
            open={openModal.open}
            close={(result) => {
              // 항목이 선택된 경우
              if( result.result ) {
                // 중복된 항목 제거
                let nodes = result.data.filter((item) => {
                  let tmp1 = list.filter((subItem) => {
                    return subItem.costItemSeq === item.seq
                  });

                  return ( tmp1.length > 0 ) ? false : true
                })

                // 추가된 항목 데이터 설정
                let tmp = nodes.map((item) => {
                  let tmp1 = {
                    seq: '',
                    type: 1,
                    costMemberSeq: selectedGroup?.seq,
                    costItemSeq: item.seq,
                    title: item.title,
                    baseAmt: 0,
                    avgAmt: 0,
                    sortOrder: item.sortOrder,
                    memo: ''
                  }

                  // 추가된 프로젝트 항목 추가
                  if( projectList.length > 0 ) {
                    projectList.forEach((subItem) => {
                      tmp1[subItem.projectNumber] = item[subItem.projectNumber];
                      tmp1[subItem.projectNumber + '_Check'] = 1;
                    });
                  }

                  return tmp1;
                });

                // 기존의 항목에 추가된 항목 추가
                tmp = [...list.concat(tmp)];

                // 전체 항목 정렬
                tmp.sort((a, b) => {
                  if( a.type < b.type ) return -1;
                  else if( a.type > b.type ) return 1;
                  else {
                    if( a.sortOrder < b.sortOrder )
                      return -1;
                    else if( a.sortOrder > b.sortOrder )
                      return 1;
                    else
                      return 0;
                  }
                });

                tmp = calc(tmp);

                setList(tmp);

                setSelectedDetail([result.data]);
              }

              setOpenModal({ open: false, modalName: 'workKind', data: openModal.data })
            }}
            group={selectedGroup}
            data={openModal.data}
            projectList={projectList}
            indicator={props.indicator}
            history={props.history}
          />
        </Dialog>
      }

      { openModal.open && openModal.modal === 'extraAdd' &&
        <Dialog
          open={openModal.open}
          onClose={() => setOpenModal({ open: false, modalName: openModal.modal, data: openModal.data })}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "450px",  // Set your width here
              },
            },
          }}
        >
          <CostExtraModal
            open={openModal.open}
            close={(result) => {
              // 항목이 선택된 경우
              if( result.result ) {
                let tmp1 = list.filter((item) => item.type === 2);
                let sortOrder = 0;
                if( tmp1.length > 0 ) {
                  sortOrder = tmp1[tmp1.length - 1].sortOrder + 1;
                }

                // 추가된 항목 데이터 설정
                let tmp = {
                  seq: '',
                  type: 2,
                  costMemberSeq: selectedGroup?.seq,
                  costItemSeq: '',
                  title: result.data.title,
                  baseAmt: result.data.baseAmt,
                  avgAmt: 0,
                  sortOrder: sortOrder,
                  memo: ''
                }

                setList([...list].concat(tmp));
              }

              setOpenModal({ open: false, modalName: openModal.modal, data: openModal.data })
            }}
            data={openModal.data}
            indicator={props.indicator}
            history={props.history}
          />
        </Dialog>
      }
    </>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default ProjectCosting;