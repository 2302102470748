/**
 * * 검색어 검색
 * @param {*} getApi
 * @param {*} params
 * @param {*} func
 * @returns
 */
export function getSearchApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("post", '/api/v1/search', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  });
}

/***************************************************************************************
 * 설명 : 이전 검색어 삭제
***************************************************************************************/
export function deletePrevSearch(seq) {
  return new Promise((resolve, reject) => {
    let tmp = window.localStorage.getItem('prevSearch');
    let tmp1 = [];

    if( tmp ) {
      tmp1 = JSON.parse(tmp);
      tmp1.splice(seq, 1);

      let tmp2 = JSON.stringify(tmp1);
      window.localStorage.setItem('prevSearch', tmp2);
    }

    resolve({
      data: {
        result: true,
        message: '검색어 연관 정보를 삭제하는데 성공하였습니다.',
        data: tmp1
      }
    });
  });
}

/***************************************************************************************
 * 설명 : 이전 검색어 전체 삭제
***************************************************************************************/
export function deleteAllPrevSearch() {
  return new Promise((resolve, reject) => {
    window.localStorage.removeItem('prevSearch');

    resolve({
      data: {
        result: true,
        message: '검색어 연관 정보를 전체삭제 하는데 성공하였습니다.',
      }
    });
  });
}

/***************************************************************************************
 * 설명 : 업체 정보 리스트 가져오기
***************************************************************************************/
export function getPreSearchInfo(searchText) {

  // 이전 검색어 가져오기
  let tmp = window.localStorage.getItem('prevSearch');
  let prevSearch = JSON.parse(tmp);

  let relationWord = [];

  if( searchText !== '' ) {
    let tmp1 = window.localStorage.getItem('relationWord');
    if( (tmp1 ?? '') === '' ) return;

    let tmp2 = JSON.parse(tmp1);
    let tmp3 = tmp2.value.filter((item) => item.baseWord.indexOf(searchText) > -1 );
    if( tmp3.length > 5 ) relationWord = tmp3.splice(0, 5);
    else relationWord = tmp3;
  }

  return new Promise((resolve, reject) => {
    resolve({
      data: {
        result: true,
        message: '검색어 연관 정보를 가져오는데 성공하였습니다.',
        relationWord: relationWord,
        prevSearch: prevSearch
      }
    });
  });
}

/**
 * * 이전 검색어 리스트 가져오기
 * @param {*} getApi
 * @param {*} func
 * @returns
 */
export function getSearchListApi(getApi, func) {
  if( ! getApi ) return;

  getApi("get", '/api/v1/search/user').then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  });
}

/**
 * * 이전 검색어 선택 삭제
 * @param {*} getApi
 * @param {*} func
 * @returns
 */
export function deletePrevSearchApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("post", '/api/v1/search/user/delete', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  });
}

/**
 * * 이전 검색어 전체 삭제
 * @param {*} getApi
 * @param {*} params
 * @param {*} func
 * @returns
 */
export function deleteAllPrevSearchAllApi(getApi, func) {
  if( ! getApi ) return;

  getApi("get", '/api/v1/search/user/deleteall').then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  });
}

/**
 * * 연관단어 리스트 가져오기
 * @param {*} getApi
 * @param {*} func
 * @returns
 */
export function getRelationShipListApi(getApi, func) {
  if( ! getApi ) return;

  getApi("get", '/api/v1/search/relationship').then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  });
}