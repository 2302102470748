/**
 * 사이트 리스트 가져오기
 * @param {*} getApi
 * @param {*} params
 * @param {*} func
 * @returns
 */
export function getSiteInfoApi(getApi, func) {
  if( ! getApi ) return;

  getApi("get", '/api/v1/cms/site').then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  });
}

/**
 * * 사이트 정보 추가/수정
 * @param {*} getApi
 * @param {*} params
 * @param {*} func
 * @returns
 */
export function setSiteApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("post", '/api/v1/cms/site', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  });
}

/**
 * * 사이트 정보 삭제
 * @param {*} getApi
 * @param {*} params
 * @param {*} func
 * @returns
 */
export function deleteSiteInfoApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("post", '/api/v1/cms/site/delete', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  });
}

/**
 * * 모듈 리스트를 가져온다.
 * @param {*} getApi
 * @param {*} params
 * @param {*} func
 * @returns
 */
export function getModuleInfoApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("get", '/api/v1/cms/module', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  });
}

/**
 * * 모듈 정보를 추가/수정한다.
 * @param {*} getApi
 * @param {*} params
 * @param {*} func
 * @returns
 */
export function setModuleApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("post", '/api/v1/cms/module', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  });
}

/**
 * * 선택된 모듈을 삭제한다.
 * @param {*} getApi
 * @param {*} params
 * @param {*} func
 * @returns
 */
export function deleteModuleInfoApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("post", '/api/v1/cms/module/delete', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  });
}

/**
 * * 모듈 순서 변경
 * @param {*} getApi
 * @param {*} params
 * @param {*} func
 * @returns
 */
export function sortModuleInfoApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("post", '/api/v1/cms/module/sort', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  });
}

/**
 * * 메뉴 정보를 추가/수정한다.
 * @param {*} getApi
 * @param {*} params
 * @param {*} func
 * @returns
 */
export function setMenuApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("post", '/api/v1/cms/menu', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  });
}

/**
 * 메뉴 리스트 가져오기
 * @param {*} getApi
 * @param {*} params
 * @param {*} func
 * @returns
 */
export function getMenuListApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("get", '/api/v1/cms/menu', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  });
}

/**
 * * 메뉴 정보 삭제
 * @param {*} getApi
 * @param {*} params
 * @param {*} func
 * @returns
 */
export function deleteMenuApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("post", '/api/v1/cms/menu/delete', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  });
}

/**
 * * 권한 별 메뉴 정보 가져오기
 * @param {*} getApi
 * @param {*} params
 * @param {*} func
 * @returns
 */
export function getAuthMenuApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("post", '/api/v1/cms/auth/menu', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  });
}