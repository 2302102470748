/*****************************************************************************************
 * 설명 : 관리자 > 시스템관리 > 비용산출양식관리 > 추가/수정
*****************************************************************************************/
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { AgGridReact } from 'ag-grid-react';
import { useFormik } from 'formik';
import * as moment from 'moment';
import { useEffect, useState } from 'react';
import * as Yup from "yup";

import { MESSAGE_DELAY } from 'config/config';
import useGridConfig from 'hooks/useGridConfig';

import { getCommonListApi } from 'service/common';
import { getCostFormListApi, getCostPurposeApi } from 'service/cost';
import { setCostMemberApi } from 'service/costMember';
import Restful from "service/restful";
import { PaperComponentSub, getSelectData } from 'service/utils';

import AlertDialog from 'components/alertDiolog';
import BtnAgGridSave from 'components/btnAgGridSave';
import { ButtonEx, InputEx, SelectEx, ToggleButtonGroupEx } from 'components/inputEx';
import alertMsg from 'components/message';
import customTooltipAgGrid from 'components/tooltipAgGrid';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
export const CostMemberModal = ( props ) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [getApi] = Restful();
  const [title, setTitle] = useState('추가');

  const [openModal, setOpenModal] = useState({open: false, modal: 'add', data: {}});
  const [purpose, setPurpose] = useState([]);
  const [order, setOrder] = useState([]);
  const [groupList, setGroupList] = useState([]);

  const formik = useFormik({
    initialValues: {
      startProjectYear: '',
      endProjectYear: '',
      order: '',
      searchText: '',
      marginRate: 19.8,
      costFormSeq: '',
      totalSize: '',
      purpose: '',
      orderType: '',
      title: '',
      year: '',
      memo: '',
      isUse: 1
    },
    validationSchema: Yup.object().shape({
      //costFormSeq: Yup.string().required('필수'),
      purpose: Yup.string().required('필수'),
      orderType: Yup.string().required('필수'),
      title: Yup.string().max(45, "45자리").required('필수').nullable(),
      year: Yup.number().required('필수').nullable(),
      totalSize: Yup.number().typeError('숫자').nullable(),
    }),
    onSubmit: (values) => {
      setOpenModal({open: true, modal: 'add', data:{}});
    }
  });

  // table column
  const [columnDefsGroup, setColumnDefsGroup] = useState([
    { headerName: '', field: 'seq', width: 35, cellClass: 'center', flex: 0,
      headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
    { headerName:'연도', field: 'year', width: 45, cellClass: 'cp center' },
    { headerName:'양식명', field: 'title', width: 130, cellClass: 'cp' },
    { headerName:'용도', field: 'purpose', hide: true },
    { headerName:'용도', field: 'purposeName', width: 100, cellClass: 'cp center' },
    { headerName:'발주처', field: 'orderType', hide: true },
    { headerName:'발주처', field: 'orderTypeName', width: 70, cellClass: 'cp center' },
  ]);

  const [gridConfigGroup, setGridUpdateGroup] = useGridConfig(11, setColumnDefsGroup);

  /***************************************************************************************
   * 설명 : 양식 저장하기
  ***************************************************************************************/
  const save = () => {
    setCostMemberApi(getApi, formik.values, (response) => {
      if( response !== undefined && response.data.result ) {
        alertMsg(response.data.message, "S", MESSAGE_DELAY);

        if( props.getList ) props.getList();

        props.close(true);

      } else {
        alertMsg(response.data.message, "E", MESSAGE_DELAY);
      }
    });
  }

  /***************************************************************************************
   * 설명 : 용도 가져오기
  ***************************************************************************************/
  const getPurpose = () => {
    getCostPurposeApi(getApi, (response) => {
      if( response !== undefined && response.data.result !== false && response.data.data && response.data.data.length > 0 ) {
        setPurpose(getSelectData([
          ...response.data.data.filter((item) => {
            return item.commonCode.length === 3
          })], 'commonName', 'commonCode')
        );
      } else {
        setPurpose([]);
      }
    });
  }

  /***************************************************************************************
   * 설명 : 발주처 리스트 가져오기
  ***************************************************************************************/
  const getCommonList = () => {
    getCommonListApi(getApi, {groupCode: 'ORDER'}, (response) => {
      if( response !== undefined && response.data.result !== false && response.data.data && response.data.data.length > 0 ) {
        setOrder(getSelectData(response.data.data, 'commonName', 'commonCode'));
      } else {
        setOrder([]);
      }
    })
  }

  /***************************************************************************************
   * 설명 : 검색 조건에 따른 검색
  ***************************************************************************************/
  const getList = () => {
    let params = {
      ...formik.values
    }

    getCostFormListApi(getApi, params, (response) => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        setGroupList(getSelectData(response.data.data, 'title', 'seq'));

      } else {
        setGroupList([]);
      }
    });
  }

  /***************************************************************************************
   * 설명 : 모달 오픈 시 처리
  ***************************************************************************************/
  useEffect(() => {
    if( props.open ) {

      getPurpose();

      getCommonList();

      getList();

      if( props.data.seq !== undefined ) {
        formik.setValues(props.data);

        formik.setFieldValue('year', String(props.data.year))

        setTitle('수정');
      } else {
        setTitle('추가');
      }
    }

    // eslint-disable-next-line
  }, [props.open]);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <section className="modal">
      <section className="modal-wrap" style={{ width: '100%' }}>
        <form onSubmit={formik.handleSubmit}>
          <header className="modal-header" style={{ cursor: 'move' }} id="draggable-dialog-title">
            <div className="modal-title">설계비가산정 {title}</div>
            <Button className="close-btn" onClick={() => props.close(false)}>
              <CloseIcon></CloseIcon>
            </Button>
          </header>

          <section className="modal-body">
            <div className="fl" style={{width: '400px'}}>
              <header>
                <BtnAgGridSave click={setGridUpdateGroup}></BtnAgGridSave>
              </header>

              <section className="ag-theme-balham mt10" style={{height:'400px'}}>
                <div style={{ height: '100%' }}>
                  <AgGridReact
                    defaultColDef={{
                      sortable: true,
                      resizable: true,
                      filter: false,
                      lockVisible: true,
                      tooltipComponent: customTooltipAgGrid,
                    }}
                    tooltipShowDelay={0}
                    tooltipHideDelay={2000}
                    rowSelection = {'single'}
                    columnDefs={columnDefsGroup}
                    rowData={groupList}
                    onGridReady={(event) => {
                      gridConfigGroup(event.columnApi);
                    }}
                    onRowClicked={(event) => {
                      let tmp = groupList.filter((item) => parseInt(item.value) === parseInt(event.data.value));
                      if( tmp.length > 0 && tmp[0].value !== '') {
                        formik.setFieldValue('costFormSeq', tmp[0].seq);
                        formik.setFieldValue('purpose', tmp[0].purpose);
                        formik.setFieldValue('orderType', tmp[0].orderType);
                        formik.setFieldValue('type', tmp[0].groupName);
                        formik.setFieldValue('title', tmp[0].title);
                        formik.setFieldValue('year', tmp[0].year);
                      }
                    }}
                    rowDragManaged={true}
                    rowDragMultiRow={true}
                    animateRows={true}
                    overlayNoRowsTemplate = "검색된 내용이 없습니다."
                  />
                </div>
              </section>
            </div>

            <div className="fr" style={{width: '450px', marginTop: '35px'}}>
              <table className="input-table">
                <colgroup>
                  <col style={{width: '30%'}} />
                  <col style={{width: '70%'}} />
                </colgroup>

                <tbody>
                  {/*
                  <tr>
                    <th scope="row"><label htmlFor="costFormSeq">설계비가산정 양식 선택</label></th>
                    <td>
                      <SelectEx
                        name="costFormSeq"
                        formik={formik}
                        fullWidth={true}
                        data={[
                          {label: '양식선택', value: ''},
                        ].concat(groupList)}
                        onChange={(event) => {

                        }}
                      />
                    </td>
                  </tr>
                  */}
                  <tr>
                    <th scope="row"><label htmlFor="purpose">용도</label></th>
                    <td>
                      <SelectEx
                        name="purpose"
                        formik={formik}
                        fullWidth={true}
                        data={[
                          {label: '용도 선택', value: ''}
                        ].concat(purpose)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row"><label htmlFor="orderType">발주처</label></th>
                    <td>
                      <SelectEx
                        name="orderType"
                        formik={formik}
                        fullWidth={true}
                        data={[
                          {label: '발주처 선택', value: ''},
                        ].concat(order)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row"><label htmlFor="title">양식명</label></th>
                    <td>
                      <InputEx
                        name="title"
                        formik={formik}
                        fullWidth={true}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row"><label htmlFor="year">기준년도</label></th>
                    <td>
                      <InputEx
                        name="year"
                        formik={formik}
                        fullWidth={true}
                        placeholder={moment().format('YYYY')}
                        inputProps={{
                          maxLength: 4
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row"><label htmlFor="totalSize">연면적</label></th>
                    <td>
                      <InputEx
                        name="totalSize"
                        formik={formik}
                        fullWidth={true}
                        inputProps={{
                          maxLength: 20
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row"><label htmlFor="memo">비고</label></th>
                    <td>
                      <InputEx
                        name="memo"
                        formik={formik}
                        fullWidth={true}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row"><label htmlFor="isUse">사용여부</label></th>
                    <td>
                      <ToggleButtonGroupEx
                        name="isUse"
                        exclusive={true}
                        formik={formik}
                        data={[
                          {label: '사용', value: 1},
                          {label: '사용안함', value: 0},
                        ]}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="clearfix"></div>
          </section>

          <footer className="modal-footer">
            <div className="modal-footer-center">
              <Button
                color="inherit"
                variant="outlined"
                className="Btn"
                onClick={() => {
                  props.close(false);
                }}
              >창닫기</Button>

              <ButtonEx
                type="submit"
                auth="isWrite"
                title="저장하기"
                variant="contained"
                color="primary"
                className="Btn ml10"
              />
            </div>
          </footer>
        </form>
      </section>

      { openModal.open && openModal.modal === 'add' &&
        <Dialog
          open={openModal.open}
          onClose={() => setOpenModal({ open: false, modalName: 'dialog', data: openModal.data })}
          PaperComponent={PaperComponentSub}
          aria-labelledby="draggable-dialog-title-sub"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "450px",  // Set your width here
              },
            },
          }}
        >
          <AlertDialog
            open={openModal.open}
            close={() => setOpenModal({ open: false, modalName: 'dialog', data: openModal.data })}
            title={"설계비가산정양식 " + title}
            message={`입력하신 설계비 가산정양식을 ${title}하시겠습니까?`}
            confirm={title + "하기"}
            color="primary"
            onClick={() => {
              save();
            }}
            indicator={props.indicator}
            history={props.history}
          />
        </Dialog>
      }
    </section>
  )
}

export default CostMemberModal;