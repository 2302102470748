/*****************************************************************************************
 * 설명 : 관리자메뉴
 * URI :
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import React from 'react';

import Layout from 'pages/layout/layout';

import DesignProject from 'pages/admin/projectDesign/designProject';
import Project from 'pages/admin/project/project';
import ProjectCosting from 'pages/admin/cost/cost/cost';
import BoardList from 'pages/admin/board/boardList';
import ProjectLog from 'pages/admin/log/projectLog/projectLog';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const AdminMenu = ( props ) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <Layout props={props}>
      {props.uri === 'design' &&
        <div className={ (props.uri === 'design') ? 'displayBlock' : 'hidden' }>
          <DesignProject></DesignProject>
        </div>
      }
      {props.uri === 'project' &&
        <div className={ (props.uri === 'project') ? 'displayBlock' : 'hidden' }>
          <Project></Project>
        </div>
      }
      {props.uri === 'projectCosting' &&
        <div className={ (props.uri === 'projectCosting') ? 'displayBlock' : 'hidden' }>
          <ProjectCosting></ProjectCosting>
        </div>
      }
      {props.uri === 'board' &&
        <div className={ (props.uri === 'board') ? 'displayBlock' : 'hidden' }>
          <BoardList></BoardList>
        </div>
      }
      {props.uri === 'projectLog' &&
        <div className={ (props.uri === 'projectLog') ? 'displayBlock' : 'hidden' }>
          <ProjectLog></ProjectLog>
        </div>
      }
    </Layout>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default AdminMenu;