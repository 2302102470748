/*****************************************************************************************
 * 설명 : 관리자 > 시스템관리 > 권한그룹 관리 > 추가/수정
 * URI : /admin/system/auth/group
*****************************************************************************************/
import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import * as Yup from "yup";

import Restful from 'service/restful';
import { setAuthGroupApi } from 'service/auth';

import { InputEx, ToggleButtonGroupEx } from 'components/inputEx';
import alertMsg from 'components/message';
import { MESSAGE_DELAY } from 'config/config';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
export const AuthGroupModal = ( props ) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [getApi] = Restful();

  const [title, setTitle] = useState('추가');

  const formik = useFormik({
    initialValues: {
      seq: 0,
      authGroupName: '',
      isAdmin: 0,
      isUse: 1
    },
    validationSchema: Yup.object().shape({
      authGroupName: Yup.string().max(45, "45자리").required('필수')
    }),
    onSubmit: (values) => {
      // 추가시 중북된 권한명 체크
      if( values.seq === null || values.seq === 0 || values.seq === '' ) {
        let tmp = props.list.filter((item) => item.authGroupName === values.authGroupName);
        if( tmp.length > 0 ) {
          alertMsg("중복되는 권한그룹명이 존재합니다. 다른 권한그룹명을 입력하시기 바랍니다.", "W", MESSAGE_DELAY);
          return;
        }
      }

      setAuthGroupApi(getApi, values, (response => {
        if( response !== undefined && response.data.result ) {
          alertMsg(response.data.message, "S", MESSAGE_DELAY);

          if( props.getList ) props.getList();

          props.close();

        } else {
          alertMsg(response.data.message || '서버와의 통신에 실패하였습니다.', "E", MESSAGE_DELAY);
        }
      }));
    }
  });

  /***************************************************************************************
   * 설명 : 모달 오픈 시 처리
  ***************************************************************************************/
  useEffect(() => {
    if( props.open ) {
      if( props.data.seq !== undefined ) {
        formik.setValues(props.data);

        setTitle('수정');
      } else {
        setTitle('추가');
      }
    }

    // eslint-disable-next-line
  }, [props.open]);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <section className="modal-wrap" style={{ width: '100%' }}>
      <form onSubmit={formik.handleSubmit}>
        <header className="modal-header" style={{ cursor: 'move' }} id="draggable-dialog-title">
          <div className="modal-title">권한그룹 {title}</div>
          <Button className="close-btn" onClick={props.close}>
            <CloseIcon style={{ color: '#fff', fontSize: '28px' }}></CloseIcon>
          </Button>
        </header>

        <section className="modal-body">
          <table className="input-table">
            <colgroup>
              <col style={{width: '25%'}} />
              <col style={{width: '75%'}} />
            </colgroup>

            <tbody>
              <tr>
                <th scope="row"><label htmlFor="authGroupName">권한그룹명</label></th>
                <td>
                  <InputEx
                    name="authGroupName"
                    formik={formik}
                    fullWidth={true}
                    placeholder={"권한그룹명 입력"}
                    inputProps={{maxLength: 45, autoFocus: true}}
                  />
                </td>
              </tr>
              <tr>
                <th scope="row"><label htmlFor="isAdmin">관리자여부</label></th>
                <td>
                  <ToggleButtonGroupEx
                    name="isAdmin"
                    exclusive={true}
                    formik={formik}
                    data={[
                      {label: '일반사용자', value: 0},
                      {label: '관리자', value: 1},
                    ]}
                  />
                </td>
              </tr>
              <tr>
                <th scope="row"><label htmlFor="isUse">사용여부</label></th>
                <td>
                  <ToggleButtonGroupEx
                    name="isUse"
                    exclusive={true}
                    formik={formik}
                    data={[
                      {label: '사용', value: 1},
                      {label: '사용안함', value: 0},
                    ]}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </section>

        <footer className="modal-footer">
          <div className="modal-footer-center">
            <Button
              color="inherit"
              variant="outlined"
              className="Btn"
              onClick={() => {
                props.close();
              }}
            >창닫기</Button>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              className="Btn ml10"
            >저장하기</Button>
          </div>
        </footer>
      </form>
    </section>
  )
}

export default AuthGroupModal;