/*****************************************************************************************
 * 설명 : 프로젝트관리 - 용도
 * URI : /project
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import Button from '@mui/material/Button';
import { AgGridReact } from 'ag-grid-react';
import { useEffect, useState } from 'react';

import { MESSAGE_DELAY } from 'config/config';

import { comma } from 'service/utils';

import alertMsg from 'components/message';
import customTooltipAgGrid from 'components/tooltipAgGrid';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const PurposeModal = (props) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [purpose1, setPurpose1] = useState([]);
  const [purpose2, setPurpose2] = useState([]);
  const [selectedPurpose1, setSelectedPurpose1] = useState([]);

  const [gridApi1, setGridApi1] = useState({});
  const [gridApi2, setGridApi2] = useState({});
  const [gridApiSelected1, setGridApiSelected1] = useState({});

  /***************************************************************************************
   * 설명 : 업체 검색
  ***************************************************************************************/
  // table column
  const [columnDefs1] = useState([
    { headerName: '', field: 'seq', width: 35, cellClass: 'center', flex: 0,
      headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
    { headerName: '순번', field: 'rowIndex', width: 45, cellClass: 'cp text-right',
      valueGetter: (params) => {
        return comma(params.node.rowIndex + 1);
      }
    },
    { headerName: '용도 1단계', field: 'commonCode', hide: true },
    { headerName: '용도 1단계', field: 'commonName', width: 250, cellClass: "cp" },
  ]);

  // table column
  const [columnDefs2] = useState([
    { headerName: '', field: 'seq', width: 35, cellClass: 'center', flex: 0,
      headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
    { headerName: '순번', field: 'rowIndex', width: 45, cellClass: 'cp text-right',
      valueGetter: (params) => {
        return comma(params.node.rowIndex + 1);
      }
    },
    { headerName: '용도 2단계', field: 'commonCode', hide: true },
    { headerName: '용도 2단계', field: 'commonName', width: 250, cellClass: "cp" },
  ]);

  // table column
  const [columnDefs3] = useState([
    { headerName: '', field: 'seq', width: 35, cellClass: 'center', flex: 0,
      headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
    { headerName: '순번', field: 'rowIndex', width: 45, cellClass: 'cp text-right',
      valueGetter: (params) => {
        return comma(params.node.rowIndex + 1);
      }
    },
    { headerName: '용도 1단계', field: 'purpose1Code', hide: true },
    { headerName: '용도 1단계', field: 'purpose1Name', width: 250, cellClass: "cp" },
    { headerName: '용도 2단계', field: 'purpose2Code', hide: true },
    { headerName: '용도 2단계', field: 'purpose2Name', width: 250, cellClass: "cp" },
  ]);

  /***************************************************************************************
   * 설명 : 선택된 용도 추가
  ***************************************************************************************/
  const addPurpose = () => {
    let nodes = gridApi1.getSelectedRows();

    if( nodes.length < 1 ) {
      alertMsg("추가할 용도를 선택하시기 바랍니다.", "W", MESSAGE_DELAY);
      return;
    }

    let nodes1 = gridApi2.getSelectedRows();

    if( purpose2.length > 0 && nodes1.length < 1 ) {
      alertMsg("추가할 용도를 선택하시기 바랍니다.", "W", MESSAGE_DELAY);
      return;
    }

    // 2단계가 없을 경우 1단계를 추가
    if( purpose2.length < 1 ) {
      // filter
      let check = selectedPurpose1.filter((item) => item.purpose1Code === nodes[0].commonCode );
      if( check.length > 0 ) return;

      let tmp = [...selectedPurpose1];

      tmp.push({
        seq: nodes[0].seq,
        purpose1Code: nodes[0].commonCode,
        purpose1Name: nodes[0].commonName,
        purpose2Code: '',
        purpose2Name: ''
      });
      setSelectedPurpose1(tmp);

    // 정상적인 경우 2단계를 추가
    } else {
      let tmp = [...selectedPurpose1];

      let check = selectedPurpose1.filter((item) => {
        return item.purpose1Code === nodes[0].commonCode && item.purpose2Code === nodes1[0].commonCode;
      });

      if( check.length > 0 ) return;

      nodes1.forEach((item) => {
        tmp.push({
          seq: item.seq,
          purpose1Code: nodes[0].commonCode,
          purpose1Name: nodes[0].commonName,
          purpose2Code: item.commonCode,
          purpose2Name: item.commonName,
        });
      });

      setSelectedPurpose1(tmp);
    }
  }

  /***************************************************************************************
   * 설명 : 선택된 용도 제거
  ***************************************************************************************/
  const delPurpose = () => {
    let nodes = gridApiSelected1.getSelectedRows();

    if( nodes.length < 1 ) {
      alertMsg("제거할 용도를 선택하시기 바랍니다.", "W", MESSAGE_DELAY);
      return;
    }

    let tmp = selectedPurpose1.filter((item) => {
      let check = true;
      nodes.forEach((subItem) => {
        if( item.seq === subItem.seq ) check = false;
      });

      return check;
    });

    setSelectedPurpose1(tmp);
  }

  /***************************************************************************************
   * 설명 : 저장 시 처리
  ***************************************************************************************/
  const submit = () => {
    let nodes = [];

    gridApiSelected1.forEachNode((item) => {
      nodes.push(item.data);
    })

    if( nodes.length < 1 ) {
      alertMsg("추가할 용도를 선택하시기 바랍니다.", "W", MESSAGE_DELAY);
      return;
    }

    let tmpCode = '';
    let tmpName = '';

    tmpCode = nodes.map((item) => {
      if( item.purpose2Code === '' ) return item.purpose1Code;
      else return item.purpose2Code;
    }).join();

    tmpName = nodes.map((item) => {
      if( item.purpose2Code === '' ) return item.purpose1Name;
      else return item.purpose1Name + '(' + item.purpose2Name + ')';
    }).join();

    props.formik.setFieldValue('purpose1Code', tmpCode);
    props.formik.setFieldValue('purpose1', tmpName);

    props.close();
  }

  /***************************************************************************************
   * 설명 : 리스트 바인딩 시 선택된 데이터 표시
  ***************************************************************************************/
  useEffect(() => {
    if( props?.formik.values.purpose1 !== '' ) {
      let tmp = props.formik.values.purpose1Code?.split(/,/gi);

      if( tmp !== undefined && tmp.length > 0 ) {
        let tmp1 = [];
        tmp.forEach((item) => {
          // 1단계인 경우
          if( item.length === 3 ) {
            let tmp2 = props.purpose1.filter((subItem) => subItem.value === item);

            if( tmp2.length > 0 ) tmp1.push({
              seq: tmp2[0].seq,
              purpose1Code: tmp2[0].commonCode,
              purpose1Name: tmp2[0].commonName,
              purpose2Code: '',
              purpose2Name: ''
            });

          // 2단계인 경우
          } else {
            let tmp2 = props.purpose2.filter((subItem) => subItem.value === item);

            if( tmp2.length > 0 ) {
              let tmp3 = props.purpose1.filter((subItem) => subItem.value === tmp2[0].commonCode.substr(0, 3));

              // 1단계 값 가져오기
              tmp1.push({
                seq: tmp2[0].seq,
                purpose1Code: tmp3[0].commonCode,
                purpose1Name: tmp3[0].commonName,
                purpose2Code: tmp2[0].commonCode,
                purpose2Name: tmp2[0].commonName
              });
            }
          }
        });

        setSelectedPurpose1(tmp1);
      }

    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.purpose1, props.purpose1Code]);

  /***************************************************************************************
   * 설명 : 팝업 시 처리
  ***************************************************************************************/
  useEffect(() => {
    if( props?.purpose1?.length > 0 )
      setPurpose1(props.purpose1);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <section className="modal-wrap" style={{width: '100%'}}>
      <header className="modal-header" style={{ cursor: 'move' }} id="draggable-dialog-title">
        <div className="modal-title">용도 선택</div>
        <Button className="close-btn" onClick={props.close}>×</Button>
      </header>

      <div className="clearfix"></div>

      <section className="modal-body">
        <section className="w50per fl">
          <div className="ag-theme-balham" style={{height: '300px'}}>
            <AgGridReact
              defaultColDef={{
                sortable: true,
                resizable: true,
                filter: false,
                lockVisible: true,
                tooltipComponent: customTooltipAgGrid,
              }}
              tooltipShowDelay={0}
              tooltipHideDelay={2000}
              columnDefs={columnDefs1}
              rowData={purpose1}
              rowSelection={'single'}
              onGridReady={(event) => {
                setGridApi1(event.api);
              }}
              onSelectionChanged={(event) => {
                let nodes = event.api.getSelectedRows();

                if( nodes.length > 0 ) {
                  setPurpose2(props.purpose2.filter((item) => item.value.substr(0, 3) === nodes[0].value))
                } else {
                  setPurpose2([]);
                }
              }}
              overlayNoRowsTemplate = "검색된 내용이 없습니다."
            />
          </div>
        </section>

        <section className="fr" style={{width: 'calc(50% - 10px)'}}>
          <div className="ag-theme-balham" style={{height: '300px'}}>
            <AgGridReact
              defaultColDef={{
                sortable: true,
                resizable: true,
                filter: false,
                lockVisible: true,
                tooltipComponent: customTooltipAgGrid,
              }}
              tooltipShowDelay={0}
              tooltipHideDelay={2000}
              columnDefs={columnDefs2}
              rowData={purpose2}
              rowSelection={'multiple'}
              onGridReady={(event) => {
                setGridApi2(event.api);
              }}
              overlayNoRowsTemplate = "검색된 내용이 없습니다."
            />
          </div>
        </section>

        <div className="clearfix"></div>

        <section className="center mt10 mb10">
          <Button
            variant='outlined'
            color="primary"
            onClick={(event) => addPurpose()}
          >▼ 추가</Button>

          <Button
            variant='outlined'
            color="error"
            className="ml10"
            onClick={(event) => delPurpose()}
          >▲ 제거</Button>
        </section>

        <section className="">
          <div className="ag-theme-balham" style={{height: '300px'}}>
            <AgGridReact
              defaultColDef={{
                sortable: true,
                resizable: true,
                filter: false,
                lockVisible: true,
                tooltipComponent: customTooltipAgGrid,
              }}
              tooltipShowDelay={0}
              tooltipHideDelay={2000}
              columnDefs={columnDefs3}
              rowData={selectedPurpose1}
              rowSelection={'multiple'}
              onGridReady={(event) => {
                setGridApiSelected1(event.api);
              }}
              overlayNoRowsTemplate = "검색된 내용이 없습니다."
            />
          </div>
        </section>
      </section>

      <footer className="modal-footer">
        <div className="modal-footer-center">
          <Button
            color="inherit"
            variant="outlined"
            className="Btn"
            onClick={() => {
              props.close();
            }}
          >창닫기</Button>

          <Button
            variant="contained"
            color="primary"
            className="Btn ml10"
            onClick={() => submit()}
          >선택하기</Button>
        </div>
      </footer>
    </section>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default PurposeModal;