import React from 'react';
import Chip from '@mui/material/Chip';

const ChipEx = (props) => {
  return (
    <Chip
      variant={props.variant}
      size={props.size || 'small'}
      label={props.label}
      style={{...props.style}}
      className={props.className || ''}
      color={props.color}
      onClick={() => {
        if( props.onClick ) props.onClick();
      }}
    />
  );
};

export default ChipEx;