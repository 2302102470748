/*****************************************************************************************
 * 설명 : 관리자 > 시스템관리 > 시스템환경설정 > 추가/수정
 * URI : /admin/site
*****************************************************************************************/
import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import * as Yup from "yup";

import { MESSAGE_DELAY } from 'config/config';

import Restful from "service/restful";
import { setAccessIpApi } from 'service/system';

import { InputEx, ToggleButtonGroupEx } from 'components/inputEx';
import alertMsg from 'components/message';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
export const SystemConfigModal = ( props ) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [getApi] = Restful();

  const [title, setTitle] = useState('추가');

  const formik = useFormik({
    initialValues: {
      seq: 0,
      group: 0,
      ipAddress: '',
      memo: '',
      type: 0,
      isUse: 1
    },
    validationSchema: Yup.object().shape({
      ipAddress: Yup.string().max(50, "50자리").required('필수').nullable(),
      memo: Yup.string().max(200, "200자리"),
    }),
    onSubmit: (values) => {
      setAccessIpApi(getApi, values, (response => {
        if( response !== undefined && response.data.result ) {
          alertMsg(response.data.message, "S", MESSAGE_DELAY);

          if( props.getList ) props.getList();

          props.close();

        } else {
          alertMsg(response.data.message || '서버와의 통신에 실패하였습니다.', "E", MESSAGE_DELAY);
        }
      }));
    }
  });

  /***************************************************************************************
   * 설명 : 모달 오픈 시 처리
  ***************************************************************************************/
  useEffect(() => {
    if( props.open ) {
      if( props.data.seq !== undefined ) {
        formik.setValues(props.data);

        setTitle('수정');
      } else {
        setTitle('추가');
      }
    }

    // eslint-disable-next-line
  }, [props.open]);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <section className="modal-wrap" style={{ width: '500px' }}>
      <form onSubmit={formik.handleSubmit}>
        <header className="modal-header" style={{ cursor: 'move' }} id="draggable-dialog-title">
          <div className="modal-title">아이피 {title}</div>
          <Button className="close-btn" onClick={props.close}>
            <CloseIcon style={{ color: '#fff', fontSize: '28px' }}></CloseIcon>
          </Button>
        </header>

        <section className="modal-body">
          <table className="input-table">
            <colgroup>
              <col style={{width: '25%'}} />
              <col style={{width: '75%'}} />
            </colgroup>

            <tbody>
              <tr>
                <th scope="row"><label htmlFor="group">타입</label></th>
                <td>
                  <ToggleButtonGroupEx
                    name="group"
                    exclusive={true}
                    formik={formik}
                    data={[
                      {label: '아이피', value: 0},
                      {label: '아이디', value: 1},
                    ]}
                  />
                </td>
              </tr>
              <tr>
                <th scope="row"><label htmlFor="ipAddress">아이피주소(아이디)</label></th>
                <td>
                  <div>
                    <InputEx
                      name="ipAddress"
                      formik={formik}
                      focus={true}
                      fullWidth={true}
                      label={"IPv4, IPv6 형식"}
                    />
                  </div>
                  <div className="mt10">
                    <p>※ '*'의 경우 전체 차단/허용에 사용</p>
                    <p className="mt5">※ IP C/D Class 등을 적용할려면 192.168.0 등으로 작성</p>
                  </div>
                </td>
              </tr>
              <tr>
                <th scope="row"><label htmlFor="memo">메모</label></th>
                <td>
                  <InputEx
                    name="memo"
                    formik={formik}
                    focus={true}
                    fullWidth={true}
                  />
                </td>
              </tr>
              <tr>
                <th scope="row"><label htmlFor="type">구분</label></th>
                <td>
                  <ToggleButtonGroupEx
                    name="type"
                    exclusive={true}
                    formik={formik}
                    data={[
                      {label: '허용', value: 0},
                      {label: '차단', value: 1},
                    ]}
                  />
                </td>
              </tr>
              <tr>
                <th scope="row"><label htmlFor="isUse">사용여부</label></th>
                <td>
                  <ToggleButtonGroupEx
                    name="isUse"
                    exclusive={true}
                    formik={formik}
                    data={[
                      {label: '사용', value: 1},
                      {label: '사용안함', value: 0},
                    ]}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </section>

        <footer className="modal-footer">
          <div className="modal-footer-center">
            <Button
              color="inherit"
              variant="outlined"
              className="Btn"
              onClick={() => {
                props.close();
              }}
            >창닫기</Button>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              className="Btn ml10"
            >저장하기</Button>
          </div>
        </footer>
      </form>
    </section>
  )
}

export default SystemConfigModal;