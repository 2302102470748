import React, { useMemo } from 'react';

const customTooltipAgGrid = (props) => {

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const data = useMemo(() => {
    return props.api.getDisplayedRowAtIndex(props.rowIndex).data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className="custom-tooltip"
      style={{ backgroundColor: props.color || '#47669c' }}
    >
      <p className="custom-tooltip-title">
        <span>{props.column?.userProvidedColDef?.headerName}</span>
      </p>
      <p className="custom-tooltip-text">
        {data[props.column.colId]}
      </p>
    </div>
  );
};

export default customTooltipAgGrid;