/*****************************************************************************************
 * 설명 : 관리자 > 시스템관리 > 설계비 가산정 양식관리 > 외주용역비 업무구분 선택
*****************************************************************************************/
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { AgGridReact } from 'ag-grid-react';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';

import { getCostItemApi } from 'service/cost';
import { getProjectCostItemListApi } from 'service/costMember';
import Restful from "service/restful";
import { PaperComponentSub } from 'service/utils';

import AlertDialog from 'components/alertDiolog';
import { ButtonEx, InputEx } from 'components/inputEx';
import alertMsg from 'components/message';
import customTooltipAgGrid from 'components/tooltipAgGrid';

import { MESSAGE_DELAY } from 'config/config';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
export const WorkKindModal = ( props ) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [getApi] = Restful();

  const [list, setList] = useState([]);
  const [openModal, setOpenModal] = useState({open: false, modal: 'add', data: {}});

  const [gridApi, setGridApi] = useState({});

  // table column
  const [columnDefs] = useState([
    { headerName: '', field: 'seq', width: 40, cellClass: 'center', flex: 0,
      headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
    { headerName:'업무구분', field: 'title', width: 345, cellClass: 'cp' },
  ]);

  const formik = useFormik({
    initialValues: {
      searchText: '',
    },
    onSubmit: (values) => {
      getList();
    }
  });

  /***************************************************************************************
   * 설명 : 외주 용역비 리스트 가져오기
  ***************************************************************************************/
  const getList = () => {
    let params = {
      costMemberSeq: props.group?.seq,
      searchText: formik.values.searchText
    }

    getCostItemApi(getApi, params, (response) => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length ) {
        setList(response.data.data);
      } else {
        setList([]);
      }
    })
  }

  /***************************************************************************************
   * 설명 : 선택 저장 시 처리
  ***************************************************************************************/
  const submit = () => {
    let nodes = gridApi.getSelectedRows();

    if( nodes.length < 1 ) {
      alertMsg("추가할 항목을 선택하시기 바랍니다.", "W", MESSAGE_DELAY);
      return;
    }

    // 추가된 프로젝트 리스트의 값을 가져와서 저장
    if( props.projectList.length > 0 ) {

      // 선택된 프로젝트 서버에서 정보 가져오기
      let parameter = {
        list: nodes,
        projectNumberList: props.projectList
      }

      getProjectCostItemListApi(getApi, parameter, (response) => {
        if(response !== undefined && response.data.result && response.data.data && response.data.data.length > 1 ) {
          // 항목 바인딩 및 평균값 계산
          let tmp = [];
          nodes.forEach((item) => {
            let tmp2 = {...item};

            props.projectList.forEach((subItem) => {
              let tmp1 = response.data.data.filter((subItem2) => subItem.projectNumber === subItem2.projectNumber && subItem2.costItemSeq === item.seq);
              tmp2[subItem.projectNumber + '_Check'] = 1;
              tmp2[subItem.projectNumber] = ( tmp1.length > 0 ) ? tmp1[0].baseAmt : 0;
            });

            tmp.push(tmp2);
          });

          nodes = tmp;
        }

        props.close({
          result: true,
          data: nodes
        });
      });

    } else {
      props.close({
        result: true,
        data: nodes
      });
    }
  }

  /***************************************************************************************
   * 설명 : 데이터 로딩 처리
  ***************************************************************************************/
  useEffect(() => {
    if( props.open ) {
      getList();
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <section className="modal">
      <section className="modal-wrap" style={{ width: '450px' }}>
        <header className="modal-header" style={{ cursor: 'move' }} id="draggable-dialog-title">
          <div className="modal-title">외주용역비 업무구분 선택</div>
          <Button className="close-btn" onClick={() => props.close({result: false})}>
            <CloseIcon></CloseIcon>
          </Button>
        </header>

        <section className="modal-body">
          <form onSubmit={formik.handleSubmit}>
            <header>
              <InputEx
                name="searchText"
                formik={formik}
                placeholder="업무구분"
                fullWidth={false}
                style={{width: 'calc(100% - 65px)'}}
                className="search-input inputbox"
              />

              <ButtonEx
                type="submit"
                title="검색"
                auth="isWrite"
                variant="outlined"
                color="secondary"
                className="ml5"
              />
            </header>
          </form>

          <section className="mt5 ag-theme-balham grid">
            <div className="" style={{ height: '350px' }}>
              <AgGridReact
                defaultColDef={{
                  sortable: true,
                  resizable: true,
                  filter: false,
                  lockVisible: true,
                  tooltipComponent: customTooltipAgGrid,
                }}
                tooltipShowDelay={0}
                tooltipHideDelay={2000}
                rowSelection = {'multiple'}
                columnDefs={columnDefs}
                onGridReady={(event) => {
                  setGridApi(event.api);
                }}
                rowData={list}
                rowDragManaged={true}
                rowDragMultiRow={true}
                animateRows={true}
                overlayNoRowsTemplate = "검색된 내용이 없습니다."
              />
            </div>
          </section>
        </section>

        <footer className="modal-footer">
          <div className="modal-footer-center">
            <Button
              color="inherit"
              variant="outlined"
              className="Btn"
              onClick={() => {
                props.close({result: false});
              }}
            >창닫기</Button>

            <Button
              variant="contained"
              color="primary"
              className="Btn ml10"
              onClick={() => {
                let nodes = gridApi.getSelectedRows();

                if( nodes.length < 1 ) {
                  alertMsg("추가할 항목을 선택하시기 바랍니다.", "W", MESSAGE_DELAY);
                  return;
                }

                setOpenModal({open: true, modal: 'add', data: {}})
              }}
            >선택하기</Button>
          </div>
        </footer>
      </section>

      { openModal.open && openModal.modal === 'add' &&
        <Dialog
          open={openModal.open}
          onClose={() => setOpenModal({ open: false, modalName: 'dialog', data: openModal.data })}
          PaperComponent={PaperComponentSub}
          aria-labelledby="draggable-dialog-title-sub"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "450px",  // Set your width here
              },
            },
          }}
        >
          <AlertDialog
            open={openModal.open}
            close={() => setOpenModal({ open: false, modalName: 'dialog', data: openModal.data })}
            title="설계비가산정 추가"
            message={`선택하신 항목을 설계비가산정에 추가하시겠습니까?`}
            confirm="추가하기"
            color="primary"
            onClick={() => submit()}
            indicator={props.indicator}
            history={props.history}
          />
        </Dialog>
      }
    </section>
  )
}

export default WorkKindModal;