/*****************************************************************************************
 * 설명 : 관리자 > 시스템관리 > 권한그룹관리
*****************************************************************************************/
import { AgGridReact } from 'ag-grid-react';
import { useFormik } from 'formik';
import 'moment/locale/ko';
import { useEffect, useState } from 'react';
import SplitPane from "react-split-pane";

import BtnAgGridSave from 'components/btnAgGridSave';
import customTooltipAgGrid from 'components/tooltipAgGrid';

import useGridConfig from 'hooks/useGridConfig';

import { getDepartmentListApi } from 'service/member';
import Restful from 'service/restful';
import { selectGrid } from 'service/utils';

import Layout from '../../../layout/layout';
import TabOrganizationMenu from './tabOrganizationMenu';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
function AuthOrganization(props) {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [getApi] = Restful();

  const [gridApi, setGridApi] = useState({});

  // 탭 클릭 시 처리
  const [departmentList, setDepartmentList] = useState([]);

  const [selected, setSelected] = useState([]);

  const formik = useFormik({
    initialValues: {
      siteSeq: null,
    },
    onSubmit: (values) => {
      //onRowClickedGroup();
    }
  });

  // 권한그룹 컬럼 선언
  const [columnDefsGroup, setColumnDefsGroup] = useState([
    { headerName: '', field: 'seq', cellClass: 'cp', width: 36, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
    { headerName: '부서명', field: 'departmentName', width: 250, cellClass: 'cp',
      valueGetter: (params) => {
        let level = Math.round(params.data.sortOrder?.length / 3) - 1;
        let space = '';
        for(let i = 0; i < level; i++) space += 'ㅤㅤ';

        return space + params.data.departmentName;
      }
    },
  ]);

  const [gridConfigGroup, setGridUpdateGroup] = useGridConfig(1, setColumnDefsGroup);

  /***************************************************************************************
   * 설명 : 조직도 리스트 데이터 가져오기
  ***************************************************************************************/
  const getDepartmentList = () => {
    let params = formik.values;

    getDepartmentListApi(getApi, params, (response) => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        setDepartmentList(response.data.data);
      } else {
        setDepartmentList([]);
      }
    });
  }

  /***************************************************************************************
   * 설명 : 조직별 그룹 새로 고침 시 자동 선택
  ***************************************************************************************/
  useEffect(() => {
    if( departmentList.length > 0 ) {
      selectGrid(gridApi, selected, setSelected, false);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departmentList]);

  /***************************************************************************************
   * 설명 : 권한 그룹 리스트 가져오기
  ***************************************************************************************/
  useEffect( () => {
    getDepartmentList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <Layout>
      <section className="admin-cms-contents system-page">
        <SplitPane split="vertical" minSize={250} defaultSize={320}>
          <div className="mr10">
            <header className="admin-cms-search-header">
              <div className="left">
                <div className="fl">
                  <BtnAgGridSave className="fl" click={setGridUpdateGroup}></BtnAgGridSave>
                </div>
                <div className="fl">
                  <h4 className="f15 sub-title">조직별 그룹</h4>
                </div>
              </div>
              <div className="text-right">
              </div>
            </header>

            <section className="ag-theme-balham grid mt10 pad-height165" style={{height:'calc(100vh - 130px)'}}>
              <div style={{ height: '100%' }}>
                <AgGridReact
                  defaultColDef={{
                    sortable: true,
                    resizable: true,
                    filter: false,
                    lockVisible: true,
                    tooltipComponent: customTooltipAgGrid,
                  }}
                  tooltipShowDelay={0}
                  tooltipHideDelay={2000}
                  rowSelection={'single'}
                  columnDefs={columnDefsGroup}
                  rowData={departmentList}
                  onGridReady={(event) => {
                    gridConfigGroup(event.columnApi);
                    setGridApi(event.api);
                  }}
                  rowDragManaged={true}
                  rowDragMultiRow={true}
                  animateRows={true}
                  onFirstDataRendered={(event) => {
                    event.api.forEachNode((item, index) => {
                      if(index === 0) {
                        item.setSelected(true);
                        setSelected([item.data]);
                      }
                    })
                  }}
                  onRowClicked={(event) => {
                    let nodes = event.api.getSelectedRows();
                    setSelected(nodes);
                  }}
                  overlayNoRowsTemplate = "검색된 내용이 없습니다."
                />
              </div>
            </section>
          </div>
          <div className="Tab-page">
            <div className="tab-contents">
              <TabOrganizationMenu
                selected={selected}
               />
            </div>
          </div>
        </SplitPane>
      </section>
    </Layout>
  )
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default AuthOrganization;