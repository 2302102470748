/*****************************************************************************************
 * 설명 : 통계관리 > 검색어 통계
 * URI : /statistic/search
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import { AgGridReact } from 'ag-grid-react';
import { useFormik } from 'formik';
import * as moment from 'moment';
import { useEffect, useState } from 'react';
import * as Yup from "yup";

import Restful from 'service/restful';
import { getStatisticSearchApi } from 'service/statistic';

import { BtnRefresh, BtnSearch } from 'components/common';
import { InputEx } from 'components/inputEx';
import customTooltipAgGrid from 'components/tooltipAgGrid';

import Layout from 'pages/layout/layout';

import 'assets/css/pages/statistic.scss';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const StatisticSearch = (props) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [getApi] = Restful();

  const [list1, setList1] = useState([]);
  const [list2, setList2] = useState([]);
  const [list3, setList3] = useState([]);
  const [list4, setList4] = useState([]);

  const initialValues = {
    startDate: moment().format('YYYY-MM'),
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object().shape({
    }),
    onSubmit: (values) => {
      getList();
    }
  });

  // table column
  const [columnDefs] = useState([
    { headerName:'순위', field: 'rankNo', width: 50, cellClass: 'cp center' },
    { headerName:'검색어', field: 'word', flex: 1, width: 300, cellClass: 'cp' },
    { headerName:'이전순위', field: 'prevRankNo', width: 100, cellClass: 'cp center',
      cellRendererFramework: function(params) {
        if( parseInt(params.data.prevRankNo) === 0 ) {
          return '진입';

        } if( parseInt(params.data.rankNo) > parseInt(params.data.prevRankNo) ) {
          return <span className="cblue">▼ {params.data.prevRankNo}</span>

        } else if( parseInt(params.data.rankNo) < parseInt(params.data.prevRankNo) ) {
          return <span className="cred">▲ {params.data.prevRankNo}</span>

        } else if( parseInt(params.data.rankNo) === parseInt(params.data.prevRankNo) ) {
          return '-';

        } else {
          return '';
        }
      }
    },
  ]);

  /***************************************************************************************
   * 설명 : 접속 정보 리스트 가져오기 - 접속기기, 테블릿기기별, 모바일기기별, 기간 접속내역
  ***************************************************************************************/
  const getList = () => {
    let params = {...formik.values};

    getStatisticSearchApi(getApi, params, (response) => {
      if( response !== undefined && response.data.result ) {
        // 이전달 검색어
        if( response.data.data && response.data.data.length > 0 )
          setList1(response.data.data);
        else
          setList1([]);

        // 이번달 검색어
        if( response.data.data1 && response.data.data1.length > 0 )
          setList2(response.data.data1);
        else
          setList2([]);

        // 이전년도 검색어
        if( response.data.data2 && response.data.data2.length > 0 )
          setList3(response.data.data2);
        else
          setList3([]);

        // 이번년도 검색어
        if( response.data.data1 && response.data.data1.length > 0 )
          setList4(response.data.data1);
        else
          setList4([]);

      } else {
        setList1([]);
        setList2([]);
        setList3([]);
        setList4([]);
      }
    });
  }

  /***************************************************************************************
   * 설명 : 데이터 로딩
  ***************************************************************************************/
  useEffect(() => {
    getList();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.startDate]);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <Layout>
      <section className="admin-cms-contents">
        <form onSubmit={formik.handleSubmit}>
          <header className="mt10">
            <div className="mb5">
              <BtnRefresh click={() => {
                formik.setValues(initialValues);
                formik.handleSubmit();
              }}></BtnRefresh>
              <BtnSearch></BtnSearch>

              <InputEx
                type="month"
                name="startDate"
                formik={formik}
                fullWidth={false}
                style={{width: '120px'}}
                label="검색 시작일"
              />
            </div>
          </header>
        </form>

        {/* 접속 통계 */}
        <section className="search-area">
          <section className="mt5 ag-theme-balham grid" style={{minWidth: '25%', height:'calc(100vh - 150px)'}}>
            <h3 className="mb10">지난달({moment(formik.values.startDate).add(-1, 'month').format('YYYY년 MM월')}) 검색어 순위</h3>
            <div className="" style={{ height: '100%'}}>
              <AgGridReact
                defaultColDef={{
                  sortable: true,
                  resizable: true,
                  filter: false,
                  lockVisible: true,
                  tooltipComponent: customTooltipAgGrid,
                }}
                tooltipShowDelay={0}
                tooltipHideDelay={2000}
                rowSelection = {'single'}
                columnDefs={columnDefs}
                rowData={list1}
                rowDragManaged={true}
                rowDragMultiRow={true}
                animateRows={true}
                overlayNoRowsTemplate = "검색된 내용이 없습니다."
              />
            </div>
          </section>

          <section className="mt5 ag-theme-balham grid" style={{minWidth: '25%', height:'calc(100vh - 150px)'}}>
            <h3 className="mb10">이번달({moment(formik.values.startDate).format('YYYY년 MM월')}) 검색어 순위</h3>
            <div className="" style={{ height: '100%'}}>
              <AgGridReact
                defaultColDef={{
                  sortable: true,
                  resizable: true,
                  filter: false,
                  lockVisible: true,
                  tooltipComponent: customTooltipAgGrid,
                }}
                tooltipShowDelay={0}
                tooltipHideDelay={2000}
                rowSelection = {'single'}
                columnDefs={columnDefs}
                rowData={list2}
                rowDragManaged={true}
                rowDragMultiRow={true}
                animateRows={true}
                overlayNoRowsTemplate = "검색된 내용이 없습니다."
              />
            </div>
          </section>

          <section className="mt5 ag-theme-balham grid" style={{minWidth: 'calc(25% - 10px)', height:'calc(100vh - 150px)'}}>
            <h3 className="mb10">지난년도({moment(formik.values.startDate).add(-1, 'year').format('YYYY년')}) 검색어 순위</h3>
            <div className="" style={{ height: '100%'}}>
              <AgGridReact
                defaultColDef={{
                  sortable: true,
                  resizable: true,
                  filter: false,
                  lockVisible: true,
                  tooltipComponent: customTooltipAgGrid,
                }}
                tooltipShowDelay={0}
                tooltipHideDelay={2000}
                rowSelection = {'single'}
                columnDefs={columnDefs}
                rowData={list3}
                rowDragManaged={true}
                rowDragMultiRow={true}
                animateRows={true}
                overlayNoRowsTemplate = "검색된 내용이 없습니다."
              />
            </div>
          </section>

          <section className="mt5 ag-theme-balham grid" style={{minWidth: '25%', height:'calc(100vh - 150px)'}}>
            <h3 className="mb10">이번년도({moment(formik.values.startDate).format('YYYY년')}) 검색어 순위</h3>
            <div className="" style={{ height: '100%'}}>
              <AgGridReact
                defaultColDef={{
                  sortable: true,
                  resizable: true,
                  filter: false,
                  lockVisible: true,
                  tooltipComponent: customTooltipAgGrid,
                }}
                tooltipShowDelay={0}
                tooltipHideDelay={2000}
                rowSelection = {'single'}
                columnDefs={columnDefs}
                rowData={list4}
                rowDragManaged={true}
                rowDragMultiRow={true}
                animateRows={true}
                overlayNoRowsTemplate = "검색된 내용이 없습니다."
              />
            </div>
          </section>

          <section className="mt5 ag-theme-balham grid" style={{minWidth: '25%', height:'calc(100vh - 150px)'}}>
            <h3 className="mb10">이번년도 검색어 순위</h3>
            <div className="" style={{ height: '100%'}}>
              <AgGridReact
                defaultColDef={{
                  sortable: true,
                  resizable: true,
                  filter: false,
                  lockVisible: true,
                  tooltipComponent: customTooltipAgGrid,
                }}
                tooltipShowDelay={0}
                tooltipHideDelay={2000}
                rowSelection = {'single'}
                columnDefs={columnDefs}
                rowData={list1}
                rowDragManaged={true}
                rowDragMultiRow={true}
                animateRows={true}
                overlayNoRowsTemplate = "검색된 내용이 없습니다."
              />
            </div>
          </section>
        </section>
      </section>
    </Layout>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default StatisticSearch;