import { saveAs } from "file-saver";
import * as XLSX from 'xlsx';
import * as moment from 'moment';

const ExcelExport = async (gridApi, title) => {
  let selectedRows = [];

  gridApi.forEachNode((node) => {
    selectedRows.push(node.data)
  });

  const obj = gridApi.rowModel.nodeManager.allNodesMap;
  const header = gridApi.columnModel.gridColumns;
  const columnWidth = [];
  const dataList = [];

  for (const index in selectedRows) {
    let tmp = {};
    for (let i in header) {
      // 제외 필드
      if( [
        'sortOrder', 'rowIndex',
        'creator', 'creatorName', 'createDate',
        'updater', 'updaterName', 'updateDate'
      ].includes(header[i].colDef.field) ) continue;

      tmp[header[i].colDef.headerName] = obj[index].data[ header[i].colDef.field ];
      columnWidth.push({ wpx: header[i].actualWidth });
    };

    dataList.push(tmp);
  };

  let worksheet = XLSX.utils.aoa_to_sheet([['data']]);
  // 제목 제외 처리
  worksheet = XLSX.utils.sheet_add_aoa(worksheet, [[title || '']]);
  // 색상변경 등은 프로 유료 버전에서만 지원
  worksheet['data'] = {
    v: title,
    t: "s",
    a: { alignment: 'center', font: {size: 14, bold: true, color: '#FF00FF' }}};

  /*
    s: { alignment: {textRotation: 90 },
    font: {sz: 14, bold: true, color: #FF00FF }
  */

  let index = 0;
  for (let item of dataList) {
    for (let key in item) {
      if (isNumeric(item[key])) {
        dataList[index][key] = parseInt(String(dataList[index][key]).replace(',',''));
      };
    };
    index++;
  };

  // 데이터 추가
  worksheet = XLSX.utils.sheet_add_json( worksheet, dataList, {origin: "A1"} );

  // 워크북 생성
  const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };

  // 컬럼 사이즈 지정
  worksheet['!cols'] = columnWidth;
  /*
  열 머지 처리
  worksheet['!merges'] = [
    {s:{r:0,c:0},e:{r:0,c: header.length } },
    {s:{r:1,c:0},e:{r:1,c: header.length } }
  ];
  */

  // 엑셀 버퍼로 보내기
  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

  // 파일로 저장
  const excelFileName = !title ? moment().format('YYYYMMDD') + '.xlsx' : title + "_" + moment().format('YYYYMMDD') + '.xlsx';
  const data = new Blob([excelBuffer], {type: excelFileName});
  saveAs( data, excelFileName );
};


const isNumeric = (num, opt = null) => {
  // 좌우 trim(공백제거)을 해준다.
  num = String(num).replace(/^\s+|\s+$/g, "");
  let regex = '';

  // 모든 10진수 (부호 선택, 자릿수구분기호 선택, 소수점 선택)
  if(typeof opt === "undefined" || opt === null || opt === "1") {
    regex = /^[+-]?(([1-9][0-9]{0,2}(,[0-9]{3})*)|[0-9]+){1}(\.[0-9]+)?$/g;

  // 부호 미사용, 자릿수구분기호 선택, 소수점 선택
  } else if(opt === "2") {
    regex = /^(([1-9][0-9]{0,2}(,[0-9]{3})*)|[0-9]+){1}(\.[0-9]+)?$/g;

  // 부호 미사용, 자릿수구분기호 미사용, 소수점 선택
  } else if(opt === "3") {
    regex = /^[0-9]+(\.[0-9]+)?$/g;

  // only 숫자만(부호 미사용, 자릿수구분기호 미사용, 소수점 미사용)
  } else {
    regex = /^[0-9]$/g;
  };

  if( regex.test(num) ) {
    num = num.replace(/,/g, "");
    return isNaN(num) ? false : true;
  } else {
    return false;
  }
};

export default ExcelExport;