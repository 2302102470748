/*****************************************************************************************
 * 설명 : 프로젝트관리 - 용도
 * URI : /project
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import Button from '@mui/material/Button';
import { AgGridReact } from 'ag-grid-react';
import { useEffect, useState } from 'react';

import { MESSAGE_DELAY } from 'config/config';

import { comma } from 'service/utils';

import alertMsg from 'components/message';
import customTooltipAgGrid from 'components/tooltipAgGrid';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const StructureModal = (props) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [structure1, setstructure1] = useState([]);
  const [structure2, setstructure2] = useState([]);
  const [selectedstructure1, setSelectedstructure1] = useState([]);

  const [gridApi1, setGridApi1] = useState({});
  const [gridApi2, setGridApi2] = useState({});
  const [gridApiSelected1, setGridApiSelected1] = useState({});

  /***************************************************************************************
   * 설명 : 업체 검색
  ***************************************************************************************/
  // table column
  const [columnDefs1] = useState([
    { headerName: '', field: 'seq', width: 35, cellClass: 'center', flex: 0,
      headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
    { headerName: '순번', field: 'rowIndex', width: 45, cellClass: 'cp text-right',
      valueGetter: (params) => {
        return comma(params.node.rowIndex + 1);
      }
    },
    { headerName: '용도 1단계', field: 'commonCode', hide: true },
    { headerName: '용도 1단계', field: 'commonName', width: 250, cellClass: "cp" },
  ]);

  // table column
  const [columnDefs2] = useState([
    { headerName: '', field: 'seq', width: 35, cellClass: 'center', flex: 0,
      headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
    { headerName: '순번', field: 'rowIndex', width: 45, cellClass: 'cp text-right',
      valueGetter: (params) => {
        return comma(params.node.rowIndex + 1);
      }
    },
    { headerName: '용도 2단계', field: 'commonCode', hide: true },
    { headerName: '용도 2단계', field: 'commonName', width: 250, cellClass: "cp" },
  ]);

  // table column
  const [columnDefs3] = useState([
    { headerName: '', field: 'seq', width: 35, cellClass: 'center', flex: 0,
      headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
    { headerName: '순번', field: 'rowIndex', width: 45, cellClass: 'cp text-right',
      valueGetter: (params) => {
        return comma(params.node.rowIndex + 1);
      }
    },
    { headerName: '용도 1단계', field: 'structure1Code', hide: true },
    { headerName: '용도 1단계', field: 'structure1Name', width: 250, cellClass: "cp" },
    { headerName: '용도 2단계', field: 'structure2Code', hide: true },
    { headerName: '용도 2단계', field: 'structure2Name', width: 250, cellClass: "cp" },
  ]);

  /***************************************************************************************
   * 설명 : 선택된 용도 추가
  ***************************************************************************************/
  const addstructure = () => {
    let nodes = gridApi1.getSelectedRows();

    if( nodes.length < 1 ) {
      alertMsg("추가할 용도를 선택하시기 바랍니다.", "W", MESSAGE_DELAY);
      return;
    }

    let nodes1 = gridApi2.getSelectedRows();

    if( structure2.length > 0 && nodes1.length < 1 ) {
      alertMsg("추가할 용도를 선택하시기 바랍니다.", "W", MESSAGE_DELAY);
      return;
    }

    // 2단계가 없을 경우 1단계를 추가
    if( structure2.length < 1 ) {
      // filter
      let check = selectedstructure1.filter((item) => item.structure1Code === nodes[0].commonCode );
      if( check.length > 0 ) return;

      let tmp = [...selectedstructure1];

      tmp.push({
        seq: nodes[0].seq,
        structure1Code: nodes[0].commonCode,
        structure1Name: nodes[0].commonName,
        structure2Code: '',
        structure2Name: ''
      });
      setSelectedstructure1(tmp);

    // 정상적인 경우 1단계를 추가
    } else {
      let tmp = [...selectedstructure1];

      let check = selectedstructure1.filter((item) => {
        return item.structure1Code === nodes[0].commonCode && item.structure2Code === nodes1[0].commonCode;
      });

      if( check.length > 0 ) return;

      nodes1.forEach((item) => {
        tmp.push({
          seq: item.seq,
          structure1Code: nodes[0].commonCode,
          structure1Name: nodes[0].commonName,
          structure2Code: item.commonCode,
          structure2Name: item.commonName,
        });
      });

      setSelectedstructure1(tmp);
    }
  }

  /***************************************************************************************
   * 설명 : 선택된 용도 제거
  ***************************************************************************************/
  const delstructure = () => {
    let nodes = gridApiSelected1.getSelectedRows();

    if( nodes.length < 1 ) {
      alertMsg("제거할 용도를 선택하시기 바랍니다.", "W", MESSAGE_DELAY);
      return;
    }

    let tmp = selectedstructure1.filter((item) => {
      let check = true;
      nodes.forEach((subItem) => {
        if( item.seq === subItem.seq ) check = false;
      });

      return check;
    });

    setSelectedstructure1(tmp);
  }

  /***************************************************************************************
   * 설명 : 저장 시 처리
  ***************************************************************************************/
  const submit = () => {
    let nodes = [];

    gridApiSelected1.forEachNode((item) => {
      nodes.push(item.data);
    })

    if( nodes.length < 1 ) {
      alertMsg("추가할 용도를 선택하시기 바랍니다.", "W", MESSAGE_DELAY);
      return;
    }

    let tmpCode = '';
    let tmpName = '';

    tmpCode = nodes.map((item) => {
      if( item.structure2Code === '' ) return item.structure1Code;
      else return item.structure2Code;
    }).join();

    tmpName = nodes.map((item) => {
      if( item.structure2Code === '' ) return item.structure1Name;
      else return item.structure1Name + '(' + item.structure2Name + ')';
    }).join();

    props.formik.setFieldValue('structure1Code', tmpCode);
    props.formik.setFieldValue('structure1', tmpName);

    props.close();
  }

  /***************************************************************************************
   * 설명 : 리스트 바인딩 시 선택된 데이터 표시
  ***************************************************************************************/
  useEffect(() => {
    if( props?.formik.values.structure1 !== '' ) {
      let tmp = props.formik.values.structure1Code?.split(/,/gi);

      if( tmp !== undefined && tmp.length > 0 ) {
        let tmp1 = [];
        tmp.forEach((item) => {
          // 1단계인 경우
          if( item.length === 3 ) {
            let tmp2 = props.structure1.filter((subItem) => subItem.value === item);

            if( tmp2.length > 0 ) tmp1.push({
              seq: tmp2[0].seq,
              structure1Code: tmp2[0].commonCode,
              structure1Name: tmp2[0].commonName,
              structure2Code: '',
              structure2Name: ''
            });

          // 2단계인 경우
          } else {
            let tmp2 = props.structure2.filter((subItem) => subItem.value === item);

            if( tmp2.length > 0 ) {
              let tmp3 = props.structure1.filter((subItem) => subItem.value === tmp2[0].commonCode.substr(0, 3));

              // 1단계 값 가져오기
              tmp1.push({
                seq: tmp2[0].seq,
                structure1Code: tmp3[0].commonCode,
                structure1Name: tmp3[0].commonName,
                structure2Code: tmp2[0].commonCode,
                structure2Name: tmp2[0].commonName
              });
            }
          }
        });

        setSelectedstructure1(tmp1);
      }

    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.structure1]);

  /***************************************************************************************
   * 설명 : 팝업 시 처리
  ***************************************************************************************/
  useEffect(() => {
    if( props?.structure1?.length > 0 )
      setstructure1(props.structure1);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <section className="modal-wrap" style={{width: '100%'}}>
      <header className="modal-header" style={{ cursor: 'move' }} id="draggable-dialog-title">
        <div className="modal-title">구조 선택</div>
        <Button className="close-btn" onClick={props.close}>×</Button>
      </header>

      <div className="clearfix"></div>

      <section className="modal-body">
        <section className="w50per fl">
          <div className="ag-theme-balham" style={{height: '300px'}}>
            <AgGridReact
              defaultColDef={{
                sortable: true,
                resizable: true,
                filter: false,
                lockVisible: true,
                tooltipComponent: customTooltipAgGrid,
              }}
              tooltipShowDelay={0}
              tooltipHideDelay={2000}
              columnDefs={columnDefs1}
              rowData={structure1}
              rowSelection={'single'}
              onGridReady={(event) => {
                setGridApi1(event.api);
              }}
              onSelectionChanged={(event) => {
                let nodes = event.api.getSelectedRows();

                if( nodes.length > 0 ) {
                  setstructure2(props.structure2.filter((item) => item.value.substr(0, 3) === nodes[0].value))
                } else {
                  setstructure2([]);
                }
              }}
              overlayNoRowsTemplate = "검색된 내용이 없습니다."
            />
          </div>
        </section>

        <section className="fr" style={{width: 'calc(50% - 10px)'}}>
          <div className="ag-theme-balham" style={{height: '300px'}}>
            <AgGridReact
              defaultColDef={{
                sortable: true,
                resizable: true,
                filter: false,
                lockVisible: true,
                tooltipComponent: customTooltipAgGrid,
              }}
              tooltipShowDelay={0}
              tooltipHideDelay={2000}
              columnDefs={columnDefs2}
              rowData={structure2}
              rowSelection={'multiple'}
              onGridReady={(event) => {
                setGridApi2(event.api);
              }}
              overlayNoRowsTemplate = "검색된 내용이 없습니다."
            />
          </div>
        </section>

        <div className="clearfix"></div>

        <section className="center mt10 mb10">
          <Button
            variant='outlined'
            color="primary"
            onClick={(event) => addstructure()}
          >▼ 추가</Button>

          <Button
            variant='outlined'
            color="error"
            className="ml10"
            onClick={(event) => delstructure()}
          >▲ 제거</Button>
        </section>

        <section className="">
          <div className="ag-theme-balham" style={{height: '300px'}}>
            <AgGridReact
              defaultColDef={{
                sortable: true,
                resizable: true,
                filter: false,
                lockVisible: true,
                tooltipComponent: customTooltipAgGrid,
              }}
              tooltipShowDelay={0}
              tooltipHideDelay={2000}
              columnDefs={columnDefs3}
              rowData={selectedstructure1}
              rowSelection={'multiple'}
              onGridReady={(event) => {
                setGridApiSelected1(event.api);
              }}
              overlayNoRowsTemplate = "검색된 내용이 없습니다."
            />
          </div>
        </section>
      </section>

      <footer className="modal-footer">
        <div className="modal-footer-center">
          <Button
            color="inherit"
            variant="outlined"
            className="Btn"
            onClick={() => {
              props.close();
            }}
          >창닫기</Button>

          <Button
            variant="contained"
            color="primary"
            className="Btn ml10"
            onClick={() => submit()}
          >선택하기</Button>
        </div>
      </footer>
    </section>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default StructureModal;