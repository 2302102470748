/*****************************************************************************************
 * 설명 : 메뉴 리스트
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import React from 'react';

export const initMenuList = [
  {
    id: 1, title: '대시보드', icon: 'bx bxs-dashboard',  url: '/admin/dashboard',
    submenu: []
  },
  {
    id: 2, title: '프로젝트관리', icon: 'ri-folder-2-line',  url: '/admin/project',
    submenu: [
      { id: 21, title: '일반 프로젝트', url: '/admin/project' },
      { id: 22, title: '디자인 프로젝트', url: '/admin/project/design' },
      { id: 23, title: '권한그룹별 프로젝트 권한', url: '/admin/project/auth/group' },
      { id: 24, title: '조직별 프로젝트 권한', url: '/admin/project/auth/organization' },
      { id: 25, title: '직위별 프로젝트 권한', url: '/admin/project/auth/position' },
      { id: 26, title: '사용자별 프로젝트 권한', url: '/admin/project/auth/employee' },
    ]
  },
  {
    id: 3, title: '설계비가산정', icon: 'bx bx-math',  url: '',
    submenu: [
      { id: 11, title: '설계비가산정', url: '/admin/cost' },
      { id: 12, title: '설계비가산정양식관리', url: '/admin/cost/form' },
    ]
  },
  {
    id: 4, title: '열람관리', icon: 'ri-archive-drawer-line',  url: '/admin/reading',
    submenu: []
  },
  {
    id: 5, title: '통계조회', icon: 'ri-bar-chart-box-line',  url: '',
    submenu: [
      { id: 51, title: '시스템접속통계', url: '/' },
      { id: 52, title: '검색통계', url: '/' },
      { id: 53, title: '열람통계', url: '/' },
    ]
  },
  {
    id: 6, title: '로그조회', icon: 'ri-file-search-line',  url: '',
    submenu: [
      { id: 61, title: '로그데이터조회', url: '/admin/log/logData' },
      { id: 62, title: '프로젝트별 로그 조회', url: '/admin/log/project' },
      { id: 63, title: '그룹웨어 동기화 로그 조회', url: '/admin/log/groupware' },
      { id: 64, title: 'PMS 동기화 로그 조회', url: '/admin/log/pms' },
    ]
  },
  {
    id: 8, title: '시스템관리', icon: 'ri-pencil-ruler-2-line',  url: '',
    submenu: [
      { id: 81, title: '사용자관리', url: '/admin/system/user' },
      { id: 83, title: '공통코드관리', url: '/admin/sytem/common' },
      { id: 84, title: '시스템환경설정', url: '/admin/system/config' },
      { id: 85, title: '논리스토리지관리', url: '/admin/system/logicstorage' },
      { id: 86, title: '권한그룹별 메뉴권한', url: '/admin/system/menu/group' },
      { id: 87, title: '조직별 메뉴권한', url: '/admin/system/menu/organization' },
      { id: 88, title: '직위별 메뉴권한', url: '/admin/system/menu/position' },
      { id: 89, title: '사용자별 메뉴권한', url: '/admin/system/menu/employee' },
    ]
  },
  {
    id: 9, title: 'CMS관리', icon: 'ri-stack-line',  url: '',
    submenu: [
      { id: 91, title: '사이트관리', url: '/admin/cms/site' },
      { id: 92, title: '모듈관리', url: '/admin/cms/module' },
      { id: 93, title: '게시판', url: '/admin/cms/board' },
      { id: 94, title: '메뉴관리', url: '/admin/cms/menuList' },
      // { id: 75, title: '팝업관리', url: '/cms/popup' },
      // { id: 76, title: '배너관리', url: '/cms/banner' }
    ]
  },
  {
    id: 9, title: '사용자메뉴', icon: 'ri-folder-2-line',  url: '/search',
    submenu: []
  },
];

/*****************************************************************************************
 * 설명 : context 선언부
*****************************************************************************************/
export const GlobalMenu = React.createContext({
  menuList: [],
  setMenuList: () => {},

  menuIndex: -1,
  setMenuIndex: () => {},

  menuReduce: '',
  setMenuReduce: () => {}
});

/*****************************************************************************************
 * 설명 : 디폴트 선언
*****************************************************************************************/
export default GlobalMenu;