/*****************************************************************************************
 * 설명 : 대시보드
 * URI : /dashboard
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';

import ChipEx from 'components/chipEx';
import DashboardCharts from "components/dashboardCharts";
import Layout from 'pages/layout/layout';

import { getDashboardInfoApi } from 'service/dashboard';
import Restful from "service/restful";
import { comma, formatBytes } from 'service/utils';

/*
import userImg01 from 'assets/images/sample/user_image01.png';
import userImg02 from 'assets/images/sample/user_image02.png';
import userImg03 from 'assets/images/sample/user_image03.png';
import userImg04 from 'assets/images/sample/user_image04.png';
import userImg05 from 'assets/images/sample/user_image05.png';
import userImg06 from 'assets/images/sample/user_image06.png';
import userImg07 from 'assets/images/sample/user_image07.png';
import userImg08 from 'assets/images/sample/user_image08.png';
*/
import noImg from 'assets/images/no_image.png';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const Dashboard = ( props ) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const currentMenu = useSelector(state => state?.menuList?.current);

  const [getApi] = Restful();
  const history = useNavigate();

  const widgetInitialValues = {
    projectCount: 0,
    fileCount: 0,
    logicalDriveCount: 0,
    logicalDriveUse: 0,
    logicalDriveSize: 0,
    logicalDriveMaxSize: 0,
    groupwareSyncDate: '',
    pmsSyncDate: '',
  }

  const [widget, setWidget] = useState(widgetInitialValues);

  const [driveList, setDriveList] = useState([]);
  const [requestList, setRequestList] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [searchList, setSearchList] = useState([]);

  /***************************************************************************************
   * 설명 : 공지사항 리스트 표시
  ***************************************************************************************
  const BoardContent = (data) => {
    const className = moment(data.date).format('ddd') === '토' ? ' sat' : moment(data.date).format('ddd') === '일' ? ' sun' : '';

    return (
      <li>
        <NavLink to="/">
          <div className={"th" + className}>
            <p>{moment(data.date).format('DD')}</p>
            <p>{moment(data.date).format('dddd')}</p>
          </div>
          <div className="td">
            <div className="date">{data.date}</div>
            <div className="title">{data.title}</div>
          </div>
        </NavLink>
      </li>
    )
  }

  /***************************************************************************************
   * 설명 : 논리 드라이브 정보
  ***************************************************************************************/
  const DriveInfo = (props) => {
    let className = '';
    if( props.data.percent < 50) className = 'bg-green';
    else if( props.data.percent >= 50 && props.data.percent < 80) className = 'bg-blue';
    else className = 'bg-red';

    return (
      <li className="progress-wrap">
        <div className="progress-title clearfix">
          <div className="fl">
            {props.data.driveName}
            <span>
              <span className="path">{props.data.pathView}</span>
              ({formatBytes(props.data.maxSize)} 중 {formatBytes(props.data.maxSize - props.data.currSize)} 사용 가능)
            </span>
          </div>
          <div className="fr">{props.data.percent}%</div>
        </div>
        <div className="progress">
          <div className={"progress-bar " + className} style={{ width: props.data.percent + '%' }}></div>
        </div>
      </li>
    );
  }

  /***************************************************************************************
   * 설명 : 데이터 가져오기
  ***************************************************************************************/
  const getInfo = () => {
    getDashboardInfoApi(getApi, (response) => {
      if( response !== undefined && response.data.result ) {
        setWidget(response.data.data ?? widgetInitialValues);

        if( response.data.data1 ) {
          setDriveList([
            ...response.data.data1.map((item) => {
              let percent = Math.round(parseInt(item.currSize) / parseInt(item.maxSize), 3);
              return {
                ...item,
                percent: percent
              }
            })
          ]);
        } else {
          setDriveList([]);
        }

        setRequestList(response.data.data2 ?? []);
        setProjectList(response.data.data3 ?? []);
        setSearchList(response.data.data4 ?? []);

      } else {
        setWidget(widgetInitialValues);
        setRequestList([]);
        setProjectList([]);
        setSearchList([]);
      }
    });
  }

  /***************************************************************************************
   * 설명 : 데이터 로딩 시 처리
  ***************************************************************************************/
  useEffect(() => {
    getInfo();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
   <Layout>
      <section className="dashboard clearfix">
        { // 위젯
          parseInt(currentMenu?.isWrite) === 1 &&
          <section className="card">
            <div className="card-body">
              <ul className="widget">
                <li>
                  <div className="widget-title">등록된 프로젝트 개수</div>
                  <div className="widget-value clearfix">
                    <div className="icon bg-green"><i className="ri-folder-2-line"></i></div>
                    <div className="value">{comma(widget.projectCount)}</div>
                  </div>
                  <NavLink to="/project" className="link">프로젝트 관리 바로가기</NavLink>
                </li>
                <li>
                  <div className="widget-title">등록된 파일 수</div>
                  <div className="widget-value clearfix">
                    <div className="icon bg-lblue"><i className="ri-file-list-2-line"></i></div>
                    <div className="value">{comma(widget.fileCount)}</div>
                  </div>
                </li>
                {parseInt(currentMenu?.isModify) === 1 &&
                  <li>
                    <div className="widget-title">등록된 논리 드라이브 개수 / 사용량</div>
                    <div className="widget-value clearfix">
                      <div className="value">{comma(widget.logicalDriveCount)}</div>
                      <div className="chart">
                        <DashboardCharts
                          seriesData={widget.logicalDriveUse}
                          colors={'#45cb85'}
                        />
                      </div>
                    </div>
                  </li>
                }
                {/* <li>
                  <div className="widget-title">PMS 동기화 마지막 일시</div>
                  <div className="widget-value clearfix">
                    <div className="icon bg-yellow"><i className="ri-arrow-left-right-line"></i></div>
                    <div className="value value1">2023-08-25<span>13:52:09</span></div>
                  </div>
                </li>    */}
                {parseInt(currentMenu?.isExcelDownload) === 1 &&
                  <li>
                    <div className="widget-title">그룹웨어 동기화 마지막 일시</div>
                    <div className="widget-value clearfix">
                      <div className="icon bg-red"><i className="ri-arrow-left-right-line"></i></div>
                      <div className="value value1">
                        {(widget.groupwareSyncDate || '') !== '' &&
                          <>
                            {moment(widget.groupwareSyncDate).format('YYYY-MM-DD')}
                            <span>{moment(widget.groupwareSyncDate).format('HH:mm:ss')}</span>
                          </>
                        }
                        {(widget.groupwareSyncDate || '') === '' && '동기화 안함' }
                      </div>
                    </div>
                  </li>
                }
              </ul>
            </div>
          </section>
        }

        <section className="flex flex-wrap">
          <div className="left-div">
            { // 논리 드라이브별 사용량
              parseInt(currentMenu?.isModify) === 1 &&
              <section className="card card1">
                <header className="card-header">
                  논리 드라이브별 사용량
                  <div className="fr">
                    논리드라이브: {driveList.length}개,
                    <span className="ml10">{formatBytes(widget.logicalDriveSize)} / {formatBytes(widget.logicalDriveMaxSize)}</span>
                  </div>
                </header>

                <section className="card-body scroll-y">
                  <ul className="drive-usage">
                    {
                      driveList.map((item, index) => {
                        return(
                          <DriveInfo
                            key={index}
                            data={item}
                          />
                        )
                      })
                    }
                  </ul>
                </section>
              </section>
            }

            { // 열람 신청 내역
              parseInt(currentMenu?.isDelete) === 1 &&
              <section className="card card2">
                <header className="card-header">
                  프로젝트 조회 및 파일 열람/다운로드 권한 요청 현황
                  <div className="fr">미처리건 : {requestList.length}건</div>
                </header>
                <div className="card-body">
                  <div className="authority">
                    <table>
                      <colgroup>
                        <col style={{width: '17%'}} />
                        <col style={{width: '40%'}} />
                        <col style={{width: '28%'}} />
                        <col style={{width: '15%'}} />
                      </colgroup>
                      <thead>
                        <tr>
                          <th scope="col" className="center">요청자</th>
                          <th scope="col" className="center">프로젝트명</th>
                          <th scope="col" className="center">요청 권한</th>
                          <th scope="col" className="center">요청일</th>
                        </tr>
                      </thead>
                    </table>
                    <div className="scroll-y">
                      <table>
                        <colgroup>
                          <col style={{width: '17%'}} />
                          <col style={{width: '40%'}} />
                          <col style={{width: '28%'}} />
                          <col style={{width: '15%'}} />
                        </colgroup>
                        <tbody>
                          {requestList.length < 1 &&
                            <tr>
                              <td colSpan={4} className="center">미처리된 내역이 없습니다.</td>
                            </tr>
                          }

                          {requestList.length > 0 &&
                            requestList.map((item, index) => {
                              return (
                                <tr key={index} onClick={() => {
                                  history('/admin/reading?seq=' + item.seq);
                                }}>
                                  <td>
                                    <div className="flex">
                                      <img
                                        src={item?.picture || ''}
                                        alt={item.creatorName + ' ' + item.positionName}
                                        className="img"
                                        onError={(event) => {
                                          event.target.src = noImg;
                                        }}
                                      />
                                      <div className="mt3">
                                        <div className="f12">{item.departmentName}</div>
                                        <div className="f13 mt5">{item.positionName} {item.creatorName}</div>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="f12 clightgrey">[{item.projectNumber}]</div>
                                    <div className="mt5">{item.projectName}</div>
                                  </td>
                                  <td className="center">
                                    {parseInt(item.isApplicationView) === 1 &&
                                      <span className="badge outline-blue">프로젝트/파일조회</span>
                                    }

                                    {parseInt(item.isApplicationView) === 2 &&
                                      <span className="badge outline-green">파일조회</span>
                                    }

                                    {parseInt(item.isApplicationView) === 3 &&
                                      <span className="badge outline-red">프로젝트 추가정보</span>
                                    }
                                  </td>
                                  <td className="center">{moment(item.createDate).format('YYYY-MM-DD')}</td>
                                </tr>
                              )
                            })
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </section>
            }
          </div>

          <div className="right-div">
          { /* 프로젝트 검색 순위 */ }
            <section className="card card1">
              <header className="card-header">
                프로젝트 검색 순위 <span className="sub">(1~6)</span>
                <span className="fr">{moment().format('YYYY년 MM월')} 기준</span>
              </header>
              <div className="card-body">
                <ul className="ranking">
                  {projectList.length > 0 &&
                    projectList.map((item, index) => {
                      return (
                        <li
                          key={index}
                          className="flex between"
                          onClick={() => {
                            history('/admin/project?projectNumber=' + item.projectNumber)
                          }
                        }>
                          <div className="fl flex" style={{alignItems: 'center'}}>
                            <div className="no fl">{item.rankNo}.</div>
                            <div className="fl">
                              <div className="f12 clightgrey">[{item.projectNumber}]</div>
                              <div className="mt5 ellipsis">{item.projectName}</div>
                            </div>
                          </div>
                          <div className="flex" style={{alignItems: 'center'}}>
                            <span style={{display: 'inline-block', lineHeight: '40px'}}>
                              {item.prevRankNo === item.rankNo ? '-' : comma(item.prevRankNo)}
                            </span>
                            <span style={{display: 'inline-block', width: '30px', marginLeft: '10px'}}>
                            {item.prevRankNo !== 0 && item.prevRankNo > item.rankNo &&
                              <i className="icon ri-arrow-up-s-fill color-red"></i>
                            }
                            {item.prevRankNo !== 0 && item.prevRankNo < item.rankNo &&
                              <i className="icon ri-arrow-up-s-fill color-blue"></i>
                            }
                            {item.prevRankNo === 0 &&
                              <ChipEx color="secondary" className="ml10" variant="outlined" size="small" label="진입" />
                            }
                            </span>
                          </div>
                        </li>
                      )
                    })
                  }
                  {projectList.length < 1 &&
                    <li className="center mt20">
                      검색된 내역이 없습니다.
                    </li>
                  }
                </ul>
              </div>
            </section>

            { /* 검색어 순위 */ }
            <section className="card card2">
              <header className="card-header">
                검색어 검색 순위 <span className="sub">(1~10)</span>
                <span className="fr">{moment().format('YYYY년 MM월')} 기준</span>
              </header>
              <div className="card-body">
                <ul className="ranking">
                  {searchList.length > 0 &&
                    searchList.map((item, index) => {
                      return (
                        <li key={index}>
                          <div className="fl" style={{alignItems: 'center'}}>
                            <span className="no">{item.rankNo}.</span>
                            <span className="mt5 ellipsis">{item.word}</span>
                          </div>
                          <div className="fr">
                            ({
                              item.prevRankNo === 0 ? '-' : comma(item.prevRankNo)
                            })
                            {item.prevRankNo !== 0 && item.prevRankNo > item.rankNo &&
                              <i className="icon ri-arrow-up-s-fill color-red"></i>
                            }
                            {item.prevRankNo !== 0 && item.prevRankNo < item.rankNo &&
                              <i className="icon ri-arrow-up-s-fill color-blue"></i>
                            }
                            {item.prevRankNo === 0 &&
                              <ChipEx color="secondary" className="ml10" variant="outlined" size="small" label="진입" />
                            }
                          </div>
                        </li>
                      )
                    })
                  }
                  {projectList.length < 1 &&
                    <li className="center mt20">
                      검색된 내역이 없습니다.
                    </li>
                  }
                </ul>
              </div>
            </section>
          </div>
        </section>
      </section>
   </Layout>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default Dashboard;