import axios from "axios";
import { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";

import { MESSAGE_DELAY, baseURL } from "config/config";
import { IndicatorContext } from 'config/indicator';

import alertMsg from 'components/message';

import { logout } from 'reducers/userReducer';

export const Restful = () => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const { setIndicator } = useContext(IndicatorContext);
  const [ indicatorList, setIndicatorList ] = useState([]);

  const dispatch = useDispatch();
  const history = useNavigate();

  /********************************************************************************
   * 설명 : 스토리지 처리
  *********************************************************************************/
  const storage = window.localStorage;

  const getItem = (itemName) => {
    return storage.getItem(itemName);
  }

  const removeItem = (itemName) => {
    return storage.removeItem(itemName);
  }

  const setItem = (itemName, value) => {
    return storage.setItem(itemName, value);
  }

  /********************************************************************************
   * 설명 : Restful API 호출 시 헤더값을 지정한다.
   * 입력값 : 없음
   * 리턴값 : 없음
  *********************************************************************************/
  function requestHeader( type ) {
    let token = getItem("token");

    let config = {
      "Content-Type": type === 'file' ? 'multipart/form-data' : "application/json;charset=utf-8",
      //"Access-Control-Allow-Credentials": true,
      "Access-Control-Allow-Origin": 'http://localhost:3000',
      //"Origin": 'http://localhost:3000',
      //"credentials": true
      "Authorization": token !== null && token.indexOf && token.indexOf('Bearer') > -1 ? token : ' Bearer ' + token
    }

    axios.defaults.headers[type.toLowerCase()] = config;
    axios.defaults.crossDomain = true;
    //axios.defaults.withCredentials = true;
  }

  /********************************************************************************
   * 설명 : 넘겨준 데이터로 URI 파라미터 형식으로 반환한다
   * 입력값 : object = array or object literal
   * 리턴값 : uri parameter
  *********************************************************************************
  function getParam(object)
  {
    var parameters = [];

    // 입력된 값이 배열인 경우 처리
    if( object.length !== undefined && object.length > 0 ) {
      object.map((item) => {
        for(let key in item) {
          parameters.push(key + '=' + item[key]);
        }
        return item;
      });

    // 입력된 값이 리터럴인 경우 처리
    } else {
      for (var property in object) {
        if (object.hasOwnProperty(property)) {
          parameters.push(encodeURI(property + '=' + object[property]));
        }
      }
    }

    return parameters.replace(/ /gi, '+').join('&');
  }

  /********************************************************************************
   * 설명 : Restful API 데이터 리턴
   * 입력값 : response data
   * 리턴값 : array
  *********************************************************************************/
  function getResponseData(response) {
    if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
      return response.data.data;

    } else {
      return [];
    }
  }

   /********************************************************************************
   * 설명 : Restful API 데이터 리턴
   * 입력값 : response data
   * 리턴값 : array
  *********************************************************************************/
  const getApi = (type, uri, data, file) => {
    requestHeader(type);

    //let token = window.localStorage.getItem("token");

    var transType = ( type === "" ) ? "get" : type;
    let url = baseURL + uri;

    // GET 일 경우 처리
    if( transType.toLowerCase() === "get" ) {
      if( data !== undefined && data !== null )
        url += "?" + new URLSearchParams(data).toString();

    // DELETE 일 경우 처리
    } else if( transType.toLowerCase() === "delete" ) {
      transType = "delete";
      /*
      if( data !== undefined && data !== null )
        url += "?" + getParam(data);
      */

    } else if( transType.toLowerCase() === 'file' ) {
      transType = "post";

      let formData = new FormData();

      formData.append('file', file);

      for(let key in data) {
        formData.append(key, data[key]);
      }

      data = formData;

    } else {
      transType = "post";
    }

    if( indicatorList !== undefined ) {
      if( indicatorList.length < 1 ) setIndicator(true);
      setIndicatorList([...indicatorList, url]);
    }

    let promise = null;

    if( type === 'file' ) {
      let token = getItem("token").replace('/', '_');

      promise = axios({
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-control-Allow-Origin": "*",
          "Authorization": token !== null && token.indexOf && token.indexOf('Bearer') > -1 ? token : ' Bearer ' + token
        },
        url: url,
        method: 'post',
        data: data
      })
    } else {
      promise = axios[transType.toLowerCase()](url, data, {
        //withCredentials: true // 쿠키 cors 통신 설정
      })
    }

    let result = promise.then( (response) => {
      if( indicatorList !== undefined ) {
        if( indicatorList.length < 2 ) {
          setIndicatorList([]);
          setIndicator(false);
        }
      }

      // 세션 로그아웃일 경우
      console.log(response.data.login);
      if( response.data.login === true ) {
        removeItem('token');
        dispatch(logout());

      } else {
        // 사용자 세션 연장 처리
        if( response.data.token !== undefined && response.data.token !== null ) {
          removeItem('token');
          setItem("token", response.data.token.replace('/', '_') );
        }
      }

      return response;
    })
    .catch( e => {
      console.log( 'error', e );
      if( indicatorList.length < 1 )
        setIndicator(false);

      // 로그인이 필요한 경우
      let uri = document.location.href;
      if( e?.message?.indexOf('401') > -1 && uri?.indexOf("/login") === -1 ) {
        alertMsg("회원 로그인이 필요합니다.", "E", MESSAGE_DELAY);
        history('/');
        return;

      } else {
        return {
          data: {
            success: false,
            result: false,
            message: e.response?.data?.message || e.message,
            detail: {}
          }
        }
      }
    });

    return result;
  }

  return [getApi, getResponseData];
}

export default Restful;