/*****************************************************************************************
 * 설명 : 사용자 통합검색 - 프로젝트 정보
 * URI : /user/TotalSearch/list
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import React, {useEffect, useState} from 'react';

import Button from '@mui/material/Button';

import { ButtonEx } from 'components/inputEx';
import alertMsg from 'components/message';

import Restful from "service/restful";
import { comma, formatBytes } from 'service/utils';
import { getProjectApi } from 'service/project';

import { MESSAGE_DELAY } from 'config/config';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const ProjectInfo = (props) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [getApi] = Restful();

  const [data, setData] = useState({});

  /***************************************************************************************
   * 설명 : 프로젝트 정보 가져오기
  ***************************************************************************************/
  const getProjectInfo = (projectNumber) => {
    let params = {
      projectNumber: projectNumber
    }

    getProjectApi(getApi, params, (response) => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        setData(response.data.data[0]);

      } else {
        alertMsg("프로젝트 정보가 존재하지 않습니다.", "E", MESSAGE_DELAY);

        props.close();
      }
    });
  }

  /***************************************************************************************
   * 설명 : 데이터 초기화
  ***************************************************************************************/
  useEffect(() => {
    if( props.open && props.data ) {
      // 파일인 경우 서버에서 프로젝트 정보 가져오기
      if( props.isFile ) {
        getProjectInfo(props.data.projectNumber);

      // 파일이 아닌 경우 리스트 정보 표시
      } else {
        setData(props.data);
      }
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open, props.data]);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <section className="projectInfoModal modal">
      <section className="modal-wrap" style={{width: '1000px'}}>
        <header className="modal-header" style={{ cursor: 'move' }} id="draggable-dialog-title">
          <div className="modal-title">프로젝트정보</div>
          <Button className="close-btn" onClick={props.close}>×</Button>
        </header>

        <section className="modal-body">
          <header className="clearfix mb10">
            <div className="fl project-nm">{data?.projectName}</div>
            <div className="fr">
              {
                parseInt(data?.readingGrade) !== 2 &&
                parseInt(data?.isView) === 1 &&
                parseInt(data?.isConfirm) === 1 &&
                parseInt(data?.isFile) === 3 &&
                <ButtonEx
                  auth="isView"
                  title="상세보기"
                  color="secondary"
                  className="fr"
                  variant="outlined"
                  onClick={() => {
                    let uri = '/project?projectNumber=' + data.projectNumber + '&tab=' + props.isFile;
                    window.open(uri, 'project');
                  }}
                />
              }
            </div>
          </header>

          <section className="mb10 mt20">
            <table className="input-table">
              <colgroup>
                <col style={{width: '10%'}} />
                <col style={{width: '23%'}} />
                <col style={{width: '10%'}} />
                <col style={{width: '23%'}} />
                <col style={{width: '10%'}} />
                <col style={{width: '24%'}} />
              </colgroup>

              <tbody>
                <tr>
                  <th scope="row"><label>구분</label></th>
                  <td>{data?.projectDivisionName}</td>
                  <th scope="row"><label>프로젝트 번호</label></th>
                  <td>{data?.projectNumber}</td>
                  <th scope="row"><label>연도</label></th>
                  <td>{data?.projectYear}</td>
                </tr>
                <tr>
                  <th scope="row"><label>유형</label></th>
                  <td>{data?.projectTypeName}</td>
                  <th scope="row"><label>담당PM</label></th>
                  <td>{data?.projectPmName}</td>
                  <th scope="row"><label>주관부서</label></th>
                  <td>{data?.projectDepartmentName}</td>
                </tr>
                <tr>
                  <th scope="row"><label>위치</label></th>
                  <td colSpan={3}>{data?.location}</td>
                  <th scope="row"><label>규모</label></th>
                  <td>{data?.scale}</td>
                </tr>
                <tr>
                  <th scope="row"><label>용도</label></th>
                  <td colSpan={3}>
                    {data?.purpose1 !== null && data?.purpose1}
                    {data?.purpose1 === null && data?.purpose}
                  </td>
                  <th scope="row"><label>구조</label></th>
                  <td>
                    {data?.structure1 !== null && data?.structure1}
                    {data?.structure1 === null && data?.structure}
                  </td>
                </tr>
                <tr>
                  <th scope="row">대지면적(㎡)</th>
                  <td>{(data?.landSize || '') !== '' ? comma(data.landSize) : ''}</td>
                  <th scope="row">건축면적(㎡)</th>
                  <td>{(data?.buildSize || '') !== '' ? comma(data.buildSize) : ''}</td>
                  <th scope="row">연면적(㎡)</th>
                  <td>{(data?.totalSize || '') !== '' ? comma(data.totalSize) : ''}</td>
                </tr>
              </tbody>
            </table>
          </section>

          { props.isFile &&
            <section className="mt20">
              <h3>파일정보</h3>

              <table className="input-table mt10">
              <colgroup>
                <col style={{width: '10%'}} />
                <col style={{width: '40%'}} />
                <col style={{width: '10%'}} />
                <col style={{width: '40%'}} />
              </colgroup>

              <tbody>
                <tr>
                  <th scope="row">경로명</th>
                  <td>{props.data.path || '/'}</td>
                  <th scope="row">파일명</th>
                  <td>
                    {props.data.fileName}
                    <span className="f11 ml5">({formatBytes(props.data.fileSize)})</span>
                  </td>
                </tr>
                <tr>
                  <th scope="row">태그</th>
                  <td colSpan={3}>{props.data.hashTag}</td>
                </tr>
              </tbody>
              </table>
            </section>
          }
        </section>

        <footer className="modal-footer">
          <div className="modal-footer-center">
            <Button
              color="inherit"
              variant="outlined"
              className="Btn"
              onClick={() => {
                props.close();
              }}
            >창닫기</Button>
          </div>
        </footer>
      </section>
    </section>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default ProjectInfo;