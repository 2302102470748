/*****************************************************************************************
 * 설명 : 관리자 > 시스템관리 > 시스텀환경설정
*****************************************************************************************/
import { AgGridReact } from 'ag-grid-react';
import { useEffect, useState } from 'react';
// import { useSelector } from 'react-redux';
import { InputAdornment } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import Modal from '@mui/material/Modal';
import { useFormik } from 'formik';
import * as Yup from "yup";

import AlertDialog from 'components/alertDiolog';
import BtnAgGridSave from 'components/btnAgGridSave';
import ChipEx from 'components/chipEx';
import { BtnRefresh, BtnSearch } from 'components/common';
import { ButtonEx, InputEx, ToggleButtonGroupEx } from 'components/inputEx';
import alertMsg from 'components/message';
import customTooltipAgGrid from 'components/tooltipAgGrid';

import Restful from 'service/restful';
import { deleteIpAddressApi, getAccessIpApi, getSystemConfigApi, setSystemConfigApi } from 'service/system';
import { PaperComponent, comma } from 'service/utils';

import { MESSAGE_DELAY } from 'config/config';

import useGridConfig from 'hooks/useGridConfig';

import SystemConfigModal from 'pages/admin/system/systemConfig/systemConfigModal';
import Layout from 'pages/layout/layout';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const SystemConfig = ( props ) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [getApi] = Restful();

  // const currentMenu = useSelector(state => state?.menuList?.current);

  const [config, setConfig] = useState([]);
  const [list, setList] = useState([]);
  const [ip, setIp] = useState('');

  const [gridApi, setGridApi] = useState({});

  const [openModal, setOpenModal] = useState({open: false, modal: 'add', data: []});

  const formik = useFormik({
    initialValues: {
    },
    validationSchema: Yup.object().shape({
    }),
    onSubmit: (values) => {

    }
  });

  const formikIp = useFormik({
    initialValues: {
      startDate: '',
      endDate: '',
      type: '',
      group: ''
    },
    validationSchema: Yup.object().shape({
    }),
    onSubmit: (values) => {
      getList();
    }
  });

  // table column
  const [columnDefs, setColumnDefs] = useState([
    { headerName: '', field: 'seq', width: 40, cellClass: 'center', flex: 0,
      headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
    { headerName: '번호', field: 'rowIndex', width: 55, cellClass: 'cp text-right',
      valueGetter: (params) => {
        return comma(params.node.rowIndex + 1);
      }
    },
    { headerName:'타입', field: 'group', width: 70, cellClass: 'cp center',
      cellRendererFramework: function(params) {
        if( parseInt(params.data.group) === 0 )
          return <ChipEx color="success" variant="outlined" size="small" label="아이피" />;
        else
          return <ChipEx color="error" variant="outlined" size="small" label="아이디" />;
      }
    },
    { headerName:'구분', field: 'type', width: 70, cellClass: 'cp center',
      cellRendererFramework: function(params) {
        if( parseInt(params.data.type) === 0 )
          return <ChipEx color="success" variant="outlined" size="small" label="허용" />;
        else
          return <ChipEx color="error" variant="outlined" size="small" label="차단" />;
      }
    },
    { headerName:'IPv4, IPv6', field: 'ipAddress', width: 400, cellClass: 'cp' },
    { headerName:'메모', field: 'memo', width: 400, cellClass: 'cp' },
    { headerName:'사용여부', field: 'isUse', width: 100, cellClass: 'cp center',
      cellRendererFramework: function(params) {
        if( parseInt(params.data.isUse) === 1 )
          return <ChipEx color="success" variant="outlined" size="small" label="사용" />;
        else
          return <ChipEx color="default" variant="outlined" size="small" label="사용안함" />;
      }
    },
    { headerName:'등록자', field: 'creatorName', width: 100, cellClass: 'cp center' },
    { headerName:'등록일시', field: 'createDate', width: 150, cellClass: 'cp center' },
  ]);

  const [gridConfig, setGridUpdate] = useGridConfig(1, setColumnDefs);

  /***************************************************************************************
   * 설명 : 환경설정 정보 가져오기
  ***************************************************************************************/
  const getConfig = () => {
    getSystemConfigApi(getApi, {groupCode: 'SYSTEM'}, (response) => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        let tmp = response.data.data;

        tmp.forEach((item) => {
          formik.setFieldValue(item.commonCode, item.memo);
        });

        setConfig(tmp);
      } else {
        setConfig([]);
      }
    });
  }

  /***************************************************************************************
   * 설명 : 환경설정 저장하기
  ***************************************************************************************/
  const saveConfig = async () => {
    let check = [];

    let promise = await config.map( async (item, index) => {
      let tmp = {...item};
      tmp.memo = formik.values[item.commonCode];

      return await setSystemConfigApi(getApi, tmp);
    });

    Promise.all(promise).then((values) => {
      values.forEach((item, index) => {
        if( item !== undefined && item.data.result )
          check[index] = true;
        else
          check[index] = false;
      });

      let checkAll = true;
      check.forEach((item) => {
        if( item === false ) checkAll = false;
      });

      if( checkAll ) {
        alertMsg('시스템 환경설정 정보를 성공적으로 저장하였습니다.', "S", MESSAGE_DELAY);
      } else {
        alertMsg('시스템 환경설정 정보를 저장하는데 실패하였습니다.', "E", MESSAGE_DELAY);
      }
    })
  }

  /***************************************************************************************
   * 설명 : 아이피 리스트 가져오기
  ***************************************************************************************/
  const getList = () => {
    getAccessIpApi(getApi, formikIp.values, (response) => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        setList(response.data.data);

        setIp(response.data.ip);
      } else {
        setList([]);
        setIp('');
      }
    });
  }

  /***************************************************************************************
   * 설명 : 선택된 아이피 삭제
  ***************************************************************************************/
  const deleteIpAddress = () => {
    let nodes = gridApi.getSelectedRows();

    if( nodes.length < 1 ) {
      alertMsg("삭제할 접근 아이피를 선택하시기 바랍니다.", "W", MESSAGE_DELAY);
      return;
    }

    let params = nodes.map((item) => {
      return {seq: item.seq}
    });

    deleteIpAddressApi(getApi, params, (response) => {
      if( response !== undefined && response.data.result ) {
        alertMsg(response.data.message, "S", MESSAGE_DELAY);

        getList();

      } else {
        alertMsg(response.data.message || '서버와의 통신에 실패하였습니다.', "E", MESSAGE_DELAY);
      }
    })
  }

  /***************************************************************************************
   * 설명 : 타입 변경 시 자동 검색
  ***************************************************************************************/
  useEffect(() => {
    getList();

    // eslint-disable-next-line
  }, [formikIp.values.type]);

  /***************************************************************************************
   * 설명 : 데이터 처리
  ***************************************************************************************/
  useEffect(() => {
    getConfig();

    getList();

    // eslint-disable-next-line
  }, []);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <Layout>
      <section className="admin-cms-contents">
        <form onSubmit={formik.handleSubmit}>
          <header className="clearfix pb5 mt10">
            <div className='fl f14 bold lh28px'>
              시스템 환경 설정
            </div>
            <div className='fr'>
              <ButtonEx
                type="submit"
                title="저장"
                auth="isWrite"
                color="secondary"
                className="ml5"
                variant="contained"
                onClick={() => setOpenModal({open: true, modal: 'configDialog', data: {}})}
              />
            </div>
          </header>
          <table className="input-table">
            <colgroup>
              <col style={{width: '10%'}} />
              <col style={{width: '23%'}} />
              <col style={{width: '10%'}} />
              <col style={{width: '23%'}} />
              <col style={{width: '10%'}} />
              <col style={{width: '23%'}} />
            </colgroup>

            <tbody>
              <tr className='layout'>
                <th scope="row"><label htmlFor="SESSION">세션타임</label></th>
                <td className="left">
                  <InputEx
                    name="SESSION"
                    formik={formik}
                    fullWidth={false}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">분</InputAdornment>
                    }}
                    style={{width: '200px'}}
                  />
                </td>
                <th scope="row"><label htmlFor="FAILLOGIN">로그인 실패 제한횟수</label></th>
                <td className="left">
                  <InputEx
                    name="FAILLOGIN"
                    formik={formik}
                    fullWidth={false}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">회</InputAdornment>
                    }}
                    style={{width: '200px'}}
                  />
                </td>
                <th scope="row"><label htmlFor="LOGEXPIRE">로그보존기간</label></th>
                <td className="left">
                  <InputEx
                    name="LOGEXPIRE"
                    formik={formik}
                    fullWidth={false}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">년</InputAdornment>
                    }}
                    style={{width: '200px'}}
                  />
                </td>
              </tr>
              <tr className='layout'>
                <th scope="row"><label htmlFor="DRIVEROOT">운영 논리드라이브 루트</label></th>
                <td className="left">
                  <InputEx
                    name="DRIVEROOT"
                    formik={formik}
                    fullWidth={false}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">경로</InputAdornment>
                    }}
                    style={{width: '200px'}}
                  />
                </td>
                <th scope="row"><label htmlFor="TEMPROOT">업로드 논리드라이브 루트</label></th>
                <td className="left">
                  <InputEx
                    name="TEMPROOT"
                    formik={formik}
                    fullWidth={false}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">경로</InputAdornment>
                    }}
                    style={{width: '200px'}}
                  />
                </td>
                <th></th>
                <td></td>
              </tr>
              <tr className='layout'>
                <th scope="row"><label htmlFor="AUTOCADID">AutoCAD 아이디</label></th>
                <td className="left">
                  <InputEx
                    name="AUTOCADID"
                    formik={formik}
                    fullWidth={false}
                    style={{width: '200px'}}
                  />
                </td>
                <th scope="row"><label htmlFor="AUTOCADPW">AutoCAD 비밀번호</label></th>
                <td className="left">
                  <InputEx
                    type="password"
                    name="AUTOCADPW"
                    formik={formik}
                    fullWidth={false}
                    style={{width: '200px'}}
                  />
                </td>
                <th></th>
                <td></td>
              </tr>
            </tbody>
          </table>
        </form>

        <form onSubmit={formikIp.handleSubmit}>
          <div className='fl f14 bold lh28px mt20'>
            접속 아이피 관리 -
            <span className="ml10">접속 IP : {ip}</span>
          </div>
          <header className="admin-cms-search-header">
            <div className="left">
              <div className='fl'>
                <BtnRefresh click={() => {
                  formik.setValues({
                    startDate: '',
                    endDate: '',
                    type: ''
                  });
                  formik.handleSubmit();
                }}></BtnRefresh>
                <BtnSearch></BtnSearch>
                <BtnAgGridSave click={setGridUpdate}></BtnAgGridSave>
              </div>
              <div className='fl'>
                <InputEx
                  type="date"
                  name="startDate"
                  formik={formikIp}
                  fullWidth={false}
                  label="검색시작일"
                  style={{width: '140px'}}
                  InputLabelProps={{ shrink: true }}
                />

                <span className="ml5 mr5 lh28px">~</span>

                <InputEx
                  type="date"
                  name="endDate"
                  formik={formikIp}
                  fullWidth={false}
                  label="검색종료일"
                  style={{width: '140px'}}
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              <div className='fl ml5'>
                <ToggleButtonGroupEx
                  name="group"
                  exclusive={true}
                  formik={formikIp}
                  className="search-toggle-btn"
                  data={[
                    {label: '전체', value: ''},
                    {label: '아이피', value: 0},
                    {label: '아이디', value: 1},
                  ]}
                />

                <span className="ml5" />

                <ToggleButtonGroupEx
                  name="type"
                  exclusive={true}
                  formik={formikIp}
                  className="search-toggle-btn"
                  data={[
                    {label: '전체', value: ''},
                    {label: '허용', value: 0},
                    {label: '차단', value: 1},
                  ]}
                />
              </div>

            </div>
            <div className="text-right">
              <ButtonEx
                title="삭제"
                auth="isDelete"
                color="error"
                variant="outlined"
                className="ml5"
                onClick={() => {
                  // deleteModule();
                  setOpenModal({open: true, modal: 'dialog', data: {}});
                }}
              />

              <ButtonEx
                title="추가"
                auth="isWrite"
                color="secondary"
                className="ml5"
                variant="outlined"
                onClick={() => {
                  setOpenModal({open: true, modal: 'add', data: {siteSeq: formik.values.siteSeq}})
                }}
              />
            </div>
          </header>
        </form>

        <section className="admin-cms-body">
          <section className="ag-theme-balham grid pad-height420" style={{height:'calc(100vh - 320px'}}>
            <AgGridReact
              defaultColDef={{
                sortable: true,
                resizable: true,
                filter: false,
                lockVisible: true,
                tooltipComponent: customTooltipAgGrid,
              }}
              tooltipShowDelay={0}
              tooltipHideDelay={2000}
              rowSelection = {'multiple'}
              columnDefs={columnDefs}
              rowData={list}
              onGridReady={(event) => {
                gridConfig(event.columnApi);
                setGridApi(event.api);
              }}
              onRowDoubleClicked={(event) => {
                //if( currentMenu?.authModify === '1' || currentMenu?.authModify === 1 )
                  setOpenModal({open: true, modal: 'add', data: event.data});
              }}
              rowDragManaged={true}
              rowDragMultiRow={true}
              animateRows={true}
              overlayNoRowsTemplate = "검색된 내용이 없습니다."
            />
          </section>
        </section>


      </section>

      { openModal.open && openModal.modal === 'add' &&
        <Dialog
          open={openModal}
          onClose={() => (event, reason) => {
            if(reason && reason === "backdropClick") return;
            setOpenModal({open: false, modal: openModal.modal, data: {}});
          }}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "500px",  // Set your width here
              },
            },
          }}
        >
          <section className="modal">
            <SystemConfigModal
              open={openModal.open}
              close={() => setOpenModal({ open: false, modalName: 'add', data: openModal.data })}
              data={openModal.data}
              getList={getList}
              indicator={props.indicator}
              history={props.history}
            />
          </section>
        </Dialog>
      }

      { openModal.open && openModal.modal === 'dialog' &&
        <Modal
          open={openModal.open}
          onClose={() => setOpenModal({ open: false, modalName: 'dialog', data: openModal.data })}
        >
          <AlertDialog
            open={openModal.open}
            close={() => setOpenModal({ open: false, modalName: 'dialog', data: openModal.data })}
            title="접속 아이피 삭제"
            message={`선택하신 "아이피 주소"를 삭제하시겠습니까?`}
            confirm="삭제"
            onClick={deleteIpAddress}
            getList={getList}
            indicator={props.indicator}
            history={props.history}
          />
        </Modal>
      }

      { openModal.open && openModal.modal === 'configDialog' &&
        <Modal
          open={openModal.open}
          onClose={() => setOpenModal({ open: false, modalName: 'dialog', data: openModal.data })}
        >
          <AlertDialog
            open={openModal.open}
            close={() => setOpenModal({ open: false, modalName: 'dialog', data: openModal.data })}
            title="환경설정 저장"
            message={`환경설정 값을 저장하시겠습니까?`}
            confirm="저장"
            color="primary"
            onClick={async () => {
              await saveConfig()
            }}
            indicator={props.indicator}
            history={props.history}
          />
        </Modal>
      }
    </Layout>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default SystemConfig;