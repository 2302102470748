import { createSlice } from '@reduxjs/toolkit'

const menuListSlice = createSlice({
  name: 'menuList',
  initialState: {},
  reducers: {
    setMenuInfo: ( state, action ) => {
      return {
        menu: action.payload,
        list: state.list,
        current: state.current
      }
    },
    setMenuOrigin: ( state, action ) => {
      return {
        menu: state.menu,
        list: action.payload,
        current: state.current
      }
    },
    setMenuCurrent: ( state, action ) => {
      return {
        menu: state.menu,
        list: state.list,
        current: action.payload
      }
    }
  }
})

export const { setMenuInfo, setMenuOrigin, setMenuCurrent } = menuListSlice.actions;
export default menuListSlice.reducer;