/*****************************************************************************************
 * 설명 : 사용자 통합검색 - list - 디자인
 * URI : /user/TotalSearch/list
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import Dialog from '@mui/material/Dialog';
import Pagination from '@mui/material/Pagination';
import { encode as base64_encode } from 'base-64';
import { useEffect, useRef, useState } from 'react';

import { comma, formatBytes, PaperComponent } from 'service/utils';

import { baseURL, IMAGE_FILE, MOVIE_FILE } from 'config/config';

import NoImage from 'assets/images/prepare.png';
import PreviewModal from './previewModal';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const TabDesign = ( props ) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const movieRef = useRef();

  const [list, setList] = useState([]);
  const [totalPage, setTotalPage] = useState(0);

  const [openModal, setOpenModal] = useState({open: false, modal: 'sync', data: []});

  const [previewMovie, setPreviewMovie] = useState('');

  /***************************************************************************************
   * 설명 : 동영상 마우스 오버시 플레이
  ***************************************************************************************/
  useEffect(() => {
    if( movieRef && movieRef.current && movieRef.current.stop !== undefined ) {
      const videoElement = movieRef && movieRef.current;

      if( previewMovie !== '' && videoElement.paused ) {
        videoElement.play();
      } else if( previewMovie === '' ) {
        videoElement.stop();
      }
    }

  }, [previewMovie])

  /***************************************************************************************
   * 설명 : 데이터 초기 설정
  ***************************************************************************************/
  useEffect(() => {
    if( props.tabIndex === 0 && props.list ) {
      setList(props.list);

      if( props.total !== undefined ) {
        let totalCount = (props.total.design === undefined ) ? 1 : props.total.design;
        let total = Math.floor(totalCount / 10);
        if( totalCount % 10 > 0 ) total++;

        setTotalPage(total);

      } else {
        setTotalPage(0);
      }

    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.tabIndex, props.list])

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <section className="search-list-result">
      { list.length > 0 &&
        <>
          <section className="pad-height420" style={{height:'calc(100vh - 380px)', overflowY: 'auto', overflowX: 'hidden'}}>
            <ul className="design clearfix">
              { list.map((item, index) => {
                let url = 'http://archive.dagroup.co.kr/synap/SynapDocViewServer/thumbnail/'
                  + item.thumbnail + '/0?dpi=L&withXml=false&resizeType=M&fileExt=jpg&resizeValue=360&quality=80';

                // if( index > 8 ) return '';

                return (
                  <>
                    <li onClick={() => setOpenModal({open: true, modal: 'previewFile', data: item})}>
                      <div className="list" >
                        { // 이미지 파일 미리보기
                          IMAGE_FILE.includes(item.ext?.toLowerCase()) &&
                          <img src={baseURL + "/api/v1/project/image/download?thumbnail=" + base64_encode(item.thumbnail)} alt={item.fileName}
                            onError={(e) => {
                              e.target.src = NoImage;
                            }}
                          />
                        }

                        { // 동영상 파일 미리보기
                          MOVIE_FILE.includes(item.ext?.toLowerCase()) &&
                          <>
                            <img
                              src={baseURL + "/api/v1/project/image/download?thumbnail=" + base64_encode(item.thumbnail)}
                              alt={item.fileName}
                              onError={(e) => {
                                e.target.src = NoImage;
                              }}
                              style={previewMovie === item.seq ? {display: 'none'} : {}}
                            />
                            <video
                              autoPlay
                              muted
                              loop
                              playsInline
                              width="360px"
                              height="270px"
                              ref={movieRef}
                              style={previewMovie !== item.seq ? {display: 'none'} : {}}
                            >
                              <source src={baseURL + '/api/v1/file/download?seq=' + item.seq} type="video/mp4" />
                            </video>
                          </>
                        }

                        { // 기타 파일 미리보기
                          ! IMAGE_FILE.includes(item.ext?.toLowerCase()) && ! MOVIE_FILE.includes(item.ext?.toLowerCase()) &&
                          <img
                            src={url}
                            alt={item.fileName}
                            onError={(e) => {
                              e.target.src = NoImage;
                            }}
                          />
                        }

                        <div className="img-wrap"
                          onMouseLeave={(e) => {
                            if( MOVIE_FILE.includes(item.ext?.toLowerCase()) ) {
                              setPreviewMovie('');
                            }
                          }}
                        ></div>
                        <div
                          className="keyword ellipsis2"
                          onMouseEnter={(e) => {
                            if( MOVIE_FILE.includes(item.ext?.toLowerCase()) ) {
                              setPreviewMovie(item.seq);
                            }
                          }}
                        >
                          {item.fileName} <span className="f10">({formatBytes(item.fileSize)})</span>
                        </div>
                        <div
                          className="info ellipsis2"
                          onMouseEnter={(e) => {
                            if( MOVIE_FILE.includes(item.ext?.toLowerCase()) ) {
                              setPreviewMovie(item.seq);
                            }
                          }}
                        >
                          {item.hashTag ? item.hashTag : "등록된 태그 없음"}
                        </div>

                        {previewMovie === '' &&
                          <div
                            className={"darkness"}
                            onMouseEnter={(e) => {
                              if( MOVIE_FILE.includes(item.ext?.toLowerCase()) ) {
                                setPreviewMovie(item.seq);
                              }
                            }}
                            onMouseLeave={(e) => {
                              if( MOVIE_FILE.includes(item.ext?.toLowerCase()) ) {
                                setPreviewMovie('');
                              }
                            }}
                          />
                        }
                      </div>
                    </li>
                  </>
                )
              })}
            </ul>
          </section>

          <footer className="mt10">
            <div className="mb10">
              <div className="f16">디자인 검색결과 : {comma(props.total.design)}건,  프로젝트 검색결과 : {comma(props.total.project)}건, 파일 검색결과 : {comma(props.total.file)}건</div>
            </div>
            <div className="search-pagination">
              <div className="pagination big">
                <Pagination
                  count={totalPage}
                  defaultPage={props.formik.values.pageNo}
                  siblingCount={0}
                  boundaryCount={2}
                  size={!props.size ? 'small' : props.size}
                  shape="rounded"
                  color="primary"
                  showFirstButton
                  showLastButton
                  onChange={(event, pageNo) => props.change(event, pageNo)}
                />
              </div>
            </div>
          </footer>
        </>
      }

      { list.length < 1 &&
        <div style={{height: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>검색된 내역이 없습니다.</div>
      }



      { /*
        openModal.open && openModal.modal === 'projectInfo' &&
        <Dialog
          open={openModal.open}
          onClose={() => setOpenModal({ open: false, modalName: 'projectInfo', data: openModal.data })}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "1000px",  // Set your width here
              },
            },
          }}
        >
          <ProjectInfo
            open={openModal.open}
            close={() => setOpenModal({ open: false, modalName: 'projectInfo', data: openModal.data })}
          />
        </Dialog>
        */ }

      { openModal.open && openModal.modal === 'previewFile' &&
        <Dialog
          open={openModal.open}
          onClose={() => setOpenModal({ open: false, modalName: 'previewFile', data: openModal.data })}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "75vw",  // Set your width here
              },
            },
          }}
        >
          <PreviewModal
            open={openModal.open}
            data={openModal.data}
            isFile={false}
            close={() => setOpenModal({ open: false, modalName: 'previewFile', data: openModal.data })}
          />
        </Dialog>
      }
    </section>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default TabDesign;