/*****************************************************************************************
 * 설명 : 게시판 - 공지사항 - 등록
 * URI : /board/write
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { useFormik } from 'formik';
import { useRef, useState } from 'react';
import * as Yup from "yup";
/*
import $ from 'jquery'
import Summernote from "react-summernote";
import 'react-summernote/lang/summernote-ko-KR.min';
*/

import Modal from '@mui/material/Modal';
import AlertDialog from 'components/alertDiolog';

import { ButtonEx, DateEx, InputEx, ToggleButtonGroupEx } from 'components/inputEx';
import alertMsg from 'components/message';

import { MESSAGE_DELAY } from 'config/config';

import { getExtensionOfFilename } from 'service/utils';

import Layout from 'pages/layout/layout';

import fileImage from 'assets/images/ext/1.png';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const BoardWrite = ( props ) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
	const [contents] = useState('');

	//const noteRef = useRef();
	const fileInput = useRef(null);

	const [upload, setUpload] = useState([]);

  const formik = useFormik({
		initialValues: {
			seq: '',
			boardId: '',
			groupId: '',
			boardIndex: '',
			subject: '',
			contents: '',
			noticeSdate: null,
			noticeEdate: null,
			screenTop: 0,
			screenLeft: 0,
			isLayerPopup: 0,
			popupWidth: 0,
			popupHeight: 0,
			isPopup: 0,
			isNotice: 0,
			isTitlelink: 0,
			titleLink: 0,
			status: 0
		},
		validationSchema: Yup.object().shape({
			subject: Yup.string().max(255, "255자리").required('필수'),
			//contents: Yup.string().required('필수'),
			/*
			screenTop: Yup.number().typeError('숫자').nullable(),
			screenLeft: Yup.number().typeError('숫자').nullable(),
			popupWidth: Yup.number().typeError('숫자').nullable(),
			popupHeight: Yup.number().typeError('숫자').nullable(),
			titleLink: Yup.string().max(1000, "1000자리")
			*/
		}),
		onSubmit: (values) => {
			let params = {
				program: 'admin',
				service: 'board',
				action: 'setBoardWrite',
				version: '1.0',
				...values
			}

			if( contents.length < 1 ) {
				alertMsg("내용을 입력해 주시기 바랍니다.", "E", MESSAGE_DELAY);
				return false;
			}

			params.contents = contents;
			params.uploadFile = upload;
/*
			restful("post", params).then( response => {
				if( response !== undefined && response.data.result ) {
					alertMsg(response.data.message, "S", MESSAGE_DELAY);

					props.setBoardFunc('list');

				} else {
					alertMsg(response?.data?.message || '서버와의 통신에 실패하였습니다.', 'E', MESSAGE_DELAY);
				}
			});
      */
		}
  });

  const [openModal, setOpenModal] = useState({open: false, modal: 'sync', data: []});

	/***************************************************************************************
   * 설명 : 파일 업로드
  ***************************************************************************************/
	const uploadFile = (files) => {
    /*
		let params = {
			program: 'admin',
			service: 'board',
			action: 'setFileUpload',
			version: '1.0',
		}

		restful("file", params, files).then( (response) => {
			if( response !== undefined && response.data.result ) {
				let tmp = [...upload];

				setUpload([
					...tmp,
					{
						thumbnail: response.data.thumbnail !== '' ? baseHost + response.data.thumbnail : baseHost + response.data.url,
						url: baseHost + response.data.url,
						filename: response.data.filename,
						filePath: response.data.url,
						fileSize: response.data.size,
						originalFilename: response.data.originalFilename
					}
				]);

			} else {
				alertMsg(response?.data?.message || '서버와의 통신에 실패하였습니다.', 'E', MESSAGE_DELAY);
			}
		});
    */
  }

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
	return (
		<Layout>
      <section className="admin-cms-contents">
        <form onSubmit={formik.handleSubmit}>
          <div className="mt10" style={{width: 'calc(100% - 2px)'}}>
            <table className="input-table">
              <colgroup>
                <col style={{width: '15%'}} />
                <col style={{width: '35%'}} />
                <col style={{width: '15%'}} />
                <col style={{width: '35%'}} />
              </colgroup>

              <tbody>
                <tr>
                  <th scope="row"><label htmlFor="subject">제목</label></th>
                  <td colSpan={3}>
                    <InputEx
                      name="subject"
                      formik={formik}
                      fullWidth={true}
                    />
                  </td>
                </tr>
                <tr>
                  <th scope="row"><label htmlFor="subject">작성자</label></th>
                  <td>홍길동(아이디)</td>
                  <th scope="row"><label htmlFor="subject">작성일시</label></th>
                  <td>2023-07-02</td>
                </tr>
                <tr>
                  <th scope="row"><label htmlFor="isNotice">공지글</label></th>
                  <td>
                    <ToggleButtonGroupEx
                      name="isNotice"
                      exclusive={true}
                      formik={formik}
                      fullWidth={false}
                      data={[
                        {label: '공지글', value: '1'},
                        {label: '일반글', value: '0'}
                      ]}
                    />
                  </td>
                  {formik.values.isNotice === '0' &&
                    <td colSpan={2}></td>
                  }
                  {formik.values.isNotice === '1' &&
                    <>
                      <th scope="row"><label htmlFor="noticeSdate">공지 기간</label></th>
                      <td>
                        <DateEx
                          type="date"
                          name="noticeSdate"
                          formik={formik}
                          fullWidth={false}
                          className="search-input-btn"
                          style={{width: '150px'}}
                        />
                        <span className="ml5 mr5 lh38px">~</span>
                        <DateEx
                          type="date"
                          name="noticeEdate"
                          formik={formik}
                          fullWidth={false}
                          className="search-input-btn"
                          style={{width: '150px'}}
                        />
                      </td>
                    </>
                  }
                </tr>
                <tr>
                  <th scope="row"><label htmlFor="uploadFile">파일</label></th>
                  <td colSpan={3}>
                    <div>
                      <Button color="secondary" variant="contained" className="btn black mb5" onClick={(event) => {
                        fileInput.current.click();
                      }}>파일첨부</Button>
                    </div>

                    <input
                      type="file"
                      accept="file/*"
                      ref={fileInput}
                      onChange={(event) => {
                        if( event.target.files ) {
                          uploadFile(event.target.files[0]);
                        }
                      }}
                      style={{display: "none"}}
                    />

                    {upload && upload.length > 0 &&
                      upload.map((item, index) => {
                        return (
                          <div className="fileUpload fl mr10">
                            <div>
                              {
                                (
                                  item.filename.toLowerCase().indexOf('jpg') > -1 ||
                                  item.filename.toLowerCase().indexOf('png') > -1 ||
                                  item.filename.toLowerCase().indexOf('jepg') > -1 ||
                                  item.filename.toLowerCase().indexOf('gif') > -1 ||
                                  item.filename.toLowerCase().indexOf('bmp') > -1
                                ) &&
                                <img src={item?.thumbnail} className="img" width="120" height="120" alt={item?.filename} />
                              }

                              {
                                (
                                  item.filename.toLowerCase().indexOf('jpg') < 0 &&
                                  item.filename.toLowerCase().indexOf('png') < 0 &&
                                  item.filename.toLowerCase().indexOf('jepg') < 0 &&
                                  item.filename.toLowerCase().indexOf('gif') < 0 &&
                                  item.filename.toLowerCase().indexOf('bmp') < 0
                                ) &&
                                <div style={{width: '120px', height: '120px'}}>
                                  <img src={fileImage} className="img" width="120" height="120" alt={item?.filename} />
                                  <div className="filename-extention">
                                    {getExtensionOfFilename(item.filename)}
                                  </div>
                                </div>
                              }
                            </div>

                            <div className="mt5">
                              <div className="fl">
                                <Tooltip arrow title={item.originalFilename}>
                                  <div className="upload-file-name">
                                    {item.originalFilename}
                                  </div>
                                </Tooltip>
                              </div>
                              <div className="fr">
                                <Button
                                  className="close-btn"
                                  style={{padding: '0px'}}
                                  onClick={() => {
                                    let tmp = [...upload];
                                    tmp.splice(index, 1);

                                    setUpload(tmp);
                                  }}
                                >
                                  <CloseIcon style={{marginBottom: '3px', fontSize: '24px', color: 'red' }}></CloseIcon>
                                </Button>
                              </div>
                            </div>
                          </div>
                        )
                      })
                    }

                    {
                      formik.values.url && formik.values.url.length > 0 &&
                      formik.values.url.map((item) => {
                        return (
                          <div>
                            <img src={item.url} className="img" width="120" height="120" alt="첨부파일" />
                            <Button className="close-btn" onClick={() => {
                              // 업로드 된 파일 삭제
                              formik.setFieldValue('url', '')
                            }}>
                              <CloseIcon style={{marginBottom: '3px', fontSize: '24px', color: 'red' }}></CloseIcon>
                            </Button>
                          </div>
                        )
                      })
                    }
                  </td>
                </tr>
              </tbody>
            </table>

            <div id="CKEditor" className="mt5">
              {/*
              <Summernote
                ref={noteRef}
                defaultCodeValue={contents}
                placeholder={"내용을 입력해 주시기 바랍니다."}
                tabsize={2}
                lang="ko-KR" // only if you want to change the default language
                height={600 || "60vh"}
                dialogsInBody={true}
                blockquoteBreakingLevel={0}
                toolbar={[
                  ['style', ['style']],
                  ['font', ['bold', 'underline', 'clear', 'strikethrough', 'superscript', 'subscript']],
                  ['fontsize', ['fontsize']],
                  ['fontname', ['fontname']],
                  ['color', ['color']],
                  ['para', ['ul', 'ol', 'paragraph']],
                  ['table', ['table']],
                  ['insert', ['link', 'picture', 'video', 'hr']],
                  ['view', ['fullscreen', 'codeview', 'help']]
                ]}
                fontNames={[
                  "Arial",
                  "Georgia",
                  "Verdana",
                  "e.t.c..."
                ]}
                callbacks={{
                  onChange: function(contents, $editable) {
                    setContents(contents);
                  },
                  onInit: function() {
                    noteRef?.current?.summernote('insertText', contents)
                  }
                }}
              />
              */}
            </div>
          </div>

          <footer className="mt10">
            <div className="text-right">
              <Button
                color="inherit"
                variant="outlined"
                className="Btn"
                href="/board"
              >이전으로</Button>

              <ButtonEx
                auth="isModify"
                title="수정하기"
                type="submit"
                color="secondary"
                variant="contained"
                className="Btn ml5"
                onClick={() => {
                  setOpenModal({open: true, modal: 'dialog', data: {}});
                }}
              />
            </div>
          </footer>
        </form>

        { openModal.open && openModal.modal === 'dialog' &&
          <Modal
            open={openModal.open}
            onClose={() => setOpenModal({ open: false, modalName: 'dialog', data: openModal.data })}
          >
            <AlertDialog
              open={openModal.open}
              close={() => setOpenModal({ open: false, modalName: 'dialog', data: openModal.data })}
              title="게시글 등록/수정"
              message={`게시글을 수정하시겠습니까?`}
              confirm="확인"
            />
          </Modal>
        }
      </section>
		</Layout>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default BoardWrite;