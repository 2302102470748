/*****************************************************************************************
 * 설명 : 프로젝트관리 - 협력업체 추가/수정
 * URI : /project
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import React, { useEffect, useState }  from 'react';
import { useFormik } from 'formik';
import Button from '@mui/material/Button';
import * as Yup from "yup";

import { InputEx } from 'components/inputEx';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const ProjectForeignModal = (props) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [title, setTitle] = useState('추가');

  /***************************************************************************************
   * 설명 : 업체 검색
  ***************************************************************************************/
  const formik = useFormik({
    initialValues: {
      seq: '',
      projectNumber: '',
      group: '',
      companyName: '',
      chargeName: '',
      memo: ''
    },
    validationSchema: Yup.object().shape({
      group: Yup.string().max(50, "50자리"),
      companyName: Yup.string().max(100, "100자리").required('필수'),
      chargeName: Yup.string().max(50, "50자리"),
      memo: Yup.string().max(200, "200자리").nullable()
    }),
    onSubmit: (values) => {

      let isAdd = props.list?.filter((item) => item.seq === values.seq);
      if( isAdd.length > 0 ) {
        let tmp = [...props.list];
        let index = 0;
        tmp.forEach((item, subIndex) => {
          if( item.seq === formik.values.seq ) index = subIndex;
        })

        tmp[index] = values;
        props.setList(tmp);

      // 추가
      } else {
        let tmp = [
          ...props.list,
          {...values}
        ];

        props.setList(tmp);
      }

      props.close();
    }
  });

  /***************************************************************************************
   * 설명 : 오픈 시 처리
  ***************************************************************************************/
  useEffect(() => {
    if( props.open ) {
      if( props.data?.seq !== undefined && props.data?.seq !== '' ) {
        setTitle('수정');

        formik.setValues(props.data);

      } else {
        setTitle('추가');

        let seq = 0;
        props.list.forEach((item) => {
          if( parseInt(item.seq) < 0 && parseInt(item.seq) < seq ) seq = parseInt(item.seq);
        });

        formik.setFieldValue('seq', seq - 1);
      }

      formik.setFieldValue("projectNumber", props.selected.projectNumber);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <section className="modal">
      <section className="modal-wrap" style={{width: '100%'}}>
        <form onSubmit={formik.handleSubmit}>
          <header className="modal-header" style={{ cursor: 'move' }} id="draggable-dialog-title">
            <div className="modal-title">해외협업사 {title}</div>
            <Button className="close-btn" onClick={props.close}>×</Button>
          </header>

          <section className="modal-body">
            <div className="mb10">
              <table className="input-table">
                <colgroup>
                  <col style={{width: '30%'}} />
                  <col style={{width: '70%'}} />
                </colgroup>

                <tbody>
                  <tr>
                    <th scope="row"><label htmlFor="group">구분</label></th>
                    <td>
                      <InputEx
                        name="group"
                        formik={formik}
                        fullWidth={true}
                        inputProps={{autoFocus: true}}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row"><label htmlFor="companyName">업체명</label></th>
                    <td>
                      <InputEx
                        name="companyName"
                        formik={formik}
                        fullWidth={true}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row"><label htmlFor="chargeName">담당자</label></th>
                    <td>
                      <InputEx
                        name="chargeName"
                        formik={formik}
                        fullWidth={true}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row"><label htmlFor="memo">메모</label></th>
                    <td>
                      <InputEx
                        name="memo"
                        formik={formik}
                        fullWidth={true}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </section>

          <footer className="modal-footer">
            <Button
              color="inherit"
              variant="outlined"
              className="Btn"
              onClick={() => {
                props.close();
              }}
            >창닫기</Button>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              className="Btn ml10"
            >저장</Button>
          </footer>
        </form>
      </section>
    </section>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default ProjectForeignModal;