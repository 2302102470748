/*****************************************************************************************
 * 설명 : 관리자 > 프로젝트 관리 > 파일정보 관리
*****************************************************************************************/
import FolderIcon from '@mui/icons-material/Folder';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Modal from '@mui/material/Modal';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { AgGridReact } from 'ag-grid-react';
import * as Excel from "exceljs/dist/exceljs.min.js";
import { saveAs } from "file-saver";
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import * as Yup from "yup";

import AlertDialog from 'components/alertDiolog';
import BtnAgGridSave from 'components/btnAgGridSave';
import ChipEx from 'components/chipEx';
import { BtnExcelDown, BtnRefresh, BtnSearch } from 'components/common';
import { ButtonEx, InputEx, SelectEx, ToggleButtonGroupEx } from 'components/inputEx';
import alertMsg from 'components/message';
import customTooltipAgGrid from 'components/tooltipAgGrid';

import { getCommonListApi } from 'service/common';
import {
  deleteFileListApi,
  getProjectPathFileApi,
  setProjectExcelUploadApi,
  setProjectFileSaveApi,
  setProjectFileTagApi
} from 'service/project';
import Restful from 'service/restful';
import { comma, formatBytes, getSelectData, isCheckValue, PaperComponent } from 'service/utils';

import { baseURL, DOWNLOAD_FILE, MESSAGE_DELAY } from 'config/config';

import useGridConfig from 'hooks/useGridConfig';

import ExcelModal from '../project/modal/excelModal';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const TabDesignFileInfo = ( props ) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [getApi] = Restful();

  const [gridApi, setGridApi] = useState({});

  const currentMenu = useSelector(state => state?.menuList?.current);

  const [list, setList] = useState([]);
  const [fileExtList, setFileExtList] = useState([]);

  const [openModal, setOpenModal] = useState({open: false, modal: 'common', data: []});

  const [pathInfo, setPathInfo] = useState({
    totalDirectoryCount: 0,
    totalFileCount: 0,
    totalSize: 0
  });

  const [dirPathInfo, setDirPathInfo] = useState({
    totalDirectoryCount: 0,
    totalFileCount: 0,
    totalSize: 0
  });

  const initialValues = {
    ext: '',
    extSearch: '',
    fileSize: '',
    sizeType: 1,
    searchText: '',
    isAuth: '',
    isType: 0,
    isDownload: ''
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object().shape({
      searchText: Yup.string().max(30, "30자리"),
      fileSize: Yup.number().typeError("숫자"),
    }),
    onSubmit: (values) => {
      getList();
    }
  });

  // table column
  const [columnDefs, setColumnDefs] = useState([
    { headerName: '키값(수정금지)', field: 'seq', width: 35, cellClass: 'center', flex: 0,
      headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true
    },
    { headerName: '순번', field: 'rowIndex', width: 45, cellClass: 'cp text-right',
      valueGetter: (params) => {
        return comma(params.node.rowIndex + 1);
      }
    },
    { headerName:'경로명', field: 'path', width: 250, cellClass: 'cp'},
    { headerName:'파일명', field: 'title', width: 150, cellClass: 'cp', tooltipField: 'title',
      cellRendererFramework: function (params) {
        if( parseInt(params.data?.type || 0) === 1 )
          return params.data.title;
        else
          return (
            <div
              className="flex vcenter" style={{height: '100%'}}>
                <FolderIcon style={{fontSize: '15px'}}/>
                <div className="ml5" style={{width: 'calc(100% - 30px)'}}>
                  {params.data.title}
                </div>
            </div>
          );
      }
    },
    { headerName:'용량', field: 'fileSize', width: 100, cellClass: 'cp text-right',
      valueGetter: (params) => {
        return formatBytes(params.data?.fileSize || 0, 2);
      }
    },
    { headerName:'태그', field: 'tag', width: 400, cellClass: 'cp', editable: true, tooltipField: 'tag'},
    { headerName:'연관프로젝트', field: 'relationProject', width: 200, cellClass: 'cp', editable: true, tooltipField: 'relationProject' },
    { headerName:'비고', field: 'memo', width: 200, cellClass: 'cp', editable: true, tooltipField: 'memo' },
    { headerName:'기본열람권한', field: 'isAuth', width: 200, cellClass: 'cp center',
      cellRendererFramework: (params) => {
        return (
          <ToggleButtonGroup
            id={'auth' + params.data.seq}
            value={String(params.data.isAuth || '')}
            onChange={(event) => {
              params.node.setDataValue('isAuth', String(event.target.value));
            }}
          >
            <ToggleButton type="button" value={'0'} size="small" className="f12">전체열람</ToggleButton>
            <ToggleButton type="button" value={'1'} size="small" className="f12">목록열람</ToggleButton>
            <ToggleButton type="button" value={'2'} size="small" className="f12">열람불가</ToggleButton>
          </ToggleButtonGroup>
        )
      }
    },
    { headerName:'다운로드허용여부', field: 'isDownload', width: 200, cellClass: 'cp center',
      cellRendererFramework: (params) => {
        return (
          <ToggleButtonGroup
            id={'download' + params.data.seq}
            value={String(params.data.isDownload || '')}
            onChange={(event) => {
              params.node.setDataValue('isDownload', String(event.target.value));
            }}
          >
            <ToggleButton type="button" value={'0'} size="small" className="f12">다운로드 불가</ToggleButton>
            <ToggleButton type="button" value={'1'} size="small" className="f12">다운로드 허용</ToggleButton>
          </ToggleButtonGroup>
        )
      }
    },
    { headerName:'미리보기허용여부', field: 'isPreview', width: 200, cellClass: 'cp center',
      cellRendererFramework: (params) => {
        return (
          <ToggleButtonGroup
            id={'download' + params.data.seq}
            value={String(params.data.isPreview || '')}
            onChange={(event) => {
              params.node.setDataValue('isPreview', String(event.target.value));
            }}
          >
            <ToggleButton type="button" value={'0'} size="small" className="f12">미리보기 불가</ToggleButton>
            <ToggleButton type="button" value={'1'} size="small" className="f12">미리보기 허용</ToggleButton>
          </ToggleButtonGroup>
        )
      }
    },
    { headerName:'등록일시', field: 'createDate', width: 150, cellClass: 'cp center' },
    { headerName:'다운로드', field: 'download', width: 70, cellClass: 'cp center',
      cellRendererFramework: function (params) {
        return (
          <div className={"grid-icon"}>
            <Button
              color="inherit"
              onClick={() => {
                let url = baseURL + '/api/v1/file/download?seq=' + params.data.seq;
                window.open(url)
              }}
            >
              <i className="ri-download-line"></i>
            </Button>
          </div>
        );
      }
    },
    { headerName:'미리보기', field: 'preview', width: 70, cellClass: 'cp center',
      cellRendererFramework: function (params) {
        return (
          <div className={"grid-icon"}>
            { DOWNLOAD_FILE.includes(params.data.ext?.toLowerCase()) &&
              <Button
                color="inherit"
                onClick={() => {
                  let url = 'http://archive.dagroup.co.kr/synap/SynapDocViewServer/job?fileType=Local&sync=true&fid=' + params.data.seq + '&filePath=';
                  window.open(url + encodeURIComponent(params.data.destPath))
                }}
              >
                <i className="ri-eye-line"></i>
              </Button>
            }
            {/*
            <Button color="inherit"><i className="ri-archive-drawer-line"></i></Button>
            <Button color="inherit"><i className="ri-delete-bin-line"></i></Button>
            <Button  color="inherit" onClick={() => toggleMenuView()}><i className=" ri-more-2-line"></i></Button>
            <div style={{display: menuView === 'view' ? 'block' : 'none'}}>
              <ul className="popup-menu" onClick={() => setMenuView('')}>
                <li>열람 권한신청</li>
                <li className="down-line">다운로드 권한신청</li>
                <li>복사하기<span className="hotkey">~^C</span></li>
                <li className="down-line">잘라내기<span className="hotkey">~^X</span></li>
                <li className="down-line">붙여넣기<span className="hotkey">~^V</span></li>
                <li className="down-line">삭제하기<span className="hotkey">~^D</span></li>
                <li>다운로드</li>
                <li>미리보기</li>
              </ul>
            </div>
            */}
          </div>
        );
      }
    },
  ]);

  const [gridConfig, setGridUpdate] = useGridConfig(31, setColumnDefs);

  /***************************************************************************************
   * 설명 : 파일정보 관리
  ***************************************************************************************/
  const getList = () => {
    let params = {
      projectNumber: props?.selected?.projectNumber,
      ...formik.values
    }

    getProjectPathFileApi(getApi, params, (response) => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        setList(response.data.data);

        if( response.data.data1)
          setPathInfo(response.data.data2);

        if( response.data.data2)
          setDirPathInfo(response.data.data1);

      } else {
        setList([]);
      }
    })
  }

  /***************************************************************************************
   * 설명 : 그리드에서 태그 직접 수정
  ***************************************************************************************/
  const modifyTag = (data) => {
    let params = {
      seq: data.data?.seq,
      tag: data.data?.tag,
      relationProject: data.data?.relationProject,
      memo: data.data?.memo
    }

    setProjectFileTagApi(getApi, params, (response) => {
      if( response !== undefined && response.data.result ) {

      } else {
        alertMsg(response.data.message || '서버와의 통신에 실패하였습니다.', "E", MESSAGE_DELAY);
      }
    });
  }

  /***************************************************************************************
   * 설명 : 검색 초기화
  ***************************************************************************************/
  const initSearchGroup = () => {
    formik.setValues(initialValues);

    formik.handleSubmit();
  }

  /***************************************************************************************
   * 설명 : 기본열람권한 상태 일괄 변경
  ***************************************************************************************/
  const setAllAuth = () => {
    let tmp = list.map((item) => {
      item.isAuth = String(formik.values.isAuth || '');
      return {
        ...item
      }
    });

    setList(tmp);
  }

  /***************************************************************************************
   * 설명 : 공통코드 리스트 데이터 가져오기
  ***************************************************************************************/
  const getCommonList = () => {
    let params = {
      groupCode: 'EXT'
    }

    getCommonListApi(getApi, params, (response) => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        setFileExtList(getSelectData(response.data.data, 'commonName', 'commonCode'));
      } else {
        setFileExtList([]);
      }
    });
  }

  /***************************************************************************************
   * 설명 : 다운로드 권한 상태 일괄 변경
  ***************************************************************************************/
  const setAllDownload = () => {
    let tmp = list.map((item) => {
      if( parseInt(formik.values.isType) === 0 )
        item.isDownload = String(formik.values.isDownload || '');
      else
        item.isPreview = String(formik.values.isDownload || '');

      return {
        ...item
      }
    });

    setList(tmp);
  }

  const styleHeaderCell = (cell) => {
    cell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "ffebebeb" },
    };
    cell.border = {
      top: { style: "thin", color: { argb: "-100000f" } },
      bottom: { style: "thin", color: { argb: "-100000f" } },
      right: { style: "thin", color: { argb: "-100000f" } },
    };
    cell.font = {
      name: "Arial",
      size: 12,
      bold: true,
      color: { argb: "ff252525" },
    };
    cell.alignment = {
      vertical: "middle",
      horizontal: "center",
      wrapText: true,
    };
  };

  const styleDataCell = (cell, align) => {
    /*
    cell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "ffffffff" },
    };
    /*
    cell.border = {
      //bottom: { style: "thin", color: { argb: "-100000f" } },
      //right: { style: "thin", color: { argb: "-100000f" } },
    };
    */
    cell.font = {
      name: "Arial",
      size: 10,
      color: { argb: "ff252525" },
    };
    cell.alignment = {
      vertical: "middle",
      horizontal: align,
      wrapText: true,
    };
  };

  /***************************************************************************************
   * 설명 : 엑셀 내보내기
  ***************************************************************************************/
  const exportExcel = async (title, fileName) => {
    try {
      // 여러 엑셀 시트를 포함하는 하나의 workbook(단위) 생성
      const wb = new Excel.Workbook();

      // 엑셀 sheet 생성
      const sheet = wb.addWorksheet("데이터");

      // 상단 타이틀 추가
      const titleCell = sheet.addRow(['', title]);
      sheet.mergeCells('B1:J1');
      titleCell.eachCell((cell, colNum) => {
        cell.font = {
          bold: true,
          name: "Arial",
          size: 30,
          color: { argb: "ff000000" },
        };
        cell.alignment = {
          vertical: "middle",
          horizontal: "center",
          wrapText: true,
        };
      });

      // 빈 라인 추가
      sheet.addRow(['']);

      let headerDefault = [...columnDefs.filter((item) => ! ['download', 'preview'].includes(item.field) )];
      let tmp = headerDefault.map((item) => {
        if( item.field === 'isAuth') {
          item.headerName = '기본열람권한\n0=전체열람,1=목록열람,\n2=열람불가';
          return item;
        } else if( item.field === 'isDownload') {
          item.headerName = '다운로드허용\n0=다운로드불가,\n1=다운로드허용';
          return item;
        } else
          return item;
      })

      headerDefault = tmp;

      const headers = [...headerDefault.map((item) => item.headerName)];
      let headerWidths = [...columnDefs.map((item) => Math.round(item.width / 8))];

      // seq 숨김
      headerWidths[0] = 0;

      // 상단 헤더(TH) 추가
      const headerRow = sheet.addRow(headers);
      headerRow.height = 35;

      // 각 헤더 cell에 스타일 지정
      headerRow.eachCell((cell, colNum) => {
        styleHeaderCell(cell);
        sheet.getColumn(colNum).width = headerWidths[colNum - 1];

        // 기본열람권한, 다운로드 허용 헤더에 설명 추가
      });


      // 각 Data cell에 데이터 삽입 및 스타일 지정
      list.forEach((item) => {
        // download, preview 제외

        const rowDatas = [...headerDefault.map((subItem) => {
          if( subItem.field === 'fileSize')
            return formatBytes(item[subItem.field] || 0, 2);
          else
            return item[subItem.field];
        })];
        const appendRow = sheet.addRow(rowDatas);

        appendRow.eachCell((cell, colNum) => {
          const align = ['right', 'left', 'left', 'right', 'left', 'center', 'center', 'center'];
          styleDataCell(cell, align[parseInt(colNum) - 1]);
          if (colNum === 4) {
            cell.numFmt = "0,000";
          }
        });
      });

      // 파일 생성
      const fileData = await wb.xlsx.writeBuffer(); //writeBuffer는 프로미스를 반환하므로 async-await을 사용해야 한다.
      const blob = new Blob([fileData], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, fileName);
    } catch(error) {
      console.log(error);
    }

  }

  /***************************************************************************************
   * 설명 : 선택한 파일리스트 삭제
  ***************************************************************************************/
  const deleteFileList = () => {
    let nodes = gridApi.getSelectedRows();

    if( nodes.length < 1 ) {
      alertMsg("삭제하고자 하는 파일을 선택하시기 바랍니다.", "W", MESSAGE_DELAY);
      return;
    }

    let params = {
      projectNumber: props.selected?.projectNumber,
      data: nodes
    }

    deleteFileListApi(getApi, params, (response) => {
      if( response !== undefined && response.data.result ) {
        alertMsg("선택하신 파일을 성공적으로 삭제하였습니다.", "S", MESSAGE_DELAY);

        getList();

      } else {
        alertMsg(response.data.message || '서버와의 통신에 실패하였습니다.', "E", MESSAGE_DELAY);
      }
    })
  }

  /***************************************************************************************
   * 설명 : 파일 정보 저장하기
  ***************************************************************************************/
  const saveFile = () => {
    let params = {
      ...props.selected,
      data: list
    }

    setProjectFileSaveApi(getApi, params, (response) => {
      if( response !== undefined && response.data.result ) {
        alertMsg(response.data.message, "S", MESSAGE_DELAY);

      } else {
        alertMsg(response.data.message || '서버와의 통신에 실패하였습니다.', "E", MESSAGE_DELAY);
      }
    });

    setOpenModal({ open: false, modalName: openModal.modal, data: openModal.data })
  }

  /***************************************************************************************
   * 설명 : 파일을 처리 완료 상태로 변경
  ***************************************************************************************/
  const confirmFile = () => {
    let params = {
      ...props.selected,
      data: list
    }

    setProjectExcelUploadApi(getApi, params, (response) => {
      if( response !== undefined && response.data.result ) {
        alertMsg(response.data.message, "S", MESSAGE_DELAY);

        // 프로젝트 상태 업데이트
        let tmp = [...props.list];
        tmp[props.selectedIndex].isFile = 3;
        props.setSelected(tmp[props.selectedIndex]);
        props.setList(tmp);

      } else {
        alertMsg(response.data.message || '서버와의 통신에 실패하였습니다.', "E", MESSAGE_DELAY);
      }
    });

    setOpenModal({ open: false, modalName: openModal.modal, data: openModal.data })
  }

  /***************************************************************************************
   * 설명 : 파일형식 변경 시 자동 검색
  ***************************************************************************************/
  useEffect(() => {
    if( props.tabIndex === 2 && formik.values.ext !== 'ext') {
      getList();
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.ext])

  /***************************************************************************************
   * 설명 : 데이터 처리
  ***************************************************************************************/
  useEffect(() => {
    if( props.tabIndex === 2 && props.selected.seq !== undefined  ) {
      getCommonList();

      getList();
    }

    // eslint-disable-next-line
  }, [props.selected, props.tabIndex]);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <section className="project-file">
      <div className="mt10">
        <div className="fl">
          { // 프로젝트 추가 정보 상태
            props.selected.isConfirm === null
            ? <ChipEx color="error" variant="variant" size="small" label="등록" className="f12" />
            : ! isCheckValue(props.selected?.detailSeq) && parseInt(props.selected?.isConfirm) !== 1
            ? <ChipEx color="error" variant="variant" size="small" label="등록" className="f12" />
            : isCheckValue(props.selected?.detailSeq) && parseInt(props.selected?.isConfirm) !== 1
            ? <ChipEx color="success" variant="variant" size="small" label="추가" className="f12" />
            : <ChipEx color="primary" variant="variant" size="small" label="등록완료" className="f12" />
          }

          { // 프로젝트 파일 정보 상태
            props.selected?.isUploadCheck === 0 ? <ChipEx color="info" variant="variant" size="small" className="ml5 f12" label="처리중" />
            : ! isCheckValue(props.selected?.isFile) || parseInt(formik.values.isFile) === 0
            ? <ChipEx color="error" variant="variant" size="small" className="ml5 f12" label="미등록" />
            : parseInt(props.selected?.isFile) === 1
            ? <ChipEx color="secondary" variant="variant" size="small" className="ml5 f12" label="등록중" />
            : parseInt(props.selected?.isFile) === 2
            ? <ChipEx color="success" variant="variant" size="small" className="ml5 f12" label="등록완료" />
            : <ChipEx color="primary" variant="variant" size="small" className="ml5 f12" label="처리완료" />
          }
          <div style={{display: "inline-block", verticalAlign: 'middle'}}>
            <span className="ml5 bold f14">[{props.selected?.projectNumber}]</span>
            <span className="pad-newLine"></span>
            <span className="bold f14">{props.selected?.projectName}</span>
          </div>
        </div>
        <div className="fr right">
          {
            parseInt(props.selected?.isFile) >= 1 &&
            <>
              <ButtonEx
                title="파일삭제"
                auth="isDelete"
                color="error"
                variant="outlined"
                className="ml5"
                disabled={(props.selected?.isUploadCheck === 0) ? true : false}
                onClick={() => {
                  let nodes = gridApi.getSelectedRows();

                  if( nodes.length < 1 ) {
                    alertMsg("삭제할 파일을 선택하시기 바랍니다.", "W", MESSAGE_DELAY);

                  } else {
                    setOpenModal({open: true, modal: 'dialog', data: nodes});
                  }
                }}
              />

              <ButtonEx
                title="엑셀업로드"
                auth="isWrite"
                color="secondary"
                className="ml5"
                variant="outlined"
                disabled={(props.selected?.isUploadCheck === 0) ? true : false}
                onClick={() => {
                  if( list.length < 1 ) {
                    alertMsg("엑셀업로드 하기 전에 파일을 먼저 등록해 주시기 바랍니다.", "W", MESSAGE_DELAY);
                  } else {
                    setOpenModal({open: true, modal: 'excel', data: props.selected})
                  }
                }}
              />

              <ButtonEx
                title="저장하기"
                auth="isWrite"
                color="primary"
                className="ml5"
                variant="outlined"
                disabled={(props.selected?.isUploadCheck === 0) ? true : false}
                onClick={() => {
                  if( list.length < 1 ) {
                    alertMsg("저장할 파일이 없습니다.", "W", MESSAGE_DELAY);
                  } else {
                    setOpenModal({open: true, modal: 'fileSave', data: props.selected})
                  }
                }}
              />

              <ButtonEx
                title="처리완료"
                auth="isWrite"
                color="error"
                className="ml5"
                variant="outlined"
                disabled={(props.selected?.isFile === 3 || props.selected?.isUploadCheck === 0)? true : false}
                onClick={() => {
                  setOpenModal({open: true, modal: 'fileConfirm', data: props.selected})
                }}
              />
            </>
          }
        </div>
      </div>

      <div className="clearfix"></div>

      <header className="admin-cms-search-header mt10">
        <div className="fl left">
          <form onSubmit={formik.handleSubmit}>
            <BtnRefresh click={() => initSearchGroup()}></BtnRefresh>
            <BtnSearch></BtnSearch>
            <BtnAgGridSave click={setGridUpdate} />
            <BtnExcelDown click={() => {
              if( list.length < 1 ) {
                alertMsg("엑셀로 내보낼 파일 리스트가 없습니다.", "W", MESSAGE_DELAY);
              } else {
                exportExcel(props.selected?.projectName, "프로젝트_" + props.selected?.projectName);
              }
            }}></BtnExcelDown>

            <SelectEx
              name="ext"
              formik={formik}
              fullWidth={false}
              style={{width: '120px'}}
              data={[
                {label: '파일형식', value: ''},
              ].concat(fileExtList).concat([{label: '직접입력', value: 'ext'}])}
              onChange={(event) => {
                if( event.target.value !== 'ext')
                  formik.setFieldValue('extSearch', '');
              }}
            />

            {formik.values.ext === 'ext' &&
              <InputEx
                name="extSearch"
                formik={formik}
                label="확장명 입력"
                className="basic ml5"
                style={{width: '90px'}}
              />
            }


            <InputEx
              name="fileSize"
              formik={formik}
              label="파일크기(MB)"
              className="basic ml5 mr5"
              style={{width: '100px'}}
            />

            <ToggleButtonGroupEx
              name="sizeType"
              exclusive={true}
              formik={formik}
              className="search-toggle-btn"
              data={[
                {label: '보다 큰', value: 1},
                {label: '보다 작은', value: 2},
              ]}
            />

            <InputEx
              name="searchText"
              formik={formik}
              fullWidth={false}
              className="ml5"
              label="파일명, 태그"
              style={{width: '120px'}}
            />
          </form>
        </div>

        <div className="fr right pad-mt5">
          <ToggleButtonGroupEx
            formik={formik}
            name="isAuth"
            exclusive={true}
            className="f12"
            data={[
              {label: '전체열람', value: '0'},
              {label: '목록열람', value: '1'},
              {label: '열람불가', value: '2'},
            ]}
          />

          <ButtonEx
            auth="isWrite"
            title="일괄적용"
            variant="outlined"
            color="primary"
            className="ml5"
            onClick={() => setAllAuth()}
          />

          <span className="ml10"></span>

          <SelectEx
            formik={formik}
            name="isType"
            fullWidth={false}
            data={[
              {label: '다운로드', value: 0},
              {label: '미리보기', value: 1},
            ]}
            style={{width: '100px'}}
          />

          <span className="ml5"></span>

          <ToggleButtonGroupEx
            formik={formik}
            name="isDownload"
            exclusive={true}
            className="f12"
            data={[
              {label: '불가', value: '0'},
              {label: '허용', value: '1'},
            ]}
          />

          <ButtonEx
            auth="isWrite"
            title="일괄적용"
            variant="outlined"
            color="primary"
            className="ml5"
            onClick={() => setAllDownload()}
          />
        </div>

        <div className="clearfix"></div>
      </header>

      <section className="mt10">
        <section className="ag-theme-balham pad-height345" style={{height:'calc(100vh - 280px)'}}>
          <div style={{ height: '100%' }}>
            <AgGridReact
              defaultColDef={{
                sortable: true,
                resizable: true,
                filter: false,
                lockVisible: true,
                tooltipComponent: customTooltipAgGrid,
              }}
              tooltipShowDelay={0}
              tooltipHideDelay={2000}
              rowSelection = {'multiple'}
              columnDefs={columnDefs}
              rowData={list}
              onGridReady={(event) => {
                gridConfig(event.columnApi);
                setGridApi(event.api);
              }}
              onRowDoubleClicked={(event) => {
                if( parseInt(currentMenu?.authModify) === 1 )
                  setOpenModal({open: true, modal: 'commonGroup', data: event.data});
              }}
              onCellEditingStopped={(event) => {
                modifyTag(event.node);
              }}
              rowDragManaged={true}
              rowDragMultiRow={true}
              animateRows={true}
              overlayNoRowsTemplate = "검색된 내용이 없습니다."
            />
          </div>
        </section>
      </section>

      <footer className="footer-txt">
      <div className="fl">
          프로젝트 전체 : 폴더 {comma(pathInfo.totalDirectoryCount || 0)}개,
          파일 {comma(pathInfo.totalFileCount || 0)}개
          / 사용용량 : {formatBytes(pathInfo.totalSize || 0)}
        </div>

        <div className="fr">
          검색결과 : 파일 {comma(dirPathInfo.totalFileCount || 0)}개
          / 사용용량 : {formatBytes(dirPathInfo.totalSize || 0)}
        </div>
      </footer>


      { openModal.open && openModal.modal === 'excel' &&
        <Dialog
          open={openModal}
          onClose={() => (event, reason) => {
            if(reason && reason === "backdropClick") return;
            setOpenModal({open: false, modal: openModal.modal, data: {}});
          }}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "90%",
                maxWidth: "1300px",  // Set your width here
              },
            },
          }}
        >
          <section className="modal">
            <ExcelModal
              open={openModal.open}
              close={() => setOpenModal({ open: false, modalName: openModal.modal, data: openModal.data })}
              data={openModal.data}
              getList={getList}
              list={list}
              setList={setList}
              indicator={props.indicator}
              history={props.history}
            />
          </section>
        </Dialog>
      }

      { openModal.open && openModal.modal === 'fileConfirm' &&
        <Dialog
          open={openModal}
          onClose={() => (event, reason) => {
            if(reason && reason === "backdropClick") return;
            setOpenModal({open: false, modal: openModal.modal, data: {}});
          }}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "550px",  // Set your width here
              },
            },
          }}
        >
          <AlertDialog
            open={openModal.open}
            close={() => setOpenModal({ open: false, modalName: openModal.modal, data: openModal.data })}
            title="파일정보 처리완료"
            message={
              <div>
                <div>프로젝트의 디렉토리 및 파일을 처리완료 하시겠습니까?</div>
              </div>
            }
            confirm="처리완료"
            color="primary"
            onClick={() => confirmFile()}
          />
        </Dialog>
      }

      { openModal.open && openModal.modal === 'fileSave' &&
        <Dialog
          open={openModal}
          onClose={() => (event, reason) => {
            if(reason && reason === "backdropClick") return;
            setOpenModal({open: false, modal: openModal.modal, data: {}});
          }}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "550px",  // Set your width here
              },
            },
          }}
        >
          <AlertDialog
            open={openModal.open}
            close={() => setOpenModal({ open: false, modalName: openModal.modal, data: openModal.data })}
            title="파일정보 저장"
            message={
              <div>
                <div>프로젝트의 디렉토리 및 파일 정보를 저장 하시겠습니까?</div>
              </div>
            }
            confirm="저장하기"
            color="primary"
            onClick={() => saveFile()}
          />
        </Dialog>
      }

      { openModal.open && openModal.modal === 'dialog' &&
        <Modal
          open={openModal.open}
          onClose={() => setOpenModal({ open: false, modalName: 'dialog', data: openModal.data })}
        >
          <AlertDialog
            open={openModal.open}
            close={() => setOpenModal({ open: false, modalName: 'dialog', data: openModal.data })}
            title="파일 삭제"
            message={`선택하신 ${openModal.data.length}개의 "파일"를 삭제하시겠습니까?`}
            confirm="삭제"
            onClick={deleteFileList}
          />
        </Modal>
      }


    </section>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default TabDesignFileInfo;