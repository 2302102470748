/*****************************************************************************************
 * 설명 : 관리자 > 시스템관리 > 비용산출양식관리 > 추가/수정
*****************************************************************************************/
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import { AgGridReact } from 'ag-grid-react';
import { useFormik } from 'formik';
import { useCallback, useEffect, useRef, useState } from 'react';
import * as Yup from "yup";

import { getCostProjectListApi, getCostProjectYearApi, getCostPurposeApi, getProjectCostItemApi } from 'service/cost';
import { getProjectDivisionApi, getProjectStatusApi } from 'service/project';
import Restful from "service/restful";
import { comma, getSelectData } from 'service/utils';

import AlertDialog from 'components/alertDiolog';
import ChipEx from 'components/chipEx';
import { CurrencyFormat } from 'components/common';
import { ButtonEx, InputEx, SelectEx } from 'components/inputEx';
import alertMsg from 'components/message';
import customTooltipAgGrid from 'components/tooltipAgGrid';
import { PaperComponentSub } from 'service/utils';

import 'assets/css/pages/projectAddModal.scss';
import { MESSAGE_DELAY } from 'config/config';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
export const ProjectAddModal = ( props ) => {

  const initOrderBy = [
    {label: '가나다순↑', value: 0},
    {label: '가나다순↓', value: 1},
    {label: '등록일순↑', value: 2},
    {label: '등록일순↓', value: 3},
    {label: '프로젝트번호↑', value: 4},
    {label: '프로젝트번호↓', value: 5},
  ];

  const columnDef = [
    { headerName: '번호', field: 'costItemSeq', hide: true},
    { headerName: '번호', field: 'rowIndex', width: 55, cellClass: 'cp text-right', pinned: 'left',
      valueGetter: (params) => {
        return comma(params.node.rowIndex + 1);
      }
    },
    { headerName:'구분', field: 'type', width: 100, cellClass: 'cp center', pinned: 'left',
      cellRendererFramework: (params) => {
        if( parseInt(params.data.type) === 0 )
          return <ChipEx color="success" variant="outlined" size="small" label="기본" />;
        else if( parseInt(params.data.type) === 1 )
          return <ChipEx color="primary" variant="outlined" size="small" label="외주용역비" />;
        else
          return <ChipEx color="error" variant="outlined" size="small" label="기타" />;
      }
    },
    { headerName:'상세', field: 'title', width: 150, cellClass: 'cp', pinned: 'left' },
    { headerName:'프로젝트평균단가', field: 'baseAmt', hide: true },
    { headerName:'프로젝트평균단가', field: 'amt', hide: true },
    { headerName:'프로젝트평균단가', field: 'avgAmt', width: 120, cellClass: 'cp text-right', valueFormatter: CurrencyFormat, pinned: 'left' },
  ];

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [getApi] = Restful();

  // aggrid에서 데이터 접근하기 위함
  const selectedProject = useRef([]);
  const gridApiProject = useRef({});
  const columnDefsProjectRef = useRef(columnDef);

  const [list, setList] = useState([]);
  const [purpose, setPurpose] = useState([]);
  const [division, setDivision] = useState([]);
  const [status, setStatus] = useState([]);
  const [year, setYear] = useState([]);

  const [addProjectInfo, setAddProjectInfo] = useState([]);
  const [costFormList, setCostFormList] = useState([]);

  const [openModal, setOpenModal] = useState({open: false, modal: 'add', data: {}});

  const [columnDefsProject, setColumnDefsProject] = useState(columnDef)

  const initialValues = {
    startProjectYear: '',
    endProjectYear: '',
    startTotalSize: '',
    endTotalSize: '',
    purpose: '',
    purposeName: '',
    orderType: '',
    orderTypeName: '',
    division: '',
    projectStatus: '',
    order: 0,
    searchText: ''
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object().shape({
      startTotalSize: Yup.number().typeError('숫자'),
      endTotalSize: Yup.number().typeError('숫자')
    }),
    onSubmit: (values) => {
      getList();
    }
  });

  const [sortView, setSortView] = useState('');
  const toggleSortView = () => {
    if (sortView === '') setSortView('view');
    else setSortView('');
  }

  /***************************************************************************************
   * 설명 : 프로젝트 리스트 컴포넌트
  ***************************************************************************************/
  const ListItem = (props) => {
    return (
      <div
        style={{ display: 'block', height: '70px', padding: '5px 20px 0px 0px' }}
        className="cp"
      >
        <div className="fl project-info">
          <div>
            <span className="f12">[{props.item.projectNumber}]</span>
            <span className="f12 ml10">연면적 : {props.item.totalSize}㎡</span>
          </div>
          <div className="mt5 project-nm">
            <span className="normal">{props.item.projectName}</span>
          </div>
        </div>
      </div>
    )
  }

  // 참여업체 컬럼
  const [columnDefs] = useState([
    { headerName: '', field: 'seq', cellClass: 'cp', width: 35, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
    { headerName: '프로젝트', field: 'projectNumber', width: 260, cellClass: 'cp',
      cellRendererFramework: function(params) {
        return (
          <ListItem
            item={params.data}
            index={params.node.rowIndex}
          />
        )
      }
    },
    {headerName: '선택', field: 'select', cellClass: 'flex cp center vcenter', width: 50,
      cellRendererFramework: (params) => {
        return (
          <ButtonEx
            title="선택"
            auth="isModify"
            color="secondary"
            variant="outlined"
            className="gridBtn"
            //onClick={() => addProject(params, gridApiProject)}
          />
        )
      }
    }
  ]);

  const getRowHeight = useCallback((params) => {
    return 70;
  }, []);

  /***************************************************************************************
   * 설명 : 저장하기
  ***************************************************************************************/
  const submit = () => {
    let tmp = [...props.list];
    let keyField = selectedProject.current.map((item) => item.projectNumber);

    let tmp1 = tmp.map((item, index) => {
      let tmp2 = {
        seq: item.seq,
        costMemberSeq: item.costMemberSeq,
        costItemSeq: item.costItemSeq,
        rowIndex: index,
        type: item.type,
        title: item.title,
        baseAmt: item.baseAmt,
        avgAmt: costFormList[index].avgAmt ?? 0,
        amt: item.amt,
        memo: item.memo
      }

      for(let key of keyField) {
        tmp2[key + '_Check'] = costFormList[index][key + '_Check'];
        tmp2[key] = costFormList[index][key];
      }

      return tmp2;
    });

    // 부모창 데이터 추가
    if( props.setList ) {
      props.setList(tmp1);

      // 부모창 컬럼 변경
      if( props.addColumn ) {
        props.addColumn(selectedProject.current);
      }

      if( props.setProjectList ) {
        props.setProjectList(selectedProject.current);
      }
    }

    props.close();
  }

  /***************************************************************************************
   * 설명 : 컬럼 전체 선택
  ***************************************************************************************/
  const setColumnAll = (e, params) => {
    let nodes = [];

    params.api.forEachNode( (node) => {
      if( e.target.checked ) {
        node.setDataValue( params.column.colDef.field, 1 );

      } else {
        node.setDataValue( params.column.colDef.field, 0 );
      }

      nodes.push(node.data);
    });

    // 데이터 계산
    let tmp = calc(nodes);
    setCostFormList(tmp);
  }

  /***************************************************************************************
   * 설명 : 컬럼 조정
  ***************************************************************************************/
  const setColumnCheck = (e, params) => {
    params.node.data[params.column.colDef.field] = (e.target.checked === false) ? 0 : 1;

    let nodes = [];
    params.api.forEachNode( (node) => {
      if( e.target.checked ) {
        node.updateData(node.data);
      } else {
        node.updateData(node.data);
      }

      nodes.push(node.data);
    });

    // 데이터 계산
    let tmp = calc(nodes);
    setCostFormList(tmp);
  }

  /***************************************************************************************
   * 설명 : 헤더 체크
  ***************************************************************************************/
  function agGridHeaderCheckbox(params, props) {
    const className = ( props.className !== undefined ) ? 'Checkbox ' + props.className : 'Checkbox';

    return (
      <>
        <Checkbox
          className={className}
          onChange={(e) => {setColumnAll(e, params)}}
          color="primary"
        />
        <span>{params.displayName}</span>
      </>
    )
  }

  /***************************************************************************************
   * 설명 : 체크박스 처리
  ***************************************************************************************/
  function agGridCheckbox(params, props) {
    let check = ( params.value === '0' || params.value === null || params.value === 0 ) ? false : true;
    const className = ( props.className !== undefined ) ? 'Checkbox ' + props.className : 'Checkbox';

    return (
      <Checkbox
        className={className}
        onChange={(e) => {setColumnCheck(e, params)}}
        checked={check}
        size="small"
        color="primary"
      />
    )
  }

  /***************************************************************************************
   * 설명 : agGrid에 프로젝트 필드 추가
  ***************************************************************************************/
  const addProjectColumn = (data) => {
    let column = [
      { headerName: '', field: data.projectNumber + '_Check', width: 40, cellClass: 'cp p0',
        headerComponentFramework: (params) => agGridHeaderCheckbox(params, props),
        cellRendererFramework: (params) => agGridCheckbox(params, props)
      },
      { headerName: data.projectName, field: data.projectNumber, width: 120, cellClass: 'cp text-right', valueFormatter: CurrencyFormat,
        cellClassRules: {
          'aggrid-cell-max': (params) => {
            const key = params.columnApi.columnModel.columnDefs?.filter((item) => {
              return ( item.field.indexOf('_Check') > -1 ) ? true : false;
            });
            const tmp = key.map((item) => item.field.replace('_Check', ''));
            const keys = [...new Set(tmp)];

            if( keys.length > 2 ) {
              // 0을 제외한 프로젝트 갯수가 3개이상일 경우만 판단
              let projectCount = keys.filter((item) => parseInt(params.data[item] ?? 0) > 0);

              if( projectCount.length > 2 ) {
                let tmp = keys.map((item) => params.data[item] ?? 0);
                let tmp1 = Math.max(...tmp);

                if( params.value !== 0 && parseInt(tmp1) === parseInt(params.value ?? 0) ) {
                  return true;
                }
              }
            }

            return false;
          },
          'aggrid-cell-min': (params) => {
            const key = params.columnApi.columnModel.columnDefs?.filter((item) => {
              return ( item.field.indexOf('_Check') > -1 ) ? true : false;
            });
            const tmp = key.map((item) => item.field.replace('_Check', ''));
            const keys = [...new Set(tmp)];

            if( keys.length > 2 ) {
              // 0을 제외한 프로젝트 갯수가 3개이상일 경우만 판단
              let projectCount = keys.filter((item) => parseInt(params.data[item] ?? 0) > 0);

              if( projectCount.length > 2 ) {
                let tmp = keys.map((item) => params.data[item] ?? 0);
                let tmp2 = tmp.filter((item) => item > 0 );
                let tmp1 = Math.min(...tmp2);

                if( params.value !== 0 && parseInt(tmp1) === parseInt(params.value ?? 0) ) {
                  return true;
                }
              }
            }

            return false;
          }
        }
      },
    ];

    let columnDef = [
      ...columnDefsProjectRef.current,
    ].concat(column);

    columnDefsProjectRef.current = columnDef;
    setColumnDefsProject(columnDef);
  }

  /***************************************************************************************
   * 설명 : 프로젝트 추가
  ***************************************************************************************/
  const addProject = (params) => {
    // 기존에 추가된 프로젝트인지 검증
    let tmp = columnDefsProjectRef.current.filter((item) => item.field === params.data.projectNumber);

    if( tmp.length > 0 ) {
      alertMsg("이미 추가된 프로젝트입니다.", "W", MESSAGE_DELAY);
      return;
    }

    // 선택된 데이터 추가
    selectedProject.current.push({
      ...params.data,
      label: '[' + params.data.projectNumber + '] ' + params.data.projectName,
      value: params.data.projectNumber
    });

    // 선택된 프로젝트 서버에서 정보 가져오기
    let parameter = {
      projectNumber: params.data.projectNumber
    }

    getProjectCostItemApi(getApi, parameter, (response) => {
      if(response !== undefined && response.data.result && response.data.data && response.data.data.length > 1 ) {
        setAddProjectInfo([
          ...addProjectInfo,
          response.data.data
        ]);

        // 항목 바인딩 및 평균값 계산
        let tmp = [];
        gridApiProject.current?.forEachNode((item) => {
          let tmp1 = response.data.data.filter((subItem) => subItem.costItemSeq === item.data.costItemSeq);
          let tmp2 = {...item.data};
          tmp2[params.data.projectNumber + '_Check'] = 1;
          tmp2[params.data.projectNumber] = ( tmp1.length > 0 ) ? tmp1[0].baseAmt : 0;

          tmp.push(tmp2);
        });

        let tmp1 = calc(tmp);

        // 프로젝트 참조프로젝트에 추가
        addProjectColumn(params.data);

        // 데이터 추가
        setCostFormList(tmp1);
      }
    });
  }

  /***************************************************************************************
   * 설명 : 추가된 프로젝트 평균단가 계산
  ***************************************************************************************/
  const calc = (data) => {
    // 프로젝트 번호로 키값 찾기
    let keyField = Object.keys(data[0]).filter((item) => item.indexOf('-') > -1 && item.indexOf('_Check') < 0 );

    // 프로젝트 체크 키값 찾기
    let keyCheckField = Object.keys(data[0]).filter((item) => item.indexOf('_Check') > -1 );

    // 추가된 프로젝트 비용 계산
    let tmp1 = data.map((item) => {

      let tmp2 = keyField.map((key) => item[key]);
      let tmp3 = keyCheckField.map((key) => item[key]);

      let total = 0;
      let count = 0;
      tmp2.forEach((subItem, index) => {
        if(parseInt(tmp3[index]) === 1 && parseInt(subItem ?? 0) !== 0) {
          total += subItem;
          count++;
        }
      });

      let tmp4 = {
        ...item
      };

      tmp4.avgAmt = Math.round(total / count);

      return tmp4;
    });

    return tmp1;
  }

  /***************************************************************************************
   * 설명 : 프로젝트 제거
  ***************************************************************************************/
  const deleteProject = () => {
    if( formik.values.project === '' ) {
      alertMsg("설계비가산정 참조 프로젝트에서 삭제할 프로젝트를 선택하시기 바랍니다.", "W", MESSAGE_DELAY);
      return;
    }

    // 컬럼 삭제
    let columnDef = [
      ...columnDefsProjectRef.current,
    ];

    let tmp = columnDef.filter((item) => item.field.indexOf(formik.values.project) < 0 );

    columnDefsProjectRef.current = tmp;
    setColumnDefsProject(tmp);

    // 프로젝트 데이터 삭제
    tmp = [...costFormList];
    let keyList = [];

    for(let key in tmp[0]) {
      if( key.indexOf(formik.values.project) < 0 )
        keyList.push(key);
    }

    let tmp1 = tmp.map((item) => {
      let tmp2 = {};
      for(let key of keyList)
        tmp2[key] = item[key];

      return tmp2;
    });

    // 평균단가 재계산
    let tmp2 = calc(tmp1);

    setCostFormList(tmp2);

    // select box 에서 삭제
    // selectedProject.current
    tmp2 = [...selectedProject.current.filter((item) => item.projectNumber !== formik.values.project)];
    selectedProject.current = tmp2;
  }

  /***************************************************************************************
   * 설명 : 용도 가져오기
  ***************************************************************************************/
  const getPurpose = () => {
    getCostPurposeApi(getApi, (response) => {
      if( response !== undefined && response.data.result !== false && response.data.data && response.data.data.length > 0 ) {
        setPurpose(getSelectData([
          ...response.data.data.filter((item) => {
            return item.commonCode.length === 3
          })], 'commonName', 'commonCode')
        );
      } else {
        setPurpose([]);
      }
    });
  }

  /***************************************************************************************
   * 설명 : 건축구분 리스트 가져오기
  ***************************************************************************************/
  const getProjectDivisionList = () => {
    getProjectDivisionApi(getApi, (response) => {
      if( response !== undefined && response.data.result !== false && response.data.data && response.data.data.length > 0 ) {
        setDivision(getSelectData(response.data.data, 'projectDivisionName', 'projectDivision'));
      } else {
        setDivision([]);
      }
    })
  }

  /***************************************************************************************
   * 설명 : 프로젝트 상태 리스트 가져오기
  ***************************************************************************************/
  const getProjectStatusList = () => {
    getProjectStatusApi(getApi, (response) => {
      if( response !== undefined && response.data.result !== false && response.data.data && response.data.data.length > 0 ) {
        setStatus(getSelectData(response.data.data, 'projectStatusName', 'projectStatus'));
      } else {
        setStatus([]);
      }
    })
  }


  /***************************************************************************************
   * 설명 : 시작 종료년도 가져오기
  ***************************************************************************************/
  const getCostYear = () => {
    getCostProjectYearApi(getApi, (response) => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        setYear(getSelectData(response.data.data, 'year', 'year'));

        formik.setFieldValue('startProjectYear', response.data.data[0].year);
        formik.setFieldValue('endProjectYear', response.data.data[0].year);
      } else {
        setYear([]);
      }
    });
  }

  /***************************************************************************************
   * 설명 : 설계비가산정 프로젝트 정보 가져오기
  ***************************************************************************************/
  const getList = () => {
    let params = {...formik.values};

    getCostProjectListApi(getApi, params, (response) => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        setList(response.data.data);
      } else {
        setList([]);
      }
    })
  }

  /***************************************************************************************
   * 설명 : 프로젝트 리스트 가져오기
  ***************************************************************************************/
  useEffect(() => {
    if( props.open && year.length > 0 ) {
      getList();
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    year,
    formik.values.startProjectYear, formik.values.endProjectYear,
    formik.values.purpose, formik.values.projectDivision, formik.values.projectStatus
  ]);

  /***************************************************************************************
   * 설명 : 데이터 처리
  ***************************************************************************************/
  useEffect(() => {
    if( props.open ) {
      getPurpose();

      getProjectDivisionList();

      getProjectStatusList();

      getCostYear();

      setCostFormList(props.list);

      selectedProject.current = props.projectList;

      let tmp = [...props.columnDefs.current];
      let tmp1 = tmp.filter((item) => ! ['memo', 'baseAmt', 'amt'].includes(item.field) );
      columnDefsProjectRef.current = tmp1;
      setColumnDefsProject(tmp1);
    }

    // eslint-disable-next-line
  }, [props.open])

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <section className="modal">
      <section className="modal-wrap" style={{ width: '1400px'}}>
        <form onSubmit={formik.handleSubmit}>
          <header className="modal-header" style={{ cursor: 'move' }} id="draggable-dialog-title">
            <div className="modal-title">설계비가산정 참조 프로젝트관리</div>
            <Button className="close-btn" onClick={props.close}>
              <CloseIcon></CloseIcon>
            </Button>
          </header>

          <section className="modal-body" style={{height: '715px'}}>
            <section className="modal-left-area">
              <header className="left-header pb0">
                <div className="mb5">
                  <SelectEx
                    name="startProjectYear"
                    formik={formik}
                    fullWidth={false}
                    style={{width: 'calc(50% - 5px)', marginRight: '5px'}}
                    data={[
                      {label: '연도 시작', value: ''},
                    ].concat(year)}
                  />
                  <SelectEx
                    name="endProjectYear"
                    formik={formik}
                    fullWidth={false}
                    style={{width: '50%'}}
                    data={[
                      {label: '연도 끝', value: ''},
                    ].concat(year)}
                  />
                </div>
                <div className="mb5">
                  <InputEx
                    name="startTotalSize"
                    formik={formik}
                    label="연면적 시작검색"
                    fullWidth={false}
                    simple={true}
                    style={{width: 'calc(50% - 5px)'}}
                    className="search-input inputbox"
                    inputProps={{maxLength: 10}}
                  />
                  <InputEx
                    name="endTotalSize"
                    formik={formik}
                    label="연면적 종료검색"
                    fullWidth={false}
                    simple={true}
                    style={{width: '50%'}}
                    className="search-input inputbox ml5"
                    inputProps={{maxLength: 10}}
                  />
                </div>

                <SelectEx
                  name="purpose"
                  formik={formik}
                  fullWidth={false}
                  style={{width: 'calc(50% - 5px)', marginRight: '5px'}}
                  data={[
                    {label: '용도', value: ''}
                  ].concat(purpose)}
                />

                <SelectEx
                  name="projectDivision"
                  formik={formik}
                  fullWidth={false}
                  style={{width: '50%'}}
                  data={[
                    {label: '건축구분', value: ''},
                  ].concat(division)}
                />

                <div className="mt5">
                  <SelectEx
                    name="projectStatus"
                    formik={formik}
                    fullWidth={true}
                    data={[
                      {label: '프로젝트 상태', value: ''},
                    ].concat(status)}
                  />
                </div>

                <div className="mt5">
                  <InputEx
                    simple={true}
                    name="searchText"
                    formik={formik}
                    placeholder="프로젝트명 검색"
                    fullWidth={false}
                    style={{width: 'calc(100% - 65px)'}}
                    className="search-input inputbox"
                  />

                  <ButtonEx
                    type="submit"
                    title="검색"
                    auth="isWrite"
                    variant="outlined"
                    color="secondary"
                    className="ml5"
                  />
                </div>

                <div className="clearfix">
                  <div className="cnt" style={{lineHeight: '30px'}}>총 {comma(list.length)}개</div>
                  <div className={"sort " + sortView}>
                    <div className="selected" onClick={() => toggleSortView()} style={{lineHeight: '30px'}}>
                      {initOrderBy[formik.values.order].label}
                    </div>
                    <ul className="select-list" onClick={() => setSortView('')}>
                      {initOrderBy.map((item) => {
                        return (
                          <li onClick={() => {
                            formik.setFieldValue('order', item.value);
                            formik.handleSubmit();
                          }}>{item.label}</li>
                        )
                      })}
                    </ul>
                  </div>
                </div>
              </header>

              <div className="clearfix"></div>

              <div className="ag-theme-balham header-hide" style={{height: '520px'}}>
                <AgGridReact
                  defaultColDef={{
                    sortable: true,
                    resizable: true,
                    filter: false,
                    lockVisible: true,
                    tooltipComponent: customTooltipAgGrid,
                  }}
                  headerHeight={0}
                  tooltipShowDelay={0}
                  tooltipHideDelay={2000}
                  alwaysShowVerticalScroll={true}
                  columnDefs={columnDefs}
                  rowData={list}
                  rowSelection={'single'}
                  getRowHeight={getRowHeight}
                  onGridReady={(event) => {
                  }}
                  onCellClicked={(event) => {
                    if(event.colDef.field === 'select' ) {
                      addProject(event, gridApiProject)
                    }
                  }}
                  overlayNoRowsTemplate = "검색된 내용이 없습니다."
                />
              </div>
            </section>

            <section className="modal-right-area">
              <header className="admin-cms-search-header mb5">
                <div className="left">
                  <div className="fl">
                    <SelectEx
                      name="project"
                      formik={formik}
                      fullWidth={false}
                      data={[
                        {label: '삭제할 프로젝트 선택', value: ''}
                      ].concat(selectedProject.current)}
                      style={{width: '500px'}}
                    />

                    <ButtonEx
                      auth="isWrite"
                      title="선택 프로젝트 제외"
                      color="error"
                      variant="outlined"
                      className="ml5"
                      disabled={selectedProject.current?.length < 1}
                      onClick={() => deleteProject()}
                    />
                  </div>
                  <div className="fr">
                  </div>
                </div>

                <div className="clearfix"></div>
              </header>

              <div className="clearfix"></div>

              <section className="mt5 ag-theme-balham" style={{height:'642px'}}>
                <AgGridReact
                  defaultColDef={{
                    sortable: true,
                    resizable: true,
                    filter: false,
                    lockVisible: true,
                    suppressMovable: true,
                    tooltipComponent: customTooltipAgGrid,
                  }}
                  suppressScrollOnNewData={true}
                  tooltipShowDelay={0}
                  tooltipHideDelay={2000}
                  rowSelection = {'multiple'}
                  columnDefs={columnDefsProject}
                  rowData={costFormList}
                  onGridReady={(event) => {
                    gridApiProject.current = event.api;
                  }}
                  rowDragManaged={true}
                  rowDragMultiRow={true}
                  animateRows={true}
                  overlayNoRowsTemplate = "검색된 내용이 없습니다."
                />
              </section>
            </section>
          </section>

          <div className="clearfix"></div>

          <footer className="modal-footer">
            <div className="modal-footer-center">
              <Button
                color="inherit"
                variant="outlined"
                className="Btn"
                onClick={() => {
                  props.close();
                }}
              >창닫기</Button>

              <Button
                type="button"
                variant="contained"
                color="primary"
                className="Btn ml10"
                onClick={() => setOpenModal({open: true, modal: 'add', data: {}})}
              >참조프로젝트 설정</Button>
            </div>
          </footer>
        </form>
      </section>

      { openModal.open && openModal.modal === 'add' &&
        <Dialog
          open={openModal.open}
          onClose={() => setOpenModal({ open: false, modalName: 'dialog', data: openModal.data })}
          PaperComponent={PaperComponentSub}
          aria-labelledby="draggable-dialog-title-sub"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "450px",  // Set your width here
              },
            },
          }}
        >
          <AlertDialog
            open={openModal.open}
            close={() => setOpenModal({ open: false, modalName: 'dialog', data: openModal.data })}
            title="설계비가산정 양식 참조프로젝트 설정"
            message={`입력하신 설계비가산정 참조프로젝트를 설정 하시겠습니까?`}
            confirm="설정하기"
            color="primary"
            onClick={submit}
            indicator={props.indicator}
            history={props.history}
          />
        </Dialog>
      }
    </section>
  )
}

export default ProjectAddModal;