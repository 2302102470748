/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { getGridApi, setGridSaveApi } from 'service/common';
import Restful from "service/restful";

import alertMsg from 'components/message';

const useGridConfig = (initialValues, setColumnDefs, isMessage = true) => {

  const [getApi] = Restful();

  const [gridApi, setGridApi] = useState({});
  const [gridNo] = useState(initialValues);
  const currentPage = useSelector(state => state.menuList?.current);

  /***************************************************************************************
   * 설명 : 그리드 설정 불러오기
  ***************************************************************************************/
  const getGrid = () => {
    let params = {
      menuSeq: currentPage?.seq,
      gridNo: gridNo
    }

    getGridApi(getApi, params, (response) => {
      if( gridApi !== undefined && response !== undefined && response.data.result && response.data.data) {
        if (gridApi?.columnModel && response.data.result && response.data.data) {

          const columns = JSON.parse(response.data.data.gridJson);
          let newColumns = [];
          let otherColumns = [];

          columns.forEach((item) => {
            let tmp = gridApi.columnModel.columnDefs.filter((subItem) => subItem.field === item.field)
            if( tmp.length > 0 ) {
              tmp[0].width = item.actualWidth;
              newColumns.push(tmp[0]);
            }
          });

          gridApi.columnModel.columnDefs.forEach((item, index) => {
            let tmp = columns.filter((subItem) => subItem.field === item.field )

            if( tmp.length < 1 ) {
              otherColumns.push(item);
            }
          });

          newColumns = newColumns.concat(otherColumns);

          if (setColumnDefs instanceof Function)
            setColumnDefs( newColumns );
          else
            setColumnDefs.current = newColumns;
        };
      }
    });
  }

  /***************************************************************************************
   * 설명 : 그리드 설정저장
  ***************************************************************************************/
  const setGridUpdate = (() => {
    if (!gridApi) {
      alertMsg("그리드 설정이 되어 있지 않습니다.", "E", 3000);
      return;
    };

    const data = gridApi.columnModel.getAllDisplayedColumns().map(column => ({
      field: column.colId,
      actualWidth: column.actualWidth
    }));

    let params = {
      menuSeq: currentPage.seq,
      gridNo: gridNo,
      gridJson: JSON.stringify(data)
    }

    setGridSaveApi(getApi, params, (response) => {
      if (response.data.result) {
        getGrid();

        if( isMessage )
          alertMsg(response.data.message, "S", 3000);

      } else {
        if( isMessage )
          alertMsg(response.data.message, 'E', 3000);
      }
    });
  });

  /***************************************************************************************
   * 설명 : 그리드 설정 로딩
  ***************************************************************************************/
  useEffect(() => {
    if( gridApi !== undefined ) {
      getGrid();
    }

  }, [gridApi]);

  return [setGridApi, setGridUpdate];
}

export default useGridConfig;