/*****************************************************************************************
 * 설명 : 관리자 > 시스템관리 > 공통코드 관리 > 그룹 추가/수정
*****************************************************************************************/
import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import * as Yup from "yup";

import Restful from "service/restful";
import { setCommonGroupApi } from 'service/common';

import { InputEx, ToggleButtonGroupEx } from 'components/inputEx';
import alertMsg from 'components/message';
import { MESSAGE_DELAY } from 'config/config';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
export const CommonGroupModal = ( props ) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [getApi] = Restful();

  const [title, setTitle] = useState('추가');

  const formik = useFormik({
    initialValues: {
      seq: 0,
      groupType: 0,
      groupCode: '',
      groupName: '',
      isUse: 1,
    },
    validationSchema: Yup.object().shape({
      groupName: Yup.string().max(30, "30자리").required('필수'),
      groupCode: Yup.string().max(20, "20자리").required('필수'),
    }),
    onSubmit: (values) => {
      setCommonGroupApi(getApi, values, (response => {
        if( response !== undefined && response.data.result ) {
          alertMsg(response.data.message, "S", MESSAGE_DELAY);

          if( props.getList ) props.getList();

          props.close();

        } else {
          alertMsg(response.data.message || '서버와의 통신에 실패하였습니다.', "E", MESSAGE_DELAY);
        }
      }));
    }
  });

  /***************************************************************************************
   * 설명 : 모달 오픈 시 처리
  ***************************************************************************************/
  useEffect(() => {
    if( props.open ) {
      if( props.data.seq !== undefined && props.data.seq !== '' && props.data.seq !== 0 ) {
        formik.setValues(props.data);

        setTitle('수정');
      } else {
        setTitle('추가');
      }
    }

    // eslint-disable-next-line
  }, [props.open]);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <section className="modal-wrap" style={{ width: '550px' }}>
      <form onSubmit={formik.handleSubmit}>
        <header className="modal-header" style={{ cursor: 'move' }} id="draggable-dialog-title">
          <div className="modal-title">공통코드그룹 {title}</div>
          <Button className="close-btn" onClick={props.close}>
            <CloseIcon style={{ color: '#fff', fontSize: '28px' }}></CloseIcon>
          </Button>
        </header>

        <section className="modal-body">
          <table className="input-table">
            <colgroup>
              <col style={{width: '30%'}} />
              <col style={{width: '70%'}} />
            </colgroup>

            <tbody>

              <tr>
                <th scope="row"><label htmlFor="groupCode">공통코드그룹 코드</label></th>
                <td>
                  <InputEx
                    name="groupCode"
                    formik={formik}
                    fullWidth={true}
                    inputProps={{maxLength: 20, autoFocus: true}}
                    placeholder={"공통코드 그룹코드 입력(영문,숫자,_조합"}
                  />
                </td>
              </tr>
              <tr>
                <th scope="row"><label htmlFor="groupName">공통코드그룹명</label></th>
                <td>
                  <InputEx
                    name="groupName"
                    formik={formik}
                    fullWidth={true}
                    placeholder={"공통코드 그룹명 입력"}
                  />
                </td>
              </tr>
              <tr>
                <th scope="row"><label htmlFor="groupType">구분</label></th>
                <td>
                  <ToggleButtonGroupEx
                    name="groupType"
                    exclusive={true}
                    formik={formik}
                    data={[
                      {label: '일반', value: 0},
                      {label: '시스템', value: 1},
                    ]}
                  />
                </td>
              </tr>
              <tr>
                <th scope="row"><label htmlFor="isUse">사용여부</label></th>
                <td>
                  <ToggleButtonGroupEx
                    name="isUse"
                    exclusive={true}
                    formik={formik}
                    data={[
                      {label: '사용', value: 1},
                      {label: '사용안함', value: 0},
                    ]}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </section>

        <footer className="modal-footer">
          <div className="modal-footer-center">
            <Button
              color="inherit"
              variant="outlined"
              className="Btn"
              onClick={() => {
                props.close();
              }}
            >창닫기</Button>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              className="Btn ml10"
            >저장하기</Button>
          </div>
        </footer>
      </form>
    </section>
  )
}

export default CommonGroupModal;