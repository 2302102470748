/*****************************************************************************************
 * 설명 : Footer
*****************************************************************************************/
import React from 'react';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const Footer = (props) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/

  /***************************************************************************************
   * 설명 : html 선언부
   * <footer className="footer">
      <div className="footer-txt">Copyright © 2022 by dA Group Urban Design & Architecture Co., Ltd. All Rights Reserved.</div>
    </footer>
  ***************************************************************************************/
  return (
    <>
    </>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default Footer;