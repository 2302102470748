/*****************************************************************************************
 * 설명 : 프로젝트관리 - 신기술
 * URI : /project
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import Button from '@mui/material/Button';
import { AgGridReact } from 'ag-grid-react';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from "yup";

import { MESSAGE_DELAY } from 'config/config';
import useGridConfig from 'hooks/useGridConfig';

import { comma } from 'service/utils';

import ChipEx from 'components/chipEx';
import { BtnRefresh, BtnSearch } from 'components/common';
import { ButtonEx, InputEx, ToggleButtonGroupEx } from 'components/inputEx';
import alertMsg from 'components/message';
import customTooltipAgGrid from 'components/tooltipAgGrid';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const NewTechModal = (props) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [list, setList] = useState([]);
  const [selectedList, setSelectedList] = useState([]);

  const [gridApi, setGridApi] = useState({});
  const [gridApiAdd, setGridApiAdd] = useState({});

  const initialValues = {
    searchText: '',
    smart: '',
    status: ''
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object().shape({
    }),
    onSubmit: (values) => {
      let tmp = [...props.newTech];

      // 검색 기능 적용
      if( values.searchText !== '' ) {
        let tmp1 = tmp.filter((item) => {
          if(
            item.commonName.indexOf(values.searchText) > -1 ||
            item.val1.indexOf(values.searchText) > -1 ||
            item.val2.indexOf(values.searchText) > -1
          ) return true;
          else return false;
        });
        tmp = tmp1;
      }

      // 스마트건설기술 검색
      if( values.smart !== '' ) {
        let tmp1 = tmp.filter((item) => {
          if(values.smart === 1 && item.val4 === '해당') return true;
          else if(values.smart === 2 && item.val4 === '미해당') return true;
          else return false;
        });
        tmp = tmp1;
      }

      // 상태 검색
      if( values.status !== '' ) {
        let tmp1 = tmp.filter((item) => {
          if(values.status === 1 && item.val5 === '유효') return true;
          else if(values.status === 2 && item.val5 === '만료') return true;
          else return false;
        });
        tmp = tmp1;
      }

      setList([
        ...tmp.map((item) => {
          return {
            ...item,
            searchText: formik.values.searchText
          }
        })
      ]);

    }
  });

  /***************************************************************************************
   * 설명 : 검색어를 찾아 빨간색으로 표시
   * searchText = 검색어
   * value = 원본 문자열
  ***************************************************************************************/
  const getSearchText = (searchText, value) => {
    if( searchText !== undefined && searchText !== '' ) {
      let changeText = '<span class="cred bold">' + searchText + '</span>';
      let reg = new RegExp(`${searchText}`, 'g');
      let text = value;
      let replaceText = text?.replace(reg, changeText);
      let html = <span dangerouslySetInnerHTML={{__html: replaceText}}></span>;

      return html;

    } else {
      return value;
    }
  }

  /***************************************************************************************
   * 설명 : 업체 검색
  ***************************************************************************************/
  // table column
  const [columnDefs, setColumnDefs] = useState([
    { headerName: '', field: 'seq', width: 35, cellClass: 'center', flex: 0,
      headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
    { headerName: '순번', field: 'rowIndex', width: 45, cellClass: 'cp text-right',
      valueGetter: (params) => {
        return comma(params.node.rowIndex + 1);
      }
    },
    { headerName: '검색어', field: 'searchText', hide: true },
    { headerName: '지정번호', field: 'commonCode', width: 70, cellClass: "cp text-right" },
    { headerName: '신기술명', field: 'commonName', width: 250, cellClass: "cp",
      cellRendererFramework: (params) => {
        return getSearchText(params.data.searchText, params.data.commonName);
      }
    },
    { headerName: '개발사(기관)', field: 'val1', width: 230, cellClass: "cp",
      cellRendererFramework: (params) => {
        return getSearchText(params.data.searchText, params.data.val1);
      }
    },
    { headerName: '기술분야', field: 'val2', width: 200, cellClass: "cp",
      cellRendererFramework: (params) => {
        return getSearchText(params.data.searchText, params.data.val2);
      }
    },
    { headerName: '보호기간', field: 'val3', width: 170, cellClass: "cp center" },
    { headerName: '스마트건설기술', field: 'val4', width: 70, cellClass: "cp center",
      cellRendererFramework: function(params) {
        if( params.data.val4 === '해당' )
          return <ChipEx color="primary" variant="outlined" size="small" label="해당" />;
        else
          return <ChipEx color="error" variant="outlined" size="small" label="미해당" />;
      }
    },
    { headerName: '상태', field: 'val5', width: 60, cellClass: "cp center",
      cellRendererFramework: function(params) {
        if( params.data.val5 === '유효' )
          return <ChipEx color="success" variant="outlined" size="small" label="유효" />;
        else
          return <ChipEx color="error" variant="outlined" size="small" label="만료" />;
      }
    },
  ]);

  const [columnDefsAdd, setColumnDefsAdd] = useState([
    { headerName: '', field: 'seq', width: 35, cellClass: 'center', flex: 0,
      headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
    { headerName: '순번', field: 'rowIndex', width: 45, cellClass: 'cp text-right',
      valueGetter: (params) => {
        return comma(params.node.rowIndex + 1);
      }
    },
    { headerName: '지정번호', field: 'commonCode', width: 70, cellClass: "cp text-right" },
    { headerName: '신기술명', field: 'commonName', width: 250, cellClass: "cp" },
    { headerName: '개발사(기관)', field: 'val1', width: 230, cellClass: "cp" },
    { headerName: '기술분야', field: 'val2', width: 200, cellClass: "cp" },
    { headerName: '보호기간', field: 'val3', width: 170, cellClass: "cp center" },
    { headerName: '스마트건설기술', field: 'val4', width: 70, cellClass: "cp center",
      cellRendererFramework: function(params) {
        if( params.data.val4 === '해당' )
          return <ChipEx color="primary" variant="outlined" size="small" label="해당" />;
        else
          return <ChipEx color="error" variant="outlined" size="small" label="미해당" />;
      }
    },
    { headerName: '상태', field: 'val5', width: 60, cellClass: "cp center",
      cellRendererFramework: function(params) {
        if( params.data.val5 === '유효' )
          return <ChipEx color="success" variant="outlined" size="small" label="유효" />;
        else
          return <ChipEx color="error" variant="outlined" size="small" label="만료" />;
      }
    },
  ]);

  const [gridConfig] = useGridConfig(99, setColumnDefs);
  const [gridConfigAdd] = useGridConfig(98, setColumnDefsAdd);

  /***************************************************************************************
   * 설명 : 신기술 추가
  ***************************************************************************************/
  const addNewTech = () => {
    let nodes = gridApi.getSelectedRows();

    if( nodes.length < 1) {
      alertMsg("추가할 신기술을 선택하시기 바랍니다.", "W", MESSAGE_DELAY);
      return;
    }

    // 중복 내역 필터링
    let tmp = nodes.filter((item) => {
      let tmp1 = selectedList.filter((subItem) => subItem.seq === item.seq);
      return (tmp1.length > 0 ) ? false : true;
    })

    setSelectedList([
      ...selectedList,
      ...tmp
    ]);
  }

  /***************************************************************************************
   * 설명 : 신기술 삭제
  ***************************************************************************************/
  const delNewTech = () => {
    let nodes = gridApiAdd.getSelectedRows();

    if( nodes.length < 1) {
      alertMsg("삭제할 신기술을 선택하시기 바랍니다.", "W", MESSAGE_DELAY);
      return;
    }

    let tmp = selectedList.filter((item) => {
      let tmp1 = nodes.filter((subItem) => subItem.seq === item.seq);
      return (tmp1.length > 0 ) ? false : true;
    });

    setSelectedList(tmp);
  }

  /***************************************************************************************
   * 설명 : 팝업 시 처리
  ***************************************************************************************/
  useEffect(() => {
    if( props?.newTech?.length > 0 ) {
      setList(props.newTech);

      if( props.newTech?.length > 0 && gridApi.forEachNode !== undefined ) {
        if( props.formik.values.newTechCode !== '' ) {
          let code = props.formik.values.newTechCode?.split(/,/gi);
          let tmp1 = [];
          gridApi.forEachNode((item) => {
            let tmp = code?.filter((subItem) => subItem === item.data.commonCode);
            if( tmp?.length > 0 ) tmp1.push(item.data);
          });

          setSelectedList(tmp1);
        }
      }
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open, gridApi]);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <section className="modal-wrap" style={{width: '100%'}}>
      <header className="modal-header" style={{ cursor: 'move' }} id="draggable-dialog-title">
        <div className="modal-title">신기술 검색</div>
        <Button className="close-btn" onClick={props.close}>×</Button>
      </header>

      <div className="clearfix"></div>

      <section className="modal-body">
        <header className="mb10">
          <form onSubmit={formik.handleSubmit}>
            <div className="fl">
              <BtnRefresh click={() => {
                formik.setValues(initialValues);
                formik.handleSubmit();
              }} />
              <BtnSearch />

              <InputEx
                name="searchText"
                formik={formik}
                fullWidth={false}
                style={{width: '250px'}}
                label="신기술명, 개발사, 기술분야 검색"
              />

              <span className="ml5"></span>

              <ToggleButtonGroupEx
                name="smart"
                formik={formik}
                exclusive={true}
                data={[
                  {label: '스마트건설기술 전체', value: ''},
                  {label: '해당', value: 1},
                  {label: '미해당', value: 2},
                ]}
              />

              <span className="ml5"></span><span className="ml5"></span>

              <ToggleButtonGroupEx
                name="status"
                formik={formik}
                exclusive={true}
                data={[
                  {label: '상태 전체', value: ''},
                  {label: '유효', value: 1},
                  {label: '만료', value: 2},
                ]}
              />
            </div>

            <div className="fr">
              <div className="f14 pt10">
                검색 개수 : {comma(list.length)} 건
              </div>
            </div>

            <div className="clearfix"></div>
          </form>
        </header>


        <div className="ag-theme-balham" style={{height: '350px'}}>
          <AgGridReact
            defaultColDef={{
              sortable: true,
              resizable: true,
              filter: false,
              lockVisible: true,
              tooltipComponent: customTooltipAgGrid,
            }}
            tooltipShowDelay={0}
            tooltipHideDelay={2000}
            columnDefs={columnDefs}
            rowData={list}
            rowSelection={'multiple'}
            onGridReady={(event) => {
              gridConfig(event.columnApi);
              setGridApi(event.api);
            }}
            overlayNoRowsTemplate = "검색된 내용이 없습니다."
          />
        </div>

        <header className="mt10 mb10 center">
          <ButtonEx
            auth="isModify"
            title="신기술 추가"
            variant="outlined"
            color="primary"
            onClick={() => addNewTech()}
          />

          <ButtonEx
            auth="isModify"
            title="신기술 삭제"
            variant="outlined"
            color="error"
            className="ml10"
            onClick={() => delNewTech()}
          />
        </header>

        <div className="ag-theme-balham" style={{height: '200px'}}>
          <AgGridReact
            defaultColDef={{
              sortable: true,
              resizable: true,
              filter: false,
              lockVisible: true,
              tooltipComponent: customTooltipAgGrid,
            }}
            tooltipShowDelay={0}
            tooltipHideDelay={2000}
            columnDefs={columnDefsAdd}
            rowData={selectedList}
            rowSelection={'multiple'}
            onGridReady={(event) => {
              gridConfigAdd(event.columnApi);
              setGridApiAdd(event.api);
            }}
            overlayNoRowsTemplate = "검색된 내용이 없습니다."
          />
        </div>
      </section>

      <footer className="modal-footer">
        <div className="modal-footer-center">
          <Button
            color="inherit"
            variant="outlined"
            className="Btn"
            onClick={() => {
              props.close();
            }}
          >창닫기</Button>

          <Button
            variant="contained"
            color="primary"
            className="Btn ml10"
            onClick={() => {
              if( selectedList.length > 0 ) {
                let tmp = selectedList.map((item) => item.commonName).join();
                let tmp1 = selectedList.map((item) => item.commonCode).join();
                props.formik.setFieldValue("newTech", tmp);
                props.formik.setFieldValue("newTechCode", tmp1);

                props.close();

              } else {
                alertMsg("신기술을 선택해 주시기 바랍니다.", "W", MESSAGE_DELAY);
              }
            }}
          >선택하기</Button>
        </div>
      </footer>
    </section>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default NewTechModal;