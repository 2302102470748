/*****************************************************************************************
 * 설명 : 관리자 > 시스템관리 > 공통코드 관리 > 공통코드 엑셀업로드
*****************************************************************************************/
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import { AgGridReact } from 'ag-grid-react';
import { useFormik } from 'formik';
import { useContext, useEffect, useState } from 'react';
import * as XLSX from 'xlsx';
import * as Yup from "yup";

import { MESSAGE_DELAY } from 'config/config';
import { IndicatorContext } from 'config/indicator';

import { setCommonExcelApi } from 'service/common';
import Restful from "service/restful";
import { comma } from 'service/utils';

import ChipEx from 'components/chipEx';
import { InputEx, ToggleButtonGroupEx } from 'components/inputEx';
import alertMsg from 'components/message';
import customTooltipAgGrid from 'components/tooltipAgGrid';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
export const CommonExcelModal = ( props ) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [getApi] = Restful();
  const { setIndicator } = useContext(IndicatorContext);

  const [gridApi, setGridApi] = useState({});

  const [list, setList] = useState([]);

  const formik = useFormik({
    initialValues: {
      groupCode: '',
      groupName: '',
      groupType: 0,
      data: []
    },
    validationSchema: Yup.object().shape({
    }),
    onSubmit: (values) => {
      let params = {...values}
      params.data = list;

      setCommonExcelApi(getApi, params, (response => {
        if( response !== undefined && response.data.result ) {
          alertMsg(response.data.message, "S", MESSAGE_DELAY);

          if( props.getList ) props.getList();

          props.close();

        } else {
          alertMsg(response.data.message || '서버와의 통신에 실패하였습니다.', "E", MESSAGE_DELAY);
        }
      }));
    }
  });

  // table column
  const [columnDefs] = useState([
    { headerName: '', field: 'seq', width: 40, cellClass: 'center', flex: 0,
      headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
    { headerName: '번호', field: 'rowIndex', width: 55, cellClass: 'cp text-right',
      valueGetter: (params) => {
        return comma(params.node.rowIndex + 1);
      }
    },
    { headerName:'공통코드', field: 'commonCode', width: 150, cellClass: 'cp' },
    { headerName:'공통코드명', field: 'commonName', width: 250, cellClass: 'cp' },
    { headerName:'추가값2', field: 'val1', width: 100, cellClass: 'cp' },
    { headerName:'추가값1', field: 'val2', width: 100, cellClass: 'cp' },
    { headerName:'추가값3', field: 'val3', width: 100, cellClass: 'cp' },
    { headerName:'추가값4', field: 'val4', width: 100, cellClass: 'cp' },
    { headerName:'추가값5', field: 'val5', width: 100, cellClass: 'cp' },
    { headerName:'메모', field: 'memo', width: 250, cellClass: 'cp' },
    { headerName: '사용여부', field: 'isUse', width: 70, cellClass: 'cp center',
      cellRendererFramework: function(params) {
        if( parseInt(params.data.isUse) === 1 )
          return <ChipEx color="success" variant="outlined" size="small" label="사용" />;
        else
          return <ChipEx color="default" variant="outlined" size="small" label="사용안함" />;
      }
    }
  ]);

  /*****************************************************************************************
   * 설명 : 엑셀 데이터 변경
  *****************************************************************************************/
  const excelToJson = async ($event) => {
    const target = $event.target;
    const reader = new FileReader();

    return await new Promise((resolve, reject) => {
      reader.onload = async (e) => {
        const workbook = XLSX.read(e.target.result, {type: 'array'});
        resolve(XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], {header: 1}));
      };
      //reader.readAsBinaryString(target.files[0]) // arrayBuffer 로 안될 경우 사용할 것
      if( target.files.length > 0 )
        reader.readAsArrayBuffer(target.files[0]);
    }).then(response => response)
  };

  /***************************************************************************************
   * 설명 : 엑셀업로드 제조번호 추가
  ***************************************************************************************/
  const excelAdd = async (event) => {
    setIndicator(true);

    const jsonData = await excelToJson(event);
    let data = [];
    jsonData.forEach((item, index) => {
      if( item.length > 0 && index > 0 ) {
        data.push({
          ...props.data,
          seq: '',
          rowIndex: '',
          groupCode: props.data.groupCode,
          commonCode: item[1],
          commonName: item[2],
          val1: item[3],
          val2: item[4],
          val3: item[5],
          val4: item[6],
          val5: item[7],
          memo: item[8],
          isUse: item[9]
        });
      }
    });

    setList(data);

    gridApi.applyTransaction({ add: data });

    setIndicator(false);
  }

  /***************************************************************************************
   * 설명 : 모달 오픈 시 처리
  ***************************************************************************************/
  useEffect(() => {
    if( props.open ) {
      formik.setValues({
        seq: props.data.seq,
        groupCode: props.data.groupCode,
        groupName: props.data.groupName,
        groupType: 0
      })
    }

    // eslint-disable-next-line
  }, [props.open]);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <section className="modal-wrap" style={{ width: '100%' }}>
      <form onSubmit={formik.handleSubmit}>
        <header className="modal-header" style={{ cursor: 'move' }} id="draggable-dialog-title">
          <div className="modal-title">공통코드 엑셀업로드</div>
          <Button className="close-btn" onClick={props.close}>
            <CloseIcon style={{ color: '#fff', fontSize: '28px' }}></CloseIcon>
          </Button>
        </header>

        <section className="modal-body">
          <table className="input-table">
            <colgroup>
              <col style={{width: '30%'}} />
              <col style={{width: '70%'}} />
            </colgroup>

            <tbody>

              <tr>
                <th scope="row"><label htmlFor="groupCode">공통코드그룹 코드</label></th>
                <td>
                  <InputEx
                    name="groupCode"
                    formik={formik}
                    fullWidth={true}
                    inputProps={{maxLength: 20, autoFocus: true}}
                    disabled={true}
                  />
                </td>
              </tr>
              <tr>
                <th scope="row"><label htmlFor="groupName">공통코드그룹명</label></th>
                <td>
                  <InputEx
                    name="groupName"
                    formik={formik}
                    fullWidth={true}
                    disabled={true}
                  />
                </td>
              </tr>
              <tr>
                <th scope="row"><label htmlFor="groupType">업로드 방식</label></th>
                <td>
                  <ToggleButtonGroupEx
                    name="groupType"
                    exclusive={true}
                    formik={formik}
                    data={[
                      {label: '삭제 후 추가', value: 0},
                      {label: '뒤에 추가', value: 1},
                    ]}
                  />
                </td>
              </tr>
              <tr>
                <th scope="row"><label htmlFor="file">업로드 파일</label></th>
                <td>
                  <InputEx
                    type="file"
                    name="file"
                    formik={formik}
                    fullWidth={true}
                    label="엑셀업로드"
                    style = {{fontSize: '12px'}}
                    className="left"
                    InputLabelProps={{ shrink: true }}
                    onChange={(event) => excelAdd(event)}
                  />
                </td>
              </tr>
            </tbody>
          </table>

          <div className="ag-theme-balham mt5" style={{height: '350px'}}>
            <AgGridReact
              defaultColDef={{
                sortable: true,
                resizable: true,
                filter: false,
                lockVisible: true,
                tooltipComponent: customTooltipAgGrid,
              }}
              tooltipShowDelay={0}
              tooltipHideDelay={2000}
              rowSelection={'multiple'}
              columnDefs={columnDefs}
              rowData={list}
              onGridReady={(event) => {
                setGridApi(event.api);
              }}
              overlayNoRowsTemplate = "검색된 내용이 없습니다."
            />
          </div>
        </section>

        <footer className="modal-footer">
          <div className="modal-footer-center">
            <Button
              color="inherit"
              variant="outlined"
              className="Btn"
              onClick={() => {
                props.close();
              }}
            >창닫기</Button>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              className="Btn ml10"
            >저장하기</Button>
          </div>
        </footer>
      </form>
    </section>
  )
}

export default CommonExcelModal;