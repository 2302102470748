/*****************************************************************************************
 * 설명 : 사용자관리 - 사용자 추가
 * URI : /system/user
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import React, {useState, useEffect} from 'react';
import { useFormik } from 'formik';
import Button from '@mui/material/Button';
import * as Yup from "yup";

import Restful from "service/restful";
import { getAuthGroupListApi } from 'service/auth';
import { getSelectData } from 'service/utils';
import { getDepartmentListApi, getPositionListApi, setUserAddApi } from 'service/member';

import { MESSAGE_DELAY } from 'config/config';

import alertMsg from 'components/message';
import { SelectEx, InputEx, SelectSearchEx } from 'components/inputEx';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const UserAddModal = (props) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [getApi] = Restful();

  const [title, setTitle] = useState('추가');

  const [authList, setAuthList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [positionList, setPositionList] = useState([]);

  const initialValues = {
    seq: 0,
    authSeq: '',
    employeeId: '',
    userId: '',
    positionId: '',
    positionName: '',
    userName: '',
    userPassword: '',
    userPasswordConfirm: '',
    departmentCode: '',
    departmentName: ''
  }

  const [validationSchema, setValidationSchema] = useState(
    Yup.object().shape({
      authSeq: Yup.string().required('필수'),
      userId: Yup.string().max(20, '최대20').required('필수'),
      employeeId: Yup.string().max(20, '최대20').required('필수'),
      userName: Yup.string().max(100, '최대100').required('필수'),
      departmentCode: Yup.object().required('필수').nullable(),
      userPassword: Yup.string().min(4, '최소4자').max(20, "20자리").required('필수')
      .test(
        'password change check',
        '영문대/소문자,숫자,특수문자 미포함',
        function( str ) {
          const tmp = String(str);
          if( typeof tmp.match !== "function" ) return true;

          return tmp.match(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&()\-_+=~./])[A-Za-z\d$@$!%*#?&()-_+=~./]{8,16}$/) ? true : false;
        }
      )
      .test(
        'password change check',
        '영문대/소문자,숫자,특수문자',
        function( str ) {
          const tmp = String(str);
          if( typeof tmp.match !== "function" ) return true;

          return tmp.match(/^[A-Za-z0-9$^@$!%*#?&()\-_+=~./]+$/);
        }
      ),
      userPasswordConfirm: Yup.string().min(4, '최소4자').max(20, "20자리").required('필수').when("userPassword", {
        is: val => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("userPassword")],
          "불일치"
        )
      }),
    })
  );

  let formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      save(values);
    }
  });

  /***************************************************************************************
   * 설명 : 저장 처리
  ***************************************************************************************/
  const save = (values) => {
    let params = {...values};

    params.departmentCode = params.departmentCode.value;

    setUserAddApi(getApi, params, (response => {
      if( response !== undefined && response.data.result ) {
        alertMsg(response.data.message, "S", MESSAGE_DELAY);

        if( props.getList ) props.getList();

        props.close();

      } else {
        alertMsg(response.data.message || '서버와의 통신에 실패하였습니다.', "E", MESSAGE_DELAY);
      }
    }));
  }

  /***************************************************************************************
   * 설명 : 권한그룹 정보 가져오기
  ***************************************************************************************/
  const getAuthGroupList = () => {
    getAuthGroupListApi(getApi, (response) => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        setAuthList(getSelectData(response.data.data, 'authGroupName', 'seq'));
      } else {
        setAuthList([]);
      }
    });
  }

  /***************************************************************************************
   * 설명 : 부서 정보 가져오기
  ***************************************************************************************/
  const getDepartmentList = () => {
    getDepartmentListApi(getApi, {}, (response) => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        setDepartmentList([
          ...response.data.data.map((item) => {
            let tmp = 'ㅤ';
            let sortOrder = tmp.repeat((item.sortOrder.length / 3) - 1) + '└ ';
            return {
              label: sortOrder + item.departmentName,
              value: item.departmentCode,
              ...item
            }
          })
        ]);
      } else {
        setDepartmentList([]);
      }
    });
  }

  /***************************************************************************************
   * 설명 : 부서 정보 가져오기
  ***************************************************************************************/
   const getPositionList = () => {
    getPositionListApi(getApi, (response) => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        setPositionList(getSelectData(response.data.data, 'positionName', 'positionId'));
      } else {
        setPositionList([]);
      }
    });
  }

  /***************************************************************************************
   * 설명 : 데이터 로딩 처리
  ***************************************************************************************/
  useEffect(() => {
    if( props.open && departmentList.length > 0 && positionList.length > 0 ) {
      if( (props.data.seq || '') === '' ) {
        setTitle('추가');
      } else {
        setTitle('수정');

        // eslint-disable-next-line react-hooks/exhaustive-deps
        setValidationSchema(
          Yup.object().shape({
            authSeq: Yup.string().required('필수'),
            userId: Yup.string().max(20, '최대20').required('필수'),
            employeeId: Yup.string().max(20, '최대20').required('필수'),
            userName: Yup.string().max(100, '최대100').required('필수'),
            departmentCode: Yup.object().required('필수').nullable(),
            userPassword: Yup.string().min(4, '최소4자').max(20, "20자리")
            .test(
              'password change check',
              '영문대/소문자,숫자,특수문자 미포함',
              function( str ) {
                if( (str || '') === '' ) return true;
                const tmp = String(str);
                if( typeof tmp.match !== "function" ) return true;

                return tmp.match(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&()\-_+=~./])[A-Za-z\d$@$!%*#?&()-_+=~./]{8,16}$/) ? true : false;
              }
            )
            .test(
              'password change check',
              '영문대/소문자,숫자,특수문자',
              function( str ) {
                const tmp = String(str);
                if( typeof tmp.match !== "function" ) return true;

                return tmp.match(/^[A-Za-z0-9$^@$!%*#?&()\-_+=~./]+$/);
              }
            ),
            userPasswordConfirm: Yup.string().min(4, '최소4자').max(20, "20자리").when("userPassword", {
              is: val => (val && val.length > 0 ? true : false),
              then: Yup.string().oneOf(
                [Yup.ref("userPassword")],
                "불일치"
              )
            }),
          })
        );

        // 부서 바인딩
        let data = {...props.data};
        let tmp = departmentList.filter((item) => item.value === data.departmentCode);
        if( tmp.length > 0 ) data.departmentCode = tmp[0];
        data.userPassword = '';
        data.userPasswordConfirm = '';

        formik.setValues(data);

      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open, departmentList, positionList])

  /***************************************************************************************
   * 설명 : 오픈시 처리
  ***************************************************************************************/
  useEffect(() => {
    if( props.open ) {
      // 권한 그룹 가져오기
      getAuthGroupList();

      // 부서 가져오기
      getDepartmentList();

      // 직위 가져오기
      getPositionList();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <section className="modal-wrap" style={{width: '100%'}}>
      <form onSubmit={formik.handleSubmit}>
        <header className="modal-header" style={{ cursor: 'move' }} id="draggable-dialog-title">
          <div className="modal-title">사용자 {title}</div>
          <Button className="close-btn" onClick={props.close}>×</Button>
        </header>

        <div className="clearfix"></div>

        <section className="modal-body">
          <table className="input-table">
            <colgroup>
              <col style={{width: '30%'}} />
              <col style={{width: '70%'}} />
            </colgroup>

            <tbody>
              <tr>
                <th scope="row"><label htmlFor="authSeq">권한그룹 선택</label></th>
                <td>
                  <SelectEx
                    name="authSeq"
                    formik={formik}
                    fullWidth={true}
                    data={[
                      {label: '권한그룹 선택', value: ''}
                    ].concat(authList)}
                  />
                </td>
              </tr>
              <tr>
                <th scope="row"><label htmlFor="departmentCode">부서 선택</label></th>
                <td>
                  <SelectSearchEx
                    name="departmentCode"
                    formik={formik}
                    fullWidth={true}
                    isMulti={false}
                    closeMenuOnSelect={true}
                    hideSelectedOptions={true}
                    optionWidth="100%"
                    placeholder="부서 선택"
                    data={[].concat(departmentList)}
                    onChange={(event) => {
                      formik.setFieldValue('departmentName', event.departmentName || '');
                    }}
                  />
                </td>
              </tr>
              <tr>
                <th scope="row"><label htmlFor="employeeId">사원번호</label></th>
                <td>
                  <InputEx
                    name="employeeId"
                    formik={formik}
                    fullWidth={true}
                  />
                </td>
              </tr>
              <tr>
                <th scope="row"><label htmlFor="positionId">직위 선택</label></th>
                <td>
                  <SelectEx
                    name="positionId"
                    formik={formik}
                    fullWidth={true}
                    data={[
                      {label: '직위 선택', value: ''}
                    ].concat(positionList)}
                    onChange={(event) => {
                      let tmp = positionList.filter((item) => item.value === event.target.value)
                      if( tmp.length > 0 )
                        formik.setFieldValue('positionName', tmp[0].label);
                    }}
                  />
                </td>
              </tr>
              <tr>
                <th scope="row"><label htmlFor="userName">사용자명</label></th>
                <td>
                  <InputEx
                    name="userName"
                    formik={formik}
                    fullWidth={true}
                  />
                </td>
              </tr>
              <tr>
                <th scope="row"><label htmlFor="userId">아이디</label></th>
                <td>
                  <InputEx
                    name="userId"
                    formik={formik}
                    fullWidth={true}
                  />
                </td>
              </tr>
              <tr>
                <th scope="row"><label htmlFor="userPassword">비밀번호</label></th>
                <td>
                  <InputEx
                    type="password"
                    name="userPassword"
                    formik={formik}
                    fullWidth={true}
                  />
                </td>
              </tr>
              <tr>
                <th scope="row"><label htmlFor="userPasswordConfirm">비밀번호 확인</label></th>
                <td>
                  <InputEx
                    type="password"
                    name="userPasswordConfirm"
                    formik={formik}
                    fullWidth={true}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </section>

        <footer className="modal-footer">
          <div className="modal-footer-center">
            <Button
              color="inherit"
              variant="outlined"
              className="Btn"
              onClick={() => {
                props.close();
              }}
            >창닫기</Button>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              className="Btn ml10"
            >사용자 {title}</Button>
          </div>
        </footer>
      </form>
    </section>
  )
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default UserAddModal;