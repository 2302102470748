/*****************************************************************************************
 * 설명 : 관리자 > 로그조회 > 프로젝트별 로그
*****************************************************************************************/
import { AgGridReact } from 'ag-grid-react';
import { useFormik } from 'formik';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';

import useGridConfig from 'hooks/useGridConfig';

import BtnAgGridSave from 'components/btnAgGridSave';
import ChipEx from 'components/chipEx';
import { BtnRefresh, BtnSearch, CustomPagenation } from 'components/common';
import { InputEx, SelectEx, SelectSearchEx, ToggleButtonGroupEx } from 'components/inputEx';
import customTooltipAgGrid from 'components/tooltipAgGrid';

import { getProjectLogApi } from 'service/logs';
import { getProjectApi, getProjectInfoApi } from 'service/project';
import Restful from "service/restful";
import { comma, getSelectData, isCheckValue } from 'service/utils';


/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const ProjectLog = ( props ) => {

  const initOrderBy = [
    {label: '가나다순↑', value: 0},
    {label: '가나다순↓', value: 1},
    {label: '등록일순↑', value: 2},
    {label: '등록일순↓', value: 3},
    {label: '프로젝트번호↑', value: 4},
    {label: '프로젝트번호↓', value: 5},
  ];

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [getApi] = Restful();

  const [gridApiProject, setGridApiProject] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [list, setList] = useState([]);
  const [logList, setLogList] = useState([]);

  const [projectYear, setProjectYear] = useState([]);
  const [divisionList, setDivisionList] = useState([]);

  const [purpose2, setPurpose2] = useState([]);

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selected, setSelected] = useState({});

  const searchinitialValues = {
    projectYear: '',
    projectDivision: '',
    projectStatus: '',
    purpose: '',
    searchText: '',
    isConfirm: '',
    isFile: '',
    order: 0,
    isDesign: 0
  }

  const formik = useFormik({
    initialValues: searchinitialValues,
    validateOnChange: false,
    onSubmit: (values) => {
      getList(true);
    }
  });

  const initialValues = {
    startDate: moment().add(-1, 'year').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
    searchText: '',
    projectNumber: '',
    isSuccess: '',
    pageNo: 1,
    pageRow: 20,
    totalCount: 0
  }

  const formikSearch = useFormik({
    initialValues: initialValues,
    validateOnChange: false,
    onSubmit: (values) => {
      getLogList();
    }
  });

  // 프로젝트 목록 축소
  const [projectReduce, setProjectReduce] = useState('');
  // sort
  const [sortView, setSortView] = useState('');
  const toggleSortView = () => {
    if (sortView === '') setSortView('view');
    else setSortView('');
  }

  /***************************************************************************************
   * 설명 : 프로젝트 리스트 컴포넌트
  ***************************************************************************************/
  const ListItem = (props) => {
    return (
      <div
        style={{ display: 'block', height: '70px', padding: '5px 20px 0px 0px' }}
        className="cp"
      >
        <div className="fl project-info">
          <div>
            {// 프로젝트 추가 정보 상태
              props.item.isConfirm === null
              ? <ChipEx color="error" variant="variant" size="small" className="chip-tiny" label="등록" />
              : ( ! isCheckValue(props.item.detailSeq) && parseInt((props.item.isConfirm || 0)) !== 1 )
                ? <ChipEx color="error" variant="variant" size="small" className="chip-tiny" label="등록" />
                : ( isCheckValue(props.item.detailSeq) && parseInt(props.item.isConfirm) !== 1 )
                  ? <ChipEx color="success" variant="variant" size="small" className="chip-tiny" label="추가" />
                  : <ChipEx color="primary" variant="variant" size="small" className="chip-tiny" label="등록완료" />
            }

            {// 프로젝트 파일 정보 상태
              props.item.isUploadCheck === 0 ? <ChipEx color="info" variant="variant" size="small" className="chip-tiny ml5" label="처리중" />
              : !isCheckValue(props.item.isFile) || parseInt(props.item.isFile) === 0
                ? <ChipEx color="error" variant="variant" size="small" className="chip-tiny ml5" label="미등록" />
                : parseInt(props.item.isFile) === 1
                  ? <ChipEx color="secondary" variant="variant" size="small" className="chip-tiny ml5" label="등록중" />
                  : parseInt(props.item.isFile) === 2
                    ? <ChipEx color="success" variant="variant" size="small" className="chip-tiny ml5" label="등록완료" />
                    : <ChipEx color="primary" variant="variant" size="small" className="chip-tiny ml5" label="처리완료" />
            }

            <span className="ml5 f12">{props.item.projectNumber}</span>
          </div>
          <div className="mt5 project-nm">
            <span className="normal">{props.item.projectName}</span>
          </div>
        </div>
      </div>
    )
  }

  /***************************************************************************************
   * 설명 : 검색어를 찾아 빨간색으로 표시
   * searchText = 검색어
   * value = 원본 문자열
  ***************************************************************************************/
  const getSearchText = (searchText, value) => {
    if( searchText !== undefined && searchText !== '' ) {
      let changeText = '<span class="cred bold">' + searchText + '</span>';
      let reg = new RegExp(`${searchText}`, 'g');
      let text = value;
      let replaceText = text?.replace(reg, changeText);
      let html = <span dangerouslySetInnerHTML={{__html: replaceText}}></span>;

      return html;

    } else {
      return value;
    }
  }

  const [columnDefsProject] = useState([
    { headerName: '', field: 'seq', cellClass: 'cp', width: 35, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
    { headerName: '프로젝트', field: 'projectNumber', width: 100, cellClass: 'cp', flex: 1,
      cellRendererFramework: function(params) {
        return (
          <ListItem
            item={params.data}
            index={params.node.rowIndex}
          />
        )
      }
    },
  ]);

  // table column
  const [columnDefs, setColumnDefs] = useState([
    { headerName: '', field: 'seq', width: 40, cellClass: 'center', flex: 0,
      headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
    { headerName: '번호', field: 'rowIndex', width: 55, cellClass: 'cp text-right',
      valueGetter: (params) => {
        return comma(((params.data.pageNo - 1) * params.data.pageRow) + params.node.rowIndex + 1);
      }
    },
    { headerName:'상태', field: 'isSuccess', width: 70, cellClass: 'cp center',
      cellRendererFramework: function(params) {
        if( parseInt(params.data.isSuccess) === 1 )
          return <ChipEx color="success" variant="outlined" size="small" label="성공" />;
        else
          return <ChipEx color="error" variant="outlined" size="small" label="실패" />;
      }
    },
    { headerName:'메뉴명', field: 'menuTitle', width: 150, cellClass: 'cp' },
    { headerName:'처리결과', field: 'action', width: 300, cellClass: 'cp', tooltipField: 'action',
      cellRendererFramework: function(params) {
        return getSearchText(params.data.searchText, params.data.action);
      }
    },
    { headerName:'파라미터', field: 'parameter', width: 400, cellClass: 'cp', tooltipField: 'parameter',
      cellRendererFramework: function(params) {
        return getSearchText(params.data.searchText, params.data.parameter);
      }
    },
    { headerName:'사용자', field: 'userId', width: 200, cellClass: 'cp',
      valueGetter: (params) => {
        return (params.data.departmentName ? params.data.departmentName + ' ' : '') +
          params.data.positionName + ' ' +
          params.data.userName;
      }
    },
    { headerName:'아이디', field: 'userId', width: 100, cellClass: 'cp center' },
    { headerName:'아이피', field: 'ipAddress', width: 100, cellClass: 'cp center' },
    { headerName:'일시', field: 'createDate', width: 140, cellClass: 'cp center' },
  ]);

  const [gridConfig, setGridUpdate] = useGridConfig(1, setColumnDefs);

  /***************************************************************************************
   * 설명 : 프로젝트 목록 height
  ***************************************************************************************/
  const getRowHeight = useCallback((params) => {
    return 70;
  }, []);

  /***************************************************************************************
   * 설명 : 프로젝트 년도 / 건축구분 / purpose 가져오기
  ***************************************************************************************/
  const getInfo = () => {

    const setData = (data, setData, name, value) => {
      if( data && data.length > 0 )
        setData(getSelectData(data, name, value));
      else
        setData([]);
    }

    /*
    const setDataOrigin = (data, setData) => {
      if( data && data.length > 0 )
        setData(data);
      else
        setData([]);
    }
    */

    getProjectInfoApi(getApi, formik.values, (response) => {
      if( response !== undefined && response.data.result ) {
        // 프로젝트 최소 년도 ~ 최대 년도
        setData(response.data.data, setProjectYear, 'projectYear', 'projectYear');

        if( response.data.data && response.data.data.length > 0 )
          formik.setFieldValue("projectYear", response.data.data[0].projectYear);

        // 용도
        if( response.data.data3 && response.data.data3?.length > 0 ) {
          // 용도 2단계
          let tmp = response.data.data3.filter((item) => item.commonCode.length > 3);
          setPurpose2(getSelectData(tmp, 'commonName', 'commonCode'));
        } else {
          setPurpose2([]);
        }

      } else {
        setProjectYear([]);
        setDivisionList([]);
        setPurpose2([]);
      }

      setIsLoading(true);
    })
  }

  /***************************************************************************************
   * 설명 : 프로젝트 리스트 가져오기
  ***************************************************************************************/
  const getList = (isSelected) => {
    let params = {...formik.values};

    params.purpose = params.purpose?.value || '';

    // 기존 선택을 다시 선택해야 하는 경우 처리
    let index = 0;
    if( isSelected === false ) {
      gridApiProject.forEachNode((item) => {
        if( item.selected ) index = item.rowIndex;
      })
    }

    getProjectApi(getApi, params, (response) => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        setList(response.data.data);

        // 선택 초기화
        if( selectedIndex === -1 || isSelected === true ) {
          setSelectedIndex(0);
          setSelected(response.data.data[0]);

        // 기존 선택 다시 선택 처리
        } else {
          setSelectedIndex(index);
          setSelected(response.data.data[index]);
        }

      } else {
        setList([]);
        setSelectedIndex(-1);
        setSelected({});
      }
    })
  }

  /***************************************************************************************
   * 설명 : 프로젝트 별 로그 리스트 가져오기
  ***************************************************************************************/
  const getLogList = () => {
    let params = {
      ...formikSearch.values
    };

    params.projectNumber = selected.projectNumber;

    getProjectLogApi(getApi, params, (response) => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        setLogList([
          ...response.data.data.map((item) => {
            return {
              ...item,
              pageNo: formikSearch.values.pageNo,
              pageRow: formikSearch.values.pageRow,
              searchText: formikSearch.values.searchText
            }
          })
        ]);

        let totalCount = (response.data.data1 || 0);
        let totalPage = Math.floor(totalCount / formikSearch.values.pageRow);
        if(totalCount % formikSearch.values.pageRow > 0 ) totalPage = totalPage + 1;
        formikSearch.setFieldValue('totalPage', totalPage);
        formikSearch.setFieldValue('totalCount', totalCount);

      } else {
        setLogList([]);
        formikSearch.setFieldValue('totalPage', 0);
        formikSearch.setFieldValue('totalCount', 0);
      }
    })
  }

  /***************************************************************************************
   * 설명 : 프로젝트 선택 시 로그 리스트 가져오기
  ***************************************************************************************/
  useEffect(() => {
    getLogList();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  /***************************************************************************************
   * 설명 : 프로젝트 선택 시 로그 리스트 가져오기
  ***************************************************************************************/
  useEffect(() => {
    getLogList();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formikSearch.values.pageNo, formikSearch.values.isSuccess]);

  /***************************************************************************************
   * 설명 : 프로젝트 리스트 가져오기
  ***************************************************************************************/
  useEffect(() => {
    if( isLoading ) {
      getList(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formik.values.projectYear, formik.values.isConfirm,
    formik.values.projectDivision, formik.values.purpose, formik.values.isFile
  ]);

  /***************************************************************************************
   * 설명 : 데이터 로딩 처리
  ***************************************************************************************/
  useEffect(() => {
    setIsLoading(false);

    getInfo();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <>
      <section className={"project " + projectReduce}>
        <section className="left-area">
          <header className="left-header">
            <form onSubmit={formik.handleSubmit}>
              <div className="h2">프로젝트 목록</div>
              <div className="fr">
                <i className="ri-arrow-left-circle-fill reduce-icon" onClick={() => setProjectReduce('reduce')}></i>
              </div>

              <div className="clearfix"></div>

              <div className="mt5 mb5">
                <SelectEx
                  name="projectYear"
                  formik={formik}
                  fullWidth={false}
                  style={{width: 'calc(50% - 5px)', marginRight: '5px'}}
                  data={[
                    {label: '년도 선택', value: ''}
                  ].concat(projectYear)}
                />
                <SelectEx
                  name="projectDivision"
                  formik={formik}
                  fullWidth={false}
                  style={{width: '50%'}}
                  data={[
                    {label: '건축구분', value: ''}
                  ].concat(divisionList)}
                />
              </div>

              <SelectSearchEx
                name="purpose"
                formik={formik}
                data={[
                  {label: '전체 검색', value: ''}
                ].concat(purpose2)}
                height={28}
                isMulti={false}
                closeMenuOnSelect={true}
                hideSelectedOptions={true}
                fullWidth={true}
                optionWidth="100%"
                placeholder="용도 전체 검색"
              />

              <InputEx
                name="searchText"
                formik={formik}
                label="프로젝트명, 프로젝트번호 검색"
                style={{width: '100%', marginTop: '5px'}}
              />

              <div className="mt5">
                <div className="">
                  <ToggleButtonGroupEx
                    name="isConfirm"
                    exclusive={true}
                    formik={formik}
                    className="f12"
                    data={[
                      {label: '추가정보 전체', value: ''},
                      {label: '등록', value: 1},
                      {label: '추가', value: 2},
                      {label: '등록완료', value: 3},
                    ]}
                  />
                </div>
                <div className="mt5">
                  <ToggleButtonGroupEx
                    name="isFile"
                    exclusive={true}
                    formik={formik}
                    className="f12"
                    data={[
                      {label: '파일 전체', value: ''},
                      {label: '미등록', value: 0},
                      {label: '등록중', value: 1},
                      {label: '등록완료', value: 2},
                      {label: '처리완료', value: 3},
                    ]}
                  />
                </div>
              </div>

              <div className="clearfix"></div>

              <div className="clearfix mt10">
                <div className="fl">
                  <BtnRefresh click={() => {
                    formik.setValues(searchinitialValues);
                    formik.handleSubmit();
                  }} />
                  <BtnSearch />
                </div>
                <div className="cnt" style={{lineHeight: '28px'}}>총 {comma(list.length || 0)}개</div>
                <div className={"sort " + sortView}>
                  <div className="selected" onClick={() => toggleSortView()} style={{lineHeight: '28px'}}>
                    {initOrderBy[formik.values.order].label}
                  </div>
                  <ul className="select-list" onClick={() => setSortView('')}>
                    {initOrderBy.map((item) => {
                      return (
                        <li onClick={() => {
                          formik.setFieldValue('order', item.value);
                          formik.handleSubmit();
                        }}>{item.label}</li>
                      )
                    })}
                  </ul>
                </div>
              </div>
            </form>
          </header>

          <div className="ag-theme-balham header-hide pad-height380" style={{height: 'calc(100vh - 350px)'}}>
            <AgGridReact
              defaultColDef={{
                sortable: true,
                resizable: true,
                filter: false,
                lockVisible: true,
                headerHeight: 0,
                tooltipComponent: customTooltipAgGrid,
              }}
              tooltipShowDelay={0}
              tooltipHideDelay={2000}
              alwaysShowVerticalScroll={true}
              columnDefs={columnDefsProject}
              rowData={list}
              rowSelection={'single'}
              getRowHeight={getRowHeight}
              onGridReady={(event) => {
                setGridApiProject(event.api);
              }}
              onRowDataUpdated={(event) => {
                if( selectedIndex > - 1) {
                  event.api.ensureIndexVisible(selectedIndex, "middle");
                  event.api.forEachNode((item) => {
                    if(item.rowIndex === selectedIndex) item.setSelected(true);
                  })
                }
              }}
              onRowClicked={(event) => {
                formikSearch.setFieldValue('pageNo', 1);
                setSelected(event.data);
              }}
              overlayNoRowsTemplate = "검색된 내용이 없습니다."
            />
          </div>
        </section>

        <div className="reduce-wrap"><i className="ri-arrow-right-circle-fill reduce-icon" onClick={() => setProjectReduce('')}></i></div>

        <section className="right-area Tab-page">
          <section className="admin-cms-contents pl10 pr0">
            <form onSubmit={formikSearch.handleSubmit}>
              <header className="admin-cms-search-header">
                <div className="left">
                  <div className='fl'>
                    <BtnRefresh click={() => {
                      formikSearch.setValues(initialValues);
                      formikSearch.handleSubmit();
                    }}></BtnRefresh>
                    <BtnSearch></BtnSearch>
                    <BtnAgGridSave click={setGridUpdate}></BtnAgGridSave>
                  </div>

                  <div className='fl mr5'>
                    <InputEx
                      type="date"
                      name="startDate"
                      formik={formikSearch}
                      fullWidth={false}
                      label="검색시작일"
                      style={{width: '140px'}}
                      InputLabelProps={{shrink: true}}
                    />

                    <span className="ml5 mr5 lh28px">~</span>

                    <InputEx
                      type="date"
                      name="endDate"
                      formik={formikSearch}
                      fullWidth={false}
                      label="검색종료일"
                      style={{width: '140px'}}
                      InputLabelProps={{shrink: true}}
                    />
                  </div>

                  <div className='fl pad-fl-newLine'>
                    <ToggleButtonGroupEx
                      name="isSuccess"
                      exclusive={true}
                      formik={formikSearch}
                      className="search-toggle-btn"
                      data={[
                        {label: '전체', value: ''},
                        {label: '성공', value: 1},
                        {label: '실패', value: 2},
                      ]}
                    />

                    <span className="ml5" />

                    <InputEx
                      name="searchText"
                      formik={formikSearch}
                      fullWidth={false}
                      label="사용자명, 처리결과, 파라미터 검색"
                      style={{width: '240px'}}
                    />
                  </div>
                </div>
              </header>
            </form>

            <section className="admin-cms-body">
              <section className="ag-theme-balham grid pad-height120-percent" style={{height:'calc(100% - 80px)'}}>
                <AgGridReact
                  defaultColDef={{
                    sortable: true,
                    resizable: true,
                    filter: false,
                    lockVisible: true,
                    tooltipComponent: customTooltipAgGrid,
                  }}
                  tooltipShowDelay={0}
                  tooltipHideDelay={2000}
                  rowSelection = {'multiple'}
                  columnDefs={columnDefs}
                  rowData={logList}
                  onGridReady={(event) => {
                    gridConfig(event.columnApi);
                  }}
                  rowDragManaged={true}
                  rowDragMultiRow={true}
                  animateRows={true}
                  overlayNoRowsTemplate = "검색된 내용이 없습니다."
                />
              </section>
              <section className="pagination mt10">
                <div className="fl">
                  <CustomPagenation
                    currPage={{pageNo: formikSearch.values.pageNo, pageRow: formikSearch.values.pageRow}}
                    totalPage={formikSearch.values.totalPage}
                    change={(event, pageNo) => {
                      formikSearch.setFieldValue("pageNo", pageNo);
                    }}
                  ></CustomPagenation>
                </div>
                <div className="fr f12">
                  검색 :<span className="ml10">{comma(formikSearch.values.totalCount)}</span>
                </div>
              </section>
            </section>
          </section>

        </section>
      </section>
    </>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default ProjectLog;