/*****************************************************************************************
 * 설명 : 검색창 - 연관검색어, 이전검색어
 * URI :
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import Button from '@mui/material/Button';
import { useEffect, useState } from 'react';

import { MESSAGE_DELAY } from 'config/config';

import alertMsg from "components/message";

import Restful from "service/restful";
import { deleteAllPrevSearchAllApi, deletePrevSearchApi, getPreSearchInfo, getSearchListApi } from 'service/search';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const HelpInputBox = (props) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [getApi] = Restful();

  const [prevSearchText, setPrevSearchText] = useState('');
  const [prevSearch, setPrevSearch] = useState([]);
  const [relationWord, setRelationWord] = useState([]);

  /***************************************************************************************
   * 설명 : 이전검색어 삭제
  ***************************************************************************************/
  const prevSearchDelete = (item, index) => {
    let params = {
      seq: item.seq,
      searchText: item.searchWord
    }

    deletePrevSearchApi(getApi, params, (response) => {
      if( response !== undefined && response.data.result ) {
        let tmp = [...prevSearch];
        tmp.splice(index, 1);

        setPrevSearch(tmp);

      } else {
        alertMsg(response.data.message || '서버와의 통신에 실패하였습니다.', "E", MESSAGE_DELAY);
      }
    });
  }

  /***************************************************************************************
   * 설명 : 이전검색어 전체 삭제
  ***************************************************************************************/
  const prevSearchAllDelete = () => {
    if( ! window.confirm("이전에 검색하신 검색어를 전부 삭제하시겠습니까?") ) return;

    deleteAllPrevSearchAllApi(getApi, (response) => {
      if( response !== undefined && response.data.result ) {
        setPrevSearch([]);

      } else {
        alertMsg(response.data.message || '서버와의 통신에 실패하였습니다.', "E", MESSAGE_DELAY);
      }
    });
  }

  /***************************************************************************************
   * 설명 : 이전검색어 서버에서 가져오기
  ***************************************************************************************/
  const getSearchList = () => {
    getSearchListApi(getApi, (response) => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 )
        setPrevSearch(response.data.data);
      else
        setPrevSearch([]);
    })
  }

  /***************************************************************************************
   * 설명 : 검색어 변경 시 연관, 이전 검색어 가져오기
  ***************************************************************************************/
  useEffect(() => {
    // if( prevSearchText?.length !== props.formik?.values?.search?.length ) {
      let promise = getPreSearchInfo(props.formik.values?.search)
      if( promise && promise.then ) {
        promise.then((response) => {
          if( response !== undefined && response.data.result ) {
            if( response.data.relationWord )
              setRelationWord(response.data.relationWord);
          }
        });

        setPrevSearchText(props.formik.values?.search);
      }
    // }

    // eslint-disable-next-line
  }, [props.formik?.values?.search]);

  /***************************************************************************************
   * 설명 : 초기 표시시 연관, 이전 검색어 불러오기
  ***************************************************************************************/
  useEffect(() => {
    if( props.display ) {
      getSearchList();

      let promise = getPreSearchInfo(props.formik.values?.search)
      if( promise && promise.then ) {
        promise.then((response) => {
          if( response !== undefined && response.data.result ) {
            if( response.data.relationWord )
              setRelationWord(response.data.relationWord);
          }
        });
      }

      setPrevSearchText(props.formik.values?.search);
    }

    // eslint-disable-next-line
  }, [props.display]);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <>
      <div
        className={props.display ? "help-inputbox on" : "help-inputbox off"}
      >
        <div className="h5">연관검색어</div>
        <div className="bb mt10">
          {relationWord?.map((item, index) => {
            return (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a
                key={index}
                href="#"
                className="search"
                rel="noreferrer"
                onClick={() => props.searchSubmit({search: item.relationWord})}
              >
                <i className="bx bx-search"></i>
                {item.relationWord}
              </a>
            )
          })}
          {relationWord.length < 1 &&
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a className="search">검색어를 입력해 주세요.</a>
          }
        </div>

        <div className="h5">이전검색어
          <Button
            className="btn"
            onClick={() => prevSearchAllDelete()}
          >전체삭제</Button>
        </div>

        <ul className={relationWord?.length > 0 ? 'off' : ''}>
          { prevSearch.length > 0 && prevSearch?.map((item, index) => {
            return (
              <li key={index}>
                <div className="searchText" onClick={() => {
                  props.searchSubmit({
                    ...props.formik.values,
                    search: item.searchWord
                  });
                }}>
                  <i className="ri-compass-2-line" />
                  {item.searchWord}
                </div>

                <div className="fr">
                  <span className="search-date">{item.createDate}</span>

                  <i
                    className="ri-close-line"
                    onClick={() => prevSearchDelete(item, index)}
                  />
                </div>
              </li>
            );
          })}

          { prevSearch.length < 1 &&
            <li>검색한 내용이 없습니다.</li>
          }
        </ul>
      </div>
    </>
  );
}
/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default HelpInputBox;