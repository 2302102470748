/*****************************************************************************************
 * 설명 : 프로젝트관리 - 열람신청
 * URI : /project
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from "yup";


import { InputEx } from 'components/inputEx';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const ProjectOwnershipModal = (props) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [title, setTitle] = useState('추가');

  /***************************************************************************************
   * 설명 : 업체 검색
  ***************************************************************************************/
  const formik = useFormik({
    initialValues: {
      seq: '',
      projectNumber: '',
      companyName: '',
      ownershipRate: '',
      memo: ''
    },
    validationSchema: Yup.object().shape({
      companyName: Yup.string().max(100, "100자리").required('필수'),
      ownershipRate: Yup.string().max(6, "3자리.2자리").required('필수'),
      memo: Yup.string().max(200, "200자리").nullable()
    }),
    onSubmit: (values) => {

      let isAdd = props.list?.filter((item) => item.seq === values.seq);
      if( isAdd.length > 0 ) {
        let tmp = [...props.list];
        let index = 0;
        tmp.forEach((item, subIndex) => {
          if( item.seq === formik.values.seq ) index = subIndex;
        })

        tmp[index] = values;
        props.setList(tmp);

      // 추가
      } else {
        let tmp = [
          ...props.list,
          {...values}
        ];

        // 주관업체 찾기
        let max = 0;
        let maxIndex = 0;
        tmp = tmp.map((item, index) => {
          if( item.ownershipRate > max ) {
            max = item.ownershipRate;
            maxIndex = index
          }

          let tmp1 = item;
          tmp1.isMain = 0;

          return tmp1;
        });

        tmp[maxIndex].isMain = 1;

        props.setList(tmp);
      }

      props.close();
    }
  });

  /***************************************************************************************
   * 설명 : 오픈 시 처리
  ***************************************************************************************/
  useEffect(() => {
    if( props.open ) {
      if( props.data?.seq !== undefined && props.data?.seq !== '' ) {
        setTitle('수정');

        formik.setValues(props.data);

      } else {
        setTitle('추가');

        let seq = 0;
        props.list.forEach((item) => {
          if( parseInt(item.seq) < 0 && parseInt(item.seq) < seq ) seq = parseInt(item.seq);
        });

        formik.setFieldValue('seq', seq - 1);
      }

      formik.setFieldValue("projectNumber", props.selected.projectNumber);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <section className="modal">
      <section className="modal-wrap" style={{width: '100%'}}>
        <form onSubmit={formik.handleSubmit}>
          <header className="modal-header" style={{ cursor: 'move' }} id="draggable-dialog-title">
            <div className="modal-title">참여업체 {title}</div>
            <Button className="close-btn" onClick={props.close}>×</Button>
          </header>

          <section className="modal-body">
            <div className="mb10">
              <table className="input-table">
                <colgroup>
                  <col style={{width: '30%'}} />
                  <col style={{width: '70%'}} />
                </colgroup>

                <tbody>
                  <tr>
                    <th scope="row"><label htmlFor="siteName">참여업체명</label></th>
                    <td>
                      <InputEx
                        name="companyName"
                        formik={formik}
                        fullWidth={true}
                        inputProps={{autoFocus: true}}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row"><label htmlFor="ownershipRate">지분율(%)</label></th>
                    <td>
                      {/*
                      <InputEx
                        name="ownershipRate"
                        formik={formik}
                        fullWidth={true}
                        inputProps={{
                          maxLength: 3,
                          max: 100,
                          min: 0,
                          endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        }}
                        onChange={(event) => {
                          if (event.nativeEvent.data && isNotNumber(event.nativeEvent.data) ){
                            event.target.value = onlyNumber(event.target.value);
                          }
                        }}
                        onBlur={(event) => {
                          let tmp = onlyNumber(event.target.value);
                          if( tmp > 100 ) event.target.value = 100;
                          else if( tmp < 0 ) event.target.value = 0;
                          else event.target.value = tmp;
                          formik.setFieldValue('ownershipRate', event.target.value);
                        }}
                      />
                      */}
                      <InputEx
                        name="ownershipRate"
                        formik={formik}
                        fullWidth={true}
                        inputProps={{
                          maxLength: 6, // 전체 길이가 최대 6자리(소수점 포함)가 되도록 수정
                          max: 100,
                          min: 0,
                          endAdornment: <InputAdornment position="end">%</InputAdornment>,
                          type: 'text', // type을 text로 지정하여 소수점 입력 가능하도록 변경
                        }}
                        onChange={(event) => {
                          let value = event.target.value;
                          // 입력값이 숫자 또는 소수점인지 확인
                          if (!isNaN(value) || value === '.' || value === '') {
                            // 소수점 아래 2자리까지만 허용
                            let parts = value.split('.');
                            if (parts.length > 1) {
                              parts[1] = parts[1].slice(0, 2);
                              value = parts.join('.');
                            }
                            event.target.value = value;
                          }
                        }}
                        onBlur={(event) => {
                          let tmp = parseFloat(event.target.value || 0); // 소수점 값으로 변경
                          // 입력값이 100을 초과하면 100으로 설정
                          if (tmp > 100) {
                            event.target.value = 100;
                          }
                          // 입력값이 0 미만이면 0으로 설정
                          else if (tmp < 0) {
                            event.target.value = 0;
                          }
                          // 입력값이 숫자가 아니거나 비어있을 경우 0으로 설정
                          else if (isNaN(tmp)) {
                            event.target.value = 0;
                          }
                          // 소수점 2자리까지 표시
                          else {
                            event.target.value = tmp.toFixed(2);
                          }
                          formik.setFieldValue('ownershipRate', event.target.value);
                        }}
                        onKeyDown={(event) => {
                          // 공백 2칸으로 대체
                          if (event.key === 'Tab') {
                            event.preventDefault();
                            let input = event.target;
                            let value = input.value;
                            let selectionStart = input.selectionStart;
                            let selectionEnd = input.selectionEnd;
                            input.value = value.substring(0, selectionStart) + '  ' + value.substring(selectionEnd);
                            input.selectionStart = input.selectionEnd = selectionStart + 2;
                          }
                          // 입력값이 100을 초과하지 못하도록 제한
                          if ((event.target.value + event.key) > 100) {
                            event.preventDefault();
                          }
                        }}
                      />



                    </td>
                  </tr>
                  <tr>
                    <th scope="row"><label htmlFor="memo">메모</label></th>
                    <td>
                      <InputEx
                        name="memo"
                        formik={formik}
                        fullWidth={true}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </section>

          <footer className="modal-footer">
            <div className="">
              <Button
                color="secondary"
                variant="outlined"
                className="Btn"
                onClick={() => {
                  let rate = 0;
                  if( props.list.length > 0 ) {
                    props.list.forEach((item) => rate += parseFloat(item.ownershipRate));
                    rate = 100 - rate;
                  } else {
                    rate = 100;
                  }

                  if( rate < 0 ) rate = 0;

                  formik.setFieldValue('companyName', '(주)디에이그룹엔지니어링종합건축사사무소');
                  formik.setFieldValue('ownershipRate', rate);
                }}
              >디에이건축</Button>
            </div>
            <div className="text-right">
              <Button
                color="inherit"
                variant="outlined"
                className="Btn"
                onClick={() => {
                  props.close();
                }}
              >창닫기</Button>

              <Button
                type="submit"
                variant="contained"
                color="primary"
                className="Btn ml10"
              >저장</Button>
            </div>
          </footer>
        </form>
      </section>
    </section>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default ProjectOwnershipModal;