/*****************************************************************************************
 * 설명 : 메뉴 리스트
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import React from 'react';

/*****************************************************************************************
 * 설명 : context 선언부
*****************************************************************************************/
export const IndicatorContext = React.createContext({
  isIndicator: [],
  setIndicator: () => {}
});

/*****************************************************************************************
 * 설명 : 디폴트 선언
*****************************************************************************************/
export default IndicatorContext;