/*****************************************************************************************
 * 설명 : 프로젝트관리 - 열람신청
 * URI : /project
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import FolderIcon from '@mui/icons-material/Folder';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { AgGridReact } from 'ag-grid-react';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from "yup";

import { MESSAGE_DELAY } from 'config/config';
import useGridConfig from 'hooks/useGridConfig';

import AlertDialog from 'components/alertDiolog';
import { InputEx, ToggleButtonGroupEx } from 'components/inputEx';
import alertMsg from 'components/message';
import customTooltipAgGrid from 'components/tooltipAgGrid';

import { setRequestApplicationApi } from 'service/request';
import Restful from "service/restful";
import { comma, formatBytes } from 'service/utils';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const ApplicationPerusalModal = (props) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [getApi] = Restful();
  const [list, setList] = useState([]);

  const [isDisabled, setIsDisabled] = useState(true);

  const [openModal, setOpenModal] = useState({open: false, modal: 'message', data: {}});

  /***************************************************************************************
   * 설명 : 업체 검색
  ***************************************************************************************/
  const formik = useFormik({
    initialValues: {
      reason: '',
      isApplicationView: 1,
      isDownload: 1,
    },
    validationSchema: Yup.object().shape({
      reason: Yup.string().max(255, "255자리").required('필수')
    }),
    onSubmit: (values) => {
      submit(values);
    }
  });

  // table column
  const [columnDefs, setColumnDefs] = useState([
    { headerName: '', field: 'seq', hide: true},
    { headerName: '키값(수정금지)', field: 'seq', hide: true },
    { headerName: '순번', field: 'rowIndex', width: 60, cellClass: 'cp text-right',
      valueGetter: (params) => {
        return comma(params.node.rowIndex + 1);
      }
    },
    { headerName:'경로명', field: 'path', width: 250, cellClass: 'cp'},
    { headerName:'파일명', field: 'title', width: 200, cellClass: 'cp',
      cellRendererFramework: function (params) {
        if( parseInt(params.data?.type || 0) === 1 )
          return params.data.title;
        else
          return (
            <div
              className="flex vcenter" style={{height: '100%'}}>
                <FolderIcon style={{fontSize: '15px'}}/>
                <div className="ml5" style={{width: 'calc(100% - 30px)'}}>
                  {params.data.title}
                </div>
            </div>
          );
      }
    },
    { headerName:'용량', field: 'fileSize', width: 100, cellClass: 'cp text-right',
      valueGetter: (params) => {
        return formatBytes(params.data?.fileSize || 0, 2);
      }
    },
    { headerName:'비고', field: 'memo', width: 200, cellClass: 'cp' },
    { headerName:'등록일시', field: 'createDate', width: 150, cellClass: 'cp center' },
  ]);

  const [gridConfig] = useGridConfig(99, setColumnDefs);

  const submit = (values) => {
    let params = {
      projectNumber: props.info.projectNumber,
      reason: values.reason,
      isApplicationView: values.isApplicationView,
      isDownload: values.isDownload,
      list: list
    }

    setRequestApplicationApi(getApi, params, (response => {
      if( response !== undefined && response.data.result ) {
        alertMsg(response.data.message, "S", MESSAGE_DELAY);

        props.close();

      } else {
        alertMsg(response.data.message || '서버와의 통신에 실패하였습니다.', "E", MESSAGE_DELAY);
      }
    }));
  }

  /***************************************************************************************
   * 설명 : 모달 오픈 시 처리
  ***************************************************************************************/
  useEffect(() => {
    if( props.open && props.gridApi ) {
      let nodes = props.gridApi.getSelectedRows();

      if( nodes.length > 0 ) {
        setIsDisabled(false);
        formik.setFieldValue('isApplicationView', 2);

        setList(nodes);
      } else {
        setIsDisabled(true);
        formik.setFieldValue('isApplicationView', 1);
      }
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open])

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <section className="modal">
      <form onSubmit={formik.handleSubmit}>
        <section className="modal-wrap" style={{width: '800px'}}>
          <header className="modal-header" style={{ cursor: 'move' }} id="draggable-dialog-title">
            <div className="modal-title">프로젝트 파일 열람 신청</div>
            <Button className="close-btn" onClick={props.close}>×</Button>
          </header>

          <section className="modal-body">
            <div className="mb10">
              <table className="input-table">
                <colgroup>
                  <col style={{width: '15%'}} />
                  <col style={{width: '35%'}} />
                  <col style={{width: '15%'}} />
                  <col style={{width: '35%'}} />
                </colgroup>

                <tbody>
                  <tr>
                    <th scope="row"><label htmlFor="reason">신청사유</label></th>
                    <td colSpan={3}>
                      <InputEx
                        name="reason"
                        formik={formik}
                        fullWidth={true}
                        placeHolder="신청사유"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row"><label htmlFor="isApplicationView">신청구분</label></th>
                    <td colSpan={3}>
                      <ToggleButtonGroupEx
                        name="isApplicationView"
                        exclusive={true}
                        formik={formik}
                        className="search-toggle-btn"
                        data={[
                          {label: '프로젝트 파일 전체 조회', value: 1},
                          {label: '선택 파일 조회', value: 2},
                        ]}
                        disabled={isDisabled}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row"><label htmlFor="isDownload">다운로드 권한</label></th>
                    <td colSpan={3}>
                      <ToggleButtonGroupEx
                        name="isDownload"
                        exclusive={true}
                        formik={formik}
                        className="search-toggle-btn"
                        data={[
                          {label: '다운로드 및 미리보기', value: 1},
                          {label: '파일 미리보기', value: 2},
                        ]}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="clearfix"></div>

            <div className="ag-theme-balham" style={{height: '400px'}}>
              <AgGridReact
                defaultColDef={{
                  sortable: true,
                  resizable: true,
                  filter: false,
                  lockVisible: true,
                  tooltipComponent: customTooltipAgGrid,
                }}
                tooltipShowDelay={0}
                tooltipHideDelay={2000}
                columnDefs={columnDefs}
                rowData={list}
                rowSelection={'single'}
                onGridReady={(event) => {
                  gridConfig(event.columnApi);
                }}
                onRowClicked={(event) => {
                }}
                onRowDoubleClicked={(event) => {
                }}
                overlayNoRowsTemplate = "검색된 내용이 없습니다."
              />
            </div>
          </section>

          <footer className="modal-footer">
            <div className="modal-footer-center">
              <Button
                color="inherit"
                variant="outlined"
                className="Btn"
                onClick={() => {
                  props.close();
                }}
              >창닫기</Button>

              <Button
                type="submit"
                variant="contained"
                color="primary"
                className="Btn ml10"
              >열람신청</Button>
            </div>
          </footer>
        </section>
      </form>

      { openModal.open && openModal.modal === 'dialog' &&
        <Modal
          open={openModal.open}
          onClose={() => setOpenModal({ open: false, modalName: openModal.modal, data: openModal.data })}
        >
          <AlertDialog
            open={openModal.open}
            close={() => setOpenModal({ open: false, modalName: openModal.modal, data: openModal.data })}
            title="열람신청"
            color="primary"
            message={`선택하신 내역으로 열람 신청 하시겠습니까?`}
            confirm="확인"
            onClick={() => {
              submit(formik.values);
            }}
          />
        </Modal>
      }
    </section>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default ApplicationPerusalModal;