/*****************************************************************************************
 * 설명 : 관리자 > 로그조회 > 로그데이터 조회
 * URI : /admin/site
*****************************************************************************************/
import { AgGridReact } from 'ag-grid-react';
import { useFormik } from 'formik';
import moment from 'moment';
import { useEffect, useState } from 'react';
import * as Yup from "yup";

import useGridConfig from 'hooks/useGridConfig';

import BtnAgGridSave from 'components/btnAgGridSave';
import ChipEx from 'components/chipEx';
import { BtnRefresh, BtnSearch, CustomPagenation } from 'components/common';
import { InputEx, SelectSearchEx, ToggleButtonGroupEx } from 'components/inputEx';
import customTooltipAgGrid from 'components/tooltipAgGrid';

import { getLogApi } from 'service/logs';
import { getEmployeeListApi } from 'service/member';
import Restful from "service/restful";
import { comma } from 'service/utils';

import Layout from 'pages/layout/layout';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const LogData = ( props ) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [getApi] = Restful();

  const [employeeList, setEmployeeList] = useState([]);
  const [list, setList] = useState([]);

  const formik = useFormik({
    initialValues: {
      startDate: moment().format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      userId: '',
      searchText: '',
      isSuccess: '',
      result: '',
      pageNo: 1,
      pageRow: 20
    },
    validationSchema: Yup.object().shape({
    }),
    onSubmit: (values) => {
      getList();
    }
  });

  /***************************************************************************************
   * 설명 : 검색어를 찾아 빨간색으로 표시
   * searchText = 검색어
   * value = 원본 문자열
  ***************************************************************************************/
  const getSearchText = (searchText, value) => {
    if( searchText !== undefined && searchText !== '' ) {
      let changeText = '<span class="cred bold">' + searchText + '</span>';
      let reg = new RegExp(`${searchText}`, 'g');
      let text = value;
      let replaceText = text?.replace(reg, changeText);
      let html = <span dangerouslySetInnerHTML={{__html: replaceText}}></span>;

      return html;

    } else {
      return value;
    }
  }

  // table column
  const [columnDefs, setColumnDefs] = useState([
    { headerName: '', field: 'seq', width: 40, cellClass: 'center', flex: 0,
      headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
    { headerName: '번호', field: 'rowIndex', width: 55, cellClass: 'cp text-right',
      valueGetter: (params) => {
        return comma(((params.data.pageNo - 1) * params.data.pageRow) + params.node.rowIndex + 1);
      }
    },
    { headerName:'상태', field: 'isSuccess', width: 70, cellClass: 'cp center',
      cellRendererFramework: function(params) {
        if( parseInt(params.data.isSuccess) === 1 )
          return <ChipEx color="success" variant="outlined" size="small" label="성공" />;
        else
          return <ChipEx color="error" variant="outlined" size="small" label="실패" />;
      }
    },
    { headerName:'메뉴명', field: 'menuTitle', width: 150, cellClass: 'cp' },
    { headerName:'처리결과', field: 'action', width: 300, cellClass: 'cp',
      cellRendererFramework: function(params) {
        return getSearchText(params.data.searchText, params.data.action);
      }
    },
    { headerName:'파라미터', field: 'parameter', width: 400, cellClass: 'cp',
      cellRendererFramework: function(params) {
        return getSearchText(params.data.searchText, params.data.parameter);
      }
    },
    { headerName:'사용자', field: 'userId', width: 200, cellClass: 'cp',
      valueGetter: (params) => {
        return (params.data.departmentName ? params.data.departmentName + ' ' : '') +
          params.data.positionName + ' ' +
          params.data.userName;
      }
    },
    { headerName:'아이디', field: 'userId', width: 100, cellClass: 'cp center' },
    { headerName:'아이피', field: 'ipAddress', width: 100, cellClass: 'cp center' },
    { headerName:'일시', field: 'createDate', width: 140, cellClass: 'cp center' },
  ]);

  const [gridConfig, setGridUpdate] = useGridConfig(1, setColumnDefs);

  /***************************************************************************************
   * 설명 : 선택된 조직도의 사용자 가져오기
  ***************************************************************************************/
  const getEmployeeList = () => {
    // if( selectedNode === undefined || selectedNode.seq === undefined ) return;

    let params = {
      ...formik.values,
      departmentCode: '',
      sortOrder: ''
    };

    getEmployeeListApi(getApi, params, (response) => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        setEmployeeList([
          ...response.data.data.map((item) => {
            return {
              label: (item.departmentName ? item.departmentName + ' ' : '' ) +
                     (item.positionName ? item.positionName + ' ' : '' ) + item.userName,
              value: item.userId,
              ...item
            }
          })
        ]);
      } else {
        setEmployeeList([]);
      }
    });
  }

  /***************************************************************************************
   * 설명 : 메뉴 리스트 가져오기
  ***************************************************************************************
  const getMenuList = (siteSeq) => {

    const findNode = (data, id) => {
      // root
      if(id.length === 3) return data;
      for(let i = 0; i < data.length; i++ ) {
        if( data[i]?.menuOrder === id.substr(0, id.length - 3) )
          return data[i];

        if( data[i].children !== undefined ) {
          let result = findNode(data[i].children, id);
          if( result !== false ) return result;
        }
      }

      return false;
    }

    getMenuListApi(getApi,
      {siteSeq: siteSeq}, (response) => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {

        let tmp = [];
        let data = response.data.data;

        data.forEach((item, index) => {
          let parentNode = findNode(tmp, item.menuOrder);

          if( item.menuOrder.length === 3 ) {
            parentNode.push({
              title: item.menuViewName,
              expanded: true,
              ...item
            });

          } else {
            if( parentNode.children === undefined ) parentNode.children = [];
              parentNode.children.push({
                title: item.menuViewName,
                expanded: true,
                ...item
              });
          }
        });

        setMenuList(tmp);

      } else {
        setMenuList([]);
      }
    });
  }

  /***************************************************************************************
   * 설명 : 데이터 로딩 처리
  ***************************************************************************************/
  const getList = () => {
    let params = {...formik.values};

    if( params.userId !== '' ) {
      params.userId = params.userId.value;
    }

    getLogApi(getApi, params, (response) => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        setList([
          ...response.data.data.map((item) => {
            return {
              ...item,
              pageNo: formik.values.pageNo,
              pageRow: formik.values.pageRow,
              searchText: formik.values.searchText
            }
          })
        ]);

        let totalCount = (response.data.data1 || 0);
        let totalPage = Math.floor(totalCount / formik.values.pageRow);
        if(totalCount % formik.values.pageRow > 0 ) totalPage = totalPage + 1;
        formik.setFieldValue('totalPage', totalPage);
        formik.setFieldValue('totalCount', totalCount);

      } else {
        setList([]);
      }
    });
  }

  /***************************************************************************************
   * 설명 : 전체, 성공, 실패 변경시 검색 처리
  ***************************************************************************************/
  useEffect(() => {
    getList();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.isSuccess, formik.values.pageNo])


  /***************************************************************************************
   * 설명 : 데이터 로딩 처리
  ***************************************************************************************/
  useEffect(() => {
    // 사원 리스트 가져오기
    getEmployeeList();

    // 메뉴 리스트 가져오기
    // getMenuList('');

    // 로그 데이터 가져오기
    getList();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <Layout>
      <section className="admin-cms-contents">
        <form onSubmit={formik.handleSubmit}>
          <header className="admin-cms-search-header">
            <div className="left">
              <div className='fl'>
                <BtnRefresh click={() => {
                  formik.setFieldValue('siteSeq', null);
                  formik.handleSubmit();
                }}></BtnRefresh>
                <BtnSearch click={() => getList()}></BtnSearch>
                <BtnAgGridSave click={setGridUpdate}></BtnAgGridSave>
              </div>
              <div className='fl'>
                <InputEx
                  type="date"
                  name="startDate"
                  formik={formik}
                  fullWidth={false}
                  label="검색시작일"
                  style={{width: '140px'}}
                />

                <span className="ml5 mr5 lh28px">~</span>

                <InputEx
                  type="date"
                  name="endDate"
                  formik={formik}
                  fullWidth={false}
                  label="검색종료일"
                  style={{width: '140px'}}
                />
              </div>

              <span className="ml5" />

              <ToggleButtonGroupEx
                name="isSuccess"
                exclusive={true}
                formik={formik}
                className="search-toggle-btn"
                data={[
                  {label: '전체', value: ''},
                  {label: '성공', value: 1},
                  {label: '실패', value: 2},
                ]}
              />

              <span className="ml5" />

              <SelectSearchEx
                name="userId"
                formik={formik}
                data={[
                  {label: '사용자 선택', value: ''}
                ].concat(employeeList)}
                height={28}
                isMulti={false}
                closeMenuOnSelect={true}
                hideSelectedOptions={true}
                placeholder="사용자 선택"
                fullWidth={false}
                optionWidth="400px"
                style={{width: '200px'}}
              />

              <InputEx
                name="searchText"
                formik={formik}
                label="사용자명, 아이디 검색"
                className="ml5"
                style={{width: '220px', maxWidth: 'calc(100vw - 880px)'}}
              />
            </div>
          </header>
        </form>

        <section className="admin-cms-body">
          <section className="ag-theme-balham grid pad-height90-percent" style={{height:'calc(100% - 60px)'}}>
            <AgGridReact
              defaultColDef={{
                sortable: true,
                resizable: true,
                filter: false,
                lockVisible: true,
                tooltipComponent: customTooltipAgGrid,
              }}
              tooltipShowDelay={0}
              tooltipHideDelay={2000}
              rowSelection = {'multiple'}
              columnDefs={columnDefs}
              rowData={list}
              onGridReady={(event) => {
                gridConfig(event.columnApi);
              }}
              onRowDoubleClicked={(event) => {
              }}
              rowDragManaged={true}
              rowDragMultiRow={true}
              animateRows={true}
              overlayNoRowsTemplate = "검색된 내용이 없습니다."
            />
          </section>
          <section className="pagination mt10">
            <div className="fl">
              <CustomPagenation
                currPage={{pageNo: formik.values.pageNo, pageRow: formik.values.pageRow}}
                totalPage={formik.values.totalPage}
                change={(event, pageNo) => {
                  formik.setFieldValue("pageNo", pageNo);
                }}
              ></CustomPagenation>
            </div>
            <div className="fr f12">
              검색 :<span className="ml10">{comma(formik.values.totalCount)}</span>
            </div>
          </section>
        </section>


      </section>
    </Layout>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default LogData;