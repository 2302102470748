/*****************************************************************************************
 * 설명 : 관리자 > 컨텐츠관리 > 기본 메뉴 설정
 * URI : /admin/cms/menuList
*****************************************************************************************/
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from "yup";
import Dialog from '@mui/material/Dialog';

import Restful from 'service/restful';
import { PaperComponent, searchOf, lpad, getSelectData } from 'service/utils';
import { getModuleInfoApi } from 'service/cms';
import { getBoardInfoListApi } from 'service/board';
import { setMenuApi, deleteMenuApi } from 'service/cms';

import { MESSAGE_DELAY } from 'config/config';

import { ButtonEx, SelectEx, InputEx, ToggleButtonGroupEx } from 'components/inputEx';
import alertMsg from 'components/message';
import AlertDialog from 'components/alertDiolog';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const TabMenuConfig = ( props ) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [getApi] = Restful();

  const [openModal, setOpenModal] = useState({open: false, modal: 'del', data: []});

  const [moduleList, setModuleList] = useState([]);
  const [boardInfoList, setBoardInfoList] = useState([]);
  const [boardGroupList, setBoardGroupList] = useState([]);

  const initValues = {
    seq: '',
    siteSeq: '',
    menuOrder: '',
    menuName: '',
    menuViewName: '',
    moduleSeq: '',
    moduleName: '',
    modulePath: '',
    isRouter: 1,
    isUse: 1,
    isMainView: 1,
    isSubView: 1,
    linkUrl: '',
    isLogin: 0,
    icon: '',
    queryString: '',
    isOpen: 0,
    boardSeq: '',
    group: ''
  }

  const formik = useFormik({
    initialValues: initValues,
    validationSchema: Yup.object().shape({
      menuName: Yup.string().max(45, "45자리").required('필수'),
      menuViewName: Yup.string().max(45, "45자리").required('필수'),
      icon: Yup.string().max(255, "255자리").nullable(),
      linkUrl: Yup.string().max(255, "255자리").nullable(),
      queryString: Yup.string().max(1000, "1000자리").nullable(),
    }),
    onSubmit: (values) => {
      let params = {...values}

      // 추가시
      if( values.seq === 0 ) {
        let menuOrder = '001';

        // 첫 메뉴일 경우
        if( values.menuOrder === '' ) {
          if( props.menuList.length !== 0 )
            menuOrder = lpad( parseInt(props.menuList[ props.menuList.length - 1 ].menuOrder) + 1, 3, '0');

        // 선택된 메뉴가 있을 경우 하위 번호
        } else {
          let page = searchOf( props.menuList, 'menuOrder', values.menuOrder );

          if( page.menuOrder === undefined ) {
            menuOrder = lpad( parseInt(props.menuList[ props.menuList.length - 1 ].menuOrder) + 1, 3, '0');

          } else if( page.children === undefined || page.children.length < 1 )
            menuOrder = values.menuOrder + '001';

          else {
            let length = page.children[ page.children.length - 1 ].menuOrder.length;
            menuOrder = lpad( parseInt(page.children[ page.children.length - 1 ].menuOrder) + 1, length, '0');
          }
        }

        params.menuOrder = menuOrder;
        formik.setFieldValue("menuOrder", menuOrder);
      }

      setMenuApi(getApi, params, (response) => {
        if( response !== undefined && response.data.result ) {
          alertMsg(response.data.message, "S", MESSAGE_DELAY);

          if( props.getList ) props.getList();

        } else {
          alertMsg(response.data.message, "E", MESSAGE_DELAY);
        }
      })
    }
  });

  /***************************************************************************************
   * 설명 : 모듈 리스트 가져오기
  ***************************************************************************************/
  const getModuleList = () => {
    getModuleInfoApi(getApi, formik.values, (response) => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        setModuleList(getSelectData(response.data.data, 'moduleName', 'seq'));
      } else {
        setModuleList([]);
      }
    });
  }

  /***************************************************************************************
   * 설명 : 게시판 정보 리스트 가져오기
  ***************************************************************************************/
  const getBoardInfoList = () => {
    getBoardInfoListApi(getApi, (response) => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        setBoardInfoList(getSelectData(response.data.data, 'boardName', 'seq'));
      } else {
        setBoardInfoList([]);
      }
    });
  }

  /***************************************************************************************
   * 설명 : 메뉴 정보 삭제
  ***************************************************************************************/
  const deleteMenu = () => {
    deleteMenuApi(getApi, formik.values, (response) => {
      if( response !== undefined && response.data.result ) {
        alertMsg(response.data.message, "S", MESSAGE_DELAY);

        if( props.getList ) props.getList();

        props.close();

      } else {
        alertMsg(response.data.message || '서버와의 통신에 실패하였습니다.', "E", MESSAGE_DELAY);
      }
    });
  }

  /***************************************************************************************
   * 설명 : 게시판 선택 시 그룹 리스트 변경 처리
  ***************************************************************************************/
  useEffect(() => {
    if( props.tabIndex === 0 ) {
      if( formik.values.boardId === '' ) {
        setBoardGroupList([]);

      } else {
        let boardData = boardInfoList.filter((item) => {
          return parseInt(item.seq) === parseInt(formik.values.boardId);
        });

        if( boardData.length < 1 ) setBoardGroupList([]);
        else if( boardData[0].groupCode === '' ) setBoardGroupList([]);
        else {
          let group = boardData[0].groupCode?.split('|');
          let groupList = group.map((item) => {
            return {
              label: item, value: item
            }
          });

          setBoardGroupList(groupList);
        }
      }
    }

    // eslint-disable-next-line
  }, [formik.values.boardId]);

  /***************************************************************************************
   * 설명 : 모듈 변경 시 모듈 정보 표시
  ***************************************************************************************/
  useEffect(() => {
    if( props.tabIndex === 0 ) {
      let node = moduleList.filter((item) => item.seq === parseInt(formik.values.moduleSeq));

      // 모듈 빈 선택
      if( node.length < 1 || node[0].seq === undefined ) {
        formik.setFieldValue("moduleName", "");
        formik.setFieldValue("modulePath", "");

      } else {
        formik.setFieldValue("moduleName", node[0].moduleName);
        formik.setFieldValue("modulePath", node[0].modulePath);
      }
    }

    // eslint-disable-next-line
  }, [formik.values.moduleSeq])

  /***************************************************************************************
   * 설명 : 사이트 선택 시 처리
  ***************************************************************************************/
  useEffect(() => {
    if( props.tabIndex === 0 ) {
      formik.setFieldValue('siteSeq', props.siteSeq );
    }

    // eslint-disable-next-line
  }, [props.siteSeq]);

  /***************************************************************************************
   * 설명 : 등록/수정 시 처리
  ***************************************************************************************/
  useEffect(() => {
    if( props.tabIndex === 0 ) {

      // 추가 시 처리
      if( props.data.seq === 0 || props.data.seq === '' ) {
        let data = {
          ...initValues
        }
        data.seq = 0;
        data.siteSeq = props.siteSeq;
        data.menuOrder = props.data?.menuOrder || '';

        formik.setValues( data );

      // 수정 시 처리
      } else {
        formik.setValues( props.data );
      }
    }

    // eslint-disable-next-line
  }, [props.data]);

  /***************************************************************************************
   * 설명 : 등록/수정 시 처리
  ***************************************************************************************/
  useEffect(() => {
    if( props.tabIndex === 0 ) {
      // 모듈 리스트 가져오기
      getModuleList();

      // 게시판 정보 가져오기
      getBoardInfoList();

      formik.setValues( initValues );
    }

    // eslint-disable-next-line
  }, [props.tabIndex]);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <>
      { props.siteSeq === '' &&
        <section className="pt10">
          <div className="menu-list-add-background f15">
            사이트를 선택 후에 '메뉴등록' 혹은 '메뉴선택'을 진행해주세요.
          </div>
        </section>
      }

      {props.siteSeq !== '' &&
        <>
          { ( props.data.seq === undefined || props.data.siteSeq === undefined ) &&
            <section className="pt10">
              <div className="menu-list-add-background">
                메뉴를 선택하시기 바랍니다.
              </div>
            </section>
          }
          { props.data.seq !== undefined && props.data.siteSeq !== undefined &&
            <>
              <section className="pt10">
                <form onSubmit={formik.handleSubmit}>
                  { ( props?.data?.seq === undefined || props?.data?.seq === 0 ) &&
                    <div className="menu-list-add-background">
                      신규 메뉴 추가 중 입니다.
                    </div>
                  }

                  <h4 className="mt20 mb10">※ 메뉴 기본 정보</h4>

                  <table className="input-table">
                    <colgroup>
                      <col style={{width: '10%'}} />
                      <col style={{width: '23%'}} />
                      <col style={{width: '10%'}} />
                      <col style={{width: '23%'}} />
                      <col style={{width: '10%'}} />
                      <col style={{width: '23%'}} />
                    </colgroup>

                    <tbody>
                      <tr>
                        <th scope="row"><label htmlFor="seq">메뉴코드</label></th>
                        <td>
                          <InputEx
                            name="seq"
                            formik={formik}
                            fullWidth={true}
                            disabled={true}
                          />
                        </td>
                        <th scope="row"><label htmlFor="menuName">메뉴명</label></th>
                        <td>
                          <InputEx
                            name="menuName"
                            formik={formik}
                            fullWidth={true}
                          />
                        </td>
                        <th scope="row"><label htmlFor="menuViewName">페이지명(표시)</label></th>
                        <td>
                          <InputEx
                            name="menuViewName"
                            formik={formik}
                            fullWidth={true}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th scope="row"><label htmlFor="moduleSeq">모듈선택</label></th>
                        <td>
                          <SelectEx
                            name="moduleSeq"
                            formik={formik}
                            fullWidth={true}
                            data={[
                              {label: '모듈 선택', value: ''}
                            ].concat(moduleList)}
                          />
                        </td>
                        <th scope="row"><label htmlFor="moduleName">모듈명</label></th>
                        <td>
                          <InputEx
                            name="moduleName"
                            formik={formik}
                            fullWidth={true}
                            disabled={true}
                          />
                        </td>
                        <th scope="row"><label htmlFor="modulePath">모듈 Path</label></th>
                        <td>
                          <InputEx
                            name="modulePath"
                            formik={formik}
                            fullWidth={true}
                            disabled={true}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th scope="row"><label htmlFor="isUse">사용여부</label></th>
                        <td>
                          <ToggleButtonGroupEx
                            name="isUse"
                            formik={formik}
                            fullWidth={false}
                            exclusive={true}
                            data={[
                              {label: '사용', value: 1},
                              {label: '사용안함', value: 0}
                            ]}
                          />
                        </td>
                        <th scope="row"><label htmlFor="isMainView">메인메뉴 표시</label></th>
                        <td>
                          <ToggleButtonGroupEx
                            name="isMainView"
                            formik={formik}
                            fullWidth={false}
                            exclusive={true}
                            data={[
                              {label: '사용', value: 1},
                              {label: '사용안함', value: 0}
                            ]}
                          />
                        </td>
                        <th scope="row"><label htmlFor="isSubView">메뉴 표시</label></th>
                        <td>
                          <ToggleButtonGroupEx
                            name="isSubView"
                            formik={formik}
                            fullWidth={false}
                            exclusive={true}
                            data={[
                              {label: '사용', value: 1},
                              {label: '사용안함', value: 0}
                            ]}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th scope="row"><label htmlFor="linkUrl">링크주소</label></th>
                        <td>
                          <InputEx
                            name="linkUrl"
                            formik={formik}
                            fullWidth={true}
                            label="쿼리 스트링 제외한 링크 주소"
                          />
                        </td>
                        <th scope="row"><label htmlFor="queryString">QueryString</label></th>
                        <td>
                          <InputEx
                            name="queryString"
                            formik={formik}
                            fullWidth={true}
                            label="?이후의 값"
                          />
                        </td>
                        <th scope="row"><label htmlFor="isOpen">새창 여부</label></th>
                        <td>
                          <ToggleButtonGroupEx
                            name="isOpen"
                            formik={formik}
                            fullWidth={false}
                            exclusive={true}
                            data={[
                              {label: '현재창', value: 0},
                              {label: '새창', value: 1},
                              {label: '팝업', value: 2}
                            ]}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th scope="row"><label htmlFor="isLogin">로그인 여부</label></th>
                        <td>
                          <ToggleButtonGroupEx
                            name="isLogin"
                            formik={formik}
                            fullWidth={false}
                            exclusive={true}
                            data={[
                              {label: '전체접근', value: 0},
                              {label: '로그인 필수', value: 1},
                            ]}
                          />
                        </td>
                        <th scope="row"><label htmlFor="icon">아이콘</label></th>
                        <td>
                          <InputEx
                            name="icon"
                            formik={formik}
                            fullWidth={true}
                            label="Meterial Icon"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <h4 className="mt20 mb10">※ 게시판 정보</h4>

                  <table className="input-table">
                    <colgroup>
                      <col style={{width: '10%'}} />
                      <col style={{width: '23%'}} />
                      <col style={{width: '10%'}} />
                      <col style={{width: '23%'}} />
                      <col style={{width: '10%'}} />
                      <col style={{width: '23%'}} />
                    </colgroup>

                    <tbody>
                      <tr>
                        <th scope="row"><label htmlFor="boardId">게시판선택</label></th>
                        <td>
                          <SelectEx
                            name="boardId"
                            formik={formik}
                            fullWidth={true}
                            data={[
                              {label: '게시판 선택', value: ''}
                            ].concat(boardInfoList)}
                          />
                        </td>
                        <th scope="row"><label htmlFor="group">게시판그룹</label></th>
                        <td>
                          <SelectEx
                            name="group"
                            formik={formik}
                            fullWidth={true}
                            data={[
                              {label: '게시판그룹 선택', value: ''}
                            ].concat(boardGroupList)}
                          />
                        </td>
                        <th scope="row"></th>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>

                  <footer className="mt10">
                    <div className="fl left">
                      <ButtonEx
                        title="메뉴정보 삭제"
                        auth={"isDelete"}
                        color="error"
                        variant="outlined"
                        className="Btn"
                        onClick={() => {
                          if( props.data.children?.length > 0 ) {
                            alertMsg("하위 메뉴가 존재할 경우 삭제가 불가능합니다.", "W", MESSAGE_DELAY);
                            return;
                          }
                          // 하위 메뉴가 있을 경우 삭제 불가
                          setOpenModal({open: true, modal: 'del', data: {}})
                        }}
                      />
                    </div>
                    <div className="fr right">
                      <ButtonEx
                        title="기본정보 저장"
                        auth={formik.values.seq === 0 || formik.values.seq === '' ? "isWrite" : "isModify"}
                        type="submit"
                        color="primary"
                        variant="contained"
                        className="Btn"
                      />
                    </div>
                  </footer>
                </form>
              </section>
           </>
          }
        </>
      }

      { openModal.open && openModal.modal === 'del' &&
        <Dialog
          open={openModal}
          onClose={() => (event, reason) => {
            if(reason && reason === "backdropClick") return;
            setOpenModal({open: false, modal: openModal.modal, data: {}});
          }}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "500px",  // Set your width here
              },
            },
          }}
        >
          <section className="modal">
            <AlertDialog
              open={openModal.open}
              close={() => setOpenModal({ open: false, modalName: openModal.modal, data: openModal.data })}
              title="메뉴 삭제"
              message={`선택하신 메뉴를 삭제하시겠습니까?`}
              confirm="삭제"
              onClick={deleteMenu}
              indicator={props.indicator}
              history={props.history}
            />
          </section>
        </Dialog>
      }
    </>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default TabMenuConfig;