/*****************************************************************************************
 * 설명 : 관리자 > 컨텐츠관리 > 게시판 관리 > 추가/수정
 * URI : /admin/board
*****************************************************************************************/
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from "yup";

import { MESSAGE_DELAY } from 'config/config';

import { InputEx, SelectEx, ToggleButtonGroupEx } from 'components/inputEx';
import alertMsg from 'components/message';

import { getBoardTableListApi, setBoardInfoApi } from 'service/board';
import Restful from 'service/restful';
import { PaperComponentSub } from 'service/utils';

import BoardTableAddModal from './boardTableAddModal';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
export const BoardModal = ( props ) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [getApi] = Restful();

  const [title, setTitle] = useState('추가');

  const [openModal, setOpenModal] = useState(false);

  const [tableList, setTableList] = useState([]);
  const [uploadTableList, setUploadTableList] = useState([]);

  const formik = useFormik({
    initialValues: {
      seq: 0,
      boardId: '',
      isGroupCode: 0,
      groupCode: '',
      boardName: '',
      tableSeq: 0,
      uploadTableSeq: 0,
      uploadFileMaxSie: 0,
      allowUploadFile: '*',
      noticeRowsCount: 0,
      newDayCount: 7,
      sortOrder: ''
    },
    validationSchema: Yup.object().shape({
      boardId: Yup.string().max(20, "20자리").required('필수'),
      group: Yup.string().max(1000, "1000자리"),
      boardName: Yup.string().max(30, "30자리").required('필수'),
      uploadFileType: Yup.string().max(100, "100자리"),
    }),
    onSubmit: (values) => {
      setBoardInfoApi(getApi, values, (response) => {
        if( response !== undefined && response.data.result ) {
          alertMsg(response.data.message, "S", MESSAGE_DELAY);

          if( props.getList ) props.getList();

          props.close();

        } else {
          alertMsg(response.data.message, "E", MESSAGE_DELAY);
        }
      });
    }
  });

  /***************************************************************************************
   * 설명 : 게시판 테이블 리스트 가져오기
  ***************************************************************************************/
  const getTableList = () => {
    getBoardTableListApi(getApi, (response) => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        let data = response.data.data;

        let table = [];
        let uploadTable = [];

        data.forEach((item) => {
          switch(item.tableType) {
            case 'BOARD':
              table.push({ label: item.tableName, value: item.seq });
              break;

            case 'UPLOAD':
              uploadTable.push({ label: item.tableName, value: item.seq });
              break;

            default:
              break;
          }
        });

        setTableList(table);
        setUploadTableList(uploadTable);

      } else {
        setTableList([]);
        setUploadTableList([]);
      }
    })
  }

  /***************************************************************************************
   * 설명 : 모달 오픈 시 처리
  ***************************************************************************************/
  useEffect(() => {
    if( props.open ) {
      getTableList();

      if( props.data.seq !== undefined ) {
        formik.setValues(props.data);

        setTitle('수정');
      } else {
        setTitle('추가');
      }
    }

    // eslint-disable-next-line
  }, [props.open]);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <section className="modal-wrap" style={{ width: '100%' }}>
      <form onSubmit={formik.handleSubmit}>
        <header className="modal-header" style={{ cursor: 'move' }} id="draggable-dialog-title">
          <div className="modal-title">게시판 {title}</div>
          <Button className="close-btn" onClick={props.close}>
            <CloseIcon style={{ color: '#fff', fontSize: '28px' }}></CloseIcon>
          </Button>
        </header>

        <section className="modal-body">
          <h4 className="mt5 mb10">※ 게시판 기본 정보</h4>

          <table className="input-table">
            <colgroup>
              <col style={{width: '10%'}} />
              <col style={{width: '23%'}} />
              <col style={{width: '10%'}} />
              <col style={{width: '23%'}} />
              <col style={{width: '10%'}} />
              <col style={{width: '23%'}} />
            </colgroup>

            <tbody>
              <tr>
                <th scope="row"><label htmlFor="boardId">게시판아이디</label></th>
                <td>
                  <InputEx
                    name="boardId"
                    formik={formik}
                    fullWidth={true}
                    label="영문"
                    focus={true}
                  />
                </td>
                <th scope="row"><label htmlFor="boardName">게시판명</label></th>
                <td>
                  <InputEx
                    name="boardName"
                    formik={formik}
                    fullWidth={true}
                  />
                </td>
                <th scope="row"><label htmlFor="noticeRowCount">공지사항개수</label></th>
                <td>
                  <InputEx
                    type="number"
                    name="noticeRowCount"
                    formik={formik}
                    fullWidth={true}
                    label="공지사항 표시 개수"
                  />
                </td>
              </tr>
              <tr>
                <th scope="row"><label htmlFor="tableSeq">게시판테이블</label></th>
                <td>
                  <SelectEx
                    name="tableSeq"
                    formik={formik}
                    fullWidth={true}
                    data={[
                      {label: '테이블 선택', value: ''}
                    ].concat(tableList)}
                  />
                </td>
                <th scope="row"><label htmlFor="uploadTableSeq">업로드테이블</label></th>
                <td>
                  <SelectEx
                    name="uploadTableSeq"
                    formik={formik}
                    fullWidth={true}
                    data={[
                      {label: '테이블 선택', value: ''}
                    ].concat(uploadTableList)}
                  />
                </td>
                <th scope="row"><label htmlFor="newDayCount">신규표시</label></th>
                <td>
                  <InputEx
                    type="number"
                    name="newDayCount"
                    formik={formik}
                    fullWidth={true}
                    label="일자기준"
                  />
                </td>
              </tr>
            </tbody>
          </table>

          <h4 className="mt20 mb10">※ 게시판 표시형식 정보</h4>

          <table className="input-table">
            <colgroup>
              <col style={{width: '10%'}} />
              <col style={{width: '23%'}} />
              <col style={{width: '10%'}} />
              <col style={{width: '23%'}} />
              <col style={{width: '10%'}} />
              <col style={{width: '23%'}} />
            </colgroup>

            <tbody>
              <tr>
                <th scope="row"><label htmlFor="isGroupCode">그룹 사용</label></th>
                <td>
                  <ToggleButtonGroupEx
                    name="isGroupCode"
                    formik={formik}
                    fullWidth={false}
                    exclusive={true}
                    data={[
                      {label: '사용안함', value: 0},
                      {label: '사용', value: 1},
                    ]}
                  />
                </td>
                <th scope="row"><label htmlFor="groupCode">게시판그룹</label></th>
                <td colSpan={3}>
                  <InputEx
                    name="groupCode"
                    formik={formik}
                    fullWidth={true}
                    label="| 구분자로 된 그룹 리스트"
                  />
                </td>
              </tr>
            </tbody>
          </table>

          <h4 className="mt20 mb10">※ 게시판 파일/이미지 업로드 설정</h4>

          <table className="input-table">
            <colgroup>
              <col style={{width: '10%'}} />
              <col style={{width: '13%'}} />
              <col style={{width: '10%'}} />
              <col style={{width: '13%'}} />
            </colgroup>

            <tbody>
              <tr>
                <th scope="row"><label htmlFor="uploadFileMaxSize">파일업로드최대</label></th>
                <td>
                  <InputEx
                    type="number"
                    name="uploadFileMaxSize"
                    formik={formik}
                    fullWidth={true}
                    label="[MB]0일 경우 제한 없음"
                  />
                </td>
                <th scope="row"><label htmlFor="allowUploadFile">파일타입</label></th>
                <td>
                  <InputEx
                    name="allowUploadFile"
                    formik={formik}
                    fullWidth={true}
                    label="| 구분자, 확장명"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </section>

        <footer className="modal-footer">
          <div className="">
            <Button
              color="success"
              variant="contained"
              className="Btn"
              onClick={() => {
                setOpenModal({open: true, modal: 'add', data: {}});
              }}
            >게시판테이블 추가</Button>
          </div>
          <div className="text-right">
            <Button
              color="inherit"
              variant="outlined"
              className="Btn"
              onClick={() => {
                props.close();
              }}
            >창닫기</Button>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              className="Btn ml10"
            >저장하기</Button>
          </div>
        </footer>
      </form>

      { openModal.open && openModal.modal === 'add' &&
        <Dialog
          open={openModal}
          onClose={() => (event, reason) => {
            if(reason && reason === "backdropClick") return;
            setOpenModal({open: false, modal: openModal.modal, data: {}});
          }}
          PaperComponent={PaperComponentSub}
          aria-labelledby="draggable-dialog-title"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "500px",  // Set your width here
              },
            },
          }}
        >
          <section className="modal">
            <BoardTableAddModal
              open={openModal}
              close={() => setOpenModal(false)}
              getList={getTableList}
              indicator={props.indicator}
              history={props.history}
            />
          </section>
        </Dialog>
      }
    </section>
  )
}

export default BoardModal;