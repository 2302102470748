/**
 * * AutoCad 에 File을 업로드 하고 URN 정보를 가져온다.
 * @param {*} getApi
 * @param {*} func
 * @returns
 */
export function getFileUrnApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("get", '/api/v1/autocad/urn', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  })
}