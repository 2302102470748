/*****************************************************************************************
 * 설명 : 관리자 > 프로젝트 관리 > 파일정보 관리
*****************************************************************************************/
import FolderIcon from '@mui/icons-material/Folder';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { AgGridReact } from 'ag-grid-react';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import * as Yup from "yup";

import BtnAgGridSave from 'components/btnAgGridSave';
import ChipEx from 'components/chipEx';
import { BtnRefresh, BtnSearch } from 'components/common';
import { ButtonEx, InputEx, SelectEx, ToggleButtonGroupEx } from 'components/inputEx';
import alertMsg from 'components/message';
import customTooltipAgGrid from 'components/tooltipAgGrid';

import { getCommonListApi } from 'service/common';
import { getProjectPathFileApi } from 'service/project';
import Restful from 'service/restful';
import { comma, formatBytes, getSelectData, PaperComponent } from 'service/utils';

import { baseURL, DOWNLOAD_FILE, MESSAGE_DELAY } from 'config/config';

import useGridConfig from 'hooks/useGridConfig';

import ApplicationPerusalModal from 'pages/admin/project/modal/applicationPerusalModal';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const TabUserFile = ( props ) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const currentMenu = useSelector(state => state?.menuList?.current);

  const [getApi] = Restful();

  const [list, setList] = useState([]);
  const [fileExtList, setFileExtList] = useState([]);
  const [gridApi, setGridApi] = useState({});

  const [openModal, setOpenModal] = useState({open: false, modal: '', data: {}});

  const [pathInfo, setPathInfo] = useState({
    totalDirectoryCount: 0,
    totalFileCount: 0,
    totalSize: 0
  });

  const [dirPathInfo, setDirPathInfo] = useState({
    totalDirectoryCount: 0,
    totalFileCount: 0,
    totalSize: 0
  });

  const initialValues = {
    ext: '',
    extSearch: '',
    fileSize: '',
    sizeType: 1,
    searchText: '',
    isAuth: '',
    isDownload: ''
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object().shape({
      searchText: Yup.string().max(30, "30자리"),
      fileSize: Yup.number().typeError("숫자"),
    }),
    onSubmit: (values) => {
      getList();
    }
  });

  // table column
  const [columnDefs, setColumnDefs] = useState([
    { headerName: '키값(수정금지)', field: 'seq', hide: true },
    { headerName: '순번', field: 'rowIndex', width: 60, cellClass: 'cp text-right',
      valueGetter: (params) => {
        return comma(params.node.rowIndex + 1);
      }
    },
    { headerName:'열람신청', field: 'status', width: 60, cellClass: 'cp center',
      cellRendererFramework: function (params) {
        if( parseInt(params.data.status) === 1 )
          return <ChipEx color="primary" variant="outlined" size="small" label="신청" />;
        else if( parseInt(params.data.status) === 2 )
          return <ChipEx color="success" variant="outlined" size="small" label="승인" />;
        else if( parseInt(params.data.status) === 3 )
          return <ChipEx color="error" variant="outlined" size="small" label="반려" />;
        else
          return '';
      }
    },
    { headerName:'경로명', field: 'path', width: 250, cellClass: 'cp', tooltipField: 'path'},
    { headerName:'파일명', field: 'title', width: 150, cellClass: 'cp', tooltipField: 'title',
      cellRendererFramework: function (params) {
        if( parseInt(params.data?.type || 0) === 1 )
          return params.data.title;
        else
          return (
            <div
              className="flex vcenter" style={{height: '100%'}}>
                <FolderIcon style={{fontSize: '15px'}}/>
                <div className="ml5" style={{width: 'calc(100% - 30px)'}}>
                  {params.data.title}
                </div>
            </div>
          );
      }
    },
    { headerName:'용량', field: 'fileSize', width: 100, cellClass: 'cp text-right',
      valueGetter: (params) => {
        return formatBytes(params.data?.fileSize || 0, 2);
      }
    },
    { headerName:'태그', field: 'tag', width: 380, cellClass: 'cp', tooltipField: 'tag' },
    { headerName:'비고', field: 'memo', width: 200, cellClass: 'cp', tooltipField: 'memo' },
    { headerName:'등록일시', field: 'createDate', width: 150, cellClass: 'cp center' },
    { headerName:'다운로드', field: 'download', width: 70, cellClass: 'cp center',
      cellRendererFramework: function (params) {
        if(
          (
            parseInt(params.data.readingGrade) === 0 &&
            parseInt(params.data.isAuth) === 0 &&
            ( parseInt(params.data.isDownload) === 1 || parseInt(currentMenu.isDownload) === 1 )
          ) ||
          ( parseInt(params.data.status) === 2 && parseInt(params.data.isDownloadRequest) === 1 )
        ) {
          return (
            <div className={"grid-icon"}>
              <Button
                color="inherit"
                onClick={() => {
                  let url = baseURL + '/api/v1/file/download?seq=' + params.data.seq;
                  window.open(url)
                }}
              >
                <i className="ri-download-line"></i>
              </Button>
            </div>
          );
        } else {
          return '';
        }
      }
    },
    { headerName:'미리보기', field: 'preview', width: 70, cellClass: 'cp center',
      cellRendererFramework: function (params) {
        if(
          (
            parseInt(params.data.readingGrade) === 0 &&
            parseInt(params.data.isAuth) === 0 &&
            ( parseInt(params.data.isPreview) === 1 || parseInt(currentMenu.isPreview) === 1 )
          ) ||
          ( parseInt(params.data.status) === 2 )
        ) {
          return (
            <div className={"grid-icon"}>
              { DOWNLOAD_FILE.includes(params.data.ext?.toLowerCase()) &&
                <Button
                  color="inherit"
                  onClick={() => {
                    let url = 'http://archive.dagroup.co.kr/synap/SynapDocViewServer/job?fileType=Local&sync=true&fid=' + params.data.seq + '&filePath=';
                    window.open(url + encodeURIComponent(params.data.destPath))
                  }}
                >
                  <i className="ri-eye-line"></i>
                </Button>
              }
              { params.data.ext?.toLowerCase() === 'dwg' &&
                <Button
                  color="inherit"
                  onClick={() => {
                    let url = '/autocad?seq=' + params.data.seq;
                    window.open(url)
                  }}
                >
                  <i className="ri-eye-line"></i>
                </Button>
              }
            </div>
          );
        } else {
          return '';
        }
      }
    },
    { headerName:'열람기간', field: 'isDate', width: 200, cellClass: 'cp center',
      cellRendererFramework: (params) => {
        if( parseInt(params.data.status) === 2 ) {
          if( parseInt(params.data.isDate) === 1 ) return <ChipEx color="primary" variant="outlined" size="small" label="기간제한 없음" />;
          else return params.data.approvalStartDate + ' ~ ' + params.data.approvalEndDate;
        } else {
          return '';
        }
      }
    }
  ]);

  const [gridConfig, setGridUpdate] = useGridConfig(31, setColumnDefs);

  /***************************************************************************************
   * 설명 : 파일정보 관리
  ***************************************************************************************/
  const getList = () => {
    let params = {
      projectNumber: props?.selected?.projectNumber,
      ...formik.values
    }

    getProjectPathFileApi(getApi, params, (response) => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        setList(response.data.data);

        if( response.data.data1)
          setPathInfo(response.data.data2);

        if( response.data.data2)
          setDirPathInfo(response.data.data1);

      } else {
        setList([]);
      }
    })
  }

  /***************************************************************************************
   * 설명 : 검색 초기화
  ***************************************************************************************/
  const initSearchGroup = () => {
    formik.setValues(initialValues);

    formik.handleSubmit();
  }

  /***************************************************************************************
   * 설명 : 공통코드 리스트 데이터 가져오기
  ***************************************************************************************/
  const getCommonList = () => {
    let params = {
      groupCode: 'EXT'
    }

    getCommonListApi(getApi, params, (response) => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        setFileExtList(getSelectData(response.data.data, 'commonName', 'commonCode'));
      } else {
        setFileExtList([]);
      }
    });
  }

  /***************************************************************************************
   * 설명 : 파일형식 변경 시 자동 검색
  ***************************************************************************************/
  useEffect(() => {
    if( props.tabIndex === 1 && formik.values.ext !== 'ext') {
      getList();
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.ext])

  /***************************************************************************************
   * 설명 : 데이터 처리
  ***************************************************************************************/
  useEffect(() => {
    if( props.tabIndex === 1 && props.selected.seq !== undefined  ) {
      getCommonList();

      getList();
    }

    // eslint-disable-next-line
  }, [props.selected, props.tabIndex]);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <section className="project-file">
      <div className="mt10">
        <div className="fl mt5">
          <span className="bold">[{props.selected?.projectNumber}]</span>
          <span className="ml5 bold">{props.selected?.projectName}</span>
        </div>
        <div className="fr right">
        </div>
      </div>

      <div className="clearfix"></div>

      <header className="admin-cms-search-header mt10">
        <div className="fl left">
          <form onSubmit={formik.handleSubmit}>
            <BtnRefresh click={() => initSearchGroup()}></BtnRefresh>
            <BtnSearch></BtnSearch>
            <BtnAgGridSave click={setGridUpdate} />

            <SelectEx
              name="ext"
              formik={formik}
              fullWidth={false}
              style={{width: '150px'}}
              data={[
                {label: '파일형식', value: ''},
              ].concat(fileExtList).concat([{label: '직접입력', value: 'ext'}])}
              onChange={(event) => {
                if( event.target.value !== 'ext')
                  formik.setFieldValue('extSearch', '');
              }}
            />

            {formik.values.ext === 'ext' &&
              <InputEx
                name="extSearch"
                formik={formik}
                label="확장명 입력"
                className="basic ml5 mr5"
                style={{width: '100px'}}
              />
            }


            <InputEx
              name="fileSize"
              formik={formik}
              label="파일크기(MB)"
              className="basic ml5 mr5"
              style={{width: '100px'}}
            />

            <ToggleButtonGroupEx
              name="sizeType"
              exclusive={true}
              formik={formik}
              className="search-toggle-btn"
              data={[
                {label: '보다 큰', value: 1},
                {label: '보다 작은', value: 2},
              ]}
            />

            <InputEx
              name="searchText"
              formik={formik}
              fullWidth={false}
              className="ml5"
              label="파일명, 태그"
            />
          </form>
        </div>

        <div className="fr">
          <ButtonEx
            auth="isWrite"
            title="파일 열람신청"
            variant="outlined"
            color="secondary"
            onClick={() => {
              let nodes = gridApi.getSelectedRows();
              let check = true;
              nodes.forEach((item) => {
                if( item.status === 1 || item.status === 2 ) check = false;
              });

              if( check === false ) {
                alertMsg("이미 열람 신청/승인 된 내역이 있습니다.", "w", MESSAGE_DELAY);
              } else {
                setOpenModal({open: true, modal: 'perusal', data: {}})
              }
            }}
          />
        </div>

        <div className="clearfix"></div>
      </header>

      <section className="mt10">
        <section className="ag-theme-balham" style={{height:'calc(100vh - 270px)'}}>
          <div style={{ height: '100%' }}>
            <AgGridReact
              defaultColDef={{
                sortable: true,
                resizable: true,
                filter: false,
                lockVisible: true,
                tooltipComponent: customTooltipAgGrid,
              }}
              tooltipShowDelay={0}
              tooltipHideDelay={2000}
              rowSelection = {'multiple'}
              columnDefs={columnDefs}
              rowData={list}
              onGridReady={(event) => {
                gridConfig(event.columnApi);
                setGridApi(event.api);
              }}
              rowDragManaged={true}
              rowDragMultiRow={true}
              animateRows={true}
              overlayNoRowsTemplate = "검색된 내용이 없습니다."
            />
          </div>
        </section>
      </section>

      <footer className="footer-txt">
        <div className="fl">
          프로젝트 전체 : 폴더 {comma(pathInfo.totalDirectoryCount || 0)}개,
          파일 {comma(pathInfo.totalFileCount || 0)}개
          / 사용용량 : {formatBytes(pathInfo.totalSize || 0)}
        </div>

        <div className="fr">
          검색결과 : 파일 {comma(dirPathInfo.totalFileCount || 0)}개
          / 사용용량 : {formatBytes(dirPathInfo.totalSize)}
        </div>
      </footer>

      { // 이미지 미리 보기 모달
        openModal.open && openModal.modal === 'perusal' &&
        <Dialog
          open={openModal}
          onClose={() => (event, reason) => {
            if (reason && reason === "backdropClick") return;
            setOpenModal({open: false, modal: openModal.modal, data: {}});
          }}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "800px",  // Set your width here
              },
            },
          }}
        >
          <section className="modal">
            <ApplicationPerusalModal
              open={openModal.open}
              close={() => setOpenModal({ open: false, modalName: openModal.modal, data: openModal.data })}
              data={openModal.data}
              formik={formik}
              gridApi={gridApi}
              info={props.selected}
              indicator={props.indicator}
              history={props.history}
            />
          </section>
        </Dialog>
      }

    </section>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default TabUserFile;