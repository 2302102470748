/*****************************************************************************************
 * 설명 : 관리자 > 시스템관리 > 논리스토리지 관리 > 폴더 추가/수정
*****************************************************************************************/
import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import * as Yup from "yup";

// import { MESSAGE_DELAY } from 'config/config';

import { InputEx, ToggleButtonGroupEx } from 'components/inputEx';
// import alertMsg from 'components/message';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
export const RogicStorageDriveModal = ( props ) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [title, setTitle] = useState('추가');

  const formik = useFormik({
    initialValues: {
      seq: 0,
      siteName: '',
      domain: '',
      uri: '',
      isUse: '1'
    },
    validationSchema: Yup.object().shape({
      siteName: Yup.string().max(50, "50자리").required('필수'),
      domain: Yup.string().max(100, "100자리").required('필수'),
      uri: Yup.string().max(100, "100자리")
    }),
    onSubmit: (values) => {
/*
      let params = {
        program: 'api',
        service: 'cms',
        version: '1.0',
        action: 'setSiteInfo',
        ...values
      }

      restful("post", params).then( response => {
        if( response !== undefined && response.data.result ) {
          alertMsg(response.data.message, "S", MESSAGE_DELAY);

          if( props.getList ) props.getList();

          props.close();

        } else {
          alertMsg(response.data.message, "E", MESSAGE_DELAY);
        }
      });
      */
    }
  });

  /***************************************************************************************
   * 설명 : 모달 오픈 시 처리
  ***************************************************************************************/
  useEffect(() => {
    if( props.open ) {
      if( props.data.seq !== undefined ) {
        formik.setValues(props.data);

        setTitle('수정');
      } else {
        setTitle('추가');
      }
    }

    // eslint-disable-next-line
  }, [props.open]);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <section className="modal">
      <section className="modal-wrap" style={{ width: '500px' }}>
        <form onSubmit={formik.handleSubmit}>
          <header className="modal-header">
            <div className="modal-title">폴더 {title}</div>
            <Button className="close-btn" onClick={props.close}>
              <CloseIcon style={{ color: '#fff', fontSize: '28px' }}></CloseIcon>
            </Button>
          </header>

          <section className="modal-body">
            <table className="input-table">
              <colgroup>
                <col style={{width: '25%'}} />
                <col style={{width: '75%'}} />
              </colgroup>

              <tbody>
                <tr>
                  <th scope="row"><label htmlFor="siteName">폴더명</label></th>
                  <td>
                    <InputEx
                      name="siteName"
                      formik={formik}
                      focus={true}
                      fullWidth={true}
                      placeholder={"폴더명"}
                    />
                  </td>
                </tr>
                <tr>
                  <th scope="row"><label htmlFor="isUse">사용여부</label></th>
                  <td>
                    <ToggleButtonGroupEx
                      name="isUse"
                      exclusive={true}
                      formik={formik}
                      data={[
                        {label: '사용', value: '1'},
                        {label: '사용안함', value: '0'},
                      ]}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </section>

          <footer className="modal-footer">
            <div className="modal-footer-center">
              <Button
                color="inherit"
                variant="outlined"
                className="Btn"
                onClick={() => {
                  props.close();
                }}
              >창닫기</Button>

              <Button
                type="submit"
                variant="contained"
                color="primary"
                className="Btn ml10"
              >저장하기</Button>
            </div>
          </footer>
        </form>
      </section>
    </section>
  )
}

export default RogicStorageDriveModal;