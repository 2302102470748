/*****************************************************************************************
 * 설명 : PMS 로그인 연동
 * URI : /pms/login
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import qs from 'qs';
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";

import { ssoLogin } from 'service/pms';
import Restful from "service/restful";

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const PmsLogin = ( props ) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [getApi] = Restful();
  const history = useNavigate();

  const [queryString, setQueryString] = useState({});
  const [result, setResult] = useState({});

  const storage = window.localStorage;

  const setItem = (itemName, value) => {
    return storage.setItem(itemName, value);
  }

  /***************************************************************************************
   * 설명 : 로그인 처리
  ***************************************************************************************/
  const login = () => {
    let params = {
      userId: queryString.userId
    }

    ssoLogin(getApi, params, (response) => {
      if( response !== undefined && response.data.result ) {
        if( response.data.token ) {
          setItem("token", response.data.token.replace('/', '_') );
          setResult({
            result: true,
            message: '사용자 로그인에 성공하였습니다.'
          });

          setTimeout(() => {
            window.location.href = '/';
          }, 100)

        } else {
          setResult({
            result: false,
            message: '사용자 로그인에 실패하였습니다.'
          });
        }

      } else {
        setResult({
          result: false,
          message: '사용자 로그인에 실패하였습니다.'
        });
      }
    })
  }

  /***************************************************************************************
   * 설명 : userId가 넘어온 경우 로그인 처리
  ***************************************************************************************/
  useEffect(() => {
    if( ( queryString.userId ?? '' ) !== '' ) {
      login();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryString])

  /***************************************************************************************
   * 설명 : 데이터 로딩 처리
  ***************************************************************************************/
  useEffect(() => {
    let query = qs.parse(window.location.search, {
      ignoreQueryPrefix: true
    });

    setQueryString(query);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <div>
    </div>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default PmsLogin;