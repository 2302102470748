/*****************************************************************************************
 * 설명 : 프로젝트관리 - 용도
 * URI : /project
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import React from 'react';
import { useFormik } from 'formik';
import Button from '@mui/material/Button';
import * as Yup from "yup";

import { InputEx } from 'components/inputEx';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const ReasonModal = (props) => {

  const formik = useFormik({
    initialValues: {
      reason: ''
    },
    validationSchema: Yup.object().shape({
      reason: Yup.string().max(200, "200자리").required('필수').nullable(),
    }),
    onSubmit: (values) => {
      if( props.data === 'department' ) {
        props.formik.setFieldValue('departmentNameReason', values.reason);
        props.formik.setFieldValue('departmentNameLast', '');
      } else if( props.data === 'pm' ) {
        props.formik.setFieldValue('projectPmNameReason', values.reason);
        props.formik.setFieldValue('projectPmNameLast', '');
      } else if( props.data === 'apm' ) {
        props.formik.setFieldValue('projectApmNameReason', values.reason);
        props.formik.setFieldValue('projectApmNameLast', '');
      }

      props.close();
    }
  });

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <section className="modal-wrap" style={{width: '100%'}}>
      <form onSubmit={formik.handleSubmit}>
        <header className="modal-header" style={{ cursor: 'move' }} id="draggable-dialog-title">
          <div className="modal-title">사유 입력</div>
          <Button className="close-btn" onClick={props.close}>×</Button>
        </header>

        <div className="clearfix"></div>

        <section className="modal-body">
          <table className="input-table">
            <colgroup>
              <col style={{width: '30%'}} />
              <col style={{width: '70%'}} />
            </colgroup>

            <tbody>
              <tr>
                <th scope="row"><label htmlFor="srcDriveSeq">사유입력</label></th>
                <td>
                  <InputEx
                    name="reason"
                    formik={formik}
                    fullWidth={true}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </section>

        <footer className="modal-footer">
          <div className="modal-footer-center">
            <Button
              color="inherit"
              variant="outlined"
              className="Btn"
              onClick={() => {
                props.close();
              }}
            >창닫기</Button>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              className="Btn ml10"
            >저장</Button>
          </div>
        </footer>
      </form>
    </section>
  )
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default ReasonModal;