/*****************************************************************************************
 * 설명 : 관리자 > 시스템관리 > 논리 드라이브 관리
*****************************************************************************************/
import Dialog from '@mui/material/Dialog';
import Modal from '@mui/material/Modal';
import { AgGridReact } from 'ag-grid-react';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from "yup";

import AlertDialog from 'components/alertDiolog';
import BtnAgGridSave from 'components/btnAgGridSave';
import { BtnRefresh, BtnSearch, CurrencyFormat } from 'components/common';
import { ButtonEx } from 'components/inputEx';
import alertMsg from 'components/message';
import customTooltipAgGrid from 'components/tooltipAgGrid';
// import ChipEx from 'components/chipEx';

import { MESSAGE_DELAY } from 'config/config';
import useGridConfig from 'hooks/useGridConfig';

import Restful from 'service/restful';
import { deleteLogicalDriveApi, getLogicalDriveApi } from 'service/system';
import { PaperComponent, comma, formatBytes } from 'service/utils';

import FileModal from 'pages/admin/system/logicStorage/fileModal';
import RogicStorageDriveModal from 'pages/admin/system/logicStorage/logicStorageDriveModal';
import RogicStorageModal from 'pages/admin/system/logicStorage/logicStorageModal';
import Layout from 'pages/layout/layout';


/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const RogicStorage = ( props ) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [getApi] = Restful();

  const [gridApi, setGridApi] = useState({});

  const [info, setInfo] = useState({
    totalSize: 0,
    totalUseSize: 0,
    totalRemainderSize: 0,
    totalFileCount: 0
  });

  const [list, setList] = useState([]);
  const [openModal, setOpenModal] = useState({open: false, modal: 'add', data: []});

  const formik = useFormik({
    initialValues: {
    },
    validationSchema: Yup.object().shape({
    }),
    onSubmit: (values) => {
      getList();
    }
  });

  /***************************************************************************************
   * 설명 : 논리 드라이브 정보
  ***************************************************************************************/
  const DriveInfo = (data) => {
    let className = '';
    if( isNaN(data.percent) || data.percent < 50) className = 'bg-green';
    else if( data.percent >= 50 && data.percent < 80) className = 'bg-blue';
    else className = 'bg-red';

    return (
      <div className="progress" style={{height: '20px', marginTop: '7px'}}>
        <div className={"progress-bar " + className} style={{ width: data.percent + '%', height: '20px' }}></div>
      </div>
    );
  }

  // table column
  const [columnDefs, setColumnDefs] = useState([
    { headerName: '', field: 'seq', width: 40, cellClass: 'center', flex: 0,
      headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
    { headerName:'드라이브명', field: 'driveName', width: 200, cellClass: 'cp' },
    { headerName:'실제경로', field: 'path', width: 250, cellClass: 'cp' },
    { headerName:'경로표시명', field: 'pathView', width: 250, cellClass: 'cp' },
    { headerName:'사용량', field: 'currSize', width: 100, cellClass: 'cp text-right',
      valueGetter: (params) => {
        return formatBytes(params.data.currSize);
      }
    },
    { headerName:'사용율(%)', field: 'pathView', width: 200, cellClass: 'cp',
      cellRendererFramework: function(params) {
        return <DriveInfo
          title={params.data.pathView}
          path={params.data.path}
          max={params.data.maxSize}
          remainder={params.data.currSize}
          percent={Math.round(params.data.currSize / params.data.maxSize)}
        />;
      }
    },
    { headerName:'최대용량(GB)', field: 'maxSize', width: 100, cellClass: 'cp text-right',
      valueGetter: (params) => {
        let tmp = String(params.data.maxSize || 0)?.replace(/,/gi, '');
        return comma(Math.round(parseInt(tmp) / (1000 * 1000 * 1000)));
      }
    },
    { headerName:'파일수', field: 'fileCount', width: 100, cellClass: 'cp text-right', valueFormatter: CurrencyFormat },
    { headerName:'등록자', field: 'creatorName', width: 100, cellClass: 'cp center' },
    /*
    { headerName:'사용여부', field: 'isUse', width: 80, cellClass: 'cp center',
      cellRendererFramework: function(params) {
        if( params.data.isUse === 1 )
          return <ChipEx color="success" variant="outlined" size="small" label="사용" />;
        else
          return <ChipEx color="default" variant="outlined" size="small" label="사용안함" />;
      }
    },
    */
    { headerName:'등록일시', field: 'createDate', width: 160, cellClass: 'cp center' },
  ]);


  const [gridConfig, setGridUpdate] = useGridConfig(1, setColumnDefs);

  /***************************************************************************************
   * 설명 : 논리드라이브 리스트 가져오기
  ***************************************************************************************/
  const getList = () => {
    getLogicalDriveApi(getApi, formik.values, (response) => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        setList(response.data.data);
        setInfo(response.data.data1);
      } else {
        setList([]);

      }
    });
  }

  /***************************************************************************************
   * 설명 : 논리드라이브 삭제
  ***************************************************************************************/
  const deleteLogicalDrive = () => {
    let nodes = gridApi.getSelectedRows();

    if( nodes.length < 1 ) {
      alertMsg("삭제할 논리드라이브를 선택하시기 바랍니다.", "W", MESSAGE_DELAY);
      return;
    }

    if( ! window.confirm("선택한 논리드라이브를 삭제하시겠습니까?") ) return;

    let params = nodes.map((item) => {
      return {seq: item.seq}
    });

    deleteLogicalDriveApi(getApi, params, (response) => {
      if( response !== undefined && response.data.result ) {
        alertMsg(response.data.message, "S", MESSAGE_DELAY);

        getList();

      } else {
        alertMsg(response.data.message || '서버와의 통신에 실패하였습니다.', "E", MESSAGE_DELAY);
      }
    });
  }

  /***************************************************************************************
   * 설명 : 사이트 변경 시 검색
  ***************************************************************************************/
  useEffect(() => {
    if( formik.values.siteSeq !== '' && formik.values.siteSeq !== null )
      getList();

    // eslint-disable-next-line
  }, [formik.values.siteSeq]);

  /***************************************************************************************
   * 설명 : 데이터 처리
  ***************************************************************************************/
  useEffect(() => {
    getList();

    // eslint-disable-next-line
  }, []);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <Layout>
      <section className="admin-cms-contents">
        <header className="clearfix pb5 mt10">
          <div className='fl f14 bold lh28px'>
            논리 드라이브 정보
          </div>
        </header>
        <table className="input-table">
          <colgroup>
            <col style={{width: '8%'}} />
            <col style={{width: '17%'}} />
            <col style={{width: '8%'}} />
            <col style={{width: '17%'}} />
            <col style={{width: '8%'}} />
            <col style={{width: '17%'}} />
            <col style={{width: '8%'}} />
            <col style={{width: '17%'}} />
          </colgroup>

          <tbody>
            <tr className='layout'>
              <th scope="row"><label htmlFor="seq">전체용량</label></th>
              <td>{formatBytes(info.totalSize * (1024 * 1024))}</td>
              <th scope="row"><label htmlFor="pageName">사용용량</label></th>
              <td>{formatBytes(info.totalUseSize * (1024 * 1024))}</td>
              <th scope="row"><label htmlFor="pageMenuName">잔여용량</label></th>
              <td>{formatBytes(info.totalRemainderSize * (1024 * 1024))}</td>
              <th scope="row"><label htmlFor="pageMenuName">파일수</label></th>
              <td>{comma(info.totalFileCount)}</td>
            </tr>
          </tbody>
        </table>
        <section>
          <section>
            <form onSubmit={formik.handleSubmit}>
              <header className="admin-cms-search-header mb5">
                <div className="left">
                  <div className='fl'>
                    <BtnRefresh click={() => {
                      formik.setFieldValue('siteSeq', null);
                      formik.handleSubmit();
                    }}></BtnRefresh>
                    <BtnSearch click={() => getList()}></BtnSearch>
                    <BtnAgGridSave click={setGridUpdate}></BtnAgGridSave>
                  </div>
                </div>
                <div className="text-right">
                  <ButtonEx
                    title="삭제"
                    auth="isDelete"
                    color="error"
                    variant="outlined"
                    className="ml5"
                    onClick={() => {
                      // deleteModule();
                      setOpenModal({open: true, modal: 'infoDialog', data: {}});
                    }}
                  />

                  <ButtonEx
                    title="추가"
                    auth="isWrite"
                    color="secondary"
                    className="ml5"
                    variant="outlined"
                    onClick={() => {
                      setOpenModal({open: true, modal: 'infoAdd', data: {siteSeq: formik.values.siteSeq}})
                    }}
                  />
                </div>
              </header>
            </form>
            <div className="ag-theme-balham grid pad-height240" style={{height:'calc(100vh - 200px)'}}>
              <AgGridReact
                defaultColDef={{
                  sortable: true,
                  resizable: true,
                  filter: false,
                  lockVisible: true,
                  tooltipComponent: customTooltipAgGrid,
                }}
                tooltipShowDelay={0}
                tooltipHideDelay={2000}
                rowSelection = {'multiple'}
                columnDefs={columnDefs}
                rowData={list}
                onGridReady={(event) => {
                  gridConfig(event.columnApi);
                  setGridApi(event.api);
                }}
                onRowDoubleClicked={(event) => {
                  //if( currentMenu?.authModify === '1' || currentMenu?.authModify === 1 )
                    setOpenModal({open: true, modal: 'infoAdd', data: event.data});
                }}
                rowDragManaged={true}
                rowDragMultiRow={true}
                animateRows={true}
                overlayNoRowsTemplate = "검색된 내용이 없습니다."
              />
            </div>
          </section>

          {/*
          <section>
            <section className='fl' style={{width: '250px'}}>
              <header className="admin-cms-search-header mb5">
                <div className="left">
                  <div className='fl'>
                    <div className='f14 bold lh28px'>drive1</div>
                  </div>
                </div>
                <div className="text-right">
                  <ButtonEx
                    title="삭제"
                    auth="isDelete"
                    color="error"
                    variant="outlined"
                    className="ml5"
                    onClick={() => {
                      // deleteModule();
                      setOpenModal({open: true, modal: 'driveDialog', data: {}});
                    }}
                  />
                  <ButtonEx
                    title="추가"
                    auth="isWrite"
                    color="secondary"
                    className="ml5"
                    variant="outlined"
                    onClick={() => {
                      setOpenModal({open: true, modal: 'driveAdd', data: {siteSeq: formik.values.siteSeq}})
                    }}
                  />
                </div>
              </header>
              <section className='organization' style={{border: 'solid 1px #ccc'}}>
                <div className="pt20" style={{height:'calc(50vh - 152px)'}}>
                  <TreeViewEx
                    treeSeq="seq"
                    treeKey="orgCode"
                    treeTitle="orgName"
                    treeData={directoryList}
                    setSelectedNode={setSelectedOrg}
                  ></TreeViewEx>
                </div>
              </section>
            </section>
            <section className='fr' style={{width: 'calc(100% - 260px)'}}>
              <form onSubmit={formik.handleSubmit}>
                <header className="admin-cms-search-header mb5">
                  <div className="left">
                    <div className='fl'>
                      <BtnAgGridSave click={setGridUpdate}></BtnAgGridSave>
                    </div>
                  </div>
                  <div className="text-right">
                    <ButtonEx
                      title="삭제"
                      auth="isDelete"
                      color="error"
                      variant="outlined"
                      className="ml5"
                      onClick={() => {
                        // deleteModule();
                        setOpenModal({open: true, modal: 'fileDialog', data: {}});
                      }}
                    />
                    <ButtonEx
                      title="추가"
                      auth="isWrite"
                      color="secondary"
                      className="ml5"
                      variant="outlined"
                      onClick={() => {
                        setOpenModal({open: true, modal: 'foldAdd', data: {siteSeq: formik.values.siteSeq}})
                      }}
                    />
                  </div>
                </header>
              </form>
              <div className="ag-theme-balham grid" style={{height:'calc(50vh - 150px)'}}>
                <AgGridReact
                  defaultColDef={{
                    sortable: true,
                    resizable: true,
                    filter: false,
                    lockVisible: true,
                    tooltipComponent: customTooltipAgGrid,
                  }}
                  tooltipShowDelay={0}
                  tooltipHideDelay={2000}
                  rowSelection = {'multiple'}
                  columnDefs={columnDefs1}
                  rowData={fileList}
                  onGridReady={(event) => {
                    gridConfig(event.columnApi);
                    setGridApi(event.api);
                  }}
                  onRowDoubleClicked={(event) => {
                    if( currentMenu?.authModify === '1' || currentMenu?.authModify === 1 )
                      setOpenModal({open: true, modal: 'foldAdd', data: event.data});
                  }}
                  rowDragManaged={true}
                  rowDragMultiRow={true}
                  animateRows={true}
                  overlayNoRowsTemplate = "검색된 내용이 없습니다."
                />
              </div>
            </section>
          </section>
          */}
        </section>

      </section>

      { // 드라이브 추가
        openModal.open && openModal.modal === 'infoAdd' &&
        <Dialog
          open={openModal}
          onClose={() => (event, reason) => {
            if(reason && reason === "backdropClick") return;
            setOpenModal({open: false, modal: openModal.modal, data: {}});
          }}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "550px",  // Set your width here
              },
            },
          }}
        >
          <section className="modal">
            <RogicStorageModal
              open={openModal.open}
              close={() => setOpenModal({ open: false, modalName: 'infoAdd', data: openModal.data })}
              data={openModal.data}
              getList={getList}
              indicator={props.indicator}
              history={props.history}
            />
          </section>
        </Dialog>
      }

      { openModal.open && openModal.modal === 'driveAdd' &&
        <Modal
          open={openModal.open}
          onClose={() => setOpenModal({ open: false, modalName: 'driveAdd', data: openModal.data })}
        >
          <RogicStorageDriveModal
            open={openModal.open}
            close={() => setOpenModal({ open: false, modalName: 'driveAdd', data: openModal.data })}
            data={openModal.data}
            getList={getList}
            indicator={props.indicator}
            history={props.history}
          />
        </Modal>
      }

      { openModal.open && openModal.modal === 'foldAdd' &&
        <Modal
          open={openModal.open}
          onClose={() => setOpenModal({ open: false, modalName: 'foldAdd', data: openModal.data })}
        >
          <FileModal
            open={openModal.open}
            close={() => setOpenModal({ open: false, modalName: 'foldAdd', data: openModal.data })}
            data={openModal.data}
            getList={getList}
            indicator={props.indicator}
            history={props.history}
          />
        </Modal>
      }

      { openModal.open && openModal.modal === 'infoDialog' &&
        <Modal
          open={openModal.open}
          onClose={() => setOpenModal({ open: false, modalName: 'infoDialog', data: openModal.data })}
        >
          <AlertDialog
            open={openModal.open}
            close={() => setOpenModal({ open: false, modalName: 'infoDialog', data: openModal.data })}
            title="논리드라이브 삭제"
            message={`선택하신 논리드라이브를 삭제하시겠습니까?\n\n삭제하신 논리드라이브는 복구가 불가능합니다.`}
            confirm="삭제"
            onClick={deleteLogicalDrive}
            getList={getList}
            indicator={props.indicator}
            history={props.history}
          />
        </Modal>
      }

    </Layout>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default RogicStorage;