/*****************************************************************************************
 * 설명 : 사용자관리 - 권한설정
 * URI : /system/user
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import React, {useState, useEffect} from 'react';
import { useFormik } from 'formik';
import Button from '@mui/material/Button';
import * as Yup from "yup";

import Restful from "service/restful";
import { setAuthGroupUserApi, getAuthGroupListApi } from 'service/auth';
import { getSelectData } from 'service/utils';
import { MESSAGE_DELAY } from 'config/config';

import alertMsg from 'components/message';
import { SelectEx } from 'components/inputEx';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const AuthModal = (props) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [getApi] = Restful();
  const [authList, setAuthList] = useState([]);

  const formik = useFormik({
    initialValues: {
      seq: '',
      data: []
    },
    validationSchema: Yup.object().shape({
      seq: Yup.string().required('필수'),
    }),
    onSubmit: (values) => {
      setAuthGroupUserApi(getApi, values, (response => {
        if( response !== undefined && response.data.result ) {
          alertMsg(response.data.message, "S", MESSAGE_DELAY);

          if( props.getList ) props.getList();

          props.close();

        } else {
          alertMsg(response.data.message || '서버와의 통신에 실패하였습니다.', "E", MESSAGE_DELAY);
        }
      }));
    }
  });

  /***************************************************************************************
   * 설명 : 권한그룹 정보 가져오기
  ***************************************************************************************/
  const getAuthGroupList = () => {
    getAuthGroupListApi(getApi, (response) => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        setAuthList(getSelectData(response.data.data, 'authGroupName', 'seq'));
      } else {
        setAuthList([]);
      }
    });
  }

  /***************************************************************************************
   * 설명 : 오픈시 처리
  ***************************************************************************************/
  useEffect(() => {
    if( props.open ) {
      let nodes = props.gridApi.getSelectedRows();
      let data = nodes.map((item) => item.memNo);
      formik.setFieldValue('data', data);

      getAuthGroupList();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <section className="modal-wrap" style={{width: '100%'}}>
      <form onSubmit={formik.handleSubmit}>
        <header className="modal-header" style={{ cursor: 'move' }} id="draggable-dialog-title">
          <div className="modal-title">권한그룹 설정</div>
          <Button className="close-btn" onClick={props.close}>×</Button>
        </header>

        <div className="clearfix"></div>

        <section className="modal-body">
          <table className="input-table">
            <colgroup>
              <col style={{width: '30%'}} />
              <col style={{width: '70%'}} />
            </colgroup>

            <tbody>
              <tr>
                <th scope="row"><label htmlFor="seq">권한그룹 선택</label></th>
                <td>
                  <SelectEx
                    name="seq"
                    formik={formik}
                    fullWidth={true}
                    data={[
                      {label: '권한그룹 선택', value: ''}
                    ].concat(authList)}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </section>

        <footer className="modal-footer">
          <div className="modal-footer-center">
            <Button
              color="inherit"
              variant="outlined"
              className="Btn"
              onClick={() => {
                props.close();
              }}
            >창닫기</Button>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              className="Btn ml10"
            >권한그룹 설정</Button>
          </div>
        </footer>
      </form>
    </section>
  )
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default AuthModal;