/*****************************************************************************************
 * 설명 : Header
*****************************************************************************************/
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux'
import Button from '@mui/material/Button';

import noImg from 'assets/images/no_image.png';

import { setUserInfo } from 'reducers/userReducer';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const Header = (props) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const dispatch = useDispatch();
  const user = useSelector(state => state?.userInfo?.user);
  const currentMenu = useSelector(state => state?.menuList?.current);
  const history = useNavigate();

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <header className="header">
      <section className="navbar-header">
        <section className="left-area">
          <Button className="menu-btn" onClick={() => props.toggleMenuReduce()}>
            <span className="hamburger-icon">
              <span></span>
              <span></span>
              <span></span>
            </span>
          </Button>

          <div className="header-path">
            <ul className="page-title">
              <li>{currentMenu?.parent?.menuName}</li>
              <li><i className="icon bx bx-chevron-right"></i>{currentMenu?.menuName}</li>
            </ul>
          </div>
        </section>

        {/*
         parseInt(currentMenu?.isAdminSearch) === 1 &&
          <SearchBox></SearchBox>
        */}

        <section className="right-area">

          <div className="util-btn-wrap">
            {/*
            <Tooltip title="사용자 검색페이지">
              <Button className="util-btn" onClick={() => history('/search')}><i className="ri-pages-line"></i></Button>
            </Tooltip>
            {/*
            <Button className="util-btn"><i className="bx bx-category-alt"></i></Button>
            <Button className="util-btn"><i className="ri-computer-line"></i></Button>
            <Button className="util-btn"><i className="ri-team-line"></i></Button>
            */}
          </div>
          {/*<Button className="util-btn pad-btn"><i className="ri-menu-add-line"></i></Button>*/}

          <Button
            className="user-btn"
            onClick={() => {
              if( ! window.confirm("사용자 로그아웃 하시겠습니까?") ) return;

              window.localStorage.removeItem('token');
              dispatch(setUserInfo({}));
              history('/');
            }}
          >
            <span>
              <img
                src={user?.picture || ''}
                alt="user"
                className="img"
                onError={(event) => {
                  event.target.src = noImg;
                }}
              />

              <span className="txt">
                <span className="position">{user?.dutyName}</span>
                <span className="name">{user?.userName}</span>
              </span>
            </span>
          </Button>
        </section>
      </section>
    </header>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default Header;