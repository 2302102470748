/*****************************************************************************************
 * 설명 : 프로젝트관리 - 파일관리 - 엑셀업로드
 * URI : /project
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import Button from '@mui/material/Button';
import { AgGridReact } from 'ag-grid-react';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import * as XLSX from 'xlsx';

import useGridConfig from 'hooks/useGridConfig';

import { ButtonEx, InputEx } from 'components/inputEx';
import alertMsg from 'components/message';
import customTooltipAgGrid from 'components/tooltipAgGrid';

import { getCommonListApi } from 'service/common';
import Restful from "service/restful";
import { comma, isNotNumber } from 'service/utils';

import { MESSAGE_DELAY } from 'config/config';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const ExcelModal = (props) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [getApi] = Restful();

  const [list, setList] = useState([]);
  const [isCheck, setIsCheck] = useState(false);
  const [gridApi, setGridApi] = useState({});

  const [exceptWord, setExceptWord] = useState([]);

  /***************************************************************************************
   * 설명 : 업체 검색
  ***************************************************************************************/
  const formik = useFormik({
    initialValues: {
      projectNumber: props.data?.projectNumber
    },
    onSubmit: (values) => {
      // 에러일 경우 에러 메시지 표시
      if( isCheck === false ) {
        alertMsg("엑셀 파일의 형식을 확인해 주시기 바랍니다.", "W", MESSAGE_DELAY);
        return;
      }

      let tmpList = [...list];

      let tmp = props.list.map((item, index) => {

        for(let i = 0; i < tmpList.length; i < 1 ) {
          if( tmpList[i].seq === item.seq ) {
            let tmp2 = {
              ...item
            }
            tmp2.tag = tmpList[i].tag;
            tmp2.relationProject = tmpList[i].relationProject;
            tmp2.memo = tmpList[i].memo;
            tmp2.isAuth = tmpList[i].isAuth;
            tmp2.isDownload = tmpList[i].isDownload;

            tmpList.splice(i, 1);

            return tmp2;
          }
        }

        return item;


        /*
        let tmp1 = list.filter((subItem) => parseInt(subItem.seq) === parseInt(item.seq));
        if( tmp1.length > 0 ) {
          let tmp2 = {
            ...item
          }
          tmp2.tag = tmp1[0].tag;
          tmp2.relationProject = tmp1[0].relationProject;
          tmp2.memo = tmp1[0].memo;
          tmp2.isAuth = tmp1[0].isAuth;
          tmp2.isDownload = tmp1[0].isDownload;

          return tmp2;

        } else {
          return item;
        }
        */
      })

      props.setList(tmp);

      props.close();
    }
  });

  // table column
  const [columnDefs, setColumnDefs] = useState([
    { headerName: '', field: 'seq', width: 40, cellClass: 'center', flex: 0,
      headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true,
      cellClassRules: {'error': (params) => {
        if( ( params.data.seq || '' ) === '' ) return true;
        else return false;
      }}
    },
    { headerName: '번호', field: 'seq', width: 50, cellClass: 'cp text-right',
      cellClassRules: {'error': (params) => {
        if( ( params.data.seq || '' ) === '' ) return true;
        else return false;
      }},
      valueGetter: (params) => {
        return comma(params.node.rowIndex + 1);
      }
    },
    { headerName: '파일명', field: 'fileName', width: 250, cellClass: "cp",
      cellClassRules: {'error': (params) => {
        if( ( params.data.seq || '' ) === '' ) return true;
        else return false;
      }}
    },
    { headerName: '태그', field: 'tag', width: 300, cellClass: "cp",
      cellClassRules: {'error': (params) => {
        if( ( params.data.seq || '' ) === '' ) return true;
        else return false;
      }}
    },
    { headerName: '연관프로젝트', field: 'relationProject', width: 200, cellClass: "cp",
      cellClassRules: {'error': (params) => {
        if( ( params.data.seq || '' ) === '' ) return true;
        else return false;
      }}
    },
    { headerName: '비고', field: 'memo', width: 200, cellClass: "cp",
      cellClassRules: {'error': (params) => {
        if( ( params.data.seq || '' ) === '' ) return true;
        else return false;
      }}
    },
    { headerName:'기본열람권한', field: 'isAuth', width: 100, cellClass: 'cp center',
      cellClassRules: {'error': (params) => {
        if( ( params.data.seq || '' ) === '' ) return true;
        else if( params.data.isAuth === '오류' ) return true;
        else return false;
      }},
      valueGetter: (params) => {
        if( parseInt(params.data.isAuth) === 0) return '전체열람';
        else if( parseInt(params.data.isAuth) === 1) return '목록열람';
        else if( parseInt(params.data.isAuth) === 2) return '열람불가';
        else return params.data.isAuth;
      }
    },
    { headerName:'다운로드허용여부', field: 'isDownload', width: 120, cellClass: 'cp center',
      cellClassRules: {'error': (params) => {
        if( ( params.data.seq || '' ) === '' ) return true;
        else if( params.data.isDownload === '오류' ) return true;
        else return false;
      }},
      valueGetter: (params) => {
        if( parseInt(params.data.isDownload) === 0) return '다운로드 불가';
        else if( parseInt(params.data.isDownload) === 1) return '다운로드 허용';
        else return params.data.isDownload;
      }
    },
  ]);

  const [gridConfig] = useGridConfig(99, setColumnDefs);

  /*****************************************************************************************
   * 설명 : 태그에서 제외할 단어 리스트 가져오기
  *****************************************************************************************/
  const getCommonList = () => {
    let params = {
      groupCode: 'EXCEPT'
    }

    getCommonListApi(getApi, params, (response) => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        setExceptWord(response.data.data);
      } else {
        setExceptWord([]);
      }
    });
  }

  /*****************************************************************************************
   * 설명 : 엑셀 데이터 변경
  *****************************************************************************************/
  const excelToJson = async ($event) => {
    const target = $event.target;
    const reader = new FileReader();

    return await new Promise((resolve, reject) => {
      reader.onload = async (e) => {
        const workbook = XLSX.read(e.target.result, {type: 'array'});
        resolve(XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], {header: 1}));
      };
      //reader.readAsBinaryString(target.files[0]) // arrayBuffer 로 안될 경우 사용할 것
      if( target.files.length > 0 ) {
        reader.readAsArrayBuffer(target.files[0]);
      }
    }).then(response => response)
  };

  /***************************************************************************************
   * 설명 : 엑셀업로드 제조번호 추가
  ***************************************************************************************/
  const excelAdd = async (event) => {
    setIsCheck(true);

    const jsonData = await excelToJson(event);
    let data = [];
    let firstIndex = 2;
    let column = ['키값(수정금지)', '파일명', '용량', '태그', '연관프로젝트', '비고', '기본열람권한', '다운로드허용'];
    let columnIndex = [4, 5, 6, 7, 8, 9, 10];

    // 열 판단
    jsonData.forEach((item, index) => {
      column.forEach((key, keyIndex) => {
        item.forEach((subItem, subIndex) => {
          if( String(subItem || '').indexOf(key) > -1 ) columnIndex[keyIndex] = subIndex;
        })
      })
    });

    // 데이터 첫줄 판단
    for(let i = 0; i < jsonData.length; i++) {
      if(jsonData[i][0] === '키값(수정금지)' ) {
        firstIndex = i;
        break;
      }
    }

    jsonData.forEach((item, index) => {
      if( item.length > 0 && index > firstIndex ) {
        let auth = item[columnIndex[6]];
        let isAuth = auth === '전체열람' || parseInt(auth) === 0 ? 0
                  : auth === '목록열람' || parseInt(auth) === 1 ? 1
                  : auth === '열람열람' || parseInt(auth) === 2 ? 2
                  : (auth || '') === '' ? '' : '오류';

        let download = item[columnIndex[7]];
        let isDownload = download === '다운로드불가' || parseInt(download) === 0 ? 0
                        : download === '다운로드허용' || parseInt(download) === 1 ? 1
                        : (download || '') === '' ? '' : '오류';

        if( isAuth === '오류' || isDownload === '오류' || isNotNumber(item[columnIndex[0]]) ) {
          setIsCheck(false);
        }

        // 태그 특수문자 제외
        // eslint-disable-next-line
        let tag = String(item[columnIndex[3]]).replaceAll(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gim, '');
        tag = tag.split(/\s/);

        // 숫자인 경우 년도만
        let tmp1 = tag.filter((item) => {
          if( ! isNotNumber(item) ) {
            let tmp = item.replaceAll(/^\d{4}$/gi, '');
            if(tmp !== '') return false;
            else return true;
          } else {
            return true;
          }
        });

        // 제외할 단어 제외
        let tmp3 = [];
        tmp1.forEach((item) => {
          let tmp = exceptWord.filter((subItem) => subItem.commonName === item );
          if( tmp.length < 1 ) {
            tmp3.push(item);
          }
        });

        let tmp2 = tmp3.join(' ');
        let relationProject = item[columnIndex[4]];
        if((relationProject || '') !== '') {
          relationProject = relationProject.replace(/\s/gim, '');
        }

        data.push({
          seq: item[columnIndex[0]],
          projectNumber: props.data?.projectNumber,
          rowIndex: '',
          process: 0,
          fileName: item[columnIndex[1]],
          tag: tmp2,
          relationProject: relationProject,
          memo: item[columnIndex[5]],
          isAuth: String(isAuth),
          isDownload: String(isDownload),
        });
      }
    });

    setList(data);

    gridApi.applyTransaction({ add: data });
  }

  /***************************************************************************************
   * 설명 : 이전 오류로 바로가기
  ***************************************************************************************/
  const prevError = () => {
    let index = 0;
    let findIndex = -1;

    gridApi.forEachNode((item) => {
      if( item.selected ) index = item.rowIndex;
    });

    for(let i = index - 1; i >= 0 ; i-- ) {
      if( list[i].isAuth === '오류' || list[i].isDownload === '오류' || list[i].seq === '' ) {
        findIndex = i;
        break;
      }
    }

    // 이전 찾기에 데이터가 없을 경우 다시 뒤에서 부터 검색
    if( findIndex === -1 ) {
      for(let i = list.length - 1; i > index ; i-- ) {
        if( list[i].isAuth === '오류' || list[i].isDownload === '오류' || list[i].seq === '' ) {
          findIndex = i;
          break;
        }
      }
    }

    if( findIndex > -1 ) {
      gridApi.forEachNode((item) => {
        if(item.rowIndex === findIndex ) {
          item.setSelected(true);
          gridApi.ensureIndexVisible(findIndex);
        }
      });
    } else {
      alertMsg("찾는 오류가 없습니다.", "W", MESSAGE_DELAY);
    }

  }

  /***************************************************************************************
   * 설명 : 다음 오류로 바로가기
  ***************************************************************************************/
  const nextError = () => {
    let index = 0;
    let findIndex = -1;

    gridApi.forEachNode((item) => {
      if( item.selected ) index = item.rowIndex;
    });

    for(let i = index + 1; i < list.length ; i++ ) {
      if( list[i].isAuth === '오류' || list[i].isDownload === '오류' || list[i].seq === '' ) {
        findIndex = i;
        break;
      }
    }

    // 다음 찾기에 데이터가 없을 경우 다시 앞에서 부터 검색
    if( findIndex === -1 ) {
      for(let i = 0; i < index ; i++ ) {
        if( list[i].isAuth === '오류' || list[i].isDownload === '오류' || list[i].seq === '' ) {
          findIndex = i;
          break;
        }
      }
    }

    if( findIndex > -1 ) {
      gridApi.forEachNode((item) => {
        if(item.rowIndex === findIndex ) {
          item.setSelected(true);
          gridApi.ensureIndexVisible(findIndex);
        }
      });
    } else {
      alertMsg("찾는 오류가 없습니다.", "W", MESSAGE_DELAY);
    }
  }

  /***************************************************************************************
   * 설명 : 데이터 로딩 처리
  ***************************************************************************************/
  useEffect(() => {
    if( props.open ) {
      getCommonList();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <form onSubmit={formik.handleSubmit}>
      <section className="modal-wrap" style={{width: '100%'}}>
        <header className="modal-header" style={{ cursor: 'move' }} id="draggable-dialog-title">
          <div className="modal-title">엑셀업로드</div>
          <Button className="close-btn" onClick={props.close}>×</Button>
        </header>

        <section className="modal-body">
          <form onSubmit={formik.handleSubmit}>
            <div className="mb10">
              <table className="input-table">
                <colgroup>
                  <col style={{width: '20%'}} />
                  <col style={{width: '80%'}} />
                </colgroup>

                <tbody>
                  <tr>
                    <th scope="row"><label htmlFor="file">파일추가</label></th>
                    <td>
                      <InputEx
                        type="file"
                        name="file"
                        formik={formik}
                        focus={true}
                        fullWidth={true}
                        InputLabelProps={{ shrink: true }}
                        onChange={(event) => excelAdd(event)}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </form>

          <header className="mb10 fr">
            <Button
              variant='outlined'
              color="primary"
              onClick={(event) => prevError()}
            >△ 이전 오류찾기</Button>

            <Button
              variant='outlined'
              color="primary"
              className="ml10"
              onClick={(event) => nextError()}
            >▽ 다음 오류찾기</Button>
          </header>

          <div className="clearfix"></div>

          <div className="ag-theme-balham" style={{height: '400px'}}>
            <AgGridReact
              defaultColDef={{
                sortable: true,
                resizable: true,
                filter: false,
                lockVisible: true,
                tooltipComponent: customTooltipAgGrid,
              }}
              tooltipShowDelay={0}
              tooltipHideDelay={2000}
              columnDefs={columnDefs}
              rowData={list}
              rowSelection={'single'}
              onGridReady={(event) => {
                gridConfig(event.columnApi);
                setGridApi(event.api);
              }}
              onRowClicked={(event) => {
              }}
              onRowDoubleClicked={(event) => {
              }}
              overlayNoRowsTemplate = "검색된 내용이 없습니다."
            />
          </div>
        </section>

        <footer className="modal-footer">
          <div className="modal-footer-center">
            <Button
              color="inherit"
              variant="outlined"
              className="Btn"
              onClick={() => {
                props.close();
              }}
            >창닫기</Button>

            <ButtonEx
              type="submit"
              auth="isModify"
              title="엑셀 업로드 처리"
              variant="contained"
              color="primary"
              className="Btn ml10"
            />
          </div>
        </footer>
      </section>
    </form>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default ExcelModal;