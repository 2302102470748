/*****************************************************************************************
 * 설명 : 페이지 서브 레이아웃
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import React, { useContext, useState } from 'react';

import GlobalMenu from 'config/menu';

import Header from "pages/layout/header";
import Snb from "pages/layout/snb";

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const Layout = (props) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  // 축소 메뉴 표시
  const {menuReduce, setMenuReduce} = useContext(GlobalMenu);
  const [padExpand, setPadExpand] = useState('');

  const toggleMenuReduce = () => {
    if (menuReduce === '') setMenuReduce('reduce');
    else setMenuReduce('');
    setPadExpand('padExpand');
  }

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <section className={"layout-wrapper " + menuReduce + ' ' + padExpand}>
      <Header toggleMenuReduce={toggleMenuReduce} setPadExpand={setPadExpand}></Header>

      <Snb toggleMenuReduce={toggleMenuReduce} setPadExpand={setPadExpand}></Snb>

      <section className="main-content" onClick={() => setPadExpand('')}>
        {props.children}
      </section>

    </section>
  )
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default Layout;