/*****************************************************************************************
 * 설명 : 관리자 > 로그조회 > 그룹웨어 동기화 로그
*****************************************************************************************/
import { AgGridReact } from 'ag-grid-react';
import { useFormik } from 'formik';
import moment from 'moment';
import { useEffect, useState } from 'react';
import * as Yup from "yup";

import BtnAgGridSave from 'components/btnAgGridSave';
import ChipEx from 'components/chipEx';
import { BtnRefresh, BtnSearch, CustomPagenation } from 'components/common';
import { InputEx, ToggleButtonGroupEx } from 'components/inputEx';
import customTooltipAgGrid from 'components/tooltipAgGrid';

import { getGroupwareLogApi } from 'service/logs';
import Restful from 'service/restful';
import { comma } from 'service/utils';

import useGridConfig from 'hooks/useGridConfig';

import Layout from 'pages/layout/layout';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const GroupWareLog = ( props ) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [getApi] = Restful();

  const [list, setList] = useState([]);

  const formik = useFormik({
    initialValues: {
      startDate: moment().add(-1, 'year').format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      isSuccess: 0,
      pageNo: 1,
      pageRow: 100
    },
    validationSchema: Yup.object().shape({
    }),
    onSubmit: (values) => {
      getList();
    }
  });

  // table column
  const [columnDefs, setColumnDefs] = useState([
    { headerName: '', field: 'seq', width: 40, cellClass: 'center', flex: 0,
      headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
    { headerName: '번호', field: 'rowIndex', width: 55, cellClass: 'cp text-right',
      valueGetter: (params) => {
        return comma(((params.data.pageNo - 1) * params.data.pageRow) + params.node.rowIndex + 1);
      }
    },
    { headerName:'상태', field: 'isSuccess', width: 70, cellClass: 'cp center',
      cellRendererFramework: function(params) {
        if( parseInt(params.data.isSuccess) === 1 )
          return <ChipEx color="success" variant="outlined" size="small" label="성공" />;
        else
          return <ChipEx color="error" variant="outlined" size="small" label="실패" />;
      }
    },
    { headerName:'일시', field: 'syncDate', width: 170, cellClass: 'cp center' },
    { headerName:'작업자', field: 'creatorName', width: 100, cellClass: 'cp center' },
    { headerName:'실패 내역', field: 'error', width: 900, cellClass: 'cp' },
  ]);

  const [gridConfig, setGridUpdate] = useGridConfig(1, setColumnDefs);

  /***************************************************************************************
   * 설명 : 그룹웨어 동기화 내역 가져오기
  ***************************************************************************************/
  const getList = () => {
    let params = {...formik.values}

    getGroupwareLogApi(getApi, params, (response) => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        setList([
          ...response.data.data.map((item) => {
            return {
              ...item,
              pageNo: formik.values.pageNo,
              pageRow: formik.values.pageRow,
              searchText: formik.values.searchText
            }
          })
        ]);

        let totalCount = (response.data.data1 || 0);
        let totalPage = Math.floor(totalCount / formik.values.pageRow);
        if(totalCount % formik.values.pageRow > 0 ) totalPage = totalPage + 1;
        formik.setFieldValue('totalPage', totalPage);
        formik.setFieldValue('totalCount', totalCount);

      } else {
        setList([]);
      }
    });
  }

  /***************************************************************************************
     * 설명 : 전체, 성공, 실패 변경시 검색 처리
    ***************************************************************************************/
  useEffect(() => {
    getList();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.isSuccess, formik.values.pageNo])

  /***************************************************************************************
   * 설명 : 데이터 처리
  ***************************************************************************************/
  useEffect(() => {
    getList();

    // eslint-disable-next-line
  }, []);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <Layout>
      <section className="admin-cms-contents log-page">
        <form onSubmit={formik.handleSubmit}>
          <header className="admin-cms-search-header">
            <div className="left">
              <div className='fl'>
                <BtnRefresh click={() => {
                  formik.setFieldValue('siteSeq', null);
                  formik.handleSubmit();
                }}></BtnRefresh>
                <BtnSearch click={() => getList()}></BtnSearch>
                <BtnAgGridSave click={setGridUpdate}></BtnAgGridSave>
              </div>
              <div className='fl'>
                <InputEx
                  type="date"
                  name="startDate"
                  formik={formik}
                  fullWidth={false}
                  label="검색시작일"
                  style={{width: '140px'}}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />

                <span className="ml5 mr5 lh28px">~</span>

                <InputEx
                  type="date"
                  name="endDate"
                  formik={formik}
                  fullWidth={false}
                  label="검색종료일"
                  style={{width: '140px'}}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className='fl ml5'>
                <ToggleButtonGroupEx
                  name="isSuccess"
                  exclusive={true}
                  formik={formik}
                  className="search-toggle-btn"
                  data={[
                    {label: '전체', value: 0},
                    {label: '성공', value: 1},
                    {label: '실패', value: 2},
                  ]}
                />
              </div>

            </div>
            <div className="text-right">
            </div>
          </header>
        </form>

        <section className="admin-cms-body">
          <section className="ag-theme-balham grid pad-height90-percent" style={{height:'calc(100% - 60px)'}}>
            <AgGridReact
              defaultColDef={{
                sortable: true,
                resizable: true,
                filter: false,
                lockVisible: true,
                tooltipComponent: customTooltipAgGrid,
              }}
              tooltipShowDelay={0}
              tooltipHideDelay={2000}
              rowSelection = {'multiple'}
              columnDefs={columnDefs}
              rowData={list}
              onGridReady={(event) => {
                gridConfig(event.columnApi);
              }}
              rowDragManaged={true}
              rowDragMultiRow={true}
              animateRows={true}
              overlayNoRowsTemplate = "검색된 내용이 없습니다."
            />
          </section>
          <section className="pagination mt10">
            <div className="fl">
              <CustomPagenation
                currPage={{pageNo: formik.values.pageNo, pageRow: formik.values.pageRow}}
                totalPage={formik.values.totalPage}
                change={(event, pageNo) => {
                  formik.setFieldValue("pageNo", pageNo);
                }}
              ></CustomPagenation>
            </div>
            <div className="fr f12">
              검색 :<span className="ml10">{comma(formik.values.totalCount)}</span>
            </div>
          </section>
        </section>


      </section>
    </Layout>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default GroupWareLog;