/*****************************************************************************************
 * 설명 : 관리자 > 시스템관리 > 비용산출양식관리 > 기타 추가/수정
*****************************************************************************************/
import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import Dialog from '@mui/material/Dialog';
import * as Yup from "yup";

import { PaperComponentSub } from 'service/utils';

import AlertDialog from 'components/alertDiolog';
import { InputEx } from 'components/inputEx';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
export const CostExtraModal = ( props ) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [title, setTitle] = useState('추가');

  const [openModal, setOpenModal] = useState({open: false, modal: 'add', data: {}});

  const formik = useFormik({
    initialValues: {
      title: '',
      baseAmt: 0
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().max(45, "45자리").required('필수'),
      baseAmt: Yup.number().typeError('숫자')
    }),
    onSubmit: (values) => {
      props.close({
        result: true,
        data: values
      });
    }
  });

  /***************************************************************************************
   * 설명 : 모달 오픈 시 처리
  ***************************************************************************************/
  useEffect(() => {
    if( props.open ) {
      if( props.data.seq !== undefined ) {
        formik.setValues(props.data);

        setTitle('수정');
      } else {
        setTitle('추가');
      }
    }

    // eslint-disable-next-line
  }, [props.open]);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <section className="modal">
      <section className="modal-wrap" style={{ width: '450px' }}>
        <form onSubmit={formik.handleSubmit}>
          <header className="modal-header" style={{ cursor: 'move' }} id="draggable-dialog-title">
            <div className="modal-title">기타비용 {title}</div>
            <Button className="close-btn" onClick={() => props.close({result: false})}>
              <CloseIcon></CloseIcon>
            </Button>
          </header>

          <section className="modal-body">
            <table className="input-table">
              <colgroup>
                <col style={{width: '30%'}} />
                <col style={{width: '70%'}} />
              </colgroup>

              <tbody>
                <tr>
                  <th scope="row"><label htmlFor="title">항목명</label></th>
                  <td>
                    <InputEx
                      name="title"
                      formik={formik}
                      fullWidth={true}
                    />
                  </td>
                </tr>
                <tr>
                  <th scope="row"><label htmlFor="baseAmt">평당가</label></th>
                  <td>
                    <InputEx
                      name="baseAmt"
                      formik={formik}
                      fullWidth={true}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </section>

          <footer className="modal-footer">
            <div className="modal-footer-center">
              <Button
                color="inherit"
                variant="outlined"
                className="Btn"
                onClick={() => {
                  props.close({result: false});
                }}
              >창닫기</Button>

              <Button
                variant="contained"
                color="primary"
                className="Btn ml10"
                onClick={() => setOpenModal({open: true, modal: 'add', data:{}})}
              >저장하기</Button>
            </div>
          </footer>
        </form>
      </section>

      { openModal.open && openModal.modal === 'add' &&
        <Dialog
          open={openModal.open}
          onClose={() => setOpenModal({ open: false, modalName: 'dialog', data: openModal.data })}
          PaperComponent={PaperComponentSub}
          aria-labelledby="draggable-dialog-title-sub"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "450px",  // Set your width here
              },
            },
          }}
        >
          <AlertDialog
            open={openModal.open}
            close={() => setOpenModal({ open: false, modalName: 'dialog', data: openModal.data })}
            title="기타비용 추가"
            message={`입력하신 기타 비용을 추가하시겠습니까?`}
            confirm="추가하기"
            color="primary"
            onClick={() => formik.handleSubmit()}
            indicator={props.indicator}
            history={props.history}
          />
        </Dialog>
      }
    </section>
  )
}

export default CostExtraModal;