/*****************************************************************************************
 * 설명 : 관리자 > 시스템관리 > 비용산출양식관리 > 추가/수정
*****************************************************************************************/
import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import Dialog from '@mui/material/Dialog';
import * as Yup from "yup";
import * as moment from 'moment';

import { MESSAGE_DELAY } from 'config/config';

import Restful from "service/restful";
import { getSelectData, PaperComponentSub } from 'service/utils';
import { getCostPurposeApi, setCostFormApi } from 'service/cost';
import { getCommonListApi } from 'service/common';

import alertMsg from 'components/message';
import AlertDialog from 'components/alertDiolog';
import { ButtonEx, SelectEx, InputEx, ToggleButtonGroupEx } from 'components/inputEx';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
export const CostingModal = ( props ) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [getApi] = Restful();
  const [title, setTitle] = useState('추가');

  const [openModal, setOpenModal] = useState({open: false, modal: 'add', data: {}});
  const [purpose, setPurpose] = useState([]);
  const [order, setOrder] = useState([]);

  const formik = useFormik({
    initialValues: {
      purpose: '',
      orderType: '',
      title: '',
      year: '',
      isUse: 1
    },
    validationSchema: Yup.object().shape({
      purpose: Yup.string().required('필수'),
      orderType: Yup.string().required('필수'),
      title: Yup.string().max(45, "45자리").required('필수').nullable(),
      year: Yup.number().required('필수').nullable(),
    }),
    onSubmit: (values) => {
      setOpenModal({open: true, modal: 'add', data:{}});
    }
  });

  /***************************************************************************************
   * 설명 : 양식 저장하기
  ***************************************************************************************/
  const save = () => {
    setCostFormApi(getApi, formik.values, (response) => {
      if( response !== undefined && response.data.result ) {
        alertMsg(response.data.message, "S", MESSAGE_DELAY);

        if( props.getList ) props.getList();

        props.close(true);

      } else {
        alertMsg(response.data.message, "E", MESSAGE_DELAY);
      }
    });
  }

  /***************************************************************************************
   * 설명 : 용도 가져오기
  ***************************************************************************************/
  const getPurpose = () => {
    getCostPurposeApi(getApi, (response) => {
      if( response !== undefined && response.data.result !== false && response.data.data && response.data.data.length > 0 ) {
        setPurpose(getSelectData([
          ...response.data.data.filter((item) => {
            return item.commonCode.length === 3
          })], 'commonName', 'commonCode')
        );
      } else {
        setPurpose([]);
      }
    });
  }

  /***************************************************************************************
   * 설명 : 발주처 리스트 가져오기
  ***************************************************************************************/
  const getCommonList = () => {
    getCommonListApi(getApi, {groupCode: 'ORDER'}, (response) => {
      if( response !== undefined && response.data.result !== false && response.data.data && response.data.data.length > 0 ) {
        setOrder(getSelectData(response.data.data, 'commonName', 'commonCode'));
      } else {
        setOrder([]);
      }
    })
  }

  /***************************************************************************************
   * 설명 : 모달 오픈 시 처리
  ***************************************************************************************/
  useEffect(() => {
    if( props.open ) {

      getPurpose();

      getCommonList();

      if( props.data.seq !== undefined ) {
        formik.setValues(props.data);

        formik.setFieldValue('year', String(props.data.year))

        setTitle('수정');
      } else {
        setTitle('추가');
      }
    }

    // eslint-disable-next-line
  }, [props.open]);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <section className="modal">
      <section className="modal-wrap" style={{ width: '450px' }}>
        <form onSubmit={formik.handleSubmit}>
          <header className="modal-header" style={{ cursor: 'move' }} id="draggable-dialog-title">
            <div className="modal-title">설계비가산정양식 {title}</div>
            <Button className="close-btn" onClick={() => props.close(false)}>
              <CloseIcon></CloseIcon>
            </Button>
          </header>

          <section className="modal-body">
            <table className="input-table">
              <colgroup>
                <col style={{width: '30%'}} />
                <col style={{width: '70%'}} />
              </colgroup>

              <tbody>
                <tr>
                  <th scope="row"><label htmlFor="purpose">용도</label></th>
                  <td>
                    <SelectEx
                      name="purpose"
                      formik={formik}
                      fullWidth={true}
                      data={[
                        {label: '용도 선택', value: ''}
                      ].concat(purpose)}
                    />
                  </td>
                </tr>
                <tr>
                  <th scope="row"><label htmlFor="orderType">발주처</label></th>
                  <td>
                    <SelectEx
                      name="orderType"
                      formik={formik}
                      fullWidth={true}
                      data={[
                        {label: '발주처 선택', value: ''},
                      ].concat(order)}
                    />
                  </td>
                </tr>
                <tr>
                  <th scope="row"><label htmlFor="title">양식명</label></th>
                  <td>
                    <InputEx
                      name="title"
                      formik={formik}
                      fullWidth={true}
                    />
                  </td>
                </tr>
                <tr>
                  <th scope="row"><label htmlFor="year">기준년도</label></th>
                  <td>
                    <InputEx
                      name="year"
                      formik={formik}
                      fullWidth={true}
                      placeholder={moment().format('YYYY')}
                      inputProps={{
                        maxLength: 4
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <th scope="row"><label htmlFor="isUse">사용여부</label></th>
                  <td>
                    <ToggleButtonGroupEx
                      name="isUse"
                      exclusive={true}
                      formik={formik}
                      data={[
                        {label: '사용', value: 1},
                        {label: '사용안함', value: 0},
                      ]}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </section>

          <footer className="modal-footer">
            <div className="modal-footer-center">
              <Button
                color="inherit"
                variant="outlined"
                className="Btn"
                onClick={() => {
                  props.close(false);
                }}
              >창닫기</Button>

              <ButtonEx
                type="submit"
                auth="isWrite"
                title="저장하기"
                variant="contained"
                color="primary"
                className="Btn ml10"
              />
            </div>
          </footer>
        </form>
      </section>

      { openModal.open && openModal.modal === 'add' &&
        <Dialog
          open={openModal.open}
          onClose={() => setOpenModal({ open: false, modalName: 'dialog', data: openModal.data })}
          PaperComponent={PaperComponentSub}
          aria-labelledby="draggable-dialog-title-sub"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "450px",  // Set your width here
              },
            },
          }}
        >
          <AlertDialog
            open={openModal.open}
            close={() => setOpenModal({ open: false, modalName: 'dialog', data: openModal.data })}
            title={"설계비가산정양식 " + title}
            message={`입력하신 설계비 가산정양식을 ${title}하시겠습니까?`}
            confirm={title + "하기"}
            color="primary"
            onClick={() => {
              save();
            }}
            indicator={props.indicator}
            history={props.history}
          />
        </Dialog>
      }
    </section>
  )
}

export default CostingModal;