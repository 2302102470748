/*****************************************************************************************
 * 설명 : 관리자 > 컨텐츠관리 > 게시판 관리
 * URI : /admin/site
*****************************************************************************************/
import Dialog from '@mui/material/Dialog';
import Modal from '@mui/material/Modal';
import { AgGridReact } from 'ag-grid-react';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from "yup";

import AlertDialog from 'components/alertDiolog';
import BtnAgGridSave from 'components/btnAgGridSave';
import ChipEx from 'components/chipEx';
import { BtnSearch } from 'components/common';
import { ButtonEx } from 'components/inputEx';
import alertMsg from 'components/message';
import customTooltipAgGrid from 'components/tooltipAgGrid';

import { MESSAGE_DELAY } from 'config/config';
import useGridConfig from 'hooks/useGridConfig';

import { deleteBoardInfoApi, getBoardInfoListApi } from 'service/board';
import Restful from 'service/restful';
import { PaperComponent } from 'service/utils';

import BoardModal from 'pages/admin/cms/board/boardManagementModal';
import Layout from 'pages/layout/layout';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const BoardManagement = ( props ) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [getApi] = Restful();

  const [gridApi, setGridApi] = useState({});

  const [count] = '1';

  const [list, setList] = useState([]);

  const [openModal, setOpenModal] = useState({open: false, modal: 'add', data: []});

  const formik = useFormik({
    initialValues: {
      siteSeq: null,
    },
    validationSchema: Yup.object().shape({
    }),
    onSubmit: (values) => {
      getList();
    }
  });

  // ag grid 컬럼 선언
  const [columnDefs, setColumnDefs] = useState([
    { headerName: '', field: 'seq', width: 40, cellClass: 'center', flex: 0,
      headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
    { headerName: '정렬', field: 'sortOrder', width: 53, cellClass: 'cp center', rowDrag: true, filter: false, editable: false },
    {headerName: '게시판아이디', field: 'boardId', width: 150, cellClass: 'cp' },
    {headerName: '게시판명', field: 'boardName', width: 200, cellClass: 'cp' },
    {headerName: '그룹', field: 'groupCode', width: 300, cellClass: 'cp' },
    {headerName: '테이블명', field: 'tableSeq', hide: true },
    {headerName: '테이블명', field: 'tableName', width: 150, cellClass: 'cp' },
    {headerName: '업로드테이블명', field: 'uploadTableSeq', hide: true },
    {headerName: '업로드테이블명', field: 'uploadTableName', width: 150, cellClass: 'cp' },
    {headerName: '업로드허용(MB)', field: 'uploadFileMaxSize', width: 150, cellClass: 'cp center',
      cellRendererFramework: function(params) {
        if( parseInt(params.data.uploadFileMaxSize) === 0 )
          return <ChipEx color="success" variant="outlined" size="small" label="무제한" />;
        else
          return params.data.uploadFileMaxSize;
      }
    },
    {headerName: '공지사항줄수', field: 'noticeRowCount', width: 150, cellClass: 'cp center',
      cellRendererFramework: function(params) {
        if( parseInt(params.data.noticeRowCount) === 0 )
          return <ChipEx color="default" variant="outlined" size="small" label="표시하지 않음" />;
        else
          return params.data.noticeRowCount;
      }
    },
    {headerName: '신규표시일', field: 'newDayCount', width: 150, cellClass: 'cp center' },
  ]);

  const [gridConfig, setGridUpdate] = useGridConfig(1, setColumnDefs);

  /***************************************************************************************
   * 설명 : 게시판 정보 리스트 가져오기
  ***************************************************************************************/
  const getList = () => {
    getBoardInfoListApi(getApi, (response) => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        setList(response.data.data);
      } else {
        setList([]);
      }
    });
  }

  /***************************************************************************************
   * 설명 : 게시판 삭제
  ***************************************************************************************/
  const deleteBoardInfo = () => {
    let nodes = gridApi.getSelectedRows();

    if( nodes.length < 1 ) {
      alertMsg("삭제할 공통코드를 선택하시기 바랍니다.", "W", MESSAGE_DELAY);
      return;
    }

    let params = nodes.map((item) => {
      return {seq: item.seq}
    });

    deleteBoardInfoApi(getApi, params, (response) => {
      if( response !== undefined && response.data.result ) {
        alertMsg(response.data.message, "S", MESSAGE_DELAY);

        getList();

      } else {
        alertMsg(response.data.message || '서버와의 통신에 실패하였습니다.', "E", MESSAGE_DELAY);
      }
    })
  }

  /***************************************************************************************
   * 설명 : 데이터 처리
  ***************************************************************************************/
  useEffect(() => {
    getList();

    // eslint-disable-next-line
  }, []);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <Layout>
      <div className="admin-cms-contents">
        <form onSubmit={formik.handleSubmit}>
          <header className="admin-cms-search-header">
            <div className="left">
              <BtnSearch click={() => getList()}></BtnSearch>
              <BtnAgGridSave click={setGridUpdate}></BtnAgGridSave>
            </div>

            <div className="text-right">
              <ButtonEx
                title="게시판삭제"
                auth="isDelete"
                color="error"
                variant="outlined"
                className="ml5"
                onClick={() => {
                  // deleteBoardInfo();
                  setOpenModal({open: true, modal: 'dialog', data: {}});
                }}
              />

              <ButtonEx
                title="게시판등록"
                auth="isWrite"
                color="secondary"
                className="ml5"
                variant="outlined"
                onClick={() => {
                  setOpenModal({open: true, modal: 'add', data: {}})
                }}
              />
            </div>
          </header>
        </form>

        <section className="admin-cms-body">
          <section className="ag-theme-balham grid" style={{height:'calc(100% - 50px)'}}>
            <AgGridReact
              defaultColDef={{
                sortable: true,
                resizable: true,
                filter: false,
                lockVisible: true,
                tooltipComponent: customTooltipAgGrid,
              }}
              tooltipShowDelay={0}
              tooltipHideDelay={2000}
              rowSelection = {'multiple'}
              columnDefs={columnDefs}
              rowData={list}
              onGridReady={(event) => {
                gridConfig(event.api);
                setGridApi(event.api);
              }}
              onRowDoubleClicked={(event) => {
                //if( currentMenu?.authModify === '1' || currentMenu?.authModify === 1 )
                  setOpenModal({open: true, modal: 'add', data: event.data});
              }}
              rowDragManaged={true}
              rowDragMultiRow={true}
              animateRows={true}
              overlayNoRowsTemplate = "검색된 내용이 없습니다."
            />
          </section>
        </section>
      </div>

      { openModal.open && openModal.modal === 'add' &&
        <Dialog
          open={openModal}
          onClose={() => (event, reason) => {
            if(reason && reason === "backdropClick") return;
            setOpenModal({open: false, modal: openModal.modal, data: {}});
          }}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "1000px",  // Set your width here
              },
            },
          }}
        >
          <section className="modal">
            <BoardModal
              open={openModal.open}
              close={() => setOpenModal({ open: false, modalName: 'add', data: openModal.data })}
              data={openModal.data}
              getList={getList}
              indicator={props.indicator}
              history={props.history}
            />
          </section>
        </Dialog>
      }

      { openModal.open && openModal.modal === 'dialog' &&
        <Modal
          open={openModal.open}
          onClose={() => setOpenModal({ open: false, modalName: 'dialog', data: openModal.data })}
        >
          <AlertDialog
            open={openModal.open}
            close={() => setOpenModal({ open: false, modalName: 'dialog', data: openModal.data })}
            title="게시판 삭제"
            message={`선택하신 ${count}개의 "게시판"를 삭제하시겠습니까?`}
            confirm="삭제"
            onClick={deleteBoardInfo}
            getList={getList}
            indicator={props.indicator}
            history={props.history}
          />
        </Modal>
      }
    </Layout>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default BoardManagement;