/*****************************************************************************************
 * 설명 : 프로젝트관리 - 규모
 * URI : /project
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import { InputAdornment } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from "yup";

//import { SelectSearchEx,  SelectEx, InputEx, TextareaEx, ToggleButtonGroupEx } from 'components/inputEx';
import DaumPost from 'components/daumAddress';
import { ButtonEx, InputEx } from 'components/inputEx';

import { onlyNumber, PaperComponent } from 'service/utils';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const ScaleModal = (props) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [title, setTitle] = useState('추가');
  const [openModal, setOpenModal] = useState({open: false, modal: '', data: {}});

  const formik = useFormik({
    initialValues: {
      seq: '',
      projectNumber: '',
      scaleName: '',
      address: '',
      addressDetail: '',
      parcelAddress: '',
      zipCode: '',
      underLayer: '',
      groundLayer: '',
      numberHouse: '',
      scaleExtra: '',
      grossFloorAreaSquareMeter: '',
      pyeong: '',
    },
    validationSchema: Yup.object().shape({
      scaleName: Yup.string().max(100, "100자리").required('필수').nullable(),
      address: Yup.string().max(100, "100자리").nullable(),
      addressDetail: Yup.string().max(100, "100자리").nullable(),
      parcelAddress: Yup.string().max(100, "100자리").nullable(),
      zipCode: Yup.string().max(5, "5자리").nullable(),
      underLayer: Yup.string().max(11, "11자리").nullable(),
      groundLayer: Yup.string().max(11, "11자리").nullable(),
      numberHouse: Yup.string().max(11, "11자리").nullable(),
      scaleExtra: Yup.string().max(100, "100자리").nullable(),
      grossFloorAreaSquareMeter: Yup.string().max(100, "100자리").nullable(),
      pyeong: Yup.string().max(100, "100자리").nullable(),
    }),
    onSubmit: (values) => {

      let isAdd = props.scaleList?.filter((item) => item.seq === values.seq);
      if( isAdd.length > 0 ) {
        let tmp = [...props.scaleList];
        let index = 0;
        tmp.forEach((item, subIndex) => {
          if( item.seq === formik.values.seq ) index = subIndex;
        })

        tmp[index] = values;
        props.setScaleList(tmp);

      // 추가
      } else {
        let tmp = [
          ...props.scaleList,
          {...values}
        ];

        props.setScaleList(tmp);
      }

      props.close();
    }
  });

  /*******************************************************************************
    설  명 : 주소 바인딩
  *******************************************************************************/
  const handleCloseModal = (data) => {
    formik.setFieldValue('address', data.address);
    formik.setFieldValue('parcelAddress', data.jibunAddress);
    formik.setFieldValue('zipCode', data.zonecode);

    setOpenModal({open: false, modal: 'daum', data: {}});
  };

  /***************************************************************************************
   * 설명 : 데이터 로딩 처리
  ***************************************************************************************/
  useEffect(() => {
    if( props.open ) {
      if( props.data?.seq !== undefined && props.data?.seq !== '' && props.data?.seq !== 0 ) {
        setTitle('수정');

        formik.setValues(props.data);

      } else {
        setTitle('추가');

        let seq = 0;
        props.scaleList.forEach((item) => {
          if( parseInt(item.seq) < 0 && parseInt(item.seq) < seq ) seq = parseInt(item.seq);
        });

        formik.setFieldValue('seq', seq - 1);
      }

      formik.setFieldValue("projectNumber", props.formik.values.projectNumber);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <section className="modal-wrap" style={{width: '100%'}}>
      <form onSubmit={formik.handleSubmit}>
        <header className="modal-header" style={{ cursor: 'move' }} id="draggable-dialog-title">
          <div className="modal-title">규모 {title}</div>
          <Button className="close-btn" onClick={props.close}>×</Button>
        </header>

        <div className="clearfix"></div>

        <section className="modal-body">
          <table className="input-table">
            <colgroup>
              <col style={{width: '20%'}} />
              <col style={{width: '80%'}} />
            </colgroup>

            <tbody>
              <tr>
                <th scope="row"><label htmlFor="scaleName">명칭</label></th>
                <td>
                  <InputEx
                    name="scaleName"
                    formik={formik}
                    fullWidth={true}
                  />
                </td>
              </tr>
              <tr>
                <th scope="row"><label htmlFor="address">주소</label></th>
                <td>
                  <InputEx
                    name="address"
                    formik={formik}
                    fullWidth={true}
                    style={{width: 'calc(100% - 90px)'}}
                  />

                  <ButtonEx
                    title="주소찾기"
                    auth="isModify"
                    color="inherit"
                    variant="outlined"
                    className="fr"
                    onClick={() => setOpenModal({open: true, modal: 'daum', data: {}})}
                  />
                </td>
              </tr>
              <tr>
                <th scope="row"><label htmlFor="addressDetail">상세 주소</label></th>
                <td>
                  <InputEx
                    name="addressDetail"
                    formik={formik}
                    fullWidth={true}
                  />
                </td>
              </tr>
              <tr>
                <th scope="row"><label htmlFor="parcelAddress">지번</label></th>
                <td>
                  <InputEx
                    name="parcelAddress"
                    formik={formik}
                    fullWidth={true}
                  />
                </td>
              </tr>
              <tr>
                <th scope="row"><label htmlFor="underLayer">층수(지하)</label></th>
                <td>
                  <InputEx
                    name="underLayer"
                    formik={formik}
                    fullWidth={true}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">층</InputAdornment>,
                    }}
                    onChange={(event) => {
                      if (event.nativeEvent.data ){
                        event.target.value = onlyNumber(event.target.value);
                      }
                    }}
                    onBlur={(event) => {
                      event.target.value = onlyNumber(event.target.value);
                    }}
                  />
                </td>
              </tr>
              <tr>
                <th scope="row"><label htmlFor="groundLayer">층수(지상)</label></th>
                <td>
                  <InputEx
                    name="groundLayer"
                    formik={formik}
                    fullWidth={true}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">층</InputAdornment>,
                    }}
                    onChange={(event) => {
                      if (event.nativeEvent.data ){
                        event.target.value = onlyNumber(event.target.value);
                      }
                    }}
                    onBlur={(event) => {
                      event.target.value = onlyNumber(event.target.value);
                    }}
                  />
                </td>
              </tr>
              <tr>
                <th scope="row"><label htmlFor="numberHouse">세대수</label></th>
                <td>
                  <InputEx
                    name="numberHouse"
                    formik={formik}
                    fullWidth={true}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">세대</InputAdornment>,
                    }}
                    onChange={(event) => {
                      if (event.nativeEvent.data ){
                        event.target.value = onlyNumber(event.target.value);
                      }
                    }}
                    onBlur={(event) => {
                      event.target.value = onlyNumber(event.target.value);
                    }}
                  />
                </td>
              </tr>
              <tr>
                <th scope="row"><label htmlFor="grossFloorAreaSquareMeter">연면적(㎡)</label></th>
                <td>
                  <InputEx
                    name="grossFloorAreaSquareMeter"
                    formik={formik}
                    fullWidth={true}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">㎡</InputAdornment>,
                    }}
                    onChange={(event) => {
                      if (event.nativeEvent.data ){
                        event.target.value = onlyNumber(event.target.value);
                      }
                    }}
                    onBlur={(event) => {
                      event.target.value = onlyNumber(event.target.value);
                    }}
                  />
                </td>
              </tr>
              <tr>
                <th scope="row"><label htmlFor="pyeong">연면적(평)</label></th>
                <td>
                  <InputEx
                    name="pyeong"
                    formik={formik}
                    fullWidth={true}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">평</InputAdornment>,
                    }}
                    onChange={(event) => {
                      if (event.nativeEvent.data ){
                        event.target.value = onlyNumber(event.target.value);
                      }
                    }}
                    onBlur={(event) => {
                      event.target.value = onlyNumber(event.target.value);
                    }}
                  />
                </td>
              </tr>
              <tr>
                <th scope="row"><label htmlFor="scaleExtra">규모(기타)</label></th>
                <td>
                  <InputEx
                    name="scaleExtra"
                    formik={formik}
                    fullWidth={true}
                  />
                </td>
              </tr>

            </tbody>
          </table>
        </section>

        <footer className="modal-footer">
          <div className="modal-footer-center">
            <Button
              color="inherit"
              variant="outlined"
              className="Btn"
              onClick={() => {
                props.close();
              }}
            >창닫기</Button>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              className="Btn ml10"
            >저장</Button>
          </div>
        </footer>
      </form>

      { // 다음 우편번호 찾기
        openModal.open && openModal.modal === 'daum' &&
        <Dialog
          open={openModal}
          onClose={() => (event, reason) => {
            if (reason && reason === "backdropClick") return;
            handleCloseModal();
          }}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "800px",  // Set your width here
              },
            },
          }}
        >
          <section className="modal">
            <DaumPost
              close={handleCloseModal}
              open={openModal.open}
              data={openModal.data}
              setClose={handleCloseModal}
            />
          </section>
        </Dialog>
      }
    </section>
  )
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default ScaleModal;