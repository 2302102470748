/*****************************************************************************************
 * 설명 : 관리 - 프로잭트정보
 * URI : /project
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { encode as base64_encode } from 'base-64';
import { useFormik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import * as Yup from "yup";

import { IMAGE_FILE, MESSAGE_DELAY, MOVIE_FILE, MOVIE_FILE_STREAM, baseURL } from 'config/config';

import { getProjectImageListApi, setProjectApi } from 'service/project';
import Restful from "service/restful";
import { checkArrayValue, comma, formatBytes, isCheckValue } from 'service/utils';

import AlertDialog from 'components/alertDiolog';
import ChipEx from 'components/chipEx';
import { ButtonEx, InputEx, TextareaEx, ToggleButtonGroupEx } from 'components/inputEx';
import alertMsg from 'components/message';

import NoImage from 'assets/images/prepare.png';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const TabDesignProjectInfo = ( props ) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const movieRef = useRef();
  const [getApi] = Restful();

  const [previewMovie, setPreviewMovie] = useState('');

  const [pathInfo, setPathInfo] = useState({
    totalDirectoryCount: 0,
    totalFileCount: 0,
    totalSize: 0
  });

  const [previewList, setPreviewList] = useState([]);

  const formik = useFormik({
    initialValues: {
      seq: '',
      projectNumber: '',
      projectName: '',
      detailSeq: '',
      memo: '',
      isConfirm: 0,
      isFile: 0,
      relationProject: '',
    },
    validationSchema: Yup.object().shape({
    }),
    onSubmit: (values) => {
      saveProject(values.isConfirm);
    }
  });

  const [openModal, setOpenModal] = useState({open: false, modal: 'sync', data: []});

  /*******************************************************************************
    설  명 : 프로젝트 추가 정보 저장
  *******************************************************************************/
  const saveProject = (isConfirm) => {

    if( (formik.values.designProjectType || '') === '' ) {
      alertMsg("구분은 필수 항목입니다.", "W", MESSAGE_DELAY);
      return;
    }

    let params = {...formik.values};
    isConfirm = isConfirm === null ? 0 : isConfirm;

    params.isConfirm = isConfirm;
    params.isFile = checkArrayValue(params, 'isFile', true);

    setProjectApi(getApi, params, (response => {
      if( response !== undefined && response.data.result ) {
        alertMsg(response.data.message, "S", MESSAGE_DELAY);

        if( formik.values.seq === null || formik.values.seq === '' )
          formik.setFieldValue("detailSeq", response.data.data?.detailSeq);

        formik.setFieldValue("isConfirm", response.data.data?.isConfirm);

        // 프로젝트 리스트 업데이트
        if( props.setList && props.list) {
          let tmp = [...props.list];
          let index = props.selectedIndex || 0;

          if( index > -1 ) {
            tmp[index] = {...formik.values};

            tmp[index].detailSeq = response.data.data?.detailSeq;
            tmp[index].projectName = response.data.data?.projectName;
            tmp[index].isConfirm = isConfirm;
          }

          if( props.setSelected && index > -1) {
            props.setSelected(tmp[index]);
          }

          if( props.setList ) {
            props.setList(tmp);
          }
        }

      } else {
        alertMsg(response.data.message || '서버와의 통신에 실패하였습니다.', "E", MESSAGE_DELAY);
      }
    }));
  }

  /***************************************************************************************
   * 설명 : 프로젝트 선택 시 대표 이미지 가져오기
  ***************************************************************************************/
  const getImageList = () => {
    let params = {
      projectNumber: props.selected?.projectNumber
    }

    getProjectImageListApi(getApi, params, (response) => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        setPreviewList(response.data.data);

        if( response.data.data1 )
          setPathInfo(response.data.data1);

      } else {
        setPreviewList([]);
        setPathInfo({
          totalDirectoryCount: 0,
          totalFileCount: 0,
          totalSize: 0
        });
      }
    });
  }

  /***************************************************************************************
   * 설명 : 프로젝트 선택 시 처리
  ***************************************************************************************/
  useEffect(() => {
    if( props.tabIndex === 0 && props.selected !== undefined && props.selected.seq !== undefined ) {
      formik.setValues(props.selected);

      getImageList();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.tabIndex, props.selected]);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <section className="project-info">
      <form onSubmit={formik.handleSubmit}>
        <section className="no-permission hidden">
          <div className="infoTxt">
            프로젝트 정보를 조회할 권한이 없습니다.<br/>
            프로젝트 조회를 원할 경우 [열람신청]을 하시기 바랍니다.
          </div>
        </section>

        <section className="">
          <header className="project-header">
            <div className="fl">
              { // 프로젝트 추가 정보 상태
                formik.values.isConfirm === null
                ? <ChipEx color="error" variant="variant" size="small" label="등록" className="f12" />
                : ! isCheckValue(formik.values.detailSeq) && parseInt(formik.values.isConfirm) !== 1
                ? <ChipEx color="error" variant="variant" size="small" label="등록" className="f12" />
                : isCheckValue(formik.values.detailSeq) && parseInt(formik.values.isConfirm) !== 1
                ? <ChipEx color="success" variant="variant" size="small" label="추가" className="f12" />
                : <ChipEx color="primary" variant="variant" size="small" label="등록완료" className="f12" />
              }

              { // 프로젝트 파일 정보 상태
                formik.values.isUploadCheck === 0 ? <ChipEx color="info" variant="variant" size="small" className="ml5 f12" label="처리중" />
                : ! isCheckValue(formik.values.isFile) || parseInt(formik.values.isFile) === 0
                ? <ChipEx color="error" variant="variant" size="small" className="ml5 f12" label="미등록" />
                : parseInt(formik.values.isFile) === 1
                ? <ChipEx color="secondary" variant="variant" size="small" className="ml5 f12" label="등록중" />
                : parseInt(formik.values.isFile) === 2
                ? <ChipEx color="success" variant="variant" size="small" className="ml5 f12" label="등록완료" />
                : <ChipEx color="primary" variant="variant" size="small" className="ml5 f12" label="처리완료" />
              }
              <div style={{display: "inline-block", verticalAlign: 'middle'}}>
                <span className="ml5 bold f14">[{formik.values.projectNumber}]</span>
                <span className="pad-newLine"></span>
                <span className="bold f14">{props.selected?.projectName}</span>
              </div>

            </div>
            <div className="fl ml5 pt3">

            </div>
          </header>

          <h4 className="mt20 mb10 f14">프로젝트 정보</h4>

          <table className="input-table mb15">
            <colgroup>
              <col style={{width: '8%'}} />
              <col style={{width: '22%'}} />
              <col style={{width: '8%'}} />
              <col style={{width: '24%'}} />
              <col style={{width: '8%'}} />
              <col style={{width: '10%'}} />
              <col style={{width: '8%'}} />
              <col style={{width: '12%'}} />
            </colgroup>

            <tbody>
              <tr>
                <th scope="row"><label htmlFor="designProjectType">구분</label></th>
                <td className="">
                  <ToggleButtonGroupEx
                    name="designProjectType"
                    exclusive={true}
                    formik={formik}
                    className="search-toggle-btn"
                    data={[
                      {label: '본사', value: 1},
                      {label: '타사', value: 2},
                      {label: '외부', value: 3},
                    ]}
                  />
                </td>
                <th scope="row"><label htmlFor="projectName">프로젝트명</label></th>
                <td className="">
                  <InputEx
                    name="projectName"
                    formik={formik}
                    fullWidth={true}
                  />
                </td>
                <th scope="row"><label htmlFor="projectYear">년도</label></th>
                <td className="pl10">
                  {formik.values.projectYear}
                </td>
                <th scope="row"><label htmlFor="projectNumber">PJ.NO</label></th>
                <td className="pl10">
                  {formik.values.projectNumber}
                </td>
              </tr>
              <tr>
                <th scope="row"><label htmlFor="relationProject">연관프로젝트</label></th>
                <td colSpan={7}>
                  <TextareaEx
                    name="relationProject"
                    formik={formik}
                    minRows="5"
                    maxRows="5"
                    className="p10"
                    style={{width: '100%'}}
                  />
                </td>
              </tr>
              <tr>
                <th scope="row"><label htmlFor="memo">비고</label></th>
                <td colSpan={7}>
                  <TextareaEx
                    name="memo"
                    formik={formik}
                    minRows="5"
                    maxRows="5"
                    className="p10"
                    style={{width: '100%'}}
                  />
                </td>
              </tr>
            </tbody>
          </table>

          <section className="mb20 mt20">
            <header className="clearfix mb5">
              <h4 className="fl f14">대표 파일정보 (최대 100개 표시)</h4>
              <div className="fr f14">전체파일수: {comma(pathInfo.totalFileCount)}개 전체용량: {formatBytes(pathInfo.totalSize)}</div>
            </header>

            <div className="fileBox">
              {previewList?.length > 0 &&
                <ul>
                  { previewList.map((item, index) => {
                    let url = 'http://archive.dagroup.co.kr/synap/SynapDocViewServer/thumbnail/'
                      + item.thumbnail + '/0?dpi=L&withXml=false&resizeType=M&fileExt=jpg&resizeValue=210&quality=80';

                    return (
                      <React.Fragment key={index}>
                        <li>
                          <div className="img">
                            { // 이미지 파일 미리보기
                              IMAGE_FILE.includes(item.ext?.toLowerCase()) &&
                              <img src={baseURL + "/api/v1/project/image/download?thumbnail=" + base64_encode(item.thumbnail)} alt={item.fileName}
                                onError={(e) => {
                                  e.target.src = NoImage;
                                }}
                              />
                            }

                            { // 동영상 파일 미리보기
                              MOVIE_FILE_STREAM.includes(item.ext?.toLowerCase()) &&
                              <>
                                <img
                                  src={baseURL + "/api/v1/project/image/download?thumbnail=" + base64_encode(item.thumbnail)}
                                  alt={item.fileName}
                                  onError={(e) => {
                                    e.target.src = NoImage;
                                  }}
                                  className="cp"
                                  style={previewMovie === item.seq ? {display: 'none', width: '100%'} : {width: '100%'}}
                                  onMouseEnter={(e) => {
                                    if( MOVIE_FILE_STREAM.includes(item.ext?.toLowerCase()) ) {
                                      setPreviewMovie(item.seq);
                                    }
                                  }}
                                />
                                <video
                                  autoPlay
                                  muted
                                  loop
                                  playsInline
                                  width="100%"
                                  height="100%"
                                  ref={movieRef}
                                  style={previewMovie !== item.seq ? {display: 'none'} : {}}
                                  className="cp"
                                  onMouseLeave={(e) => {
                                    if( MOVIE_FILE_STREAM.includes(item.ext?.toLowerCase()) ) {
                                      setPreviewMovie('');
                                    }
                                  }}
                                >
                                  <source src={baseURL + '/api/v1/file/download?seq=' + item.seq} type="video/mp4" />
                                </video>
                              </>
                            }

                            { // 기타 파일 미리보기
                              ! IMAGE_FILE.includes(item.ext?.toLowerCase()) && ! MOVIE_FILE_STREAM.includes(item.ext?.toLowerCase()) &&
                              <img
                                src={url}
                                alt={item.fileName}
                                onError={(e) => {
                                  e.target.src = NoImage;
                                }}
                              />
                            }
                          </div>
                          <div className="name">{item.fileName}</div>
                          <div className="size">{formatBytes(item.fileSize)}</div>
                          { ! MOVIE_FILE.includes(item.ext?.toLowerCase()) &&
                            <div className="hover-wrap">
                              <Button
                                variant="contained"
                                color="primary"
                                className="Btn"
                                onClick={() => {
                                  if( ! MOVIE_FILE.includes(item.ext?.toLowerCase()) ) {
                                    let url = 'http://archive.dagroup.co.kr/synap/SynapDocViewServer/job?fileType=Local&sync=true&fid=' + item.seq + '&filePath=';
                                    window.open(url + encodeURIComponent(item.destPath))
                                  }
                                  //setOpenModal({open: true, modal: 'previewFile', data: item})
                                }}
                              >미리보기</Button>
                              <div className="darkness"></div>
                            </div>
                          }
                        </li>
                      </React.Fragment>
                    )
                  })}
                </ul>
              }

              {previewList?.length < 1 &&
                <div style={{height: '190px', display: 'flex', alignItems: 'center'}}>
                  <div style={{width: '100%', textAlign: 'center', fontSize: '14px'}}>등록된 미리보기 파일이 없습니다.</div>
                </div>
              }
            </div>
          </section>

          <footer className="mt10 mb30">
            <div className="fr right">
              <ButtonEx
                auth="isWrite"
                title="저장하기"
                color="secondary"
                variant="contained"
                className="Btn ml10"
                onClick={() => {
                  if( (formik.values.designProjectType || '') === '' ) {
                    alertMsg("구분은 필수 항목입니다.", "W", MESSAGE_DELAY);
                    return;
                  }

                  setOpenModal({open: true, modal: 'dialog', data: {}});
                }}
              />

              {isCheckValue(formik.values.detailSeq) && parseInt(formik.values.isConfirm) !== 1 &&
                <ButtonEx
                  auth="isModify"
                  title="등록완료"
                  color="primary"
                  variant="contained"
                  className="Btn ml10"
                  onClick={() => {
                    setOpenModal({open: true, modal: 'dialogComplete', data: {}});
                  }}
                />
              }
            </div>

            <div className="clearfix"></div>
          </footer>

          { openModal.open && openModal.modal === 'dialog' &&
            <Modal
              open={openModal.open}
              onClose={() => setOpenModal({ open: false, modalName: openModal.modal, data: openModal.data })}
            >
              <AlertDialog
                open={openModal.open}
                close={() => setOpenModal({ open: false, modalName: openModal.modal, data: openModal.data })}
                title="프로젝트 추가정보 저장"
                color="primary"
                message={`입력하신 프로젝트 추가 정보를 저장하시겠습니까?`}
                confirm="확인"
                onClick={() => {
                  saveProject(formik.values.isConfirm);
                }}
              />
            </Modal>
          }

          { openModal.open && openModal.modal === 'dialogComplete' &&
            <Modal
              open={openModal.open}
              onClose={() => setOpenModal({ open: false, modalName: openModal.modal, data: openModal.data })}
            >
              <AlertDialog
                open={openModal.open}
                close={() => setOpenModal({ open: false, modalName: openModal.modal, data: openModal.data })}
                title="프로젝트 등록완료"
                color="primary"
                message={`해당 프로젝트를 등록완료 처리 하시겠습니까?`}
                confirm="확인"
                onClick={() => {
                  saveProject(1);
                }}
              />
            </Modal>
          }

        </section>
      </form>
    </section>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default TabDesignProjectInfo;