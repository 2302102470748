/**
 * * 열람신청 리스트 가져오기
 * @param {*} getApi
 * @param {*} params
 * @param {*} func
 * @returns
 */
export function getRequestApplicationApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("get", '/api/v1/request', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  });
}

/**
 * * 사용자 열람신청 리스트 가져오기
 * @param {*} getApi
 * @param {*} params
 * @param {*} func
 * @returns
 */
export function getUserRequestApplicationApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("get", '/api/v1/request/user', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  });
}


/**
 * * 파일 열람신청
 * @param {*} getApi
 * @param {*} func
 * @returns
 */
export function setRequestApplicationApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("post", '/api/v1/request/application', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  })
}

/**
 * * 프로젝트 열람신청
 * @param {*} getApi
 * @param {*} func
 * @returns
 */
export function setRequestApplicationProjectApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("post", '/api/v1/request/application/project', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  })
}


/**
 * * 열람신청 승인/반려 처리
 * @param {*} getApi
 * @param {*} func
 * @returns
 */
export function setRequestApproval(getApi, params, func) {
  if( ! getApi ) return;

  getApi("post", '/api/v1/request/approval', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  })
}

/**
 * * 열람신청 파일 리스트 가져오기
 * @param {*} getApi
 * @param {*} params
 * @param {*} func
 * @returns
 */
export function getRequestApplicationFileApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("get", '/api/v1/request/file', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  });
}