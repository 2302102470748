/*****************************************************************************************
 * 설명 : 열람관리 - 상세정보
 * URI : /reading
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { AgGridReact } from 'ag-grid-react';
import { useFormik } from 'formik';
import * as moment from 'moment';
import { useEffect, useState } from 'react';

import { MESSAGE_DELAY } from 'config/config';
import useGridConfig from 'hooks/useGridConfig';

import AlertDialog from 'components/alertDiolog';
import ChipEx from 'components/chipEx';
import { ButtonEx, CheckBoxEx, DateEx, InputEx } from 'components/inputEx';
import alertMsg from 'components/message';
import customTooltipAgGrid from 'components/tooltipAgGrid';

import { getRequestApplicationFileApi, setRequestApproval } from 'service/request';
import Restful from 'service/restful';
import { comma, formatBytes } from 'service/utils';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const ReadingModal = (props) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [getApi] = Restful();
  const [list, setList] = useState([]);

  const [openModal, setOpenModal] = useState({open: false, modal: 'approval', data:{}});

  /***************************************************************************************
   * 설명 : 업체 검색
  ***************************************************************************************/
  const formik = useFormik({
    initialValues: {
      isDate: 0,
      approvalStartDate: moment().format('YYYY-MM-DD'),
      approvalEndDate: moment().add(14, 'day').format('YYYY-MM-DD'),
      approvalReason: '',
    },
    onSubmit: (values) => {
    }
  });

  // table column
  const [columnDefs, setColumnDefs] = useState([
    { headerName: '', field: 'seq', hide: true },
    { headerName: '순번', field: 'rowIndex', width: 45, cellClass: 'cp text-right',
      valueGetter: (params) => {
        return comma(params.node.rowIndex + 1);
      }
    },
    { headerName: '파일명', field: 'fileName', width: 200, cellClass: "cp" },
    { headerName: '용량', field: 'fileSize', width: 80, cellClass: "cp text-right",
      valueGetter: (params) => {
        return formatBytes(params.data?.fileSize || 0, 2);
      }
    },
    { headerName: '태그', field: 'hashTag', width: 280, cellClass: "cp" },
    { headerName: '등록일시', field: 'createDate', width: 150, cellClass: "cp center" },
  ]);

  const [gridConfig] = useGridConfig(99, setColumnDefs);

  /***************************************************************************************
   * 설명 : 승인 처리
  ***************************************************************************************/
  const setApproval = () => {
    let params = {
      seq: props.selected[0].seq,
      ...formik.values
    }

    if( Array.isArray(params.isDate) && params.isDate.length > 0 && params.isDate[0] === 'on' ) params.isDate = 1;
    else params.isDate = 0;

    params.status = 2;

    setRequestApproval(getApi, params, (response) => {
      if( response !== undefined && response.data.result ) {
        alertMsg(response.data.message, "S", MESSAGE_DELAY);

        if( props.getList ) props.getList();

        props.close();

      } else {
        alertMsg(response.data.message || '서버와의 통신에 실패하였습니다.', "E", MESSAGE_DELAY);
      }
    });
  }

  /***************************************************************************************
   * 설명 : 반려 처리
  ***************************************************************************************/
  const setApprovalReturn = () => {
    let params = {
      seq: props.selected[0].seq,
      ...formik.values
    }

    if( Array.isArray(params.isDate) && params.isDate.length > 0 && params.isDate[0] === 'on' ) params.isDate = 1;
    else params.isDate = 0;

    params.status = 3;

    setRequestApproval(getApi, params, (response) => {
      if( response !== undefined && response.data.result ) {
        alertMsg(response.data.message, "S", MESSAGE_DELAY);

        if( props.getList ) props.getList();

        props.close();

      } else {
        alertMsg(response.data.message || '서버와의 통신에 실패하였습니다.', "E", MESSAGE_DELAY);
      }
    });
  }

  /***************************************************************************************
   * 설명 : 파일 리스트 가져오기
  ***************************************************************************************/
  const getRequestApplicationFile = () => {
    let params = {
      seq: props.selected[0].seq
    }

    getRequestApplicationFileApi(getApi, params, (response) => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        setList(response.data.data);

      } else {
        setList([]);
      }
    })
  }

  /***************************************************************************************
   * 설명 : 모달 오픈 처리
  ***************************************************************************************/
  useEffect(() => {
    if( props.open && props.selected && props.selected.length > 0 ) {
      formik.setValues(props.selected[0]);

      if( props.selected[0].approvalStartDate === null || props.selected[0].approvalStartDate === '' ) {
        formik.setFieldValue('approvalStartDate', moment().format('YYYY-MM-DD'),)
      }

      if( props.selected[0].approvalEndDate === null || props.selected[0].approvalEndDate === '' ) {
        formik.setFieldValue('approvalEndDate', moment().add(14, 'day').format('YYYY-MM-DD'),)
      }

      if( props.selected[0].isApplicationView === 2 )
        getRequestApplicationFile();
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <section className="modal">
      <section className="modal-wrap" style={{width: '1000px'}}>
        <header className="modal-header" style={{ cursor: 'move' }} id="draggable-dialog-title">
          <div className="modal-title">열람 신청정보</div>
          <Button className="close-btn" onClick={props.close}>×</Button>
        </header>

        <section className="modal-body">
          <form onSubmit={formik.handleSubmit}>
            <div className="mb10">
              <table className="input-table">
                <colgroup>
                  <col style={{width: '15%'}} />
                  <col style={{width: '35%'}} />
                  <col style={{width: '15%'}} />
                  <col style={{width: '35%'}} />
                </colgroup>

                <tbody>
                  <tr>
                    <th scope="row"><label htmlFor="">신청상태</label></th>
                    <td className="pl10">
                      {parseInt(props.selected[0]?.status) === 1 &&
                        <ChipEx color="primary" variant="outlined" size="small" label="신청" />
                      }
                      {parseInt(props.selected[0]?.status) === 2 &&
                        <ChipEx color="success" variant="outlined" size="small" label="승인" />
                      }
                      {parseInt(props.selected[0]?.status) === 3 &&
                        <ChipEx color="error" variant="outlined" size="small" label="반려" />
                      }
                    </td>
                    <th scope="row"><label htmlFor="">신청자</label></th>
                    <td className="pl10">
                      {props.selected[0]?.departmentName && props.selected[0]?.departmentName + ', '}
                      {props.selected[0]?.positionName && props.selected[0]?.positionName + ', '}
                      {props.selected[0]?.creatorName && props.selected[0]?.creatorName}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row"><label htmlFor="">프로젝트명</label></th>
                    <td className="pl10">{props.selected[0].projectName}</td>
                    <th scope="row"><label htmlFor="">신청일시</label></th>
                    <td className="pl10">{props.selected[0].createDate}</td>
                  </tr>
                  <tr>
                    <th scope="row"><label htmlFor="">신청사유</label></th>
                    <td colSpan={3} className="pl10">{props.selected[0].reason}</td>
                  </tr>
                  <tr>
                    <th scope="row"><label htmlFor="">신청구분</label></th>
                    <td className="pl10">
                      {parseInt(props.selected[0].isApplicationView) === 1 &&
                        <ChipEx color="primary" variant="outlined" size="small" label="프로젝트 파일 전체 조회" />
                      }
                      {parseInt(props.selected[0].isApplicationView) === 2 &&
                        <ChipEx color="success" variant="outlined" size="small" label="선택 파일만 조회" />
                      }
                      {parseInt(props.selected[0].isApplicationView) === 3 &&
                        <ChipEx color="secondary" variant="outlined" size="small" label="프로젝트 추가정보 조회" />
                      }
                    </td>

                    {parseInt(props.selected[0].isApplicationView) !== 3 &&
                      <>
                        <th scope="row"><label htmlFor="">다운로드 권한</label></th>
                        <td className="pl10">
                          {parseInt(props.selected[0].isDownload) === 1 &&
                            <ChipEx color="error" variant="outlined" size="small" label="다운로드 및 미리보기" />
                          }
                          {parseInt(props.selected[0].isDownload) === 2 &&
                            <ChipEx color="secondary" variant="outlined" size="small" label="파일 미리보기" />
                          }
                        </td>
                      </>
                    }

                    {parseInt(props.selected[0].isApplicationView) === 3 &&
                      <>
                        <th scope="row"></th>
                        <td className="pl10"></td>
                      </>
                    }

                  </tr>
                  <tr>
                    <th scope="row"><label htmlFor="">승인/반려자</label></th>
                    <td className="pl10">
                      {props.selected[0].updaterDepartmentName && props.selected[0].updaterDepartmentName + ', '}
                      {props.selected[0].updaterPositionName && props.selected[0].updaterPositionName + ', '}
                      {props.selected[0].updaterName}
                    </td>
                    <th scope="row"><label htmlFor="">승인/반려일시</label></th>
                    <td className="pl10">{props.selected[0].updateDate}</td>
                  </tr>
                  <tr>
                    <th scope="row"><label htmlFor="">승인기간</label></th>
                    <td colSpan={3}>
                      <div className="flex" style={{alignItems: 'center'}}>
                        <DateEx
                          type="date"
                          name="approvalStartDate"
                          formik={formik}
                          fullWidth={false}
                          style={{width: '230px'}}
                          className="ml5"
                        />

                        <span className="ml5 mr5">~</span>

                        <DateEx
                          type="date"
                          name="approvalEndDate"
                          formik={formik}
                          fullWidth={false}
                          style={{width: '230px', lineHeight: '28px'}}
                        />

                        <CheckBoxEx
                          name="isDate"
                          color="default"
                          formik={formik}
                          className="checkBox ml10"
                          label="기간제한 없음"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row"><label htmlFor="">승인/반려 사유</label></th>
                    <td colSpan={3} className="p5">
                      <InputEx
                        name="approvalReason"
                        formik={formik}
                        fullWidth={true}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </form>

          {formik.values.isApplicationView !== 3 &&
            <section className="clearfix mt20">
              <div className="clearfix mb10">
                <h4 className="fl f14">파일신청</h4>
                <div className="fr f14">신청갯수: {comma(list.length || 0)}</div>
              </div>

              <div className="ag-theme-balham" style={{height: '250px'}}>
                <AgGridReact
                  defaultColDef={{
                    sortable: true,
                    resizable: true,
                    filter: false,
                    lockVisible: true,
                    tooltipComponent: customTooltipAgGrid,
                  }}
                  tooltipShowDelay={0}
                  tooltipHideDelay={2000}
                  columnDefs={columnDefs}
                  rowData={list}
                  rowSelection={'single'}
                  onGridReady={(event) => {
                    gridConfig(event.columnApi);
                  }}
                  onRowClicked={(event) => {
                  }}
                  onRowDoubleClicked={(event) => {
                  }}
                  overlayNoRowsTemplate = "검색된 내용이 없습니다."
                />
              </div>
            </section>
          }

        </section>

        <footer className="modal-footer">
          <div>
            {parseInt(props.selected[0]?.status) === 1 &&
              <>
                <ButtonEx
                  auth="isModify"
                  title="승인"
                  variant="outlined"
                  color="secondary"
                  className="Btn"
                  onClick={() => {
                    if( (formik.values.approvalReason || '') === '' ) {
                      alertMsg("승인 사유는 필수 항목입니다.", "W", MESSAGE_DELAY);
                    } else {
                      setOpenModal({open: true, modal: 'approval', data: {}})
                    }
                  }}
                />

                <ButtonEx
                  auth="isModify"
                  title="반려"
                  variant="outlined"
                  color="error"
                  className="Btn ml10"
                  onClick={() => {
                    if( (formik.values.approvalReason || '') === '' ) {
                      alertMsg("반려 사유는 필수 항목입니다.", "W", MESSAGE_DELAY);
                    } else {
                      setOpenModal({open: true, modal: 'return', data: {}})}
                    }
                  }
                />
              </>
            }
          </div>

          <Button
            color="inherit"
            variant="outlined"
            className="Btn fr"
            onClick={() => {
              props.close();
            }}
          >창닫기</Button>
        </footer>
      </section>


      { openModal.open && openModal.modal === 'approval' &&
        <Modal
          open={openModal.open}
          onClose={() => setOpenModal({ open: false, modalName: openModal.modal, data: openModal.data })}
        >
          <AlertDialog
            open={openModal.open}
            close={() => setOpenModal({ open: false, modalName: openModal.modal, data: openModal.data })}
            title="열람신청 승인"
            color="primary"
            message={
              <div>
                <div className="">열람신청 내역을 승인 처리 하시겠습니까?</div>
              </div>
            }
            confirm="확인"
            onClick={() => {
              setApproval();
            }}
          />
        </Modal>
      }

      { openModal.open && openModal.modal === 'return' &&
        <Modal
          open={openModal.open}
          onClose={() => setOpenModal({ open: false, modalName: openModal.modal, data: openModal.data })}
        >
          <AlertDialog
            open={openModal.open}
            close={() => setOpenModal({ open: false, modalName: openModal.modal, data: openModal.data })}
            title="열람신청 반려"
            color="primary"
            message={
              <div>
                <div className="cred">열람신청 내역을 반려 처리 하시겠습니까?</div>
              </div>
            }
            confirm="확인"
            onClick={() => {
              if( formik.values.approvalReason === '' ) {
                alertMsg("반려 사유는 필수 항목입니다.", "W", MESSAGE_DELAY);
              } else {
                setApprovalReturn();
              }
            }}
          />
        </Modal>
      }
    </section>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default ReadingModal;