/*****************************************************************************************
 * 설명 : 관리 - 프로잭트정보
 * URI : /project
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { AgGridReact } from 'ag-grid-react';
import { encode as base64_encode } from 'base-64';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from "yup";

import { IMAGE_FILE, MESSAGE_DELAY, MOVIE_FILE } from 'config/config';
import useGridConfig from 'hooks/useGridConfig';

import {
  getProjectAwardListApi,
  getProjectForeignListApi, getProjectImageListApi,
  getProjectOwnershipListApi,
  getProjectPartnerListApi,
  getProjectScaleListApi,
  setProjectApi
} from 'service/project';
import Restful from "service/restful";
import { PaperComponent, checkArrayValue, comma, formatBytes } from 'service/utils';

import BtnAgGridSave from 'components/btnAgGridSave';
import ChipEx from 'components/chipEx';
import alertMsg from 'components/message';
import customTooltipAgGrid from 'components/tooltipAgGrid';
import ImagePreviewModal from 'pages/admin/project/modal/imagePreviewModal';
import RequestAddInfoModal from './requestAddInfoModal';

import NoImage from 'assets/images/prepare.png';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const TabUserProjectInfo = ( props ) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [getApi] = Restful();

  const [ownershipList, setOwnershipList] = useState([]);
  const [partnerList, setPartnerList] = useState([]);
  const [foreignList, setForeignList] = useState([]);
  const [newTechList, setNewTechList] = useState([]);
  const [awardInfoList, setAwardInfoList] = useState([]);
  const [scaleArrayList, setScaleArrayList] = useState([]);

  const [pathInfo, setPathInfo] = useState({
    totalDirectoryCount: 0,
    totalFileCount: 0,
    totalSize: 0
  });

  const [previewList, setPreviewList] = useState([]);


  const formik = useFormik({
    initialValues: {
      seq: '',
      projectNumber: '',
      hashTag: '',
      businessType: '',
      builderType: '',
      orderCompanyId: '',
      builderCompanyId: '',
      purpose1: '',
      purpose1Code: '',
      purposeExtra: '',
      doroAddress: '',
      parcelAddress: '',
      longitude: '',
      latitude: '',
      pointOut: '',
      purposeRegion: '',
      responsibilityConstructor: '',
      cooperationCompany: '',
      partMember: '',
      underLayer: '',
      groundLayer: '',
      numberHouse: '',
      scaleExtra: '',
      awardInfo: '',
      structure1: '',
      structure2: '',
      structureExtra: '',
      newTech: '',
      newTechCode: '',
      detailSeq: '',
      memo: '',
      ownershipMemo: '',
      partnerMemo: '',
      readingGrade: 0,
      isConfirm: 0,
      isFile: 0,
      relationProject: '',
      departmentNameLast: '',
      departmentNameLastReason: '',
      projectPmNameLast: '',
      projectPmNameLastReason: '',
      projectApmNameLast: '',
      projectApmNameLastReason: '',
      projectCompanyList: [],
      ownershipCompanyList: [],
      awardInfoList: []
    },
    validationSchema: Yup.object().shape({
      hashTag: Yup.string().max(45, "45자리").nullable(),
      purposeExtra: Yup.string().max(200, "200자리").nullable(),
      doroAddress: Yup.string().max(100, "100자리").nullable(),
      parcelAddress: Yup.string().max(100, "100자리").nullable(),
      pointOut: Yup.string().max(100, "100자리").nullable(),
      purposeRegion: Yup.string().max(100, "100자리").nullable(),
      responsibilityConstructor: Yup.string().max(100, "100자리").nullable(),
      // cooperationCompany: Yup.string().max(100, "100자리").nullable(),
      partMember: Yup.string().max(200, "200자리").nullable(),
      // underLayer: Yup.string().max(100, "100자리").nullable(),
      // groundLayer: Yup.string().max(100, "100자리").nullable(),
      // numberHouse: Yup.string().max(100, "100자리").nullable(),
      scaleExtra: Yup.string().max(100, "100자리").nullable(),
      awardInfo: Yup.string().max(200, "200자리").nullable(),
      structureExtra: Yup.string().max(200, "200자리").nullable(),
      // newTech: Yup.string().max(1000, "1000자리").nullable(),
    }),
    onSubmit: (values) => {
      saveProject(values.isConfirm);
    }
  });

  const [openModal, setOpenModal] = useState({open: false, modal: 'sync', data: []});

  // 참여업체 컬럼
  const [columnDefs, setColumnDefs] = useState([
    { headerName: '', field: 'seq', hide: true},
    { headerName: '순번', field: 'rowIndex', width: 45, cellClass: 'cp text-right',
      valueGetter: (params) => {
        return comma(params.node.rowIndex + 1);
      }
    },
    { headerName: '주관업체여부', field: 'isMain', width: 100, cellClass: 'cp center',
      cellRendererFramework: function(params) {
        if( parseInt(params.data.isMain) === 1 )
          return <ChipEx color="secondary" variant="outlined" size="small" label="주관업체" />;
        else
          return <ChipEx color="success" variant="outlined" size="small" label="참여업체" />;
      }
    },
    { headerName: '참여업체', field: 'companyName', width: 300, cellClass: 'cp' },
    { headerName: '지분율(%)', field: 'ownershipRate', width: 100, cellClass: 'cp text-right' },
    { headerName: '메모', field: 'memo', width: 500, cellClass: 'cp', tooltipField: 'memo' },
  ]);

  // 협력업체 컬럼
  const [columnDefsPartner, setColumnDefsPartner] = useState([
    { headerName: '', field: 'seq', cellClass: 'cp', width: 35, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
    { headerName: '순번', field: 'rowIndex', width: 45, cellClass: 'cp text-right',
      valueGetter: (params) => {
        return comma(params.node.rowIndex + 1);
      }
    },
    { headerName: '구분', field: 'group', width: 100, cellClass: 'cp center' },
    { headerName: '업체명', field: 'companyName', width: 300, cellClass: 'cp' },
    { headerName: '담당자', field: 'chargeName', width: 200, cellClass: 'cp' },
    { headerName: '메모', field: 'memo', width: 500, cellClass: 'cp', tooltipField: 'memo' },
  ]);

   // 협업해외사 컬럼
   const [columnDefsForeign, setColumnDefsForeign] = useState([
    { headerName: '', field: 'seq', cellClass: 'cp', width: 35, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
    { headerName: '순번', field: 'rowIndex', width: 45, cellClass: 'cp text-right',
      valueGetter: (params) => {
        return comma(params.node.rowIndex + 1);
      }
    },
    { headerName: '구분', field: 'group', width: 100, cellClass: 'cp center' },
    { headerName: '업체명', field: 'companyName', width: 300, cellClass: 'cp' },
    { headerName: '담당자', field: 'chargeName', width: 200, cellClass: 'cp' },
    { headerName: '메모', field: 'memo', width: 500, cellClass: 'cp', tooltipField: 'memo' },
  ]);

  // 수상정보 컬럼
  const [columnDefsAward, setColumnDefsAward] = useState([
    { headerName: '', field: 'seq', hide: true},
    { headerName: '순번', field: 'rowIndex', width: 45, cellClass: 'cp text-right',
      valueGetter: (params) => {
        return comma(params.node.rowIndex + 1);
      }
    },
    { headerName: '수상정보', field: 'awardInfo', width: 500, cellClass: 'cp', tooltipField: 'awardInfo' },
    { headerName: '메모', field: 'memo', width: 500, cellClass: 'cp', tooltipField: 'memo' },
  ]);

  // 신기술 정보 컬럼
  const [columnDefsNewTech, setColumnDefsNewTech] = useState([
    { headerName: '', field: 'seq', hide: true},
    { headerName: '순번', field: 'rowIndex', width: 45, cellClass: 'cp text-right',
      valueGetter: (params) => {
        return comma(params.node.rowIndex + 1);
      }
    },
    { headerName: '검색어', field: 'searchText', hide: true },
    { headerName: '지정번호', field: 'commonCode', width: 70, cellClass: "cp text-right" },
    { headerName: '신기술명', field: 'commonName', width: 300, cellClass: "cp", tooltipField: 'commonName' },
    { headerName: '개발사(기관)', field: 'val1', width: 230, cellClass: "cp", tooltipField: 'val1' },
    { headerName: '기술분야', field: 'val2', width: 200, cellClass: "cp", tooltipField: 'val2' },
    { headerName: '보호기간', field: 'val3', width: 170, cellClass: "cp center", tooltipField: 'val3' },
    { headerName: '스마트건설기술', field: 'val4', width: 70, cellClass: "cp center",
      cellRendererFramework: function(params) {
        if( params.data.val4 === '해당' )
          return <ChipEx color="primary" variant="outlined" size="small" label="해당" />;
        else
          return <ChipEx color="error" variant="outlined" size="small" label="미해당" />;
      }
    },
    { headerName: '상태', field: 'val5', width: 60, cellClass: "cp center",
      cellRendererFramework: function(params) {
        if( params.data.val5 === '유효' )
          return <ChipEx color="success" variant="outlined" size="small" label="유효" />;
        else
          return <ChipEx color="error" variant="outlined" size="small" label="만료" />;
      }
    },
  ]);

  // 규모 정보 컬럼
  const [columnDefsScale, setColumnDefsScale] = useState([
    { headerName: '', field: 'seq', hide: true},
    { headerName: '순번', field: 'rowIndex', width: 45, cellClass: 'cp text-right',
      valueGetter: (params) => {
        return comma(params.node.rowIndex + 1);
      }
    },
    { headerName: '명칭', field: 'scaleName', width: 150, cellClass: "cp", tooltipField: 'scaleName' },
    { headerName: '우편번호', field: 'zipCode', width: 50, cellClass: "cp center" },
    { headerName: '도로명주소', field: 'address', width: 200, cellClass: "cp", tooltipField: 'address' },
    { headerName: '상세주소', field: 'addressDetail', width: 150, cellClass: "cp", tooltipField: 'addressDetail' },
    { headerName: '지번주소', field: 'parcelAddress', width: 150, cellClass: "cp", tooltipField: 'parcelAddress' },
    { headerName: '층수(지하)', field: 'underLayer', width: 70, cellClass: "cp text-right" },
    { headerName: '층수(지상)', field: 'groundLayer', width: 70, cellClass: "cp text-right" },
    { headerName: '세대수', field: 'numberHouse', width: 70, cellClass: "cp text-right" },
    { headerName: '규모(기타)', field: 'scaleExtra', width: 200, cellClass: "cp", tooltipField: 'scaleExtra' },
  ]);

  const [gridConfig, setGridUpdate] = useGridConfig(30, setColumnDefs);
  const [gridConfigNewTech, setGridUpdateNewTech] = useGridConfig(31, setColumnDefsNewTech);
  const [gridConfigAward, setGridUpdateAward] = useGridConfig(32, setColumnDefsAward);
  const [gridConfigPartner, setGridUpdatePartner] = useGridConfig(33, setColumnDefsPartner);
  const [gridConfigForeign, setGridUpdateForeign] = useGridConfig(34, setColumnDefsForeign);
  const [gridConfigScale, setGridUpdateScale] = useGridConfig(35, setColumnDefsScale);

  /*******************************************************************************
    설  명 : 주소 바인딩
  *******************************************************************************/
  const handleCloseModal = (data) => {
    formik.setFieldValue('doroAddress', data.address);
    formik.setFieldValue('parcelAddress', data.jibunAddress);

    setOpenModal({open: false, modal: 'daum', data: {}});
  };

  /*******************************************************************************
    설  명 : 참여업체 정보 가져오기
  *******************************************************************************/
  const getOwnershipList = () => {
    let params = {
      projectNumber: props.selected?.projectNumber
    }

    getProjectOwnershipListApi(getApi, params, (response) => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        setOwnershipList(response.data.data);
      } else {
        setOwnershipList([]);
      }
    });
  }

  /*******************************************************************************
    설  명 : 협력업체 정보 가져오기
  *******************************************************************************/
  const getPartnerList = () => {
    let params = {
      projectNumber: props.selected?.projectNumber
    }

    getProjectPartnerListApi(getApi, params, (response) => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        setPartnerList(response.data.data);
      } else {
        setPartnerList([]);
      }
    });
  }

  /*******************************************************************************
    설  명 : 협력업체 정보 가져오기
  *******************************************************************************/
  const getForeignList = () => {
    let params = {
      projectNumber: props.selected?.projectNumber
    }

    getProjectForeignListApi(getApi, params, (response) => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        setForeignList(response.data.data);
      } else {
        setForeignList([]);
      }
    });
  }

  /*******************************************************************************
    설  명 : 수상 정보 가져오기
  *******************************************************************************/
  const getAwardInfoList = () => {
    let params = {
      projectNumber: props.selected?.projectNumber
    }

    getProjectAwardListApi(getApi, params, (response) => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        setAwardInfoList(response.data.data);
      } else {
        setAwardInfoList([]);
      }
    });
  }

  /*******************************************************************************
    설  명 : 프로젝트 추가 정보 저장
  *******************************************************************************/
  const saveProject = (isConfirm) => {
    let params = {...formik.values};
    isConfirm = isConfirm === null ? 0 : isConfirm;

    params.orderCompanyId = checkArrayValue(params, 'orderCompanyId', true);
    params.builderCompanyId = checkArrayValue(params, 'builderCompanyId', true);
    params.pointOut = checkArrayValue(params, 'pointOut', true);
    params.purposeRegion = checkArrayValue(params, 'purposeRegion', true);
    params.cooperationCompany = checkArrayValue(params, 'cooperationCompany', true);
    params.isConfirm = isConfirm;
    params.isFile = checkArrayValue(params, 'isFile', true);

    params.ownershipCompanyList = ownershipList;
    params.awardInfoList = awardInfoList;
    params.scaleArrayList = scaleArrayList;
    params.partnerCompanyList = partnerList;
    params.foreignCompanyList = foreignList;

    setProjectApi(getApi, params, (response => {
      if( response !== undefined && response.data.result ) {
        alertMsg(response.data.message, "S", MESSAGE_DELAY);

        if( formik.values.seq === null || formik.values.seq === '' )
          formik.setFieldValue("detailSeq", response.data.data?.detailSeq);

        formik.setFieldValue("isConfirm", response.data.data?.isConfirm);
        formik.setFieldValue("departmentNameOrg", formik.values.departmentNameLast);
        formik.setFieldValue("projectPmNameOrg", formik.values.projectPmNameLast);

        // 프로젝트 리스트 업데이트
        if( props.setList && props.list) {
          let tmp = [...props.list];
          let index = props.selectedIndex || 0;

          if( index > -1 ) {
            tmp[index] = {...formik.values};

            tmp[index].detailSeq = response.data.data?.detailSeq;
            tmp[index].cooperationCompany = formik.values.cooperationCompany?.join();
            tmp[index].orderCompanyId = params.orderCompanyId;
            tmp[index].builderCompanyId = params.builderCompanyId;
            tmp[index].pointOut = params.pointOut;
            tmp[index].purposeRegion = params.purposeRegion;
            tmp[index].isConfirm = isConfirm;
            tmp[index].isFile = params.isFile;
          }

          if( props.setSelected && index > -1) {
            props.setSelected(tmp[index]);
          }

          if( props.setList ) {
            props.setList(tmp);
          }

        }

      } else {
        alertMsg(response.data.message || '서버와의 통신에 실패하였습니다.', "E", MESSAGE_DELAY);
      }
    }));
  }

  /***************************************************************************************
   * 설명 : 신기술 초기화
  ***************************************************************************************/
  const newTechInit = () => {
    let tmp = String(formik.values.newTechCode || '');
    let tmp1 = tmp.split(/,/gi);

    let node = props.newTech.filter((item) => {
      let tmp2 = tmp1.filter((subItem) => {
        return parseInt(subItem) === parseInt(item.commonCode)
      });

      if( tmp2.length > 0 ) return true;
      else return false;
    });

    setNewTechList(node);
  }

  /*******************************************************************************
    설  명 : 규모 정보 가져오기
  *******************************************************************************/
  const getScaleList = () => {
    let params = {
      projectNumber: props.selected?.projectNumber
    }

    getProjectScaleListApi(getApi, params, (response) => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        setScaleArrayList(response.data.data);
      } else {
        setScaleArrayList([]);
      }
    });
  }


  /***************************************************************************************
   * 설명 : 프로젝트 선택 시 대표 이미지 가져오기
  ***************************************************************************************/
  const getImageList = () => {
    let params = {
      projectNumber: props.selected?.projectNumber
    }

    getProjectImageListApi(getApi, params, (response) => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        setPreviewList(response.data.data);

        if( response.data.data1 )
          setPathInfo(response.data.data1);

      } else {
        setPreviewList([]);
        setPathInfo({
          totalDirectoryCount: 0,
          totalFileCount: 0,
          totalSize: 0
        });
      }
    });
  }

  /***************************************************************************************
   * 설명 : 프로젝트 선택 시 처리
  ***************************************************************************************/
  useEffect(() => {
    if( props.tabIndex === 0 && props.selected !== undefined && props.selected.seq !== undefined ) {
      formik.setValues(props.selected);

      // 추가 정보 바인딩
      let tmp = props?.order?.filter((item) => props.selected.orderCompanyId === item.value);
      if( tmp.length > 0 )
        formik.setFieldValue('orderCompanyIdName', tmp[0].companyName);
      else
        formik.setFieldValue('orderCompanyIdName', '');

      tmp = props?.pointOut?.filter((item) => props.selected.pointOut === item.value);
      if( tmp.length > 0 )
        formik.setFieldValue('pointOutName', tmp[0].commonName);
      else
        formik.setFieldValue('pointOutName', '');

      tmp = props?.purposeRegion?.filter((item) => props.selected.purposeRegion === item.value);
      if( tmp.length > 0 )
        formik.setFieldValue('purposeRegionName', tmp[0].commonName);
      else
        formik.setFieldValue('purposeRegionName', '');

      let tmp1 = props.selected.cooperationCompany?.split(/,/gi);
      if( tmp1 !== undefined ) {
        tmp = [];
        props?.partner?.forEach((item) => {
          for(let i = 0; i < tmp1.length; i++ ) {
            if( item.value === tmp1[i] ) tmp.push(item);
          }
        });
        if( tmp.length > 0 )
          formik.setFieldValue('cooperationCompanyName', tmp.join());
        else
          formik.setFieldValue('cooperationCompanyName', '');
      } else {
        formik.setFieldValue('cooperationCompanyName', '');
      }

      tmp = props.structure2.filter((item) => item.value.substr(0, 3) === props.selected.structure1);
      if( tmp.length > 0 ) {
        // setStructure2(tmp);
        formik.setFieldValue('structure2', props.selected.structure2);
      } else {
        formik.setFieldValue('structure2', '');
        // setStructure2([{label: '해당없음', value: ''}]);
      }

      // 신기술 처리
      newTechInit();

      // 규모정보 가져오기
      getScaleList();

      // 참여업체 정보 리스트 가져오기
      getOwnershipList();

      // 협력업체 정보 리스트 가져오기
      getPartnerList();

      // 협업해외사 정보 리스트 가져오기
      getForeignList();

      // 수상 정보 리스트 가져오기
      getAwardInfoList();

      // 대표 이미지 파일 가져오기
      getImageList();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.tabIndex, props.selected]);

  /***************************************************************************************
   * 설명 : 신기술 변경 시 리스트 표시
  ***************************************************************************************/
  useEffect(() => {
    if( props.tabIndex === 0 && props.newTech?.length > 0 ) {
      // 신기술 처리
      newTechInit();
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.newTechCode]);

  /***************************************************************************************
   * 설명 : 용도 1단계 데이터 로딩 시 첫번째 자동 선택
  ***************************************************************************************/
  useEffect(() => {
    if( props.tabIndex === 0 ) {
      // if( props.purpose1.length > 0 )
        // formik.setFieldValue('purpose1', props.purpose1[0].value);

      // if( props.builderType.length > 0 )
        // formik.setFieldValue('builderType', props.builderType[0].value);

      // if( props.pointOut.length > 0 )
        // formik.setFieldValue('pointOut', props.pointOut[0].value);

      // if( props.purposeRegion.length > 0 )
        // formik.setFieldValue('purposeRegion', props.purposeRegion[0].value);

      /*
      if( props.structure1.length > 0 ) {
        formik.setFieldValue('structure1', props.structure1[0].value);

        let tmp = props.structure2.filter((item) => item.value.substr(0, 3) === formik.values.structure1);
        if( tmp.length > 0 ) {
          formik.setFieldValue('structure2', formik.values.structure2);
          setStructure2(tmp);
        } else {
          formik.setFieldValue('structure2', '');
          setStructure2([{label: '해당없음', value: ''}]);
        }
      }
      */
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.purpose1, props.builderType, props.pointOut,
    props.purposeRegion, props.structure
  ]);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <section className="project-info">
      <form onSubmit={formik.handleSubmit}>
        <section className="no-permission hidden">
          <div className="infoTxt">
            프로젝트 정보를 조회할 권한이 없습니다.<br/>
            프로젝트 조회를 원할 경우 [열람신청]을 하시기 바랍니다.
          </div>
        </section>

        <section className="">
          {/* <header className="mt20">
            <div className="clearfix">
              <div className="fl">
                <ChipEx color="warning" variant="variant" size="small" label="준비" />
              </div>
              <div className="fl">
                <div className="pl10 bold">행복도시 2-4생활권 P1주상복합 신축공사 실시설계</div>
                <div className="bigo pl10 mt5">2023. 프로젝트구분</div>
              </div>
            </div>
          </header> */}

          <header className="project-header">
            <div className="fl">
              <span className="bold">[{formik.values.projectNumber}]</span>
            </div>
            <div className="fl ml5">
              <span className="bold">{formik.values.projectName}</span>
            </div>
          </header>

          <table className="input-table mb15">
            <colgroup>
              <col style={{width: '8%'}} />
              <col style={{width: '17%'}} />
              <col style={{width: '8%'}} />
              <col style={{width: '17%'}} />
              <col style={{width: '8%'}} />
              <col style={{width: '17%'}} />
              <col style={{width: '8%'}} />
              <col style={{width: '17%'}} />
            </colgroup>

            <tbody>
              <tr>
                <th scope="row"><label htmlFor="projectDivision">구분</label></th>
                <td className="pl10">
                  {formik.values.projectDivisionName}
                </td>
                <th scope="row"><label htmlFor="projectYear">년도</label></th>
                <td className="pl10">
                  {formik.values.projectYear}
                </td>
                <th scope="row"><label htmlFor="projectNumber">PJ.NO</label></th>
                <td className="pl10">
                  {formik.values.projectNumber}
                </td>
                <th scope="row"><label htmlFor="projectStatusName">PJ 상태</label></th>
                <td className="pl10">
                  {formik.values.projectStatusName}
                </td>
              </tr>
              <tr>
                <th scope="row"><label htmlFor="projectName">PJ명</label></th>
                <td colSpan={3} className="pl10">
                  {formik.values.projectName}
                </td>
                <th scope="row"><label htmlFor="projectPmName">담당PM</label></th>
                <td className="pl10">
                  {formik.values.projectPmName}
                </td>
                <th scope="row"><label htmlFor="projectApmName">담당APM</label></th>
                <td className="pl10">
                  {formik.values.projectApmName}
                </td>
              </tr>
              <tr>
                <th scope="row"><label htmlFor="projectTypeName">유형</label></th>
                <td className="pl10">
                  {formik.values.projectTypeName}
                </td>
                <th scope="row"><label htmlFor="useSeal">사용인감</label></th>
                <td className="pl10">
                  {formik.values.useSeal}
                </td>
                <th scope="row"><label htmlFor="projectTaxRate">과세/면세</label></th>
                <td className="pl10">
                  {formik.values.projectTaxRate}
                  {(formik.values.projectTaxRate || '' ) !== '' && '%'}
                </td>
                <th scope="row"><label htmlFor="">실행계획변경</label></th>
                <td className="pl10">
                </td>
              </tr>
              <tr>
                <th scope="row"><label htmlFor="indirectCostRate">간접비(%)</label></th>
                <td className="pl10">
                  {formik.values.indirectCostRate}
                </td>
                <th scope="row"><label htmlFor="partialIndirectCostRate">부분간접비(%)</label></th>
                <td className="pl10">
                  {formik.values.partialIndirectCostRate}
                </td>
                <th scope="row"><label htmlFor="totalIndirectionCostRate">전사간접비(%)</label></th>
                <td className="pl10">
                  {formik.values.totalIndirectionCostRate}
                </td>
                <th scope="row"><label htmlFor="">항목</label></th>
                <td className="pl10">
                </td>
              </tr>
              <tr>
                <th scope="row"><label htmlFor="departmentName">주관부서</label></th>
                <td className="pl10">
                  {formik.values.departmentName}
                </td>
                <th scope="row"><label htmlFor="departmentName2">부서2</label></th>
                <td className="pl10">
                  {formik.values.departmentName2}
                </td>
                <th scope="row"><label htmlFor="departmentName3">부서3</label></th>
                <td className="pl10">
                  {formik.values.departmentName3}
                </td>
                <th scope="row"><label htmlFor="departmentName4">부서4</label></th>
                <td className="pl10">
                  {formik.values.departmentName4}
                </td>
              </tr>
              <tr>
                <th scope="row"><label htmlFor="departmentRate">지분율(%)</label></th>
                <td className="pl10">
                  {formik.values.departmentRate}
                  {(formik.values.departmentRate || '') !== '' && '%'}
                </td>
                <th scope="row"><label htmlFor="departmentRate2">지분율(%)</label></th>
                <td className="pl10">
                  {formik.values.departmentRate2}
                  {(formik.values.departmentRate2 || '') !== '' && '%'}
                </td>
                <th scope="row"><label htmlFor="departmentRate3">지분율(%)</label></th>
                <td className="pl10">
                  {formik.values.departmentRate3}
                  {(formik.values.departmentRate3 || '') !== '' && '%'}
                </td>
                <th scope="row"><label htmlFor="departmentRate4">지분율(%)</label></th>
                <td className="pl10">
                  {formik.values.departmentRate4}
                  {(formik.values.departmentRate4 || '') !== '' && '%'}
                </td>
              </tr>
              <tr>
                <th scope="row"><label htmlFor="builderName">건축주</label></th>
                <td className="pl10">
                  {formik.values.builderName}
                </td>
                <th scope="row"><label htmlFor="builderCharge">담당(건축주)</label></th>
                <td className="pl10">
                  {formik.values.builderCharge}
                </td>
                <th scope="row"><label htmlFor="builderTel">전화(건축주)</label></th>
                <td className="pl10">
                  {formik.values.builderTel}
                </td>
                <th scope="row"><label htmlFor="builderFax">FAX(건축주)</label></th>
                <td className="pl10">
                  {formik.values.builderFax}
                </td>
              </tr>
              <tr>
                <th scope="row"><label htmlFor="builderAddress">주소(건축주)</label></th>
                <td colSpan={7} className="pl10">
                  {formik.values.builderAddress}
                </td>
              </tr>
              <tr>
                <th scope="row"><label htmlFor="location">위치</label></th>
                <td colSpan={3} className="pl10">
                  {formik.values.location}
                </td>
                <th scope="row"><label htmlFor="scale">규모</label></th>
                <td colSpan={3} className="pl10">
                  {formik.values.scale}
                </td>
              </tr>
              <tr>
                <th scope="row"><label htmlFor="purpose">용도</label></th>
                <td colSpan={3} className="pl10">
                  {formik.values.purpose}
                </td>
                <th scope="row"><label htmlFor="structure">구조</label></th>
                <td colSpan={3} className="pl10">
                  {formik.values.structure}
                </td>
              </tr>
              <tr>
                <th scope="row"><label htmlFor="landSize">대지면적(㎡)</label></th>
                <td className="pl10">
                  {formik.values.landSize}
                </td>
                <th scope="row"><label htmlFor="buildSize">건축면적(㎡)</label></th>
                <td className="pl10">
                  {formik.values.buildSize}
                </td>
                <th scope="row"><label htmlFor="totalSize">연면적(㎡)</label></th>
                <td className="pl10">
                  {formik.values.totalSize}
                </td>
                <th scope="row"><label htmlFor="treeSize">조경면적(㎡)</label></th>
                <td className="pl10">
                  {formik.values.treeSize}
                </td>
              </tr>
              <tr>
                <th scope="row"><label htmlFor="bigo">비고</label></th>
                <td colSpan={7} className="pl10">
                  {formik.values.bigo}
                </td>
              </tr>
            </tbody>
          </table>

          {
            (
              parseInt(formik.values.readingProject) !== 1 &&
              (
                ( parseInt(formik.values.isApplicationView) === 3 && parseInt(formik.values.status) !== 2 ) ||
                formik.values.isApplicationView === null
              )
            ) &&
            <section className="mt10 right">
              <Button
                variant="outlined"
                color="secondary"
                className="Btn"
                onClick={() => setOpenModal({open: true, modal: 'addInfoRequest', data: {}})}
              >추가정보 열람신청</Button>
            </section>
          }

          {
            (
              parseInt(formik.values.readingProject) === 1 ||
              ( parseInt(formik.values.isApplicationView) === 3 && parseInt(formik.values.status) === 2 )
            ) &&
            <>
              <h4 className="mt20 mb10">
                프로젝트 추가정보
                { parseInt(formik.values.isApplicationView) === 3 &&
                  <span className="ml10">
                    {parseInt(formik.values.status) === 1 &&
                      <ChipEx color="success" variant="outlined" size="small" label="추가정보 열람신청" />
                    }
                    {parseInt(formik.values.status) === 2 &&
                      <>
                        <ChipEx color="primary" variant="outlined" size="small" label="추가정보 열람승인" />
                        {parseInt(formik.values.isDate) === 1 &&
                          <span className="ml10 f13 normal">기간제한 없음</span>
                        }

                        {parseInt(formik.values.isDate) === 0 &&
                          <span className="ml10 f13 normal">({formik.values.approvalStartDate + ' ~ ' + formik.values.approvalEndDate})</span>
                        }
                      </>
                    }
                  </span>
                }
              </h4>
              <table className="input-table mb15">
                <colgroup>
                  <col style={{width: '8%'}} />
                  <col style={{width: '17%'}} />
                  <col style={{width: '8%'}} />
                  <col style={{width: '17%'}} />
                  <col style={{width: '8%'}} />
                  <col style={{width: '17%'}} />
                  <col style={{width: '8%'}} />
                  <col style={{width: '17%'}} />
                </colgroup>

                <tbody>
                  <tr>
                    <th scope="row"><label htmlFor="businessType">사업유형</label></th>
                    <td className="pl10">
                      {formik.values.businessTypeName}
                    </td>
                    <th scope="row"><label htmlFor="builderType">건축주유형</label></th>
                    <td className="pl10">
                      {formik.values.builderTypeName}
                    </td>
                    <th scope="row"><label htmlFor="orderCompanyId">발주처</label></th>
                    <td className="pl10">
                      {formik.values.orderCompanyIdName}
                    </td>
                    <th scope="row"><label htmlFor="builderCompanyIdName">시공사</label></th>
                    <td>
                      {formik.values.builderCompanyIdName}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row"><label htmlFor="purpose1">용도</label></th>
                    <td colSpan={3} className="pl10">
                      {formik.values.purpose1}
                    </td>
                    <th scope="row"><label htmlFor="purposeExtra">용도기타</label></th>
                    <td colSpan={3} className="pl10">
                      {formik.values.purposeExtra}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row"><label htmlFor="purposeRegion">용도지역</label></th>
                    <td className="pl10" colSpan={3}>
                      {formik.values.purposeRegion}
                    </td>
                    <th scope="row"><label htmlFor="pointOut">지목</label></th>
                    <td className="pl10">
                      {formik.values.pointOut}
                    </td>
                  </tr>
                  {/*
                  <tr>
                    <th scope="row"><label htmlFor="doroAddress">도로명 주소</label></th>
                    <td colSpan={3} className="pl10">
                      {formik.values.doroAddress}
                    </td>
                    <th scope="row"><label htmlFor="doroAddress">도로명 상세 주소</label></th>
                    <td colSpan={3} className="pl10">
                      {formik.values.doroAddressDetail}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row"><label htmlFor="parcelAddress">지번</label></th>
                    <td colSpan={3} className="pl10">
                      {formik.values.parcelAddress}
                    </td>
                  </tr>
                  */}
                  <tr>
                    <th scope="row"><label htmlFor="hashTag">키워드</label></th>
                    <td colSpan={3} className="pl10">
                      {formik.values.hashTag}
                    </td>
                    <th scope="row"><label htmlFor="responsibilityConstructor">책임건축사</label></th>
                    <td colSpan={3} className="pl10">
                      {formik.values.responsibilityConstructor}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row"><label htmlFor="departmentNameLast">주관부서</label></th>
                    <td colSpan={3} className="pl10">
                      {formik.values.departmentNameLast}
                    </td>
                    <th scope="row"><label htmlFor="readingGrade">기본열람등급</label></th>
                    <td colSpan={3} className="pl10">
                      {
                        formik.values.readingGrade === 0 ? '전체열람'
                        : formik.values.readingGrade === 1 ? '목록열람'
                        : formik.values.readingGrade === 2 ? '열람불가' : ''
                      }
                    </td>
                  </tr>
                  <tr>
                  <th scope="row"><label htmlFor="projectPmNameLast">담당PM</label></th>
                    <td colSpan={3} className="pl10">
                      {formik.values.projectPmNameLast}
                    </td>
                    <th scope="row"><label htmlFor="projectApmNameLast">담당APM</label></th>
                    <td colSpan={3} className="pl10">
                      {formik.values.projectApmNameLast}
                    </td>
                  </tr>
                  {/*
                  <tr>
                    <th scope="row"><label htmlFor="underLayer">층수(지하)</label></th>
                    <td className="pl10">
                      {formik.values.underLayer}
                      {formik.values.underLayer && '층'}
                    </td>
                    <th scope="row"><label htmlFor="groundLayer">층수(지상)</label></th>
                    <td className="pl10">
                      {formik.values.groundLayer}
                      {formik.values.groundLayer && '층'}
                    </td>
                    <th scope="row"><label htmlFor="numberHouse">세대수</label></th>
                    <td className="pl10">
                      {formik.values.numberHouse}
                      {formik.values.numberHouse && '세대'}
                    </td>
                    <th scope="row"><label htmlFor="scaleExtra">규모(기타)</label></th>
                    <td className="pl10">
                      {formik.values.scaleExtra}
                    </td>
                  </tr>
                  */}
                  <tr>
                    <th scope="row"><label htmlFor="structure1">구조</label></th>
                    <td className="pl10" colSpan={3}>
                      {formik.values.structure1}
                    </td>
                    <th scope="row"><label htmlFor="structureExtra">구조(추가정보)</label></th>
                    <td colSpan={3} className="pl10">
                      {formik.values.structureExtra}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row"><label htmlFor="partMember">참여구성원</label></th>
                    <td colSpan={7}>
                      <div style={{height: '90px', padding: '10px'}}>
                        {formik.values.partMember}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row"><label htmlFor="relationProject">연관프로젝트</label></th>
                    <td colSpan={7}>
                      <div style={{height: '90px', padding: '10px'}}>
                        {formik.values.relationProject}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row"><label htmlFor="memo">비고</label></th>
                    <td colSpan={7}>
                      <div style={{height: '90px', padding: '10px'}}>
                        {formik.values.memo}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>

              {/* 규모 정보 */}
              <section>
                <header className="admin-cms-search-header">
                  <div className="left">
                    <div className="fl">
                      <BtnAgGridSave className="fl" click={setGridUpdateScale}></BtnAgGridSave>
                    </div>
                    <div className="fl">
                      <h4 className="f15 sub-title">규모 정보</h4>
                    </div>
                  </div>

                  <div className="text-right">
                  </div>
                  <div className="clearfix"></div>
                </header>

                <div className="ag-theme-balham mt5" style={{height: '200px'}}>
                  <AgGridReact
                    defaultColDef={{
                      sortable: true,
                      resizable: true,
                      filter: false,
                      lockVisible: true,
                      tooltipComponent: customTooltipAgGrid,
                    }}
                    tooltipShowDelay={0}
                    tooltipHideDelay={2000}
                    columnDefs={columnDefsScale}
                    rowData={scaleArrayList}
                    rowSelection={'single'}
                    onGridReady={(event) => {
                      gridConfigScale(event.columnApi);
                    }}
                    overlayNoRowsTemplate = "검색된 내용이 없습니다."
                  />
                </div>
              </section>


              {/* 신기술 정보 */}
              <section className="mt10">
                <header className="admin-cms-search-header">
                  <div className="left">
                    <div className="fl">
                      <BtnAgGridSave className="fl" click={setGridUpdateNewTech}></BtnAgGridSave>
                    </div>
                    <div className="fl">
                      <h4 className="f15 sub-title">신기술 정보</h4>
                    </div>
                  </div>

                  <div className="text-right">
                  </div>
                  <div className="clearfix"></div>
                </header>

                <div className="ag-theme-balham mt5" style={{height: '200px'}}>
                  <AgGridReact
                    defaultColDef={{
                      sortable: true,
                      resizable: true,
                      filter: false,
                      lockVisible: true,
                      tooltipComponent: customTooltipAgGrid,
                    }}
                    tooltipShowDelay={0}
                    tooltipHideDelay={2000}
                    columnDefs={columnDefsNewTech}
                    rowData={newTechList}
                    rowSelection={'single'}
                    onGridReady={(event) => {
                      gridConfigNewTech(event.columnApi);
                    }}
                    overlayNoRowsTemplate = "검색된 내용이 없습니다."
                  />
                </div>
              </section>

              {/* 참여업체 정보 */}
              <section className="mt10">
                <header className="admin-cms-search-header">
                  <div className="left">
                    <div className="fl">
                      <BtnAgGridSave className="fl" click={setGridUpdate}></BtnAgGridSave>
                    </div>
                    <div className="fl">
                      <h4 className="f15 sub-title">참여업체 정보</h4>
                    </div>
                  </div>

                  <div className="text-right">
                  </div>
                  <div className="clearfix"></div>
                </header>

                <div className="ag-theme-balham mt5" style={{height: '200px'}}>
                  <AgGridReact
                    defaultColDef={{
                      sortable: true,
                      resizable: true,
                      filter: false,
                      lockVisible: true,
                      tooltipComponent: customTooltipAgGrid,
                    }}
                    tooltipShowDelay={0}
                    tooltipHideDelay={2000}
                    columnDefs={columnDefs}
                    rowData={ownershipList}
                    rowSelection={'single'}
                    onGridReady={(event) => {
                      gridConfig(event.columnApi);
                    }}
                    overlayNoRowsTemplate = "검색된 내용이 없습니다."
                  />
                </div>

                <table className="input-table mb15">
                  <colgroup>
                    <col style={{width: '10%'}} />
                    <col style={{width: '90%'}} />
                  </colgroup>

                  <tbody>
                    <tr>
                      <th scope="row"><label htmlFor="ownershipMemo">참여업체 특이사항</label></th>
                      <td className="p10" style={{height: '100px', verticalAlign: 'top', overflowY: 'auto'}}>
                        {formik.values.ownershipMemo}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </section>

              {/* 협력업체 정보 */}
              <section className="mt10">
                <header className="admin-cms-search-header">
                  <div className="left">
                    <div className="fl">
                      <BtnAgGridSave className="fl" click={setGridUpdatePartner}></BtnAgGridSave>
                    </div>
                    <div className="fl">
                      <h4 className="f15 sub-title">협력업체 정보</h4>
                    </div>
                  </div>

                  <div className="text-right">
                  </div>
                  <div className="clearfix"></div>
                </header>

                <div className="ag-theme-balham mt5" style={{height: '200px'}}>
                  <AgGridReact
                    defaultColDef={{
                      sortable: true,
                      resizable: true,
                      filter: false,
                      lockVisible: true,
                      tooltipComponent: customTooltipAgGrid,
                    }}
                    tooltipShowDelay={0}
                    tooltipHideDelay={2000}
                    columnDefs={columnDefsPartner}
                    rowData={partnerList}
                    rowSelection={'single'}
                    onGridReady={(event) => {
                      gridConfigPartner(event.columnApi);
                    }}
                    overlayNoRowsTemplate = "검색된 내용이 없습니다."
                  />
                </div>
                <table className="input-table mb15">
                  <colgroup>
                    <col style={{width: '10%'}} />
                    <col style={{width: '90%'}} />
                  </colgroup>

                  <tbody>
                    <tr>
                      <th scope="row"><label htmlFor="partnerMemo">협력업체 특이사항</label></th>
                      <td className="p10" style={{height: '100px', verticalAlign: 'top', overflowY: 'auto'}}>
                        {formik.values.partnerMemo}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </section>

              {/* 협업해외사 정보 */}
              <section className="mt10">
                <header className="admin-cms-search-header">
                  <div className="left">
                    <div className="fl">
                      <BtnAgGridSave className="fl" click={setGridUpdateForeign}></BtnAgGridSave>
                    </div>
                    <div className="fl">
                      <h4 className="f15 sub-title">협업해외사 정보</h4>
                    </div>
                  </div>

                  <div className="text-right">
                  </div>
                  <div className="clearfix"></div>
                </header>

                <div className="ag-theme-balham mt5" style={{height: '200px'}}>
                  <AgGridReact
                    defaultColDef={{
                      sortable: true,
                      resizable: true,
                      filter: false,
                      lockVisible: true,
                      tooltipComponent: customTooltipAgGrid,
                    }}
                    tooltipShowDelay={0}
                    tooltipHideDelay={2000}
                    columnDefs={columnDefsForeign}
                    rowData={foreignList}
                    rowSelection={'single'}
                    onGridReady={(event) => {
                      gridConfigForeign(event.columnApi);
                    }}
                    overlayNoRowsTemplate = "검색된 내용이 없습니다."
                  />
                </div>
                <table className="input-table mb15">
                  <colgroup>
                    <col style={{width: '10%'}} />
                    <col style={{width: '90%'}} />
                  </colgroup>

                  <tbody>
                    <tr>
                      <th scope="row"><label htmlFor="foreignMemo">협업해외사 특이사항</label></th>
                      <td className="p10" style={{height: '100px', verticalAlign: 'top', overflowY: 'auto'}}>
                        {formik.values.foreignMemo}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </section>

              {/* 수상정보 */}
              <section className="mt10">
                <header className="admin-cms-search-header">
                  <div className="left">
                    <div className="fl">
                      <BtnAgGridSave className="fl" click={setGridUpdateAward}></BtnAgGridSave>
                    </div>
                    <div className="fl">
                      <h4 className="f15 sub-title">수상정보</h4>
                    </div>
                  </div>

                  <div className="text-right">
                  </div>
                  <div className="clearfix"></div>
                </header>

                <div className="ag-theme-balham mt5" style={{height: '200px'}}>
                  <AgGridReact
                    defaultColDef={{
                      sortable: true,
                      resizable: true,
                      filter: false,
                      lockVisible: true,
                      tooltipComponent: customTooltipAgGrid,
                    }}
                    tooltipShowDelay={0}
                    tooltipHideDelay={2000}
                    columnDefs={columnDefsAward}
                    rowData={awardInfoList}
                    rowSelection={'single'}
                    onGridReady={(event) => {
                      gridConfigAward(event.columnApi);
                    }}
                    onRowDoubleClicked={(event) => {
                      setOpenModal({
                        open: true,
                        modal: 'awardAdd',
                        data: event.data
                      })
                    }}
                    overlayNoRowsTemplate = "검색된 내용이 없습니다."
                  />
                </div>
              </section>


              <footer className="mt10 mb30">
                <div className="fr right">
                </div>

                <div className="clearfix"></div>
              </footer>

              <section className="mb80 mt20">
                <header className="clearfix mb5">
                  <h4 className="fl f14">대표 파일정보 (최대 100개 표시)</h4>
                  <div className="fr f14">전체: {comma(pathInfo.totalFileCount)}개 전체용량: {formatBytes(pathInfo.totalSize)}</div>
                </header>

                <div className="fileBox">
                  {previewList?.length > 0 &&
                    <ul>
                      { previewList.map((item) => {
                        let url = 'http://archive.dagroup.co.kr/synap/SynapDocViewServer/thumbnail/'
                        + item.thumbnail + '/0?dpi=L&withXml=false&resizeType=M&fileExt=jpg&resizeValue=210&quality=80';

                        return (
                          <>
                            <li>
                              <div className="img">
                              { // 이미지 파일 미리보기
                                IMAGE_FILE.includes(item.ext?.toLowerCase()) &&
                                <img src={"/api/v1/project/image/download?thumbnail=" + base64_encode(item.thumbnail)} alt={item.fileName}
                                  onError={(e) => {
                                    e.target.src = NoImage;
                                  }}
                                />
                              }

                              { // 동영상 파일 미리보기
                                MOVIE_FILE.includes(item.ext?.toLowerCase()) &&
                                <img src={"/api/v1/project/image/download?thumbnail=" + base64_encode(item.thumbnail)} alt={item.fileName}
                                  onError={(e) => {
                                    e.target.src = NoImage;
                                  }}
                                />
                              }

                              { // 기타 파일 미리보기
                                ! IMAGE_FILE.includes(item.ext?.toLowerCase()) && ! MOVIE_FILE.includes(item.ext?.toLowerCase()) &&
                                <img
                                  src={url}
                                  alt={item.fileName}
                                  onError={(e) => {
                                    e.target.src = NoImage;
                                  }}
                                />
                              }
                              </div>
                              <div className="name">{item.fileName}</div>
                              <div className="size">{formatBytes(item.fileSize)}</div>
                              <div className="hover-wrap">
                                <Button
                                  variant="contained"
                                  color="primary"
                                  className="Btn"
                                  onClick={() => {
                                    let url = 'http://archive.dagroup.co.kr/synap/SynapDocViewServer/job?fileType=Local&sync=true&fid=' + item.seq + '&filePath=';
                                    window.open(url + encodeURIComponent(item.destPath))
                                    //setOpenModal({open: true, modal: 'previewFile', data: item})
                                  }}
                                >미리보기</Button>
                                <div className="darkness"></div>
                              </div>
                            </li>
                          </>
                        )
                      })}
                    </ul>
                  }

                  {previewList?.length < 1 &&
                    <div style={{height: '190px', display: 'flex', alignItems: 'center'}}>
                      <div style={{width: '100%', textAlign: 'center'}}>등록된 미리보기 파일이 없습니다.</div>
                    </div>
                  }
                </div>
              </section>
            </>
          }

          { // 이미지 미리 보기 모달
            openModal.open && openModal.modal === 'previewFile' &&
            <Dialog
              open={openModal}
              onClose={() => (event, reason) => {
                if (reason && reason === "backdropClick") return;
                handleCloseModal();
              }}
              PaperComponent={PaperComponent}
              aria-labelledby="draggable-dialog-title"
              sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "1000px",  // Set your width here
                  },
                },
              }}
            >
              <section className="modal">
                <ImagePreviewModal
                  open={openModal.open}
                  close={() => setOpenModal({ open: false, modalName: openModal.modal, data: openModal.data })}
                  data={openModal.data}
                  formik={formik}
                  indicator={props.indicator}
                  history={props.history}
                />
              </section>
            </Dialog>
          }

          { openModal.open && openModal.modal === 'addInfoRequest' &&
            <Dialog
              open={openModal}
              onClose={() => (event, reason) => {
                if (reason && reason === "backdropClick") return;
                handleCloseModal();
              }}
              PaperComponent={PaperComponent}
              aria-labelledby="draggable-dialog-title"
              sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "600px",  // Set your width here
                  },
                },
              }}
            >
              <section className="modal">
                <RequestAddInfoModal
                  open={openModal.open}
                  data={props.selected}
                  formik={formik}
                  close={() => setOpenModal({ open: false, modalName: openModal.modal, data: openModal.data })}
                />
              </section>
            </Dialog>
          }
        </section>
      </form>
    </section>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default TabUserProjectInfo;