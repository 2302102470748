/*****************************************************************************************
 * 설명 : 메시지 다이얼로그
*****************************************************************************************/
import React, { useState } from 'react';

import styled from 'styled-components';
import Button from '@mui/material/Button';

const ModalBody = styled.section`
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  line-height: 1.5
`;

/*****************************************************************************************
 * 설명 : 함수 선언
 * 입력값 : title = 타이틀
 *          message = 표시할 메시지
 *          button = 표시할 버튼
 *          width = 표시할 가로 사이즈
 *          height = 모달 컨텐츠 영역 사이즈
*****************************************************************************************/
const AlertDialog = (props) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [width] = useState(props.width ? props.width : '500px');

  const handleCloseModal = () => {
    props.close();
  };

  const onClick = () => {
    if( props.onClick ) props.onClick();
    props.close();
  }

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <section className="modal">
      <section className="center-add modal-wrap" style={{width: width, minHeight: '250px'}}>
        <header className="modal-header" style={{ cursor: 'move' }} id="draggable-dialog-title-sub">
          <div className="modal-title">{props.title}</div>
          <Button className="close-btn" onClick={handleCloseModal}>×</Button>
        </header>

        <ModalBody className="modal-body" style={{minHeight: '150px'}}>
          {props.isHtml === undefined &&
            props.message
          }
          {props.isHtml !== undefined &&
            <div className='center' dangerouslySetInnerHTML={{__html: props.message}}></div>
          }
        </ModalBody>

        <footer className="modal-footer center">
          <div className="modal-footer-center">
            <Button
              color="inherit"
              variant="outlined"
              className="Btn"
              onClick={handleCloseModal}
            >닫기</Button>

            <Button
              type="button"
              id="btnOk"
              variant="contained"
              color={props.color || "error"}
              className="Btn ml10"
              onClick={onClick}
              autoFocus={true}
            >{props.confirm}</Button>
          </div>
        </footer>
      </section>
    </section>
  )
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default AlertDialog;