/*****************************************************************************************
 * 설명 : 사용자 통합검색 - list
 * URI : /user/TotalSearch/list
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useTheme } from '@mui/material/styles';
import * as Excel from "exceljs/dist/exceljs.min.js";
import { saveAs } from "file-saver";
import { useFormik } from 'formik';
import qs from 'qs';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';

import Restful from "service/restful";
import { getSearchApi } from 'service/search';
import { checkEng, checkHangul, checkKor, comma, formatBytes, isCheckRadio } from 'service/utils';

import { MESSAGE_DELAY } from 'config/config';

import { BtnExcelDown } from 'components/common';
import { CheckBoxEx, InputEx } from 'components/inputEx';
import alertMsg from 'components/message';

import DetailSearchBox from 'pages/layout/detailSearchBox';
import HelpInputBox from 'pages/layout/helpInputBox';
import UserLayout from 'pages/layout/userLayout';
import TabDesign from './tabDesign';
import TabFile from './tabFile';
import TabProject from './tabProject';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const SearchList = ( props ) => {

  const COLOR = ['bg-blue1', 'bg-lblue', 'bg-green', 'bg-yellow', 'bg-red'];

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [getApi] = Restful();
  const history = useNavigate();
  const location = useLocation();

  const prevQueryString = useRef('');

  // 탭 클릭 시 처리
  const [isLoading, setIsLoading] = useState(false);

  const [tabMenu, setTabMenu] = useState('');

  const [queryString, setQueryString] = useState('');
  const [designList, setDesignList] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [tagList, setTagList] = useState([]);

  const [total, setTotal] = useState({
    design: 0,
    project: 0,
    file: 0
  })

  const [isDetail, setIsDetail] = useState(false);
  const [viewHelpInputBox, setViewHelpInputBox] = useState(false);

  const theme = useTheme();

  const initialValues = {
    isReSearch: [],
    searchCheckbox1: true,
    searchCheckbox2: true,
    searchCheckbox3: true,
    designProjectType: 1,
    search: '',
    isRelation: 0,
    relationSeq: 0,
    prevDesignPageNo: 1,
    designPageNo: 1,
    designPageRow: 10,
    prevProjectPageNo: 1,
    projectPageNo: 1,
    projectPageRow: 20,
    prevFilePageNo: 1,
    filePageNo: 1,
    filePageRow: 20,
    searchText: '',
    searchTextOrg: '',
    tab: '',
    projectDivision: '',
    projectNumber: '',
    projectStartYear: '',
    projectEndYear: '',
    projectName: '',
    departmentName: '',
    projectPm: '',
    projectType: '',
    businessType: '',
    builderType: '',
    purpose: '',
    scaleUnderStart: '',
    scaleUnderEnd: '',
    scaleStart: '',
    scaleEnd: '',
    structure: '',
    builderName: '',
    orderCompanyId: '',
    builderCompanyId: '',
    location: '',
    totalSizeStart: '',
    totalSizeEnd: '',
    isNewTech: '',
    newTech: '',
    isAwardInfo: '',
    isOwnership: '',
    isCooperationCompany: '',
    fileType: '',
    responsibilityConstructor: '',
    joinCompany: '',
    rate: '',
    betterThan: 0,
  };

  const formik = useFormik({
    initialValues: initialValues,
    validateOnChange: false,
    onSubmit: (values) => {
      searchSubmit(values);
    }
  });

  // table column
  const [columnDefs] = useState([
    { headerName: '번호', field: 'index', width: 45, cellClass: 'cp text-right',
      valueGetter: (params) => {
        return comma(( params.node.rowIndex + 1 ) + ( (params.data.projectPageNo - 1) * params.data.projectPageRow ));
      }
    },
    { headerName:'구분', field: 'projectDivisionName', width: 120, cellClass: 'cp center' },
    { headerName:'PJ NO', field: 'projectNumber', width: 100, cellClass: 'cp center' },
    { headerName:'PJ 명', field: 'projectName', width: 300, cellClass: 'cp' },
    { headerName:'유형', field: 'projectTypeName', width: 100, cellClass: 'cp center' },
    { headerName:'용도', field: 'purpose', width: 150, cellClass: 'cp center' },
    { headerName:'규모', field: 'scale', width: 150, cellClass: 'cp center' },
    { headerName:'주관부서', field: 'projectDepartmentName', width: 120, cellClass: 'cp center' },
    { headerName:'담당PM', field: 'projectPmName', width: 100, cellClass: 'cp center' },
    { headerName:'건축주', field: 'builderName', width: 200, cellClass: 'cp' },
    { headerName:'발주처', field: 'orderCompanyIdName', width: 200, cellClass: 'cp' },
    { headerName:'시공사', field: 'builderCompanyIdName', width: 200, cellClass: 'cp' }
  ]);


  /***************************************************************************************
   * 설명 : 검색 처리
  ***************************************************************************************/
  const searchSubmit = (values) => {
    /*
    if( values.search === '' ) {
      alertMsg("검색어를 입력해 주시기 바랍니다.", "W", MESSAGE_DELAY);
      return;
    }
    */

    let tmp = window.localStorage.getItem('prevSearch');
    let tmp1 = [];

    if( tmp ) {
      tmp1 = JSON.parse(tmp);

      // 중복 검색어는 추가하지 않음
      let tmp2 = tmp1.filter((item) => item.search === values.search );
      if( tmp2.length < 1 )
        tmp1.unshift({ search: values.search });

    } else {
      tmp1.unshift({ search: values.search });
    }

    let tmp2 = JSON.stringify(tmp1);
    window.localStorage.setItem('prevSearch', tmp2);

    formik.setFieldValue('search', values.search);
    setViewHelpInputBox(false);
    setIsDetail( false );

    let data = {...values};

    data.searchCheckbox1 = isCheckRadio(data.searchCheckbox1);
    data.searchCheckbox2 = isCheckRadio(data.searchCheckbox2);
    data.searchCheckbox3 = isCheckRadio(data.searchCheckbox3);
    data.isReSearch = isCheckRadio(data.isReSearch);

    let uri = new URLSearchParams(data).toString();

    history("/user/search/list?" + uri);
  }

  /***************************************************************************************
   * 설명 : 데이터 검색
  ***************************************************************************************/
  const getSearchText = async () => {
    let params = {...formik.values};

    params.searchText = formik.values.search;

    if( Array.isArray(formik.values.purpose) && formik.values.purpose.length > 0 ) {
      params.purpose = formik.values.purpose.map((item) => item.label).join().trim().replace(/ㅤ/g, '');
    } else if( formik.values.purpose?.label !== undefined ) {
      params.purpose = formik.values.purpose?.label?.trim()?.replace(/ㅤ/g, '');
    } else if( formik.values.purpose?.label !== '' ) {
      params.purpose = params.purpose?.trim()?.replace(/ㅤ/g, '');
    }

    if( Array.isArray(formik.values.structure) && formik.values.structure.length > 0 ) {
      params.structure = formik.values.structure.map((item) => item.value).join().trim().replace(/ㅤ/g, '');
    } else if( formik.values.structure?.value !== undefined ) {
      params.structure = formik.values.structure?.value?.trim().replace(/ㅤ/g, '');
    } else if( formik.values.structure?.value !== '' ) {
      params.structure = params.structure?.trim()?.replace(/ㅤ/g, '');
    }

    params.scaleStart = ( params.scaleStart !== '' ) ? parseInt(params.scaleStart) : '';
    params.scaleEnd = ( params.scaleEnd !== '' ) ? parseInt(params.scaleEnd) : '';
    params.scaleUnderStart = ( params.scaleUnderStart !== '' ) ? parseInt(params.scaleUnderStart) : '';
    params.scaleUnderEnd = ( params.scaleUnderEnd !== '' ) ? parseInt(params.scaleUnderEnd) : '';

    params.searchCheckbox1 = isCheckRadio(formik.values.searchCheckbox1, false);
    params.searchCheckbox2 = isCheckRadio(formik.values.searchCheckbox2, false);
    params.searchCheckbox3 = isCheckRadio(formik.values.searchCheckbox3, false);
    params.isReSearch = isCheckRadio(formik.values.isReSearch, false);

    if( params.isReSearch && params.searchText?.trim() !== '') {
      params.searchTextOrg = params.searchTextOrg + ' +' + params.searchText;
    } else {
      params.searchTextOrg = params.searchText;
    }


    await getSearchApi(getApi, params, (response) => {
      if( response !== undefined && response.data.result ) {
        // 디자인 리스트
        if( response.data.data && response.data.data.length > 0 )
          setDesignList(response.data.data);
        else
          setDesignList([]);

        // 프로젝트 리스트
        if( response.data.data1 && response.data.data1.length > 0 )
          setProjectList([
            ...response.data.data1.map((item) => {
              return {
                ...item,
                designPageNo: formik.values.designPageNo,
                designPageRow: formik.values.designPageRow,
                projectPageNo: formik.values.projectPageNo,
                projectPageRow: formik.values.projectPageRow,
                filePageNo: formik.values.filePageNo,
                filePageRow: formik.values.filePageRow,
              }
            })
          ]);
        else
          setProjectList([]);

        // 파일 리스트
        if( response.data.data2 && response.data.data2.length > 0 )
          setFileList([
            ...response.data.data2.map((item) => {
              return {
                ...item,
                designPageNo: formik.values.designPageNo,
                designPageRow: formik.values.designPageRow,
                projectPageNo: formik.values.projectPageNo,
                projectPageRow: formik.values.projectPageRow,
                filePageNo: formik.values.filePageNo,
                filePageRow: formik.values.filePageRow,
              }
            })
          ]);
        else
          setFileList([]);

        // 연관 태그
        if( response.data.data3 && response.data.data3.length > 0 )
          setTagList(response.data.data3);
        else
          setTagList([]);

        // 전체 개수
        setTotal(response.data.data4);

        formik.setFieldValue('prevDesignPageNo', formik.values.designPageNo);
        formik.setFieldValue('prevProjectPageNo', formik.values.projectPageNo);
        formik.setFieldValue('prevFilePageNo', formik.values.filePageNo);

        setIsLoading(true);

      } else {
        setDesignList([]);
        setProjectList([]);
        setFileList([]);
        setTagList([]);

        setTotal({
          design: 0,
          project: 0,
          file: 0
        });

        setIsLoading(true);
      }
    })
  }

  const styleHeaderCell = (cell) => {
    cell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "ffebebeb" },
    };
    cell.border = {
      top: { style: "thin", color: { argb: "-100000f" } },
      bottom: { style: "thin", color: { argb: "-100000f" } },
      right: { style: "thin", color: { argb: "-100000f" } },
    };
    cell.font = {
      name: "Arial",
      size: 12,
      bold: true,
      color: { argb: "ff252525" },
    };
    cell.alignment = {
      vertical: "middle",
      horizontal: "center",
      wrapText: true,
    };
  };

  const styleDataCell = (cell, align) => {
    /*
    cell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "ffffffff" },
    };
    /*
    cell.border = {
      //bottom: { style: "thin", color: { argb: "-100000f" } },
      //right: { style: "thin", color: { argb: "-100000f" } },
    };
    */
    cell.font = {
      name: "Arial",
      size: 10,
      color: { argb: "ff252525" },
    };
    cell.alignment = {
      vertical: "middle",
      horizontal: align,
      wrapText: true,
    };
  };

  /***************************************************************************************
   * 설명 : 데이터 검색
  ***************************************************************************************/
  const getSearchTextExcel = async () => {
    let params = {...formik.values};

    params.searchText = formik.values.search;

    if( Array.isArray(formik.values.purpose) && formik.values.purpose.length > 0 ) {
      params.purpose = formik.values.purpose.map((item) => item.label).join().trim().replace(/ㅤ/g, '');
    } else if( formik.values.purpose?.label !== undefined ) {
      params.purpose = formik.values.purpose?.label?.trim()?.replace(/ㅤ/g, '');
    } else if( formik.values.purpose?.label !== '' ) {
      params.purpose = params.purpose?.trim()?.replace(/ㅤ/g, '');
    }

    if( Array.isArray(formik.values.structure) && formik.values.structure.length > 0 ) {
      params.structure = formik.values.structure.map((item) => item.value).join().trim().replace(/ㅤ/g, '');
    } else if( formik.values.structure?.value !== undefined ) {
      params.structure = formik.values.structure?.value?.trim().replace(/ㅤ/g, '');
    } else if( formik.values.structure?.value !== '' ) {
      params.structure = params.structure?.trim()?.replace(/ㅤ/g, '');
    }

    params.scaleStart = ( params.scaleStart !== '' ) ? parseInt(params.scaleStart) : '';
    params.scaleEnd = ( params.scaleEnd !== '' ) ? parseInt(params.scaleEnd) : '';
    params.scaleUnderStart = ( params.scaleUnderStart !== '' ) ? parseInt(params.scaleUnderStart) : '';
    params.scaleUnderEnd = ( params.scaleUnderEnd !== '' ) ? parseInt(params.scaleUnderEnd) : '';

    params.searchCheckbox1 = isCheckRadio(formik.values.searchCheckbox1, false);
    params.searchCheckbox2 = isCheckRadio(formik.values.searchCheckbox2, false);
    params.searchCheckbox3 = isCheckRadio(formik.values.searchCheckbox3, false);
    params.isReSearch = isCheckRadio(formik.values.isReSearch);

    if( params.isReSearch && params.searchText?.trim() !== '') {
      params.searchTextOrg = params.searchTextOrg + ' +' + params.searchText;
    } else {
      params.searchTextOrg = params.searchText;
    }

    params.projectPageNo = 1;
    params.projectPageRow = 10000;

    await getSearchApi(getApi, params, async (response) => {
      if( response !== undefined && response.data.result ) {

        // 프로젝트 리스트
        if( response.data.data1 && response.data.data1.length > 0 ) {
          let tmp = [
            ...response.data.data1.map((item) => {
              return {
                ...item,
                designPageNo: formik.values.designPageNo,
                designPageRow: formik.values.designPageRow,
                projectPageNo: formik.values.projectPageNo,
                projectPageRow: formik.values.projectPageRow,
                filePageNo: formik.values.filePageNo,
                filePageRow: formik.values.filePageRow,
              }
            })
          ];

          await exportExcel("프로젝트 검색 정보", "프로젝트_검색정보", tmp);
        } else {
          alertMsg("엑셀 데이터를 다운로드하는데 실패하였습니다.", "E", MESSAGE_DELAY);
        }

      } else {
        alertMsg("엑셀 데이터를 다운로드하는데 실패하였습니다.", "E", MESSAGE_DELAY);
      }
    });
  }

  /***************************************************************************************
   * 설명 : 엑셀 내보내기
  ***************************************************************************************/
  const exportExcel = async (title, fileName, projectExcelList) => {
    try {
      // 여러 엑셀 시트를 포함하는 하나의 workbook(단위) 생성
      const wb = new Excel.Workbook();

      // 엑셀 sheet 생성
      const sheet = wb.addWorksheet("데이터");

      // 상단 타이틀 추가
      const titleCell = sheet.addRow(['', title]);
      sheet.mergeCells('B1:L1');
      titleCell.eachCell((cell, colNum) => {
        cell.font = {
          bold: true,
          name: "Arial",
          size: 30,
          color: { argb: "ff000000" },
        };
        cell.alignment = {
          vertical: "middle",
          horizontal: "center",
          wrapText: true,
        };
      });

      // 빈 라인 추가
      sheet.addRow(['']);

      let headerDefault = [...columnDefs.filter((item) => ! ['download', 'preview'].includes(item.field) )];
      let tmp = headerDefault.map((item) => {
        return item;
      })

      headerDefault = tmp;

      const headers = [...headerDefault.map((item) => item.headerName)];
      let headerWidths = [...columnDefs.map((item) => Math.round(item.width / 8))];

      // seq 숨김
      headerWidths[0] = 0;

      // 상단 헤더(TH) 추가
      const headerRow = sheet.addRow(headers);
      headerRow.height = 35;

      // 각 헤더 cell에 스타일 지정
      headerRow.eachCell((cell, colNum) => {
        styleHeaderCell(cell);
        sheet.getColumn(colNum).width = headerWidths[colNum - 1];

        // 기본열람권한, 다운로드 허용 헤더에 설명 추가
      });


      // 각 Data cell에 데이터 삽입 및 스타일 지정
      projectExcelList.forEach((item) => {
        // download, preview 제외

        const rowDatas = [...headerDefault.map((subItem) => {
          if( subItem.field === 'fileSize')
            return formatBytes(item[subItem.field] || 0, 2);
          else
            return item[subItem.field];
        })];
        const appendRow = sheet.addRow(rowDatas);

        appendRow.eachCell((cell, colNum) => {
          const align = ['center', 'center', 'center', 'left', 'center', 'center', 'center', 'center', 'center', 'center', 'center', 'center'];
          styleDataCell(cell, align[parseInt(colNum) - 1]);
          /*
          if (colNum === 4) {
            cell.numFmt = "0,000";
          }
          */
        });
      });

      // 파일 생성
      const fileData = await wb.xlsx.writeBuffer(); //writeBuffer는 프로미스를 반환하므로 async-await을 사용해야 한다.
      const blob = new Blob([fileData], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, fileName);
    } catch(error) {
      console.log(error);
    }

  }

  /***************************************************************************************
   * 설명 : 데이터 검색
  ***************************************************************************************
  useEffect(() => {
    if( isLoading ) {
      getSearchText();
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formik.values.filePageNo,
    formik.values.designPageNo,
    formik.values.projectPageNo,
    formik.values.searchCheckbox1,
    formik.values.searchCheckbox2,
    formik.values.searchCheckbox3
  ]);

  /***************************************************************************************
   * 설명 : 데이터 검색
  ***************************************************************************************/
  useEffect(() => {
    if( queryString !== '' && prevQueryString.current !== queryString ) {
      getSearchText();

    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    /*
    queryString.searchCheckbox1,
    queryString.searchCheckbox2,
    queryString.searchCheckbox3,
    queryString.designProjectType,
    // queryString.search,
    queryString.prevDesignPageNo,
    queryString.designPageNo,
    queryString.designPageRow,
    queryString.prevProjectPageNo,
    queryString.projectPageNo,
    queryString.projectPageRow,
    queryString.prevFilePageNo,
    queryString.filePageNo,
    queryString.filePageRow,
    queryString.searchText,
    queryString.tab,
    queryString.projectDivision,
    queryString.projectNumber,
    queryString.projectStartYear,
    queryString.projectEndYear,
    queryString.projectName,
    queryString.departmentName,
    queryString.projectPm,
    queryString.projectType,
    queryString.businessType,
    queryString.builderType,
    queryString.purpose,
    queryString.scaleUnderStart,
    queryString.scaleUnderEnd,
    queryString.scaleStart,
    queryString.scaleEnd,
    queryString.structure,
    queryString.builderName,
    queryString.orderCompanyId,
    queryString.builderCompanyId,
    queryString.location,
    queryString.totalSizeStart,
    queryString.totalSizeEnd,
    queryString.isNewTech,
    queryString.newTech,
    queryString.isAwardInfo,
    queryString.isOwnership,
    queryString.isCooperationCompany,
    queryString.fileType,
    queryString.responsibilityConstructor,
    queryString.joinCompany,
    queryString.rate,
    queryString.betterThan,
    */
    queryString,
    formik.values.filePageNo,
    formik.values.designPageNo,
    formik.values.projectPageNo,
    formik.values.searchCheckbox1,
    formik.values.searchCheckbox2,
    formik.values.searchCheckbox3
  ]);

  /***************************************************************************************
   * 설명 : 쿼리스트링 변경 시 처리
  ***************************************************************************************
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if( queryString !== '' && ! isLoading ) {

    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryString])

  /***************************************************************************************
   * 설명 : 쿼리스트링 변경 시 처리
  ***************************************************************************************/
  useEffect(() => {
    let query = qs.parse(window.location.search, {
      ignoreQueryPrefix: true
    });

    // 영문 오타일 경우 한글로 변경
    if( query.search !== '' && checkEng(query.search) ) {
      let tmp = checkHangul(query.search);
      if( checkKor(tmp) && ! checkEng(query.search) )
        query.search = tmp;
    }

    setIsLoading(false);
    setQueryString(query);
    formik.setValues(query);

    let tmp = query.tab;
    if( tmp === 'project' ) {
      setTabMenu(1);
    } else if( tmp === 'file' ) {
      setTabMenu(2);
    } else {
      setTabMenu(0);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <UserLayout>
      <section className={"search-list mt30" + (isDetail ? ' detail' : '')}>
        <form
          onSubmit={formik.handleSubmit}
        >
          <div
            className="searchBoxBlurList"
            onBlur={() => {
              // setViewHelpInputBox(false);
            }}
          >
            <div className="searchBox">
              <InputEx
                name="search"
                formik={formik}
                placeholder="프로젝트, 파일검색"
                fullWidth={true}
                className="search-input inputbox"
                onClick={() => setViewHelpInputBox( ! viewHelpInputBox )}
                onKeyUp={(event) => {
                  if( event.code === 'Enter' ) {
                    searchSubmit(formik.values);
                  }
                }}
                //onBlur={() => setViewHelpInputBox(false)}
                autoComplete="off"
              />

              <i className="bx bx-search search-widget-icon"></i>
              <i className="bx bx-chevron-down search-widget-icon search-widget-icon-right"
                onClick={() => {
                  setViewHelpInputBox(false)
                  setIsDetail( ! isDetail )
                }}
              ></i>

              <HelpInputBox
                formik={formik}
                display={viewHelpInputBox}
                searchSubmit={searchSubmit}
                setViewHelpInputBox={setViewHelpInputBox}
              ></HelpInputBox>
            </div>
          </div>

          <div className="fl ml10">
            <CheckBoxEx
              name="isReSearch"
              formik={formik}
              fontSize="14px !important"
              label="결과내 재검색"
            />
          </div>

          {/*
            <div className="fl result">
              <div className="f16">[조감도 - 상세검색 조건 표시]</div>
            </div>
          */}

          {tabMenu === 0 &&
            <div className="design-option">
              <CheckBoxEx
                name="searchCheckbox1"
                formik={formik}
                fontSize="14px !important"
                label="본사"
                onChange={(e) => {
                  // 모든 체크 해제된지 체크
                  if( e.target.checked === false ) {
                    if( ! isCheckRadio(formik.values.searchCheckbox2) && ! isCheckRadio(formik.values.searchCheckbox3) ) {
                      alertMsg("검색 조건을 모두 해제하실 수 없습니다.", "W", MESSAGE_DELAY);
                      e.target.checked = true;
                      formik.setFieldValue('searchCheckbox1', ['on']);
                      return;
                    }
                  }
                }}
              />

              <CheckBoxEx
                name="searchCheckbox2"
                formik={formik}
                fontSize="14px !important"
                label="타사"
                onChange={(e) => {
                  // 모든 체크 해제된지 체
                  if( e.target.checked === false ) {
                    if( ! isCheckRadio(formik.values.searchCheckbox1) && ! isCheckRadio(formik.values.searchCheckbox3) ) {
                      alertMsg("검색 조건을 모두 해제하실 수 없습니다.", "W", MESSAGE_DELAY);
                      e.target.checked = true;
                      formik.setFieldValue('searchCheckbox2', ['on']);
                      return;
                    }
                  }
                }}
              />

              <CheckBoxEx
                name="searchCheckbox3"
                formik={formik}
                fontSize="14px !important"
                label="외부"
                onChange={(e) => {
                  // 모든 체크 해제된지 체크
                  if( e.target.checked === false ) {
                    if( ! isCheckRadio(formik.values.searchCheckbox1) && ! isCheckRadio(formik.values.searchCheckbox2) ) {
                      alertMsg("검색 조건을 모두 해제하실 수 없습니다.", "W", MESSAGE_DELAY);
                      e.target.checked = true;
                      formik.setFieldValue('searchCheckbox3', ['on']);
                      return;
                    }
                  }
                }}
              />
            </div>
          }

          <div className="clearfix"></div>
        </form>

        {isDetail &&
          <DetailSearchBox
            isSearchDetail={true}
            isDetail={isDetail}
            formik={formik}
            close={() => setIsDetail(false)}
            getSearchList={(values) => {
              searchSubmit(values)
            }}
          />
        }

        <section className="tab-header">
          {isLoading && tabMenu !== '' &&
            <>
              <Tabs
                initialSelectedIndex={tabMenu}
                value={tabMenu}
                className="tab-menu"
                onChange={(event, newValue) => {
                  setTabMenu(newValue);

                  if( newValue === 1)
                    formik.setFieldValue('tab', 'project');
                  else if( newValue === 2)
                    formik.setFieldValue('tab', 'file');
                  else
                    formik.setFieldValue('tab', 'design');
                }}
                aria-label="basic tabs example"
              >
                <Tab label="디자인" />
                <Tab label="프로젝트" />
                <Tab label="파일" />
              </Tabs>

              <div className="tag mb5">
                연관 태그
                <ul className="clearfix">
                  { tagList.length > 0 && tagList.map((item, index) => {
                    return (
                      <li className={COLOR[index]} onClick={() => {
                        setIsLoading(false);

                        let tmp = {...formik.values};
                        tmp.search = item.relationWord;
                        tmp.isRelation = 1;
                        tmp.relationSeq = item.seq;

                        formik.setFieldValue('search', item.relationWord);
                        formik.setFieldValue('isRelation', 1);
                        formik.setFieldValue('relationSeq', item.seq);

                        searchSubmit(tmp);
                      }}>
                        {item.relationWord}
                      </li>
                    )
                  })}

                  {tagList.length < 1 &&
                    <li className="" style={{color: '#000'}}>검색된 연관 태그가 없습니다.</li>
                  }
                  {/*
                  <li className="bg-blue1">도시</li>
                  <li className="bg-blue">태그</li>
                  <li className="bg-green">건축물</li>
                  <li className="bg-yellow">도면</li>
                  <li className="bg-red">파일</li>
                  */}
                </ul>

                {formik.values.tab === 'project' &&
                  <>
                    <div className="fr">
                      <label>
                        <BtnExcelDown
                          click={async () => {
                            if( projectList.length < 1 ) {
                              alertMsg("엑셀로 내보낼 프로젝트 리스트가 없습니다.", "W", MESSAGE_DELAY);
                            } else {
                              getSearchTextExcel();
                            }
                          }}
                        />
                        엑셀저장
                      </label>
                    </div>
                    <div className="clearFix"></div>
                  </>
                }
              </div>
            </>
          }
        </section>

        <div className="clearFix"></div>

        <section className="tab-contents" style={{height: 'calc(100vh - 270px)'}}>
          {
            isLoading &&
            <SwipeableViews
              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={tabMenu}
              onChangeIndex={(index) => {
                setTabMenu(index);
              }}
              style={{height: 'calc(100vh - 270px)'}}
            >
              <div
                role="tabpanel"
                id={`simple-tabpanel-0`}
                aria-labelledby={`simple-tab-0`}
                dir={theme.direction}
              >
                <TabDesign
                  tabIndex={tabMenu}
                  formik={formik}
                  list={designList}
                  total={total}
                  change={(event, pageNo) => {
                    formik.setFieldValue("designPageNo", pageNo);
                  }}
                />
              </div>

              <div
                role="tabpanel"
                id={`simple-tabpanel-1`}
                aria-labelledby={`simple-tab-1`}
                dir={theme.direction}
              >
                <TabProject
                  tabIndex={tabMenu}
                  formik={formik}
                  list={projectList}
                  total={total}
                  change={(event, pageNo) => {
                    formik.setFieldValue("projectPageNo", pageNo);
                  }}
                />
              </div>

              <div
                role="tabpanel"
                id={`simple-tabpanel-2`}
                aria-labelledby={`simple-tab-2`}
                dir={theme.direction}
              >
                <TabFile
                  tabIndex={tabMenu}
                  formik={formik}
                  list={fileList}
                  total={total}
                  change={(event, pageNo) => {
                    formik.setFieldValue("filePageNo", pageNo);
                  }}
                />
              </div>
            </SwipeableViews>
          }
        </section>
      </section>
    </UserLayout>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default SearchList;