/*****************************************************************************************
 * 설명 : 게시판 - 공지사항
 * URI : /board/list
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import Button from '@mui/material/Button';
import Pagination from '@mui/material/Pagination';
import { AgGridReact } from 'ag-grid-react';
import { useFormik } from 'formik';
import { useState } from 'react';

import BtnAgGridSave from 'components/btnAgGridSave';
import { BtnRefresh, BtnSearch } from 'components/common';
import { DateEx, InputEx, SelectEx } from 'components/inputEx';
import customTooltipAgGrid from 'components/tooltipAgGrid';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const BoardList = (props) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [list] = useState([1]);
  const formik = useFormik({
    initialValues: {
      search: '',
    },
  });

  // table column
  const [columnDefs] = useState([
    { headerName: 'No', field: '', width: 80 },
    { headerName: '제목', field: 'subject', width: 1200, tooltipField: 'subject' },
    { headerName: '작성자', field: '', width: 150 },
    { headerName: '일시', field: '', width: 150 },
  ]);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <>
      <div className="admin-cms-contents">
        <header className="admin-cms-search-header">
          <div className="left">
            <BtnRefresh></BtnRefresh>
            <BtnSearch></BtnSearch>
            <BtnAgGridSave></BtnAgGridSave>

            <SelectEx
              name="siteSeq"
              formik={formik}
              fullWidth={false}
              data={[
                {label: '20줄', value: ''}
              ]}
            />
            <DateEx
              type="date"
              name="sdate"
              formik={formik}
              fullWidth={false}
              style={{width: '150px'}}
              className="ml5"
            />
            <span className="ml5 mr5">~</span>
            <DateEx
              type="date"
              name="edate"
              formik={formik}
              fullWidth={false}
              style={{width: '150px'}}
            />
            <InputEx
              name="searchText"
              formik={formik}
              style={{width: '300px'}}
              label="제목, 내용 검색"
              className="ml5"
            />
          </div>

          <div className="text-right">

            <Button
              color="secondary"
              className="btn ml5"
              variant="outlined"
              href="/board/write"
            >등록하기</Button>
          </div>
        </header>

        <section className="admin-cms-body">
          <section className="ag-theme-balham grid mb10" style={{height:'calc(100% - 90px)'}}>
            <AgGridReact
              defaultColDef={{
                sortable: true,
                resizable: true,
                filter: false,
                lockVisible: true,
                tooltipComponent: customTooltipAgGrid,
              }}
              tooltipShowDelay={0}
              tooltipHideDelay={2000}
              columnDefs={columnDefs}
              rowData={list}
              // onRowDoubleClicked={(event) => {
              //   {props.history.push('/board/view')}
              // }}
              rowDragManaged={true}
              rowDragMultiRow={true}
              animateRows={true}
              overlayNoRowsTemplate = "검색된 내용이 없습니다."
            />
          </section>
          <div className="fr f14 mr5">검색/전체 : 1,000 / 12,030</div>
          <div className="pagination">
            <Pagination
              count={10}
              showFirstButton
              showLastButton
            />
          </div>

        </section>
      </div>
    </>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default BoardList;