/*****************************************************************************************
 * 설명 : 페이지 라우팅
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import { Suspense, useContext, useState } from 'react';
import { Route, Routes } from 'react-router-dom';

import { IndicatorContext } from 'config/indicator';

import Dashboard from "pages/admin/dashboard/dashboard";
import Login from "pages/login/login";
//import Board             from "pages/board/boardList";
import BoardView from "pages/admin/board/boardView";
import BoardWrite from "pages/admin/board/boardWrite";
import Reading from "pages/admin/reading/reading";
import AdminMenu from "pages/layout/adminMenu";

import CostPrint from 'pages/admin/cost/cost/costPrint';
import Costing from 'pages/admin/cost/costForm/costForm';

import UserMenu from "pages/layout/userMenu";
import TotalSearch from "pages/user/search/search";
import SearchList from "pages/user/search/searchList/searchList";

import ProjectAuthEmployee from './admin/project/auth/employee/employee';
import ProjectAuthGroup from './admin/project/auth/group/group';
import ProjectAuthOrganization from './admin/project/auth/organization/orgranization';
import ProjectAuthPosition from './admin/project/auth/position/position';

import BoardManagement from 'pages/admin/cms/board/boardManagement';
import MenuList from 'pages/admin/cms/contents/menuList';
import Module from 'pages/admin/cms/module';
import Site from 'pages/admin/cms/site';

import AuthGroup from 'pages/admin/auth/group/group';
import AuthEmployee from './admin/auth/employee/employee';
import AuthOrganization from './admin/auth/organization/organization';
import AuthPosition from './admin/auth/position/position';

import GroupWareLog from 'pages/admin/log/groupWareLog/groupWareLog';
import LogData from 'pages/admin/log/logData/logData';
import PmsLog from 'pages/admin/log/pmsLog/pmsLog';
import Common from 'pages/admin/system/common/common';
import GroupwareSync from 'pages/admin/system/groupwareSync/groupwareSync';
import RogicStorage from 'pages/admin/system/logicStorage/logicStorage';
import PmsSync from 'pages/admin/system/pmsSync/pmsSync';
import SystemConfig from 'pages/admin/system/systemConfig/systemConfig';
import UserManager from 'pages/admin/system/userManager/userManager';

import StatisticDeviceInfo from 'pages/admin/statistic/deviceInfo';
import StatisticSearch from 'pages/admin/statistic/search';
import StatisticAccess from './admin/statistic/access';

import PageInfoMsg from "pages/layout/pageInfoMsg";
import DesignProject from './admin/projectDesign/designProject';

import UserReading from './user/reading/reading';

import AutoCAD from './autoCAD/autoCAD';

import PmsLogin from './user/pmsLogin/pmsLogin';


// 홈페이지

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const SiteRouter = (props) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const { setIndicator } = useContext(IndicatorContext);

  const [moduleList] = useState([]);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <Suspense fallback={() => setIndicator(true)}>
      <Routes>
        <Route exact="true" path="/" element={<Login indicator={props.indicator} history={props.history} />}></Route>
        <Route exact="true" path="/login" element={<Login indicator={props.indicator} history={props.history} />}></Route>
        <Route exact="true" path="/admin" element={<Dashboard indicator={props.indicator} history={props.history} />}></Route>
        <Route exact="true" path="/admin/dashboard" element={<Dashboard indicator={props.indicator} history={props.history} />}></Route>
        <Route exact="true" path="/admin/board" element={<AdminMenu indicator={props.indicator} history={props.history} uri={'board'} />}></Route>
        <Route exact="true" path="/admin/board/view" element={<BoardView indicator={props.indicator} history={props.history} />}></Route>
        <Route exact="true" path="/admin/board/write" element={<BoardWrite indicator={props.indicator} history={props.history} />}></Route>
        <Route exact="true" path="/admin/board/view" element={<BoardView indicator={props.indicator} history={props.history} />}></Route>

        <Route exact="true" path="/admin/design/project" element={<DesignProject indicator={props.indicator} history={props.history} />}></Route>
        <Route exact="true" path="/admin/project" element={<AdminMenu indicator={props.indicator} history={props.history}  uri={'project'} />}></Route>
        <Route exact="true" path="/admin/project/auth/group" element={<ProjectAuthGroup indicator={props.indicator} history={props.history} />}></Route>
        <Route exact="true" path="/admin/project/auth/employee" element={<ProjectAuthEmployee indicator={props.indicator} history={props.history} />}></Route>
        <Route exact="true" path="/admin/project/auth/position" element={<ProjectAuthPosition indicator={props.indicator} history={props.history} />}></Route>
        <Route exact="true" path="/admin/project/auth/organization" element={<ProjectAuthOrganization indicator={props.indicator} history={props.history} />}></Route>

        <Route exact="true" path="/admin/cost" element={<AdminMenu indicator={props.indicator} history={props.history} uri={'projectCosting'} />}></Route>
        <Route exact="true" path="/admin/cost/print" element={<CostPrint indicator={props.indicator} history={props.history} />}></Route>
        <Route exact="true" path="/admin/cost/form" element={<Costing indicator={props.indicator} history={props.history} />}></Route>
        <Route exact="true" path="/admin/reading" element={<Reading indicator={props.indicator} history={props.history} />}></Route>

        <Route exact="true" path="/user/request" element={<UserReading indicator={props.indicator} history={props.history} />}></Route>
        <Route exact="true" path="/search" element={<TotalSearch indicator={props.indicator} history={props.history} />}></Route>
        <Route exact="true" path="/user/search/list" element={<SearchList indicator={props.indicator} history={props.history} />}></Route>
        <Route exact="true" path="/project" element={<UserMenu indicator={props.indicator} history={props.history} uri={'project'} />}></Route>
        <Route exact="true" path="/cost" element={<UserMenu indicator={props.indicator} history={props.history} uri={'projectCosting'} />}></Route>
        <Route exact="true" path="/board" element={<UserMenu indicator={props.indicator} history={props.history} uri={'board'} />}></Route>
        <Route exact="true" path="/pms/sso" element={<PmsLogin indicator={props.indicator} history={props.history} />}></Route>

        <Route exact="true" path="/admin/cms/site" element={<Site indicator={props.indicator} history={props.history} />}></Route>
        <Route exact="true" path="/admin/cms/module" element={<Module indicator={props.indicator} history={props.history} />}></Route>
        <Route exact="true" path="/admin/cms/board" element={<BoardManagement indicator={props.indicator} history={props.history} />}></Route>
        <Route exact="true" path="/admin/cms/menuList" element={<MenuList indicator={props.indicator} history={props.history} />}></Route>
        {/* <Route exact="true" path="/cms/popup" element={<Popup indicator={props.indicator} history={props.history} />}></Route>
        <Route exact="true" path="/cms/banner" element={<Banner indicator={props.indicator} history={props.history} />}></Route> */}

        <Route exact="true" path="/admin/statistic/device" element={<StatisticDeviceInfo indicator={props.indicator} history={props.history} />}></Route>
        <Route exact="true" path="/admin/statistic/search" element={<StatisticSearch indicator={props.indicator} history={props.history} />}></Route>
        <Route exact="true" path="/admin/statistic/access" element={<StatisticAccess indicator={props.indicator} history={props.history} />}></Route>

        <Route exact="true" path="/admin/system/user" element={<UserManager indicator={props.indicator} history={props.history} />}></Route>
        <Route exact="true" path="/admin/system/common" element={<Common indicator={props.indicator} history={props.history} />}></Route>
        <Route exact="true" path="/admin/system/config" element={<SystemConfig indicator={props.indicator} history={props.history} />}></Route>
        <Route exact="true" path="/admin/system/logicstorage" element={<RogicStorage indicator={props.indicator} history={props.history} />}></Route>
        <Route exact="true" path="/admin/system/menu/group" element={<AuthGroup indicator={props.indicator} history={props.history} />}></Route>
        <Route exact="true" path="/admin/system/menu/position" element={<AuthPosition indicator={props.indicator} history={props.history} />}></Route>
        <Route exact="true" path="/admin/system/menu/organization" element={<AuthOrganization indicator={props.indicator} history={props.history} />}></Route>
        <Route exact="true" path="/admin/system/menu/employee" element={<AuthEmployee indicator={props.indicator} history={props.history} />}></Route>
        <Route exact="true" path="/admin/system/pms/sync" element={<PmsSync indicator={props.indicator} history={props.history} />}></Route>
        <Route exact="true" path="/admin/system/groupware/sync" element={<GroupwareSync indicator={props.indicator} history={props.history} />}></Route>

        <Route exact="true" path="/admin/log/data" element={<LogData indicator={props.indicator} history={props.history} uri={'projectLog'} />}></Route>
        <Route exact="true" path="/admin/log/project" element={<AdminMenu indicator={props.indicator} history={props.history} uri={'projectLog'} />}></Route>
        <Route exact="true" path="/admin/log/groupware" element={<GroupWareLog indicator={props.indicator} history={props.history} />}></Route>
        <Route exact="true" path="/admin/log/pms" element={<PmsLog indicator={props.indicator} history={props.history} />}></Route>

        <Route exact="true" path="/pageInfo/msg" element={<PageInfoMsg indicator={props.indicator} history={props.history} />}></Route>

        <Route exact="true" path="/autocad" element={<AutoCAD indicator={props.indicator} history={props.history} />}></Route>
        <>
          {
            moduleList && moduleList.length > 0 &&
            moduleList.map((item, index) => {
              if( item.isRouter !== 0 && item.isRouter !== '0' ) {
                var Module = item.module;

                return (
                  <Route key={'admin_' + item.seq + '_' + index} exact="true" path={item.linkUrl} element={<Module />}></Route>
                )
              } else {
                return '';
              }
            })

          }
        </>

      </Routes>
    </Suspense>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default SiteRouter;

