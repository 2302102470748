/*****************************************************************************************
 * 설명 : 관리자 > 프로젝트 관리 > 파일정보 관리
*****************************************************************************************/
import axios from "axios";
import qs from 'qs';
import { useEffect, useState } from 'react';
import ForgeViewer from 'react-forge-viewer';

import { MESSAGE_DELAY } from "config/config";

import alertMsg from "components/message";

import { getFileUrnApi } from "service/autoCad";
import Restful from 'service/restful';
import { getSystemConfigApi } from 'service/system';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const AutoCAD = ( props ) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [getApi] = Restful();

  const [userId, setUserId] = useState('');
  const [userPw, setUserPw] = useState('');

  const [accessToken, setAccessToken] = useState('');
  const [expiresIn, setExpiresIn] = useState(null);

  const [autoCadInfo, setAutoCadInfo] = useState({});

  const [state, setState] = useState({
    view:null
  });

  const [query, setQuery] = useState({});

  /***************************************************************************************
   * 설명 : 환경설정 정보 가져오기
  ***************************************************************************************/
  const getConfig = () => {
    getSystemConfigApi(getApi, {groupCode: 'SYSTEM'}, (response) => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        let tmp = response.data.data;

        tmp.forEach((item) => {
          if( item.commonCode === 'AUTOCADID' )
            setUserId(item.memo);
          else if( item.commonCode === 'AUTOCADPW' )
            setUserPw(item.memo);
        })

      }
    });
  }

  /***************************************************************************************
   * 설명 : Autodesk Forge 토큰 가져오기
  ***************************************************************************************/
  const getForgeToken = async () => {
    let auth = btoa('OR2GDsFPhn9vcrjU1j8MmFOeaL8n3T4N:tPtuBAmfEASBQLXF');

    let tmp = {};
    await axios({
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        'Accept': 'application/json',
        "Access-control-Allow-Origin": "*",
        "Authorization": 'Basic ' + auth
      },
      url: 'https://developer.api.autodesk.com/authentication/v2/token',
      method: 'post',
      data: {
        grant_type: 'client_credentials',
        scope: 'data:read data:write data:create bucket:read bucket:create'
      }
    }).then(async (response) => {
      tmp = response.data;

      setAccessToken(tmp.access_token);
      setExpiresIn(tmp.expires_in);

      getBucket(tmp.access_token);
    });

    return tmp;
  }

  /***************************************************************************************
   * 설명 : Autodesk 버킷 만들기
   * bucketKey : "dagroupbucket"
      bucketOwner : "OR2GDsFPhn9vcrjU1j8MmFOeaL8n3T4N"
      createdDate : 1706646520117
      permissions : [{authId: "OR2GDsFPhn9vcrjU1j8MmFOeaL8n3T4N", access: "full"}]
        0 : {authId: "OR2GDsFPhn9vcrjU1j8MmFOeaL8n3T4N", access: "full"}
      access : "full"
      authId : "OR2GDsFPhn9vcrjU1j8MmFOeaL8n3T4N"
      policyKey : "transient"
  ***************************************************************************************/
  const getBucket = async (auth) => {
    await axios({
      headers: {
        "Content-Type": "application/json",
        "Authorization": 'Bearer ' + auth
      },
      url: 'https://developer.api.autodesk.com/oss/v2/buckets',
      method: 'post',
      data: {
        "bucketKey": "dagroupbucket",
        "policyKey": "transient"
      }
    }).then(async (response) => {

      getFileUrn(auth);

    }).catch((e) => {
      if( e.response.status === 409 ) {
        console.log('error run file');
        getFileUrn(auth);
        return;

      } else {
        alertMsg("AutoCAD 서버에 접속할 수 없습니다.", "E", MESSAGE_DELAY);
        return;
      }
    });
  }

  /***************************************************************************************
   * 설명 : AUTO CAD URN 가져오기
  ***************************************************************************************/
  const getFileUrn = (token) => {
    const params = {
      seq: query.seq,
      token: token
    }

    getFileUrnApi(getApi, params, (response) => {
      if( response !== undefined && response.data.result && response.data.data ) {
        setAutoCadInfo(response.data.data);

      } else {
        alertMsg(response.data.message || '서버와의 통신에 실패하였습니다.', "E", MESSAGE_DELAY);
      }
    })
  }

  /***************************************************************************************
   * 설명 : AutoCAD 로그인
  ***************************************************************************************/
  useEffect(() => {
    if( userId !== '' && userPw !== '' )
      getForgeToken();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, userPw]);

  /***************************************************************************************
   * 설명 : 데이터 로딩 처리
  ***************************************************************************************/
  useEffect(() => {
    let tmp = qs.parse(window.location.search, {
      ignoreQueryPrefix: true
    });

    setQuery(tmp);

    getConfig();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location]);

  const handleViewerError = (error) => {
    alertMsg("AutoDesk : Error loading viewer. 30초 후에 새로고침(F5) 해주시기 바랍니다.", "E", MESSAGE_DELAY);
  }

  /* after the viewer loads a document, we need to select which viewable to
  display in our component */
  const handleDocumentLoaded = (doc, viewables) => {
    if (viewables.length === 0) {
      alertMsg("AutoDesk : Document contains no viewables. 30초 후에 새로고침(F5) 해주시기 바랍니다.", "E", MESSAGE_DELAY);

    } else {
      //Select the first viewable in the list to use in our viewer component
      setState({view:viewables[0]});
    }
  }

  const handleDocumentError = (viewer, error) => {
    alertMsg("AutoDesk : Error loading a document. 30초 후에 새로고침(F5) 해주시기 바랍니다.", "E", MESSAGE_DELAY);
  }

  const handleModelLoaded = (viewer, model) => {
    console.log('Loaded model:', model);
  }

  const handleModelError = (viewer, error) => {
    alertMsg("AutoDesk : Error loading the model. 30초 후에 새로고침(F5) 해주시기 바랍니다.", "E", MESSAGE_DELAY);
  }

  /* Once the viewer has initialized, it will ask us for a forge token so it can
  access the specified document. */
  const handleTokenRequested = (onAccessToken) => {
    onAccessToken(accessToken, expiresIn);
  }


  /***************************************************************************************
   * 설명 : html 선언부
   * <<INSERT_YOUR_FORGE_DOCUMENT_URN>>
   * 'https://archivedev.dagroup.co.kr/api/v1/file/download?seq=' + query.seq}
  ***************************************************************************************/
  return (
    <div className="App">
      { (autoCadInfo ?? '') !== '' &&
        autoCadInfo.seq !== undefined &&
        <>
          <ForgeViewer
            version="6.0"
            urn={autoCadInfo.urn}
            view={state.view}
            headless={false}
            onViewerError={handleViewerError}
            onTokenRequest={handleTokenRequested}
            onDocumentLoad={handleDocumentLoaded}
            onDocumentError={handleDocumentError}
            onModelLoad={handleModelLoaded}
            onModelError={handleModelError}
          />
        </>
      }
    </div>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default AutoCAD;
