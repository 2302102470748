/*****************************************************************************************
 * 설명 : 관리자 > 시스템관리 > 권한그룹관리
*****************************************************************************************/
import { AgGridReact } from 'ag-grid-react';
import { useFormik } from 'formik';
import 'moment/locale/ko';
import { useEffect, useState } from 'react';
import SplitPane from "react-split-pane";

import BtnAgGridSave from 'components/btnAgGridSave';
import ChipEx from 'components/chipEx';
import { ButtonEx } from 'components/inputEx';
import customTooltipAgGrid from 'components/tooltipAgGrid';

import useGridConfig from 'hooks/useGridConfig';

import { getAuthGroupListApi } from 'service/auth';
import Restful from 'service/restful';

import Layout from 'pages/layout/layout';
import TabProjectAuth from './tabProjectAuth';
// import AuthGroupModal from './employeeModal';
//import AuthGroupCopyModal from './groupCopyModal';
// import ProjectAuthEmployee from './tabGroupProject';
//import MenuAuthEmployee from './tabGroupMenu';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
function ProjectAuthEmployee(props) {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [getApi] = Restful();

  const [authgroupSeq, setAuthgroupSeq] = useState('');
  const [authList, setAuthList] = useState([]);

  const [selected, setSelected] = useState([]);

  const formik = useFormik({
    initialValues: {
      siteSeq: null,
    },
    onSubmit: (values) => {
      //onRowClickedGroup();
    }
  });

  // 권한그룹 컬럼 선언
  const [columnDefsGroup, setColumnDefsGroup] = useState([
    { headerName: '', field: 'seq', cellClass: 'cp', width: 36, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
    { headerName: '사용자명', field: 'authGroupName', width: 185, cellClass: 'cp' },
    { headerName: '사용여부', field: 'isUse', width: 75, cellClass: 'cp center',
      cellRendererFramework: function(params) {
        if( parseInt(params.data.isUse) === 1 )
          return <ChipEx color="success" variant="outlined" size="small" label="사용" />;
        else
          return <ChipEx color="default" variant="outlined" size="small" label="사용안함" />;
      }
    }
  ]);

  // 권한그룹 데이터
  const [groupList] = useState([
    {
      groupName: '비회원'
    },
    {
      groupName: '일반회원'
    },
    {
      groupName: '직원'
    },
    {
      groupName: '관리자'
    },
    {
      groupName: '시스템관리자'
    },
    {
      groupName: '업체관리자'
    },

  ]);
  const [gridConfigGroup, setGridUpdateGroup] = useGridConfig(1, setColumnDefsGroup);

  /***************************************************************************************
   * 설명 : 권한 그룹 셀 클릭 이벤트
  ***************************************************************************************/
  const onRowClickedGroup = (seq) => {
    setAuthgroupSeq(seq);

    /*
    let params = {
      program: 'api',
      service: 'cms',
      action: 'getAuthGroupInfo',
      version: '1.0',
      seq: seq,
      siteSeq: formik.values.siteSeq
    }

    getApi("get", params).then( response => {
      if( response !== undefined && response.data.result ) {
        setAuthPageList(response.data.data);
      } else {
        setAuthPageList([]);
      }
    })
    */
  }


  /***************************************************************************************
   * 설명 : 권한그룹 정보 가져오기
  ***************************************************************************************/
  const getAuthGroupList = () => {
    getAuthGroupListApi(getApi, (response) => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        setAuthList([]);
      } else {
        setAuthList([]);
      }
    });
  }

  /***************************************************************************************
   * 설명 : Step - 2, 그룹 리스트 변경 시 페이지 데이터 가져오기
  ***************************************************************************************/
  useEffect(() => {
    if( groupList.length > 0 ) {
      onRowClickedGroup(authgroupSeq);
    }

    // eslint-disable-next-line
  }, [formik.values.siteSeq])

  /***************************************************************************************
   * 설명 : 권한 그룹 리스트 가져오기
  ***************************************************************************************/
  useEffect( () => {
    getAuthGroupList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <Layout>
      <section className="admin-cms-contents system-page">
        <SplitPane split="vertical" minSize={250} defaultSize={320}>
          <div className="mr10">
            <header className="admin-cms-search-header">
              <div className="left">
                <div className="fl">
                  <BtnAgGridSave className="fl" click={setGridUpdateGroup}></BtnAgGridSave>
                </div>
                <div className="fl">
                  <h4 className="f15 sub-title">사용자별 프로젝트 권한설정</h4>
                </div>
              </div>
              <div className="text-right">
                <ButtonEx
                  title="복사"
                  auth="isModify"
                  color="inherit"
                  variant="outlined"
                  className="Btn"
                  onClick={() => {
                    // deleteCommon();
                  }}
                />
              </div>
            </header>

            <section className="ag-theme-balham grid mt10" style={{height:'calc(100vh - 130px)'}}>
              <div style={{ height: '100%' }}>
                <AgGridReact
                  defaultColDef={{
                    sortable: true,
                    resizable: true,
                    filter: false,
                    lockVisible: true,
                    tooltipComponent: customTooltipAgGrid,
                  }}
                  tooltipShowDelay={0}
                  tooltipHideDelay={2000}
                  rowSelection={'single'}
                  columnDefs={columnDefsGroup}
                  rowData={authList}
                  onGridReady={(event) => {
                    gridConfigGroup(event.columnApi);
                  }}
                  onFirstDataRendered={(event) => {
                    event.api.forEachNode((item, index) => {
                      if(index === 0) {
                        item.setSelected(true);
                        onRowClickedGroup(item.data.seq);
                      }
                    })
                  }}
                  onRowClicked={(event) => {
                    let nodes = event.api.getSelectedRows();
                    setSelected(nodes);
                  }}
                  rowDragManaged={true}
                  rowDragMultiRow={true}
                  animateRows={true}
                  overlayNoRowsTemplate = "검색된 내용이 없습니다."
                />
              </div>
            </section>
          </div>
          <div className="Tab-page">
            <div className="tab-contents">
              <TabProjectAuth
                tabIndex={'2'}
                selected={selected}
              />
            </div>
          </div>
        </SplitPane>
      </section>

      { /*
        openModal.open && openModal.modal === 'copy' &&
        <AuthGroupCopyModal
          open={openModal.open}
          close={() => setOpenModal({ open: false, modalName: 'copy', data: openModal.data })}
          data={openModal.data}
          indicator={props.indicator}
          history={props.history}
        />
        */
      }
    </Layout>
  )
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default ProjectAuthEmployee;