/**
 * * 시스템 접속통계 가져오기
 * @param {*} getApi
 * @param {*} func
 * @returns
 */
export function getStatisticDeviceApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("get", '/api/v1/statistic/device', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  })
}

/**
 * * 검색통계 가져오기
 * @param {*} getApi
 * @param {*} func
 * @returns
 */
export function getStatisticSearchApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("get", '/api/v1/statistic/search', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  })
}

/**
 * * 열람통계 가져오기
 * @param {*} getApi
 * @param {*} func
 * @returns
 */
export function getStatisticAccessApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("get", '/api/v1/statistic/access', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  })
}
