/**
 * * 설계비가산정 양식 리스트 가져오기
 * @param {*} getApi
 * @param {*} params
 * @param {*} func
 * @returns
 */
export function getCostMemberListApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("get", '/api/v1/cost/member/form', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  });
}

/**
 * * 비용산출 양식 마스터 추가/수정
 * @param {*} getApi
 * @param {*} params
 * @param {*} func
 * @returns
 */
export function setCostMemberApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("post", '/api/v1/cost/member/form', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  });
}

/**
 * * 비용산출 양식 마스터 삭제
 * @param {*} getApi
 * @param {*} params
 * @param {*} func
 * @returns
 */
export function deleteCostMemberApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("post", '/api/v1/cost/member/form/delete', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  });
}

/**
 * * 설계비가산정 양식 디테일 리스트 가져오기
 * @param {*} getApi
 * @param {*} params
 * @param {*} func
 * @returns
 */
export function getCostMemberDetailApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("get", '/api/v1/cost/member/form/detail', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  });
}

/**
 * * 설계비가산정 외주용역비 가져오기
 * @param {*} getApi
 * @param {*} params
 * @param {*} func
 * @returns
 */
export function getCostMemberItemApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("get", '/api/v1/cost/member/item', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  });
}

/**
 * * 설계비가산정 양식 추가/수정
 * @param {*} getApi
 * @param {*} params
 * @param {*} func
 * @returns
 */
export function setCostMemberDetailApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("post", '/api/v1/cost/member/detail', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  });
}

/**
 * * 설계비가산정 양식 시작/종료년도 가져오기
 * @param {*} getApi
 * @param {*} params
 * @param {*} func
 * @returns
 */
export function getCostMemberYearApi(getApi, func) {
  if( ! getApi ) return;

  getApi("get", '/api/v1/cost/member/form/year').then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  });
}

/**
 * * 비용산출 양식 디테일 삭제
 * @param {*} getApi
 * @param {*} params
 * @param {*} func
 * @returns
 */
export function deleteCostMemberDetailApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("post", '/api/v1/cost/member/detail/delete', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  });
}

/**
 * * 설계비가산정 프로젝트 시작/종료년도 가져오기
 * @param {*} getApi
 * @param {*} params
 * @param {*} func
 * @returns
 */
export function getCostMemberProjectYearApi(getApi, func) {
  if( ! getApi ) return;

  getApi("get", '/api/v1/cost/member/project/year').then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  });
}

/**
 * * 설계비가산정 프로젝트 리스트 가져오기
 * @param {*} getApi
 * @param {*} params
 * @param {*} func
 * @returns
 */
export function getCostMemberProjectListApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("get", '/api/v1/cost/member/project', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  });
}

/**
 * * 설계비가산정 프로젝트 별 상세항목 가져오기
 * @param {*} getApi
 * @param {*} params
 * @param {*} func
 * @returns
 */
export function getProjectCostMemberItemApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("get", '/api/v1/cost/member/item/project', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  });
}

/**
 * * 설계비가산정 프로젝트 별 상세항목 가져오기
 * @param {*} getApi
 * @param {*} params
 * @param {*} func
 * @returns
 */
export function getProjectCostMemberItemListApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("post", '/api/v1/cost/member/item/project/list', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  });
}

/**
 * * 설계비가산정 프로젝트 별 상세항목 가져오기
 * @param {*} getApi
 * @param {*} params
 * @param {*} func
 * @returns
 */
export function getProjectCostItemListApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("post", '/api/v1/cost/member/item/project/list', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  });
}