/*****************************************************************************************
 * 설명 : 관리자 > 설계비가산정 > 설계비가산정 양식관리
*****************************************************************************************/
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import { AgGridReact } from 'ag-grid-react';
import { useFormik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import SplitPane from 'react-split-pane';
import * as Yup from "yup";

import AlertDialog from 'components/alertDiolog';
import BtnAgGridSave from 'components/btnAgGridSave';
import ChipEx from 'components/chipEx';
import { BtnRefresh, CurrencyFormat } from 'components/common';
import { ButtonEx, InputEx, SelectEx } from 'components/inputEx';
import alertMsg from 'components/message';
import customTooltipAgGrid from 'components/tooltipAgGrid';

import { getCommonListApi } from 'service/common';
import {
  deleteCostFormApi,
  deleteCostFormDetailApi,
  getCostFormDetailApi,
  getCostFormListApi,
  getCostPurposeApi,
  getCostYearApi,
  setCostFormDetailApi
} from 'service/cost';
import Restful from "service/restful";
import { PaperComponent, comma, getSelectData } from 'service/utils';

import useGridConfig from 'hooks/useGridConfig';

import Layout from 'pages/layout/layout';

import { MESSAGE_DELAY } from 'config/config';
import CostingModal from 'pages/admin/cost/costForm/costFormModal';
import CostExtraModal from '../cost/costExtraModal';
import ProjectAddModal from './projectAddModal';
import WorkKindModal from './workKindModal';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const Costing = ( props ) => {

  const columnDef = [
    { headerName: '', field: 'seq', width: 40, cellClass: 'center', flex: 0, pinned: 'left',
      headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
    { headerName: '정렬', field: 'sortOrder1', width: 52, cellClass: 'cp center', pinned: 'left', rowDrag: true, filter: false, editable: false, resize: false },
    { headerName: '번호', field: 'rowIndex', width: 55, cellClass: 'cp text-right', pinned: 'left',
      valueGetter: (params) => {
        return comma(params.node.rowIndex + 1);
      }
    },
    { headerName:'구분', field: 'costFormSeq', hide: true},
    { headerName:'구분', field: 'costItemSeq', hide: true},
    { headerName:'구분', field: 'type', width: 100, cellClass: 'cp center', pinned: 'left',
      cellRendererFramework: (params) => {
        if( parseInt(params.data.type) === 0 )
          return <ChipEx color="success" variant="outlined" size="small" label="기본" />;
        else if( parseInt(params.data.type) === 1 )
          return <ChipEx color="primary" variant="outlined" size="small" label="외주용역비" />;
        else
          return <ChipEx color="error" variant="outlined" size="small" label="기타" />;
      }
    },
    { headerName:'상세', field: 'title', width: 150, cellClass: 'cp', pinned: 'left' },
    { headerName:'기준 평단가', field: 'baseAmt', width: 100, editable: true, cellClass: 'cp text-right', valueFormatter: CurrencyFormat, pinned: 'left' },
    { headerName:'평균 평단가', field: 'avgAmt', width: 100, cellClass: 'cp text-right', valueFormatter: CurrencyFormat, pinned: 'left' },
    { headerName:'비고', field: 'memo', width: 170, cellClass: 'cp', editable: true, pinned: 'left' },
  ]

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [getApi] = Restful();
  const columnDefsRef = useRef(columnDef);

  const [groupList, setGroupList] = useState([]);
  const [list, setList] = useState([]);
  const [projectList, setProjectList] = useState([]);

  const [year, setYear] = useState([]);
  const [purpose, setPurpose] = useState([]);
  const [order, setOrder] = useState([]);

  const [selectedGroup, setSelectedGroup] = useState({});

  const [openModal, setOpenModal] = useState({open: false, modal: '', data: []});

  const [gridApi, setGridApi] = useState({});
  const [gridApiDetail, setGridApiDetail] = useState({});

  const [selectedDetail, setSelectedDetail] = useState([]);

  const initialValues = {
    startProjectYear: '',
    endProjectYear: '',
    purpose: '',
    order: '',
    searchText: ''
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object().shape({
      searchText: Yup.string().max(30, "30자리"),
    }),
    onSubmit: (values) => {
      getList();
    }
  });

  // table column
  const [columnDefsGroup, setColumnDefsGroup] = useState([
    { headerName: '', field: 'seq', width: 35, cellClass: 'center', flex: 0,
      headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
    { headerName:'연도', field: 'year', width: 45, cellClass: 'cp center' },
    { headerName:'양식명', field: 'title', width: 110, cellClass: 'cp' },
    { headerName:'용도', field: 'purpose', hide: true },
    { headerName:'용도', field: 'purposeName', width: 100, cellClass: 'cp center' },
    { headerName:'발주처', field: 'orderType', hide: true },
    { headerName:'발주처', field: 'orderTypeName', width: 70, cellClass: 'cp center' },
    {headerName: '수정', field: 'modify', cellClass: 'cp center', width: 50,
      cellRendererFramework: function (params) {
        return (
          <ButtonEx
            title="수정"
            auth="isModify"
            color="secondary"
            variant="outlined"
            className="gridBtn"
            onClick={() => setOpenModal({open: true, modal: 'masterAdd', data: params.data})}
          />
        );
      }
    },
    { headerName:'등록일시', field: 'createDate', width: 150, cellClass: 'cp center' }
  ]);

  const [gridConfigGroup, setGridUpdateGroup] = useGridConfig(1, setColumnDefsGroup);
  const [gridConfig, setGridUpdate] = useGridConfig(2, columnDefsRef);

  const setAgGridFocus = (gridApi, columnField = 'seq', value) => {
    gridApi.forEachNode((item, index) => {
      if( item.data[columnField] === value ) {
        item.setSelected(true);
        gridApiDetail.ensureIndexVisible(index);
      }
    });
  }


  // 왼쪽영역 축소
  const [costReduce, setCostReduce] = useState('');
  const [costMin, setCostMin] = useState('350');
  const [costMax, setCostMax] = useState('450');

  const setCostReduceClick = () => {
    if (costReduce === '') {
      setCostReduce('reduce');
      setCostMin('10');
      setCostMax('18');
    } else {
      setCostReduce('');
      setCostMin('350');
      setCostMax('450');
    }
  }


  /***************************************************************************************
   * 설명 : 추가된 프로젝트 평균 값 계산
  ***************************************************************************************/
  const calc = (data) => {

    // 프로젝트 번호로 키값 찾기
    let keyField = Object.keys(data[0]).filter((item) => item.indexOf('-') > -1 && item.indexOf('_Check') < 0 );

    // 프로젝트 체크 키값 찾기
    let keyCheckField = Object.keys(data[0]).filter((item) => item.indexOf('_Check') > -1 );

    // 추가된 프로젝트 비용 계산
    let tmp1 = data.map((item) => {

      let tmp2 = keyField.map((key) => item[key]);
      let tmp3 = keyCheckField.map((key) => item[key]);

      let total = 0;
      let count = 0;
      tmp2.forEach((subItem, index) => {
        if(parseInt(tmp3[index]) === 1 && parseInt(subItem ?? 0) !== 0 )  {
          total += subItem;
          count++;
        }
      });

      let tmp4 = {
        ...item
      };

      tmp4.avgAmt = Math.round(total / count);

      return tmp4;
    });

    return tmp1;
  }

  /***************************************************************************************
   * 설명 : 컬럼 조정
  ***************************************************************************************/
  const setColumnCheck = (e, params) => {
    params.node.data[params.column.colDef.field] = (e.target.checked === false) ? 0 : 1;

    let nodes = [];
    params.api.forEachNode( (node) => {
      if( e.target.checked ) {
        node.updateData(node.data);
      } else {
        node.updateData(node.data);
      }

      nodes.push(node.data);
    });

    // 데이터 계산
    let tmp = calc(nodes);
    setList(tmp);
  }

  /***************************************************************************************
   * 설명 : 컬럼 전체 선택
  ***************************************************************************************/
  const setColumnAll = (e, params) => {
    let nodes = [];

    params.api.forEachNode( (node) => {
      if( e.target.checked ) {
        node.setDataValue( params.column.colDef.field, 1 );

      } else {
        node.setDataValue( params.column.colDef.field, 0 );
      }

      nodes.push(node.data);
    });

    // 데이터 계산
    let tmp = calc(nodes);
    setList(tmp);
  }

  /***************************************************************************************
   * 설명 : 체크박스 처리
  ***************************************************************************************/
  function agGridCheckbox(params, props) {
    let check = ( params.value === '0' || params.value === null || params.value === 0 ) ? false : true;
    const className = ( props.className !== undefined ) ? 'Checkbox ' + props.className : 'Checkbox';

    return (
      <Checkbox
        className={className}
        onChange={(e) => {setColumnCheck(e, params)}}
        checked={check}
        size="small"
        color="primary"
      />
    )
  }

  /***************************************************************************************
   * 설명 : 헤더 체크
  ***************************************************************************************/
  function agGridHeaderCheckbox(params, props) {
    const className = ( props.className !== undefined ) ? 'Checkbox ' + props.className : 'Checkbox';

    return (
      <>
        <Checkbox
          className={className}
          onChange={(e) => {setColumnAll(e, params)}}
          color="primary"
        />
        <span>{params.displayName}</span>
      </>
    )
  }

  /***************************************************************************************
   * 설명 : 컬럼 변경 처리
  ***************************************************************************************/
  const addColumn = (project) => {
    columnDefsRef.current = columnDef;

    project.forEach((item) => {
      columnDefsRef.current.push(
        { headerName: '', field: item.projectNumber + '_Check', width: 40, cellClass: 'cp p0',
          headerComponentFramework: (params) => agGridHeaderCheckbox(params, props),
          cellRendererFramework: (params) => agGridCheckbox(params, props)
        }
      );
      columnDefsRef.current.push(
        { headerName: item.projectName, field: item.projectNumber, width: 100, cellClass: 'cp text-right', valueFormatter: CurrencyFormat,
          cellClassRules: {
            'aggrid-cell-max': (params) => {
              const key = params.columnApi.columnModel.columnDefs?.filter((item) => {
                return ( item.field.indexOf('_Check') > -1 ) ? true : false;
              });
              const tmp = key.map((item) => item.field.replace('_Check', ''));
              const keys = [...new Set(tmp)];

              if( keys.length > 2 ) {
                // 0을 제외한 프로젝트 갯수가 3개이상일 경우만 판단
                let projectCount = keys.filter((item) => parseInt(params.data[item] ?? 0) > 0);

                if( projectCount.length > 2 ) {
                  let tmp = keys.map((item) => params.data[item] ?? 0);
                  let tmp1 = Math.max(...tmp);

                  if( params.value !== 0 && parseInt(tmp1) === parseInt(params.value ?? 0) ) {
                    return true;
                  }
                }
              }

              return false;
            },
            'aggrid-cell-min': (params) => {
              const key = params.columnApi.columnModel.columnDefs?.filter((item) => {
                return ( item.field.indexOf('_Check') > -1 ) ? true : false;
              });
              const tmp = key.map((item) => item.field.replace('_Check', ''));
              const keys = [...new Set(tmp)];

              if( keys.length > 2 ) {
                // 0을 제외한 프로젝트 갯수가 3개이상일 경우만 판단
                let projectCount = keys.filter((item) => parseInt(params.data[item] ?? 0) > 0);

                if( projectCount.length > 2 ) {
                  let tmp = keys.map((item) => params.data[item] ?? 0);
                  let tmp2 = tmp.filter((item) => item > 0 );
                  let tmp1 = Math.min(...tmp2);

                  if( params.value !== 0 && parseInt(tmp1) === parseInt(params.value ?? 0) ) {
                    return true;
                  }
                }
              }

              return false;
            }
          }
        }
      );
    });
  }

  /***************************************************************************************
   * 설명 : 저장하기
  ***************************************************************************************/
  const save = () => {
    // 프로젝트 적용된 리스트 추출
    let tmp = projectList.map((item) => {
      let index = 0;
      return list.map((subItem) => {
        return {
          costFormSeq: subItem.costFormSeq,
          costItemSeq: subItem.costItemSeq ?? index--,
          projectNumber: item.projectNumber,
          baseAmt: subItem[item.projectNumber],
          isApply: subItem[item.projectNumber + '_Check'],
          sortOrder: subItem.rowIndex
        }
      })
    });

    // 프로젝트 리스트 추출
    let projectNumberList = projectList.map((item) => {
      return {
        costFormSeq: selectedGroup.seq,
        projectNumber: item.projectNumber
      }
    })

    let tmp1 = [];
    gridApiDetail.forEachNode((item, index) => {
      let tmp2 = {
        ...item.data
      }

      tmp2.sortOrder = index;

      tmp1.push(tmp2);
    })

    let params = {
      list: tmp1,
      projectList: tmp.flat(),
      projectNumberList: projectNumberList
    }

    setCostFormDetailApi(getApi, params, (response) => {
      if( response !== undefined && response.data.result ) {
        alertMsg(response.data.message, "S", MESSAGE_DELAY);

      } else {
        alertMsg(response.data.message || '서버와의 통신에 실패하였습니다.', "E", MESSAGE_DELAY);
      }
    });
  }

  /***************************************************************************************
   * 설명 : 검색 조건에 따른 검색
  ***************************************************************************************/
  const getList = () => {
    let params = {
      ...formik.values
    }

    getCostFormListApi(getApi, params, (response) => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        setGroupList(response.data.data);
      } else {
        setGroupList([]);
      }
    });
  }

  /***************************************************************************************
   * 설명 : 설계비 가산정 양식 삭제
  ***************************************************************************************/
  const deleteCostForm = () => {
    let nodes = gridApi.getSelectedRows();

    let params = {
      seq: nodes[0].seq
    }

    deleteCostFormApi(getApi, params, (response) => {
      if( response !== undefined && response.data.result ) {
        alertMsg(response.data.message, "S", MESSAGE_DELAY);

        getList();

        setList([]);

      } else {
        alertMsg(response.data.message || '서버와의 통신에 실패하였습니다.', "E", MESSAGE_DELAY);
      }
    })
  }

  /***************************************************************************************
   * 설명 : 설계비가산정 양식 상세항목 삭제
  ***************************************************************************************/
  const deleteCostFormDetail = () => {
    let nodes = gridApiDetail.getSelectedRows();

    // 로컬에서 삭제
    let tmp = nodes.filter((item) => ( item.seq ?? '' ) === '' );
    if( tmp.length > 0 ) {
      let tmp2 = list.filter((item) => {
        let tmp1 = tmp.filter((subItem) => subItem.costItemSeq === item.costItemSeq);
        if( tmp1.length > 0 )
          return false;
        else
          return true;
      });

      setList(tmp2);
    }

    // 서버에서 삭제
    tmp = nodes.filter((item) => ( item.seq ?? '' ) !== '' );
    if( tmp.length > 0 ) {
      let params = {
        list: nodes
      }

      deleteCostFormDetailApi(getApi, params, (response) => {
        if( response !== undefined && response.data.result ) {
          alertMsg(response.data.message, "S", MESSAGE_DELAY);

          getDetail();

        } else {
          alertMsg(response.data.message || '서버와의 통신에 실패하였습니다.', "E", MESSAGE_DELAY);
        }
      });
    }
  }

  /***************************************************************************************
   * 설명 : 시작 종료년도 가져오기
  ***************************************************************************************/
  const getCostYear = () => {
    getCostYearApi(getApi, (response) => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        setYear(getSelectData(response.data.data, 'year', 'year'));

        formik.setFieldValue('startProjectYear', response.data.data[0].year);
        formik.setFieldValue('endProjectYear', response.data.data[0].year);
      } else {
        setYear([]);
      }
    });
  }

  /***************************************************************************************
   * 설명 : 용도 가져오기
  ***************************************************************************************/
  const getPurpose = () => {
    getCostPurposeApi(getApi, (response) => {
      if( response !== undefined && response.data.result !== false && response.data.data && response.data.data.length > 0 ) {
        setPurpose(getSelectData([
          ...response.data.data.filter((item) => {
            return item.commonCode.length === 3
          })], 'commonName', 'commonCode')
        );
      } else {
        setPurpose([]);
      }
    });
  }

  /***************************************************************************************
   * 설명 : 발주처 리스트 가져오기
  ***************************************************************************************/
  const getCommonList = () => {
    getCommonListApi(getApi, {groupCode: 'ORDER'}, (response) => {
      if( response !== undefined && response.data.result !== false && response.data.data && response.data.data.length > 0 ) {
        setOrder(getSelectData(response.data.data, 'commonName', 'commonCode'));
      } else {
        setOrder([]);
      }
    })
  }

  /***************************************************************************************
   * 설명 : 양식 디테일 불러오기
  ***************************************************************************************/
  const getDetail = () => {
    init();

    let params = {
      seq: selectedGroup.seq
    }

    getCostFormDetailApi(getApi, params, (response) => {
      if( response !== undefined && response.data.result !== false && response.data.data && response.data.data.length > 0 ) {
        let tmp = [...response.data.data.map((item) =>
          {
            return {
              seq: item.seq,
              type: item.type,
              costItemSeq: item.costItemSeq,
              costFormSeq: item.costFormSeq,
              title: item.title,
              avgAmt: item.avgAmt,
              baseAmt: item.baseAmt,
              memo: item.memo,
              sortOrder: item.sortOrder,
              sortOrder1: item.sortOrder1,
            }
          })
        ];

        // 프로젝트 적용 처리
        if( response.data.data1 && response.data.data1.length > 0 && response.data.data2 && response.data.data2.length > 0 ) {
          let key = response.data.data1.map((item) => item.projectNumber);

          let tmp1 = tmp.map((subItem) => {
            let tmp2 =  {
              ...subItem,
            }

            key.forEach((projectNumber) => {
              let tmp5 = response.data.data2.filter((subItem2) => {
                return subItem2.costItemSeq === subItem.costItemSeq && subItem2.projectNumber === projectNumber
              })

              tmp2[projectNumber] = tmp5[0]?.baseAmt ?? 0;
              tmp2[projectNumber + '_Check'] = parseInt(tmp5[0]?.isApply ?? 1);
            });

            return tmp2;
          });

          tmp = tmp1;
        }

        setList(tmp);

        setProjectList([
          ...response.data.data1.map((item) => {
            return {
              label: item.projectName,
              value: item.projectNumber,
              ...item
            }
          })
        ]);

        // 컬럼 설정
        addColumn(response.data.data1);

      } else {
        setList([]);

        setProjectList([]);
      }

    });
  }

  /***************************************************************************************
   * 설명 : 데이터 초기화
  ***************************************************************************************/
  const init = () => {
    // 선택된 프로젝트 초기화
    setProjectList([]);

    // 컬럼 초기화
    columnDefsRef.current = columnDef;
  }

  /***************************************************************************************
   * 설명 : 양식 마스터 변경 시 디테일 불러오기
  ***************************************************************************************/
  useEffect(() => {
    if( (selectedGroup.seq ?? '') !== '' ) {
      getDetail();

    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[selectedGroup]);

  /***************************************************************************************
   * 설명 : 리스트 변경 시 포커스 처리
  ***************************************************************************************/
  useEffect(() => {
    if( list && list.length > 0 && gridApiDetail.ensureIndexVisible !== undefined && selectedDetail.length > 0) {
      setAgGridFocus(gridApiDetail, 'seq', selectedDetail[0].seq);
    }

    // eslint-disable-next-line
  }, [list]);

  /***************************************************************************************
   * 설명 : 데이터 검색
  ***************************************************************************************/
  useEffect(() => {
    if( groupList.length > 0 )
      getList();

    // eslint-disable-next-line
  }, [
    formik.values.startProjectYear, formik.values.endProjectYear,
    formik.values.purpose, formik.values.orderTypeName
  ]);

  /***************************************************************************************
   * 설명 : 데이터 처리
  ***************************************************************************************/
  useEffect(() => {
    if( year.length > 0 && formik.values.year !== '' ) {
      getList();
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year])

  /***************************************************************************************
   * 설명 : 데이터 처리
  ***************************************************************************************/
  useEffect(() => {
    // 용도
    getPurpose();

    // 발주처
    getCommonList();

    // 시작, 종료년도 가져오기
    getCostYear();

    // eslint-disable-next-line
  }, []);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <Layout>
      <section className={"admin-cms-contents cost-page "  + costReduce}>
        <SplitPane split="vertical" minSize={costMin} defaultSize={costMax}>
          <div className="left-area">
            <div className="pad-reduce-btn">
              <i className={costReduce === 'reduce' ? "ri-arrow-right-circle-fill reduce-icon" : "ri-arrow-left-circle-fill reduce-icon"} onClick={() => setCostReduceClick()}></i>
            </div>
            <div className="reduce-blank">
              <form onSubmit={formik.handleSubmit}>
                <header>
                  <div className="mt5 mb5">
                    <SelectEx
                      name="startProjectYear"
                      formik={formik}
                      fullWidth={false}
                      style={{width: 'calc(50% - 5px)', marginRight: '5px'}}
                      data={[
                        {label: '연도 시작', value: ''},
                      ].concat(year)}
                    />
                    <SelectEx
                      name="endProjectYear"
                      formik={formik}
                      fullWidth={false}
                      style={{width: '50%'}}
                      data={[
                        {label: '연도 끝', value: ''},
                      ].concat(year)}
                    />
                  </div>
                  <div className="mb5">
                    <SelectEx
                      name="purpsoe"
                      formik={formik}
                      fullWidth={false}
                      style={{width: 'calc(50% - 5px)', marginRight: '5px'}}
                      data={[
                        {label: '용도 선택', value: ''}
                      ].concat(purpose)}
                    />
                    <SelectEx
                      name="order"
                      formik={formik}
                      fullWidth={false}
                      style={{width: '50%'}}
                      data={[
                        {label: '발주처 선택', value: ''},
                      ].concat(order)}
                    />
                  </div>

                  <div className="flex between">
                    <InputEx
                      name="searchText"
                      formik={formik}
                      placeholder="양식명"
                      fullWidth={true}
                      className="search-input inputbox"
                      style={{width: 'calc(100% - 70px)'}}
                    />

                    <Button
                      variant="outlined"
                      color="primary"
                      className="Btn ml10"
                      onClick={(event) => {
                        getList();
                      }}
                    >검색</Button>
                  </div>

                  <div className="clearfix mt5">
                    <div className="fl">
                      <BtnRefresh click={() => {
                        formik.setValues(initialValues);
                        formik.handleSubmit();
                      }}></BtnRefresh>
                      <BtnAgGridSave click={setGridUpdateGroup}></BtnAgGridSave>
                    </div>

                    <div className="fr">
                      <ButtonEx
                        title="삭제"
                        auth="isDelete"
                        color="error"
                        variant="outlined"
                        className="ml5"
                        onClick={() => {
                          let nodes = gridApi.getSelectedRows();

                          if( nodes.length < 1 ) {
                            alertMsg("삭제할 설계비가산정 양식을 선택하시기 바랍니다.", "W", MESSAGE_DELAY);
                            return;
                          }

                          setOpenModal({open: true, modal: 'masterDialog', data: {}});
                        }}
                      />

                      <ButtonEx
                        title="추가"
                        auth="isWrite"
                        color="secondary"
                        className="ml5"
                        variant="outlined"
                        // disabled={selectedGroup.seq === undefined}
                        onClick={() => {
                          setOpenModal({open: true, modal: 'masterAdd', data: groupList})
                        }}
                      />
                    </div>
                  </div>
                </header>
              </form>

              <section className="admin-cms-body mt5">
                <section className="ag-theme-balham pad-height320" style={{height:'calc(100vh - 230px)'}}>
                  <div style={{ height: '100%' }}>
                    <AgGridReact
                      defaultColDef={{
                        sortable: true,
                        resizable: true,
                        filter: false,
                        lockVisible: true,
                        tooltipComponent: customTooltipAgGrid,
                      }}
                      tooltipShowDelay={0}
                      tooltipHideDelay={2000}
                      rowSelection = {'single'}
                      columnDefs={columnDefsGroup}
                      rowData={groupList}
                      onGridReady={(event) => {
                        gridConfigGroup(event.columnApi);
                        setGridApi(event.api);
                      }}
                      onRowClicked={(event) => {
                        setSelectedGroup(event.data);
                      }}
                      onRowDoubleClicked={(event) => {
                        setOpenModal({open: true, modal: 'masterAdd', data: event.data});
                      }}
                      rowDragManaged={true}
                      rowDragMultiRow={true}
                      animateRows={true}
                      overlayNoRowsTemplate = "검색된 내용이 없습니다."
                    />
                  </div>
                </section>
              </section>
            </div>
          </div>

          <div className="">
            <section className="">
              <header>
                <table className="input-table mt10">
                  <colgroup>
                    <col style={{width: '5%'}} />
                    <col style={{width: '5%'}} />
                    <col style={{width: '5%'}} />
                    <col style={{width: '10%'}} />
                    <col style={{width: '5%'}} />
                    <col style={{width: '10%'}} />
                    <col style={{width: '5%'}} />
                    <col style={{width: '30%'}} />
                  </colgroup>

                  <tbody>
                    <tr>
                      <th scope="row">연도</th>
                      <td className="center">{selectedGroup?.year}</td>
                      <th scope="row">용도</th>
                      <td className="center">{selectedGroup?.purposeName}</td>
                      <th scope="row">발주처</th>
                      <td className="center">{selectedGroup?.orderTypeName}</td>
                      <th scope="row">양식명</th>
                      <td className="pl10">{selectedGroup?.title}</td>
                    </tr>
                  </tbody>
                </table>
              </header>

              <section className="ag-theme-balham grid pad-height242" style={{height:'calc(100vh - 175px)'}}>
                <div className="" style={{ height: '100%' }}>
                  <header className="admin-cms-search-header mb5">
                    <div className="left">
                      <div className="fl">
                        <BtnAgGridSave click={setGridUpdate}></BtnAgGridSave>
                      </div>

                      <div className="fr">
                        <ButtonEx
                          title="삭제"
                          auth="isDelete"
                          color="error"
                          variant="outlined"
                          disabled={(selectedGroup.seq ?? '') === ''}
                          onClick={() => {
                            let nodes = gridApiDetail.getSelectedRows();

                            if( nodes.length < 1 ) {
                              alertMsg("삭제할 설계비가산정 상세항목을 선택하세요.", "W", MESSAGE_DELAY);
                              return;
                            }

                            // 기본 항목이 선택된 경우 삭제 불가
                            let tmp = nodes.filter((item) => parseInt(item.type) === 0);
                            if( tmp.length > 0 ) {
                              alertMsg("기본항목은 삭제하실 수 없습니다.", "W", MESSAGE_DELAY);
                              return;
                            }

                            setOpenModal({open: true, modal: 'delDetail', data: {}})
                          }}
                        />

                        <ButtonEx
                          title="외주용역비"
                          auth="isWrite"
                          color="secondary"
                          className="ml5"
                          variant="outlined"
                          disabled={(selectedGroup.seq ?? '') === ''}
                          onClick={() => {
                            setOpenModal({open: true, modal: 'workKind', data: {}})
                          }}
                        />

                        <ButtonEx
                          title="기타"
                          auth="isWrite"
                          color="secondary"
                          className="ml5"
                          variant="outlined"
                          disabled={(selectedGroup.seq ?? '') === ''}
                          onClick={() => {
                            setOpenModal({open: true, modal: 'extraAdd', data: {}})
                          }}
                        />
                      </div>
                    </div>

                    <div className="text-right">
                      <ButtonEx
                        title="참조프로젝트 관리"
                        auth="isWrite"
                        color="secondary"
                        className="ml5"
                        variant="outlined"
                        disabled={(selectedGroup.seq ?? '') === ''}
                        onClick={() => {
                          setOpenModal({open: true, modal: 'projectAdd', data: {}})
                        }}
                      />

                      <ButtonEx
                        title="저장"
                        auth="isWrite"
                        color="secondary"
                        className="ml5"
                        disabled={(selectedGroup.seq ?? '') === ''}
                        variant="contained"
                        onClick={() => setOpenModal({open: true, modal: 'saveConfirm', data: {}})}
                      />
                    </div>
                  </header>

                  <AgGridReact
                    defaultColDef={{
                      sortable: true,
                      resizable: true,
                      filter: false,
                      lockVisible: true,
                      tooltipComponent: customTooltipAgGrid,
                    }}
                    tooltipShowDelay={0}
                    tooltipHideDelay={2000}
                    rowSelection = {'multiple'}
                    columnDefs={columnDefsRef.current}
                    rowData={list}
                    onGridReady={(event) => {
                      gridConfig(event.columnApi);
                      setGridApiDetail(event.api);
                      // setColumnApiDetail(event.columnApi);
                    }}
                    rowDragManaged={true}
                    rowDragMultiRow={true}
                    animateRows={true}
                    overlayNoRowsTemplate = "검색된 내용이 없습니다."
                  />
                </div>
              </section>
            </section>
          </div>
        </SplitPane>
      </section>

      { openModal.open && openModal.modal === 'masterAdd' &&
        <Dialog
          open={openModal.open}
          onClose={() => setOpenModal({ open: false, modalName: 'masterAdd', data: openModal.data })}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "450px",  // Set your width here
              },
            },
          }}
        >
          <CostingModal
            open={openModal.open}
            close={(result) => {
              // 년도가 추가된 경우를 위해 새로 가져옴
              if(result)
                getCostYear();

              setOpenModal({ open: false, modalName: 'masterAdd', data: openModal.data })
            }}
            group={selectedGroup}
            data={openModal.data}
            setGroupList={setGroupList}
            getList={getList}
            indicator={props.indicator}
            history={props.history}
          />
        </Dialog>
      }

      { openModal.open && openModal.modal === 'masterDialog' &&
        <Dialog
          open={openModal.open}
          onClose={() => setOpenModal({ open: false, modalName: 'masterDialog', data: openModal.data })}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "500px",  // Set your width here
              },
            },
          }}
        >
          <AlertDialog
            open={openModal.open}
            close={() => setOpenModal({ open: false, modalName: 'masterDialog', data: openModal.data })}
            title="모듈 삭제"
            message={`선택하신 설계비가산정 양식을 삭제하시겠습니까?`}
            confirm="삭제"
            onClick={deleteCostForm}
            indicator={props.indicator}
            history={props.history}
          />
        </Dialog>
      }

      { // 설계비가산정 양식 상세항목 삭제
        openModal.open && openModal.modal === 'delDetail' &&
        <Dialog
          open={openModal.open}
          onClose={() => setOpenModal({ open: false, modal: openModal.modal, data: openModal.data })}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "500px",  // Set your width here
              },
            },
          }}
        >
          <AlertDialog
            open={openModal.open}
            close={() => setOpenModal({ open: false, modal: openModal.modal, data: openModal.data })}
            title="상세항목 삭제"
            message={`선택하신 설계비가산정 양식 항목을 삭제하시겠습니까?`}
            confirm="삭제"
            onClick={() => {
              deleteCostFormDetail();
            }}
            indicator={props.indicator}
            history={props.history}
          />
        </Dialog>
      }


      { // 저장
        openModal.open && openModal.modal === 'saveConfirm' &&
        <Dialog
          open={openModal.open}
          onClose={() => setOpenModal({ open: false, modalName: openModal.modal, data: openModal.data })}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "500px",  // Set your width here
              },
            },
          }}
        >
          <AlertDialog
            open={openModal.open}
            close={() => setOpenModal({ open: false, modalName: openModal.modal, data: openModal.data })}
            title="설계비가산정양식 저장"
            message={`입력하신 설계비가산정 양식을 저장하시겠습니까?`}
            color="primary"
            confirm="저장"
            onClick={save}
            indicator={props.indicator}
            history={props.history}
          />
        </Dialog>
      }

      { // 참조 프로젝트 관리
        openModal.open && openModal.modal === 'projectAdd' &&
        <Dialog
          open={openModal.open}
          onClose={() => (event, reason) => {
            if(reason && reason === "backdropClick") return;
              setOpenModal({open: false, modal: openModal.modal, data: {}});
          }}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "1400px",  // Set your width here
              },
            },
          }}
        >
          <ProjectAddModal
            open={openModal.open}
            close={() => setOpenModal({ open: false, modalName: 'projectAdd', data: openModal.data })}
            data={openModal.data}
            columnDefs={columnDefsRef}
            list={list}
            setList={setList}
            addColumn={addColumn}
            projectList={projectList}
            setProjectList={setProjectList}
            indicator={props.indicator}
            history={props.history}
          />
        </Dialog>
      }

      { openModal.open && openModal.modal === 'workKind' &&
        <Dialog
          open={openModal.open}
          onClose={() => setOpenModal({ open: false, modalName: 'workKind', data: openModal.data })}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "450px",  // Set your width here
              },
            },
          }}
        >
          <WorkKindModal
            open={openModal.open}
            close={(result) => {
              // 항목이 선택된 경우
              if( result.result ) {
                // 중복된 항목 제거
                let nodes = result.data.filter((item) => {
                  let tmp1 = list.filter((subItem) => {
                    return subItem.costItemSeq === item.seq
                  });

                  return ( tmp1.length > 0 ) ? false : true
                })

                // 추가된 항목 데이터 설정
                let tmp = nodes.map((item) => {
                  let tmp1 = {
                    seq: '',
                    type: 1,
                    costFormSeq: selectedGroup.seq,
                    costItemSeq: item.seq,
                    title: item.title,
                    baseAmt: 0,
                    avgAmt: 0,
                    sortOrder: item.sortOrder,
                    memo: ''
                  }

                  // 추가된 프로젝트 항목 추가
                  if( projectList.length > 0 ) {
                    projectList.forEach((subItem) => {
                      tmp1[subItem.projectNumber] = item[subItem.projectNumber];
                      tmp1[subItem.projectNumber + '_Check'] = 1;
                    });
                  }

                  return tmp1;
                });

                // 기존의 항목에 추가된 항목 추가
                tmp = [...list.concat(tmp)];

                // 전체 항목 정렬
                tmp.sort((a, b) => {
                  if( a.type < b.type ) return -1;
                  else if( a.type > b.type ) return 1;
                  else {
                    if( a.sortOrder < b.sortOrder )
                      return -1;
                    else if( a.sortOrder > b.sortOrder )
                      return 1;
                    else
                      return 0;
                  }
                });

                tmp = calc(tmp);

                setList(tmp);

                setSelectedDetail([result.data]);
              }

              setOpenModal({ open: false, modalName: 'workKind', data: openModal.data })
            }}
            group={selectedGroup}
            data={openModal.data}
            projectList={projectList}
            indicator={props.indicator}
            history={props.history}
          />
        </Dialog>
      }

      { openModal.open && openModal.modal === 'extraAdd' &&
        <Dialog
          open={openModal.open}
          onClose={() => setOpenModal({ open: false, modalName: openModal.modal, data: openModal.data })}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "450px",  // Set your width here
              },
            },
          }}
        >
          <CostExtraModal
            open={openModal.open}
            close={(result) => {
              // 항목이 선택된 경우
              if( result.result ) {
                let tmp1 = list.filter((item) => item.type === 2);
                let sortOrder = 0;
                if( tmp1.length > 0 ) {
                  sortOrder = tmp1[tmp1.length - 1].sortOrder + 1;
                }

                // 추가된 항목 데이터 설정
                let tmp = {
                  seq: '',
                  type: 2,
                  costFormSeq: selectedGroup.seq,
                  costItemSeq: '',
                  title: result.data.title,
                  baseAmt: result.data.baseAmt,
                  avgAmt: 0,
                  sortOrder: sortOrder,
                  memo: ''
                }

                setList([...list].concat(tmp));
              }

              setOpenModal({ open: false, modalName: openModal.modal, data: openModal.data })
            }}
            data={openModal.data}
            indicator={props.indicator}
            history={props.history}
          />
        </Dialog>
      }
    </Layout>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default Costing;