/*****************************************************************************************
 * 설명 : 사용자 통합검색
 * URI : /user/TotalSearch
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Cookies } from 'react-cookie'
import * as Yup from "yup";
import { withStyles } from '@mui/styles';

import { InputEx } from 'components/inputEx';

import {checkEng, checkKor, checkHangul, isCheckRadio} from 'service/utils';

import UserLayout from 'pages/layout/userLayout';

import HelpInputBox from 'pages/layout/helpInputBox';
import DetailSearchBox from 'pages/layout/detailSearchBox';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const TotalSearch = ( props ) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const history = useNavigate();
  const cookies = new Cookies();

  const [isLoading, setIsLoading] = useState(false);

  // 상세검색 버튼 클릭시
  const [isDetail, setIsDetail] = useState(false);
  const [viewHelpInputBox, setViewHelpInputBox] = useState(false);

  const initialValues = {
    isReSearch: [],
    searchCheckbox1: ['on'],
    searchCheckbox2: ['on'],
    searchCheckbox3: ['on'],
    designProjectType: 1,
    isRelation: 0,
    relationSeq: 0,
    search: '',
    prevDesignPageNo: 1,
    designPageNo: 1,
    designPageRow: 10,
    prevProjectPageNo: 1,
    projectPageNo: 1,
    projectPageRow: 20,
    prevFilePageNo: 1,
    filePageNo: 1,
    filePageRow: 20,
    searchText: '',
    searchTextOrg: '',
    tab: '',
    projectDivision: '',
    projectNumber: '',
    projectStartYear: '',
    projectEndYear: '',
    projectName: '',
    departmentName: '',
    projectPm: '',
    projectType: '',
    businessType: '',
    builderType: '',
    purpose: '',
    scaleUnderStart: '',
    scaleUnderEnd: '',
    scaleStart: '',
    scaleEnd: '',
    structure: '',
    builderName: '',
    orderCompanyId: '',
    builderCompanyId: '',
    location: '',
    totalSizeStart: '',
    totalSizeEnd: '',
    isNewTech: '',
    newTech: '',
    isAwardInfo: '',
    isOwnership: '',
    isCooperationCompany: '',
    fileType: '',
    responsibilityConstructor: '',
    joinCompany: '',
    rate: '',
    betterThan: 0,
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object().shape({
      // search: Yup.string().max(100, "100자리").required(),
    }),
    onSubmit: (values) => {
      if( values !== '' ) {
        searchSubmit(values);
      }
    }
  });

  /***************************************************************************************
   * 설명 : 검색 처리
  ***************************************************************************************/
  const searchSubmit = (values) => {
    /*
    if( (values.search || '') === '' ) {
      alertMsg("검색어를 입력해 주시기 바랍니다.", "W", MESSAGE_DELAY);
      return;
    }
    */

    let tmp = window.localStorage.getItem('prevSearch');
    let tmp1 = [];

    if( tmp ) {
      tmp1 = JSON.parse(tmp);

      // 중복 검색어는 추가하지 않음
      let tmp2 = tmp1.filter((item) => item.search === values.search );
      if( tmp2.length < 1 )
        tmp1.unshift({ search: values.search });

    } else {
      tmp1.unshift({ search: values.search });
    }

    let tmp2 = JSON.stringify(tmp1);
    window.localStorage.setItem('prevSearch', tmp2);

    formik.setFieldValue('search', values.search);
    setViewHelpInputBox(false);

    let data = {...values};

    // 영문 오타일 경우 한글로 변경
    if( data.search !== '' && checkEng(data.search) ) {
      let tmp = checkHangul(data.search);
      if( checkKor(tmp) && ! checkEng(data.search) )
        data.search = tmp;
    }

    data.searchCheckbox1 = isCheckRadio(data.searchCheckbox1);;
    data.searchCheckbox2 = isCheckRadio(data.searchCheckbox1);;
    data.searchCheckbox3 = isCheckRadio(data.searchCheckbox1);;

    let uri = new URLSearchParams(data).toString();

    history("/user/search/list?" + uri);
    return;
  }

  /***************************************************************************************
   * 설명 : 데이터 로딩 처리
  ***************************************************************************************/
  useEffect(() => {
    let tmp = cookies.get("dagroup_searchtab");
    if( ( tmp || '' ) !== '' ) {
      formik.setFieldValue('tab', tmp);
    }

    setIsLoading(true);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const RadioEx = (props) => {
    return (
      <Radio
        {...props}
        sx={{
          '& .MuiSvgIcon-root': {
            fontSize: 15,
          },
        }}
      />
    )
  }

  const StyledFormControlLabel = withStyles(() => ({
    label: {
      fontSize: '0.9rem',
    }
  }))(FormControlLabel);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <UserLayout>
      <section className={"total-search " + (isDetail ? 'detail' : '')}>
        <section className="search-input">
          <div className="input-txt">검색어를 입력하세요</div>

          <form
            onSubmit={formik.handleSubmit}
          >
            <div
              className="searchBoxBlur"
              onBlur={() => {
                //setViewHelpInputBox(false)
              }}
            >
              <div className="searchBox">
                <InputEx
                  name="search"
                  formik={formik}
                  placeholder="프로젝트, 파일검색"
                  fullWidth={true}
                  inputProps={{
                    maxLength: 50
                  }}
                  className="search-input inputbox"
                  onClick={() => setViewHelpInputBox(!viewHelpInputBox)}
                  //onBlur={() => setViewHelpInputBox(false)}
                  autoComplete="off"
                />

                <i className="bx bx-search search-widget-icon"></i>
                <i className="bx bx-chevron-down search-widget-icon search-widget-icon-right"
                  onClick={() => {
                    setViewHelpInputBox(false)
                    setIsDetail( ! isDetail )
                  }}
                ></i>

                <HelpInputBox
                  formik={formik}
                  display={viewHelpInputBox}
                  searchSubmit={searchSubmit}
                  setViewHelpInputBox={setViewHelpInputBox}
                ></HelpInputBox>
              </div>
            </div>

            <div className="searchBoxBlur mt10">
              {isLoading &&
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  defaultValue={formik.values.tab}
                  name="radio-buttons-group"
                  className="flex"
                  onChange={(event) => {
                    formik.setFieldValue('tab', event.target.value);
                    cookies.set("dagroup_searchtab", event.target.value);
                  }}
                >
                  <StyledFormControlLabel value="design" control={<RadioEx />} label="디자인" className="f14" />
                  <StyledFormControlLabel value="project" control={<RadioEx />} label="프로젝트" />
                  <StyledFormControlLabel value="file" control={<RadioEx />} label="파일" />
                </RadioGroup>
              }
            </div>
          </form>

        </section>

        {isDetail &&
          <DetailSearchBox
            isSearchDetail={false}
            isDetail={isDetail}
            formik={formik}
            close={() => setIsDetail(false)}
            getSearchList={(values) => searchSubmit(values)}
          />
        }
      </section>

    </UserLayout>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default TotalSearch;