import { createSlice } from '@reduxjs/toolkit'

const siteInfoSlice = createSlice({
  name: 'siteInfo',
  initialState: {},
  reducers: {
    setSiteInfo: ( state, action ) => {
      return action.payload;
    }
  }
})

export const { setSiteInfo } = siteInfoSlice.actions;
export default siteInfoSlice.reducer;