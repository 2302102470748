/*****************************************************************************************
 * 설명 : 디자인 프로젝트관리
 * URI : /project/design
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useTheme } from '@mui/material/styles';
import { AgGridReact } from 'ag-grid-react';
import { useFormik } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';

import AlertDialog from 'components/alertDiolog';
import ChipEx from 'components/chipEx';
import { BtnRefresh, BtnSearch } from 'components/common';
import { ButtonEx, InputEx, SelectEx, ToggleButtonGroupEx } from 'components/inputEx';
import alertMsg from 'components/message';
import customTooltipAgGrid from 'components/tooltipAgGrid';

import { delDesignProjectApi, getProjectApi, getProjectInfoApi, setAddDesignProjectApi } from 'service/project';
import Restful from "service/restful";
import { comma, getSelectData, isCheckValue } from 'service/utils';

import { MESSAGE_DELAY } from 'config/config';

import Layout from 'pages/layout/layout';
import TabDesignFile from './tabFile';
import TabDesignFileInfo from './tabFileInfo';
import TabDesignProjectInfo from './tabProjectInfo';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const DesignProject = ( props ) => {

  const initOrderBy = [
    {label: '가나다순↑', value: 0},
    {label: '가나다순↓', value: 1},
    {label: '등록일순↑', value: 2},
    {label: '등록일순↓', value: 3},
    {label: '프로젝트번호↑', value: 4},
    {label: '프로젝트번호↓', value: 5},
  ];

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [getApi] = Restful();

  const theme = useTheme();

  const [gridApiProject, setGridApiProject] = useState({});

  // 탭 클릭 시 처리
  const [tabMenu, setTabMenu] = useState(0);

  const [selectedIndex, setSelectedIndex] = useState(0)
  const [selected, setSelected] = useState({});

  const [projectYear, setProjectYear] = useState([]);

  const [list, setList] = useState([]);

  const searchinitialValues = {
    projectYear: '',
    designProjectType: '',
    projectStatus: '',
    purpose: '',
    searchText: '',
    isConfirm: '',
    isFile: '',
    order: 0,
    isDesign: 1
  }

  const formik = useFormik({
    initialValues: searchinitialValues,
    validateOnChange: false,
    onSubmit: (values) => {
      getList(true);
    }
  });

  // 프로젝트 목록 축소
  const [projectReduce, setProjectReduce] = useState('');
  // sort
  const [sortView, setSortView] = useState('');
  const toggleSortView = () => {
    if (sortView === '') setSortView('view');
    else setSortView('');
  }

  const [openModal, setOpenModal] = useState({open: false, modal: 'add', data: []});

  // 참여업체 컬럼
  const [columnDefs] = useState([
    { headerName: '', field: 'seq', cellClass: 'cp', width: 35, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
    { headerName: '프로젝트', field: 'projectNumber', width: 100, cellClass: 'cp', flex: 1,
      cellRendererFramework: function(params) {
        return (
          <ListItem
            item={params.data}
            index={params.node.rowIndex}
          />
        )
      }
    },
  ]);

  const getRowHeight = useCallback((params) => {
    return 70;
  }, []);

  /***************************************************************************************
   * 설명 : 프로젝트 리스트 가져오기
  ***************************************************************************************/
  const getList = (isSelected) => {
    let params = {...formik.values};

    params.purpose = params.purpose?.value || '';

    // 기존 선택을 다시 선택해야 하는 경우 처리
    let index = 0;
    if( isSelected === false ) {
      gridApiProject.forEachNode((item) => {
        if( item.selected ) index = item.rowIndex;
      })
    }

    getProjectApi(getApi, params, (response) => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        setList(response.data.data);

        if( selectedIndex === -1 || isSelected === true ) {
          setSelectedIndex(0);
          setSelected(response.data.data[0]);
        } else {
          setSelectedIndex(index);
          setSelected(response.data.data[index]);
        }
      } else {
        setList([]);
        setSelectedIndex(-1);
        setSelected({});
      }
    })
  }

  /***************************************************************************************
   * 설명 : 프로젝트 년도 / 건축구분 / purpose 가져오기
  ***************************************************************************************/
  const getInfo = () => {

    const setData = (data, setData, name, value) => {
      if( data && data.length > 0 )
        setData(getSelectData(data, name, value));
      else
        setData([]);
    }

    getProjectInfoApi(getApi, formik.values, (response) => {
      if( response !== undefined && response.data.result ) {
        // 프로젝트 최소 년도 ~ 최대 년도
        setData(response.data.data, setProjectYear, 'projectYear', 'projectYear');

        if( response.data.data && response.data.data.length > 0 )
          formik.setFieldValue("projectYear", response.data.data[0].projectYear);

      } else {
        setProjectYear([]);
      }
    })
  }

  /***************************************************************************************
   * 설명 : 디자인 프로젝트 추가
  ***************************************************************************************/
  const addDesignProject = () => {
    setAddDesignProjectApi(getApi, formik.values, (response) => {
      if( response !== undefined && response.data.result ) {
        alertMsg(response.data.message, "S", MESSAGE_DELAY);

        getList(true);

      } else {
        alertMsg(response.data.message || '서버와의 통신에 실패하였습니다.', "E", MESSAGE_DELAY);
      }
    })
  }

  /***************************************************************************************
   * 설명 : 디자인 프로젝트 삭제
  ***************************************************************************************/
  const delDesignProject = () => {
    let nodes = gridApiProject.getSelectedRows();

    if( nodes.length < 1 ) {
      alertMsg("삭제할 디자인 프로젝트를 선택하시기 바랍니다.", "W", MESSAGE_DELAY);
      return;
    }

    let params = {
      projectNumber: nodes[0].projectNumber
    }

    delDesignProjectApi(getApi, params, (response) => {
      if( response !== undefined && response.data.result ) {
        alertMsg(response.data.message, "S", MESSAGE_DELAY);

        getList(true);

      } else {
        alertMsg(response.data.message || '서버와의 통신에 실패하였습니다.', "E", MESSAGE_DELAY);
      }
    })
  }

  /***************************************************************************************
   * 설명 : 프로젝트 리스트 컴포넌트
  ***************************************************************************************/
  const ListItem = (props) => {
    return (
      <div
        style={{ display: 'block', height: '70px', padding: '5px 20px 0px 0px' }}
        className="cp"
      >
        <div className="fl project-info">
          <div>
            {// 프로젝트 추가 정보 상태
              props.item.isConfirm === null
              ? <ChipEx color="error" variant="variant" size="small" className="chip-tiny" label="등록" />
              : ( !isCheckValue(props.item.detailSeq) && parseInt(props.item.isConfirm) !== 1 )
                ? <ChipEx color="error" variant="variant" size="small" className="chip-tiny" label="등록" />
                : ( isCheckValue(props.item.detailSeq) && parseInt(props.item.isConfirm) !== 1 )
                  ? <ChipEx color="success" variant="variant" size="small" className="chip-tiny" label="추가" />
                  : <ChipEx color="primary" variant="variant" size="small" className="chip-tiny" label="등록완료" />
            }

            {// 프로젝트 파일 정보 상태
            props.item.isUploadCheck === 0 ? <ChipEx color="info" variant="variant" size="small" className="chip-tiny ml5" label="처리중" />
            : !isCheckValue(props.item.isFile) || parseInt(props.item.isFile) === 0
                ? <ChipEx color="error" variant="variant" size="small" className="chip-tiny ml5" label="미등록" />
                : parseInt(props.item.isFile) === 1
                  ? <ChipEx color="secondary" variant="variant" size="small" className="chip-tiny ml5" label="등록중" />
                  : parseInt(props.item.isFile) === 2
                    ? <ChipEx color="success" variant="variant" size="small" className="chip-tiny ml5" label="등록완료" />
                    : <ChipEx color="primary" variant="variant" size="small" className="chip-tiny ml5" label="처리완료" />
            }

            <span className="ml5 f12">{props.item.projectNumber}</span>
          </div>
          <div className="mt5 project-nm">
            <span className="normal">{props.item.projectName}</span>
          </div>
        </div>
      </div>
    )
  }

  /***************************************************************************************
   * 설명 : 프로젝트 리스트 가져오기
  ***************************************************************************************/
  useEffect(() => {
    getList(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formik.values.projectYear,
    formik.values.designProjectType,
    formik.values.isConfirm,
    formik.values.isFile
  ]);

  /***************************************************************************************
   * 설명 : 초기 데이터 처리
  ***************************************************************************************/
  useEffect(() => {
    getInfo();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <Layout props={props}>
      <section className={"project " + projectReduce}>
        <section className="left-area">

          <header className="left-header">
            <form onSubmit={formik.handleSubmit}>
              <div className="h2 mb5">프로젝트 목록</div>
              <div className="fr">
                <i className="ri-arrow-left-circle-fill reduce-icon" onClick={() => setProjectReduce('reduce')}></i>
              </div>

              <div className="clearfix"></div>

              <div className="fr">
                <ButtonEx
                  auth="isDelete"
                  title="프로젝트 삭제"
                  color="error"
                  variant="outlined"
                  onClick={(event) => {
                    let nodes = gridApiProject.getSelectedRows();

                    if( nodes.length < 1 ) {
                      alertMsg("선택하실 디자인 프로젝트를 선택하시기 바랍니다.", "W", MESSAGE_DELAY);
                      return;
                    }

                    /*
                    if( ( nodes[0].isFile || 0 ) !== 0 ) {
                      alertMsg("파일이 등록된 디자인 프로젝트는 삭제가 불가능합니다.", "W", MESSAGE_DELAY);
                      return;
                    }
                    */

                    setOpenModal({open: true, modal: 'del', data: {}})
                  }}
                />

                <ButtonEx
                  auth="isWrite"
                  title="프로젝트 추가"
                  color="secondary"
                  variant="outlined"
                  className="ml5"
                  onClick={(event) => setOpenModal({open: true, modal: 'add', data: {}})}
                />
              </div>

              <div className="clearfix"></div>

              <div className="mt5 mb5">
                <SelectEx
                  name="projectYear"
                  formik={formik}
                  fullWidth={true}
                  style={{width: 'calc(50% - 5px)', marginRight: '5px'}}
                  data={[
                    {label: '년도 선택', value: ''}
                  ].concat(projectYear)}
                />

                <SelectEx
                  name="designProjectType"
                  formik={formik}
                  fullWidth={false}
                  style={{width: '50%'}}
                  data={[
                    {label: '구분', value: ''},
                    {label: '본사', value: 1},
                    {label: '타사', value: 2},
                    {label: '외부', value: 3},
                  ]}
                />
              </div>

              <InputEx
                name="searchText"
                formik={formik}
                label="프로젝트명, 프로젝트번호 검색"
                style={{width: '100%'}}
              />

              <div className="clearfix"></div>

              <div className="mt5">
                <div className="">
                  <ToggleButtonGroupEx
                    name="isConfirm"
                    exclusive={true}
                    formik={formik}
                    className="f12"
                    data={[
                      {label: '추가정보 전체', value: ''},
                      {label: '등록', value: 1},
                      {label: '추가', value: 2},
                      {label: '등록완료', value: 3},
                    ]}
                  />
                </div>
                <div className="mt5">
                  <ToggleButtonGroupEx
                    name="isFile"
                    exclusive={true}
                    formik={formik}
                    className="f12"
                    data={[
                      {label: '파일 전체', value: ''},
                      {label: '미등록', value: 0},
                      {label: '등록중', value: 1},
                      {label: '등록완료', value: 2},
                      {label: '처리완료', value: 3},
                    ]}
                  />
                </div>
              </div>

              <div className="clearfix"></div>

              <div className="clearfix mt10">
                <div className="fl">
                  <BtnRefresh click={() => {
                    formik.setValues(searchinitialValues);
                    formik.handleSubmit();
                  }} />
                  <BtnSearch />
                </div>
                <div className="cnt" style={{lineHeight: '28px'}}>총 {comma(list.length || 0)}개</div>
                <div className={"sort " + sortView}>
                  <div className="selected" onClick={() => toggleSortView()} style={{lineHeight: '28px'}}>
                    {initOrderBy[formik.values.order].label}
                  </div>
                  <ul className="select-list" onClick={() => setSortView('')}>
                    {initOrderBy.map((item) => {
                      return (
                        <li onClick={() => {
                          formik.setFieldValue('order', item.value);
                          formik.handleSubmit();
                        }}>{item.label}</li>
                      )
                    })}
                  </ul>
                </div>
              </div>
            </form>
          </header>

          <div className="clearfix"></div>

          <div className="ag-theme-balham header-hide pad-height375" style={{height: 'calc(100vh - 335px)'}}>
            <AgGridReact
              defaultColDef={{
                sortable: true,
                resizable: true,
                filter: false,
                lockVisible: true,
                headerHeight: 0,
                tooltipComponent: customTooltipAgGrid,
              }}
              tooltipShowDelay={0}
              tooltipHideDelay={2000}
              alwaysShowVerticalScroll={true}
              columnDefs={columnDefs}
              rowData={list}
              rowSelection={'single'}
              getRowHeight={getRowHeight}
              onGridReady={(event) => {
                setGridApiProject(event.api);
              }}
              onRowDataUpdated={(event) => {
                event.api.ensureIndexVisible(selectedIndex, "middle");
                event.api.forEachNode((item) => {
                  if(item.rowIndex === selectedIndex) item.setSelected(true);
                })
              }}
              onRowClicked={(event) => {
                // 리스트 갱신여부 확인
                let tmp = window.localStorage.getItem('da_scan');
                let tmp1 = JSON.parse(tmp);

                if( tmp1 !== null && Array.isArray(tmp1) && tmp1?.length > 0 ) {
                  let tmp2 = tmp1.filter((item) =>
                    item.projectNumber === event.data.projectNumber && item.isCheck === true && item.isRefresh === false
                  );

                  if( tmp2.length > 0 ) {
                    tmp2[0].isRefresh = true;
                    window.localStorage.setItem('da_scan', JSON.stringify(tmp1));

                    getList(false);
                  }
                }

                setTabMenu(0);

                setSelectedIndex(event.rowIndex);
                setSelected(event.data);
              }}
              overlayNoRowsTemplate = "검색된 내용이 없습니다."
            />
          </div>
        </section>

        <div className="reduce-wrap"><i className="ri-arrow-right-circle-fill reduce-icon" onClick={() => setProjectReduce('')}></i></div>

        <section className="right-area Tab-page">
          <div className="project-pad">
            <section className="tab-header">
              <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs value={tabMenu} onChange={(event, index) => {
                    setTabMenu(index)
                  }} aria-label="basic tabs example">
                    {selected?.seq}
                    <Tab
                      className="tab-menu"
                      label="프로젝트 정보"
                      disabled={selected?.seq === undefined ? true : false}
                    />
                    <Tab
                      className="tab-menu"
                      label="파일관리"
                      disabled={selected?.seq === undefined ? true : false}
                    />
                    <Tab
                      className="tab-menu"
                      label="파일정보 관리"
                      disabled={selected?.seq === undefined ? true : false}
                    />
                  </Tabs>
                </Box>
              </Box>
            </section>

            {selected.seq !== undefined &&
              <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={tabMenu}
                onChangeIndex={(index) => {
                  setTabMenu(index)
                }}
                className="tab-contents pad-height165"
                style={{height: 'calc(100vh-135px'}}
              >
                <div
                  role="tabpanel"
                  hidden={tabMenu !== 0}
                  dir={theme.direction}
                >
                  {tabMenu === 0 && (
                    <TabDesignProjectInfo
                      tabIndex={tabMenu}
                      selected={selected}
                      setSelected={setSelected}
                      selectedIndex={selectedIndex}
                      setList={setList}
                      list={list}
                      year={projectYear}
                      getList={() => getList(true)}
                      gridApiProject={gridApiProject}
                      setGridApiProject={setGridApiProject}
                    />
                  )}
                </div>

                <div
                  role="tabpanel"
                  hidden={tabMenu !== 1}
                  dir={theme.direction}
                >
                  { tabMenu === 1 &&
                    <TabDesignFile
                      tabIndex={tabMenu}
                      selected={selected}
                      setSelected={setSelected}
                      selectedIndex={selectedIndex}
                      list={list}
                      setList={setList}
                    />
                  }
                </div>

                <div
                  role="tabpanel"
                  hidden={tabMenu !== 2}
                  dir={theme.direction}
                >
                  { tabMenu === 2 &&
                    <TabDesignFileInfo
                      tabIndex={tabMenu}
                      selected={selected}
                      setSelected={setSelected}
                      selectedIndex={selectedIndex}
                      list={list}
                      setList={setList}
                    />
                  }
                </div>
              </SwipeableViews>
            }
          </div>
        </section>

        { openModal.open && openModal.modal === 'add' &&
          <Modal
            open={openModal.open}
            onClose={() => setOpenModal({ open: false, modalName: openModal.modal, data: openModal.data })}
          >
            <AlertDialog
                open={openModal.open}
                close={() => setOpenModal({ open: false, modalName: openModal.modal, data: openModal.data })}
                title="디자인 프로젝트 추가"
                color="primary"
                message={`디자인 프로젝트를 추가 하시겠습니까?`}
                confirm="추가"
                onClick={() => {
                  addDesignProject();
                }}
              />
          </Modal>
        }

        { openModal.open && openModal.modal === 'del' &&
          <Modal
            open={openModal.open}
            onClose={() => setOpenModal({ open: false, modalName: openModal.modal, data: openModal.data })}
          >
            <AlertDialog
                open={openModal.open}
                close={() => setOpenModal({ open: false, modalName: openModal.modal, data: openModal.data })}
                title="디자인 프로젝트 삭제"
                color="primary"
                message={
                  <div>
                    <div className="">
                      선택하신 디자인 프로젝트를 삭제 하시겠습니까?
                    </div>
                    <div className="mt20 cred bold">
                      주의!!! 업로드된 파일이 전부 삭제 됩니다.
                    </div>
                  </div>
                }
                confirm="삭제"
                onClick={() => {
                  delDesignProject();
                }}
              />
          </Modal>
        }
      </section>
    </Layout>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default DesignProject;