/*****************************************************************************************
 * 설명 : 관리자 > 시스템관리 > 공통코드 관리 > 추가/수정
*****************************************************************************************/
import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import * as Yup from "yup";

import Restful from "service/restful";
import { setCommonApi } from 'service/common';
import { getSelectData } from 'service/utils';

import { SelectEx, InputEx, ToggleButtonGroupEx } from 'components/inputEx';
import alertMsg from 'components/message';
import { MESSAGE_DELAY } from 'config/config';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
export const CommonModal = ( props ) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [getApi] = Restful();

  const [title, setTitle] = useState('추가');

  const [groupList, setGroupList] = useState([]);

  const formik = useFormik({
    initialValues: {
      seq: 0,
      groupCode: '',
      commonCode: '',
      commonName: '',
      memo: '',
      isUse: 1,
      val1: '',
      val2: '',
      val3: '',
      val4: '',
      val5: ''
    },
    validationSchema: Yup.object().shape({
      commonName: Yup.string().max(45, "45자리").required('필수'),
      commonCode: Yup.string().max(20, "20자리").required('필수'),
      memo: Yup.string().max(1000, '1000자리').nullable(),
      val1: Yup.string().max(100, "100자리").nullable(),
      val2: Yup.string().max(100, "100자리").nullable(),
      val3: Yup.string().max(100, "100자리").nullable(),
      val4: Yup.string().max(100, "100자리").nullable(),
      val5: Yup.string().max(100, "100자리").nullable(),
    }),
    onSubmit: (values) => {
      setCommonApi(getApi, values, (response => {
        if( response !== undefined && response.data.result ) {
          alertMsg(response.data.message, "S", MESSAGE_DELAY);

          if( props.getList ) props.getList();

          props.close();

        } else {
          alertMsg(response.data.message || '서버와의 통신에 실패하였습니다.', "E", MESSAGE_DELAY);
        }
      }));
    }
  });

  /***************************************************************************************
   * 설명 : 모달 오픈 시 처리
  ***************************************************************************************/
  useEffect(() => {
    if( props.open ) {
      setGroupList(getSelectData(props.groupList, 'groupName', 'groupCode'));
      formik.setValues(props.data);

      if( props.data.seq !== undefined && props.data.seq !== '' && props.data.seq !== 0 ) {
        setTitle('수정');
      } else {
        setTitle('추가');
      }
    }

    // eslint-disable-next-line
  }, [props.open]);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <section className="modal-wrap" style={{ width: '550px' }}>
      <form onSubmit={formik.handleSubmit}>
        <header className="modal-header" style={{ cursor: 'move' }} id="draggable-dialog-title">
          <div className="modal-title">공통코드 {title}</div>
          <Button className="close-btn" onClick={props.close}>
            <CloseIcon style={{ color: '#fff', fontSize: '28px' }}></CloseIcon>
          </Button>
        </header>

        <section className="modal-body">
          <table className="input-table">
            <colgroup>
              <col style={{width: '30%'}} />
              <col style={{width: '70%'}} />
            </colgroup>

            <tbody>
              <tr>
                <th scope="row"><label htmlFor="groupCode">공통코드그룹</label></th>
                <td>
                  <SelectEx
                    name="groupCode"
                    formik={formik}
                    data={groupList}
                    disabled={true}
                  />
                </td>
              </tr>
              <tr>
                <th scope="row"><label htmlFor="commonCode">공통코드</label></th>
                <td>
                  <InputEx
                    name="commonCode"
                    formik={formik}
                    fullWidth={true}
                    inputProps={{maxLength: 20, autoFocus: true}}
                    placeholder={"공통코드 코드 입력(영문,숫자,_조합"}
                  />
                </td>
              </tr>
              <tr>
                <th scope="row"><label htmlFor="commonName">공통코드명</label></th>
                <td>
                  <InputEx
                    name="commonName"
                    formik={formik}
                    fullWidth={true}
                    inputProps={{maxLength: 45}}
                    placeholder={"공통코드명 입력"}
                  />
                </td>
              </tr>
              <tr>
                <th scope="row"><label htmlFor="val1">추가 값1</label></th>
                <td>
                  <InputEx
                    name="val1"
                    formik={formik}
                    fullWidth={true}
                    inputProps={{maxLength: 100}}
                  />
                </td>
              </tr>
              <tr>
                <th scope="row"><label htmlFor="val2">추가 값2</label></th>
                <td>
                  <InputEx
                    name="val2"
                    formik={formik}
                    fullWidth={true}
                    inputProps={{maxLength: 100}}
                  />
                </td>
              </tr>
              <tr>
                <th scope="row"><label htmlFor="val1">추가 값3</label></th>
                <td>
                  <InputEx
                    name="val3"
                    formik={formik}
                    fullWidth={true}
                    inputProps={{maxLength: 100}}
                  />
                </td>
              </tr>
              <tr>
                <th scope="row"><label htmlFor="val4">추가 값4</label></th>
                <td>
                  <InputEx
                    name="val4"
                    formik={formik}
                    fullWidth={true}
                    inputProps={{maxLength: 100}}
                  />
                </td>
              </tr>
              <tr>
                <th scope="row"><label htmlFor="val5">추가 값5</label></th>
                <td>
                  <InputEx
                    name="val5"
                    formik={formik}
                    fullWidth={true}
                    inputProps={{maxLength: 100}}
                  />
                </td>
              </tr>
              <tr>
                <th scope="row"><label htmlFor="memo">메모</label></th>
                <td>
                  <InputEx
                    name="memo"
                    formik={formik}
                    fullWidth={true}
                  />
                </td>
              </tr>
              <tr>
                <th scope="row"><label htmlFor="isUse">사용여부</label></th>
                <td>
                  <ToggleButtonGroupEx
                    name="isUse"
                    exclusive={true}
                    formik={formik}
                    data={[
                      {label: '사용', value: 1},
                      {label: '사용안함', value: 0},
                    ]}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </section>

        <footer className="modal-footer">
          <div className="modal-footer-center">
            <Button
              color="inherit"
              variant="outlined"
              className="Btn"
              onClick={() => {
                props.close();
              }}
            >창닫기</Button>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              className="Btn ml10"
            >저장하기</Button>
          </div>
        </footer>
      </form>
    </section>
  )
}

export default CommonModal;