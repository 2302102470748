/*****************************************************************************************
 * 설명 : 게시판 - 공지사항 - 상세보기
 * URI : /board/view
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import React, { useState } from 'react';
import { ButtonEx } from 'components/inputEx';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import AlertDialog from 'components/alertDiolog';
import Layout from 'pages/layout/layout';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const BoardView = (props) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [openModal, setOpenModal] = useState({open: false, modal: 'sync', data: []});

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <Layout>
      <div className="admin-cms-contents">
        <div className="page-contents">
          <table className="input-table mt10 board-table">
            <colgroup>
              <col style={{width: '15%'}} />
              <col style={{width: '35%'}} />
              <col style={{width: '15%'}} />
              <col style={{width: '35%'}} />
            </colgroup>

            <tbody>
              <tr>
                <th scope="row"><label htmlFor="">제목</label></th>
                <td colSpan={3}></td>
              </tr>
              <tr>
                <th scope="row"><label htmlFor="">작성자</label></th>
                <td></td>
                <th scope="row"><label htmlFor="">작성일시</label></th>
                <td></td>
              </tr>
              <tr>
                <th scope="row"><label htmlFor="">첨부파일</label></th>
                <td colSpan={3}></td>
              </tr>
              <tr>
                <td colSpan={4} style={{height: '500px'}} className="va-top">
                  게시글 내용입니다.
                </td>
              </tr>
            </tbody>
          </table>

          <footer className="mt10">
            <ButtonEx
              auth="isModify"
              title="수정하기"
              color="secondary"
              variant="outlined"
              className="Btn"
            />
            <ButtonEx
              auth="isDelete"
              title="삭제하기"
              color="error"
              variant="outlined"
              className="Btn ml5"
              onClick={() => {
                setOpenModal({open: true, modal: 'dialog', data: {}});
              }}
            />
            <div className="fr">
              <Button
                color="inherit"
                variant="outlined"
                className="Btn"
                href="/board"
              >목록으로</Button>
            </div>
          </footer>
        </div>

        { openModal.open && openModal.modal === 'dialog' &&
          <Modal
            open={openModal.open}
            onClose={() => setOpenModal({ open: false, modalName: 'dialog', data: openModal.data })}
          >
            <AlertDialog
              open={openModal.open}
              close={() => setOpenModal({ open: false, modalName: 'dialog', data: openModal.data })}
              title="게시글 삭제"
              message={`해당 게시글을 삭제하시겠습니까? 삭제된 게시글은 복구할 수 없습니다.`}
              confirm="확인"
            />
          </Modal>
        }

      </div>
    </Layout>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default BoardView;