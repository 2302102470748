/*****************************************************************************************
 * 설명 : 프로젝트관리 - 일괄등록 에러로그 조회
 * URI : /project
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import Button from '@mui/material/Button';
import { useEffect, useState } from 'react';

import { getProjectBatchErrorApi } from 'service/project';
import Restful from "service/restful";

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const ProjectBatchErrorModal = (props) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [getApi] = Restful();

  const [error, setError] = useState({});

  /***************************************************************************************
   * 설명 : 일괄등록 에러 가져오기
  ***************************************************************************************/
  const getList = () => {
    let params = {
      seq: props?.data.seq
    }

    getProjectBatchErrorApi(getApi, params, (response) => {
      if( response !== undefined && response.data.result && response.data.data ) {
        setError(response.data.data);

      } else {
        setError([]);
      }
    });
  }

  /***************************************************************************************
   * 설명 : 오픈 시 처리
  ***************************************************************************************/
  useEffect(() => {
    if( props.open ) {
      if( props.data?.seq !== undefined && props.data?.seq !== '' ) {
        getList();
      }
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <section className="modal">
      <section className="modal-wrap" style={{width: '100%'}}>
        <header className="modal-header" style={{ cursor: 'move' }} id="draggable-dialog-title">
          <div className="modal-title">일괄등록 에러로그</div>
          <Button className="close-btn" onClick={props.close}>×</Button>
        </header>

        <section className="modal-body">
          <div
            style={{height: 'calc(80vh - 140px)'}}
            dangerouslySetInnerHTML={{__html: error?.log}}
          ></div>
        </section>

        <footer className="modal-footer">
          <div className="modal-footer-center">
            <Button
              color="inherit"
              variant="outlined"
              className="Btn"
              onClick={() => {
                props.close();
              }}
            >창닫기</Button>
          </div>
        </footer>
      </section>
    </section>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default ProjectBatchErrorModal;