/*****************************************************************************************
 * 설명 : 프로젝트관리
 * URI : /project
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useTheme } from '@mui/material/styles';
import { AgGridReact } from 'ag-grid-react';
import { useFormik } from 'formik';
import * as moment from 'moment';
import qs from 'qs';
import { useCallback, useEffect, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';

import { BtnRefresh, BtnSearch } from 'components/common';
import { InputEx, SelectEx, SelectSearchEx } from 'components/inputEx';
import customTooltipAgGrid from 'components/tooltipAgGrid';

import { getProjectApi, getProjectInfoApi } from 'service/project';
import Restful from "service/restful";
import { comma, getSelectData } from 'service/utils';

import TabUserFile from './tabFile';
import TabUserProjectInfo from './tabProjectInfo';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const UserProject = ( props ) => {

  const initOrderBy = [
    {label: '가나다순↑', value: 0},
    {label: '가나다순↓', value: 1},
    {label: '등록일순↑', value: 2},
    {label: '등록일순↓', value: 3},
    {label: '프로젝트번호↑', value: 4},
    {label: '프로젝트번호↓', value: 5},
  ];

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [getApi] = Restful();

  const theme = useTheme();

  const [gridApiProject, setGridApiProject] = useState({});

  // 탭 클릭 시 처리
  const [tabMenu, setTabMenu] = useState(0);

  const [selectedIndex, setSelectedIndex] = useState(0)
  const [selected, setSelected] = useState({});

  const [company, setCompany] = useState([]);
  const [order, setOrder] = useState([]);
  const [partner, setPartner] = useState([]);
  const [projectYear, setProjectYear] = useState([]);
  const [divisionList, setDivisionList] = useState([]);
  // const [organization, setOrganization] = useState([]);

  const [purpose, setPurpose] = useState([]);
  const [purpose1, setPurpose1] = useState([]);
  const [purpose2, setPurpose2] = useState([]);
  const [businessType, setBusinessType] = useState([]);
  const [builderType, setBuilderType] = useState([]);
  const [pointOut, setPointOut] = useState([]);
  const [purposeRegion, setPurposeRegion] = useState([]);
  const [structure, setStructure] = useState([]);
  const [structure1, setStructure1] = useState([]);
  const [structure2, setStructure2] = useState([]);
  const [newTech, setNewTech] = useState([]);

  const [list, setList] = useState([]);

  const searchinitialValues = {
    projectYear: '',
    projectDivision: '',
    projectStatus: '',
    purpose: '',
    searchText: '',
    isConfirm: 3,
    isFile: 3,
    order: 0,
    isDesign: 0
  }

  const formik = useFormik({
    initialValues: searchinitialValues,
    validateOnChange: false,
    onSubmit: (values) => {
      getList();
    }
  });

  // 프로젝트 목록 축소
  const [projectReduce, setProjectReduce] = useState('');
  // sort
  const [sortView, setSortView] = useState('');
  const toggleSortView = () => {
    if (sortView === '') setSortView('view');
    else setSortView('');
  }

  // 참여업체 컬럼
  const [columnDefs] = useState([
    { headerName: '', field: 'seq', cellClass: 'cp', width: 35, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
    { headerName: '프로젝트', field: 'projectNumber', width: 100, cellClass: 'cp', flex: 1,
      cellRendererFramework: function(params) {
        return (
          <ListItem
            item={params.data}
            index={params.node.rowIndex}
          />
        )
      }
    },
  ]);

  const getRowHeight = useCallback((params) => {
    return 70;
  }, []);

  /***************************************************************************************
   * 설명 : 프로젝트 리스트 가져오기
  ***************************************************************************************/
  const getList = (projectNumber) => {
    let params = {...formik.values};

    if( projectNumber !== undefined && projectNumber !== '' )
      params = {
        projectNumber: projectNumber
      }
    else
      params.purpose = params.purpose?.value || '';

    getProjectApi(getApi, params, (response) => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        setList(response.data.data);

        setSelectedIndex(0);
        setSelected(response.data.data[0]);
      } else {
        setList([]);
        setSelectedIndex(-1);
        setSelected({});
      }
    })
  }

  /***************************************************************************************
   * 설명 : 프로젝트 년도 / 건축구분 / purpose 가져오기
  ***************************************************************************************/
  const getInfo = () => {

    const setData = (data, setData, name, value) => {
      if( data && data.length > 0 )
        setData(getSelectData(data, name, value));
      else
        setData([]);
    }

    const setDataOrigin = (data, setData) => {
      if( data && data.length > 0 )
        setData(data);
      else
        setData([]);
    }

    getProjectInfoApi(getApi, formik.values, (response) => {
      if( response !== undefined && response.data.result ) {
        // 프로젝트 최소 년도 ~ 최대 년도
        setData(response.data.data, setProjectYear, 'projectYear', 'projectYear');

        if( response.data.data && response.data.data.length > 0 )
          formik.setFieldValue("projectYear", response.data.data[0].projectYear);

        // 프로젝트 건축구분
        setData(response.data.data1, setDivisionList, 'projectDivisionName', 'projectDivision');

        // 업체정보
        setData(response.data.data2, setCompany, 'companyName', 'companyId');

        // 발주처
        let tmp = response.data.data2.filter((item) => parseInt(item.isOrder) === 1);
        setOrder(getSelectData(tmp, 'companyName', 'companyId'));

        // 협력사
        tmp = response.data.data2.filter((item) => parseInt(item.isPartner) === 1);
        setPartner(getSelectData(tmp, 'companyName', 'companyId'));

        // 용도
        if( response.data.data3 && response.data.data3?.length > 0 ) {
          setPurpose(response.data.data3);

          // 용도 1단계
          let tmp = response.data.data3.filter((item) => item.commonCode.length === 3);
          setPurpose1(getSelectData(tmp, 'commonName', 'commonCode'));

          // 용도 2단계
          tmp = response.data.data3.filter((item) => item.commonCode.length > 3);
          setPurpose2(getSelectData(tmp, 'commonName', 'commonCode'));
        } else {
          setPurpose([]);
          setPurpose1([]);
          setPurpose2([]);
        }

        // 사업유형
        setData(response.data.data4, setBusinessType, 'commonName', 'commonCode');

        // 건축주유형
        setData(response.data.data5, setBuilderType, 'commonName', 'commonCode');

        // 지목
        setData(response.data.data6, setPointOut, 'commonName', 'commonCode');

        // 용도 지역
        setData(response.data.data7, setPurposeRegion, 'commonName', 'commonCode');

        // 신기술
        setDataOrigin(response.data.data8, setNewTech);

        // 구조
        if( response.data.data9 && response.data.data9?.length > 0 ) {
          setStructure(response.data.data9);

          // 구조 1단계
          let tmp = response.data.data9.filter((item) => item.commonCode.length === 3);
          setStructure1(getSelectData(tmp, 'commonName', 'commonCode'));

          // 구조 2단계
          tmp = response.data.data9.filter((item) => item.commonCode.length > 3);
          setStructure2(getSelectData(tmp, 'commonName', 'commonCode'));
        } else {
          setStructure([]);
          setStructure1([]);
          setStructure2([]);
        }


      } else {
        setProjectYear([]);
        setDivisionList([]);
        setCompany([]);
        setOrder([]);
        setPartner([]);
        setPurpose([]);
        setPurpose1([]);
        setPurpose2([]);
        setBusinessType([]);
        setBuilderType([]);
        setPointOut([]);
        setPurposeRegion([]);
      }

      formik.setFieldValue("projectYear", moment().format('YYYY'));
    })
  }

  /***************************************************************************************
   * 설명 : 프로젝트 리스트 컴포넌트
  ***************************************************************************************/
  const ListItem = (props) => {
    return (
      <div
        style={{ display: 'block', height: '70px', padding: '5px 20px 0px 0px' }}
        className="cp"
      >
        <div className="fl project-info">
          <div>
            <span className="f12">{props.item.projectNumber}</span>
          </div>
          <div className="mt5 project-nm">
            <span className="normal">{props.item.projectName}</span>
          </div>
        </div>
      </div>
    )
  }

  /***************************************************************************************
   * 설명 : 프로젝트 리스트 가져오기
  ***************************************************************************************/
  useEffect(() => {
    if( projectYear.length > 0 && divisionList.length > 0 && purpose.length > 0 ) {
      getList();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formik.values.projectYear, formik.values.isConfirm,
    formik.values.projectDivision, formik.values.purpose, formik.values.isFile
  ]);

  /***************************************************************************************
   * 설명 : 초기 데이터 처리
  ***************************************************************************************/
  useEffect(() => {
    getInfo();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /***************************************************************************************
   * 설명 : 쿼리스트링 변경 시 처리
  ***************************************************************************************/
  useEffect(() => {
    if( projectYear.length > 0 ) {
      let query = qs.parse(window.location.search, {
        ignoreQueryPrefix: true
      });

      if( (query.projectNumber || '') !== '' ) {
        formik.setValues({
          projectYear: '',
          projectDivision: '',
          projectStatus: '',
          projectNumber: query.projectNumber,
          purpose: '',
          searchText: query.projectNumber,
          isConfirm: '',
          isFile: '',
          order: 0,
          isDesign: 0
        });

        if( query.tab === true || query.tab === 'true' ) {
          setTabMenu(1);
        } else {
          setTabMenu(0);
        }
      }
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectYear, window.location]);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <>
      <section className={"project " + projectReduce}>
        <section className="left-area">

          <header className="left-header">
            <form onSubmit={formik.handleSubmit}>
              <div className="h2 mb5">프로젝트 목록</div>
              <div className="fr">
                <i className="ri-arrow-left-circle-fill reduce-icon" onClick={() => setProjectReduce('reduce')}></i>
              </div>

              <div className="clearfix"></div>

              <div className="mt5 mb5">
                <SelectEx
                  name="projectYear"
                  formik={formik}
                  fullWidth={false}
                  style={{width: 'calc(50% - 5px)', marginRight: '5px'}}
                  data={[
                    {label: '년도 선택', value: ''}
                  ].concat(projectYear)}
                />
                <SelectEx
                  name="projectDivision"
                  formik={formik}
                  fullWidth={false}
                  style={{width: '50%'}}
                  data={[
                    {label: '건축구분', value: ''}
                  ].concat(divisionList)}
                />
              </div>

              <SelectSearchEx
                name="purpose"
                formik={formik}
                data={[
                  {label: '전체 검색', value: ''}
                ].concat(purpose2)}
                height={28}
                isMulti={false}
                closeMenuOnSelect={true}
                hideSelectedOptions={true}
                fullWidth={true}
                optionWidth="100%"
                placeholder="용도 전체 검색"
              />

              <InputEx
                name="searchText"
                formik={formik}
                label="프로젝트명, 프로젝트번호 검색"
                style={{width: '100%', marginTop: '5px'}}
              />

              <div className="clearfix"></div>

              <div className="clearfix mt10">
                <div className="fl">
                  <BtnRefresh click={() => {
                    formik.setValues(searchinitialValues);
                    formik.handleSubmit();
                  }} />
                  <BtnSearch />
                </div>
                <div className="cnt" style={{lineHeight: '28px'}}>총 {comma(list.length || 0)}개</div>
                <div className={"sort " + sortView}>
                  <div className="selected" onClick={() => toggleSortView()} style={{lineHeight: '28px'}}>
                    {initOrderBy[formik.values.order].label}
                  </div>
                  <ul className="select-list" onClick={() => setSortView('')}>
                    {initOrderBy.map((item) => {
                      return (
                        <li onClick={() => {
                          formik.setFieldValue('order', item.value);
                          formik.handleSubmit();
                        }}>{item.label}</li>
                      )
                    })}
                  </ul>
                </div>
              </div>
            </form>
          </header>

          <div className="clearfix"></div>

          <div className="ag-theme-balham header-hide pad-height345" style={{height: 'calc(100vh - 280px)'}}>
            <AgGridReact
              defaultColDef={{
                sortable: true,
                resizable: true,
                filter: false,
                lockVisible: true,
                headerHeight: 0,
                tooltipComponent: customTooltipAgGrid,
              }}
              tooltipShowDelay={0}
              tooltipHideDelay={2000}
              alwaysShowVerticalScroll={true}
              columnDefs={columnDefs}
              rowData={list}
              rowSelection={'single'}
              getRowHeight={getRowHeight}
              onGridReady={(event) => {
                setGridApiProject(event.api);
              }}
              onRowDataUpdated={(event) => {
                event.api.ensureIndexVisible(selectedIndex, "middle");
                event.api.forEachNode((item) => {
                  if(item.rowIndex === selectedIndex) item.setSelected(true);
                })
              }}
              onRowClicked={(event) => {
                setSelectedIndex(event.rowIndex);
                setSelected(event.data);
              }}
              overlayNoRowsTemplate = "검색된 내용이 없습니다."
            />
          </div>
        </section>

        <div className="reduce-wrap"><i className="ri-arrow-right-circle-fill reduce-icon" onClick={() => setProjectReduce('')}></i></div>

        <section className="right-area Tab-page">
          <section className="tab-header">
            <Box sx={{ width: '100%' }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tabMenu} onChange={(event, index) => {
                  setTabMenu(index)
                }} aria-label="basic tabs example">
                  <Tab
                    className="tab-menu"
                    label="프로젝트 정보"
                    disabled={selected?.seq === undefined ? true : false}
                  />
                  <Tab
                    className="tab-menu"
                    label="파일 정보"
                    disabled={selected?.seq === undefined ? true : false}
                  />
                </Tabs>
              </Box>
            </Box>
          </section>

          {selected.seq !== undefined &&
            <SwipeableViews
              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={tabMenu}
              onChangeIndex={(index) => {
                setTabMenu(index)
              }}
              className="tab-contents"
              style={{height: '100%'}}
            >
              <div
                role="tabpanel"
                hidden={tabMenu !== 0}
                dir={theme.direction}
              >
                {tabMenu === 0 && (
                  <TabUserProjectInfo
                    tabIndex={tabMenu}
                    selected={selected}
                    setSelected={setSelected}
                    selectedIndex={selectedIndex}
                    setList={setList}
                    list={list}
                    division={divisionList}
                    year={projectYear}
                    company={company}
                    order={order}
                    partner={partner}
                    purpose={purpose}
                    purpose1={purpose1}
                    purpose2={purpose2}
                    businessType={businessType}
                    builderType={builderType}
                    pointOut={pointOut}
                    purposeRegion={purposeRegion}
                    newTech={newTech}
                    structure={structure}
                    structure1={structure1}
                    structure2={structure2}
                    getList={getList}
                    gridApiProject={gridApiProject}
                    setGridApiProject={setGridApiProject}
                  />
                )}
              </div>

              <div
                role="tabpanel"
                hidden={tabMenu !== 1}
                dir={theme.direction}
              >
                { tabMenu === 1 &&
                  <TabUserFile
                    tabIndex={tabMenu}
                    selected={selected}
                    setSelected={setSelected}
                    selectedIndex={selectedIndex}
                    list={list}
                    setList={setList}
                  />
                }
              </div>
            </SwipeableViews>
          }
        </section>
      </section>
    </>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default UserProject;