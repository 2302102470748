/*****************************************************************************************
 * 설명 : AGGrid 저장하기 버튼
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import React from 'react';

import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const BtnAgGridSave = (props) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const clickHandler = (event) => {
    if( props.click ) {
      props.click();
    };
  };

  let disabled = (props.disabled) ? props.disabled : false;

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <Tooltip title="그리드 컬럼 변경정보 저장" placement="top">
      <Button
        type="button"
        variant="outlined"
        color="inherit"
        className="mr5 btn-grid-save"
        disabled={disabled}
        onClick={() => clickHandler()}
      >
        <span className="material-icons md-24">grid_on</span>
      </Button>
    </Tooltip>
  )
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default BtnAgGridSave;