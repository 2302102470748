/*****************************************************************************************
 * 설명 : 사용자 통합검색 - list - 파일
 * URI : /user/TotalSearch/list
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Pagination from '@mui/material/Pagination';
import { AgGridReact } from 'ag-grid-react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { comma, formatBytes, PaperComponent } from 'service/utils';

import { baseURL, DOWNLOAD_FILE } from 'config/config';

import customTooltipAgGrid from 'components/tooltipAgGrid';

import ProjectInfo from 'pages/user/search/searchList/projectInfo';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const TabFile = ( props ) => {

  const PAGEROW = 20;

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const currentMenu = useSelector(state => state?.menuList?.current);

  const [openModal, setOpenModal] = useState({open: false, modal: 'sync', data: []});

  const [list, setList] = useState([]);
  const [totalPage, setTotalPage] = useState(0);

  // table column
  const [columnDefs] = useState([
    { headerName: '번호', field: 'index', width: 60, cellClass: 'cp text-right',
      valueGetter: (params) => {
        return comma(( params.node.rowIndex + 1 ) + ( (params.data.filePageNo - 1) * params.data.filePageRow ));
      }
    },
    { headerName:'PJ NO', field: 'projectNumber', width: 120, cellClass: 'cp center' },
    { headerName:'PJ 명', field: 'projectName', width: 450, cellClass: 'cp', tooltipField: 'projectName' },
    { headerName:'파일명', field: 'fileName', width: 400, cellClass: 'cp', tooltipField: 'fileName' },
    { headerName:'용량', field: 'fileSize', width: 100, cellClass: 'cp text-right',
      valueGetter: (params) => {
        return formatBytes(params.data.fileSize, 2);
      }
    },
    { headerName:'파일형식', field: 'ext', width: 100, cellClass: 'cp center' },
    { headerName:'키워드', field: 'tag', width: 400, cellClass: 'cp', tooltipField: 'tag' },
    { headerName:'다운로드', field: 'download', width: 70, cellClass: 'cp center',
      cellRendererFramework: function (params) {
        if(
          parseInt(params.data.readingGrade) === 0 &&
          ( parseInt(params.data.isDownload) === 1 || parseInt(currentMenu.isDownload) === 1 ) &&
          (params.data.destPath || '') !== ''
        ) {
          return (
            <div className={"grid-icon"}>
              <Button
                color="inherit"
                onClick={() => {
                  let url = baseURL + '/api/v1/file/download?seq=' + params.data.seq;
                  window.open(url)
                }}
              >
                <i className="ri-download-line"></i>
              </Button>
            </div>
          );
        } else {
          return '';
        }
      }
    },
    { headerName:'미리보기', field: 'preview', width: 70, cellClass: 'cp center',
      cellRendererFramework: function (params) {
        if(
          parseInt(params.data.readingGrade) === 0 &&
          ( parseInt(params.data.isPreview) === 1 || parseInt(currentMenu.isPreview) === 1 ) &&
          (params.data.destPath || '') !== ''
        ) {
          return (
            <div className={"grid-icon"}>
              { DOWNLOAD_FILE.includes(params.data.ext?.toLowerCase()) &&
                <Button
                  color="inherit"
                  onClick={() => {
                    let url = 'http://archive.dagroup.co.kr/synap/SynapDocViewServer/job?fileType=Local&sync=true&fid=' + params.data.seq + '&filePath=';
                    window.open(url + encodeURIComponent(params.data.destPath))
                  }}
                >
                  <i className="ri-eye-line"></i>
                </Button>
              }
              {/*
              <Button color="inherit"><i className="ri-archive-drawer-line"></i></Button>
              <Button color="inherit"><i className="ri-delete-bin-line"></i></Button>
              <Button  color="inherit" onClick={() => toggleMenuView()}><i className=" ri-more-2-line"></i></Button>
              <div style={{display: menuView === 'view' ? 'block' : 'none'}}>
                <ul className="popup-menu" onClick={() => setMenuView('')}>
                  <li>열람 권한신청</li>
                  <li className="down-line">다운로드 권한신청</li>
                  <li>복사하기<span className="hotkey">~^C</span></li>
                  <li className="down-line">잘라내기<span className="hotkey">~^X</span></li>
                  <li className="down-line">붙여넣기<span className="hotkey">~^V</span></li>
                  <li className="down-line">삭제하기<span className="hotkey">~^D</span></li>
                  <li>다운로드</li>
                  <li>미리보기</li>
                </ul>
              </div>
              */}
            </div>
          );
        } else {
          return '';
        }
      }
    },
  ]);

  /***************************************************************************************
   * 설명 : 데이터 초기화
  ***************************************************************************************/
  useEffect(() => {
    if( props.tabIndex === 2 && props.list ) {
      if( props.total !== undefined ) {
        let totalCount = (props.total.file === undefined ) ? 1 : props.total.file;
        let total = Math.floor(totalCount / PAGEROW);
        if( totalCount % PAGEROW > 0 ) total++;

        setTotalPage(total);

      } else {
        setTotalPage(0);
      }

      setList([
        ...props.list.map((item) => {
          return {
            ...item,
            filePageNo: props.formik.values.filePageNo || 1,
            filePageRow: props.formik.values.filePageRow || PAGEROW
          }
        })
      ]);

    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.tabIndex, props.list])

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <section className="search-list-result">
      <section className="project-file">
        <section className="ag-theme-balham grid pad-height420" style={{height:'calc(100vh - 380px)'}}>
          <AgGridReact
            defaultColDef={{
              sortable: true,
              resizable: true,
              filter: false,
              lockVisible: true,
              tooltipComponent: customTooltipAgGrid,
            }}
            tooltipShowDelay={0}
            tooltipHideDelay={2000}
            rowSelection={'single'}
            columnDefs={columnDefs}
            rowData={list}
            onCellClicked={(event) => {
              if( ! ['download', 'preview'].includes(event.colDef.field) )
                setOpenModal({open: true, modal: 'projectInfo', data: event.data});
            }}
            overlayNoRowsTemplate = "검색된 내용이 없습니다."
          />
        </section>

        <footer className="mt10">
          <div className="mb10">
            <div className="f16">디자인 검색결과 : {comma(props.total.design)}건,  프로젝트 검색결과 : {comma(props.total.project)}건, 파일 검색결과 : {comma(props.total.file)}건</div>
          </div>
          <div className="search-pagination2">
            <div className="pagination big">
              <Pagination
                count={totalPage}
                defaultPage={props.formik.values.pageNo}
                siblingCount={0}
                boundaryCount={2}
                size={!props.size ? 'small' : props.size}
                shape="rounded"
                color="primary"
                showFirstButton
                showLastButton
                onChange={(event, pageNo) => props.change(event, pageNo)}
              />
            </div>
          </div>
        </footer>
      </section>

      {
        openModal.open && openModal.modal === 'projectInfo' &&
        <Dialog
          open={openModal.open}
          onClose={() => setOpenModal({ open: false, modalName: 'projectInfo', data: openModal.data })}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "1000px",  // Set your width here
              },
            },
          }}
        >
          <ProjectInfo
            open={openModal.open}
            data={openModal.data}
            isFile={true}
            close={() => setOpenModal({ open: false, modalName: 'projectInfo', data: openModal.data })}
          />
        </Dialog>
      }
    </section>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default TabFile;