/*****************************************************************************************
 * 설명 : 사용자 통합검색 - 프로젝트 상세보기 - 미리보기
 * URI : /user/TotalSearch/list
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { encode as base64_encode } from 'base-64';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { getRelationProjectApi } from 'service/project';
import Restful from "service/restful";
import { PaperComponent } from 'service/utils';

import { IMAGE_FILE, MESSAGE_DELAY, MOVIE_FILE, baseURL } from 'config/config';

import ChipEx from 'components/chipEx';
import alertMsg from 'components/message';

import ProjectInfo from 'pages/user/search/searchList/projectInfo';
import ApplicationPerusalModal from './applicationPerusalModal';

import NoImage from 'assets/images/prepare.png';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const PreviewModal = (props) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const currentMenu = useSelector(state => state?.menuList?.current);

  const movieRef = useRef();
  const [getApi] = Restful();

  const [previewMovie, setPreviewMovie] = useState('');

  const [openModal, setOpenModal] = useState({open: false, modal: 'sync', data: []});
  const [projectList, setProjectList] = useState([]);

  /***************************************************************************************
   * 설명 : 연관 프로젝트 리스트 가져오기
  ***************************************************************************************/
  const getRelationProject = () => {
    if( ( props.data.relationProject1 || '' ) === '' ) return;

    let tmp = props.data.relationProject1.split(/,/gi);
    let tmp1 = tmp.map((item) => {
      return ( item.substr(-3) !== '-00' ) ? item + '-00' : item;
    })

    let params = {
      relationProject1: tmp1
    }

    getRelationProjectApi(getApi, params, (response) => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        setProjectList(response.data.data);
      } else {
        setProjectList([]);
      }

    });
  }

  /***************************************************************************************
   * 설명 : 업체 검색
  ***************************************************************************************/
  useEffect(() => {
    if(props.open) {
      getRelationProject();
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <section className="previewModal modal">
      <section className="modal-wrap" style={{width: '75vw'}}>
        <header className="modal-header" style={{ cursor: 'move' }} id="draggable-dialog-title">
          <div className="modal-title">파일 미리보기</div>
          <Button className="close-btn" onClick={props.close}>×</Button>
        </header>

        <section className="modal-body">
          <div className="preview-div">
            <div className="img">
              { // 이미지 파일 미리보기
                IMAGE_FILE.includes(props.data.ext?.toLowerCase()) &&
                <img src={baseURL + "/api/v1/project/image/download?thumbnail=" + base64_encode(props.data.destPath)} alt={props.data.fileName}
                  onError={(e) => {
                    e.target.src = NoImage;
                  }}
                />
              }

              { // 동영상 파일 미리보기
                MOVIE_FILE.includes(props.data.ext?.toLowerCase()) &&
                <>
                  <img
                    src={baseURL + "/api/v1/project/image/download?thumbnail=" + base64_encode(props.data.thumbnail)}
                    alt={props.data.fileName}
                    onError={(e) => {
                      e.target.src = NoImage;
                    }}
                    className="cp"
                    style={previewMovie === props.data.seq ? {display: 'none', width: '100%'} : {width: '100%'}}
                    onMouseEnter={(e) => {
                      if( MOVIE_FILE.includes(props.data.ext?.toLowerCase()) ) {
                        setPreviewMovie(props.data.seq);
                      }
                    }}
                  />
                  <video
                    autoPlay
                    muted
                    loop
                    playsInline
                    width="100%"
                    height="100%"
                    ref={movieRef}
                    style={previewMovie !== props.data.seq ? {display: 'none'} : {}}
                    className="cp"
                    onMouseLeave={(e) => {
                      if( MOVIE_FILE.includes(props.data.ext?.toLowerCase()) ) {
                        setPreviewMovie('');
                      }
                    }}
                  >
                    <source src={baseURL + '/api/v1/file/download?seq=' + props.data.seq} type="video/mp4" />
                  </video>
                </>
              }

              { // 기타 파일 미리보기
                ! IMAGE_FILE.includes(props.data.ext?.toLowerCase()) && ! MOVIE_FILE.includes(props.data.ext?.toLowerCase()) &&
                <img
                  src={baseURL + 'http://archive.dagroup.co.kr/synap/SynapDocViewServer/thumbnail/' + props.data.thumbnail + '/0?dpi=L&withXml=false&resizeType=M&fileExt=jpg&resizeValue=910&quality=80'}
                  alt={props.data.fileName}
                  onError={(e) => {
                    e.target.src = NoImage;
                  }}
                />
              }
            </div>
            <div className="info">
              <div className="info-title">
                파일정보
                { parseInt(props.data.status) === 1 &&
                  <ChipEx color="primary" className="ml10" variant="outlined" size="small" label="열람 신청" />
                }
                { parseInt(props.data.status) === 2 &&
                  <ChipEx color="success" className="ml10" variant="outlined" size="small" label="열람 승인" />
                }
                { parseInt(props.data.status) === 3 &&
                  <ChipEx color="error" className="ml10" variant="outlined" size="small" label="열람 반려" />
                }
              </div>
              <table className="input-table">
                <colgroup>
                  <col style={{width: '95px'}} />
                  <col />
                </colgroup>

                <tbody>
                  <tr>
                    <th scope="row">파일명</th>
                    <td>{props?.data?.fileName}</td>
                  </tr>
                  <tr>
                    <th scope="row">키워드</th>
                    <td>{props?.data.hashTag}</td>
                  </tr>
                  <tr>
                    <th scope="row">연관 프로젝트</th>
                    <td>
                      <div className="scroll-wrap scroll-y">
                        <ul className="plist">
                          { projectList.map((item) =>
                            <>
                              <li onClick={() => setOpenModal({open: true, modal: 'projectInfo', data: item})}>
                                <i className="ri-folder-line"></i><span>{item.projectName}</span>
                              </li>
                            </>
                          )}
                        </ul>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">비고</th>
                    <td>
                      <div className="scroll-wrap scroll-y">
                        {props.data.memo}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </section>

        <footer className="modal-footer">
          <div className="modal-footer-center">
            { // 다운로드 권한 체크
              (
                (
                  parseInt(props.data.readingGrade) === 0 &&
                  ( parseInt(props.data.isDownload) === 1 || parseInt(currentMenu.isDownload) === 1 )
                ) ||
                (
                  ( parseInt(props.data.status) === 2 && parseInt(props.data.isDownloadRequest) === 1 )
                )
              ) &&
              <Button
                color="secondary"
                variant="outlined"
                className="Btn"
                onClick={() => {
                  let url = baseURL + '/api/v1/file/download?seq=' + props.data.seq;
                  window.open(url)
                }}
              >다운로드</Button>
            }

            { // 전체 열람일 경우만 미리보기 링크
              (
                (
                  parseInt(props.data.isAuth) === 0 &&
                  ( parseInt(props.data.isPreview) === 1 || parseInt(currentMenu.isPreview) === 1) &&
                  parseInt(props.data.readingGrade) === 0
                ) ||
                (
                  ( parseInt(props.data.status) === 2 )
                )
              ) && ! MOVIE_FILE.includes(props.data.ext?.toLowerCase()) &&
              <Button
                color="inherit"
                variant="outlined"
                className="Btn ml10"
                onClick={() => {
                  let url = 'http://archive.dagroup.co.kr/synap/SynapDocViewServer/job?fileType=Local&sync=true&fid=' + props.data.seq + '&filePath=';
                  window.open(url + encodeURIComponent(props.data.destPath))
                }}
              >미리보기</Button>
            }

            {
              parseInt(props.data.readingGrade) === 0 &&
              (
                ( parseInt(props.data.isDownload) === 0 && parseInt(currentMenu.isDownload) === 0 )
                ||
                ( parseInt(props.data.isAuth) === 0 && ( parseInt(props.data.isPreview) === 0 && parseInt(currentMenu.isPreview) === 0) )
              ) &&
              ( props.data.status === null )  &&
              <Button
                color="primary"
                variant="outlined"
                className="Btn ml10"
                onClick={() => {
                  let check = true;
                  if( check === false ) {
                    alertMsg("이미 열람 신청/승인 된 내역이 있습니다.", "w", MESSAGE_DELAY);

                  } else {
                    setOpenModal({open: true, modal: 'perusal', data: {}})
                  }
                }}
              >열람신청</Button>
            }

            <Button
              color="inherit"
              variant="outlined"
              className="Btn ml10"
              onClick={() => {
                props.close();
              }}
            >창닫기</Button>
          </div>
        </footer>
      </section>

      {
        openModal.open && openModal.modal === 'projectInfo' &&
        <Dialog
          open={openModal.open}
          onClose={() => setOpenModal({ open: false, modalName: 'projectInfo', data: openModal.data })}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "1000px",  // Set your width here
              },
            },
          }}
        >
          <ProjectInfo
            open={openModal.open}
            data={openModal.data}
            close={() => setOpenModal({ open: false, modalName: 'projectInfo', data: openModal.data })}
          />
        </Dialog>
      }

      { // 이미지 미리 보기 모달
        openModal.open && openModal.modal === 'perusal' &&
        <Dialog
          open={openModal}
          onClose={() => (event, reason) => {
            if (reason && reason === "backdropClick") return;
            setOpenModal({open: false, modal: openModal.modal, data: {}});
          }}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "800px",  // Set your width here
              },
            },
          }}
        >
          <section className="modal">
            <ApplicationPerusalModal
              open={openModal.open}
              close={() => setOpenModal({ open: false, modalName: openModal.modal, data: openModal.data })}
              data={props.data}
              indicator={props.indicator}
              history={props.history}
            />
          </section>
        </Dialog>
      }
    </section>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default PreviewModal;