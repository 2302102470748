/*****************************************************************************************
 * 설명 : 프로젝트관리 - 주관부서 변경이력 조회
 * URI : /project
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import Button from '@mui/material/Button';
import { AgGridReact } from 'ag-grid-react';
import { useEffect, useState } from 'react';

import useGridConfig from 'hooks/useGridConfig';

import { getDepartmentHistoryListApi } from 'service/project';
import Restful from "service/restful";
import { comma } from 'service/utils';

import customTooltipAgGrid from 'components/tooltipAgGrid';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const DepartmentHistoryModal = (props) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [getApi] = Restful();

  const [list, setList] = useState([]);

  /***************************************************************************************
   * 설명 : 업체 검색
  ***************************************************************************************/
  // table column
  const [columnDefs, setColumnDefs] = useState([
    { headerName: '', field: 'seq', width: 35, cellClass: 'center', flex: 0,
      headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
    { headerName: '순번', field: 'rowIndex', width: 45, cellClass: 'cp text-right',
      valueGetter: (params) => {
        return comma(params.node.rowIndex + 1);
      }
    },
    { headerName: '주관부서명', field: 'departmentName', width: 140, cellClass: "cp" },
    { headerName: '변경이력', field: 'history', width: 200, cellClass: "cp" },
    { headerName: '사유', field: 'reason', width: 200, cellClass: "cp" },
    { headerName: '변경자', field: 'creatorName', width: 100, cellClass: "cp center" },
    { headerName: '변경일시', field: 'createDate', width: 170, cellClass: "cp center" },
  ]);

  const [gridConfig] = useGridConfig(99, setColumnDefs);

  /***************************************************************************************
   * 설명 : 변경 이력 가져오기
  ***************************************************************************************/
  const getList = () => {
    let params = {
      projectNumber: props.data?.projectNumber
    }

    getDepartmentHistoryListApi(getApi, params, (response) => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        setList(response.data.data);

      } else {
        setList([]);
      }
    });
  }

  /***************************************************************************************
   * 설명 : 팝업 시 처리
  ***************************************************************************************/
  useEffect(() => {
    if( props.open )
      getList();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <section className="modal-wrap" style={{width: '100%'}}>
      <header className="modal-header" style={{ cursor: 'move' }} id="draggable-dialog-title">
        <div className="modal-title">주관부서 변경이력 조회</div>
        <Button className="close-btn" onClick={props.close}>×</Button>
      </header>

      <section className="modal-body">
        <div className="clearfix"></div>

        <div className="ag-theme-balham" style={{height: '400px'}}>
          <AgGridReact
            defaultColDef={{
              sortable: true,
              resizable: true,
              filter: false,
              lockVisible: true,
              tooltipComponent: customTooltipAgGrid,
            }}
            tooltipShowDelay={0}
            tooltipHideDelay={2000}
            columnDefs={columnDefs}
            rowData={list}
            rowSelection={'multiple'}
            onGridReady={(event) => {
              gridConfig(event.columnApi);
            }}
            overlayNoRowsTemplate = "검색된 내용이 없습니다."
          />
        </div>
      </section>

      <footer className="modal-footer">
        <div className="modal-footer-center">
          <Button
            color="inherit"
            variant="outlined"
            className="Btn"
            onClick={() => {
              props.close();
            }}
          >창닫기</Button>
        </div>
      </footer>
    </section>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default DepartmentHistoryModal;