/*****************************************************************************************
 * 설명 : 관리자 > 시스템관리 > 논리스토리지 관리 > 추가/수정
*****************************************************************************************/
import React, { useEffect, useState } from 'react';
/*
import SortableTree from 'react-sortable-tree';
import FileExplorerTheme from 'react-sortable-tree-theme-file-explorer';
*/
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import * as Yup from "yup";

import Restful from "service/restful";
import { MESSAGE_DELAY } from 'config/config';

import { setLogicalDriveApi } from 'service/system';
import { onlyNumber } from 'service/utils';

import { InputEx } from 'components/inputEx';
import alertMsg from 'components/message';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
export const RogicStorageModal = ( props ) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [getApi] = Restful();

  const [title, setTitle] = useState('추가');

  const formik = useFormik({
    initialValues: {
      seq: 0,
      driveName: '',
      path: '',
      pathView: '',
      maxSize: 0,
    },
    validationSchema: Yup.object().shape({
      driveName: Yup.string().max(45, "45자리").required('필수'),
      path: Yup.string().max(255, "255자리").required('필수'),
      pathView: Yup.string().max(255, "255자리"),
      maxSize: Yup.string().typeError("숫자").required('필수')
    }),
    onSubmit: (values) => {
      // 실제 경로가 존재하는지 확인 결과
      setLogicalDriveApi(getApi, values, (response => {
        if( response !== undefined && response.data.result ) {
          alertMsg(response.data.message, "S", MESSAGE_DELAY);

          if( props.getList ) props.getList();

          props.close();

        } else {
          alertMsg(response.data.message || '서버와의 통신에 실패하였습니다.', "E", MESSAGE_DELAY);
        }
      }));
    }
  });

  /***************************************************************************************
   * 설명 : 모달 오픈 시 처리
  ***************************************************************************************/
  useEffect(() => {
    if( props.open ) {
      if( props.data.seq !== undefined ) {
        let tmp = {...props.data};

        let maxSize = String(props.data.maxSize || 0).replace(/,/gi, '');
        tmp.maxSize = Math.round(parseInt(maxSize) / 1000);

        formik.setValues(tmp);

        setTitle('수정');

      } else {
        setTitle('추가');
      }
    }

    // eslint-disable-next-line
  }, [props.open]);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <section className="modal-wrap">
      <form onSubmit={formik.handleSubmit}>
        <header className="modal-header" style={{ cursor: 'move' }} id="draggable-dialog-title">
          <div className="modal-title">논리 드라이브 {title}</div>
          <Button className="close-btn" onClick={props.close}>
            <CloseIcon style={{ color: '#fff', fontSize: '28px' }}></CloseIcon>
          </Button>
        </header>

        <section className="modal-body">
          <table className="input-table">
            <colgroup>
              <col style={{width: '25%'}} />
              <col style={{width: '75%'}} />
            </colgroup>

            <tbody>
              {/*
              <tr>
                <td>
                  <ToggleButtonGroupEx
                    exclusive={true}
                    formik={formik}
                      {label: '일반', value: 0},
                      {label: '기타', value: 2},
                    ]}
                  />
                </td>
              </tr>
              */}
              <tr>
                <th scope="row"><label htmlFor="driveName">드라이브명</label></th>
                <td>
                  <InputEx
                    name="driveName"
                    formik={formik}
                    focus={true}
                    fullWidth={true}
                    label="드라이브 표시명"
                  />
                </td>
              </tr>
              <tr>
                <th scope="row"><label htmlFor="path">실제경로</label></th>
                <td>
                  <InputEx
                    name="path"
                    formik={formik}
                    focus={true}
                    fullWidth={true}
                  />
                </td>
              </tr>
              <tr>
                <th scope="row"><label htmlFor="pathView">경로 표시명</label></th>
                <td>
                  <InputEx
                    name="pathView"
                    formik={formik}
                    focus={true}
                    fullWidth={true}
                    label="표시할 경로명"
                  />
                </td>
              </tr>
              {/*
              <tr>
                <td colSpan={2}>
                  <div className="p10">
                    <SortableTree
                      treeData={directoryList}
                      onChange={(rowInfo) => {
                        setDirectoryList(rowInfo);
                      }}
                      isVirtualized={false}
                      theme={FileExplorerTheme}
                      style={{fontSize: '13px', width: '100%', maxHeight: '300px', overflow: 'auto'}}
                      canDrag={false}
                      scaffoldBlockPxWidth={22}
                      generateNodeProps={(rowInfo) => {
                        return ({
                          onClick: (event) => {
                            formik.setFieldValue('path', rowInfo.node.realPath );
                            setSelectedNode(rowInfo.node);
                          },
                          icons: rowInfo.node.children !== undefined && rowInfo.node.children.length > 0
                            ? [
                                <div
                                  style={{
                                    borderLeft: 'solid 8px gray',
                                    borderBottom: 'solid 10px gray',
                                    marginRight: 10,
                                    boxSizing: 'border-box',
                                    width: 16,
                                    height: 12,
                                    filter: rowInfo.node.expanded
                                      ? 'drop-shadow(1px 0 0 gray) drop-shadow(0 1px 0 gray) drop-shadow(0 -1px 0 gray) drop-shadow(-1px 0 0 gray)'
                                      : 'none',
                                    borderColor: rowInfo.node.expanded ? 'white' : 'gray',
                                  }}
                                />,
                              ]
                            : [
                                <div
                                  style={{
                                    borderLeft: 'solid 8px gray',
                                    borderBottom: 'solid 10px gray',
                                    marginRight: 10,
                                    boxSizing: 'border-box',
                                    width: 16,
                                    height: 12,
                                    filter: 'drop-shadow(1px 0 0 gray) drop-shadow(0 1px 0 gray) drop-shadow(0 -1px 0 gray) drop-shadow(-1px 0 0 gray)',
                                    borderColor: 'white',
                                  }}
                                />,
                              ],
                          className: selectedNode?.id === (rowInfo.node?.id || '') ? 'tree-selected' : '',
                        })
                      }}
                    />
                  </div>
                </td>
              </tr>
              */}
              <tr>
                <th scope="row"><label htmlFor="maxSize">허용용량</label></th>
                <td>
                  <InputEx
                    name="maxSize"
                    formik={formik}
                    fullWidth={true}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">GB</InputAdornment>,
                    }}
                    onChange={(event) => {
                      if (event.nativeEvent.data ){
                        event.target.value = onlyNumber(event.target.value);
                      }
                    }}
                    onBlur={(event) => {
                      event.target.value = onlyNumber(event.target.value);
                    }}
                  />
                </td>
              </tr>
              {/*
              <tr>
                <th scope="row"><label htmlFor="isUse">사용여부</label></th>
                <td>
                  <ToggleButtonGroupEx
                    name="isUse"
                    exclusive={true}
                    formik={formik}
                    data={[
                      {label: '사용', value: 1},
                      {label: '사용안함', value: 0},
                    ]}
                  />
                </td>
              </tr>
              */}
            </tbody>
          </table>
        </section>

        <footer className="modal-footer">
          <div className="modal-footer-center">
            <Button
              color="inherit"
              variant="outlined"
              className="Btn"
              onClick={() => {
                props.close();
              }}
            >창닫기</Button>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              className="Btn ml10"
            >저장하기</Button>
          </div>
        </footer>
      </form>
    </section>
  )
}

export default RogicStorageModal;