/*****************************************************************************************
 * 설명 : 프로젝트관리 - 주관부서 변경이력 조회
 * URI : /project
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import Button from '@mui/material/Button';
import { AgGridReact } from 'ag-grid-react';
import { useFormik } from 'formik';
import $ from 'jquery';
import { useEffect, useState } from 'react';
import SortableTree from 'react-sortable-tree';
import FileExplorerTheme from 'react-sortable-tree-theme-file-explorer';
import * as Yup from "yup";

import { getDepartmentListApi, getEmployeeListApi } from 'service/member';
import Restful from "service/restful";
import { comma } from 'service/utils';

import { InputEx } from 'components/inputEx';
import alertMsg from 'components/message';
import customTooltipAgGrid from 'components/tooltipAgGrid';

import { MESSAGE_DELAY } from 'config/config';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const PmSelectModal = (props) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [getApi] = Restful();

  const [title, setTitle] = useState('담당PM');

  const [list, setList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [selectedNode, setSelectedNode] = useState({});

  const formik = useFormik({
    initialValues: {
      departmentNameLast: '',
      Reason: ''
    },
    validationSchema: Yup.object().shape({
      Reason: Yup.string().max(200, "200자리").required('필수').nullable(),
    }),
    onSubmit: (values) => {
      if( values.userName === '' ) {
        alertMsg(title + "으로 지정할 직원을 선택 혹은 입력하시기 바랍니다.", "W", MESSAGE_DELAY);
        return;
      }

      if( props.data === 'pm' ) {
        props.formik.setFieldValue('projectPmNameReason', values.Reason);
        props.formik.setFieldValue('projectPmNameLast', values.userName);
      } else {
        props.formik.setFieldValue('projectApmNameReason', values.Reason);
        props.formik.setFieldValue('projectApmNameLast', values.userName);
      }

      props.close();
    }
  });

  // table column
  const [columnDefs] = useState([
    { headerName: '', field: 'seq', width: 40, cellClass: 'center', flex: 0,
      headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
    { headerName: '번호', field: 'rowIndex', width: 55, cellClass: 'cp text-right',
      valueGetter: (params) => {
        return comma(params.node.rowIndex + 1);
      }
    },
    { headerName:'직위', field: 'dutyName', width: 100, cellClass: 'cp' },
    { headerName:'사용자명', field: 'userName', width: 200, cellClass: 'cp' },
  ]);

  /***************************************************************************************
   * 설명 : 조직도 리스트 데이터 가져오기
  ***************************************************************************************/
  const getDepartmentList = () => {
    let params = formik.values;

    const findNode = (data, id) => {
      // root
      if(id.length === 3) return data;

      for(let i = 0; i < data.length; i++ ) {
        if( data[i]?.sortOrder === id.substr(0, id.length - 3) )
          return data[i];

        if( data[i].children !== undefined ) {
          let tmp = findNode(data[i].children, id);
          if( tmp !== undefined ) return tmp;
        }
      }
    }

    getDepartmentListApi(getApi, params, (response) => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        setDepartmentList(response.data.data);

        let tmp = [];
        let data = response.data.data;
        data.forEach((item, index) => {
          let parentNode = findNode(tmp, item.sortOrder, 'sortOrder');

          if( parentNode === undefined ) {
            tmp.push({
              title: item.departmentName,
              expanded: true,
              ...item
            })

          } else if( item.sortOrder.length === 3 ) {
            parentNode.push({
              title: item.departmentName,
              expanded: true,
              ...item
            });

          } else {
            if( parentNode.children === undefined ) parentNode.children = [];
              parentNode.children.push({
                title: item.departmentName,
                expanded: true,
                ...item
              });
          }
        });

        setDepartmentList(tmp);

      } else {
        setDepartmentList([]);
      }
    });
  }

  /***************************************************************************************
   * 설명 : 트리 노드 클릭 시 한줄 선택 처리
  ***************************************************************************************/
  const treeRowClick = () => {
    $(".rstcustom__node").each((index, item) => {
      $(item).on('click', (event) => {

        // 기존 선택된 노드 삭제
        $(".modal-body").find('.tree-selected-parent').each((index, subItem) => {
          $(subItem).removeClass('tree-selected-parent');
        });

        $(event.target).closest('.rstcustom__rowWrapper').addClass('tree-selected-parent');
      });
    })
  }

  /***************************************************************************************
   * 설명 : 선택된 조직도의 사용자 가져오기
  ***************************************************************************************/
  const getEmployeeList = () => {
    // if( selectedNode === undefined || selectedNode.seq === undefined ) return;

    let params = {
      ...formik.values,
      departmentCode: selectedNode.departmentCode || '',
      sortOrder: selectedNode.sortOrder || ''
    };

    getEmployeeListApi(getApi, params, (response) => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        setList(response.data.data);
      } else {
        setList([]);
      }
    });
  }

  /***************************************************************************************
   * 설명 : 트리 노드 클릭 시 처리
  ***************************************************************************************/
  useEffect(() => {
    if( departmentList.length > 0 )
      treeRowClick();

    // eslint-disable-next-line
  }, [departmentList]);

  /***************************************************************************************
   * 설명 : 그룹코드 선택 시 데이터 불러오기
  ***************************************************************************************/
  useEffect(() => {
    getEmployeeList();

    // eslint-disable-next-line
  }, [selectedNode])

  /***************************************************************************************
   * 설명 : 팝업 시 처리
  ***************************************************************************************/
  useEffect(() => {
    if( props.open ) {
      if( props.data === 'pm' ) setTitle('담당PM');
      else setTitle('담당APM');
      getDepartmentList();
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <section className="modal-wrap" style={{width: '100%'}}>
      <form onSubmit={formik.handleSubmit}>
        <header className="modal-header" style={{ cursor: 'move' }} id="draggable-dialog-title">
          <div className="modal-title">{title} 선택</div>
          <Button className="close-btn" onClick={props.close}>×</Button>
        </header>

        <section className="modal-body">
          <div className="clearfix"></div>

          <div>
            <table className="input-table mb15">
              <colgroup>
                <col style={{width: '10%'}} />
                <col style={{width: '40%'}} />
                <col style={{width: '10%'}} />
                <col style={{width: '40%'}} />
              </colgroup>

              <tbody>
                <tr>
                  <th scope="row"><label htmlfor="">부서 / 직원 선택</label></th>
                  <td>
                    <div className="p10" style={{height: '300px', overflow: 'auto'}}>
                      <SortableTree
                        treeData={departmentList}
                        onChange={(rowInfo) => {
                          setDepartmentList(rowInfo);
                        }}
                        isVirtualized={false}
                        theme={FileExplorerTheme}
                        style={{fontSize: '13px'}}
                        canDrag={false}
                        scaffoldBlockPxWidth={22}
                        generateNodeProps={(rowInfo) => {
                          return ({
                            onClick: (event) => {
                              setSelectedNode(rowInfo.node);
                            },
                            icons: rowInfo.node.children !== undefined && rowInfo.node.children.length > 0
                              ? [
                                  <div
                                    style={{
                                      borderLeft: 'solid 8px gray',
                                      borderBottom: 'solid 10px gray',
                                      marginRight: 10,
                                      boxSizing: 'border-box',
                                      width: 16,
                                      height: 12,
                                      filter: rowInfo.node.expanded
                                        ? 'drop-shadow(1px 0 0 gray) drop-shadow(0 1px 0 gray) drop-shadow(0 -1px 0 gray) drop-shadow(-1px 0 0 gray)'
                                        : 'none',
                                      borderColor: rowInfo.node.expanded ? 'white' : 'gray',
                                    }}
                                  />,
                                ]
                              : [
                                  <div
                                    style={{
                                      borderLeft: 'solid 8px gray',
                                      borderBottom: 'solid 10px gray',
                                      marginRight: 10,
                                      boxSizing: 'border-box',
                                      width: 16,
                                      height: 12,
                                      filter: 'drop-shadow(1px 0 0 gray) drop-shadow(0 1px 0 gray) drop-shadow(0 -1px 0 gray) drop-shadow(-1px 0 0 gray)',
                                      borderColor: 'white',
                                    }}
                                  />,
                                ],
                            className: selectedNode?.seq === (rowInfo.node?.seq || '') ? 'tree-selected' : '',
                          })
                        }}
                      />
                    </div>
                  </td>
                  <td colSpan={2}>
                    <section className="ag-theme-balham grid" style={{height:'300px'}}>
                      <div style={{ height: '100%' }}>
                        <AgGridReact
                          defaultColDef={{
                            sortable: true,
                            resizable: true,
                            filter: false,
                            lockVisible: true,
                            tooltipComponent: customTooltipAgGrid,
                          }}
                          tooltipShowDelay={0}
                          tooltipHideDelay={2000}
                          rowSelection = {'multiple'}
                          columnDefs={columnDefs}
                          rowData={list}
                          onGridReady={(event) => {
                          }}
                          onSelectionChanged={(event) => {
                            let nodes = event.api.getSelectedRows();
                            if( nodes.length < 1 ) {
                              formik.setFieldValue('userName', '');
                            } else {
                              formik.setFieldValue('userName', nodes[0].userName);
                            }
                          }}
                          rowDragManaged={true}
                          rowDragMultiRow={true}
                          animateRows={true}
                          overlayNoRowsTemplate = "검색된 내용이 없습니다."
                        />
                      </div>
                    </section>
                  </td>
                </tr>
                <tr>
                  <th scope="row"><label htmlfor="Reason">사유입력</label></th>
                  <td>
                    <InputEx
                      name="Reason"
                      formik={formik}
                      fullWidth={true}
                    />
                  </td>
                  <th scope="row">
                    <label htmlfor="userName">
                      {props.data === 'pm' ? '담당PM' : '담당APM'}
                    </label>
                  </th>
                  <td>
                    <InputEx
                      name="userName"
                      formik={formik}
                      fullWidth={true}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>

        <footer className="modal-footer">
          <div className="modal-footer-center">
            <Button
              color="inherit"
              variant="outlined"
              className="Btn"
              onClick={() => {
                props.close();
              }}
            >창닫기</Button>

            <Button
              type="submit"
              color="primary"
              variant="outlined"
              className="Btn ml10"
            >선택하기</Button>
          </div>
        </footer>
      </form>
    </section>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default PmSelectModal;