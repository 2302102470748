/*****************************************************************************************
 * 설명 : 관리자 > 시스템관리 > 권한그룹관리 > 프로젝트별 권한
*****************************************************************************************/
import { Button, Checkbox } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import { useFormik } from 'formik';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import SortableTree from 'react-sortable-tree';
import FileExplorerTheme from 'react-sortable-tree-theme-file-explorer';
import * as Yup from "yup";

import useGridConfig from 'hooks/useGridConfig';

import BtnAgGridSave from 'components/btnAgGridSave';
import { BtnRefresh, BtnSearch } from 'components/common';
import { ButtonEx, InputEx } from 'components/inputEx';
import customTooltipAgGrid from 'components/tooltipAgGrid';

import { getAuthProjectListApi } from 'service/auth';
import Restful from "service/restful";

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const TabProjectAuthOrganization = ( props ) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [getApi] = Restful();

  const gridRef = useRef();

  const [projectList, setProjectList] = useState([]);
  const [directoryList, setDirectoryList] = useState([]);

  const [selectedNode, setSelectedNode] = useState({});

  const currentMenu = useSelector(state => state?.menuList?.current);

  // 권한그룹 권한 리스트 AgGrid API
  const [gridApiList, setGridApiList] = useState(null);

  const [list] = useState([]);

  const [setOpenModal] = useState({open: false, modal: 'add', data: []});

  const initialValues = {
    siteSeq: 0,
    startDate: moment().format('YYYY'),
    endDate: moment().format('YYYY'),
    searchText: ''
  }
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object().shape({
    }),
    onSubmit: (values) => {
      getList();
    }
  });

  // 권한그룹 설정 컬럼 정보
  const [columnDefsAuth, setColumnDefsAuth] = useState([
    { headerName: '', field: 'checkrow', cellClass: 'cp', width: 60,
      cellRendererFramework: function(params) {
        let check = ( params.value === 0 || params.value === null || params.value === undefined ) ? false : true;
        const className = ( props.className !== undefined ) ? 'Checkbox ' + props.className : 'Checkbox';

        return (
          <Checkbox
            className={className}
            onChange={(event) => {
              params.node.data.checkrow = event.target.checked === false? 0 : 1;
              params.node.data.authView = event.target.checked === false? 0 : 1;
              params.node.data.authWrite = event.target.checked === false? 0 : 1;
              params.node.data.authModify = event.target.checked === false? 0 : 1;
              params.node.data.authDelete = event.target.checked === false? 0 : 1;
              params.node.data.authExcel = event.target.checked === false? 0 : 1;
              params.node.data.authEmail = event.target.checked === false? 0 : 1;
              params.node.data.authKakao = event.target.checked === false? 0 : 1;
              params.node.data.authSms = event.target.checked === false? 0 : 1;
              params.node.data.authAdmin = event.target.checked === false? 0 : 1;

              params.api.forEachNode( (node) => {
                if( event.target.checked ) {
                  node.updateData(node.data);
                } else {
                  node.updateData(node.data);
                }
              });
            }}
            checked={check}
            color="primary"
          />
        )
      },
    },
    { headerName: '프로젝트명', field: 'menuName', width: 300, cellClass: 'cp',},
    { headerName: '조회', field: 'authView', cellClass: 'cp center', width: 120, suppressMenu: true,
      cellRendererFramework: (params) => agGridCheckbox(params, props),
      headerComponentFramework: (params) => agGridHeaderCheckbox(params, props)
    },
    { headerName: '상세조회', field: 'authWrite', cellClass: 'cp center', width: 120,
      cellRendererFramework: (params) => agGridCheckbox(params, props),
      headerComponentFramework: (params) => agGridHeaderCheckbox(params, props)
    },
    { headerName: '다운로드', field: 'authModify', cellClass: 'cp center', width: 120,
      cellRendererFramework: (params) => agGridCheckbox(params, props),
      headerComponentFramework: (params) => agGridHeaderCheckbox(params, props)
    },
    { headerName: '미리보기', field: 'authDelete', cellClass: 'cp center', width: 120,
      cellRendererFramework: (params) => agGridCheckbox(params, props),
      headerComponentFramework: (params) => agGridHeaderCheckbox(params, props)
    },
    { headerName: '프로젝트관리', field: 'authExcel', cellClass: 'cp center', width: 140,
      cellRendererFramework: (params) => agGridCheckbox(params, props),
      headerComponentFramework: (params) => agGridHeaderCheckbox(params, props)
    },
  ]);

  // table column
  const [columnDefsFile, setColumnDefsFile] = useState([
    { headerName: '', field: 'checkrow', cellClass: 'cp', width: 60,
      cellRendererFramework: function(params) {
        let check = ( params.value === 0 || params.value === null || params.value === undefined ) ? false : true;
        const className = ( props.className !== undefined ) ? 'Checkbox ' + props.className : 'Checkbox';

        return (
          <Checkbox
            className={className}
            onChange={(event) => {
              params.node.data.checkrow = event.target.checked === false? 0 : 1;
              params.node.data.authView = event.target.checked === false? 0 : 1;
              params.node.data.authWrite = event.target.checked === false? 0 : 1;
              params.node.data.authModify = event.target.checked === false? 0 : 1;
              params.node.data.authDelete = event.target.checked === false? 0 : 1;
              params.node.data.authExcel = event.target.checked === false? 0 : 1;
              params.node.data.authEmail = event.target.checked === false? 0 : 1;
              params.node.data.authKakao = event.target.checked === false? 0 : 1;
              params.node.data.authSms = event.target.checked === false? 0 : 1;
              params.node.data.authAdmin = event.target.checked === false? 0 : 1;

              params.api.forEachNode( (node) => {
                if( event.target.checked ) {
                  node.updateData(node.data);
                } else {
                  node.updateData(node.data);
                }
              });
            }}
            checked={check}
            color="primary"
          />
        )
      },
    },
    { headerName: '파일명', field: 'menuName', width: 300, cellClass: 'cp',},
    { headerName: '목록조회', field: 'authView', cellClass: 'cp center', width: 120, suppressMenu: true,
      cellRendererFramework: (params) => agGridCheckbox(params, props),
      headerComponentFramework: (params) => agGridHeaderCheckbox(params, props)
    },
    { headerName: '상세조회', field: 'authWrite', cellClass: 'cp center', width: 120,
      cellRendererFramework: (params) => agGridCheckbox(params, props),
      headerComponentFramework: (params) => agGridHeaderCheckbox(params, props)
    },
    { headerName: '미리보기', field: 'authModify', cellClass: 'cp center', width: 120,
      cellRendererFramework: (params) => agGridCheckbox(params, props),
      headerComponentFramework: (params) => agGridHeaderCheckbox(params, props)
    },
    { headerName: '다운로드', field: 'authDelete', cellClass: 'cp center', width: 120,
      cellRendererFramework: (params) => agGridCheckbox(params, props),
      headerComponentFramework: (params) => agGridHeaderCheckbox(params, props)
    },
  ]);

  const [gridConfigAuth, setGridUpdateAuth] = useGridConfig(2, setColumnDefsAuth);
  const [gridConfigFile, setGridUpdateFile] = useGridConfig(3, setColumnDefsFile);

   /***************************************************************************************
   * 설명 : 헤더 체크
  ***************************************************************************************/
   function agGridHeaderCheckbox(params, props) {
    const className = ( props.className !== undefined ) ? 'Checkbox ' + props.className : 'Checkbox';

    return (
      <>
        <Checkbox
          className={className}
          onChange={(e) => {setColumnAll(e, params)}}
          color="primary"
        />
        <span>{params.displayName}</span>
      </>
    )
  }

  /***************************************************************************************
   * 설명 : 체크박스 처리
  ***************************************************************************************/
  function agGridCheckbox(params, props) {
    let check = ( params.value === '0' || params.value === null || params.value === 0 ) ? false : true;
    const className = ( props.className !== undefined ) ? 'Checkbox ' + props.className : 'Checkbox';

    return (
      <Checkbox className={className}
        onChange={(e) => {setColumnCheck(e, params)}}

        // onChange={(event) => {
        //   params.node.data[params.colDef.field] = event.target.checked === false ? 0 : 1;
        //   params.api.updateRowData({ update: [params.node.data]});
        // }}
        checked={check}
        color="primary"
      />
    )
  }

  /***************************************************************************************
   * 설명 : 권한그룹 설정 전체 선택/해제
  ***************************************************************************************/
  const selectAll = (checked) => {
    gridApiList.forEachNode( (node) => {
      node.setDataValue( 'checkrow', checked );
      node.setDataValue( 'authView', checked );
      node.setDataValue( 'authWrite', checked );
      node.setDataValue( 'authModify', checked );
      node.setDataValue( 'authDelete', checked );
      node.setDataValue( 'authExcel', checked );
      node.setDataValue( 'authSms', checked );
      node.setDataValue( 'authEmail', checked );
      node.setDataValue( 'authKakao', checked );
      node.setDataValue( 'authAdmin', checked );
    });
  }

  const setColumnAll = (e, params) => {
    params.api.forEachNode( (node) => {
      if( e.target.checked ) {
        node.setDataValue( params.column.colDef.field, 1 );
      } else {
        node.setDataValue( params.column.colDef.field, 0 );
      }
    });
  }

  /***************************************************************************************
   * 설명 : 컬럼 조정
  ***************************************************************************************/
  const setColumnCheck = (e, params) => {
    params.node.data[params.column.colDef.field] = (e.target.checked === false) ? 0 : 1;
    params.api.forEachNode( (node) => {
      if( e.target.checked ) {
        node.updateData(node.data);
      } else {
        node.updateData(node.data);
      }
    });
  }

  /***************************************************************************************
   * 설명 : 권한 그룹 저장
  ***************************************************************************************/
  const saveAuthGroup = () => {
    /*
    let data = [];
    gridApiList.forEachNode((node, i) => {
      data.push(node.data);
      data[i].authgroupSeq = authgroupSeq;
      data[i].seq = node.data.seq === null ? 0 : node.data.seq;
      data[i].menuSeq = node.data.menuSeq === null ? '' : node.data.menuSeq;
      data[i].authDelete = node.data.authDelete === null ? 0 : node.data.authDelete;
      data[i].authEmail = node.data.authEmail === null ? 0 : node.data.authEmail;
      data[i].authExcel = node.data.authExcel === null ? 0 : node.data.authExcel;
      data[i].authKakao = node.data.authKakao === null ? 0 : node.data.authKakao;
      data[i].authModify = node.data.authModify === null ? 0 : node.data.authModify;
      data[i].authSms = node.data.authSms === null ? 0 : node.data.authSms;
      data[i].authView = node.data.authView === null ? 0 : node.data.authView;
      data[i].authWrite = node.data.authWrite === null ? 0 : node.data.authWrite;
      data[i].authAdmin = node.data.authAdmin === null ? 0 : node.data.authAdmin;
    });

    if( authgroupSeq === '' ) {
      alertMsg('권한그룹명을 선택해 주세요.', 'W', MESSAGE_DELAY);
    }

    let params = {
      program: 'api',
      service: 'cms',
      action: 'setAuthGroupList',
      version: '1.0',
      data: data
    }

    /*
    getApi("post", params).then( response => {
      if( response !== undefined && response.data.result ) {
        alertMsg(response.data.message, "S", MESSAGE_DELAY);

      } else {
        alertMsg(response?.data?.message || '서버와의 통신에 실패하였습니다.', 'E', MESSAGE_DELAY);
      }
    });
    */
  }

  /***************************************************************************************
   * 설명 : 데이터 처리
  ***************************************************************************************/
  const getList = () => {
    let params = {
      seq: props.selected[0]?.seq,
      ...formik.values
    }

    getAuthProjectListApi(getApi, params, (response) => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        setProjectList(response.data.data);

      } else {
        setProjectList([]);

      }
    });

    getAuthProjectListApi(getApi, params, (response) => {

    })
  }

  /***************************************************************************************
   * 설명 : 데이터 처리
  ***************************************************************************************/
  useEffect(() => {
    if( props.tabIndex === '2')
      getList();

    // eslint-disable-next-line
  }, [props.tabIndex, props.selected]);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <section className='ml10'>
      <section>
        <form onSubmit={formik.handleSubmit}>
          <header className="admin-cms-search-header">
            <div className="left">
              <BtnRefresh click={() => {
                formik.setValues(initialValues);
                formik.handleSubmit();
              }}></BtnRefresh>
              <BtnSearch></BtnSearch>
              <BtnAgGridSave click={setGridUpdateAuth}></BtnAgGridSave>

              <InputEx
                type="year"
                name="startDate"
                formik={formik}
                fullWidth={false}
                style={{width: '130px'}}
                className="search-input"
              />
              <span className="ml5 mr5 lh28px">~</span>
              <InputEx
                type="year"
                name="endDate"
                formik={formik}
                fullWidth={false}
                className="ml5 search-input"
                style={{width: '130px'}}
              />

              <InputEx
                name="searchText"
                formik={formik}
                label="프로젝트 명, 태그 검색"
                className="ml5"
              />
            </div>

            <div className="text-right">
              <Button
                color="inherit"
                variant="outlined"
                className="Btn"
                onClick={() => selectAll(1)}
              >전체선택</Button>

              <Button
                color="error"
                variant="outlined"
                className="ml5"
                onClick={() => selectAll(0)}
              >전체해제</Button>

              <ButtonEx
                title="저장"
                auth="isModify"
                color="secondary"
                className="ml5"
                variant="contained"
                onClick={saveAuthGroup}
              />
            </div>
          </header>
        </form>

        <div className="ag-theme-balham grid mt10" style={{height:'calc(50vh - 70px)'}}>
          <div style={{ height: '100%' }}>
            <AgGridReact
              ref={gridRef}
              defaultColDef={{
                sortable: true,
                resizable: true,
                filter: false,
                lockVisible: true,
                tooltipComponent: customTooltipAgGrid,
              }}
              tooltipShowDelay={0}
              tooltipHideDelay={2000}
              rowSelection={'multiple'}
              columnDefs={columnDefsAuth}
              rowData={projectList}
              onGridReady={(params) => {
                setGridApiList(params.api);
                gridConfigAuth(params.columnApi);
              }}
              overlayNoRowsTemplate="검색된 내용이 없습니다."
            />
          </div>
        </div>
      </section>
      <section>
        <section className='fl' style={{width: '300px'}}>
          <header className="admin-cms-search-header mb5">
            <div className="left">
              <div className='fl'>
                <span className='f14 bold lh28px'>프로젝트 폴더 선택</span>
              </div>
            </div>
          </header>
          <section className='organization' style={{border: 'solid 1px #ccc'}}>
            <div className='pt20' style={{height:'calc(50vh - 105px)'}}>
              <div style={{ height: '100%' }}>
                <SortableTree
                  treeData={directoryList}
                  onChange={(rowInfo) => {
                    setDirectoryList(rowInfo);
                  }}
                  isVirtualized={false}
                  theme={FileExplorerTheme}
                  style={{fontSize: '13px'}}
                  canDrag={false}
                  scaffoldBlockPxWidth={22}
                  generateNodeProps={(rowInfo) => {
                    return ({
                      onClick: (event) => {
                        setSelectedNode(rowInfo.node);
                      },
                      icons: rowInfo.node?.children !== undefined && rowInfo.node?.children?.length > 0
                        ? [
                            <div
                              style={{
                                borderLeft: 'solid 8px gray',
                                borderBottom: 'solid 10px gray',
                                marginRight: 10,
                                boxSizing: 'border-box',
                                width: 16,
                                height: 12,
                                filter: rowInfo.node.expanded
                                  ? 'drop-shadow(1px 0 0 gray) drop-shadow(0 1px 0 gray) drop-shadow(0 -1px 0 gray) drop-shadow(-1px 0 0 gray)'
                                  : 'none',
                                borderColor: rowInfo.node.expanded ? 'white' : 'gray',
                              }}
                            />,
                          ]
                        : [
                            <div
                              style={{
                                borderLeft: 'solid 8px gray',
                                borderBottom: 'solid 10px gray',
                                marginRight: 10,
                                boxSizing: 'border-box',
                                width: 16,
                                height: 12,
                                filter: 'drop-shadow(1px 0 0 gray) drop-shadow(0 1px 0 gray) drop-shadow(0 -1px 0 gray) drop-shadow(-1px 0 0 gray)',
                                borderColor: 'white',
                              }}
                            />,
                          ],
                      className: selectedNode?.id === (rowInfo.node?.id || '') ? 'tree-selected' : '',
                    })
                  }}
                />
              </div>
            </div>
          </section>
        </section>
        <section className='fr' style={{width: 'calc(100% - 310px)'}}>
          <header className="admin-cms-search-header mb5">
            <div className="fl left">
              <BtnAgGridSave click={setGridUpdateFile}></BtnAgGridSave>
              <span className="f14">
                전체 : 12,345  현재 : 120
              </span>
            </div>
            <div className="fr right">
              <ButtonEx
                title="권한저장"
                auth="isWrite"
                color="secondary"
                className="ml5"
                variant="outlined"
              />
            </div>
          </header>
          <div className="ag-theme-balham grid" style={{height:'calc(50vh - 100px)'}}>
            <AgGridReact
              defaultColDef={{
                sortable: true,
                resizable: true,
                filter: false,
                lockVisible: true,
                tooltipComponent: customTooltipAgGrid,
              }}
              tooltipShowDelay={0}
              tooltipHideDelay={2000}
              rowSelection = {'multiple'}
              columnDefs={columnDefsFile}
              rowData={list}
              onGridReady={(event) => {
                gridConfigFile(event.columnApi);
              }}
              onRowDoubleClicked={(event) => {
                if( currentMenu?.authModify === '1' || currentMenu?.authModify === 1 )
                  setOpenModal({open: true, modal: 'foldAdd', data: event.data});
              }}
              rowDragManaged={true}
              rowDragMultiRow={true}
              animateRows={true}
              overlayNoRowsTemplate = "검색된 내용이 없습니다."
            />
          </div>
        </section>
      </section>
    </section>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default TabProjectAuthOrganization;