/*****************************************************************************************
 * 설명 : 프로젝트관리 - 용도지역
 * URI : /project
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import Button from '@mui/material/Button';
import { AgGridReact } from 'ag-grid-react';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from "yup";

import { MESSAGE_DELAY } from 'config/config';
import useGridConfig from 'hooks/useGridConfig';

import { comma } from 'service/utils';

import { ButtonEx } from 'components/inputEx';
import alertMsg from 'components/message';
import customTooltipAgGrid from 'components/tooltipAgGrid';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const PurposeRegionModal = (props) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [list, setList] = useState([]);
  const [selectedList, setSelectedList] = useState([]);

  const [gridApi, setGridApi] = useState({});
  const [gridApiAdd, setGridApiAdd] = useState({});

  const initialValues = {
    searchText: '',
    smart: '',
    status: ''
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object().shape({
    }),
    onSubmit: (values) => {
      let tmp = [...props.newTech];

      // 검색 기능 적용
      if( values.searchText !== '' ) {
        let tmp1 = tmp.filter((item) => {
          if(
            item.commonName.indexOf(values.searchText) > -1 ||
            item.val1.indexOf(values.searchText) > -1 ||
            item.val2.indexOf(values.searchText) > -1
          ) return true;
          else return false;
        });
        tmp = tmp1;
      }

      // 스마트건설기술 검색
      if( values.smart !== '' ) {
        let tmp1 = tmp.filter((item) => {
          if(values.smart === 1 && item.val4 === '해당') return true;
          else if(values.smart === 2 && item.val4 === '미해당') return true;
          else return false;
        });
        tmp = tmp1;
      }

      // 상태 검색
      if( values.status !== '' ) {
        let tmp1 = tmp.filter((item) => {
          if(values.status === 1 && item.val5 === '유효') return true;
          else if(values.status === 2 && item.val5 === '만료') return true;
          else return false;
        });
        tmp = tmp1;
      }

      setList([
        ...tmp.map((item) => {
          return {
            ...item,
            searchText: formik.values.searchText
          }
        })
      ]);

    }
  });

  /***************************************************************************************
   * 설명 : 업체 검색
  ***************************************************************************************/
  // table column
  const [columnDefs, setColumnDefs] = useState([
    { headerName: '', field: 'seq', width: 35, cellClass: 'center', flex: 0,
      headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
    { headerName: '순번', field: 'rowIndex', width: 45, cellClass: 'cp text-right',
      valueGetter: (params) => {
        return comma(params.node.rowIndex + 1);
      }
    },
    { headerName: '용도지역', field: 'label', width: 500, cellClass: 'cp', flex: 1 },
  ]);

  const [columnDefsAdd, setColumnDefsAdd] = useState([
    { headerName: '', field: 'seq', width: 35, cellClass: 'center', flex: 0,
      headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
    { headerName: '순번', field: 'rowIndex', width: 45, cellClass: 'cp text-right',
      valueGetter: (params) => {
        return comma(params.node.rowIndex + 1);
      }
    },
    { headerName: '용도지역', field: 'label', width: 500, cellClass: 'cp', flex: 1 },
  ]);

  const [gridConfig] = useGridConfig(99, setColumnDefs);
  const [gridConfigAdd] = useGridConfig(98, setColumnDefsAdd);

  /***************************************************************************************
   * 설명 : 용도지역 추가
  ***************************************************************************************/
  const addPurposeRegion = () => {
    let nodes = gridApi.getSelectedRows();

    if( nodes.length < 1) {
      alertMsg("추가할 용도지역을 선택하시기 바랍니다.", "W", MESSAGE_DELAY);
      return;
    }

    // 중복 내역 필터링
    let tmp = nodes.filter((item) => {
      let tmp1 = selectedList.filter((subItem) => subItem.seq === item.seq);
      return (tmp1.length > 0 ) ? false : true;
    })

    setSelectedList([
      ...selectedList,
      ...tmp
    ]);
  }

  /***************************************************************************************
   * 설명 : 용도지역 삭제
  ***************************************************************************************/
  const delPurposeRegion = () => {
    let nodes = gridApiAdd.getSelectedRows();

    if( nodes.length < 1) {
      alertMsg("삭제할 용도지역을 선택하시기 바랍니다.", "W", MESSAGE_DELAY);
      return;
    }

    let tmp = selectedList.filter((item) => {
      let tmp1 = nodes.filter((subItem) => subItem.seq === item.seq);
      return (tmp1.length > 0 ) ? false : true;
    });

    setSelectedList(tmp);
  }

  /***************************************************************************************
   * 설명 : 팝업 시 처리
  ***************************************************************************************/
  useEffect(() => {
    if( props?.purposeRegion?.length > 0 ) {
      setList(props.purposeRegion);

      if( props.purposeRegion?.length > 0 && gridApi.forEachNode !== undefined ) {
        if( props.formik.values.purposeRegionCode !== '' ) {
          let code = props.formik.values.purposeRegionCode?.split(/,/gi);
          let tmp1 = [];
          gridApi.forEachNode((item) => {
            let tmp = code?.filter((subItem) => subItem === item.data.commonCode);
            if( tmp?.length > 0 ) tmp1.push(item.data);
          });

          setSelectedList(tmp1);
        }
      }
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open, gridApi]);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <section className="modal-wrap" style={{width: '100%'}}>
      <header className="modal-header" style={{ cursor: 'move' }} id="draggable-dialog-title">
        <div className="modal-title">용도지역 선택</div>
        <Button className="close-btn" onClick={props.close}>×</Button>
      </header>

      <div className="clearfix"></div>

      <section className="modal-body">
        <div className="fl">
          <div className="ag-theme-balham" style={{width: '300px', height: '450px'}}>
            <AgGridReact
              defaultColDef={{
                sortable: true,
                resizable: true,
                filter: false,
                lockVisible: true,
                tooltipComponent: customTooltipAgGrid,
              }}
              tooltipShowDelay={0}
              tooltipHideDelay={2000}
              columnDefs={columnDefs}
              rowData={list}
              rowSelection={'multiple'}
              onGridReady={(event) => {
                gridConfig(event.columnApi);
                setGridApi(event.api);
              }}
              overlayNoRowsTemplate = "검색된 내용이 없습니다."
            />
          </div>
        </div>

        <div className="fl ml10 mr10">
          <div className="center" style={{marginTop: '170px'}}>
            <ButtonEx
              auth="isModify"
              title="용도지역 추가"
              variant="outlined"
              color="primary"
              onClick={() => addPurposeRegion()}
            />
          </div>

          <div className="center mt20">
            <ButtonEx
              auth="isModify"
              title="용도지역 삭제"
              variant="outlined"
              color="error"
              onClick={() => delPurposeRegion()}
            />
          </div>
        </div>

        <div className="fl">
          <div className="ag-theme-balham" style={{width: '300px', height: '450px'}}>
            <AgGridReact
              defaultColDef={{
                sortable: true,
                resizable: true,
                filter: false,
                lockVisible: true,
                tooltipComponent: customTooltipAgGrid,
              }}
              tooltipShowDelay={0}
              tooltipHideDelay={2000}
              columnDefs={columnDefsAdd}
              rowData={selectedList}
              rowSelection={'multiple'}
              onGridReady={(event) => {
                gridConfigAdd(event.columnApi);
                setGridApiAdd(event.api);
              }}
              overlayNoRowsTemplate = "검색된 내용이 없습니다."
            />
          </div>
        </div>
      </section>

      <footer className="modal-footer">
        <div className="modal-footer-center">
          <Button
            color="inherit"
            variant="outlined"
            className="Btn"
            onClick={() => {
              props.close();
            }}
          >창닫기</Button>

          <Button
            variant="contained"
            color="primary"
            className="Btn ml10"
            onClick={() => {
              if( selectedList.length > 0 ) {
                let tmp = selectedList.map((item) => item.commonName).join();
                let tmp1 = selectedList.map((item) => item.commonCode).join();
                props.formik.setFieldValue("purposeRegion", tmp);
                props.formik.setFieldValue("purposeRegionCode", tmp1);

                props.close();

              } else {
                alertMsg("용도지역을 선택해 주시기 바랍니다.", "W", MESSAGE_DELAY);
              }
            }}
          >선택하기</Button>
        </div>
      </footer>
    </section>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default PurposeRegionModal;