/*****************************************************************************************
 * 설명 : 상세검색창
 * URI :
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import { useFormik } from 'formik';
import InputAdornment from '@mui/material/InputAdornment';
import * as Yup from "yup";

import Restful from "service/restful";
import { onlyNumber, getSelectData, isCheckRadio } from 'service/utils';
import { getProjectInfoApi } from 'service/project';

import { InputEx, SelectEx, SelectSearchEx, ToggleButtonGroupEx } from 'components/inputEx';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const DetailSearchBox = (props) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [getApi] = Restful();

  const [isLoading, setIsLoading] = useState(false);

  const [divisionList, setDivisionList] = useState([]);
  const [projectType, setProjectType] = useState([]);
  const [purpose, setPurpose] = useState([]);
  const [businessType, setBusinessType] = useState([]);
  const [builderType, setBuilderType] = useState([]);
  const [structure, setStructure] = useState([]);
  const [disabledNewTech, setDisabledNewTech] = useState(false);

  const initialValues = {
    searchCheckbox1: true,
    searchCheckbox2: true,
    searchCheckbox3: true,
    designProjectType: 1,
    isRelation: 0,
    relationSeq: 0,
    search: '',
    searchText: '',
    searchTextOrg: '',
    prevDesignPageNo: 1,
    designPageNo: 1,
    designPageRow: 10,
    prevProjectPageNo: 1,
    projectPageNo: 1,
    projectPageRow: 20,
    prevFilePageNo: 1,
    filePageNo: 1,
    filePageRow: 20,
    tab: '',
    projectDivision: '',
    projectNumber: '',
    projectStartYear: '',
    projectEndYear: '',
    projectName: '',
    departmentName: '',
    projectPm: '',
    projectType: '',
    businessType: '',
    builderType: '',
    purpose: '',
    scaleUnderStart: '',
    scaleUnderEnd: '',
    scaleStart: '',
    scaleEnd: '',
    structure: '',
    builderName: '',
    orderCompanyId: '',
    builderCompanyId: '',
    location: '',
    totalSizeStart: '',
    totalSizeEnd: '',
    isNewTech: '',
    newTech: '',
    isAwardInfo: '',
    isOwnership: '',
    isCooperationCompany: '',
    fileType: '',
    responsibilityConstructor: '',
    joinCompany: '',
    rate: '',
    betterThan: 0,
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object().shape({
      projectDivision: Yup.string().max(20, "20자리").nullable(),
      projectNumber: Yup.string().max(75, "75자리").nullable(),
      projectStartYear: Yup.string().min(4, '4자리').max(4, "4자리").nullable(),
      projectEndYear: Yup.string().min(4, '4자리').max(4, "4자리").nullable(),
      projectName: Yup.string().max(50, "50자리").nullable(),
      departmentName: Yup.string().max(50, "50자리").nullable(),
      projectPm: Yup.string().max(50, "50자리").nullable(),
      builderName: Yup.string().max(50, "50자리").nullable(),
      orderCompanyId: Yup.string().max(50, "50자리").nullable(),
      builderCompanyId: Yup.string().max(50, "50자리").nullable(),
      location: Yup.string().max(50, "50자리").nullable(),
      newTech: Yup.string().max(50, "50자리").nullable(),
      responsibilityConstructor: Yup.string().max(50, "50자리").nullable(),
      joinCompany: Yup.string().max(50, "50자리").nullable(),
    }),
    onSubmit: (values) => {
      let tmp = {...values};
      tmp.search = tmp.search || '';
      tmp.searchText = tmp.searchText || '';

      if( Array.isArray(values.purpose) && values.purpose.length > 0 ) {
        values.purpose = values.purpose.map((item) => item.label).join();
      } else if( values.purpose.label !== undefined ) {
        values.purpose = values.purpose.label;
      }

      if( Array.isArray(values.structure) && values.structure.length > 0 ) {
        values.structure = values.structure.map((item) => item.value).join();
      } else if( values.structure.value !== undefined ) {
        values.structure = values.structure.value;
      }

      props.getSearchList(values);
      /*
      let tab = '&tab=desgin';
      if( user?.memNo === 2 ) tab = '&tab=project';
      else if( user?.memNo === 3 ) tab = '&tab=file';

      history("/user/search/list?search=" + values.search + tab);

      const queryString = new URLSearchParams(values).toString();
      history("/user/search/list?" + queryString + tab);
      return;
      */
    }
  });

  /***************************************************************************************
   * 설명 : 데이터 로딩 처리
  ***************************************************************************************/
  const getSearchInit = () => {
    const setData = (data, setData, name, value) => {
      if( data && data.length > 0 )
        setData(getSelectData(data, name, value));
      else
        setData([]);
    }

    // 이미 로딩 되어 있을 경우 건너 뜀
    if(
      divisionList.length > 0 &&
      purpose.length > 0 &&
      businessType.length > 0 &&
      builderType.length > 0 &&
      projectType.length > 0
    ) return;

    let params = {...formik.values};

    params.searchCheckbox1 = isCheckRadio(params.searchCheckbox1, false);
    params.searchCheckbox2 = isCheckRadio(params.searchCheckbox2, false);
    params.searchCheckbox3 = isCheckRadio(params.searchCheckbox3, false);

    getProjectInfoApi(getApi, params, (response) => {
      if( response !== undefined && response.data.result ) {

        // 프로젝트 건축구분
        setData(response.data.data1, setDivisionList, 'projectDivisionName', 'projectDivision');

        // 용도
        if( response.data.data3 && response.data.data3?.length > 0 ) {
          setPurpose([
            ...response.data.data3.map((item) => {
              return {
                label: (item.commonCode.length !== 3) ? 'ㅤ' + item.commonName : item.commonName,
                value: item.commonCode,
                ...item
              }
            })
          ]);
        } else {
          setPurpose([]);
        }

        // 사업유형
        setData(response.data.data4, setBusinessType, 'commonName', 'commonCode');

        // 건축주유형
        setData(response.data.data5, setBuilderType, 'commonName', 'commonCode');

        // 구조
        if( response.data.data9 && response.data.data9?.length > 0 ) {
          setStructure([
            ...response.data.data9.map((item) => {
              return {
                label: (item.commonCode.length !== 3) ? 'ㅤ' + item.commonName : item.commonName,
                value: item.commonCode,
                ...item
              }
            })
          ]);

        } else {
          setStructure([]);
        }

        setData(response.data.data10, setProjectType, 'projectTypeName', 'projectType');

      } else {
        setDivisionList([]);
        setPurpose([]);
        setBusinessType([]);
        setBuilderType([]);
        setProjectType([]);
      }

      setIsLoading(true);
    });
  }

  /***************************************************************************************
   * 설명 : 데이터 로딩 완료 후 처리
  ***************************************************************************************/
  useEffect(() => {
    if( isLoading ) {
      formik.setValues(props.formik.values);

      if( props.formik.values?.purpose !== '' ) {
        let tmp = purpose.filter((item) => props.formik.values?.purpose?.indexOf(item.label) > -1 );
        if( tmp.length > 0 )
          formik.setFieldValue('purpose', tmp);
      }

      if( props.formik.values?.structure !== '' ) {
        let tmp = structure.filter((item) => props.formik.values?.structure?.indexOf(item.value) > -1 );
        if( tmp.length > 0 )
          formik.setFieldValue('structure', tmp);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, props.formik.values]);

  /***************************************************************************************
   * 설명 : 데이터 로딩 처리
  ***************************************************************************************/
  useEffect(() => {
    if( props.isDetail ) {
      getSearchInit();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isDetail]);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <>
      <form className={"detail-search" + (props.isSearchDetail ? ' detail-search-position' : '')} onSubmit={formik.handleSubmit}>
        <header className="detail-header">
          상세검색
          <span className="ml10" />
          <Button onClick={() => formik.setValues(initialValues)}><span className="ri-refresh-line icon"></span></Button>
          <Button className="btn fr" onClick={props.close}><span className="ri-close-line icon"></span></Button>
        </header>

        <section className="detail-body">

          <table className="input-table mb15">
            <colgroup>
              <col style={{width: '8%'}} />
              <col style={{width: '17%'}} />
              <col style={{width: '8%'}} />
              <col style={{width: '17%'}} />
              <col style={{width: '8%'}} />
              <col style={{width: '17%'}} />
              <col style={{width: '8%'}} />
              <col style={{width: '17%'}} />
            </colgroup>

            <tbody>
              <tr>
                <th scope="row"><label htmlFor="projectDivision">구분</label></th>
                <td>
                  <SelectEx
                    name="projectDivision"
                    formik={formik}
                    fullWidth={true}
                    inputProps={{
                      maxLength: 20
                    }}
                    data={[
                      {label: '전체', value: ''},
                    ].concat(divisionList)}
                  />
                </td>
                <th scope="row"><label htmlFor="projectNumber">PJ.NO</label></th>
                <td>
                  <InputEx
                    name="projectNumber"
                    formik={formik}
                    fullWidth={true}
                    inputProps={{
                      maxLength: 75
                    }}
                  />
                </td>
                <th scope="row"><label htmlFor="projectStartYear">년도 시작</label></th>
                <td>
                  <InputEx
                    name="projectStartYear"
                    formik={formik}
                    fullWidth={true}
                    inputProps={{
                      maxLength: 4
                    }}
                    label="년도 4자리"
                    onChange={(event) => {
                      if (event.nativeEvent.data ){
                        event.target.value = onlyNumber(event.target.value);
                      }
                    }}
                    onBlur={(event) => {
                      event.target.value = onlyNumber(event.target.value);
                    }}
                  />
                </td>
                <th scope="row"><label htmlFor="projectEndYear">년도 끝</label></th>
                <td>
                  <InputEx
                    name="projectEndYear"
                    formik={formik}
                    fullWidth={true}
                    inputProps={{
                      maxLength: 4
                    }}
                    label="년도 4자리"
                    onChange={(event) => {
                      if (event.nativeEvent.data ){
                        event.target.value = onlyNumber(event.target.value);
                      }
                    }}
                    onBlur={(event) => {
                      event.target.value = onlyNumber(event.target.value);
                    }}
                  />
                </td>
              </tr>
              <tr>
                <th scope="row"><label htmlFor="projectName">PJ명</label></th>
                <td colSpan={3}>
                  <InputEx
                    name="projectName"
                    formik={formik}
                    fullWidth={true}
                    label="프로젝트명"
                    inputProps={{
                      maxLength: 50
                    }}
                  />
                </td>
                <th scope="row"><label htmlFor="departmentName">주관부서</label></th>
                <td>
                  <InputEx
                    name="departmentName"
                    formik={formik}
                    fullWidth={true}
                    inputProps={{
                      maxLength: 50
                    }}
                  />
                </td>
                <th scope="row"><label htmlFor="projectPm">담당PM</label></th>
                <td>
                  <InputEx
                    name="projectPm"
                    formik={formik}
                    fullWidth={true}
                    inputProps={{
                      maxLength: 50
                    }}
                  />
                </td>
              </tr>
              <tr>
                <th scope="row"><label htmlFor="projectType">유형</label></th>
                <td>
                  <SelectEx
                    name="projectType"
                    formik={formik}
                    fullWidth={true}
                    data={[
                      {label: '전체', value: ''}
                    ].concat(projectType)}
                  />
                </td>
                <th scope="row"><label htmlFor="businessType">사업유형</label></th>
                <td>
                  <SelectEx
                    name="businessType"
                    formik={formik}
                    fullWidth={true}
                    data={[
                      {label: '전체', value: ''},
                    ].concat(businessType)}
                  />
                </td>
                <th scope="row"><label htmlFor="builderType">건축주유형</label></th>
                <td>
                  <SelectEx
                    name="builderType"
                    formik={formik}
                    fullWidth={true}
                    data={[
                      {label: '전체', value: ''},
                    ].concat(builderType)}
                  />
                </td>
                <th scope="row"><label htmlFor="builderName">건축주</label></th>
                <td>
                  <InputEx
                    name="builderName"
                    formik={formik}
                    fullWidth={true}
                    inputProps={{
                      maxLength: 50
                    }}
                  />
                </td>
              </tr>
              <tr>
                <th scope="row"><label htmlFor="purpose">용도</label></th>
                <td colSpan={3}>
                  <SelectSearchEx
                    name="purpose"
                    formik={formik}
                    fullWidth={true}
                    optionWidth="100%"
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    isMulti={true}
                    placeholder="용도 검색"
                    data={[
                      {label: '전체', value: ''},
                    ].concat(purpose)}
                  />
                </td>
                <th scope="row"><label htmlFor="structure">구조</label></th>
                <td colSpan={3}>
                  <SelectSearchEx
                    name="structure"
                    formik={formik}
                    fullWidth={true}
                    optionWidth="100%"
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    isMulti={true}
                    placeholder="구조 검색"
                    data={[
                      {label: '전체', value: ''},
                    ].concat(structure)}
                  />
                </td>
              </tr>
              <tr>
                <th scope="row"><label htmlFor="orderCompanyId">발주처</label></th>
                <td>
                  <InputEx
                    name="orderCompanyId"
                    formik={formik}
                    fullWidth={true}
                    inputProps={{
                      maxLength: 50
                    }}
                  />
                </td>
                <th scope="row"><label htmlFor="builderCompanyId">시공사</label></th>
                <td>
                  <InputEx
                    name="builderCompanyId"
                    formik={formik}
                    fullWidth={true}
                    inputProps={{
                      maxLength: 50
                    }}
                  />
                </td>
                <th scope="row"><label htmlFor="location">위치</label></th>
                <td colSpan={3}>
                  <InputEx
                    name="location"
                    formik={formik}
                    fullWidth={true}
                    inputProps={{
                      maxLength: 50
                    }}
                  />
                </td>
              </tr>
              <tr>
                <th scope="row"><label htmlFor="scaleUnderStart">층수(지하) 시작</label></th>
                <td>
                  <InputEx
                    name="scaleUnderStart"
                    formik={formik}
                    fullWidth={true}
                    inputProps={{
                      maxLength: 4
                    }}
                    onChange={(event) => {
                      if (event.nativeEvent.data ){
                        event.target.value = onlyNumber(event.target.value);
                      }
                    }}
                    onBlur={(event) => {
                      event.target.value = onlyNumber(event.target.value);
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">&gt;=</InputAdornment>,
                    }}
                  />
                </td>
                <th scope="row"><label htmlFor="scaleUnderEnd">층수(지하) 끝</label></th>
                <td>
                  <InputEx
                    name="scaleUnderEnd"
                    formik={formik}
                    fullWidth={true}
                    inputProps={{
                      maxLength: 4
                    }}
                    onChange={(event) => {
                      if (event.nativeEvent.data ){
                        event.target.value = onlyNumber(event.target.value);
                      }
                    }}
                    onBlur={(event) => {
                      event.target.value = onlyNumber(event.target.value);
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">&lt;=</InputAdornment>,
                    }}
                  />
                </td>
                <th scope="row"><label htmlFor="scaleStart">층수(지상) 시작</label></th>
                <td>
                  <InputEx
                    name="scaleStart"
                    formik={formik}
                    fullWidth={true}
                    inputProps={{
                      maxLength: 4
                    }}
                    onChange={(event) => {
                      if (event.nativeEvent.data ){
                        event.target.value = onlyNumber(event.target.value);
                      }
                    }}
                    onBlur={(event) => {
                      event.target.value = onlyNumber(event.target.value);
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">&gt;=</InputAdornment>,
                    }}
                  />
                </td>
                <th scope="row"><label htmlFor="scaleEnd">층수(지상) 끝</label></th>
                <td>
                  <InputEx
                    name="scaleEnd"
                    formik={formik}
                    fullWidth={true}
                    inputProps={{
                      maxLength: 4
                    }}
                    onChange={(event) => {
                      if (event.nativeEvent.data ){
                        event.target.value = onlyNumber(event.target.value);
                      }
                    }}
                    onBlur={(event) => {
                      event.target.value = onlyNumber(event.target.value);
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">&lt;=</InputAdornment>,
                    }}
                  />
                </td>
              </tr>
              <tr>
                <th scope="row"><label htmlFor="totalSizeStart">연면적 시작</label></th>
                <td>
                  <InputEx
                    name="totalSizeStart"
                    formik={formik}
                    fullWidth={true}
                    inputProps={{
                      maxLength: 20
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">㎡</InputAdornment>,
                    }}
                    onChange={(event) => {
                      if (event.nativeEvent.data ){
                        event.target.value = onlyNumber(event.target.value);
                      }
                    }}
                    onBlur={(event) => {
                      event.target.value = onlyNumber(event.target.value);
                    }}
                  />
                </td>
                <th scope="row"><label htmlFor="totalSizeEnd">연면적 끝</label></th>
                <td>
                  <InputEx
                    name="totalSizeEnd"
                    formik={formik}
                    fullWidth={true}
                    inputProps={{
                      maxLength: 20
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">㎡</InputAdornment>,
                    }}
                    onChange={(event) => {
                      if (event.nativeEvent.data ){
                        event.target.value = onlyNumber(event.target.value);
                      }
                    }}
                    onBlur={(event) => {
                      event.target.value = onlyNumber(event.target.value);
                    }}
                  />
                </td>
                <th scope="row"><label htmlFor="isNewTech">신기술 적용여부</label></th>
                <td>
                  <ToggleButtonGroupEx
                    name="isNewTech"
                    exclusive={true}
                    formik={formik}
                    data={[
                      {label: '전체', value: ''},
                      {label: '적용', value: '1'},
                      {label: '비적용', value: '0'}
                    ]}
                    onChange={(event, newValue) => {
                      if( newValue === '0' ) {
                        setDisabledNewTech(true);
                        formik.setFieldValue('newTech', '');
                      } else {
                        setDisabledNewTech(false);
                      }
                    }}
                  />
                </td>
                <th scope="row"><label htmlFor="newTech">신기술 번호</label></th>
                <td>
                  <InputEx
                    name="newTech"
                    formik={formik}
                    fullWidth={true}
                    disabled={disabledNewTech}
                    inputProps={{
                      maxLength: 50
                    }}
                  />
                </td>
              </tr>
            <tr>
              <th scope="row"><label htmlFor="isAwardInfo">수상여부</label></th>
              <td>
                <ToggleButtonGroupEx
                  name="isAwardInfo"
                  exclusive={true}
                  formik={formik}
                  data={[
                    {label: '전체', value: ''},
                    {label: '수상', value: '1'},
                    {label: '비수상', value: '0'}
                  ]}
                />
              </td>
              <th scope="row"><label htmlFor="isOwnership">단독여부</label></th>
              <td>
                <ToggleButtonGroupEx
                  name="isOwnership"
                  exclusive={true}
                  formik={formik}
                  data={[
                    {label: '전체', value: ''},
                    {label: '단독', value: '1'},
                    {label: '비단독', value: '0'}
                  ]}
                />
              </td>
              <th scope="row"><label htmlFor="isCooperationCompany">해외사 협업여부</label></th>
              <td>
                <ToggleButtonGroupEx
                  name="isCooperationCompany"
                  formik={formik}
                  exclusive={true}
                  data={[
                    {label: '전체', value: ''},
                    {label: '단독', value: '1'},
                    {label: '비단독', value: '0'}
                  ]}
                />
              </td>
              <th scope="row"><label htmlFor="fileType">파일형식</label></th>
              <td>
                <SelectEx
                  name="fileType"
                  formik={formik}
                  fullWidth={true}
                  data={[
                    {label: '전체', value: '0'},
                    {label: '이미지', value: '1'},
                    {label: '문서', value: '2'},
                    {label: '도면(DWG)', value: '3'},
                    {label: '압축', value: '4'}
                  ]}
                />
              </td>
            </tr>
            <tr>
              <th scope="row"><label htmlFor="responsibilityConstructor">책임건축사</label></th>
              <td>
                <InputEx
                  name="responsibilityConstructor"
                  formik={formik}
                  fullWidth={true}
                  inputProps={{
                    maxLength: 50
                  }}
                />
              </td>
              <th scope="row"><label htmlFor="joinCompany">참여업체</label></th>
              <td>
                <InputEx
                  name="joinCompany"
                  formik={formik}
                  fullWidth={true}
                  inputProps={{
                    maxLength: 50
                  }}
                />
              </td>
              <th scope="row"><label htmlFor="rate">지분율</label></th>
              <td>
                <div className="fl" style={{width: 'calc(35% - 10px)'}}>
                  <InputEx
                    name="rate"
                    formik={formik}
                    fullWidth={true}
                    InputProps={{
                      maxLength: 3,
                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }}
                    onChange={(event) => {
                      if (event.nativeEvent.data ){
                        event.target.value = onlyNumber(event.target.value);
                      }
                    }}
                    onBlur={(event) => {
                      event.target.value = onlyNumber(event.target.value);
                    }}
                  />
                </div>

                <div className="fr" style={{width: '65%'}}>
                  <ToggleButtonGroupEx
                    name="betterThan"
                    formik={formik}
                    exclusive={true}
                    data={[
                      {label: '보다 큰', value: '0'},
                      {label: '보다 작은', value: '1'}
                    ]}
                  />
                </div>
              </td>
              <th></th>
              <td></td>
            </tr>
          </tbody>
        </table>

        </section>

        <footer className="detail-footer">
          <div className="center">
            <Button
              type="submit"
              color="secondary"
              variant="contained"
              className="btn f16 center"
            >검색하기</Button>
          </div>
        </footer>
      </form>
    </>
  );
}
/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default DetailSearchBox;