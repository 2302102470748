/*****************************************************************************************
 * 설명 : 관리자 > 시스템관리 > 권한그룹관리 > 메뉴별 권한
*****************************************************************************************/
import { Button, Checkbox } from '@mui/material';
import Modal from '@mui/material/Modal';
import { AgGridReact } from 'ag-grid-react';
import { useFormik } from 'formik';
import 'moment/locale/ko';
import { useEffect, useRef, useState } from 'react';

import BtnAgGridSave from 'components/btnAgGridSave';
import { BtnRefresh, BtnSearch } from 'components/common';
import { ButtonEx, SelectEx } from 'components/inputEx';
import alertMsg from "components/message";
import customTooltipAgGrid from 'components/tooltipAgGrid';

import { MESSAGE_DELAY } from 'config/config';
import useGridConfig from 'hooks/useGridConfig';

import { getAuthListApi, setAuthGroupSaveApi } from 'service/auth';
import { getSiteInfoApi } from 'service/cms';
import Restful from "service/restful";
import { getSelectData } from 'service/utils';

import AlertDialog from 'components/alertDiolog';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
function TabPositionMenu(props) {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const gridRef = useRef();
  const [getApi] = Restful();

  const [siteList, setSiteList] = useState([]);
  const [list, setList] = useState([]);

  // 권한그룹 권한 리스트 AgGrid API
  const [gridApiList, setGridApiList] = useState(null);

  const [openModal, setOpenModal] = useState({open: false, modal: 'add', data: []});

  const formik = useFormik({
    initialValues: {
      siteSeq: null,
    },
    onSubmit: (values) => {
      //onRowClickedGroup();
    }
  });

  /***************************************************************************************
   * 설명 : 헤더 체크
  ***************************************************************************************/
  function agGridHeaderCheckbox(params, props) {
    const className = ( props.className !== undefined ) ? 'Checkbox ' + props.className : 'Checkbox';

    return (
      <>
        <Checkbox
          className={className}
          onChange={(e) => {setColumnAll(e, params)}}
          color="primary"
        />
        <span>{params.displayName}</span>
      </>
    )
  }

  /***************************************************************************************
   * 설명 : 체크박스 처리
  ***************************************************************************************/
  function agGridCheckbox(params, props) {
    let check = ( params.value === '0' || params.value === null || params.value === 0 ) ? false : true;
    const className = ( props.className !== undefined ) ? 'Checkbox ' + props.className : 'Checkbox';

    return (
      <Checkbox className={className}
        onChange={(e) => {setColumnCheck(e, params)}}

        // onChange={(event) => {
        //   params.node.data[params.colDef.field] = event.target.checked === false ? 0 : 1;
        //   params.api.updateRowData({ update: [params.node.data]});
        // }}
        checked={check}
        color="primary"
      />
    )
  }

  // 권한그룹 설정 컬럼 정보
  const [columnDefsAuth, setColumnDefsAuth] = useState([
    { headerName: '', field: 'checkrow', cellClass: 'cp', width: 60,
      cellRendererFramework: function(params) {
        let check = ( params.value === 0 || params.value === null || params.value === undefined ) ? false : true;
        const className = ( props.className !== undefined ) ? 'Checkbox ' + props.className : 'Checkbox';

        return (
          <Checkbox
            className={className}
            onChange={(event) => {
              params.node.data.checkrow = event.target.checked === false? 0 : 1;
              params.node.data.isView = event.target.checked === false? 0 : 1;
              params.node.data.isWrite = event.target.checked === false? 0 : 1;
              params.node.data.isModify = event.target.checked === false? 0 : 1;
              params.node.data.isDelete = event.target.checked === false? 0 : 1;
              params.node.data.isExcelDownload = event.target.checked === false? 0 : 1;
              /*
              params.node.data.isEmail = event.target.checked === false? 0 : 1;
              params.node.data.isSms = event.target.checked === false? 0 : 1;
              */
              params.node.data.isAdmin = event.target.checked === false? 0 : 1;
              params.node.data.isAdminSearch = event.target.checked === false? 0 : 1;
              params.node.data.isDownload = event.target.checked === false? 0 : 1;
              params.node.data.isPreview = event.target.checked === false? 0 : 1;

              params.api.forEachNode( (node) => {
                if( event.target.checked ) {
                  node.updateData(node.data);
                } else {
                  node.updateData(node.data);
                }
              });
            }}
            checked={check}
            color="primary"
          />
        )
      },
    },
    { headerName: '메뉴명', field: 'menuName', width: 300, cellClass: 'cp',
      valueGetter: (params) => {
        let level = Math.round(params.data.menuOrder?.length / 3);
        let space = '';
        for(let i = 0; i < level; i++) space += 'ㅤㅤ';
        return space + params.data.menuName;
      }
    },
    { headerName: '조회', field: 'isView', cellClass: 'cp center', width: 100, suppressMenu: true,
      cellRendererFramework: (params) => agGridCheckbox(params, props),
      headerComponentFramework: (params) => agGridHeaderCheckbox(params, props)
    },
    { headerName: '등록', field: 'isWrite', cellClass: 'cp center', width: 100,
      cellRendererFramework: (params) => agGridCheckbox(params, props),
      headerComponentFramework: (params) => agGridHeaderCheckbox(params, props)
    },
    { headerName: '수정', field: 'isModify', cellClass: 'cp center', width: 100,
      cellRendererFramework: (params) => agGridCheckbox(params, props),
      headerComponentFramework: (params) => agGridHeaderCheckbox(params, props)
    },
    { headerName: '삭제', field: 'isDelete', cellClass: 'cp center', width: 100,
      cellRendererFramework: (params) => agGridCheckbox(params, props),
      headerComponentFramework: (params) => agGridHeaderCheckbox(params, props)
    },
    { headerName: '엑셀', field: 'isExcelDownload', cellClass: 'cp center', width: 100,
      cellRendererFramework: (params) => agGridCheckbox(params, props),
      headerComponentFramework: (params) => agGridHeaderCheckbox(params, props)
    },
    /*
    { headerName: 'SMS', field: 'isSms', cellClass: 'cp center', width: 100,
      cellRendererFramework: (params) => agGridCheckbox(params, props),
      headerComponentFramework: (params) => agGridHeaderCheckbox(params, props)
    },
    { headerName: 'Email', field: 'isEmail', cellClass: 'cp center', width: 100,
      cellRendererFramework: (params) => agGridCheckbox(params, props),
      headerComponentFramework: (params) => agGridHeaderCheckbox(params, props)
    },
    */
    { headerName: '관리자', field: 'isAdmin', cellClass: 'cp center', width: 100,
      cellRendererFramework: (params) => agGridCheckbox(params, props),
      headerComponentFramework: (params) => agGridHeaderCheckbox(params, props)
    },
    { headerName: '관리자검색', field: 'isAdminSearch', cellClass: 'cp center', width: 100,
      cellRendererFramework: (params) => agGridCheckbox(params, props),
      headerComponentFramework: (params) => agGridHeaderCheckbox(params, props)
    },
    { headerName: '다운로드', field: 'isDownload', cellClass: 'cp center', width: 100,
      cellRendererFramework: (params) => {
        if(
          (
            params.data.menuName.indexOf('프로젝트') > -1 ||
            params.data.menuName.indexOf('검색') > -1
          ) && (
            params.data.menuName.indexOf('통계') < 0 &&
            params.data.menuName.indexOf('로그') < 0
          )
        )
          return agGridCheckbox(params, props);
        else
          return '';
      },
      headerComponentFramework: (params) => agGridHeaderCheckbox(params, props)
    },
    { headerName: '미리보기', field: 'isPreview', cellClass: 'cp center', width: 100,
      cellRendererFramework: (params) => {
        if(
          (
            params.data.menuName.indexOf('프로젝트') > -1 ||
            params.data.menuName.indexOf('검색') > -1
          ) && (
            params.data.menuName.indexOf('통계') < 0 &&
            params.data.menuName.indexOf('로그') < 0
          )
        )
          return agGridCheckbox(params, props);
        else
          return '';
      },
      headerComponentFramework: (params) => agGridHeaderCheckbox(params, props)
    },
  ]);

  const [gridConfigAuth, setGridUpdateAuth] = useGridConfig(2, setColumnDefsAuth);

  /***************************************************************************************
   * 설명 : 권한그룹 설정 전체 선택/해제
  ***************************************************************************************/
  const selectAll = (checked) => {
    gridApiList.forEachNode( (node) => {
      node.setDataValue( 'checkrow', checked );
      node.setDataValue( 'isView', checked );
      node.setDataValue( 'isWrite', checked );
      node.setDataValue( 'isModify', checked );
      node.setDataValue( 'isDelete', checked );
      node.setDataValue( 'isExcelDownload', checked );
      /*
      node.setDataValue( 'isSms', checked );
      node.setDataValue( 'isEmail', checked );
      */
      node.setDataValue( 'isAdmin', checked );
      node.setDataValue( 'isAdminSearch', checked );
      node.setDataValue( 'isDownload', checked );
      node.setDataValue( 'isPreview', checked );
    });
  }

  const setColumnAll = (e, params) => {
    params.api.forEachNode( (node) => {
      if( e.target.checked ) {
        node.setDataValue( params.column.colDef.field, 1 );
      } else {
        node.setDataValue( params.column.colDef.field, 0 );
      }
    });
  }

  /***************************************************************************************
   * 설명 : 컬럼 조정
  ***************************************************************************************/
  const setColumnCheck = (e, params) => {
    params.node.data[params.column.colDef.field] = (e.target.checked === false) ? 0 : 1;
    params.api.forEachNode( (node) => {
      if( e.target.checked ) {
        node.updateData(node.data);
      } else {
        node.updateData(node.data);
      }
    });
  }

  /***************************************************************************************
   * 설명 : 권한 그룹 저장
  ***************************************************************************************/
  const saveAuthGroup = () => {
    let data = [];
    gridApiList.forEachNode((node, i) => {
      data.push({
        //authgroupSeq: authgroupSeq,
        seq: node.data.seq === null ? 0 : node.data.seq,
        authType: 0,
        menuSeq: node.data.menuSeq === null ? '' : node.data.menuSeq,
        authGroup: 3,
        authGroupSeq: props?.selected[0]?.positionName,
        isView: node.data.isView === null ? 0 : node.data.isView,
        isWrite: node.data.isWrite === null ? 0 : node.data.isWrite,
        isModify: node.data.isModify === null ? 0 : node.data.isModify,
        isDelete: node.data.isDelete === null ? 0 : node.data.isDelete,
        isExcelDownload: node.data.isExcelDownload === null ? 0 : node.data.isExcelDownload,
        isAdmin: node.data.isAdmin === null ? 0 : node.data.isAdmin,
        isAdminSearch: node.data.isAdminSearch === null ? 0 : node.data.isAdminSearch,
        isDownload: node.data.isDownload === null ? 0 : node.data.isDownload,
        isPreview: node.data.isPreview === null ? 0 : node.data.isPreview,
      });

      /*
      data[i].authEmail = node.data.authEmail === null ? 0 : node.data.authEmail;
      data[i].authSms = node.data.authSms === null ? 0 : node.data.authSms;
      */
    });

    setAuthGroupSaveApi(getApi, data, (response => {
      if( response !== undefined && response.data.result ) {
        alertMsg(response.data.message, "S", MESSAGE_DELAY);

        if( props.getList ) props.getList();

        props.close();

      } else {
        alertMsg(response.data.message || '서버와의 통신에 실패하였습니다.', "E", MESSAGE_DELAY);
      }
    }));
  }

  /***************************************************************************************
   * 설명 : 권한 정보 가져오기
  ***************************************************************************************/
  const getAuthGroupList = (siteSeq) => {
    if( props.selected === undefined || props.selected[0]?.seq === undefined ) return;

    let params = {
      siteSeq: ( siteSeq === undefined && siteList.length > 0 ) ? siteList[0]?.value : siteSeq,
      authType: 0,
      authGroup: 3,
      positionName: props.selected[0]?.positionName
    }

    getAuthListApi(getApi, params, (response) => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        setList(response.data.data);
      } else {
        setList([]);

      }
    });
  }

  /***************************************************************************************
   * 설명 : 사이트 정보 가져오기
  ***************************************************************************************/
  const getSiteInfo = () => {
    getSiteInfoApi(getApi, (response) => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        setSiteList(getSelectData(response.data.data, 'siteName', 'seq'));

        formik.setFieldValue('siteSeq', response.data.data[0]?.seq);

        getAuthGroupList(response.data.data[0].seq);

      } else {
        setSiteList([]);
      }
    });
  }

  /***************************************************************************************
   * 설명 : Step - 2, 그룹 리스트 변경 시 페이지 데이터 가져오기
  ***************************************************************************************/
  useEffect(() => {
    if(formik.values.siteSeq !== '' )
      getAuthGroupList(formik.values.siteSeq);

    // eslint-disable-next-line
  }, [formik.values.siteSeq])

  /***************************************************************************************
   * 설명 : 그룹 변경 시 메뉴별 권한 가져오기
  ***************************************************************************************/
  useEffect(() => {
    if(siteList.length > 0 && props?.selected?.length > 0 && props.selected !== undefined )
      getAuthGroupList();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteList, props.selected])

  /***************************************************************************************
   * 설명 : Step - 1, 사이트 정보 가져오기
  ***************************************************************************************/
  useEffect( () => {
    if(props?.selected?.length > 0 && props.selected !== undefined )
      getSiteInfo();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.tabIndex, props.selected]);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <section className='ml10'>
      <header className="admin-cms-search-header">
        <div className="left">
          <BtnRefresh></BtnRefresh>
          <BtnSearch onClick={() => getAuthGroupList(formik.values.siteSeq)}></BtnSearch>
          <BtnAgGridSave click={setGridUpdateAuth}></BtnAgGridSave>

          <SelectEx
            name="siteSeq"
            formik={formik}
            fullWidth={false}
            data={siteList}
          />
        </div>

        <div className="text-right">
          <Button
            color="inherit"
            variant="outlined"
            className="Btn"
            onClick={() => selectAll(1)}
          >전체선택</Button>

          <Button
            color="error"
            variant="outlined"
            className="ml5"
            onClick={() => selectAll(0)}
          >전체해제</Button>

          <ButtonEx
            title="저장"
            auth="isModify"
            color="secondary"
            className="ml5"
            variant="contained"
            onClick={saveAuthGroup}
          />
        </div>
      </header>

      <section className="admin-cms-body">
        <section className="ag-theme-balham grid pad-height165" style={{height:'calc(100vh - 130px)'}}>
          <div style={{ height: '100%' }}>
            <AgGridReact
              ref={gridRef}
              defaultColDef={{
                sortable: true,
                resizable: true,
                filter: false,
                lockVisible: true,
                tooltipComponent: customTooltipAgGrid,
              }}
              tooltipShowDelay={0}
              tooltipHideDelay={2000}
              rowSelection={'multiple'}
              columnDefs={columnDefsAuth}
              rowData={list}
              onGridReady={(params) => {
                setGridApiList(params.api);
                gridConfigAuth(params.columnApi);
              }}
              overlayNoRowsTemplate="검색된 내용이 없습니다."
            />
          </div>
        </section>
      </section>

    { openModal.open && openModal.modal === 'dialog' &&
      <Modal
        open={openModal.open}
        onClose={() => setOpenModal({ open: false, modalName: 'dialog', data: openModal.data })}
      >
        <AlertDialog
          open={openModal.open}
          close={() => setOpenModal({ open: false, modalName: 'dialog', data: openModal.data })}
          title="모듈 삭제"
          message={`선택하신 "관리자"를 삭제하시겠습니까?`}
          confirm="삭제"
          indicator={props.indicator}
          history={props.history}
        />
      </Modal>
    }
    </section>
  )
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default TabPositionMenu;