/**
 * * PMS 로그 가져오기
 * @param {*} getApi
 * @param {*} params
 * @param {*} func
 * @returns
 */
export function getPmsLogApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("get", '/api/v1/log/pms', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  })
}

/**
 * * 그룹웨어 로그 가져오기
 * @param {*} getApi
 * @param {*} params
 * @param {*} func
 * @returns
 */
export function getGroupwareLogApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("get", '/api/v1/log/groupware', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  })
}

/**
 * * 로그 리스트 가져오기
 * @param {*} getApi
 * @param {*} params
 * @param {*} func
 * @returns
 */
export function getLogApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("get", '/api/v1/log', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  })
}

/**
 * * 프로젝트 로그 리스트 가져오기
 * @param {*} getApi
 * @param {*} params
 * @param {*} func
 * @returns
 */
export function getProjectLogApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("get", '/api/v1/log/project', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  });
}
