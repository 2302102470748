/*****************************************************************************************
 * 설명 : 관리자 > 컨텐츠관리 > 게시판 관리 > 게시판 테이블 추가/수정
 * URI : /admin/board
*****************************************************************************************/
import React from 'react';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import * as Yup from "yup";

import { MESSAGE_DELAY } from 'config/config';

import {setBoardTableApi} from 'service/board';
import Restful from 'service/restful';

import { InputEx, ToggleButtonGroupEx } from 'components/inputEx';
import alertMsg from 'components/message';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
export const BoardTableAddModal = ( props ) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [getApi] = Restful();

  const formik = useFormik({
    initialValues: {
      tableType: 'BOARD',
      tableName: '',
    },
    validationSchema: Yup.object().shape({
      tableName: Yup.string().max(30, "30자리").required('필수'),
    }),
    onSubmit: (values) => {
      setBoardTableApi(getApi, values, (response) => {
        if( response !== undefined && response.data.result ) {
          alertMsg(response.data.message, "S", MESSAGE_DELAY);

          if( props.getList ) props.getList();

          props.close();

        } else {
          alertMsg(response.data.message || '서버와의 통신에 실패하였습니다.', "E", MESSAGE_DELAY);
        }
      })
    }
  });

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <section className="modal-wrap" style={{ width: '100%' }}>
      <form onSubmit={formik.handleSubmit}>
        <header className="modal-header">
          <div className="modal-title">게시판테이블 추가</div>
          <Button className="close-btn" onClick={props.close}>
            <CloseIcon style={{ color: '#fff', fontSize: '28px' }}></CloseIcon>
          </Button>
        </header>

        <section className="modal-body">
          <table className="input-table">
            <colgroup>
              <col style={{width: '25%'}} />
              <col style={{width: '75%'}} />
            </colgroup>

            <tbody>
              <tr>
                <th scope="row"><label htmlFor="tableType">테이블 종류</label></th>
                <td>
                  <ToggleButtonGroupEx
                    name="tableType"
                    formik={formik}
                    fullWidth={true}
                    exclusive={true}
                    className="no-min-width"
                    data={[
                      {label: '게시판', value: 'BOARD'},
                      // {label: '댓글', value: 'COMMENT'},
                      {label: '업로드', value: 'UPLOAD'},
                      {/*
                      {label: '추천', value: 'RECOMMEND'},
                      {label: '반대', value: 'OPPOSITE'},
                      {label: '신고', value: 'REPORT'},
                      {label: '팝업', value: 'POPUP'}
                      */}
                    ]}
                  />
                </td>
              </tr>
              <tr>
                <th scope="row"><label htmlFor="tableName">테이블명</label></th>
                <td>
                  <InputEx
                    name="tableName"
                    formik={formik}
                    fullWidth={true}
                    label="영문 및 _"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </section>

        <footer className="modal-footer">
          <div className="modal-footer-center">
            <Button
              color="inherit"
              variant="outlined"
              className="Btn"
              onClick={() => {
                props.close();
              }}
            >창닫기</Button>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              className="Btn ml10"
            >저장하기</Button>
          </div>
        </footer>
      </form>
    </section>
  )
}

export default BoardTableAddModal;