/*****************************************************************************************
 * 설명 : 관리자 > 컨텐츠관리 > 모듈 관리
 * URI : /admin/site
*****************************************************************************************/
import Modal from '@mui/material/Modal';
import { AgGridReact } from 'ag-grid-react';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import * as Yup from "yup";

import BtnAgGridSave from 'components/btnAgGridSave';
import ChipEx from 'components/chipEx';
import { BtnRefresh, BtnSearch } from 'components/common';
import { ButtonEx, SelectEx } from 'components/inputEx';
import customTooltipAgGrid from 'components/tooltipAgGrid';

import { deleteModuleInfoApi, getModuleInfoApi, getSiteInfoApi, sortModuleInfoApi } from 'service/cms';
import Restful from "service/restful";
import { comma, getSelectData } from 'service/utils';

import { MESSAGE_DELAY } from 'config/config';

import useGridConfig from 'hooks/useGridConfig';

import AlertDialog from 'components/alertDiolog';
import alertMsg from 'components/message';
import ModuleModal from 'pages/admin/cms/moduleModal';
import Layout from 'pages/layout/layout';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const Module = ( props ) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [gridApi, setGridApi] = useState({});
  const [getApi] = Restful();

  const [count] = '1';

  const currentMenu = useSelector(state => state?.menuList?.current);

  const [list, setList] = useState([]);
  const [siteList, setSiteList] = useState([]);

  const [openModal, setOpenModal] = useState({open: false, modal: 'add', data: []});

  const formik = useFormik({
    initialValues: {
      searchText: '',
    },
    validationSchema: Yup.object().shape({
    }),
    onSubmit: (values) => {
      getModuleList();
    }
  });

  // table column
  const [columnDefs, setColumnDefs] = useState([
    { headerName: '', field: 'seq', width: 40, cellClass: 'center', flex: 0,
      headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
    { headerName: '정렬', field: 'sortOrder', width: 52, cellClass: 'cp center', rowDrag: true, filter: false, editable: false, resize: false },
    { headerName: '번호', field: 'rowIndex', width: 55, cellClass: 'cp text-right',
      valueGetter: (params) => {
        return comma(params.node.rowIndex + 1);
      }
    },
    { headerName:'사이트', field: 'siteName', width: 250, cellClass: 'cp', tooltipField: 'siteName',
      valueGetter: (params) => {
        if( params.data.siteName === '' || params.data.siteName === null )
          return '공통';
        else
          return params.data.siteName;
      }
    },
    { headerName:'모듈', field: 'module', width: 200, cellClass: 'cp' },
    { headerName:'모듈명', field: 'moduleName', width: 300, cellClass: 'cp', tooltipField: 'moduleName' },
    { headerName:'모듈패스', field: 'modulePath', width: 400, cellClass: 'cp', tooltipField: 'modulePath' },
    { headerName: '사용여부', field: 'isUse', width: 100, cellClass: 'cp center',
      cellRendererFramework: function(params) {
        if( parseInt(params.data.isUse) === 1 )
          return <ChipEx color="success" variant="outlined" size="small" label="사용" />;
        else
          return <ChipEx color="default" variant="outlined" size="small" label="사용안함" />;
      }
    },
    {headerName: '수정', field: 'modify', cellClass: 'cp center', width: 100,
      cellRendererFramework: function (params) {
        return <ButtonEx title="수정" auth="isModify" color="secondary" variant="outlined" className="gridBtn" onClick={() => setOpenModal({open: true, modal: 'add', data: params.data})} />;
      }
    }
  ]);

  const [gridConfig, setGridUpdate] = useGridConfig(1, setColumnDefs);

  /***************************************************************************************
   * 설명 : 사이트 리스트 가져오기
  ***************************************************************************************/
  const getModuleList = () => {
    getModuleInfoApi(getApi, formik.values, (response) => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        setList(response.data.data);
      } else {
        setList([]);
      }
    });
  }

  /***************************************************************************************
   * 설명 : 사이트 리스트 가져오기
  ***************************************************************************************/
  const getSiteList = () => {
    getSiteInfoApi(getApi, (response) => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        setSiteList(getSelectData(response.data.data, 'siteName', 'seq'));
      } else {
        setSiteList([]);
      }
    });
  }

  /***************************************************************************************
   * 설명 : 모듈 삭제
  ***************************************************************************************/
  const deleteModule = () => {
    let nodes = gridApi.getSelectedRows();

    if( nodes.length < 1 ) {
      alertMsg("삭제할 모듈을 선택하시기 바랍니다.", "W", MESSAGE_DELAY);
      return;
    }

    if( ! window.confirm("선택한 모듈을 삭제하시겠습니까?") ) return;

    let params = nodes.map((item) => {
      return {seq: item.seq}
    });

    deleteModuleInfoApi(getApi, params, (response) => {
      if( response !== undefined && response.data.result ) {
        alertMsg(response.data.message, "S", MESSAGE_DELAY);

        getModuleList();

      } else {
        alertMsg(response.data.message || '서버와의 통신에 실패하였습니다.', "E", MESSAGE_DELAY);
      }
    })
  }

  /***************************************************************************************
   * 설명 : 순서 저장
  ***************************************************************************************/
  const setSortModule = () => {
    let nodes = gridApi.getSelectedRows();

    if( nodes.length < 1 ) {
      if( ! window.confirm("모듈 전체의 순서를 저장하시겠습니까?") ) return;

      nodes = [];
      gridApi.forEachNode((item) => {
        nodes.push(item.data);
      });
    } else if( ! window.confirm("선택한 모듈의 순서를 저장하시겠습니까?") ) return;

    let params = nodes.map((item) => {
      return {seq: item.seq}
    });

    sortModuleInfoApi(getApi, params, (response) => {
      if( response !== undefined && response.data.result ) {
        alertMsg(response.data.message, "S", MESSAGE_DELAY);

      } else {
        alertMsg(response.data.message || '서버와의 통신에 실패하였습니다.', "E", MESSAGE_DELAY);
      }
    })
  }

  /***************************************************************************************
   * 설명 : 사이트 변경 시 검색
  ***************************************************************************************/
  useEffect(() => {
    if( formik.values.searchText !== '' && formik.values.searchText !== null )
      getModuleList();

    // eslint-disable-next-line
  }, [formik.values.searchText]);

  /***************************************************************************************
   * 설명 : 데이터 처리
  ***************************************************************************************/
  useEffect(() => {
    getSiteList();

    getModuleList();

    // eslint-disable-next-line
  }, []);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <Layout>
      <div className="admin-cms-contents">
        <form onSubmit={formik.handleSubmit}>
          <header className="admin-cms-search-header">
            <div className="left">
              <BtnRefresh click={() => {
                formik.setFieldValue('searchText', null);
                formik.handleSubmit();
              }}></BtnRefresh>
              <BtnSearch></BtnSearch>
              <BtnAgGridSave click={setGridUpdate}></BtnAgGridSave>

              <SelectEx
                name="searchText"
                formik={formik}
                fullWidth={false}
                data={[
                  {label: '공통', value: ''}
                ].concat(siteList)}
                style={{width: '200px'}}
              />
            </div>

            <div className="text-right">
              <ButtonEx
                title="순서저장"
                auth="isModify"
                color="inherit"
                variant="outlined"
                className="Btn"
                onClick={() => setSortModule()}
              />

              <ButtonEx
                title="모듈삭제"
                auth="isDelete"
                color="error"
                variant="outlined"
                className="ml5"
                onClick={() => {
                  // deleteModule();
                  setOpenModal({open: true, modal: 'dialog', data: {}});
                }}
              />

              <ButtonEx
                title="모듈등록"
                auth="isWrite"
                color="secondary"
                className="ml5"
                variant="outlined"
                onClick={() => {
                  setOpenModal({open: true, modal: 'add', data: {siteSeq: formik.values.siteSeq}})
                }}
              />
            </div>
          </header>
        </form>

        <section className="admin-cms-body">
          <section className="ag-theme-balham grid" style={{height:'calc(100% - 50px)'}}>
            <AgGridReact
              defaultColDef={{
                sortable: true,
                resizable: true,
                filter: false,
                lockVisible: true,
                tooltipComponent: customTooltipAgGrid,
              }}
              tooltipShowDelay={0}
              tooltipHideDelay={2000}
              rowSelection = {'multiple'}
              columnDefs={columnDefs}
              rowData={list}
              onGridReady={(event) => {
                gridConfig(event.columnApi);
                setGridApi(event.api);
              }}
              onRowDoubleClicked={(event) => {
                if( currentMenu?.authModify === '1' || currentMenu?.authModify === 1 )
                  setOpenModal({open: true, modal: 'add', data: event.data});
              }}
              rowDragManaged={true}
              rowDragMultiRow={true}
              animateRows={true}
              overlayNoRowsTemplate = "검색된 내용이 없습니다."
            />
          </section>
        </section>
      </div>

      { openModal.open && openModal.modal === 'add' &&
        <Modal
          open={openModal.open}
          onClose={() => setOpenModal({ open: false, modalName: 'add', data: openModal.data })}
        >
          <ModuleModal
            open={openModal.open}
            close={() => setOpenModal({ open: false, modalName: 'add', data: openModal.data })}
            data={openModal.data}
            getList={getModuleList}
            indicator={props.indicator}
            history={props.history}
          />
        </Modal>
      }

      { openModal.open && openModal.modal === 'dialog' &&
        <Modal
          open={openModal.open}
          onClose={() => setOpenModal({ open: false, modalName: 'dialog', data: openModal.data })}
        >
          <AlertDialog
            open={openModal.open}
            close={() => setOpenModal({ open: false, modalName: 'dialog', data: openModal.data })}
            title="모듈 삭제"
            message={`선택하신 ${count}개의 "모듈"를 삭제하시겠습니까?`}
            confirm="삭제"
            onClick={deleteModule}
            getList={getModuleList}
            indicator={props.indicator}
            history={props.history}
          />
        </Modal>
      }
    </Layout>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default Module;