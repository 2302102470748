/*****************************************************************************************
 * 설명 : 관리자 > 시스템설정 > 공통코드 관리
*****************************************************************************************/
import Dialog from '@mui/material/Dialog';
import { AgGridReact } from 'ag-grid-react';
import { useEffect, useState } from 'react';
// import { useSelector } from 'react-redux';
import Modal from '@mui/material/Modal';
import { useFormik } from 'formik';
import SplitPane from 'react-split-pane';
import * as Yup from "yup";

import AlertDialog from 'components/alertDiolog';
import BtnAgGridSave from 'components/btnAgGridSave';
import ChipEx from 'components/chipEx';
import { BtnExcelDown, BtnRefresh, BtnSearch } from 'components/common';
import ExcelExport from 'components/excelExport';
import { ButtonEx, InputEx } from 'components/inputEx';
import alertMsg from 'components/message';
import customTooltipAgGrid from 'components/tooltipAgGrid';

import { deleteCommonApi, deleteCommonGroupApi, getCommonGroupListApi, getCommonListApi, sortCommonApi } from 'service/common';
import Restful from 'service/restful';
import { PaperComponent, comma } from 'service/utils';

import { MESSAGE_DELAY } from 'config/config';

import useGridConfig from 'hooks/useGridConfig';

import Layout from 'pages/layout/layout';
import CommonExcelModal from './commonExcelModal';
import CommonGroupModal from './commonGroupModal';
import CommonModal from './commonModal';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const Common = ( props ) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [getApi] = Restful();

  const [gridApi, setGridApi] = useState({});
  const [gridApiCommon, setGridApiCommon] = useState({});

  // const currentMenu = useSelector(state => state?.menuList?.current);

  const [groupList, setGroupList] = useState([]);
  const [list, setList] = useState([]);

  const [selectedGroup, setSelectedGroup] = useState({});

  const [openModal, setOpenModal] = useState({open: false, modal: 'common', data: []});

  const formik = useFormik({
    initialValues: {
      searchText: '',
    },
    validationSchema: Yup.object().shape({
      searchText: Yup.string().max(30, "30자리"),
    }),
    onSubmit: (values) => {
      getGroupList();
    }
  });

  const formikSearch = useFormik({
    initialValues: {
      searchText: '',
    },
    validationSchema: Yup.object().shape({
      searchText: Yup.string().max(30, "30자리"),
    }),
    onSubmit: (values) => {
      if( selectedGroup.seq === undefined ) return;

      getList(selectedGroup.groupCode);
    }
  });

  // table column
  const [columnDefsGroup, setColumnDefsGroup] = useState([
    { headerName: '', field: 'seq', width: 40, cellClass: 'center', flex: 0,
      headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
    { headerName: '번호', field: 'rowIndex', width: 45, cellClass: 'cp text-right',
      valueGetter: (params) => {
        return comma(params.node.rowIndex + 1);
      }
    },
    { headerName:'그룹타입', field: 'groupType', width: 75, cellClass: 'cp center',
      cellRendererFramework: function(params) {
        if( parseInt(params.data.groupType) === 0 )
          return <ChipEx color="success" variant="outlined" size="small" label="일반" />;
        else
          return <ChipEx color="primary" variant="outlined" size="small" label="시스템" />;
      }
    },
    { headerName:'그룹코드', field: 'groupCode', width: 120, cellClass: 'cp' },
    { headerName:'공통코드그룹명', field: 'groupName', width: 150, cellClass: 'cp' },
  ]);

  // table column
  const [columnDefs, setColumnDefs] = useState([
    { headerName: '', field: 'seq', width: 40, cellClass: 'center', flex: 0,
      headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
    { headerName: '정렬', field: 'sortOrder', width: 52, cellClass: 'cp center', rowDrag: true, filter: false, editable: false, resize: false },
    { headerName: '번호', field: 'rowIndex', width: 55, cellClass: 'cp text-right',
      valueGetter: (params) => {
        return comma(params.node.rowIndex + 1);
      }
    },
    { headerName:'공통코드', field: 'commonCode', width: 150, cellClass: 'cp' },
    { headerName:'공통코드명', field: 'commonName', width: 250, cellClass: 'cp' },
    { headerName:'추가값2', field: 'val1', width: 100, cellClass: 'cp' },
    { headerName:'추가값1', field: 'val2', width: 100, cellClass: 'cp' },
    { headerName:'추가값3', field: 'val3', width: 100, cellClass: 'cp' },
    { headerName:'추가값4', field: 'val4', width: 100, cellClass: 'cp' },
    { headerName:'추가값5', field: 'val5', width: 100, cellClass: 'cp' },
    { headerName:'메모', field: 'memo', width: 250, cellClass: 'cp' },
    { headerName: '사용여부', field: 'isUse', width: 70, cellClass: 'cp center',
      cellRendererFramework: function(params) {
        if( parseInt(params.data.isUse) === 1 )
          return <ChipEx color="success" variant="outlined" size="small" label="사용" />;
        else
          return <ChipEx color="default" variant="outlined" size="small" label="사용안함" />;
      }
    }
  ]);

  const [gridConfigGroup, setGridUpdateGroup] = useGridConfig(1, setColumnDefsGroup);
  const [gridConfig, setGridUpdate] = useGridConfig(2, setColumnDefs);

  /***************************************************************************************
   * 설명 : 공통코드 그룹 리스트 데이터 가져오기
  ***************************************************************************************/
  const getGroupList = () => {
    getCommonGroupListApi(getApi, formik.values, (response) => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        setGroupList(response.data.data);
      } else {
        setGroupList([]);
        alertMsg(response.data.message || '서버와의 통신에 실패하였습니다.', "E", MESSAGE_DELAY);
      }
    });
  }

  /***************************************************************************************
   * 설명 : 공통코드 그룹 삭제
  ***************************************************************************************/
  const deleteCommonGroup = () => {
    let nodes = gridApi.getSelectedRows();

    if( nodes.length < 1 ) {
      alertMsg("삭제할 공통코드를 선택하시기 바랍니다.", "W", MESSAGE_DELAY);
      return;
    }

    let params = nodes.map((item) => {
      return {seq: item.seq}
    });

    deleteCommonGroupApi(getApi, params, (response) => {
      if( response !== undefined && response.data.result ) {
        alertMsg(response.data.message, "S", MESSAGE_DELAY);

        getGroupList();

      } else {
        alertMsg(response.data.message || '서버와의 통신에 실패하였습니다.', "E", MESSAGE_DELAY);
      }
    })
  }

  /***************************************************************************************
   * 설명 : 공통코드 리스트 데이터 가져오기
  ***************************************************************************************/
  const getList = (groupCode) => {
    if( groupCode === undefined && groupCode === '' ) return;

    let params = {
      ...formikSearch.values,
      groupCode: groupCode
    }

    getCommonListApi(getApi, params, (response) => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        setList(response.data.data);
      } else {
        setList([]);
      }
    });
  }

  /***************************************************************************************
   * 설명 : 공통코드 삭제
  ***************************************************************************************/
  const deleteCommon = () => {
    let nodes = gridApiCommon.getSelectedRows();

    if( nodes.length < 1 ) {
      alertMsg("삭제할 공통코드를 선택하시기 바랍니다.", "W", MESSAGE_DELAY);
      return;
    }

    let params = nodes.map((item) => {
      return {seq: item.seq}
    });

    deleteCommonApi(getApi, params, (response) => {
      if( response !== undefined && response.data.result ) {
        alertMsg(response.data.message, "S", MESSAGE_DELAY);

        getList(selectedGroup.groupCode);

      } else {
        alertMsg(response.data.message || '서버와의 통신에 실패하였습니다.', "E", MESSAGE_DELAY);
      }
    })
  }

  /***************************************************************************************
   * 설명 : 순서 저장
  ***************************************************************************************/
  const setSortCommon = () => {
    let nodes = gridApiCommon.getSelectedRows();

    if( nodes.length < 1 ) {
      if( ! window.confirm("공통코드 전체의 순서를 저장하시겠습니까?") ) return;

      nodes = [];
      gridApiCommon.forEachNode((item) => {
        nodes.push(item.data);
      });
    } else if( ! window.confirm("선택한 공통코드의 순서를 저장하시겠습니까?") ) return;

    let params = nodes.map((item) => {
      return {seq: item.seq}
    });

    sortCommonApi(getApi, params, (response) => {
      if( response !== undefined && response.data.result ) {
        alertMsg(response.data.message, "S", MESSAGE_DELAY);

      } else {
        alertMsg(response.data.message || '서버와의 통신에 실패하였습니다.', "E", MESSAGE_DELAY);
      }
    })

  }

  /***************************************************************************************
   * 설명 : 엑셀 업로드 폼 실행
  ***************************************************************************************/
  const setExcelUpload = () => {
    if( selectedGroup.seq === undefined ) {
      alertMsg("엑셀로 업로드를 할 공통코드 그룹을 선택하시기 바랍니다.", "W", MESSAGE_DELAY);
      return;
    }

    setOpenModal({open: true, modal: 'excel', data: selectedGroup});
  }

  /***************************************************************************************
   * 설명 : 그룹코드 검색 초기화
  ***************************************************************************************/
  const initSearchGroup = () => {
    formik.setValues({
      searchText: ''
    });

    formik.handleSubmit();
  }

  /***************************************************************************************
   * 설명 : 그룹코드에 포함된 공통코드 검색 초기화
  ***************************************************************************************/
  const initSearch = () => {
    formikSearch.setValues({
      searchText: ''
    });

    formikSearch.handleSubmit();
  }

  /***************************************************************************************
   * 설명 : 데이터 처리
  ***************************************************************************************/
  useEffect(() => {
    getGroupList();

    // eslint-disable-next-line
  }, []);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <Layout>
      <section className="admin-cms-contents system-page">
        <SplitPane split="vertical" minSize={400} maxSize={470} defaultSize={430}>
          <div className="mr10">
            <header className="admin-cms-search-header">
              <div className="left">
                <form onSubmit={formik.handleSubmit}>
                  <BtnRefresh click={() => initSearchGroup()}></BtnRefresh>
                  <BtnSearch></BtnSearch>
                  <BtnAgGridSave click={setGridUpdateGroup}></BtnAgGridSave>

                  <InputEx
                    name="searchText"
                    formik={formik}
                    label="그룹명,코드 검색"
                  />
                </form>
              </div>

              <div className="text-right">
                <ButtonEx
                  title="삭제"
                  auth="isDelete"
                  color="error"
                  variant="outlined"
                  className="ml5"
                  onClick={() => {
                    let nodes = gridApi.getSelectedRows();

                    if( nodes.length < 1 ) {
                      alertMsg("삭제할 공통코드를 선택하시기 바랍니다.", "W", MESSAGE_DELAY);
                    } else {
                      if( list.length > 0 ) {
                        alertMsg("공통코드가 있는 공통코드 그룹은 삭제하실 수 없습니다.", "W", MESSAGE_DELAY);
                      } else {
                        setOpenModal({open: true, modal: 'groupDialog', data: {}});
                      }
                    }
                  }}
                />

                <ButtonEx
                  title="추가"
                  auth="isWrite"
                  color="secondary"
                  className="ml5"
                  variant="outlined"
                  // disabled={selectedGroup.seq === undefined}
                  onClick={() => {
                    setOpenModal({open: true, modal: 'commonGroup', data: {seq: 0, groupCode: selectedGroup.groupCode}})
                  }}
                />
              </div>
            </header>

            <section className="admin-cms-body">
              <section className="ag-theme-balham pad-height165" style={{height:'calc(100vh - 130px)'}}>
                <div style={{ height: '100%' }}>
                  <AgGridReact
                    defaultColDef={{
                      sortable: true,
                      resizable: true,
                      filter: false,
                      lockVisible: true,
                      tooltipComponent: customTooltipAgGrid,
                    }}
                    tooltipShowDelay={0}
                    tooltipHideDelay={2000}
                    rowSelection = {'single'}
                    columnDefs={columnDefsGroup}
                    rowData={groupList}
                    onGridReady={(event) => {
                      gridConfigGroup(event.columnApi);
                      setGridApi(event.api);
                    }}
                    onRowClicked={(event) => {
                      setSelectedGroup(event.data);
                      getList(event.data.groupCode);
                    }}
                    onRowDoubleClicked={(event) => {
                      //if( parseInt(currentMenu?.authModify) === 1 )
                        setOpenModal({open: true, modal: 'commonGroup', data: event.data});
                    }}
                    rowDragManaged={true}
                    rowDragMultiRow={true}
                    animateRows={true}
                    overlayNoRowsTemplate = "검색된 내용이 없습니다."
                  />
                </div>
              </section>
            </section>
          </div>

          <div className="ml10">
            <header className="admin-cms-search-header pad-header">
              <div className="left">
                <form onSubmit={formikSearch.handleSubmit}>
                  <BtnRefresh click={() => initSearch()}></BtnRefresh>
                  <BtnSearch></BtnSearch>
                  <BtnAgGridSave click={setGridUpdate}></BtnAgGridSave>
                  <BtnExcelDown click={() => {
                    if( selectedGroup.seq === undefined )
                      alertMsg("공통코드를 엑셀로 다운로드할 공통코드 그룹을 선택하시기 바랍니다.", "W", MESSAGE_DELAY);
                    else
                      ExcelExport(gridApiCommon, "공통코드_" + selectedGroup?.groupName);
                  }}></BtnExcelDown>

                  <InputEx
                    name="searchText"
                    formik={formikSearch}
                    label="코드명, 코드 검색"
                    style={{width: '250px'}}
                  />
                </form>
              </div>

              <div className="text-right">
                <ButtonEx
                  title="엑셀업로드"
                  auth="isModify"
                  color="success"
                  variant="outlined"
                  className="Btn"
                  disabled={selectedGroup.seq === undefined}
                  onClick={() => setExcelUpload()}
                />

                <ButtonEx
                  title="순서저장"
                  auth="isModify"
                  color="inherit"
                  variant="outlined"
                  className="Btn ml5"
                  disabled={selectedGroup.seq === undefined}
                  onClick={() => setSortCommon()}
                />

                <ButtonEx
                  title="삭제"
                  auth="isDelete"
                  color="error"
                  variant="outlined"
                  className="ml5"
                  disabled={selectedGroup.seq === undefined}
                  onClick={() => {
                    let nodes = gridApi.getSelectedRows();

                    if( nodes.length < 1 ) {
                      alertMsg("삭제할 공통코드를 선택하시기 바랍니다.", "W", MESSAGE_DELAY);
                    } else {
                      setOpenModal({open: true, modal: 'commonDialog', data: {}});
                    }
                  }}
                />

                <ButtonEx
                  title="추가"
                  auth="isWrite"
                  color="secondary"
                  className="ml5"
                  variant="outlined"
                  disabled={selectedGroup.seq === undefined}
                  onClick={() => {
                    setOpenModal({
                      open: true,
                      modal: 'common',
                      data: {
                        seq: 0,
                        groupCode: selectedGroup.groupCode,
                        commonCode: '',
                        commonName: '',
                        memo: '',
                        isUse: 1,
                        val1: '',
                        val2: '',
                        val3: '',
                        val4: '',
                        val5: ''
                      }
                    })
                  }}
                />
              </div>
            </header>

            <section className="admin-cms-body">
              <section className="ag-theme-balham grid common-grid pad-height198" style={{height:'calc(100vh - 130px)'}}>
                <div style={{ height: '100%' }}>
                  <AgGridReact
                    defaultColDef={{
                      sortable: true,
                      resizable: true,
                      filter: false,
                      lockVisible: true,
                      tooltipComponent: customTooltipAgGrid,
                    }}
                    tooltipShowDelay={0}
                    tooltipHideDelay={2000}
                    rowSelection = {'multiple'}
                    columnDefs={columnDefs}
                    rowData={list}
                    onGridReady={(event) => {
                      gridConfig(event.columnApi);
                      setGridApiCommon(event.api);
                    }}
                    onRowDoubleClicked={(event) => {
                      //if( currentMenu?.authModify === '1' || currentMenu?.authModify === 1 )
                        setOpenModal({open: true, modal: 'common', data: event.data});
                    }}
                    rowDragManaged={true}
                    rowDragMultiRow={true}
                    animateRows={true}
                    overlayNoRowsTemplate = "검색된 내용이 없습니다."
                  />
                </div>
              </section>
            </section>
          </div>
        </SplitPane>
      </section>

      { openModal.open && openModal.modal === 'commonGroup' &&
        <Dialog
          open={openModal}
          onClose={() => (event, reason) => {
            if(reason && reason === "backdropClick") return;
            setOpenModal({open: false, modal: openModal.modal, data: {}});
          }}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "550px",  // Set your width here
              },
            },
          }}
        >
          <section className="modal">
            <CommonGroupModal
              open={openModal.open}
              close={() => setOpenModal({ open: false, modalName: 'commonGroup', data: openModal.data })}
              group={selectedGroup}
              data={openModal.data}
              getList={getGroupList}
              indicator={props.indicator}
              history={props.history}
            />
          </section>
        </Dialog>
      }

      { openModal.open && openModal.modal === 'common' &&
        <Dialog
          open={openModal}
          onClose={() => (event, reason) => {
            if(reason && reason === "backdropClick") return;
            setOpenModal({open: false, modal: openModal.modal, data: {}});
          }}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "550px",  // Set your width here
              },
            },
          }}
        >
          <section className="modal">
            <CommonModal
              open={openModal.open}
              close={() => setOpenModal({ open: false, modalName: 'common', data: openModal.data })}
              group={selectedGroup}
              data={openModal.data}
              groupList={groupList}
              getList={() => getList(selectedGroup.groupCode)}
              indicator={props.indicator}
              history={props.history}
            />
          </section>
        </Dialog>
      }

      { openModal.open && openModal.modal === 'excel' &&
        <Dialog
          open={openModal}
          onClose={() => (event, reason) => {
            if(reason && reason === "backdropClick") return;
            setOpenModal({open: false, modal: openModal.modal, data: {}});
          }}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "800px",  // Set your width here
              },
            },
          }}
        >
          <section className="modal">
            <CommonExcelModal
              open={openModal.open}
              close={() => setOpenModal({ open: false, modalName: 'common', data: openModal.data })}
              group={selectedGroup}
              data={openModal.data}
              groupList={groupList}
              getList={() => getList(selectedGroup.groupCode)}
              indicator={props.indicator}
              history={props.history}
            />
          </section>
        </Dialog>
      }

      { openModal.open && openModal.modal === 'groupDialog' &&
        <Modal
          open={openModal.open}
          onClose={() => setOpenModal({ open: false, modalName: 'groupDialog', data: openModal.data })}
        >
          <AlertDialog
            open={openModal.open}
            close={() => setOpenModal({ open: false, modalName: 'groupDialog', data: openModal.data })}
            title="공통코드그룹 삭제"
            message={`선택하신 "공통코드 그룹"을 삭제하시겠습니까?`}
            confirm="삭제"
            onClick={deleteCommonGroup}
            indicator={props.indicator}
            history={props.history}
          />
        </Modal>
      }

      { openModal.open && openModal.modal === 'commonDialog' &&
        <Modal
          open={openModal.open}
          onClose={() => setOpenModal({ open: false, modalName: 'commonDialog', data: openModal.data })}
        >
          <AlertDialog
            open={openModal.open}
            close={() => setOpenModal({ open: false, modalName: 'commonDialog', data: openModal.data })}
            title="공통코드 삭제"
            message={`선택하신 "공통코드"를 삭제하시겠습니까?`}
            confirm="삭제"
            onClick={deleteCommon}
            indicator={props.indicator}
            history={props.history}
          />
        </Modal>
      }
    </Layout>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default Common;