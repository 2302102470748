/*****************************************************************************************
 * 설명 : 관리자 > 로그조회 > PMS 동기화 로그
*****************************************************************************************/
import Modal from '@mui/material/Modal';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { AgGridReact } from 'ag-grid-react';
import { useFormik } from 'formik';
import moment from 'moment';
import { useEffect, useState } from 'react';
import SplitPane from "react-split-pane";
import * as Yup from "yup";

import AlertDialog from 'components/alertDiolog';
import BtnAgGridSave from 'components/btnAgGridSave';
import ChipEx from 'components/chipEx';
import { BtnRefresh, BtnSearch, CustomPagenation } from 'components/common';
import { ButtonEx, InputEx, ToggleButtonGroupEx } from 'components/inputEx';
import alertMsg from 'components/message';
import customTooltipAgGrid from 'components/tooltipAgGrid';

import { getPmsLogApi } from 'service/logs';
import { getProjectApi, setProjectViewApi } from 'service/project';
import Restful from 'service/restful';
import { getPMSSyncApi } from 'service/sync';
import { comma, onlyNumber } from 'service/utils';

import { MESSAGE_DELAY } from 'config/config';
import useGridConfig from 'hooks/useGridConfig';

import Layout from 'pages/layout/layout';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const PmsSync = ( props ) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [getApi] = Restful();

  const [openModal, setOpenModal] = useState({open: false, modal: 'sync', data: []});

  const [list, setList] = useState([]);
  const [projectList, setProjectList] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [gridApiProject, setGridApiProject] = useState({});

  const initFormik = {
    startDate: moment().add(-1, 'year').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
    isSuccess: 0,
    pageNo: 1,
    pageRow: 100
  }

  const formik = useFormik({
    initialValues: initFormik,
    validationSchema: Yup.object().shape({
    }),
    onSubmit: (values) => {
      getList();
    }
  });

  const initFormikProject = {
    startProjectYear: moment().add(-1, 'year').format('YYYY'),
    endProjectYear: moment().format('YYYY'),
    projectDivision: '',
    projectStatus: '',
    purpose: '',
    searchText: '',
    isConfirm: '',
    isFile: '',
    isView: 1,
    order: 0
  }

  const formikProject = useFormik({
    initialValues: initFormikProject,
    validationSchema: Yup.object().shape({
    }),
    onSubmit: (values) => {
      getProjectList();
    }
  });

  // table column
  const [columnDefs, setColumnDefs] = useState([
    { headerName: '', field: 'seq', width: 40, cellClass: 'cp center', flex: 0,
      headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
    { headerName: '번호', field: 'rowIndex', width: 40, cellClass: 'cp text-right',
      valueGetter: (params) => {
        return comma(params.node.rowIndex + 1);
      }
    },
    { headerName:'상태', field: 'isSuccess', width: 60, cellClass: 'cp center',
      cellRendererFramework: function(params) {
        if( parseInt(params.data.isSuccess) === 1 )
          return <ChipEx color="success" variant="outlined" size="small" label="성공" />;
        else
          return <ChipEx color="error" variant="outlined" size="small" label="실패" />;
      }
    },
    { headerName:'일시', field: 'syncDate', width: 160, cellClass: 'cp center' },
    { headerName:'작업자', field: 'creatorName', width: 100, cellClass: 'cp center' },
    { headerName:'실패 내역', field: 'error', width: 130, cellClass: 'cp', flex: 1, tooltipField: 'error' },
  ]);

  const [columnDefsProject, setColumnDefsProject] = useState([
    { headerName: '', field: 'seq', width: 40, cellClass: 'center', flex: 0,
      headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
    { headerName: '번호', field: 'rowIndex', width: 55, cellClass: 'cp text-right',
      valueGetter: (params) => {
        return comma(params.node.rowIndex + 1);
      }
    },
    { headerName:'동기화', field: 'isView', width: 150, cellClass: 'cp center',
      cellRendererFramework: (params) => {
        return (
          <ToggleButtonGroup
            id={'isView' + params.data.seq}
            value={String(params.data.isView)}
            onChange={(event) => {
              params.node.setDataValue('isView', event.target.value);
            }}
          >
            <ToggleButton type="button" value={'0'} size="small" className="f12">미사용</ToggleButton>
            <ToggleButton type="button" value={'1'} size="small" className="f12">사용</ToggleButton>
          </ToggleButtonGroup>
        )
      }
    },
    { headerName:'상태', field: 'projectStatusName', width: 100, cellClass: 'cp center' },
    { headerName:'구분', field: 'projectDivisionName', width: 150, cellClass: 'cp' },
    { headerName:'년도', field: 'projectYear', width: 50, cellClass: 'cp center' },
    { headerName:'PJ NO', field: 'projectNumber', width: 120, cellClass: 'cp' },
    { headerName:'PJ 명', field: 'projectName', width: 300, cellClass: 'cp' },
    { headerName:'유형', field: 'projectTypeName', width: 100, cellClass: 'cp' },
    { headerName:'용도', field: 'purpose', width: 300, cellClass: 'cp' },
    { headerName:'규모', field: 'scale', width: 200, cellClass: 'cp' },
    { headerName:'주관부서', field: 'departmentName', width: 100, cellClass: 'cp' },
    { headerName:'담당PM', field: 'projectPmName', width: 100, cellClass: 'cp' },
    { headerName:'건축주', field: 'builderName', width: 100, cellClass: 'cp' },
    { headerName:'발주처', field: 'orderCompanyId', width: 100, cellClass: 'cp' },
    { headerName:'시공사', field: 'builderCompanyId', width: 100, cellClass: 'cp' }
  ]);

  const [gridConfig, setGridUpdate] = useGridConfig(1, setColumnDefs);
  const [gridConfigProject, setGridUpdateProject] = useGridConfig(2, setColumnDefsProject);

  /***************************************************************************************
   * 설명 : PMS 동기화 로그 가져오기
  ***************************************************************************************/
  const getList = () => {
    let params = {...formik.values}

    getPmsLogApi(getApi, params, (response) => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        setList(response.data.data);

        let totalCount = (response.data.data1 || 0);
        let totalPage = totalCount / formik.values.pageRow;
        if(totalCount % formik.values.pageRow > 0 ) totalPage = totalPage + 1;
        formik.setFieldValue('totalPage', totalPage);
        formik.setFieldValue('totalCount', totalCount);

      } else {
        setList([]);
      }
    });
  }

  /***************************************************************************************
   * 설명 : 프로젝트 리스트 가져오기
  ***************************************************************************************/
  const getProjectList = () => {
    let params = {...formikProject.values};

    params.purpose = params.purpose?.value || '';

    getProjectApi(getApi, params, (response) => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        setProjectList(response.data.data);

      } else {
        setProjectList([]);
      }
    })
  }

  /***************************************************************************************
   * 설명 : PMS 수동 동기화
  ***************************************************************************************/
  const getPMSSyncProcess = () => {
    getPMSSyncApi(getApi, (response) => {
      if( response !== undefined && response.data.result ) {
        alertMsg(response.data.message, "S", MESSAGE_DELAY);

        getList();

      } else {
        alertMsg(response.data.message || '서버와의 통신에 실패하였습니다.', "E", MESSAGE_DELAY);
      }
    })
  }

  /***************************************************************************************
   * 설명 : 기본열람권한 상태 일괄 변경
  ***************************************************************************************/
  const setAllAuth = () => {
    let tmp = projectList.map((item) => {
      item.isView = parseInt(formik.values.isView);
      return {
        ...item
      }
    });

    setProjectList(tmp);
  }

  /***************************************************************************************
   * 설명 : 프로젝트 조회 허용여부 적용
  ***************************************************************************************/
  const saveProject = () => {
    let nodes = gridApiProject.getSelectedRows();

    let params = {
      data: ( nodes.length > 0 ) ? nodes : projectList
    };

    setProjectViewApi(getApi, params, (response => {
      if( response !== undefined && response.data.result ) {
        alertMsg(response.data.message, "S", MESSAGE_DELAY);
      } else {
        alertMsg(response.data.message || '서버와의 통신에 실패하였습니다.', "E", MESSAGE_DELAY);
      }
    }));
  }

  /***************************************************************************************
   * 설명 : 전체, 성공, 실패 변경시 검색 처리
  ***************************************************************************************/
  useEffect(() => {
    if( isLoading )
      getList();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.isSuccess, formik.values.pageNo]);

  /***************************************************************************************
   * 설명 : 프로젝트 조회
  ***************************************************************************************/
  useEffect(() => {
    if( isLoading )
      getProjectList();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formikProject.values.projectStatus, formikProject.values.projectYear]);

  /***************************************************************************************
   * 설명 : 데이터 처리
  ***************************************************************************************/
  useEffect(() => {
    // getProjectYear();

    getList();

    getProjectList();

    setIsLoading(true);

    // eslint-disable-next-line
  }, []);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <Layout>
      <section className="admin-cms-contents log-page pad-1920">
        <SplitPane split="vertical" minSize={550} defaultSize={550} style={{height: 'calc(100vh - 70px)'}}>
          <section className="h100">
            <form onSubmit={formik.handleSubmit}>
              <header className="admin-cms-search-header mb10">
                <div className="left">
                  <div className='fl'>
                    <BtnRefresh click={() => {
                      formik.setValues(initFormik);
                      formik.handleSubmit();
                    }}></BtnRefresh>
                    <BtnSearch></BtnSearch>
                    <BtnAgGridSave click={setGridUpdate}></BtnAgGridSave>
                  </div>
                  <div className='fl'>
                    <InputEx
                      type="date"
                      name="startDate"
                      formik={formik}
                      fullWidth={false}
                      label="검색시작일"
                      style={{width: '125px'}}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />

                    <span className="ml5 mr5 lh28px">~</span>

                    <InputEx
                      type="date"
                      name="endDate"
                      formik={formik}
                      fullWidth={false}
                      label="검색종료일"
                      style={{width: '125px'}}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                  {/*
                  <div className='fl ml5'>
                    <ToggleButtonGroupEx
                      name="isSuccess"
                      exclusive={true}
                      formik={formik}
                      className="search-toggle-btn"
                      data={[
                        {label: '전체', value: 0},
                        {label: '성공', value: 1},
                        {label: '실패', value: 2},
                      ]}
                    />
                  </div>
                  */}
                </div>
                <div className="text-right">
                  <div className="fr">
                    <ButtonEx
                      title="PMS 수동동기화"
                      auth="isWrite"
                      color="secondary"
                      className="ml5"
                      variant="outlined"
                      onClick={() => setOpenModal({open: true, modal: 'dialog', data: {}})}
                    />
                  </div>
                </div>
              </header>
            </form>

            <section className="ag-theme-balham grid pad-height140-percent" style={{height:'calc(100% - 100px)'}}>
              <AgGridReact
                defaultColDef={{
                  sortable: true,
                  resizable: true,
                  filter: false,
                  lockVisible: true,
                  tooltipComponent: customTooltipAgGrid,
                }}
                tooltipShowDelay={0}
                tooltipHideDelay={2000}
                rowSelection = {'multiple'}
                columnDefs={columnDefs}
                rowData={list}
                onGridReady={(event) => {
                  gridConfig(event.columnApi);
                }}
                rowDragManaged={true}
                rowDragMultiRow={true}
                animateRows={true}
                overlayNoRowsTemplate = "검색된 내용이 없습니다."
              />
            </section>

            <section className="pagination mt10">
              <div className="fl">
                <CustomPagenation
                  currPage={{pageNo: formik.values.pageNo, pageRow: formik.values.pageRow}}
                  totalPage={formik.values.totalPage}
                  change={(event, pageNo) => {
                    formik.setFieldValue("pageNo", pageNo);
                  }}
                ></CustomPagenation>
              </div>
              <div className="fr f12">
                검색 :<span className="ml10">{comma(formik.values.totalCount)}</span>
              </div>
            </section>
          </section>

          <section className="h100">
            <form onSubmit={formikProject.handleSubmit}>
              <header className="admin-cms-search-header mb10">
                <div className="left">
                  <div className='fl'>
                    <BtnRefresh click={() => {
                      formikProject.setValues(initFormikProject);

                      formikProject.handleSubmit();
                    }}></BtnRefresh>
                    <BtnSearch></BtnSearch>
                    <BtnAgGridSave click={setGridUpdateProject}></BtnAgGridSave>
                  </div>
                  <div className='fl'>
                    <InputEx
                      name="startProjectYear"
                      formik={formikProject}
                      fullWidth={false}
                      label="검색시작년도"
                      style={{width: '100px'}}
                      inputProps={{maxLength: 4}}
                      onChange={(event) => {
                        if (event.nativeEvent.data ){
                          event.target.value = onlyNumber(event.target.value);
                        }
                      }}
                      onBlur={(event) => {
                        event.target.value = onlyNumber(event.target.value);
                      }}
                    />

                    <span className="ml5">~</span>

                    <InputEx
                      name="endProjectYear"
                      formik={formikProject}
                      fullWidth={false}
                      label="검색종료년도"
                      className="ml5"
                      style={{width: '100px'}}
                      inputProps={{maxLength: 4}}
                      onChange={(event) => {
                        if (event.nativeEvent.data ){
                          event.target.value = onlyNumber(event.target.value);
                        }
                      }}
                      onBlur={(event) => {
                        event.target.value = onlyNumber(event.target.value);
                      }}
                    />

                    <span className="ml5"></span>

                    <ToggleButtonGroupEx
                      name="projectStatus"
                      exclusive={true}
                      formik={formikProject}
                      className="search-toggle-btn"
                      data={[
                        {label: '전체', value: ''},
                        {label: '완료만조회', value: 'f'},
                      ]}
                    />

                    <InputEx
                      name="searchText"
                      formik={formikProject}
                      fullWidth={false}
                      className="ml5"
                      label="프로젝트명, 프로젝트번호"
                    />

                    <span className="ml10"></span>

                    <ToggleButtonGroupEx
                      formik={formik}
                      name="isView"
                      exclusive={true}
                      data={[
                        {label: '미사용', value: 0},
                        {label: '사용', value: 1},
                      ]}
                    />

                    <ButtonEx
                      auth="isWrite"
                      title="일괄적용"
                      variant="outlined"
                      color="primary"
                      className="ml5"
                      onClick={() => setAllAuth()}
                    />
                  </div>
                </div>
                <div className="text-right">
                  <div className="fr">
                    <ButtonEx
                      title="저장하기"
                      auth="isWrite"
                      color="secondary"
                      className="ml5"
                      variant="outlined"
                      onClick={saveProject}
                    />
                  </div>
                </div>
              </header>
            </form>

            <section className="ag-theme-balham grid pad-height140-percent" style={{height:'calc(100% - 100px)'}}>
              <AgGridReact
                defaultColDef={{
                  sortable: true,
                  resizable: true,
                  filter: false,
                  lockVisible: true,
                  tooltipComponent: customTooltipAgGrid,
                }}
                tooltipShowDelay={0}
                tooltipHideDelay={2000}
                rowSelection = {'multiple'}
                columnDefs={columnDefsProject}
                rowData={projectList}
                onGridReady={(event) => {
                  gridConfigProject(event.columnApi);
                  setGridApiProject(event.api);
                }}
                rowDragManaged={true}
                rowDragMultiRow={true}
                animateRows={true}
                overlayNoRowsTemplate = "검색된 내용이 없습니다."
              />
            </section>

            <footer className="mt10">
              <div className="fr f12">검색 : {comma(projectList.length || 0)}</div>
            </footer>
          </section>
        </SplitPane>
      </section>

      { openModal.open && openModal.modal === 'dialog' &&
        <Modal
          open={openModal.open}
          onClose={() => setOpenModal({ open: false, modalName: 'dialog', data: openModal.data })}
        >
          <AlertDialog
            open={openModal.open}
            close={() => setOpenModal({ open: false, modalName: 'dialog', data: openModal.data })}
            title="PMS 수동 동기화"
            message={`PMS의 프로젝트 정보를 동기화 하시겠습니까?`}
            color="primary"
            confirm="동기화"
            onClick={getPMSSyncProcess}
            indicator={props.indicator}
            history={props.history}
          />
        </Modal>
      }
    </Layout>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default PmsSync;