/*****************************************************************************************
 * 설명 : UserHeader
*****************************************************************************************/
import { Button, IconButton } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { setUserInfo } from 'reducers/userReducer';

import logo from 'assets/images/logo.png';
import noImg from 'assets/images/no_image.png';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const UserHeader = (props) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const dispatch = useDispatch();
  const history = useNavigate();
  const user = useSelector(state => state?.userInfo?.user);
  const menuList = useSelector(state => state?.menuList?.list);
  const currentMenu = useSelector(state => state?.menuList?.current);

  const [viewBtn, setViewBtn] = useState(false);
  const [isCost, setIsCost] = useState(false);

  /***************************************************************************************
   * 설명 : 설계비가산정 권한 확인
  ***************************************************************************************/
  useEffect(() => {
    if( menuList?.length > 0 ) {
      let tmp = menuList.filter((item) => item.seq === 42 && parseInt(item.isView) === 1);
      if( tmp.length > 0 ) {
        setIsCost(true);
      } else {
        setIsCost(false);
      }
    }
  }, [menuList]);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <header className="userHeader">
      <div className="navbar-header">
        <div className="logo">
          <img src={logo} alt="DA그룹" onClick={() => history('/search')} className="cp" />

          <div className="header-path">
            <ul className="page-title">
              <li onClick={() => history('/search')}>{currentMenu?.parent?.menuName}</li>
              <li><i className="icon bx bx-chevron-right"></i>{currentMenu?.menuName}</li>
            </ul>
          </div>
        </div>


        {/*
          window.location.pathname !== '/search' &&
          <SearchBox
            formik={formik}
          ></SearchBox>
          */}

        <div className="right-area">
          <div className={viewBtn? "util-btn-wrap view":"util-btn-wrap"}>
            <div className="pad-only view-title">바로가기</div>
            <ul>
              {
                user.isAdmin &&
                <li className="mr10">
                  <Tooltip title="관리자">
                    <IconButton className="util-btn" aria-label="관리자" onClick={() => history("/admin/dashboard")}>
                      <i className="bx bx-math f18"></i>
                      <div className="btn-title">관리자</div>
                    </IconButton>
                  </Tooltip>
                </li>
              }
              {
                isCost &&
                <li className="mr10">
                  <Tooltip title="설계비가산정">
                    <IconButton className="util-btn" aria-label="설계비가산정" onClick={() => history("/cost")}>
                      <i className="bx bx-math f18"></i>
                      <div className="btn-title">설계비가산정</div>
                    </IconButton>
                  </Tooltip>
                </li>
              }
              <li>
                <Tooltip title="프로젝트조회">
                  <IconButton className="util-btn" aria-label="프로젝트" onClick={() => history("/project")}>
                    <i className="ri-folder-2-line f18"></i>
                    <div className="btn-title">프로젝트</div>
                  </IconButton>
                </Tooltip>
              </li>
              <li>
                <Tooltip title="열람신청내역">
                  <IconButton className="util-btn" onClick={() => history("/user/request")}>
                    <i className="ri-pages-line f18"></i>
                    <div className="btn-title">열람신청</div>
                  </IconButton>
                </Tooltip>
              </li>
              {/*
              <li>
                <Tooltip title="공지사항">
                  <Button className="util-btn" onClick={() => history("/board")}><i className="ri-volume-up-line"></i></Button><div className="pad-only btn-title">공지사항</div>
                </Tooltip>
              </li>
              <li><Button className="util-btn"><i className="bx bx-category-alt"></i></Button><div className="pad-only btn-title">그룹웨어</div></li>
              <li><Button className="util-btn"><i className="ri-pages-line"></i></Button><div className="pad-only btn-title">PMS</div></li>
              <li><Button className="util-btn"><i className="ri-computer-line"></i></Button><div className="pad-only btn-title">홈페이지</div></li>
              <li><Button className="util-btn"><i className="ri-team-line"></i></Button><div className="pad-only btn-title">조직도</div></li>
              */}
            </ul>
          </div>
          <Button className="util-btn pad-only pad-btn" onClick={() => setViewBtn(!viewBtn)}>
            <i className="ri-menu-add-line"></i>
          </Button>
          <Button className="user-btn" onClick={() => {
            if( ! window.confirm("사용자 로그아웃 하시겠습니까?") ) return;

            window.localStorage.removeItem('token');
            dispatch(setUserInfo({}));
            history('/');
          }}>
            <span>
              <img
                src={user?.picture || ''}
                alt="user"
                className="img"
                onError={(event) => {
                  event.target.src = noImg;
                }}
              />

              <span className="txt">
                <span className="position">{user?.positionName}</span>
                <span className="name">{user?.userName}</span>
              </span>
            </span>
          </Button>
        </div>
      </div>

    </header>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default UserHeader;