/**
 * * 프로젝트 리스트 가져오기
 * @param {*} getApi
 * @param {*} params
 * @param {*} func
 * @returns
 */
export function ssoLogin(getApi, params, func) {
  if( ! getApi ) return;

  getApi("get", '/api/v1/pms/sso', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  });
}