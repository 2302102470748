/**
 * * 프로젝트 리스트 가져오기
 * @param {*} getApi
 * @param {*} params
 * @param {*} func
 * @returns
 */
export function getProjectApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("get", '/api/v1/project', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  });
}

/**
 * * 프로젝트 조회에 필요한 년도 정보 리스트 가져오기
 * @param {*} getApi
 * @param {*} params
 * @param {*} func
 * @returns
 */
export function getProjectInfoYearApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("get", '/api/v1/project/info/year', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  });
}

/**
 * * 프로젝트 조회에 필요한 정보 리스트 가져오기
 * @param {*} getApi
 * @param {*} params
 * @param {*} func
 * @returns
 */
export function getProjectInfoApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("get", '/api/v1/project/info', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  });
}

/**
 * * 프로젝트 건축구분 가져오기
 * @param {*} getApi
 * @param {*} func
 * @returns
 */
export function getProjectDivisionApi(getApi, func) {
  if( ! getApi ) return;

  getApi("get", '/api/v1/project/division').then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  });
}

/**
 * * 프로젝트 진행상태 가져오기
 * @param {*} getApi
 * @param {*} func
 * @returns
 */
export function getProjectStatusApi(getApi, func) {
  if( ! getApi ) return;

  getApi("get", '/api/v1/project/status').then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  });
}


/**
 * * 프로젝트 추가정보 추가/수정
 * @param {*} getApi
 * @param {*} func
 * @returns
 */
export function setProjectApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("post", '/api/v1/project', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  })
}

/**
 * * 프로젝트 경로 추가/수정
 * @param {*} getApi
 * @param {*} func
 * @returns
 */
export function setProjectPathApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("post", '/api/v1/project/path', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  })
}

/**
 * * 프로젝트 일괄등록 리스트 가져오기
 * @param {*} getApi
 * @param {*} params
 * @param {*} func
 * @returns
 */
export function getProjectBatchApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("get", '/api/v1/project/batch', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  });
}

/**
 * * 프로젝트 일괄등록 에러 가져오기
 * @param {*} getApi
 * @param {*} params
 * @param {*} func
 * @returns
 */
export function getProjectBatchErrorApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("get", '/api/v1/project/batch/error', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  });
}

/**
 * * 프로젝트 경로 승인
 * @param {*} getApi
 * @param {*} params
 * @param {*} func
 * @returns
 */
export function setProjectPathConfirmApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("post", '/api/v1/project/path/confirm', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  });
}

/**
 * * 프로젝트 경로 승인 해제
 * @param {*} getApi
 * @param {*} params
 * @param {*} func
 * @returns
 */
export function setProjectPathConfirmCancelApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("post", '/api/v1/project/path/confirm/cancel', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  });
}

/**
 * * 프로젝트 경로 정보 삭제
 * @param {*} getApi
 * @param {*} params
 * @param {*} func
 * @returns
 */
export function deleteProjectPathApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("post", '/api/v1/project/path/delete', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  });
}

/**
 * * 프로젝트 경로 분석 진행
 * @param {*} getApi
 * @param {*} params
 * @param {*} func
 * @returns
 */
export function setProjectPathScan(getApi, params, func) {
  if( ! getApi ) return;

  getApi("post", '/api/v1/project/path/scan', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  });
}

/**
 * * 프로젝트 경로 분석 진행(백그라운드)
 * @param {*} getApi
 * @param {*} params
 * @param {*} func
 * @returns
 */
export function setProjectPathScanBackground(getApi, params, func) {
  if( ! getApi ) return;

  let url = '/scan/v1/project/path/scan/background';
  if( window.location.hostname === 'localhost' ) {
    url = '/api/v1/project/path/scan/background';
  }

  getApi("post", url, params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  });
}

/**
 * * 프로젝트 디렉토리/파일 분석 진행
 * @param {*} getApi
 * @param {*} params
 * @param {*} func
 * @returns
 */
export function getProjectDirectoryScanApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("post", '/api/v1/project/directory/scan', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  });
}

/**
 * * 프로젝트 디렉토리/파일 분석 진행
 * @param {*} getApi
 * @param {*} params
 * @param {*} func
 * @returns
 */
export function setProjectDirectoryScanApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("post", '/api/v1/project/directory/scan/save', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  });
}

/**
 * * 프로젝트 디렉토리/파일 분석 진행 완료 처리
 * @param {*} getApi
 * @param {*} params
 * @param {*} func
 * @returns
 */
export function setProjectDirectoryScanCompleteApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("post", '/api/v1/project/directory/scan/complete', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  });
}

/**
 * * 프로젝트 주관부서 변경이력 가져오기
 * @param {*} getApi
 * @param {*} params
 * @param {*} func
 * @returns
 */
export function getDepartmentHistoryListApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("get", '/api/v1/project/department/history', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  });
}

/**
 * * 프로젝트 담당PM 변경이력 가져오기
 * @param {*} getApi
 * @param {*} params
 * @param {*} func
 * @returns
 */
export function getProjectPmNameHistoryListApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("get", '/api/v1/project/pm/history', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  });
}

/**
 * * 논리 드라이브 디렉토리 리스트 가져오기
 * @param {*} getApi
 * @param {*} func
 * @returns
 */
export function getLogicalDirectoryListApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("get", '/api/v1/project/logical/drive/directory', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  })
}

/**
 * * 프로젝트 경로 선택 시 포함된 디렉토리 리스트 가져오기
 * @param {*} getApi
 * @param {*} func
 * @returns
 */
export function getProjectPathDirectoryApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("get", '/api/v1/project/path/directory', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  })
}

/**
 * * 프로젝트 경로의 디렉토리 선택 시 포함된 파일 리스트 가져오기
 * @param {*} getApi
 * @param {*} func
 * @returns
 */
export function getProjectPathDirectoryFileApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("get", '/api/v1/project/path/directory/file', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  })
}

/**
 * * 프로젝트 경로의 파일 리스트 가져오기
 * @param {*} getApi
 * @param {*} func
 * @returns
 */
export function getProjectPathFileApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("get", '/api/v1/project/path/file', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  })
}

/**
 * * 프로젝트 엑셀 업로드 처리
 * @param {*} getApi
 * @param {*} func
 * @returns
 */
export function setProjectExcelUploadApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("post", '/api/v1/project/excel/upload', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  })
}

/**
 * * 프로젝트 파일 저장 업로드 처리
 * @param {*} getApi
 * @param {*} func
 * @returns
 */
export function setProjectFileSaveApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("post", '/api/v1/project/file/save', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  })
}


/**
 * * 프로젝트 파일정보 관리 태그 직접 수정
 * @param {*} getApi
 * @param {*} func
 * @returns
 */
export function setProjectFileTagApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("post", '/api/v1/project/file/tag', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  })
}

/**
 * * 프로젝트 참여업체 정보 리스트 가져오기
 * @param {*} getApi
 * @param {*} func
 * @returns
 */
export function getProjectOwnershipListApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("get", '/api/v1/project/ownership', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  })
}

/**
 * * 프로젝트 구조 정보 리스트 가져오기
 * @param {*} getApi
 * @param {*} func
 * @returns
 */
export function getProjectScaleListApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("get", '/api/v1/project/scale', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  })
}



/**
 * * 프로젝트 협력업체 정보 리스트 가져오기
 * @param {*} getApi
 * @param {*} func
 * @returns
 */
export function getProjectPartnerListApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("get", '/api/v1/project/partner', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  })
}

/**
 * * 프로젝트 협업해외사 정보 리스트 가져오기
 * @param {*} getApi
 * @param {*} func
 * @returns
 */
export function getProjectForeignListApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("get", '/api/v1/project/foreign', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  })
}


/**
 * * 프로젝트 수상 정보 리스트 가져오기
 * @param {*} getApi
 * @param {*} func
 * @returns
 */
export function getProjectAwardListApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("get", '/api/v1/project/award', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  })
}

/**
 * * 프로젝트의 파일을 파일없음 처리
 * @param {*} getApi
 * @param {*} params
 * @param {*} func
 * @returns
 */
export function setProjectFileNotUpload(getApi, params, func) {
  if( ! getApi ) return;

  getApi("post", '/api/v1/project/file/not', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  })
}

/**
 * * 프로젝트 파일 상태값 처리완료로 변경
 * @param {*} getApi
 * @param {*} params
 * @param {*} func
 * @returns
 */
export function setProjectFileComplete(getApi, params, func) {
  if( ! getApi ) return;

  getApi("post", '/api/v1/project/file/complete', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  })
}

/**
 * * 프로젝트 대표 이미지 가져오기
 * @param {*} getApi
 * @param {*} params
 * @param {*} func
 * @returns
 */
export function getProjectImageListApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("get", '/api/v1/project/image', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  })
}

/**
 * * 선택한 파일 삭제 처리
 * @param {*} getApi
 * @param {*} params
 * @param {*} func
 * @returns
 */
export function deleteFileListApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("post", '/api/v1/project/file/delete', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  });
}

/**
 * * 프로젝트 조회허용 저장
 * @param {*} getApi
 * @param {*} params
 * @param {*} func
 * @returns
 */
export function setProjectViewApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("post", '/api/v1/project/view', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  });
}

/**
 * * 프로젝트 전체 용량, 디렉토리 파일 정보 가져오기
 * @param {*} getApi
 * @param {*} params
 * @param {*} func
 * @returns
 */
export function getPathInfoApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("get", '/api/v1/project/path/info', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  });
}


/**
 * * 디자인 프로젝트 추가
 * @param {*} getApi
 * @param {*} params
 * @param {*} func
 * @returns
 */
export function setAddDesignProjectApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("post", '/api/v1/project/design/add', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  });
}

/**
 * * 디자인 프로젝트 삭제
 * @param {*} getApi
 * @param {*} params
 * @param {*} func
 * @returns
 */
export function delDesignProjectApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("post", '/api/v1/project/design/del', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  });
}

/**
 * * 연관 프로젝트 리스트 가져오기
 * @param {*} getApi
 * @param {*} params
 * @param {*} func
 * @returns
 */
export function getRelationProjectApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("post", '/api/v1/project/relation', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  });
}

/**
 * * 일괄등록 결과 가져오기
 * @param {*} getApi
 * @param {*} params
 * @param {*} func
 * @returns
 */
export function getScanResultApi(getApi, params, func) {
  if( ! getApi ) return;

  getApi("get", '/api/v1/project/scan/result', params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  });
}

/**
 * * 프로젝트 섬네일 진행(백그라운드)
 * @param {*} getApi
 * @param {*} params
 * @param {*} func
 * @returns
 */
export function setProjectThumbBackground(getApi, params, func) {
  if( ! getApi ) return;

  let url = '/scan/v1/project/thumbnail/background';
  if( window.location.hostname === 'localhost' ) {
    url = '/api/v1/project/thumbnail/background';
  }

  getApi("post", url, params).then( response => {
    if( func ) func(response);

  }).catch(error => {
    console.error(error);
  });
}