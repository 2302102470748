/*****************************************************************************************
 * 설명 : 통계관리 > 접속 디바이스, 브라우저 정보
 * URI : /statistic/device
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import { AgGridReact } from 'ag-grid-react';
import {
    ArcElement,
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    Title,
    Tooltip
} from 'chart.js';
import { useFormik } from 'formik';
import * as moment from 'moment';
import { useEffect, useState } from 'react';
import { Bar, Pie } from 'react-chartjs-2';
import * as Yup from "yup";


import Restful from 'service/restful';
import { getStatisticDeviceApi } from 'service/statistic';

import { BtnRefresh, BtnSearch } from 'components/common';
import { InputEx } from 'components/inputEx';
import customTooltipAgGrid from 'components/tooltipAgGrid';

import Layout from 'pages/layout/layout';

import 'assets/css/pages/statistic.scss';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const StatisticDeviceInfo = (props) => {

  ChartJS.register(ArcElement, Tooltip, Legend);
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [getApi] = Restful();

  const initialValues = {
    startDate: moment().format('YYYY-01-01'),
    endDate: moment().format('YYYY-MM-DD'),
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object().shape({
    }),
    onSubmit: (values) => {
      getList();
    }
  });

  // table column
  const [columnDefs, setColumnDefs] = useState([
    { headerName: '', field: 'seq', width: 35, cellClass: 'center', flex: 0,
      headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
    { headerName:'구분', field: 'title', width: 45, cellClass: 'cp center' },
    { headerName:'', field: 'yearMonth01', width: 100, cellClass: 'cp text-right' },
  ]);

  const [deviceData, setDeviceData] = useState({});
  const [tabletData, setTabletData] = useState({});
  const [mobileData, setMobileData] = useState({});
  const [browserData, setBrowserData] = useState({});
  const [connectData, setConnectData] = useState({});

  const [data, setData] = useState([]);

  /***************************************************************************************
   * 설명 : 차트 설정
  ***************************************************************************************/
  const setChart = (labels, data, background = null, border = null) => {
    const backgroundColor = [
      'rgba(255, 99, 132, 0.2)',
      'rgba(54, 162, 235, 0.2)',
      'rgba(255, 206, 86, 0.2)',
      'rgba(75, 192, 192, 0.2)',
      'rgba(153, 102, 255, 0.2)',
      'rgba(255, 159, 64, 0.2)',
    ];

    const borderColor = [
      'rgba(255, 99, 132, 1)',
      'rgba(54, 162, 235, 1)',
      'rgba(255, 206, 86, 1)',
      'rgba(75, 192, 192, 1)',
      'rgba(153, 102, 255, 1)',
      'rgba(255, 159, 64, 1)',
    ]

    return {
      labels: labels,
      datasets: [
        {
          label: '# 접속수',
          data: data,
          backgroundColor: background === null ? backgroundColor : background,
          borderColor: border === null ? borderColor : border,
          borderWidth: 1,
          legend: {
            display: true,
            labels: {
              color: 'rgb(255, 99, 132)'
            }
          }
        },
      ],
      options: {
        maintainAspectRatio: false,
        responsive: true
      }
    }
  }

  /***************************************************************************************
   * 설명 : 접속 정보 리스트 가져오기 - 접속기기, 테블릿기기별, 모바일기기별, 기간 접속내역
  ***************************************************************************************/
  const getList = () => {
    let params = {...formik.values};

    getStatisticDeviceApi(getApi, params, (response) => {
      if( response !== undefined && response.data.result ) {
        // 기기별 접속 구분
        if( response.data.data ) {
          setDeviceData(
            setChart(
              ['PC', '테블릿', '모바일'],
              [
                (response.data.data?.pcCount ?? 0),
                (response.data.data?.tabletCount ?? 0),
                (response.data.data?.mobileCount ?? 0),
              ]
            )
          );

        } else {
          setDeviceData(setChart(['PC', '테블릿', '모바일'], []));
        }

        // 테블릿 기기
        if( response.data.data1 && response.data.data1.length > 0 ) {
          let label = response.data.data1.map((item) => item.deviceName);

          setTabletData(setChart(label, response.data.data1.map((item) => item.deviceCount) ));

        } else {
          setTabletData(setChart(['테블릿'], []));
        }

        // 모바일 기기
        if( response.data.data2 && response.data.data2.length > 0 ) {
          let label = response.data.data2.map((item) => item.deviceName);

          setMobileData(setChart(label, response.data.data2.map((item) => item.deviceCount)));

        } else {
          setTabletData(setChart(['모바일'], []));
        }

        // 브라우저 기기
        if( response.data.data3 && response.data.data3.length > 0 ) {
          let label = response.data.data3.map((item) => item.deviceName);

          setBrowserData(setChart(label, response.data.data3.map((item) => item.deviceCount)));

        } else {
          setBrowserData(setChart(['브라우저'], []));
        }

        if( response.data.data4 && response.data.data4.length > 0 ) {
          // grid setting
          let tmp1 = response.data.data4.map((item) => {
            return { headerName: item.deviceName, field: item.deviceName?.replace(/-/gi, ''), width: 100, cellClass: 'cp text-right' };
          });

          setColumnDefs(tmp1);

          let tmp = {};
          let labels = [];
          tmp1 = [];
          let backgroundColor = [];
          let borderColor = [];
          const blue = 'rgba(54, 162, 235, 0.2)';
          const blueBorder = 'rgba(54, 162, 235, 1)';
          const red = 'rgba(255, 99, 132, 0.2)';
          const redBorder = 'rgba(255, 99, 132, 1)';

          response.data.data4.forEach((item, index) => {
            tmp[item.deviceName?.replace(/-/gi, '')] = item.deviceCount;
            tmp1.push(item.deviceCount);
            labels.push(item.deviceName);

            if( index === 0 ) {
              backgroundColor.push(blue);
              borderColor.push(blueBorder)

            } else {
              if( parseInt(response.data.data4[index-1].deviceCount) >= parseInt(item.deviceCount) ) {
                backgroundColor.push(blue);
                borderColor.push(blueBorder);

              } else {
                backgroundColor.push(red);
                borderColor.push(redBorder);
              }
            }
          });
          setData([tmp]);

          setConnectData(setChart(labels, response.data.data4.map((item) => item.deviceCount), backgroundColor, borderColor));

        } else {
          setData([])
        }
      }
    })
  }

  /***************************************************************************************
   * 설명 : 데이터 로딩
  ***************************************************************************************/
  useEffect(() => {
    getList();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.startDate, formik.values.endDate]);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <Layout>
      <section className="admin-cms-contents">
        <form onSubmit={formik.handleSubmit}>
          <header className="mt10">
            <div className="mb5">
              <BtnRefresh click={() => {
                formik.setValues(initialValues);
                formik.handleSubmit();
              }}></BtnRefresh>
              <BtnSearch></BtnSearch>
              {/*<BtnAgGridSave click={setGridUpdate} />*/}

              <InputEx
                type="date"
                name="startDate"
                formik={formik}
                fullWidth={false}
                style={{width: '140px'}}
                label="검색 시작일"
              />
              <span className="ml5 mr5">~</span>
              <InputEx
                type="date"
                name="endDate"
                formik={formik}
                fullWidth={false}
                style={{width: '140px'}}
                label="검색 종료일"
              />
            </div>
          </header>
        </form>

        <section className="deviceInfo">
          {/* 접속기기 */}
          <section className="pie-chart center">
            {(deviceData.datasets ?? '') !== '' &&
              <Pie data={deviceData} />
            }

            <h3 className="mt20">접속구분</h3>
          </section>

          {/* Tablet 기기종류 */}
          <section className="pie-chart center">
            {(tabletData.datasets ?? '') !== '' &&
              <Pie data={tabletData} />
            }

            <h3 className="mt20">테블릿 접속기기</h3>
          </section>

          {/* Mobile 기기종류 */}
          <section className="pie-chart center">
            {(mobileData.datasets ?? '') !== '' &&
              <Pie data={mobileData} />
            }

            <h3 className="mt20">모바일 접속기기</h3>
          </section>

          {/* 브라우저 종류 */}
          <section className="pie-chart center">
            {(browserData.datasets ?? '') !== '' &&
              <Pie data={browserData} />
            }

            <h3 className="mt20">브라우저</h3>
          </section>
        </section>

        {/* 접속 통계 */}
        <section>

          <section className="pie-chart center" style={{width: '100%', height: '250px'}}>
            {(connectData.datasets ?? '') !== '' &&
              <Bar data={connectData} options={{ maintainAspectRatio: false, responsive: true }} />
            }

            <h3 className="mt20">접속자 정보</h3>
          </section>

          <div className="clearfix" />

          <section className="mt5 ag-theme-balham grid pad-height350" style={{height:'100px'}}>
            <div className="" style={{ height: '100%', marginLeft: '35px'}}>
              <AgGridReact
                defaultColDef={{
                  sortable: true,
                  resizable: true,
                  filter: false,
                  lockVisible: true,
                  flex: 1,
                  tooltipComponent: customTooltipAgGrid,
                }}
                tooltipShowDelay={0}
                tooltipHideDelay={2000}
                rowSelection = {'single'}
                columnDefs={columnDefs}
                rowData={data}
                rowDragManaged={true}
                rowDragMultiRow={true}
                animateRows={true}
                overlayNoRowsTemplate = "검색된 내용이 없습니다."
              />
            </div>
          </section>
        </section>
      </section>
    </Layout>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default StatisticDeviceInfo;